import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface RobProps {
  
}

export interface RobState {
    height:number
    width:number
}


export class Rob extends React.Component<RobProps, RobState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:520})
      
    }

    getRobChar(){
       const kid=<svg id="Layer_1" data-name="Layer 1"  viewBox="0 0 500 520" height={this.state.height}>
                    <defs>
                        <linearGradient id="linear-gradient" x1="249.45" y1="597.48" x2="249.45" y2="610.74" gradientTransform="matrix(1, 0, 0, -1, 0, 826.43)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#656565" stop-opacity="0"/>
                            <stop offset="1" stop-color="#161616" stop-opacity="0.3"/>
                        </linearGradient>
                        <clipPath id="clip-path">
                            <path d="M310.9,379.8a268.15,268.15,0,0,0-7.49-43.42c-4.59-16.68-9.12-23.63-9.12-23.63L284,309l-6.62-22.42H222.51L215.89,309l-10.33,3.71s-4.52,6.89-9.11,23.63A269.4,269.4,0,0,0,189,379.8c-1.22,12.09-1.89,26.2-1.89,42.34,0,0,.07,1.89.2,5.14l62.87,3.57,62.53-3.57c.07-3.18.2-5.14.2-5.14Q312.76,397.93,310.9,379.8Z" fill="none"/>
                        </clipPath>
                    </defs>
                    <title>
                        500x520
                    </title>
                    <g id="Rob">
                        <g id="Rob-2" data-name="Rob">
                            <g id="Woman_wo_Tool" data-name="Woman wo Tool">
                                <path d="M373.37,215c-1.69-4.39-6.08-9.72-10.81-9.52a13.36,13.36,0,0,0-4.59,1c-6.21-40.24-52.13-85.28-107.84-85.28s-101.76,45-108,85.21a15.51,15.51,0,0,0-4.6-1c-4.72-.2-9,5.2-10.8,9.52-6,15.4.47,27.15,1.62,28.9,3.17,5.54,7.83,6.42,9.32,6.28a17.81,17.81,0,0,0,8.1-2.9c8.92,29.1,32,47.94,72.32,54.16l-2.22,7.49-8.31,3c-9.93.81-32.75,5.2-35.52,28.43-5.2,44.23-26.94,85.63-7.49,95.69,8.91,4.52,16.4-.27,22.68-9.32V427l62.74,3.57L312.46,427v-.68c6.28,9.18,14,14.18,22.89,9.59,19.38-10-2.3-51.32-7.5-95.69-2.77-23.43-26-27.68-35.79-28.49l-8.3-3-2.23-7.5c40.52-6.21,63.61-25,72.52-54.22a19.25,19.25,0,0,0,8.11,2.9c1.48.07,6.07-.81,9.32-6.28C372.76,242.12,379.31,230.37,373.37,215Z" fill="#cb8b6e"/>
                                <path d="M283.35,428.63l-33.29,1.62-33.63-1.62-29.24-1.42v1.21c.68,9.12,5.2,65.84,15.33,82.93a10.9,10.9,0,0,0,4,4.39,30.61,30.61,0,0,0,9.38,3.37,64.6,64.6,0,0,0,10.81.88H227c9.79,0,19.72-1.82,18.5-6.48-2.3-8.51-3.51-39.64-3.17-44.84h.27c2.29.2,4.59.27,7.09.27h.27q3.34,0,6.48-.2l1-.07c.27,5.2-1,36.33-3.17,44.84C253,518.23,263,520,272.75,520H273a55.26,55.26,0,0,0,10.6-.81,32.11,32.11,0,0,0,9.59-3.51,12.84,12.84,0,0,0,4.12-4.39c8.78-14.79,13.3-58.95,14.79-76.64.07-2.91.2-5.54.27-7.5Z" fill="#161616"/>
                                <path d="M196.45,336.32a269.54,269.54,0,0,0-7.5,43.35H310.84a269.21,269.21,0,0,0-7.5-43.35Z" fill="#161616"/>
                                <path d="M239.53,228.07s4.39-7,10.53-7.22,9.32,7.22,9.32,7.22A36.77,36.77,0,0,1,239.53,228.07Z" fill="url(#linear-gradient)"/>
                                <path d="M373.37,215c-1.69-4.39-6.08-9.72-10.81-9.52a13.36,13.36,0,0,0-4.59,1,58.27,58.27,0,0,1,.68,9.12,109.77,109.77,0,0,1-4.33,31.8,19.26,19.26,0,0,0,8.11,2.91c1.48.06,6.07-.81,9.32-6.28A34.27,34.27,0,0,0,373.37,215Z" fill="#2b2c2b" opacity="0.12" />
                                <path d="M142.15,206.46a14.85,14.85,0,0,0-4.59-1c-4.72-.2-9,5.2-10.8,9.52-6,15.4.47,27.15,1.62,28.9,3.17,5.54,7.83,6.42,9.32,6.28a17.81,17.81,0,0,0,8.1-2.9,110.84,110.84,0,0,1-4.39-31.81A57.86,57.86,0,0,1,142.15,206.46Z" fill="#2b2c2b" opacity="0.12" />
                                <path d="M228.05,244.07l2.29.68c11.42-1.82,20.33-1.62,31.94-1a34.1,34.1,0,0,0,12.09-8.24,59.4,59.4,0,0,1-1.21,9.79c7.49,1.42,13.43,9.52,13.43,9.52,11.82-33.49-89.87-32.95-73.47,0,0,0,6.69-8.3,14.93-9.72Z" fill="#442613" opacity="0.3" />
                                <path d="M236,269.4s16.2,11.88,29.71,0C265.8,269.4,252.63,298,236,269.4Z" fill="#442613" opacity="0.3" />
                                <path d="M213.8,260.42s-12.49,17.42-4.59,30.92,76,13.1,82.25,1.22-6.62-35.25-6.62-35.25,2,29-30.12,29S215,278.18,213.8,260.42Z" fill="#442613" opacity="0.3" />
                                <path d="M228.05,244.07a40.61,40.61,0,0,0,.88,6.62c6.68,2.64,26.4,8.51,43.82-2.9a59.86,59.86,0,0,0,1.69-12.09C256.27,254.41,228.05,244.07,228.05,244.07Z" fill="#fff6ee"/>
                                <path d="M228.93,250.69a29.92,29.92,0,0,0,4.59,11.08,25.27,25.27,0,0,1,9-7.7A65.07,65.07,0,0,1,228.93,250.69Z" fill="#9d1f60"/>
                                <path d="M251.28,270.27c13.44-.6,19.11-12.83,21.54-22.48-10.33,6.82-21.41,7.43-30.25,6.28a25.27,25.27,0,0,0-9,7.7C237,266.7,242.43,270.68,251.28,270.27Z" fill="#db727f"/>
                                <path d="M178.08,234.49a36.11,36.11,0,0,1,21.4,7,20.21,20.21,0,1,0-29.78-17.83A20,20,0,0,0,173,234.76,41,41,0,0,1,178.08,234.49Z" fill="#fff"/>
                                <path d="M196.72,214.57a10.53,10.53,0,1,0,10.53,10.53A10.55,10.55,0,0,0,196.72,214.57Z" fill="#442613"/>
                                <path d="M189.83,225.17h-7a4.09,4.09,0,0,1-4.11-4.12h0a4.09,4.09,0,0,1,4.11-4.12h7a4.1,4.1,0,0,1,4.12,4.12h0A4.14,4.14,0,0,1,189.83,225.17Z" fill="#fff"/>
                                <path d="M322,234.49a36.11,36.11,0,0,0-21.41,7,20.21,20.21,0,1,1,29.78-17.83A20,20,0,0,1,327,234.76,39.54,39.54,0,0,0,322,234.49Z" fill="#fff"/>
                                <path d="M303.41,214.57a10.53,10.53,0,1,1-10.54,10.53A10.54,10.54,0,0,1,303.41,214.57Z" fill="#442613"/>
                                <path d="M310.3,225.17h7a4.1,4.1,0,0,0,4.12-4.12h0a4.1,4.1,0,0,0-4.12-4.12h-7a4.09,4.09,0,0,0-4.12,4.12h0A4.13,4.13,0,0,0,310.3,225.17Z" fill="#fff"/>
                                <path d="M290,194.17s25.53-8.51,39.23,1.69c0,0,4.93,3,.68,8.24,0,0,.4-6-9.52-6.28a69.32,69.32,0,0,0-27.69,4.32S285.18,200.79,290,194.17Z" fill="#442613"/>
                                <path d="M210.15,194.17s-25.52-8.51-39.23,1.69c0,0-4.93,3-.68,8.24,0,0-.4-6,9.53-6.28a69.3,69.3,0,0,1,27.68,4.32S215,200.79,210.15,194.17Z" fill="#442613"/>
                                <path d="M337.91,234.28c-2,1.42-5.87,1.22-9.11,1.08" fill="#c48066"/>
                                <path d="M162.21,234.28c2,1.42,5.87,1.22,9.12,1.08h-.07c-1.89.81-6.62,2.5-7.84,4.6a6.16,6.16,0,0,1,2.64-3.58A5.64,5.64,0,0,1,162.21,234.28Z" fill="#c48066"/>
                            </g>
                            <path d="M334.34,373.25a6.3,6.3,0,0,0-.41-1.48c-.4-1.49-.67-3.11-1.08-4.59-.07-.48-.2-1-.27-1.42-.2-1.08-.47-2.23-.68-3.31a5,5,0,0,0-.2-1.08,3.07,3.07,0,0,0-.2-.68c-.2-1-.41-2-.61-3.1-.07-.61-.27-1.29-.4-1.89-.21-1-.41-2.1-.61-3.11,0-.2-.07-.4-.07-.67a8.39,8.39,0,0,0-.2-1.22,26.9,26.9,0,0,1-.47-3.31c-.07-.47-.21-1.08-.27-1.62-.21-1.69-.48-3.31-.68-5-3.51-29.84-40.11-28.63-40.11-28.63l.27,5.2h0l.07,1.82h0l2.23,42.34v1.35a.43.43,0,0,0,.06.27v.07c.07.41.21,1,.27,1.49v.2a3.48,3.48,0,0,0,.07.47v.21a3.77,3.77,0,0,0,.07.47.39.39,0,0,0,.07.27,4.36,4.36,0,0,0,.06.47.39.39,0,0,0,.07.27c0,.2.07.41.07.61a.39.39,0,0,0,.07.27c0,.2.06.4.06.67,0,.07,0,.21.07.21.2.81.41,1.68.61,2.7,0,.07,0,.2.07.2a3.8,3.8,0,0,0,.2.81c0,.07.07.27.07.41a3.8,3.8,0,0,0,.2.81c0,.2.07.27.07.47a3.8,3.8,0,0,0,.2.81c0,.2.07.27.07.47a3.8,3.8,0,0,0,.2.81c0,.2.07.27.07.48a3.8,3.8,0,0,0,.2.81c0,.2.07.27.07.47a3.49,3.49,0,0,0,.2.88c0,.06,0,.2.07.2.2.61.27,1.28.47,2,0,.21.07.27.07.48a3.8,3.8,0,0,0,.2.81,2.56,2.56,0,0,0,.2.61,5.57,5.57,0,0,0,.2.81,2.47,2.47,0,0,0,.21.67,3.8,3.8,0,0,0,.2.81,3.07,3.07,0,0,0,.2.68,5.57,5.57,0,0,0,.2.81,4.63,4.63,0,0,0,.21.81c.13.33.06.47.2.81a4,4,0,0,1,.2.88,2.47,2.47,0,0,0,.21.67v.07h0c.6,2.09,1.28,4.32,2,6.48,9.79-11,25.8-14.32,37-15.19A37.29,37.29,0,0,0,334.34,373.25Z" fill="#282828"/>
                            <path d="M202.73,390.41h0c3.17-11.21,5.33-21.21,6.21-26h0c0-.21.07-.41.07-.61v-.07a4.36,4.36,0,0,1,.06-.47v-.07c0-.2.07-.27.07-.4v-.07a1,1,0,0,1,.07-.41h0a.41.41,0,0,1,.07-.27v-.54l2.63-49.36h-1.69c-.88,0-2,.07-3.31.2h0c-.27,0-.61.07-1,.07h0c-.27,0-.68.07-1,.07h-.07c-.68.07-1.49.2-2.3.27h-.06a11.68,11.68,0,0,0-1.29.2h-.07a7.27,7.27,0,0,1-1.21.2h-.07c-.81.21-1.62.41-2.5.61h-.2c-.4.07-.81.2-1.21.27h0c-.07,0-.21.07-.27.07h0c-.82.27-1.69.47-2.5.81h0c-.07,0-.07,0-.21.07a17.72,17.72,0,0,0-2.63,1.08h0c-.07,0-.2.07-.27.07h0l-1.22.6h0c-.06,0-.2.07-.27.07h0c-.4.2-.67.41-1.08.61h0c-.06,0-.06.07-.2.07h0c-.4.2-.81.47-1.21.67h0c-.07.07-.21.07-.27.2h0a14.31,14.31,0,0,0-1.22.81h0c-.4.27-.67.48-1.08.81h0a.07.07,0,0,0-.07.07c-.81.61-1.62,1.29-2.43,2h0l-.07.07a7.86,7.86,0,0,0-1.08,1.08h0c-.06.06-.06.2-.2.2h0a7,7,0,0,0-.88,1h0l-.2.2h0c-.4.48-.67.88-1.08,1.42h0a15.08,15.08,0,0,0-1.69,2.7c-.07.07-.07.21-.2.27h0c-.47,1-1,2-1.49,3.18,0,.06,0,.06-.06.2h0a30.6,30.6,0,0,0-1.62,5.67v.2h0c-.07.61-.21,1.29-.27,1.9-.21,1.68-.41,3.3-.68,5,0,.2-.07.27-.07.48a3.93,3.93,0,0,1-.2,1.08c-.2,1.08-.27,2.22-.47,3.3a4.65,4.65,0,0,1-.2.88,6.63,6.63,0,0,1-.21,1c-.2,1-.4,2-.61,3.1a8.62,8.62,0,0,0-.2,1.22c0,.2-.07.47-.07.67-.2,1-.4,2-.6,3a17.69,17.69,0,0,1-.41,1.89c-.2,1.08-.47,2.23-.67,3.18-.07.47-.21,1-.27,1.48-.41,1.49-.68,3.11-1.08,4.6h0a6.3,6.3,0,0,1-.41,1.48v.07c-.67,2.77-1.42,5.6-2.09,8.37,11.27,1,27.21,4.39,36.93,15.53.48-1.48,1-3,1.42-4.52h0a2.11,2.11,0,0,1,.21-.61h0A7.27,7.27,0,0,0,202.73,390.41Z" fill="#282828"/>
                            <g clip-path="url(#clip-path)">
                                <path d="M188.88,379.8C187.67,391.89,187,406,187,422.14c0,0,.07,1.89.2,5.14l62.87,3.57,62.53-3.57c.07-3.18.2-5.14.2-5.14,0-16.14-.67-30.25-1.89-42.34a268.15,268.15,0,0,0-7.49-43.42A132,132,0,0,0,297.87,320l-9.12-1.21-.4-1-.41.88c-3.51,6.69-11.27,17.29-11.27,17.29-8.58-1.08-15.94-10.33-16.55-11h0c0,.41-.27,2.64-1.68,3.58-1.09.81-3.38-.2-4.73,1.22h0V345h-7.23v-1.82l-1.69-10c-2.7-1.89-5.6-4.39-7.83-5.13h0c-3,3-8,7.23-13.64,7.9,0,0-7.9-10.6-11.28-17.29l-10,1.29a135,135,0,0,0-5.54,16.34A257.56,257.56,0,0,0,188.88,379.8Z" fill="#282828"/>
                            </g>
                            <g clip-path="url(#clip-path)">
                                <path d="M266.27,355.76h0l-.2-.4-.07.4Zm-.2-.6h0l.47,1h-.07l-.07-.27H266l-.07.27h-.07Z" fill="#fff"/>
                                <path d="M267.15,355.76h0l-.07.27h.27s.07,0,.07-.06v-.14l-.07-.07h-.2m0-.54h0l-.07.27h.27v-.06C267.35,355.36,267.35,355.22,267.15,355.22Zm.13.95H267v-1h.47c.14,0,.07.06.07.06v.07a.21.21,0,0,1-.2.2c.07,0,.07,0,.2.07a.29.29,0,0,1,.07.2v.07l-.07.07C267.35,356.17,267.35,356.17,267.28,356.17Z" fill="#fff"/>
                                <path d="M268.5,355.22h0c-.07,0-.21,0-.27.07a.56.56,0,0,0,0,.54c.06.07.2.07.27.07a.41.41,0,0,0,.27-.07.64.64,0,0,0,0-.54c-.07.07-.21-.07-.27-.07m0-.06a.79.79,0,0,1,.4.06.41.41,0,0,1,.2.41.67.67,0,0,1-.2.4c-.13.14-.2.07-.4.07a1,1,0,0,1-.41-.07c-.13-.06-.07-.2-.07-.4a.75.75,0,0,1,.21-.41C268.36,355.09,268.36,355.16,268.5,355.16Z" fill="#fff"/>
                                <path d="M269.64,355.22h0v.27h.14l.07-.06v-.21l-.07-.06c0,.06,0,.06-.14.06m-.2.95v-1h.2c.07,0,.21,0,.21.06s.06.07.06.21v.06l-.06.07a.06.06,0,0,1,.06.07c0,.07.07.07.07.2s.07.07.07.07l.07.07h-.21v-.07h0v-.14l-.06-.06-.07-.07c-.07-.07-.07,0-.07,0h0v.4h-.27v.14Z" fill="#fff"/>
                                <rect x="270.59" y="355.16" width="0.07" height="1.01" fill="#fff"/>
                                <path d="M271.74,355.63H272v.4c-.07.07-.2.07-.41.07a.69.69,0,0,1-.4-.07c-.14-.06-.07-.2-.07-.4a.79.79,0,0,1,.2-.41c.14-.13.21-.06.41-.06h.2c.14,0,.07,0,.2.06v.07c-.06-.07-.2-.07-.4-.07a.34.34,0,1,0,0,.68h.2v-.2h-.2v-.07Z" fill="#fff"/>
                                <rect x="272.55" y="355.16" width="0.07" height="1.01" fill="#fff"/>
                                <polygon points="273.97 355.16 274.17 355.16 274.17 356.17 273.97 356.17 273.36 355.36 273.36 356.17 273.16 356.17 273.16 355.16 273.36 355.16 273.97 355.97 273.97 355.16" fill="#fff"/>
                                <path d="M275.18,355.76h0l-.2-.4-.07.4Zm-.34-.6h0l.48,1h-.07l-.07-.27h-.4l-.07.27h-.07Z" fill="#fff"/>
                                <polygon points="275.86 355.16 275.99 355.16 275.99 356.03 276.4 356.03 276.4 356.17 275.86 356.17 275.86 355.16" fill="#fff"/>
                                <path d="M278.36,356v.2c-.07.07-.21.07-.41.07a.39.39,0,0,1-.27-.07c-.07,0-.07-.07-.2-.2s-.07-.21-.07-.27a.79.79,0,0,1,.2-.41c.14-.13.21-.07.41-.07a.39.39,0,0,1,.27.07v.2a.64.64,0,0,0-.54,0,.41.41,0,0,0-.07.27c0,.07,0,.21.07.27a.39.39,0,0,0,.27.07.69.69,0,0,1,.34-.13" fill="#fff"/>
                                <path d="M279.3,355.22h0a.28.28,0,1,0,.27.07c-.07.07-.2-.07-.27-.07m0-.06a.85.85,0,0,1,.41.06.43.43,0,0,1,.2.41.74.74,0,0,1-.2.4c-.14.14-.21.07-.41.07a.93.93,0,0,1-.4-.07c-.14-.06-.07-.2-.07-.4a.79.79,0,0,1,.2-.41C279,355.16,279.1,355.16,279.3,355.16Z" fill="#fff"/>
                                <path d="M280.45,355.22h0v.27h.13l.07-.06v-.21l-.07-.06c0,.06-.13.06-.13.06m-.2.95v-1h.2c.07,0,.2,0,.2.06s.07.07.07.21v.06l-.07.07a.07.07,0,0,1,.07.07c0,.07.07.07.07.2s.06.07.06.07l.07.07h-.2v-.07h0v-.14l-.07-.06-.07-.07h-.06v.4h-.27v.14Z" fill="#fff"/>
                                <path d="M281.66,355.22h0l-.06.27h.27v-.06a.22.22,0,0,0-.21-.21m-.27.95v-1h.27c.07,0,.21,0,.21.06s.06.07.06.21v.2c0,.13-.06.07-.06.07h-.27v.4l-.21.07Z" fill="#fff"/>
                                <path d="M282.88,355.22h0a.28.28,0,1,0,.27.07c-.07.07-.2-.07-.27-.07m0-.06a.85.85,0,0,1,.41.06.43.43,0,0,1,.2.41.74.74,0,0,1-.2.4c-.14.14-.21.07-.41.07a1,1,0,0,1-.41-.07c-.13-.06-.06-.2-.06-.4a.72.72,0,0,1,.2-.41C282.54,355.16,282.68,355.16,282.88,355.16Z" fill="#fff"/>
                                <path d="M284.1,355.22h0v.27h.13l.07-.06v-.21l-.07-.06a.22.22,0,0,1-.13.06m-.21.95v-1h.21c.06,0,.2,0,.2.06s.07.07.07.21v.06l-.07.07a.07.07,0,0,1,.07.07c0,.07.06.07.06.2s.07.07.07.07l.07.07h-.2v-.07h0v-.14l-.07-.06-.07-.07h-.07v.4h-.27v.14Z" fill="#fff"/>
                                <path d="M285.45,355.76h0l-.21-.4-.06.4Zm-.21-.6h0l.48,1h-.07l-.07-.27h-.4l-.07.27H285Z" fill="#fff"/>
                                <polygon points="285.99 355.16 286.8 355.16 286.8 355.22 286.46 355.22 286.46 356.17 286.26 356.17 286.26 355.22 285.99 355.22 285.99 355.16" fill="#fff"/>
                                <rect x="287.2" y="355.16" width="0.07" height="1.01" fill="#fff"/>
                                <path d="M288.28,355.22h0a.28.28,0,1,0,.27.07c-.07.07-.07-.07-.27-.07m0-.06a.85.85,0,0,1,.41.06.63.63,0,0,1,.2.41.74.74,0,0,1-.2.4c-.07.07-.21.07-.41.07a.93.93,0,0,1-.4-.07c-.14-.06-.07-.2-.07-.4a.79.79,0,0,1,.2-.41C288.08,355.16,288.15,355.16,288.28,355.16Z" fill="#fff"/>
                                <polygon points="290.11 355.16 290.17 355.16 290.17 356.17 290.11 356.17 289.5 355.36 289.5 356.17 289.3 356.17 289.3 355.16 289.36 355.16 290.11 355.97 290.11 355.16" fill="#fff"/>
                                <rect x="291.39" y="355.16" width="0.07" height="1.01" fill="#fff"/>
                                <polygon points="292.81 355.16 292.87 355.16 292.87 356.17 292.81 356.17 292.2 355.36 292.2 356.17 292 356.17 292 355.16 292.06 355.16 292.81 355.97 292.81 355.16" fill="#fff"/>
                                <path d="M294.29,356v.2c-.07.07-.2.07-.4.07s-.21,0-.27-.07-.07-.07-.21-.2-.06-.21-.06-.27a.72.72,0,0,1,.2-.41c.13-.13.2-.07.4-.07s.21,0,.27.07v.2a.43.43,0,0,0-.27-.06.26.26,0,0,0-.33.33.43.43,0,0,0,.06.27.41.41,0,0,0,.27.07c0-.07.21-.13.34-.13" fill="#fff"/>
                                <path d="M294.76,356h0" fill="#fff"/>
                                <path d="M266.47,354.14h-.61v-1.28h.61a.65.65,0,0,1,.61.61.66.66,0,0,1-.61.67m-.61-2.09h.61a.27.27,0,0,1,0,.54h-.61Zm1,.68a.83.83,0,0,0,.2-.48c0-.27-.27-.47-.61-.47h-.81v2.63h.81a.87.87,0,0,0,.81-.87A.8.8,0,0,0,266.88,352.73Z" fill="#fff"/>
                                <path d="M268.77,354.28a.82.82,0,0,0,.2-.47v-1h-.2v1a.47.47,0,0,1-.48.47c-.27,0-.4-.2-.4-.47v-1h-.2v1a1.27,1.27,0,0,0,.06.47.77.77,0,0,0,.48.2.7.7,0,0,0,.54-.2" fill="#fff"/>
                                <path d="M272.14,354.28a.77.77,0,0,0,.21-.47v-1h-.27v1a.47.47,0,0,1-.48.47c-.27,0-.4-.2-.4-.47v-1H271v1a1.27,1.27,0,0,0,.06.47.77.77,0,0,0,.48.2.73.73,0,0,0,.6-.2" fill="#fff"/>
                                <path d="M270,352.93l-.07-.2c-.06.06-.27.06-.4.27v-.21h-.27v1.69h.2v-.88a1,1,0,0,1,.54-.67" fill="#fff"/>
                                <path d="M270.79,352.93l-.06-.2c-.07.06-.27.06-.41.27v-.21h-.2v1.69h.2v-.88c.07-.47.34-.54.47-.67" fill="#fff"/>
                                <path d="M273.9,352.93a.8.8,0,0,0-.47-.27,1.15,1.15,0,0,0-.48.07h-.2v1.68h.2v-1.08a.45.45,0,0,1,.41-.47c.27,0,.4.2.4.47v1H274v-1a.69.69,0,0,0-.07-.4" fill="#fff"/>
                                <path d="M276.67,353.87h0a1.14,1.14,0,0,1-.88.27h-.2v-2.09h.2a1.19,1.19,0,0,1,.81.27,1.56,1.56,0,0,1,.41.81c-.07.2-.07.54-.34.74m.13-1.82a1.37,1.37,0,0,0-.88-.27h-.47v2.7h.61a1.32,1.32,0,0,0,1.08-.47,1.45,1.45,0,0,0,.27-1A5,5,0,0,0,276.8,352.05Z" fill="#fff"/>
                                <path d="M279.1,353.67h0a.67.67,0,0,1-.68.61.68.68,0,0,1,0-1.35.63.63,0,0,1,.68.61Zm0-.94h0a1.12,1.12,0,0,0-.68-.21.88.88,0,0,0,0,1.76.88.88,0,0,0,.68-.27v.2h.2v-1.75h-.2Z" fill="#fff"/>
                                <path d="M281.87,353.67h0a.67.67,0,0,1-.68.61.68.68,0,0,1,0-1.35.63.63,0,0,1,.68.61Zm0-.94h0a1.14,1.14,0,0,0-.68-.21.88.88,0,1,0,.68,1.49v.2h.2v-1.75h-.2Z" fill="#fff"/>
                                <rect x="279.71" y="351.78" width="0.2" height="2.63" fill="#fff"/>
                                <rect x="282.47" y="352.73" width="0.2" height="1.69" fill="#fff"/>
                                <path d="M282.74,352.32a.21.21,0,0,0-.2-.2.18.18,0,0,0-.2.2.18.18,0,0,0,.2.2c.14,0,.2,0,.2-.2" fill="#fff"/>
                                <path d="M282.07,346.18h-.2c-.21,0-.41.06-.41.27h-.07a.46.46,0,0,0-.47.47h0a.37.37,0,0,0-.27.4v.21a.54.54,0,0,0-.2.4v.2c-.07.07-.2.21-.2.27s-.07-.2-.21-.2v-.07a.46.46,0,0,0-.2-.4v-.07c0-.2-.07-.4-.27-.4v-.14a.46.46,0,0,0-.47-.47H279a.37.37,0,0,0-.4-.27h-.07c-.07-.2-.2-.2-.41-.2a.46.46,0,0,0-.27.06.43.43,0,0,0-.27-.06.7.7,0,0,0-.4.2H277a.58.58,0,0,0-.48.4h0a.47.47,0,0,0-.47.48v.06a.38.38,0,0,0-.27.41v.2a.57.57,0,0,0-.2.41v.2l-.21.2h0c0-.07-.06-.27-.2-.27v-.07c0-.2-.07-.4-.27-.4v-.07a.58.58,0,0,0-.4-.47A.47.47,0,0,0,274,347H274a.37.37,0,0,0-.41-.27c-.07,0-.2,0-.2.06a.45.45,0,0,0-.27-.06c-.07,0-.2.06-.27.06a.68.68,0,0,1-.2-.06.48.48,0,0,0-.27.87v.21c0,.13.06.27.2.27-.07.06-.07.2-.07.27a.46.46,0,0,0,.47.47c.07,0,.27-.07.27-.2l.07.06v.07c0,.2.07.27.27.41v.06c0,.21.07.41.27.41v.07a9.46,9.46,0,0,0,.88.94h.2a.57.57,0,0,0,.41.2.46.46,0,0,0,.27-.06c.06.06.2.2.27.2a.41.41,0,0,0,.27-.07l.06-.07h.21c.13,0,.2,0,.27-.06a.41.41,0,0,0,.07-.27c.06,0,.2-.07.27-.07a.45.45,0,0,0,.06-.27h0c.07,0,.07-.07.21-.07a.45.45,0,0,0,.06-.27v-.07l.07-.06a.41.41,0,0,0,.07-.27v-.21c.07-.06.2-.2.2-.27h.07a.28.28,0,0,0,.27.27v.07c0,.2.07.27.27.41v.06a.58.58,0,0,0,.4.48.46.46,0,0,0,.48.4h0a.5.5,0,0,0,.47.41c.07,0,.2,0,.2-.07a.57.57,0,0,0,.41.2.48.48,0,0,0,.4-.2.62.62,0,0,1,.2.07c.21,0,.27-.07.41-.27h.07a.47.47,0,0,0,.47-.48h0c.2-.06.27-.2.27-.47v-.2a.57.57,0,0,0,.2-.41V349a.56.56,0,0,0,.2-.4.46.46,0,0,0,.41-.48.41.41,0,0,0-.07-.27h0c.07-.06.07-.2.07-.27a.41.41,0,0,0-.07-.27h0c.07-.06.07-.2.07-.27a.41.41,0,0,0-.07-.27.45.45,0,0,0-.27-.06c-.27-.75-.34-.68-.47-.54" fill="#010101"/>
                                <path d="M273.09,346.85c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.2.27-.27" fill="#fbb615"/>
                                <path d="M272.48,346.85c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M273.7,346.85c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M274.17,347.12c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M274.57,347.53c0-.21-.06-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M274.78,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.06.27-.27" fill="#fbb615"/>
                                <path d="M275.05,348.67c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.06.27-.27" fill="#fbb615"/>
                                <path d="M275.45,349.08c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M276,348.74c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M276.19,348.27c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.51.51,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M276.26,347.66c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M276.67,347.12c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M277.07,346.78c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M277.68,346.65c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M278.29,346.65c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.3.3,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M278.76,346.85c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M279.3,347.12c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M279.57,347.66c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M279.84,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#fbb615"/>
                                <path d="M280.18,348.67c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M280.65,348.88c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M281,348.47c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M281.19,347.93c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M281.39,347.32c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#fbb615"/>
                                <path d="M281.66,346.85c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M282.2,346.65c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fbb615"/>
                                <path d="M282.74,346.45c0-.21-.06-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#fbb615"/>
                                <path d="M282.74,347.05c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#fff"/>
                                <path d="M282.2,347.26c0-.21-.06-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M281.87,347.73c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M281.66,348.34c0-.21-.06-.27-.27-.27s-.27.06-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M281.46,348.94c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.13.27-.27" fill="#fff"/>
                                <path d="M281.19,349.35c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M280.58,349.55c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.2.27-.27" fill="#fff"/>
                                <path d="M280,349.48c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27a.29.29,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M279.57,349.08c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M279.3,348.54c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M279.1,347.93c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M278.76,347.53c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M278.29,347.26c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M277.75,347.26c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M277.28,347.46c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.2.27-.27" fill="#fff"/>
                                <path d="M276.94,347.86c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.06.27-.27" fill="#fff"/>
                                <path d="M276.8,348.47c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M276.6,349.08c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M276.19,349.55c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M275.59,349.62c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M275.05,349.62c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.14.27-.27" fill="#fff"/>
                                <path d="M274.57,349.15c0-.21-.06-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M274.37,348.67c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#fff"/>
                                <path d="M274.1,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#fff"/>
                                <path d="M273.76,347.66c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M273.29,347.46c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#fff"/>
                                <path d="M272.68,347.46c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#fff"/>
                                <path d="M272.68,348.13c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M273.36,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.13.27-.27" fill="#c52328"/>
                                <path d="M273.7,348.67c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.2.27-.27" fill="#c52328"/>
                                <path d="M273.9,349.15c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M274.17,349.62c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M274.57,350.16c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.2.27-.27" fill="#c52328"/>
                                <path d="M275.18,350.23c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M275.79,350.36c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.2.27-.27" fill="#c52328"/>
                                <path d="M276.4,350.16c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27a.25.25,0,0,0,.27-.27" fill="#c52328"/>
                                <path d="M276.87,349.75c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.28.28,0,0,0,.27-.27" fill="#c52328"/>
                                <path d="M277.14,349.35c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.14.27-.27" fill="#c52328"/>
                                <path d="M277.28,348.74c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M277.48,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M278,347.86c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M278.49,348.27c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M278.76,348.88c0-.21-.07-.27-.27-.27s-.27.06-.27.27.07.27.27.27.27-.21.27-.27" fill="#c52328"/>
                                <path d="M279,349.35c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#c52328"/>
                                <path d="M279.37,349.75c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M279.84,350c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M280.45,350.16c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M281.06,350c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M281.66,349.82c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.13.27-.27" fill="#c52328"/>
                                <path d="M282,349.35c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27a.25.25,0,0,0,.27-.27" fill="#c52328"/>
                                <path d="M282.2,348.74c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M282.41,348.13c0-.2-.07-.27-.27-.27s-.27.07-.27.27.06.27.27.27.27-.06.27-.27" fill="#c52328"/>
                                <path d="M282.74,347.73c0-.2-.06-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M294.9,347.53a6.08,6.08,0,1,0-6.08,6.07,6.05,6.05,0,0,0,6.08-6.07" fill="#010101"/>
                                <path d="M289.16,341.92c0-.2-.07-.27-.27-.27s-.27.07-.27.27.07.27.27.27.27-.07.27-.27" fill="#c52328"/>
                                <path d="M288.48,341.92c0-.2-.2-.27-.4-.27s-.27.2-.27.41.2.27.4.27.34-.21.27-.41" fill="#c52328"/>
                                <path d="M287.88,342.06c0-.21-.21-.27-.41-.21s-.27.21-.2.41.2.27.4.2.34-.34.21-.4" fill="#c52328"/>
                                <path d="M287.27,342.12a.32.32,0,1,0-.2.41c.2-.07.33-.2.2-.41" fill="#c52328"/>
                                <path d="M286.66,342.33c-.07-.21-.27-.21-.4-.21s-.21.27-.21.41.27.2.41.2c.34-.07.34-.27.2-.4" fill="#c52328"/>
                                <path d="M286.19,342.53c-.07-.2-.27-.2-.47-.07s-.21.27-.07.47.27.21.47.07a.49.49,0,0,0,.07-.47" fill="#c52328"/>
                                <path d="M285.65,342.87a.34.34,0,0,0-.54.4.34.34,0,1,0,.54-.4" fill="#c52328"/>
                                <path d="M285.18,343.27a.55.55,0,0,0-.48,0,.61.61,0,0,0,0,.47.55.55,0,0,0,.48,0,.61.61,0,0,0,0-.47" fill="#c52328"/>
                                <path d="M284.7,343.68c-.06-.07-.27-.07-.47,0s-.07.27,0,.47a.53.53,0,0,0,.47,0,.25.25,0,0,0,0-.47" fill="#c52328"/>
                                <path d="M284.3,344.15c-.2-.07-.41-.07-.47.07s-.07.4.06.47.41.07.48-.07.13-.4-.07-.47" fill="#c52328"/>
                                <path d="M284,344.62c-.2-.07-.4,0-.4.07s0,.4.06.4.41,0,.41-.06.07-.27-.07-.41" fill="#c52328"/>
                                <path d="M283.69,345.23c-.2-.07-.4,0-.4.2s0,.41.2.41.4,0,.4-.2,0-.41-.2-.41" fill="#c52328"/>
                                <path d="M283.49,345.77c-.2,0-.41.07-.41.2s.07.41.21.41.4-.07.4-.2a.57.57,0,0,0-.2-.41" fill="#c52328"/>
                                <path d="M283.29,346.38c-.21,0-.27.07-.41.27s.07.27.27.4.27-.06.41-.27-.07-.4-.27-.4" fill="#c52328"/>
                                <path d="M283.15,346.92c-.2,0-.27.07-.27.27s.07.27.27.27.27-.07.27-.27-.07-.27-.27-.27" fill="#c52328"/>
                                <path d="M283.15,347.53c-.2,0-.27.2-.27.4s.2.27.41.27.27-.2.27-.4-.21-.27-.41-.27" fill="#c52328"/>
                                <path d="M283.15,348.27c-.2,0-.27.2-.27.4s.2.27.41.27.27-.2.27-.4-.21-.41-.41-.27" fill="#c52328"/>
                                <path d="M283.29,348.88a.28.28,0,0,0-.21.4.29.29,0,0,0,.41.2.28.28,0,0,0,.2-.4.24.24,0,0,0-.4-.2" fill="#c52328"/>
                                <path d="M283.49,349.35c-.2.07-.2.27-.2.4s.27.21.4.21.2-.27.2-.41-.2-.2-.4-.2" fill="#c52328"/>
                                <path d="M283.69,350c-.2.06-.2.27-.07.4s.27.2.41.07.2-.27.07-.41-.21-.2-.41-.06" fill="#c52328"/>
                                <path d="M284,350.56a.34.34,0,1,0,.41.54.38.38,0,0,0,.06-.47c-.13-.2-.27-.27-.47-.07" fill="#c52328"/>
                                <path d="M284.37,351c-.07.06-.07.27,0,.47s.27.07.47,0a.53.53,0,0,0,0-.47.25.25,0,0,0-.47,0" fill="#c52328"/>
                                <path d="M284.77,351.44a.55.55,0,0,0,0,.48.61.61,0,0,0,.47,0,.34.34,0,0,0-.47-.48" fill="#c52328"/>
                                <path d="M285.24,351.85c-.06.07-.06.4.07.47s.41.07.47-.07.07-.4-.06-.47-.34,0-.48.07" fill="#c52328"/>
                                <path d="M285.78,352.25c-.06.21,0,.41.07.41s.41,0,.41-.07,0-.4-.07-.4-.41-.14-.41.06" fill="#c52328"/>
                                <path d="M286.26,352.52c-.07.21,0,.41.2.41s.4,0,.4-.2,0-.41-.2-.41-.27,0-.4.2" fill="#c52328"/>
                                <path d="M286.86,352.73c0,.2.07.4.21.4s.4-.07.4-.2-.07-.41-.2-.41-.41.14-.41.21" fill="#c52328"/>
                                <path d="M287.4,352.93c0,.2.07.27.27.4s.27-.06.41-.27-.07-.27-.27-.4a.38.38,0,0,0-.41.27" fill="#c52328"/>
                                <path d="M288.08,353.06c0,.21.07.27.27.27s.27-.06.27-.27-.07-.27-.27-.27-.27.07-.27.27" fill="#c52328"/>
                                <path d="M288.69,353.13a.27.27,0,1,0,.27-.27c-.21-.13-.27.07-.27.27" fill="#c52328"/>
                                <path d="M289.3,353.06c0,.21.2.27.4.27s.27-.2.27-.4-.2-.27-.4-.27a.41.41,0,0,0-.27.4" fill="#c52328"/>
                                <path d="M289.9,352.93c0,.2.21.27.41.2s.27-.2.2-.4a.28.28,0,0,0-.4-.21c-.21.14-.34.34-.21.41" fill="#c52328"/>
                                <path d="M290.51,352.86c.07.2.27.2.41.2s.2-.27.2-.4-.27-.2-.41-.2a.25.25,0,0,0-.2.4" fill="#c52328"/>
                                <path d="M291.05,352.66c.07.2.27.2.41.07s.2-.27.06-.41-.27-.2-.4-.07-.14.21-.07.41" fill="#c52328"/>
                                <path d="M291.59,352.32a.38.38,0,0,0,.47.07.34.34,0,1,0-.4-.54.29.29,0,0,0-.07.47" fill="#c52328"/>
                                <path d="M292.06,352a.32.32,0,0,0,.48,0,.32.32,0,0,0,0-.47.33.33,0,0,0-.48,0,.64.64,0,0,0,0,.47" fill="#c52328"/>
                                <path d="M292.6,351.58a.34.34,0,1,0,0-.48c-.2.14-.13.34,0,.48" fill="#c52328"/>
                                <path d="M293,351.17c.07.07.4.07.47-.07s.07-.4-.07-.47-.4-.07-.47.07a.33.33,0,0,0,.07.47" fill="#c52328"/>
                                <path d="M293.41,350.63c.21.07.41,0,.41-.07s0-.4-.07-.4-.4,0-.4.07-.14.33.06.4" fill="#c52328"/>
                                <path d="M293.68,350.16c.21.07.41,0,.41-.2s0-.41-.2-.41-.41,0-.41.2a.47.47,0,0,0,.2.41" fill="#c52328"/>
                                <path d="M294,349.55c.21.07.41,0,.41-.2s0-.41-.2-.41-.41,0-.41.21,0,.4.2.4" fill="#c52328"/>
                                <path d="M294.09,348.94c.2,0,.27-.06.4-.27s-.06-.27-.27-.4-.27.07-.4.27c.07.2.13.4.27.4" fill="#c52328"/>
                                <path d="M294.29,348.34c.2,0,.27-.07.41-.27s-.07-.27-.27-.41-.27.07-.41.27.07.41.27.41" fill="#c52328"/>
                                <path d="M294.29,347.73a.27.27,0,1,0-.27-.27c-.07.2.14.27.27.27" fill="#c52328"/>
                                <path d="M294.29,347.12c.2,0,.27-.2.27-.4s-.2-.27-.4-.27-.27.2-.27.4a.42.42,0,0,0,.4.27" fill="#c52328"/>
                                <path d="M294.29,346.58c.2,0,.27-.2.2-.4s-.2-.27-.4-.21-.27.21-.2.41.2.2.4.2" fill="#c52328"/>
                                <path d="M294.09,346a.29.29,0,0,0,.2-.4.3.3,0,0,0-.4-.21.31.31,0,0,0-.21.41.29.29,0,0,0,.41.2" fill="#c52328"/>
                                <path d="M293.89,345.36c.2-.06.2-.27.06-.4s-.27-.2-.4-.07-.2.27-.07.41a.4.4,0,0,0,.41.06" fill="#c52328"/>
                                <path d="M293.55,344.82c.2-.06.2-.27.07-.47s-.27-.2-.48-.07-.2.27-.06.48.4.2.47.06" fill="#c52328"/>
                                <path d="M293.28,344.22a.34.34,0,0,0-.47-.48.32.32,0,0,0,0,.48c.2.13.4.2.47,0" fill="#c52328"/>
                                <path d="M292.87,343.88a.53.53,0,0,0,0-.47c-.06-.07-.27-.07-.47,0s-.07.27,0,.47.41.07.47,0" fill="#c52328"/>
                                <path d="M292.47,343.34c.07-.07.07-.27-.07-.47s-.27-.07-.47.06-.07.27.07.48.4.13.47-.07" fill="#c52328"/>
                                <path d="M292,342.93a.52.52,0,0,0-.07-.47.49.49,0,0,0-.47.07.53.53,0,0,0,.06.47.29.29,0,0,0,.48-.07" fill="#c52328"/>
                                <path d="M291.46,342.66c.06-.2,0-.4-.21-.4s-.4,0-.4.2,0,.41.2.41.34,0,.41-.21" fill="#c52328"/>
                                <path d="M290.85,342.33c.07-.21,0-.41-.2-.41s-.41,0-.41.2,0,.41.2.41.41,0,.41-.2" fill="#c52328"/>
                                <path d="M290.38,342.12c0-.2-.07-.4-.21-.4s-.4.07-.4.2.07.41.2.41.34,0,.41-.21" fill="#c52328"/>
                                <path d="M292.06,351.44a.45.45,0,0,0-.06.27c-.07,0-.21,0-.27.07l-.21.2h-.27c-.06.07-.2.07-.2.21h-.27a.21.21,0,0,0-.2.2c-.07,0-.2-.07-.27,0h0a.21.21,0,0,0-.2.2.41.41,0,0,0-.27-.07h0c-.07,0-.21.07-.27.07a.41.41,0,0,0-.27-.07h0a.42.42,0,0,0-.28.07c-.06-.07-.2-.07-.27-.07a.41.41,0,0,0-.27.07c-.06-.07-.2-.07-.27-.2a.41.41,0,0,0-.27.07c-.06-.07-.06-.21-.27-.21h-.27c-.06-.06-.06-.2-.2-.2h-.27a.21.21,0,0,0-.2-.2.2.2,0,0,0-.27,0,.35.35,0,0,0-.2-.27.41.41,0,0,0-.27-.07.36.36,0,0,0-.21-.27c-.06-.07-.2-.07-.27-.07a.45.45,0,0,0-.06-.27.28.28,0,0,1-.34-.34c-.07-.06-.2-.06-.2-.2s0-.2-.07-.27-.07-.2-.2-.2v-.27a.22.22,0,0,0-.21-.2v-.27a.21.21,0,0,0-.2-.21.2.2,0,0,0,0-.27.39.39,0,0,0-.2-.27c.07-.06.07-.2.07-.27s-.07-.2-.07-.27.07-.2.07-.27a.41.41,0,0,0-.07-.27c.07-.06.07-.2.07-.27a.41.41,0,0,0-.07-.27c.07-.06.07-.2.2-.27v-.27c.07-.06.2-.06.2-.2v-.27a.22.22,0,0,0,.21-.2c0-.14.06-.21,0-.27a.22.22,0,0,0,.2-.21c.07-.06.07-.2.07-.27a.36.36,0,0,0,.27-.2.45.45,0,0,0,.06-.27c.07,0,.21-.07.27-.07s.07-.2.07-.27a.45.45,0,0,0,.27-.06c.07-.07.07-.21.2-.27s.21,0,.27-.07.21-.07.21-.2h.27a.22.22,0,0,0,.2-.21h.27a.21.21,0,0,0,.2-.2c.07,0,.2.07.27,0a.37.37,0,0,0,.27-.2.39.39,0,0,0,.27.07c.07,0,.2-.07.27-.07a.56.56,0,0,0,.54,0,.41.41,0,0,0,.27.07c.07,0,.21,0,.27-.07s.21.07.27.2h.27c.07.07.07.2.21.2h.27a.21.21,0,0,0,.2.21c.13,0,.2.06.27,0a.22.22,0,0,0,.2.2.39.39,0,0,0,.27.07.39.39,0,0,0,.2.27.59.59,0,0,0,.27.06c0,.07.07.21.07.27a.39.39,0,0,0,.27.07h0a.39.39,0,0,0,.07.27c.07.07.2.07.27.2v.07a.39.39,0,0,0,.07.27c.06.07.06.2.2.2v.27a.18.18,0,0,0,.2.21v.27a.18.18,0,0,0,.2.2v.27a.22.22,0,0,0,.21.2.71.71,0,0,1-.07.21v.06c0,.07.07.21.07.27a.41.41,0,0,0-.07.27h0c0,.07,0,.21.07.27a.41.41,0,0,0-.07.27h0c0,.07,0,.21.07.27s-.07.21-.21.27v.27c-.06.07-.2.07-.2.21h0v.27a.21.21,0,0,0-.2.2h0v.27a.21.21,0,0,0-.2.2.39.39,0,0,0-.07.27.37.37,0,0,0-.27.2h0a.41.41,0,0,0-.07.27c-.07,0-.2.07-.27.07a.39.39,0,0,0-.07.27c.14.2,0,.2,0,.27" fill="#fbb615"/>
                                <path d="M289.77,342.06c0-.21-.07-.27-.27-.41s-.27.07-.41.27.07.27.27.41.41-.07.41-.27" fill="#c52328"/>
                                <path d="M291.05,347.86h0c-.07,0-.07-.06,0,0v-.2a.48.48,0,0,0-.07-.2c0-.07-.06-.07-.2-.2v.06a2.5,2.5,0,0,0-.07.41c0,.2-.2.27-.27.47a1.23,1.23,0,0,1-.27.47c0,.07-.06.21,0,.27a1,1,0,0,0,.21.41,4.34,4.34,0,0,0,.4.81,2.86,2.86,0,0,1,.2.61v.2a.21.21,0,0,1-.2.2c-.13,0-.07.07-.2.07s-.07,0-.07.07v.06h.41c.06,0,.06-.06.2-.2a.62.62,0,0,1,.07-.2c0-.07.06-.07,0-.2s-.07-.07-.07-.21a3.09,3.09,0,0,0-.2-.6,3.9,3.9,0,0,0-.41-1v-.27a3.47,3.47,0,0,1,.47-.47c0,.07.07-.13.07-.34Z" fill="#010101"/>
                                <path d="M292.06,351.24c-.06-.07-.2-.07-.2-.27a11.68,11.68,0,0,1-.47-1.49v-.4c0-.14.2-.47.27-.88a2.75,2.75,0,0,0,.2-.81c0-.2-.2-.81-.2-1a4.58,4.58,0,0,0-.61.4l-.2.07h0v.07c.07.07.07.2.2.27s.07.07.07.2v.2h0v-.2h0c0,.2-.07.47-.07.61h0a2.31,2.31,0,0,0-.07.47,1,1,0,0,0,0,.61c.07.2.21.4.41.81s.27.67.4.88a.2.2,0,0,1,0,.27.06.06,0,0,1-.06.06s0,.07.06.07h.27l.41-.2c-.07.4-.27.27-.41.27" fill="#010101"/>
                                <path d="M290.78,344h0c-.07,0-.07-.07,0,0s0-.07,0,0h0m1.08,2c0-.06-.07-1.21-.07-1.42s0-.06-.06-.2-.21-.07-.41-.2a.57.57,0,0,1-.2-.41.69.69,0,0,0-.07-.4.35.35,0,0,0-.27-.2c-.07,0-.07,0-.07.06v.27a1.43,1.43,0,0,0,.07.27s.07.07.07,0H291a.06.06,0,0,0-.06.07c0,.07.06.07.06.2s0,.21-.06.21a.39.39,0,0,1-.21.06.38.38,0,0,0-.27.21,1.3,1.3,0,0,0-.2.27c-.07.2-.2.27-.27.47a5.21,5.21,0,0,1-.61,1c-.06.07-.2.07-.27.07s-.27.27-.27.27h0a.21.21,0,0,1,.2-.2.35.35,0,0,1,.21-.07v.2l-.21.21a.54.54,0,0,0,.21-.07.62.62,0,0,1,.07-.2h0c.06-.21.47-.68.47-.88s.2-.27.27-.41a2.46,2.46,0,0,0,.2-.27h.07c.07.2.2.2.2.41a1.73,1.73,0,0,1,0,.61v.47l.41-.41.06-.06c.07-.07.27-.21.27-.27V345a5.7,5.7,0,0,0-.06-.61h0a5.7,5.7,0,0,1,.06.61v.61c0,.13-.06.2-.27.27l-.06.06a3.42,3.42,0,0,1-.48.41c-.06.07-.2.07-.27.07s-.06,0-.2.06a.22.22,0,0,0-.2.21.32.32,0,0,1,.2-.07.07.07,0,0,1,.07.07.53.53,0,0,1-.07.2c0,.07-.07.07-.2.07h-.07v.06a.72.72,0,0,0,.41-.2l.2-.2.2-.2.2-.07a1.45,1.45,0,0,0,.48-.27l.06-.07c.21-.07.21-.07.27-.2C291.86,346.18,291.86,346.18,291.86,346Z" fill="#010101"/>
                                <path d="M288.15,346.78c-.07-.06-.07-.06-.21-.06h-.2a.29.29,0,0,0-.2-.07c-.14,0-.27-.27-.41-.41s-.2-.06-.2-.2-.27-.68-.4-.68,0-.06-.21-.2-.27-.2-.27-.27v-.2h0s.07.07.07,0a.07.07,0,0,0,.07-.07c0-.07.07-.07.07-.2v-.2c0-.07,0-.07-.07-.07s-.2-.07-.27,0-.07.2-.2.27-.07.27-.07.4-.2.21-.27.21-.2.06-.27.2a2.3,2.3,0,0,0-.07.41c0,.2.07.67.07.81v.2h0a.29.29,0,0,1,.07.2c.06.07.06.07.4.27l.41.2h.06a.71.71,0,0,1,.21.07.31.31,0,0,0,.2.07h.27l-.27-.07v-.27h.2a.07.07,0,0,0-.07-.07h-.27l-.2-.06c-.27-.21-.61-.41-.67-.48s-.07-.06-.07-.2v-.74h0v.27h0a.29.29,0,0,0,.2.07c.07,0,.2,0,.2-.07l.07-.07h0a.07.07,0,0,1,.07.07.07.07,0,0,0,.07.07h.2v-.21c0-.13-.07-.06-.07-.2s-.07-.07-.07-.07v-.07a.31.31,0,0,1,.07.21c.07.07.07.2.2.27s.27.27.27.4.07.07.21.21a1.57,1.57,0,0,0,.4.27c.07.06.2.06.2.2a.07.07,0,0,0,.07.07.06.06,0,0,1,.07.06V347s-.07-.07,0-.07h.2c.27-.14.34-.07.41-.14s.06.07,0,0h0" fill="#010101"/>
                                <path d="M285.38,346.45h0c-.07,0-.07.13-.14.33a3.76,3.76,0,0,1-.2.61v.68c0,.4.07.6.07.87s.07.68.07,1,.06.67.06.81v.2s0,.07-.06.07.2.2.2.2.07-.07.07-.2v-2.57a1.47,1.47,0,0,1,.06-.47.39.39,0,0,1,.07-.27.07.07,0,0,1,.07.07,1.26,1.26,0,0,0,.2.47,1.21,1.21,0,0,1,.2.61c0,.13.07.2.07.27v.27c0,.2-.07.2-.07.4v.47a4.29,4.29,0,0,1-.06.48c0,.06,0,.06-.07.2V351c0,.06.07.06.07.06s0,.07.06.07h.27s0-.07-.06-.07-.07-.06-.21-.06-.06-.07-.06-.21v-.27a1.46,1.46,0,0,1,.2-.6c.07-.27.07-.48.2-.61a2.3,2.3,0,0,0,.07-.41c0-.2-.07-.4-.07-.67a3.43,3.43,0,0,0-.2-1c0-.21-.07-.41-.07-.61l-.4-.2c-.14.13-.27.13-.34,0" fill="#010101"/>
                                <path d="M286.19,345.77h0c-.07-.07-.07-.13-.14-.13h0a.06.06,0,0,0-.06.06c-.07.07-.07.07-.21.07s-.06,0-.2-.07V346c0,.07,0,.07.07.21a2,2,0,0,0,.61.4c0-.4.06-.81.06-.88-.06.14-.13.14-.13.07" fill="#010101"/>
                                <path d="M287.47,347.32h0m1.62-.4c-.06,0-.06.07-.27.2a.49.49,0,0,0-.27.2l-.27.07s-.2,0-.2.07-.07.07-.2.07-.07-.07-.07-.07v-.2l.07-.07c.06,0,.06-.07.06-.07s0-.2-.06-.2-.07,0-.21,0l-.2.2v.2c0,.14,0,0,.07.07a.48.48,0,0,1-.07.2c0,.07-.07.07-.2.07s-.2-.07-.27-.2a1,1,0,0,0-.41-.27.73.73,0,0,0-.4-.2l.07-.07h0l-.07.07H286s-.07-.07-.07,0l.07.06h.2a1,1,0,0,1,.27.07,1.05,1.05,0,0,0,.4.27,1.27,1.27,0,0,1,.41.27.29.29,0,0,1,.07.2v.41a.79.79,0,0,0,.06.4,1,1,0,0,1,.07.41c0,.13-.07.27-.07.47a1.27,1.27,0,0,0-.06.47,4.29,4.29,0,0,0,.06.48v.27c0,.06-.2.06-.2.06v.07a.29.29,0,0,1,.2-.07c.14,0,.07-.06.07-.06v-.88c0-.07.07-.27.07-.41s.07-.2.07-.27h0v-.06s.06.06.06.27v.47a1,1,0,0,0,.21.4c.06.07.06.27.2.27v.21c0,.13-.07,0-.07,0v.06h.14l.06-.06a.61.61,0,0,0-.06-.21,1,1,0,0,1-.21-.47v-1a1.05,1.05,0,0,0-.2-.61c-.13-.2-.07-.27-.07-.47a.39.39,0,0,1,.07-.27c0-.07.2-.07.41-.2s.06-.07.2-.07a.48.48,0,0,0,.2-.07c.07,0,.07-.07.2-.2l.21-.2H289l.07-.07c.21.2.07.13,0,0,.07.13,0,.13,0,0h0Z" fill="#010101"/>
                                <path d="M272.28,347.73a3.58,3.58,0,1,0-7.16,0,3.6,3.6,0,0,0,3.58,3.58,3.53,3.53,0,0,0,3.58-3.58" fill="#010101"/>
                                <path d="M265.86,347.73a.27.27,0,1,1-.54,0c0-.07.21-.27.27-.27.21-.14.27.07.27.27" fill="#fbb615"/>
                                <path d="M265.93,348.27c0,.2-.07.4-.27.4s-.4-.06-.4-.27.06-.4.27-.4.4.07.4.27" fill="#fbb615"/>
                                <path d="M266.07,348.74c.06.2,0,.41-.21.41s-.4,0-.4-.21,0-.4.2-.4.41,0,.41.2" fill="#fbb615"/>
                                <path d="M266.34,349.28c.06.2.06.41-.07.47s-.41.07-.47-.06-.07-.41.06-.48.41-.13.48.07" fill="#fbb615"/>
                                <path d="M266.67,349.62a.53.53,0,0,1,0,.47c-.06.07-.27.07-.47,0a.53.53,0,0,1,0-.47c.07-.2.34-.07.47,0" fill="#fbb615"/>
                                <path d="M267.08,350c.2.06.2.27.07.47s-.27.2-.48.07-.2-.27-.06-.48c.13-.06.33-.2.47-.06" fill="#fbb615"/>
                                <path d="M267.55,350.23c.2.06.2.27.2.4s-.27.2-.4.2-.2-.27-.2-.4.2-.27.4-.2" fill="#fbb615"/>
                                <path d="M268.09,350.43c.2,0,.27.2.27.4s-.2.27-.4.27-.27-.2-.27-.4.2-.34.4-.27" fill="#fbb615"/>
                                <path d="M268.56,350.43a.28.28,0,0,1,.27.27.27.27,0,1,1-.27-.27" fill="#fbb615"/>
                                <path d="M269.17,350.43c.2,0,.41.07.41.27s-.07.4-.27.4-.41-.06-.41-.27a.29.29,0,0,1,.27-.4" fill="#fbb615"/>
                                <path d="M269.64,350.23c.21-.07.41,0,.41.2s0,.4-.2.4-.41,0-.41-.2,0-.27.2-.4" fill="#fbb615"/>
                                <path d="M270.05,350c.2-.06.41-.06.47.07s.07.41-.06.47-.41.07-.48-.06a.59.59,0,0,1,.07-.48" fill="#fbb615"/>
                                <path d="M270.59,349.75a.61.61,0,0,1,.47,0,.55.55,0,0,1,0,.48.61.61,0,0,1-.47,0c-.2-.21-.2-.41,0-.48" fill="#fbb615"/>
                                <path d="M270.86,349.35c.07-.2.27-.2.47-.07s.21.27.07.47-.27.21-.47.07-.14-.34-.07-.47" fill="#fbb615"/>
                                <path d="M271.2,348.88c.07-.21.27-.21.4-.21s.21.27.21.41-.27.2-.41.2a.24.24,0,0,1-.2-.4" fill="#fbb615"/>
                                <path d="M271.4,348.34c0-.21.2-.27.41-.27s.27.2.27.4-.21.27-.41.27-.4-.2-.27-.4" fill="#fbb615"/>
                                <path d="M271.4,347.86a.28.28,0,0,1,.27-.27.29.29,0,0,1,.27.27.28.28,0,0,1-.27.27c-.13.14-.27-.06-.27-.27" fill="#fbb615"/>
                                <path d="M271.4,347.26c0-.21.07-.41.27-.41s.41.07.41.27-.07.41-.27.41-.41-.07-.41-.27" fill="#fbb615"/>
                                <path d="M271.27,346.78c-.07-.2,0-.4.2-.4s.4,0,.4.2,0,.41-.2.41-.4-.07-.4-.21" fill="#fbb615"/>
                                <path d="M271,346.24a.53.53,0,0,1,.06-.47.51.51,0,0,1,.48.07.52.52,0,0,1-.07.47.3.3,0,0,1-.47-.07" fill="#fbb615"/>
                                <path d="M270.59,345.77a.33.33,0,1,1,.47-.47c.07.06.07.27,0,.47s-.27.2-.47,0" fill="#fbb615"/>
                                <path d="M270.18,345.43a.34.34,0,1,1,.48-.07c-.07.07-.41.21-.48.07" fill="#fbb615"/>
                                <path d="M269.64,345.16c-.2-.07-.2-.27-.2-.4s.27-.21.41-.21.2.27.2.41-.2.27-.41.2" fill="#fbb615"/>
                                <path d="M269.17,345c-.2,0-.27-.2-.2-.41s.2-.27.4-.2.27.2.21.41-.34.27-.41.2" fill="#fbb615"/>
                                <path d="M268.56,345c-.2,0-.27-.2-.27-.41s.21-.27.41-.27.27.21.27.41-.2.27-.41.27" fill="#fbb615"/>
                                <path d="M268.09,345c-.2,0-.4-.07-.4-.27s.06-.41.27-.41.4.07.4.27-.2.34-.27.41" fill="#fbb615"/>
                                <path d="M267.48,345.23c-.2.07-.4,0-.4-.2s0-.41.2-.41.41,0,.41.2a.31.31,0,0,1-.21.41" fill="#fbb615"/>
                                <path d="M267.08,345.43a.49.49,0,0,1-.47-.07.53.53,0,0,1,.06-.47.51.51,0,0,1,.48.07.38.38,0,0,1-.07.47" fill="#fbb615"/>
                                <path d="M266.67,345.77a.33.33,0,1,1,0-.47.25.25,0,0,1,0,.47" fill="#fbb615"/>
                                <path d="M266.34,346.24a.34.34,0,1,1-.07-.47c.2.14.2.27.07.47" fill="#fbb615"/>
                                <path d="M266.07,346.78c-.07.21-.27.21-.41.21s-.2-.27-.2-.41.27-.2.4-.2.27.2.21.4" fill="#fbb615"/>
                                <path d="M270.05,350l-.2.2h-.27a.22.22,0,0,0-.21.2c-.06,0-.2-.07-.27,0a.73.73,0,0,0-.2.07.56.56,0,0,0-.54,0c-.07-.07-.07-.07-.2-.07h-.27c0-.07-.07-.07-.2-.2h-.27a.22.22,0,0,0-.21-.2.31.31,0,0,0-.2-.07.62.62,0,0,0-.07-.2.31.31,0,0,0-.2-.07.39.39,0,0,0-.07-.27l-.2-.2v-.27a.22.22,0,0,0-.2-.21v-.27a.73.73,0,0,0-.07-.2.56.56,0,0,0,0-.54c.07-.07.07-.07.07-.2v-.27c.07,0,.07-.07.07-.2s.06-.21,0-.27a.22.22,0,0,0,.2-.21c.07-.06.07-.2.07-.27a.81.81,0,0,0,.2-.06c.07-.07.07-.07.07-.21s.06,0,.2-.06.2-.07.2-.21h.2a.33.33,0,0,0,.27-.2H268c.06,0,.2-.07.27-.07a.31.31,0,0,0,.2.07.39.39,0,0,0,.27-.07c.07.07.07.07.2.07h.27c0,.07.07.07.2.2s.21.07.27,0a.22.22,0,0,0,.21.21.26.26,0,0,1,.33.33.42.42,0,0,0,.28.07h0c0,.07,0,.2.06.2s.07.21.21.21V347a.18.18,0,0,0,.2.2c0,.07-.07.2-.07.27s.07.2.07.27a.39.39,0,0,0-.07.27h0c0,.07,0,.2.07.2s-.07.07-.07.2v.27c-.07,0-.07.07-.2.21h0v.27a.21.21,0,0,0-.2.2.39.39,0,0,0-.07.27.71.71,0,0,0-.21.07c-.06.06-.06.06-.06.2.13-.07.06-.07-.07.07" fill="#c52328"/>
                                <path d="M265.93,347.26c0,.2-.2.27-.4.2s-.27-.2-.21-.41.21-.27.41-.2.2.2.2.41" fill="#fbb615"/>
                                <path d="M268.77,346.24h0c0,.14-.07.14,0,0-.07.14-.07,0,0,0m1.41-.2H270c-.13,0-.07-.07-.07-.07s0,.07.07.07a.07.07,0,0,0-.07.07.39.39,0,0,0-.27.2,2,2,0,0,1-.4.27.2.2,0,0,1-.27,0c-.07-.07,0-.07,0-.2h-.07c.2,0,.07-.07.07-.2s-.07-.21-.2-.21-.07-.06-.21,0-.06.21-.06.21.06.06.2.2a.29.29,0,0,1,.07.2c0,.14,0,.07-.07.07h-.2c-.14,0-.07-.07-.21-.07s-.2,0-.27-.07-.06-.06-.2-.06a.18.18,0,0,1-.2-.21.35.35,0,0,1-.27-.2c-.07-.13-.07-.07-.2-.07h.06a.07.07,0,0,1,.07.07v.07h.07l.2.2.2.2a.31.31,0,0,0,.21.07c.06.07.2.07.27.2s.2.07.27.07a.32.32,0,0,1,.06.2v.41c0,.07-.06.27-.06.4a.41.41,0,0,0-.07.27V349a.74.74,0,0,1-.07.41c-.07.13-.07.27-.2.4v.2h.2c-.07,0-.07,0-.07-.06v-.21c0-.13.07-.2.21-.27s.06-.2.06-.47v-.47c0-.07,0-.07.07-.07v0h0v.2c0,.14.07.21.07.27v.61c0,.2-.07.27-.07.41V350a.29.29,0,0,0,.2.07h.07l-.07-.07-.06-.06v-.21c0-.13.06-.27.06-.47v-.4a4.65,4.65,0,0,1-.06-.48c-.07-.2,0-.27,0-.4s.06-.2.06-.27v-.54c0-.07.07-.07.21-.2s.27-.21.47-.27.27-.21.4-.27c.07-.41.14-.41.27-.41s.07,0,.21,0C270.25,346,270.25,346,270.18,346Z" fill="#010101"/>
                            </g>
                            <g clip-path="url(#clip-path)">
                                <path d="M294.36,312.75,284,309l-6.62-22.42H222.65L216,309l-4.19,1.48L211,316.4c.07.2.07.4.2.47L202.86,318h0c-5.2,11.61-15.8,42.61-15.8,104.06,0,0,.07,1.89.2,5.13l62.87,3.58,62.53-3.58c.07-3.17.2-5.13.2-5.13C312.79,340.84,294.36,312.75,294.36,312.75Z" fill="none"/>
                                <path d="M211.1,317c-.07-.2-.2-.4-.2-.47l.81-5.87-6.22,2.22a47.37,47.37,0,0,0-2.76,5.41h0Z" fill="#282828"/>
                            </g>
                            <g clip-path="url(#clip-path)">
                                <path d="M297.06,318.15l-8.37-1.08c.06-.2.2-.4.2-.47l-.81-5.88-4.19-1.48-6.61-22.42H222.44l-6.61,22.42L205.49,313S187,341,187,422.35c0,0,.07,1.89.2,5.13l62.87,3.58,62.53-3.58c.07-3.18.2-5.13.2-5.13C312.79,360.63,302.26,329.63,297.06,318.15Z" fill="none"/>
                                <path d="M288.69,317l8.37,1.08a48.41,48.41,0,0,0-2.77-5.4l-6.21-2.23.81,5.88A2.11,2.11,0,0,1,288.69,317Z" fill="#282828"/>
                            </g>
                            <path d="M246.48,345h7.23V329.7h0a27.82,27.82,0,0,0-7.23,13.5Z" fill="#505050"/>
                            <path d="M256.14,326.66c-.47.61-4.32.41-5.34,2.43-2.29,4.53-4.38,12.22-4.38,13.71v.47a27.28,27.28,0,0,1,7.22-13.5c1.28-1.42,3.58-.41,4.73-1.22,1.62-1.08,1.69-3.71,1.69-3.71a51.71,51.71,0,0,0,12.22-6.42c.07-.07-6.21,3.38-12,5.81A11.21,11.21,0,0,0,256.14,326.66Z" fill="#1c1c1c"/>
                            <path d="M212.45,304.85,211,316.46c.07.21.07.41.21.48l.81,1.68c3.51,6.69,11.27,17.29,11.27,17.29,9-1.08,16.55-11.07,16.55-11.07C226,320.31,212.45,304.85,212.45,304.85Z" fill="#333"/>
                            <path d="M288.69,317c.06-.2.2-.4.2-.47l-1.49-11.61s-13.5,15.53-27.41,20c0,0,7.63,10,16.54,11.08,0,0,7.9-10.6,11.28-17.29Z" fill="#333"/>
                            <path d="M216,308.63l1.08-3.71h-4.73C212.45,304.85,213.73,306.33,216,308.63Z" fill="#1c1c1c"/>
                            <path d="M248.64,334.43l-8.77-9.53a32.38,32.38,0,0,1-2.91,3.18c3.18,1.08,8.1,6,11.41,7.09A3.79,3.79,0,0,0,248.64,334.43Z" fill="#1c1c1c"/>
                            <path d="M246.48,342.73a46,46,0,0,1,1.89-7.63,12.07,12.07,0,0,1-3.71-2l1.69,10v-.41l.13.07Z" fill="#0d0e0e"/>
                            <path d="M287.4,304.85h-4.72l1.08,3.71C286.05,306.33,287.4,304.85,287.4,304.85Z" fill="#1c1c1c"/>
                        </g>
                    </g>
                </svg>
        return kid;     
    }
   
   

    render() {
     
        return (
            // <>
            // {
                this.getRobChar()
        //     }
        // </>
        )
    }
}
