import React from 'react';
import './dreamCirclePage.scss';
import { authenticationService, childrenService, storyService, followesService, newsFeedService, behavoursService } from '../../services';
import { Grid, Box, Typography, Button, Link, IconButton } from '@material-ui/core';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import ButtonRound from '../components/buttonRound/buttonRound';
import ObjectTable from '../components/objectTable/objectTable';
import HeaderNav from '../components/header/headerNav';
import ModalStoryList from '../components/modal/modalStoryList';
import ModalStory from '../components/modal/modalStory';
import ModalBehaviourPlan from '../components/modal/modalBehaviourPlan';
import { userRole, dreamCircle } from '../../enums';
// import Layout from '../components/layout/layout';
import WayDreamCircle from '../components/wayDreamCircle/wayDreamCircle';
import { Notifier } from '../components/notifier/notifier';
import { Common } from '../../common';

const avatar = Common.getAvadarDefaut();
const titleIcon = require('../../assets/images/menuSelected.svg');
const refreshIcon = require('../../assets/images/refresh.png')
const logo = "backgroud-fish";

const empathyCircleRed = require('../../assets/images/dreamCircles/empathy_red.png');
const cooperationCircleRed = require('../../assets/images/dreamCircles/cooperation_red.png');
const spiritualCircleRed = require('../../assets/images/dreamCircles/spiritual_red.png');
const selfCircleRed = require('../../assets/images/dreamCircles/self_red.png');
const thinkingCircleRed = require('../../assets/images/dreamCircles/thinking_red.png');

const empathyCircleYlw = require('../../assets/images/dreamCircles/empathy_ylw.png');
const cooperationCircleYlw = require('../../assets/images/dreamCircles/cooperation_ylw.png');
const spiritualCircleYlw = require('../../assets/images/dreamCircles/spiritual_ylw.png');
const selfCircleYlw = require('../../assets/images/dreamCircles/self_ylw.png');
const thinkingCircleYlw = require('../../assets/images/dreamCircles/thinking_ylw.png');


export interface Props {
    className: any,
    history: any,
    location: any,
    match: any
}

export interface State {
    data: any,
    myAllStars: Array<any>,
    myAllStories: Array<any>,
    myStars: Array<any>,
    myStories: Array<any>,
    uncompletedStories: Array<any>,
    isLoading: boolean,
    userId: number,
    modalStory: boolean;
    modalBehaviourPlan: boolean;
    titleSecond: string,
    childInfo: any,
    dreamCircleList: Array<any>,
    dreamCircleSelect: any,
    anyStarSelect: boolean,
    starNameSelect: string,
    storyDetails: any;
    snackBarStatus: any;
    myBehavioursPlan: Array<any>;
}

let currentUser;
let childUser;

class DreamCirclePage extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: null,
            date: new Date(),
            variant: 'error'
        }

        this.state = {
            data: "",
            myAllStars: [],
            myAllStories: [],
            myStars: [],
            myStories: [],
            myBehavioursPlan: [],
            uncompletedStories: [],
            isLoading: true,
            userId: 0,
            modalStory: false,
            modalBehaviourPlan: false,
            titleSecond: "",
            childInfo: "",
            dreamCircleList: [],
            dreamCircleSelect: null,
            anyStarSelect: false,
            starNameSelect: "",
            storyDetails: null,
            snackBarStatus: stateObject,
        }
    }

    async componentWillMount() {
        this.setState({ isLoading: true });
        currentUser = authenticationService.currentUserValue.user;
        childUser = authenticationService.isTeamMemberChildValue;
        this.setState({ userId: this.props.match.params.id })
        if (currentUser) {
            await this.getData(this.props.match.params.id, childUser);
        } else {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
        this.setState({ isLoading: false });
    }

    async getData(id: number, childUser: any) {
        this.getChildDreamCircleNewsFeed(id, childUser);
        await storyService.getIncompleteStoriesByChild(id).then((data: any) => {
            if (data.success) {
                this.setState({ uncompletedStories: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);

        });
        this.getChildStars(id);
        this.getAllStories(id);
    }

    async getChildStoriesByStar(childId: number, starId: number) {
        await childrenService.getChildStoriesByStar(childId, starId).then((data: any) => {
            if (data.success) {
                this.setState({ myStories: data.data });
                this.setState({ myAllStories: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
        });
    }

    async getChildStars(childId: number) {
        childrenService.getChildStars(childId).then((data: any) => {
            if (data.success) {
                this.setState({ myStars: data.data });
                this.setState({ myAllStars: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);

        })
    }

    async getChildLastStarsForDreamCircle(childId: number, behaviourId: number, dreamCircleSelected?:boolean) {
      
        this.setState({ starNameSelect: "" });
        this.setState({ anyStarSelect: false });
        if(!dreamCircleSelected){
                await childrenService.getChildLastStarsForDreamCircle(childId, behaviourId).then(async (data: any) => {
                    if (data.success) {
                        await this.getBehaviourPlan(childId, behaviourId);
                        this.setState({ myStars: data.data });
                
                        this.setState({ dreamCircleList: this.createObjectList(this.state.childInfo.dreamCircle, childId, behaviourId, this.state.myStars,dreamCircleSelected) })
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                })
            }else{
                this.setState({ myStars:this.state.myAllStars });
                this.setState({ myStories:this.state.myAllStories });
                this.setState({ dreamCircleList: this.createObjectList(this.state.childInfo.dreamCircle, childId)});
               
            }
        this.getAllStories(childId);
    }

    async getAllStories(childId: number) {
        await storyService.getUserStories(childId).then((data: any) => {
            if (data.success) {
                this.setState({ myStories: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
        })
    }

    async getChildDreamCircleNewsFeed(childId: number, childUser: any) {
        const value: any = childUser ? childUser.userId : 0;
        await childrenService.getChildDreamCircleNewsFeed(childId, value).then((data: any) => {
            if (data.success) {
                if (data.data.avatarFile === null && childId == value) {
                    this.props.history.push({ pathname: `/avatar/${childId}` });
                } else {
                    this.setState({ childInfo: data.data });
                    this.setState({ dreamCircleList: this.createObjectList(data.data.dreamCircle, data.data.id) })
                }

            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
        })
    }

    selectStar(starId: number) {
        this.state.myStars.forEach(element => {
            if (element.id === starId) {
                element.isSelect = true;
            } else {
                element.isSelect = false;
            }
        });
        this.setState({ myStars: this.state.myStars });
    }

    callbackUpdateChildStories = (star: any) => {
        this.setState({ anyStarSelect: true });
        this.setState({ starNameSelect: star.starName ? star.starName : star.name });
        this.selectStar(star.id);
        this.getChildStoriesByStar(this.state.userId, star.id);
    }
    reSetStories = () => {
        this.setState({ anyStarSelect: false });
        this.setState({ starNameSelect: "" });
        this.selectStar(0);
        this.getAllStories(this.props.match.params.id);
    }
    callbackOpenStory = (story: any) => {
        storyService.getById(story.id).then((data: any) => {
            if (data.success) {
                this.setState({ storyDetails: data.data });
                this.openModal("Edit Story")
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
        });
    }

    closeModal = () => {
        this.setState({ storyDetails: null });
        this.setState({ modalStory: false });
        this.setState({ modalBehaviourPlan: false });
    }

    openModal = (title: string) => {
        this.setState({ titleSecond: title });
        switch (title) {
            case "Behaviour Plan":
                this.setState({ modalBehaviourPlan: true });
                break;

            case "New Story":
            case "Edit Story":
                this.setState({ modalStory: true });
                break;
        }
    }

    openNewsFeed(childInfo: any) {
        if (childUser || currentUser.roleId === userRole.child) {
            const currentUserId: number = childUser ? childUser.userId : currentUser.userId;
            const stateObject = {
                msg: "",
                status: false,
                date: new Date(),
                variant: ''
            }
            if (childInfo.followingThisChild) {
                followesService.unfollowMre(childInfo.id, currentUserId).then((data: any) => {
                    if (data.success) {
                        stateObject.status = true;
                        stateObject.msg = data.message;
                        stateObject.variant = "success";
                        this.getChildDreamCircleNewsFeed(childInfo.id, childUser);
                    } else {
                        //ERROR
                        stateObject.status = true;
                        stateObject.msg = data.message;
                        stateObject.variant = "error";
                    }
                    this.setState({ snackBarStatus: stateObject });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                    }, 2000);
                }, error => {
                    //ERROR
                    console.log(error.message);
                    stateObject.msg = "There is a error on Server!";
                    stateObject.variant = "error";
                    this.setState({ snackBarStatus: stateObject });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                    }, 3000);

                })
            } else {
                const values = {
                    followedId: childInfo.id,
                    followedById: currentUserId
                }
                followesService.followMe(values).then((data: any) => {
                    if (data.success) {
                        stateObject.status = true;
                        stateObject.msg = data.message;
                        stateObject.variant = "success";
                        this.getChildDreamCircleNewsFeed(childInfo.id, childUser);
                    } else {
                        //ERROR
                        stateObject.status = true;
                        stateObject.msg = data.message;
                        stateObject.variant = "error";
                    }
                    this.setState({ snackBarStatus: stateObject });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                    }, 2000);
                }, error => {
                    //ERROR
                    console.log(error.message);
                    stateObject.msg = "There is a error on Server!";
                    stateObject.variant = "error";
                    this.setState({ snackBarStatus: stateObject });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                    }, 3000);
                })
            }
        } else {
            authenticationService.asLoginAsChild(childInfo);
            this.props.history.push({ pathname: `/newsfeed/${childInfo.id}` });
        }
    }



    //About S

    createObjectList(dreamCircleList: Array<any>, childId: number, behaviourId?: number, starList?: Array<any>, dreamCircleSelected?:boolean): Array<any> {
      
        let newDreamCircleList: Array<any> = [];
        dreamCircleList.forEach(element => {
            console.log( dreamCircleSelected, behaviourId && behaviourId === element.id && !dreamCircleSelected)
            if (behaviourId && behaviourId === element.id ) {
                this.setState({ dreamCircleSelect: { name: element.circleName, id: element.id } })
            }else{
                this.setState({ dreamCircleSelect: null})
            }
            let object: any = {
                childId: childId,
                imgCircle: "",
                positionTop: 0,
                positionLeft: 0,
                positionRotate: 0,
                className: "",
                isSelect: behaviourId && behaviourId === element.id  ? true : false,
                // isSelect:  false,
                starList: starList && behaviourId === element.id ? starList : [],
                getStarsByBehaviour: (childId, behaviourId, isSelect) => this.getChildLastStarsForDreamCircle(childId, behaviourId, isSelect),
                getStoriesByStar: (childId, star) => this.callbackUpdateChildStories(star),
                data: null,
            }
            switch (element.id) {
                case dreamCircle.empathy:
                    object.imgCircleRed = empathyCircleRed;
                    object.imgCircleYlw = empathyCircleYlw;
                    object.className = "way-serpent-empathy";
                    object.positionTop = 574;
                    object.positionLeft = 738;
                    object.positionRotate = 15;
                    object.data = element;
                    break;

                case dreamCircle.cooperation:
                    object.imgCircleRed = cooperationCircleRed;
                    object.imgCircleYlw = cooperationCircleYlw;
                    object.className = "way-serpent-cooperation";
                    object.positionTop = 348;
                    object.positionLeft = 721;
                    object.positionRotate = -25;
                    object.data = element;
                    break;

                case dreamCircle.spiritual:
                    object.imgCircleRed = spiritualCircleRed;
                    object.imgCircleYlw = spiritualCircleYlw;
                    object.className = "way-serpent-spiritual";
                    object.positionTop = 187;
                    object.positionLeft = 465;
                    object.positionRotate = -90;
                    object.data = element;
                    break;

                case dreamCircle.selfLove:
                    object.imgCircleRed = selfCircleRed;
                    object.imgCircleYlw = selfCircleYlw;
                    object.className = "way-serpent-self";
                    object.positionTop = 350;
                    object.positionLeft = 217;
                    object.positionRotate = -155;
                    object.data = element;
                    break;

                case dreamCircle.thinkingSmart:
                    object.imgCircleRed = thinkingCircleRed;
                    object.imgCircleYlw = thinkingCircleYlw;
                    object.className = "way-serpent-thinking";
                    object.positionTop = 592;
                    object.positionLeft = 205;
                    object.positionRotate = -180;
                    object.data = element;
                    break;
            }
            newDreamCircleList.push(object);
        });
        return newDreamCircleList;
    }
    checkStoriesNeedToShow() {
        // child Login 
        if (childUser === undefined && +this.props.match.params.id === currentUser.id)
            return false
        else if (childUser === undefined && +this.props.match.params.id !== currentUser.id)
            return true;
        else if (childUser && +this.props.match.params.id !== childUser.userId)
            return true
        else if (childUser && currentUser.roleId !== userRole.child)
            return false
    }

    getBehaviourPlan = (userId: number, behaviourId: number) => {
        behavoursService.getBehaviourPlansByBehaviour(userId, behaviourId).then((data: any) => {
            if (data.success) {
                this.setState({ myBehavioursPlan: data.data })
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
        });
    }
    render() {
        const { className, location } = this.props;
        const { myStars,
            myStories,
            uncompletedStories,
            userId,
            modalStory,
            modalBehaviourPlan,
            titleSecond,
            childInfo,
            dreamCircleList,
            dreamCircleSelect,
            starNameSelect,
            anyStarSelect,
            myBehavioursPlan,
            storyDetails } = this.state;
        // console.log(childUser, currentUser)
        return (
            <div className={className}>
                <div className={"background-top"}></div>
                <div className="background-logo"></div>
                <div className={"background-top-logo"}></div>
                <div className={"background-bottom"}></div>
                <Grid className={"header-" + className}>
                    <HeaderNav className="menu-header" currentUser={currentUser} location={location}  ></HeaderNav>
                </Grid>
                <Box style={{ display: "inline-flex" }}>
                    <Box style={{ width: "1300px", height: "500px", display: "inline-block" }}>
                        {
                            dreamCircleList.map((item, index) => (
                                <WayDreamCircle data={item}></WayDreamCircle>
                            ))
                        }
                        <Box style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: "448px", left: "540px", zIndex: 13 }}>
                            <Box style={{ }}>
                                <img style={{ width: "220px", margin: "auto" }} src={childInfo.avatarFile ? childInfo.avatarFile : avatar} />
                            </Box>
                            <Box style={{ padding: "10px", display: "flex" }}>
                                <span style={{ fontSize: "20px", textTransform: "capitalize", margin: "auto", color: "#183787" }} >{childInfo.firstName + " " + childInfo.lastName}</span>
                            </Box>
                            <Box style={{ padding: "10px", display: childUser && childUser.userId === childInfo.id || currentUser.userId === childInfo.id ? "none" : "flex" }}>
                                <Button
                                    variant="contained"
                                    className={"red-button"}
                                    onClick={() => this.openNewsFeed(childInfo)}
                                >{childUser || currentUser.roleId === userRole.child ? childInfo.followingThisChild ? "Unfollow Me" : "Follow Me" : "Log in as " + childInfo.firstName + " " + childInfo.lastName}</Button>
                            </Box>
                        </Box>
                    </Box>


                    <Box style={{ width: "600px", height: "500px", zIndex: 9 }}>
                        <Box>
                            <Typography className="title">
                                <img src={titleIcon}></img>
                                <span>My Last Stars {dreamCircleSelect ? "for " + dreamCircleSelect.name : ""}</span>
                            </Typography>
                            <Box style={{ width: "80%" }}>
                                <ObjectTable
                                    childInfo={childInfo}
                                    title={dreamCircleSelect ? "My Last Stars for " + dreamCircleSelect.name : "My Last Stars"}
                                    className={"star-table"}
                                    callback={this.callbackUpdateChildStories}
                                    maxShow={5}
                                    data={myStars}
                                    titleCol={[{ name: "date", label: "Date" }, { name: "name", label: "Name" }]}
                                    currentUser={currentUser}></ObjectTable>
                            </Box>
                        </Box>
                        <Box style={{ height: "5px" }}></Box>
                        {this.checkStoriesNeedToShow() ?
                            null :
                            <Box>
                                <Typography className="title">
                                    <img src={titleIcon}></img>
                                    <span className={anyStarSelect ? "star-select" : ""}>My Last Stories {starNameSelect ? "for " + starNameSelect : ""}</span>
                                    {/* <ButtonRound backgroundColor={"FFFCE2"} className={"button-refresh"} callback={this.reSetStories}  title="reset"></ButtonRound> */}

                                </Typography>
                                <Box style={{ width: "80%" }}>
                                    <ObjectTable
                                        childInfo={childInfo}
                                        title={starNameSelect ? "My Last Stories for " + starNameSelect : "My Last Stories"}
                                        className={"stories-table"}
                                        // callback={childUser || currentUser.roleId === userRole.child ? () => { } : (this.callbackOpenStory)}
                                        callback={this.checkStoriesNeedToShow() ? () => { } : (this.callbackOpenStory)}
                                        anyStarSelect={anyStarSelect}
                                        maxShow={5}
                                        data={childUser || currentUser.roleId === userRole.child ? myStories.filter(story=>story.isCompleted)  :myStories }
                                        titleCol={[{ name: "date", label: "Date" }, { name: "writtenBy", label: "Written By" }, { name: "name", label: "Story" }]}
                                        currentUser={currentUser}></ObjectTable>
                                    {childUser || currentUser.roleId === userRole.child ?
                                        null : <Box style={{ paddingLeft: "60px", paddingTop: "10px" }}>
                                            <Button
                                                style={{ marginRight: "10px" }}
                                                variant="contained"
                                                className={"blue-button"}
                                                onClick={() => this.openModal("New Story")}
                                            >Add A Story</Button>
                                            {myBehavioursPlan.length > 0 ?
                                                < Button
                                                    variant="contained"
                                                    className={"red-button"}
                                                    onClick={() => this.openModal("Behaviour Plan")}
                                                >Read Behaviour Plan</Button>
                                                : null}
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        }
                        <Box style={{ height: "5px" }}></Box>
                        {childUser || currentUser.roleId === userRole.child ?
                            null : <Box>
                                <Typography className="title">
                                    <img src={titleIcon}></img>
                                    <span>Incomplete Stories</span>
                                </Typography>
                                <Box style={{ width: "80%" }}>
                                    <ObjectTable
                                        childInfo={childInfo}
                                        title={"Incomplete Stories"}
                                        className={"uncompletedStories-table"}
                                        maxShow={3}
                                        callback={childUser || currentUser.roleId === userRole.child ? () => { } : this.callbackOpenStory}
                                        data={uncompletedStories}
                                        titleCol={[{ name: "date", label: "Date" }, { name: "name", label: "Story" }]}
                                        currentUser={currentUser}></ObjectTable>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                {
                    modalBehaviourPlan ? <ModalBehaviourPlan
                        setOpen={modalBehaviourPlan}
                        callback={this.closeModal}
                        currentUser={currentUser}
                        childInfo={childInfo}
                        titleFirst={childInfo.firstName + " " + childInfo.lastName}
                        titleSecond={titleSecond}
                        logo={"background-behaviour-fish"}
                        data={myBehavioursPlan}
                    ></ModalBehaviourPlan> : null
                }
                {
                    modalStory ? <ModalStory
                        setOpen={modalStory}
                        callback={this.closeModal}
                        className={storyDetails ? "new-story" : "editStory"}
                        currentUser={currentUser}
                        childInfo={childInfo}
                        titleFirst={childInfo.firstName + " " + childInfo.lastName}
                        titleSecond={titleSecond}
                        logo={logo}
                        data={storyDetails}
                        behaviour={dreamCircleSelect}
                    ></ModalStory> : null
                }
                {
                    this.state.snackBarStatus.status ?
                        <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null
                }
            </div >
        );
    }
}

export default DreamCirclePage
