import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const userService = {
    createUser,
    updateUser,
    forgotPassword,
    resetPassword,
    changePassword //Admim user changes password
};

function createUser(values:any){
   
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/users/addUser`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })

}

function getUserById(id:number)
{
    const requestOptions = { method: 'GET',  Authorization:authHeader() };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/offices/getOffices`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function updateUser(values:any,isChildPageCall:boolean){
    const requestOptions = {
        method: 'PATCH',
        headers: { authorization:authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    if(isChildPageCall){
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/updateChildDetails`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
    }else{
        return new Promise((resolve, reject)=>{
            fetch(`${config.apiURL}/teamMembers/updateTeamMember`, requestOptions)
            .then(
                response=>{
                
                    response.json()
                    .then(data=>{
                    resolve(data)
                    })
                })
                .catch(error=>{
                    reject(error);
                })
        })
    }
}


function forgotPassword(values:any){
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/users/forgotPassword`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })



}

function resetPassword(values:any){
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/users/resetPassword`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function changePassword(values:any) {
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/users/changePassword`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}