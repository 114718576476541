import React from 'react'
import AdminLayout from './components/adminLayout'
import Grid from '@material-ui/core/Grid'
import UserDetailsComponent from './components/userDetailsComponent'
import UserStoriesListComponent from './components/userStoriesListComponent'
import TeamMemberListComponent from './components/teamMemberListComponent'
import  AvatarComponent from './components/avatarComponent'
import DreamCircleComponent from './components/dreamCircleComponent'
import BehaviouralPlansComponent from './components/behaviouralPlansComponent'
// import  EditDetails from './components/popups/editDetails'
import {childrenService} from '../../services'
import messageBar from '../components/messageBar/messageBar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import  ProgressBar from '../components/progressBar/progressBar'
// import {ConfirmationDialog} from './components/popups/editDetailsDialog'
import createBreakpoints from "../app/createBreakpoints"
import { useTheme } from '@material-ui/core/styles';


export interface  Props {
    className:string;
    location:any;
    history:any;
    match: any;
}
 
export interface  State {
    // dates:any
    isLoading:boolean;
    childData:any;
    // behaviourPlansForMe:any;
}

let userId:any = null;
 


export class ChildDetails extends React.Component<Props, State>{
    // export default  function UserCreate(){

        constructor(props) {
            super(props);
            this.state = {
                isLoading:false,
                childData:[]
            }
          }

          
          componentWillMount() {
            userId = this.props.match.params.id;
            this.getData(userId);
        }
    
     
    
        getData(id){
            this.setState({isLoading: true});
            console.log(id);
            childrenService.getById(id).then((child:any)=>{
                if(child.success)
                {
                  
                   
                    this.setState({childData: child.data, isLoading: false})
                   
                   
                }else{
                    const variantIcon = {
                        success: CheckCircleIcon,
                        warning: WarningIcon,
                        error: ErrorIcon,
                        info: InfoIcon,
                      };
                      const props={
                          message:"there is an error",
                          variant:variantIcon.error
                      }
                    // messageBar(props);
                }
             })
        }

        onEditDetailsClick(memberDetails:any){
            console.log(memberDetails);
            console.log('this is clicked ')
        }

    render(){
        const emails = ['username@gmail.com', 'user02@gmail.com'];
        // const [open, setOpen] = React.useState(false);
        // const [selectedValue, setSelectedValue] = React.useState(emails[1]);
       
        return(
            <AdminLayout className={this.props.className} history={this.props.history} value={this.props.location.pathname.includes('/childdetails')?"child":"team"}>
                { this.state.isLoading? 
                 <ProgressBar></ProgressBar>
                :
                 <Grid container style={{padding:"10px"}}>
                    <Grid item xs={12}  className={""}>
                        <AvatarComponent  childId={this.state.childData.id} memberDetails={this.state.childData} isChildPageCall labelName={"Child"} history={this.props.history}></AvatarComponent>
                    </Grid>
                        {/* <Grid item xs={12}  > */}
                            <Grid item xs={11}   lg={6} style={{padding:"10px"}}>
                                <UserDetailsComponent refresh={() => this.componentWillMount()} memberDetails={this.state.childData} isChildPageCall onClick={()=>this.onEditDetailsClick(this.state.childData)}></UserDetailsComponent>
                            </Grid>
                            <Grid item xs={11} lg={6} className="remove-top-margin" style={{padding:"10px"}}>
                                <UserStoriesListComponent storyListData={this.state.childData} userId={userId} isChildrenStory={true}></UserStoriesListComponent>
                               
                            </Grid>
                        {/* </Grid> */}
                        {/* <Grid item xs={12}  > */}
                            <Grid item  className={"dream-circle-header"} xs={11}  lg ={6} style={{padding:"10px"}}>
                                <DreamCircleComponent dreamCirleList={this.state.childData.dreamCircle} childId={userId} history={this.props.history}></DreamCircleComponent>
                            </Grid>
                            <Grid item className={"user-stroies-header"} xs={11}   lg ={6}  style={{padding:"10px"}}>
                                <BehaviouralPlansComponent behaviouralPlansData={this.state.childData} ></BehaviouralPlansComponent>
                            </Grid>
                        {/* </Grid> */}
                        {/* <Grid item xs={12}  > */}
                            <Grid  xs={11} lg ={6} style={{padding:"10px"}}>
                                <TeamMemberListComponent refresh={() => this.componentWillMount()} teamMemberListData={this.state.childData} userId={userId} history={this.props.history}></TeamMemberListComponent> 
                            </Grid>
                        {/* </Grid> */}
                    </Grid>
              
                 
               }
            </AdminLayout>
        )
    }
}

