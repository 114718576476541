import React from 'react'
import MUIDataTable from "mui-datatables";
import AdminLayout from './components/adminLayout'
// import CustomToolbar from "./components/customToolbar";
import { Typography, Grid } from '@material-ui/core';
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
import AddIconButton from './components/addUserIconButton'
import {childrenService} from '../../services'
import {Moment} from 'moment'
import { FormatDate } from '../../common/formatDate';


export interface  Props {
    className:string;
    history:any;
    location:any
}
 
export interface  State {
    isLoading:boolean;
    childrenData:any
    
}
 
class ChildrenList  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
    //    this.setState({isLoading: true});
    }
     
    componentWillMount() {
        console.log("this land here");
      console.log(this.props.history)
        // if(this.props.location.search==='?child'){
        //     window.location.reload(true);
        // }
        this.getData();
    }

 

    getData(){
        this.setState({isLoading: true});
        childrenService.getAll().then((children:any)=>{
            if(children.success)
            {
                this.setState({childrenData: children.data, isLoading: false})  
            }
         })
    }

   
    render(){
        
    const columns = [
        {
            name:"id",
            label:"id",
            options:{
               display:false
            }
        },  {
            name:"uniqueId",
            label:"Unique ID",
            options:{
                sort:true
            }
        },{
            name:"fullName",
            label:"Full Name",
            options:{
                sort:true
            }
        },
        {
            name:"dateOfBirth",
            label:"Date of Birth",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value){
                        return (
                            <div>{FormatDate.trasformDate(new Date(value))}</div>               
                        );
                    }
                    
                }
            }
        }, {
            name:"suburb",
            label:"Location",
            options:{
                sort:true
            }
        },
       {
            name:"phoneNumber",
            label:"Phone Number"
        },{
            name:"storyCount",
            label:"Stories",
            options:{
                sort:true
            }
        },{
            name:"starCount",
            label:"Stars",
            options:{
                sort:true
            }
        },{
            name:"dreamCircleCount",
            label:"Dream Circles",
            options:{
                sort:true
            }
        },
       
    ];

   
  

    const options = {
        print: false,
        download:false,
        filter:false,
        viewColumns:false,
        // selectableRowsOnClick:true,
        selectableRowsHeader:false, 
        selectableRows:"none",
        onRowClick:  (rowData) => this.props.history.push({pathname:'/childdetails/'+rowData[0], data:rowData}),
        // customToolbar: () => {
        //     return (
        //       <CustomToolbar />
        //     );
        //   }
      };
   return( 

        <AdminLayout className={this.props.className} value="child" history={this.props.history}>
            <Grid  container>
                <Grid item style={{width:'172px'}}>
                    {/* <Typography> */}
                        <h2>
                            Children
                        </h2>
                    {/* </Typography> */}
                </Grid>
                <Grid>
                     <AddIconButton toolTip="Add Child" action="/adduser?child" ></AddIconButton>
                </Grid>
            </Grid>
               
                <MUIDataTable
                // title={"Children"}
                data={this.state.childrenData}
                options={options}
                columns={columns}
                className={"trasnparent-muipaper-root"}
                />
        </AdminLayout>
    
    )};

}

export default ChildrenList;