import React from 'react';
import { Route,  Switch } from "react-router-dom";
import { Router } from "react-router";
import { LastLocationProvider } from 'react-router-last-location';
// import logo from '../../../logo.svg';
// import * as ReactDOM from 'react-dom';
// import Home  from '../home/home'
import config from '../../config';
import {Login} from '../login'
import ChildrenList from '../adminPages/childrenList';
import TeamMemberList from '../adminPages/teamMemberList';
import AdminDetails from '../adminPages/adminDetails';
import ForgotPassword from '../forgotPassword/forgotPassword';
import { history, Role } from '../../helper';
import { authenticationService, childrenService } from '../../services';
import {UserCreate} from '../../views/adminPages/userCreate';
import { TeamMemberDetails} from '../../views/adminPages/teamMemberDetails';
import {ChildDetails} from '../../views/adminPages/childDetails';
import { DreamCircle } from '../../views/adminPages/dreamCircle';
import { NewsFeed} from '../newsFeedPage';
import { userRole } from '../../enums/index';
import './App.css';
import Avatar from '../avatarPage/avatar';
import Profile from '../profilePage/profile';
import Notification from '../notificationPage/notification';
import DreamCirclePage from '../dreamCirclePage/dreamCirclePage';

import PrivateRoute from '../../componentHelper/privateRoute'
import { Grid } from '@material-ui/core';
  

export enum Roles{
    child=1,
    teamMember=2,
    admin=3,
    superAdmin=4
}
export interface Props {
 
}
 
export interface State {
    currentUser:any,
    isAdmin:Boolean,
    role:number,
    className:string
}

class App extends React.Component<Props, State> {
  constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            role:userRole.child,
            className:"bg"
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe((res:any) => {
            authenticationService.isTeamMemberChild.subscribe((child:any) => {
            if(res !== null && res.user){
                const user:any = res.user;
                this.setState({
                    currentUser: user,
                    isAdmin: user && user.roleId === userRole.Admin,
                    role: user.roleId,
                    className: user.roleId === userRole.child || child ? "bg-child" : user.roleId === userRole.teamMember ? "bg-team" : user.roleId === userRole.Admin ? "bg-admin" : "bg", 
                })
            }else{
                this.setState({className: "bg"});
            }
        })
        }
        );
    }

    logout() {
        authenticationService.logout();
        history.push('/login');
    }

   render() { 
      const { currentUser, isAdmin ,role , className} = this.state;
        return (
            <Router history={history}>
            <Switch>
                <Router history={history}>
                    <Route exact path="/"  render={(props)=><NewsFeed {...props} className={className}/>} />
                    {/* <PrivateRoute  path="/" component={(props)=><NewsFeed {...props} className={className}/>} ></PrivateRoute> */}
                    {/* <Route path="/"  render={(props)=><Login {...props} className="bg" />} /> */}
                    <Route path="/login"  render={(props)=><Login {...props} className="bg" />} />
                    <Route path="/forgot" render={(props)=><ForgotPassword {...props} className="bg" />} />
                    {/* <PrivateRoute  path="/childrenlist" component={(props)=><ChildrenList {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/teammember" component={(props)=><TeamMemberList {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/administration" component={(props)=><AdminDetails {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/adduser" component={(props)=><UserCreate {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/teammemberdetails" component={(props)=><TeamMemberDetails {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/childdetails" component={(props)=><ChildDetails {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/dreamcircle" component={(props)=><DreamCircle {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/newsfeed/:id" component={(props)=><NewsFeed {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/avatar/:id" component={(props)=><Avatar {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/profile/:id" component={(props)=><Profile {...props} className="bg" />} ></PrivateRoute>
                    <PrivateRoute  path="/mydreamcircle/:id" component={(props)=><DreamCirclePage {...props} className="bg-dreamcircle" />} ></PrivateRoute>
                    <PrivateRoute  path="/notification/:id" component={(props)=><Notification {...props} className="bg" />} ></PrivateRoute> */}
                   
                   
                    <Route path="/childrenlist" render={(props)=><ChildrenList {...props} className="bg" />} />
                    <Route path="/teammember" render={(props)=><TeamMemberList {...props} className="bg" />} />
                    <Route path="/administration" render={(props)=><AdminDetails {...props} className="bg" />} />
                    <Route path="/adduser" render={(props)=><UserCreate {...props} className="bg" />} />
                    <Route path="/teammemberdetails/:id" render={(props)=><TeamMemberDetails {...props} className="bg" />} />
                    <Route path="/childdetails/:id" render={(props)=><ChildDetails {...props} className="bg" />} />
                    <Route path="/dreamcircle/" render={(props)=><DreamCircle {...props} className="bg" />} />    
                    
                    <Route path="/newsfeed/:id" render={(props)=><NewsFeed {...props} className={className}/>} />
                    <Route path="/avatar/:id" render={(props)=><Avatar {...props} className={className}/>} />
                    <Route path="/profile/:id" render={(props)=><Profile {...props} className={className}/>} />
                    <Route path="/mydreamcircle/:id" render={(props)=><DreamCirclePage {...props} className={"bg-dreamcircle"}/>} />
                    <Route path="/notification/:id" render={(props)=><Notification {...props} className={className}/>} />                                  
                </Router>
            </Switch>
            </Router>
          
        );
    
        //   <Login history={history} className={className}></Login>    
        // </div>
     
   }
}

export default App;
