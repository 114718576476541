import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  MenuItem,
  Button,
  Typography,
  TextField,
  Select
} from "@material-ui/core";
import Wrapper from '../../../../baseComponents/wrapper';
import { Formik, Field, Form } from 'formik';
// import {TextField } from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase';
import * as yup from 'yup';
import { storyService, behavoursService } from '../../../../../services'
import moment from 'moment'
import { Notifier, notifierProps } from '../../../../components/notifier/notifier'
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormatDate } from "../../../../../common/formatDate";
import ReactToPrint from 'react-to-print';
const closeIcon = require('../../../../../assets/images/close_icon.png');

export interface ConfirmationOptions {
  catchOnCancel?: boolean;


  // description: string;
}

export interface EditStoriesDialogProps extends ConfirmationOptions {
  openAction: boolean;
  storyData: any;
  onClose: () => void;
  refreshFuntion:()=>void;
}

const storyDataInitialState: any = "";
const snackBarStatusInitialState: any = "";
let componentRef;

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}


export const EditStoriesDialog: FunctionComponent<EditStoriesDialogProps> = ({
  openAction,
  storyData,
  onClose,
  refreshFuntion
}) => {

  const [storyUpdatedData, setStoryData] = useState(storyDataInitialState);
  const [snackBarStatus, setsnackBarStatus] = useState(snackBarStatusInitialState);
  const [state, setState] = React.useState({ checkedB: false });
  const [isPrint, setPrint] = React.useState(false);
  const [behavioursList, setBehaviours] = useState([] as any)
  const schema = yup.object().shape({
    story: yup.string().required(),
    behaviourId: yup.number().required(),
    isPublished: yup.string().required(),

  });

  const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1)
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(16px)",
          color: theme.palette.common.white,
          "& + $track": {
            backgroundColor: "#52d869",
            opacity: 1,
            border: "none"
          }
        },
        "&$focusVisible $thumb": {
          color: "#52d869",
          border: "6px solid #fff"
        }
      },
      thumb: {
        width: 24,
        height: 24
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.primary[400]}`,
        backgroundColor: theme.palette.primary[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"])
      },
      checked: {},
      focusVisible: {}
    })
  )(({ classes, ...props }: Props) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  });
  interface behaviourData {
    value: number,
    label: string
  }
  //  console.log(storyData)

  useEffect(
    () => {
      let locations: behaviourData[] = [];
      setBehaviours({ behavioursList: locations });
      behavoursService.getAllBehavours().then((behaviours: any) => {
        if (behaviours.success) {
          behaviours.data.map(behaviour => {
            let data: any = {
              value: behaviour.id,
              label: behaviour.behaviourName
            }
            locations.push(data);
          }
          );
          setBehaviours({ behavioursList: locations });
        }
      })
      storyService.getById(storyData[0]).then((story: any) => {
        if (story.success) {
          const data = story.data
          console.log(data);
          const dateOfCreation = (new Date(story.data.creationOn));
          data.dateAssign = dateOfCreation;
          data.checked = story.data.isPublished === 1 ? true : false;
          setState({ checkedB:data.checked});
          setStoryData({ storyUpdatedData: data });
          console.log(storyUpdatedData, "thjios is story data")
        } else {

        }
      })
    }, []

  );

  const handleChangeSelect = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked });
  };

  // console.log(behaviourId,"behaviourId")
  interface Values {
    id: number,
    story: string,
    childId: number,
    teamMemberId: number,
    behaviourId: number,
    isPublished: number,
    creationOn: Date,
  }
  console.log((storyUpdatedData && storyUpdatedData.storyUpdatedData.behaviour.id), "brijender story data", (storyUpdatedData && storyUpdatedData.storyUpdatedData.Behaviour !== undefined))
  // const behaviourId=  (storyUpdatedData &&  storyUpdatedData.storyUpdatedData.Behaviour !==undefined )? storyUpdatedData.storyUpdatedData.Behaviour.id:0;
  const behaviourId = storyUpdatedData && storyUpdatedData.storyUpdatedData.behaviour.id;
  console.log(behaviourId, "this is behaviourId");
  return (

    <Dialog open={openAction} className="popup-edit-details story-background" ref={el => (componentRef = el)}>
      <DialogContent className={"edit-story-fish"}>
        <Wrapper title={""} >
          <Formik<Values> enableReinitialize={true}
            initialValues={{
              id: storyUpdatedData && storyUpdatedData.storyUpdatedData.id,
              story: storyUpdatedData && storyUpdatedData.storyUpdatedData.story,
              childId: storyUpdatedData ? storyUpdatedData.storyUpdatedData.child.id : 0,
              teamMemberId: storyUpdatedData ? storyUpdatedData.storyUpdatedData.teamMember.id : 0,
              behaviourId: storyUpdatedData ? storyUpdatedData.storyUpdatedData.behaviour.id : 0,
              isPublished: storyUpdatedData ? storyUpdatedData.storyUpdatedData.isPublished : 0,
              creationOn: storyUpdatedData ? storyUpdatedData.storyUpdatedData.dateAssign : new Date(),
            }}
            // validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values)
              let object = {
                story: values.story,
                behaviourId: values.behaviourId,
                isPublished: state.checkedB === true ? 1 : 0,
                creationOn: FormatDate.trasformDate(values.creationOn)
              }
              const stateObject = {
                msg: "",
                status: false,
                date: new Date(),
                variant: ''
              }
              openAction = false;
              storyService.updateStory(values.id, object).then((data: any) => {
                if (data.success) {
                  stateObject.status = true;
                  stateObject.msg = data.message;
                  stateObject.variant = "success";
                  // Force list of the stories to refresh
                  refreshFuntion();
                } else {
                  stateObject.status = true;
                  stateObject.msg = data.message;
                  stateObject.variant = "error";
                }
                setsnackBarStatus(stateObject);
                setSubmitting(false);
                setTimeout(() => {
                  stateObject.status = false;
                  setsnackBarStatus({ snackBarStatus: stateObject });
                }, 2000);
              }, error => {
                //ERROR
                console.log(error.message);
                stateObject.status = true;
                stateObject.msg = "There is a problem on Server!";
                stateObject.variant = "error";
                setsnackBarStatus(stateObject);
                setSubmitting(false);
                setTimeout(() => {
                  stateObject.status = false;
                  setsnackBarStatus(stateObject);
                }, 3000);

              });
            }}
            render={({ setFieldTouched, handleChange, submitForm, isSubmitting, handleBlur, values }) => (
              <>
                <h2>
                  <img src={closeIcon} onClick={onClose} />
                </h2>
                <h2 className={"popup-heading margin-top"} > {storyUpdatedData && storyUpdatedData.storyUpdatedData.submittedFor} </h2>


                <Form className="edit-detail-form">
                  <Grid container spacing={3} xs={8} item className={"add-user"} >
                    <Grid item xs={12} className={"row"}>
                      <Typography>
                        Details
                                  </Typography>
                    </Grid>
                    <Grid item xs={12} className={"row"}>
                      <InputBase
                        defaultValue="Date"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <InputBase
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label-disable"}
                        value={storyUpdatedData && FormatDate.trasformDate(values.creationOn)}
                      />

                    </Grid>
                    <Grid item xs={12} className={"row submitted-by"}>
                      <InputBase
                        defaultValue="Submitted By"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <InputBase
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label-disable"}
                        value={storyUpdatedData && storyUpdatedData.storyUpdatedData.teamMember? storyUpdatedData.storyUpdatedData.teamMember.firstName +" "+storyUpdatedData.storyUpdatedData.teamMember.lastName : ""}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row"}>
                      <InputBase
                        defaultValue="Behaviour"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <Select
                        name="behaviourId"
                        onChange={handleChange}
                        value={values.behaviourId}
                      >
                        {behavioursList.behavioursList.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <Field
                        type="text"
                        name="behaviourId"
                        //defaultValue={values.behaviourId}
                        onChange={handleChange}
                        value={values.behaviourId}
                        select
                        SelectProps={{
                          MenuProps: {
                            className: "classes.menu",
                          },
                        }}
                        // helperText="Please select gender"
                        component={TextField}
                      > 
                        {behavioursList.behavioursList.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>*/}

                    </Grid>
                    <Grid item xs={12} className={"row"}>
                      <InputBase
                        defaultValue="Linked to Star"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <InputBase
                        value={(storyUpdatedData && storyUpdatedData.storyUpdatedData.starsST.length) > 0 ? "Yes" : "No"}
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label-disable"}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row"}>
                      <InputBase
                        defaultValue="Published"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            // checked={true}
                            onChange={handleChangeSelect("checkedB")}
                            value="checkedB"
                            checked={state.checkedB}
                          />
                        }
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {isPrint ? 
                    <p id={"print"}>{values.story}</p>
                    : <TextField
                      className={"full-width_control"}
                      id="filled-multiline-static"
                      multiline
                      rows="10"
                      rowsMax="10"
                      variant="filled"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.story}
                      type="text"
                      name="story"
                    />}
                  </Grid>
                  {isPrint ? "" : 
                  <Grid item className={"margin-top button-container row-btn"} xs={12}>
                    {isSubmitting && <LinearProgress />}
                    <Grid item>
                      <Button
                        variant="contained"
                        className={"orange-button"}
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >Save</Button>

                    </Grid>
                    <Grid item>
                    <ReactToPrint
                        trigger={() => {
                          return <Button
                            variant="contained"
                            style={{marginLeft:30}}
                            className={"green-button"}
                            disabled={isSubmitting}
                            onClick={()=>{}}
                          >Print</Button>
                        }}
                        onBeforeGetContent={async ()=>{ await setPrint(true)}}
                        onAfterPrint={()=>{setPrint(false)}}
                        onBeforePrint={()=>{setPrint(true)}}
                        onPrintError={()=>{setPrint(true)}}
                        content={() => componentRef}
                      />
                    </Grid>
                  </Grid>}
                </Form>
              </>
            )}
          />
          {snackBarStatus.status ?
            <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
        </Wrapper>
      </DialogContent>
      <DialogActions>
        {/* <Button color="primary" onClick={onSubmit}>
                Save
              </Button> */}
      </DialogActions>
    </Dialog>
  );
};
