import React from 'react'
import AdminLayout from './components/adminLayout'
import MUIDataTable from "mui-datatables";
import { Typography, Grid } from '@material-ui/core';
import AddUserButton from './components/addUserIconButton'
import {adminMemberService} from '../../services'

export interface  Props {
    className:string
    history:any
}
 
export interface  State {
    isLoading:boolean;
    adminMemberData:any
  
}
 
class AdminDetails  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
       
    }
    componentWillMount() {
        this.getData();
    }

 

    getData(){
        this.setState({isLoading: true});
        adminMemberService.getAll().then((adminMember:any)=>{
            if(adminMember.success)
            {
                adminMember.data.forEach(item=>{
                     const dob=(new Date(item.dateOfBirth));
                    item.dateOfBirth= dob.getDate() + "/"+ (dob.getMonth() + 1) +"/"+dob.getFullYear();

                })
                this.setState({adminMemberData: adminMember.data, isLoading: false})
               
            }
         })
    }


    onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }){
      
        console.log("----RowClick");
        console.log("rowData: ", rowData);
        console.log("rowMeta: ", rowMeta);
    }

    render() {
        const columns = [
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },  {
                name:"uniqueId",
                label:"Unique ID",
                options:{
                    sort:true
                }
            },{
                name:"fullName",
                label:"Full Name",
                options:{
                    sort:true
                }
            },{
                name:"jobTitle",
                label:"Job Title",
                options:{
                    sort:true
                }
            },
            {
                name:"phoneNumber",
                label:"Phone Number"
            }
        ]

        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            selectableRowsHeader:false, 
            selectableRows:'none',
            onRowClick: this.onRowClick,
            // customToolbar: () => {
            //     return (
            //       <CustomToolbar />
            //     );
            //   }
          };
       return( 
            <AdminLayout className={this.props.className} value="admin" history={this.props.history}>
                    <Grid  container>
                        <Grid item style={{width:'332px'}}>
                            <Typography>
                                <h2>
                                        Administration
                                </h2>
                            </Typography>
                        </Grid>
                        <Grid>
                            <AddUserButton toolTip="Add Child" action="/adduser?team" ></AddUserButton>
                        </Grid>
                    </Grid>
                    <MUIDataTable
                    // title={"Team Member"}
                    data={this.state.adminMemberData}
                    options={options}
                    columns={columns}
                    />
            </AdminLayout>
        
        )};
}

export default AdminDetails;