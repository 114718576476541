import React,{Fragment} from "react";
import "./avatarComponent.scss";
import { Box } from "@material-ui/core";
import ButtonRound from "../buttonRound/buttonRound";
import ColorPicker from "../colorPicker/colorPicker";
import { AvatarInfo,AvatarAccessory } from "../../../enums/AvatarInfo";
import { characterName, backGroundName, menuTypes } from "../../../enums/index";
import ResizableRect from 'react-resizable-rotatable-draggable'
import ReactDOM from "react-dom";
import zIndex from "@material-ui/core/styles/zIndex";

const close = require("../../../assets/images/close_icon.svg");

export interface Props {
  avatarDetails: AvatarInfo;
  OnDrag : (deltaX : number, deltaY : number) => void;
  OnResize :(style : any, isShiftKey : any, type:any) => void;
  accessory: AvatarAccessory;
  

}

export interface State {
  avatarDetailState: AvatarInfo;
  move: string
}

class ResizableContantComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  
  async componentWillMount() {
    this.setState({ avatarDetailState: this.props.avatarDetails, move:"n, w, s, e, nw, ne, se, sw" });
  }

  render() {
    const rotateAngle: number = 0;
        const {name, top, left, width, height } = this.props.accessory
        const {OnDrag, OnResize} = this. props;
        const { move } = this.state;
        console.log(name)
        const contentStyle =  {
                position: 'absolute' as 'absolute', // cast string to type 'absolute'
                top: top,
                left: left, 
                width: width,
                height:height,
                zIndex: 2
            }
       
         
    return (
        <Fragment>
    {name ?
        <Fragment>
     <div style={contentStyle}>{this.props.children}</div>
      <ResizableRect
      left={left}
      top={top}
      width={width}
      height={height}
      rotateAngle={rotateAngle}
      //aspectRatio="1.68"
      // aspectRatio={false}
      // minWidth={10}
      // minHeight={10}
      zoomable={move}
      rotatable={false}
      // onRotateStart={this.handleRotateStart}
      // onRotate={this.handleRotate}
      // onRotateEnd={this.handleRotateEnd}
      // onResizeStart={this.handleResizeStart}
      onResize={OnResize}
      // onResizeEnd={this.handleUp}
      // onDragStart={this.handleDragStart}
      onDrag={OnDrag}
      // onDragEnd={this.handleDragEnd}

    >
    


    </ResizableRect> </Fragment>
    :null}
     </Fragment> 
    );
  }
}

export default ResizableContantComponent;