
import React, { FunctionComponent, useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    InputBase,
    TextField,
    MenuItem,
    LinearProgress,
    Typography
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { behavoursService } from '../../../../../services'
import Wrapper from '../../../../baseComponents/wrapper';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import MomentUtils from "@date-io/moment";
import MUIDataTable from "mui-datatables";
import { storyService, starService } from '../../../../../services'
import { FormatDate } from '../../../../../common/formatDate';
import { Notifier } from '../../../../components/notifier/notifier';

const closeIcon = require('../../../../../assets/images/close_icon.png');
const snackBarStatusInitialState: any = "";


export interface grantNewStarDialogProps {
    openAction: boolean;
    grantStartData: any;
    refreshFuntion: () => void;
    onClose: () => void;
}

const initialState = {
    snackBarStatus: [],
    data: [],
    selectedDate: new Date(),
    open: false,

}



export const GrantNewStarDialog: FunctionComponent<grantNewStarDialogProps> = ({
    openAction,
    grantStartData,
    refreshFuntion,
    onClose }) => {

    const [selectedDate, setSelectedDate] = React.useState<Date>(
        new Date(),
    );
    const [newStoriesdata, setnewStoriesdata] = React.useState([] as any)
    const [rowsSelected, setRowsSelected] = React.useState([] as any)
    const [snackBarStatus, setsnackBarStatus] = useState(snackBarStatusInitialState);
    useEffect(
        () => {

            storyService.getNewStoriesByUserAndBehaviour(grantStartData.id, grantStartData.dreamCircleDetails.circleId).then((newstories: any) => {
                if (newstories.success) {
                    setnewStoriesdata({
                        newStoriesdata: (newstories.data)

                    }
                    );
                }
                else {

                }
            })
        }, []
    );

    //   console.log(newStoriesdata.newStoriesdata)
    // console.log(grantStartData)
    interface Values {
        starName: string,
        dateGranted: string,
        progress: number,
        childId: string,
        storiesRelated: number[]
    }
    const schema = yup.object().shape({
        starName: yup.string().required("Star Name is required"),
        dateGranted: yup.string(),
        childId: yup.number().positive(),
        progress: yup.number().positive(),
        storiesRelated: yup.array().of(yup.number().min(1))

    })

    const handleDateChange = (val: any) => {
        if(val){
        setSelectedDate(val._d);
        }
    };
    //   console.log(behavioursList[0])
    //   behavioursList.map((option:any) =>{
    //     console.log(option, option.value, option.label)
    //   })

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                display: false
            }
        }, {
            name: "creationOn",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>{FormatDate.trasformDate(new Date(value))}</div>
                    );
                }
            }
        }, {
            name: "storyBy",
            label: "Story By",
            options: {
                sort: true
            }
        },
        {
            name: "behaviourName",
            label: "Behaviour",
            options: {
                sort: true,
            }
        }, {
            name: "story",
            label: " Story",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div 
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "21vw",
                            textOverflow: "ellipsis",
                        }}
                      >{value}
                      </div>
                    );
                }
            },
        },

    ];

    const options = {
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        selectableRowsOnClick: true,
        rowsSelected: rowsSelected.rowsSelected,
        onRowsSelect: (rowsSelected, allRows) => {
            const selected = allRows.map(item => item.index);
            setRowsSelected({
                rowsSelected: selected
            });
        }
    }

    return (
        <Dialog open={openAction} className="popup-edit-details child-background">
            <DialogContent className={"edit-child-behaviours-grant-star"}>
                <Grid container >
                    <Grid item xs={12}>
                        <h2>
                            <img src={closeIcon} onClick={onClose} />
                        </h2>
                        <h2 className={"popup-heading margin-top"} > {grantStartData.fullName} </h2>
                        <h2 className={"popup-heading"}> {"Grant a new Star"} </h2>
                    </Grid>
                    <Grid item xs={12}>
                        <Wrapper title={""} >
                            <Formik<Values>
                                initialValues={{
                                    progress: 0,//(data!==null || data!==undefined) ?data.childId:0,
                                    dateGranted: "",
                                    starName: "",
                                    childId: grantStartData.id,
                                    storiesRelated: []
                                }}
                                validationSchema={schema}
                                onSubmit={(values, { setSubmitting }) => {
                                    let stateObject = {
                                        msg: "",
                                        status: false,
                                        date: new Date(),
                                        variant: ''
                                    }
                                    values.dateGranted = FormatDate.trasformDate(selectedDate);
                                    console.log("FORMATE", values);

                                    if (rowsSelected.rowsSelected && rowsSelected.rowsSelected.length > 0) {
                                        let storiesRelated: number[] = [];
                                        rowsSelected.rowsSelected.forEach(element => {
                                            storiesRelated.push(newStoriesdata.newStoriesdata[element].id)
                                        });
                                        values.storiesRelated = storiesRelated;
                                        starService.grantNewStar(values).then((data: any) => {
                                            if (data.success) {
                                                stateObject.msg = data.message;
                                                stateObject.status = true;
                                                stateObject.variant = "success"
                                            }
                                            else {
                                                //ERROR
                                                stateObject.msg = data.message;
                                                stateObject.status = true;
                                                stateObject.variant = "error"
                                            }
                                            setsnackBarStatus(stateObject);
                                            setTimeout(() => {
                                                if(data.success) refreshFuntion();
                                                stateObject.msg = "";
                                                stateObject.status = false;
                                                stateObject.variant = "";
                                                setSubmitting(false);
                                                setsnackBarStatus(stateObject);
                                            }, 3000);
                                        }, error => {
                                            //ERROR
                                            console.log(error.message);
                                            stateObject.msg = "Error in Server";
                                            stateObject.status = true;
                                            stateObject.variant = "error"
                                            setsnackBarStatus(stateObject);
                                            setTimeout(() => {
                                                stateObject.msg = "";
                                                stateObject.status = false;
                                                stateObject.variant = "";
                                                setSubmitting(false);
                                                setsnackBarStatus(stateObject);
                                            }, 3000);

                                        })
                                    } else {
                                        stateObject.msg = "You need to select some Story!";
                                        stateObject.status = true;
                                        stateObject.variant = "error"
                                        setsnackBarStatus(stateObject);
                                        setTimeout(() => {
                                            stateObject.msg = "";
                                            stateObject.status = false;
                                            stateObject.variant = "";
                                            setSubmitting(false);
                                            setsnackBarStatus(stateObject);
                                        }, 3000);
                                    }
                                }}
                                render={({ setFieldTouched, handleChange, handleBlur, submitForm, isSubmitting, values, errors }) => (
                                    <Form className="margin-top">
                                        <Grid item xs={8}>
                                            {/* <Grid item xs={12} className={"row"}>
                                                {errors.starName ? errors.starName : ""}
                                            </Grid> */}
                                            <Grid item xs={12} className={"row"}>
                                                <InputBase
                                                    defaultValue="Name the Star *"
                                                    inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                    className={errors.starName ? "label Mui-error-label" : "label"}
                                                />
                                                <TextField
                                                    type="text"
                                                    name="starName"
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={"row"}>
                                                <InputBase
                                                    defaultValue="Date Granted *"
                                                    inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                    className={"label"}
                                                />
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        name="dateGranted"
                                                        format="DD/MM/YYYY"
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item xs={12} className={"row"}>
                                                <InputBase
                                                    defaultValue="Progress *"
                                                    inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                    className={errors.progress ? "label Mui-error-label" : "label"}
                                                />
                                                <TextField
                                                    type="number"
                                                    name="progress"
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <MUIDataTable
                                                title={"Which stories should be linked to this star?"}
                                                data={newStoriesdata.newStoriesdata}
                                                options={options}
                                                columns={columns}
                                                className={"trasnparent-muipaper-root-grant-new-star"}
                                            />
                                        </Grid>
                                        <Grid item className={"margin-top button-container"} xs={12}>
                                            {isSubmitting && <LinearProgress />}
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    className={"orange-button"}
                                                    disabled={isSubmitting}
                                                    onClick={submitForm}
                                                >
                                                    Save
                                                    </Button>

                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            />
                        </Wrapper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
            {snackBarStatus.status ?
                <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
        </Dialog>
    )
};
