import { string } from "prop-types"
import * as htmlToImage from 'html-to-image';
import { typeImage } from '../enums/index';


export class Common{
  
    /**
     * creating image url/path according to provided parameter
     * @param menuName 
     * @param menuItemname 
     */
    static getImageURL(menuName,menuItemname):string{
        if(!menuName){
            return ( "../../assets/images/avatar/"+ menuItemname + ".png")
        }
        else{
            return ( `../../assets/images/avatar/${menuName}/${ menuItemname }.png`)
        }
        return string.toString()
    }

    static convertToImage(html:any, type:string){
        return new Promise ((resolve, reject) => {
            switch(type){
                case typeImage.toPng:
                    htmlToImage.toPng(html).then(dataUrl => {
                        const img = new Image();
                        img.src = dataUrl;
                        document.body.appendChild(img);
                        resolve(img);
                    }, error => {
                        console.log(error.message);
                        resolve(null);
                    })
                    break;

                    case typeImage.toJpeg:
                    htmlToImage.toJpeg(html).then(dataUrl => {
                        const img = new Image();
                        img.src = dataUrl;
                        document.body.appendChild(img);
                        resolve(img);
                    }, error => {
                        console.log(error.message);
                        resolve(null);
                    })
                    break;
                    case typeImage.toBlob:
                    htmlToImage.toBlob(html).then(dataUrl => {
                        resolve(dataUrl);
                    }, error => {
                        console.log(error.message);
                        resolve(null);
                    })
                    break;
                default:
                    htmlToImage.toPng(html).then(dataUrl => {
                        const img = new Image();
                        img.src = dataUrl;
                        document.body.appendChild(img);
                        resolve(img);
                    }, error => {
                        console.log(error.message);
                        resolve(null);
                    })
                    break;
            }
        })
    }

    static getAvadarDefaut(){
        return require("../assets/images/avatar/default_avatar.png");
    }

    static randomNumber(digit:number):string{
        let number:string = '';
        for(var i = 0; i < digit; i++){
            number += Math.floor(Math.random() * 9).toString();
        }
        return number;
    }

}