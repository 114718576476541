import React from 'react';
import './archievementsList.scss';
import { userRole, archievementType } from '../../../enums/index';
import { Paper, TableBody, TableHead, TableCell, TableRow, Table, Typography } from "@material-ui/core/";
import TableContainer from '@material-ui/core/TableContainer';
import { FormatDate } from '../../../common/formatDate';
import { Scrollbars } from 'react-custom-scrollbars';
import { authenticationService } from '../../../services';

export interface Props {
    archievementsList: Array<any>,
    currentUser: any
}

const titleIcon = require('../../../assets/images/menuSelected.svg');
const storyImg = require('../../../assets/images/story.svg');
const starImg = require('../../../assets/images/star.svg');
const dreamCircle = require('../../../assets/images/dream-circle.svg');

export interface State {
    archievementsList: Array<any>,
    title: string

}

let formatDate:FormatDate = new FormatDate();
let child = null;

class ArchievementsList extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        child = authenticationService.isTeamMemberChildValue;
    }

    componentWillMount() {
        this.setState({ title: this.props.currentUser.roleId === userRole.child || child ? "My Achievements" : "My Nieces' and Nephews' Achievements" });
    }

    createObjectList(objList: Array<any>): Array<any> {
        if(objList){
            objList.forEach(item => {
                var isoDate: Date = new Date(item.creationOn);
                item.formatedDate = formatDate.trasformDate(isoDate);
            });
        }else{
            objList = [];
        } 
        return objList
    }

    render() {
        const archievementsList = this.createObjectList(this.props.archievementsList);
        return (
            <div>
                <Typography className="title">
                    <img src={titleIcon}></img>
                    <span>{this.state.title}</span>
                </Typography>
                {archievementsList.length > 0 ? 
                <TableContainer style={{overflowY:"hidden"}}>
                <Table className="archievement-table" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Behaviour</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {archievementsList.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {item.formatedDate}
                            </TableCell>
                            <TableCell align="left" style={{display: "flex"}}>
                                <img style={{ paddingRight: "10px" }} src={item.type === archievementType.story ? storyImg : item.type === archievementType.star ? starImg : item.type === archievementType.dreamCircle ? dreamCircle : "-"}></img>
                                <span style={{ marginTop: "auto", marginBottom:"auto"}}>{item.type === archievementType.story ? "Story" : item.type === archievementType.star ? "Star" : item.type === archievementType.dreamCircle ? " Dream Circle" : "-"}</span>
                            </TableCell>
                            <TableCell align="left">{item.behaviourName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer> : <span style={{paddingLeft: "65px"}}>No Achievements registered yet.</span>}
            </div>
        );
    }
}

export default ArchievementsList;