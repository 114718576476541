import React from "react";
import {  authenticationService } from '../../../services';
import {browserHistory} from "react-router";
import './layout.scss';

//import 'font-awesome/css/font-awesome.css';
//import '../assets/css/styles.scss';
const logoTop = require('../../../assets/images/logo.png');

export enum Roles {
  child = 1,
  teamMember = 2,
  admin = 3,
  superAdmin = 4
}

export interface Props {
  className: string,
  currentUser: any,
  location: any
  history:any
}

export interface State {
  isAdmin: Boolean,
  role: Roles
}

class Layout extends React.Component<Props, State>{

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      role: Roles.child
    };
  }

  componentWillMount(){
    const currentUser = authenticationService.currentUserValue.user;
    console.log(currentUser)
     if (currentUser===undefined){
       console.log("brijender it comes here")
         const { from } =  { from: { pathname: "/login" } };
    // TO::DO Brijender This is hack I put in to redirect to login page 
         window.location.href="/login";
     }
 }

  render() {
    const { location, className, currentUser } = this.props;
    return (
      <div className="background-white-bottom">
        <div className="background-white-top"></div>
        <div className="background-logo"></div>
        <div className={"background background-" + className} ></div>
      </div>
    );
  }
}

export default Layout;