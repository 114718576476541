import React from 'react';
import Img from 'react-image';
import { Grid, Typography, Box } from '@material-ui/core'
import ButtonComponent from './buttonComponent'
import ButtonRound from '../../components/buttonRound/buttonRound';
import { history } from '../../../helper';
import { Common } from '../../../common';

const avatar  = Common.getAvadarDefaut();
const backIcon = require('../../../assets/images/back.png');


export interface Props {
    memberDetails: any;
    isChildPageCall: boolean;
    labelName: string
    history:any;
    childId?:any
}

export interface State {

}
class AvatarComponent extends React.Component<Props, State> {
    constructor(props) {
        super(props);

    }

    onClick(childId:any) {
        const url = window.location.href;
        if(url && childId){
            const urlAux = url.includes("/childdetails") ? url.substring(0, url.indexOf("/childdetails")) : url.includes("/dreamcircle") ? url.substring(0, url.indexOf("/dreamcircle")) : null;
            if(urlAux){
                window.open(`${urlAux}/mydreamcircle/${childId}`);
            }
        }
    }

    comeBack() {
        if (this.props.isChildPageCall) {
            history.push("/childrenlist");
        } else {
            history.push("/teammember");
        }
    }

    render() {
        const { memberDetails } = this.props;

          return (
            <Grid container >
                <Grid xs={1} item className={"avatar-image-container"}>
                    <Img src={memberDetails.avatarFile ? memberDetails.avatarFile : avatar} style={{ width: "170px" }}></Img>
                </Grid>
                <Grid xs={10}  item className="avatar-heading-container">
                    <Typography className='blue-headering'>
                            {this.props.labelName}
                    </Typography>
                    <Typography className={ this.props.memberDetails.dreamCircleDetails?"avatar-name-twice":"avatar-name"}>
                            {this.props.memberDetails.fullName}
                    </Typography>
                  {  this.props.memberDetails.dreamCircleDetails ?
                    <Typography className={"avatar-name-twice"}>
                            {this.props.memberDetails.dreamCircleDetails.circleName}
                    </Typography>:null }
                    {this.props.isChildPageCall ?
                        <ButtonComponent toolTip="View Dream Circle" text="View Dream Circle"  onClick={()=>this.onClick(this.props.childId)} cssClasses={"view-profile"}></ButtonComponent> :null}
                </Grid>
            </Grid>
        )
    }
}

export default AvatarComponent; 
