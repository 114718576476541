import React , {FunctionComponent, useEffect,useLayoutEffect, useState, useRef}  from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  MenuItem,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import Wrapper from '../../../../baseComponents/wrapper';
import { Formik, Field, Form } from 'formik';
import Autocomplete from "@material-ui/lab/Autocomplete";
// import {TextField } from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase';
import * as yup from 'yup';
import {childrenService,categoryService} from '../../../../../services'
import moment from 'moment'
import {Notifier, notifierProps} from '../../../../components/notifier/notifier'
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { FormatDate } from '../../../../../common/formatDate';
const closeIcon= require('../../../../../assets/images/close_icon.png');

export interface ConfirmationOptions {
    catchOnCancel?: boolean;
   
   
    // description: string;
  }
 
  export interface AddChildDialogProps extends ConfirmationOptions {
    openAction: boolean;
    teamMemberData:any;
    onClose: () => void;
  }

  const storyDataInitialState :any=""
  const snackBarStatusInitialState:any="";

  export const AddChildDialog: FunctionComponent<AddChildDialogProps> = ({
    openAction,
    teamMemberData,
    onClose
  }) => {
           
       const [selectedDate, setSelectedDate] = React.useState<Date>(
                new Date(),
              );
    const [storyUpdatedData, setStoryData]= useState(storyDataInitialState);
    const [snackBarStatus, setsnackBarStatus]= useState(snackBarStatusInitialState);
    const [childrenList, setChildrenList]= useState([] as any)
    const [categoryList, setcategoryList]= useState([] as any)
    const [selectedStartDate, setSelectedStartDate] = React.useState<Date >(
      new Date( ),
    );
    const [childValue, setChildValue] = React.useState({value:0,text:""});
    const [categoryValue, setCategoryValue] = React.useState({value:0,text:""});
    const [textValue, setTextValue] = React.useState("");
    const schema = yup.object().shape({
      childId:yup.number().required(),
      startDate:yup.string().required(),
     
     
   });
   

   const handleDateChange =  (date: any) => {
    if(date!==null){
      setSelectedDate(date)
    }
//    this.state.handleDateChange(val);
  };

interface selectData {
      value:number,
      text:string
  }
  //  console.log(storyData)



  const handleStartDateChange = date => {
    if(date){
    setSelectedStartDate(date._d);
    }
  };

  const onChildChange = (event, values) => {
    setChildValue(values)
  }
  const onCategoryChange = (event, values) => {
    setCategoryValue(values)
  }
  const handleTextChange = event => {
    setTextValue(event.target.value);
  };

const closePopup=()=>{
    if (typeof onClose === 'function') {
      onClose();
    }
}

  useEffect(
      () => {

        let childListData:selectData[]=[];
       
          childrenService.getChildrenAll().then((children:any)=>{
            if(children.success)
            {
             children.data.map(child=>{
                       let data:any={
                             value:child.id,
                             text: child.fullName    
                         }
                         childListData.push(data);
                  }
             )
            }
         })
         setChildrenList( {childrenList:childListData});
       
      
       
        let categoryData:selectData[]=[];

      
        categoryService.getAll().then((categories:any)=>{
           if(categories.success)
           {
            categories.data.map(category=>{
                      let data:any={
                            value:category.id,
                            text: category.categoryName    
                        }
                        categoryData.push(data);
                 }
            )
           }
        })
        setcategoryList( {categoryList:categoryData});
      
        },[]
  
    );
    interface Values {
      childId:number,
      categoryId:number,
      categoryName:string,
      teamMemberId:number,
      startDate:string,
      addTeam:string
    }
   
    console.log(childrenList.childrenList, categoryList.categoryList)
    return (
     <>
      <Dialog open={openAction} className="popup-edit-details admin-background">
        <DialogContent className={"edit-teamMember-AddChild"}>
        <Wrapper title={""} >
                    <Formik<Values>
                    initialValues={{
                     
                        childId:0,
                        categoryId:0,
                        categoryName:"",
                        teamMemberId:teamMemberData.id,
                        startDate: "",
                        addTeam:"c"
                    
                    }}
                    // validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values)
                        if(childValue && childValue.value >0)
                        {
                          values.childId=childValue.value
                        }
                        if(categoryValue && categoryValue.value >0)
                        {
                          values.categoryId=categoryValue.value
                        }else if(textValue!==""){
                          values.categoryId=0;
                          values.categoryName=textValue
                        }
                        values.startDate=FormatDate.trasformDate(selectedStartDate);
                        setTimeout(() => {
                        setSubmitting(false);
                        
                          openAction=false;
                            childrenService.addChildToTeamMember(values).then((data:any)=>{
                              if(data.success)
                              {
                                const stateObject={
                                  msg: data.message ,
                                  status:true,
                                  date:new Date(),
                                  variant:'success'
                              }
                              setsnackBarStatus(stateObject);
                                // closePopup()
                              }else{
                                console.log(data)
                                const stateObject={
                                  msg: data.errors.message ,
                                  status:true,
                                  date:new Date(),
                                  variant:'error'
                              }
                              setsnackBarStatus(stateObject );
                              console.log(stateObject)
                              }

                            })
                        }, 2000)
                       
                    }}
                    render={({ setFieldTouched, handleChange,  submitForm, isSubmitting, values }) => (
                        <>
                        <h2>
                          <img src={closeIcon} onClick={closePopup} />
                        </h2>
                        <h2 className={"popup-heading margin-top"} > {teamMemberData.fullName } </h2>
                        <h2 className={"popup-heading"}> {"Add New Child"} </h2>
                      
                        
                        <Form className="edit-detail-form "  style={{marginTop:"50px"}}>
                            <Grid container spacing={3} xs={8} item className={"add-user"} >
                                <Grid item xs={12}className={"auto-complete-row"}>
                                    <InputBase
                                        defaultValue="Child"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                      <Autocomplete
                                        id="childId"
                                        options={childrenList.childrenList}
                                        getOptionLabel={(option: selectData) => option.text}
                                        onChange={onChildChange}
                                        renderInput={params => (
                                          <TextField {...params} 
                                           placeholder="Select Child" 
                                           margin="normal"
                                           variant="outlined"
                                              />
                                        )}
                                      />
                                 
                                </Grid>
                                <Grid item xs={12} className={"auto-complete-row"}>
                                    <InputBase
                                        defaultValue="Type"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    /> 
                                        <Autocomplete
                                        id="categoryId"
                                        freeSolo
                                        className={"auto-complete"}
                                        options={categoryList.categoryList}
                                        getOptionLabel={(option: selectData) => option.text}
                                        onChange={onCategoryChange}
                                        renderInput={params => (
                                          <TextField {...params}   
                                          placeholder="Enter the Team Member Type" 
                                          margin="normal"
                                          variant="outlined"
                                          onChange={handleTextChange} 
                                         />
                                        )}
                                      />
                                </Grid>
                                <Grid item xs={12} className={"row"}>
                                    <InputBase
                                        defaultValue="Start Date"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    /> 
                                     <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            name="dateOfBirth"
                                            format="DD/MM/YYYY"
                                            value={selectedStartDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                      
                        </Grid>
                         
                                <Grid item className={"margin-top button-container"}  xs={12}>
                                    {isSubmitting && <LinearProgress />}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                              className={"green-button"}
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                                >
                                                Add
                                            </Button>

                                        </Grid>
                                </Grid>
                        </Form>
                        </>
                    )}
                    />
                       {snackBarStatus.status ? 
                     <Notifier   variant={snackBarStatus.variant}  key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg}  /> : null}
                </Wrapper>
        </DialogContent>
        <DialogActions>
              {/* <Button color="primary" onClick={onSubmit}>
                Save
              </Button> */}
        </DialogActions>
      </Dialog>
      { snackBarStatus.status ? 
                     <Notifier   variant={snackBarStatus.variant}  key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg}  /> : null} 
      </>
    );
  };
