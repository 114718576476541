import * as React from 'react';
import { authenticationService, userService } from '../../../services';
import { Router } from 'react-router-dom';
import { history } from '../../../helper';
import { Grid, Link, Box, MenuItem, Badge, Popper, Grow, ClickAwayListener, Paper } from '@material-ui/core'
import './headerNav.scss';
import { userRole } from '../../../enums/index';
import NotificationList from '../notificationList/notificationList'
import { notificationService } from '../../../services/notificationService';
import ModalNotificationList from '../modal/modalNotificationList';
import ModalResetPassword from '../modal/modalResetPassword';
import config from '../../../config';
import socketIOClient from "socket.io-client";
import { async } from 'rxjs/internal/scheduler/async';

const logoTop = require('../../../assets/images/logo.png');
export interface Props {
    className: string,
    currentUser: any,
    location: any,
}
export interface State {
    isAdmin: boolean,
    display: string,
    pathname: string,
    bagdeNotification: number,
    child: any,
    showNotification: boolean,
    notificationData: any,
    showNotificationModal: boolean,
    showResetPasswordModal: boolean,
    response: string,
    endPoint: any,
    socketPath:any,
}

let extraMenuItems: Array<any> = [];
let childUser:any = null;
let userInfo:any = null;

class HeaderNav extends React.Component<Props, State>{

    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            display: "none",
            showNotification: false,
            pathname: "",
            bagdeNotification: 0,
            child: null,
            notificationData: null,
            showNotificationModal: false,
            showResetPasswordModal:false,
            response: "",
            endPoint: config.apiURLSocket,
            socketPath: config.apiSocketPath,
        };
    }

    joinSocket = async (userId) => {
        const endpoint = this.state.endPoint;
        const socketPath = this.state.socketPath;
        const socket = await socketIOClient(endpoint, { origins: '*:*', path:`${socketPath}`});
        await socket.removeAllListeners();
        await socket.disconnect();
        await socket.connect();
        await socket.emit("JoinSocket", userId);
        await socket.on("notification", data => {
            console.log(data, "this is socketed resturj")
            this.getNotificationList(userId);
        });
    }
 

    componentWillMount() {
        userInfo = authenticationService.currentUserValue.userData;
        childUser = authenticationService.isTeamMemberChildValue;
        const currentId = childUser ? childUser.userId : this.props.currentUser.userId;
        this.joinSocket(currentId);
        this.setState({ child: childUser });
        this.getNotificationList(currentId);
        this.props.location.pathname === "/" ?
            this.setState({ pathname: `/newsfeed/${currentId}` }) : this.setState({ pathname: this.props.location.pathname });
    }


    handleClick = (menuItem, userId) => {
        this.closeExtraMenu();
        console.log(menuItem.name)
        switch (menuItem.name) {
            case 'Log Out':
                if (this.state.child) {
                    authenticationService.logoutLoginAsChild();
                    history.push(`/newsfeed/${this.props.currentUser.userId}`);
                    window.location.reload();
                } else {
                    authenticationService.logout().then(res => {
                        if (res.success) {
                            history.push(menuItem.href);
                        } else {
                            //We need to put information of error. Example show popup about error
                        }
                    });
                }
                break;
            case 'Administration':
                history.push(menuItem.href);
                break;
            case 'Change Password':
                this.openResetPasswordModal();
                break;
            case 'Help':
                const win = window.open(menuItem.href, '_blank');
                 win &&  win.focus();
                break;
            case 'default':
                break;
        }
    }

    openNotification = (bagdeNotification:number, userId:number) => {
        if(bagdeNotification > 0){
            notificationService.updateNotificationBadgeRead(userId).then((data:any) => {
                if(data.success){
                    this.setState({bagdeNotification: 0});
                }else{
                    //ERROR
                }
            }, error =>{
                //ERROR
                console.log(error.message);
                
            })
        }
        this.setState({ showNotification: true });
    }

    closeNotification = () => {
        this.setState({ showNotification: false });
    }

    openNotificationModal = () => {
        this.setState({ showNotification: false });
        this.setState({ showNotificationModal: true });
    }

    closeNotificationModal = () => {
        this.setState({ showNotificationModal: false });
    }


    openResetPasswordModal = () => {
        this.setState({ showResetPasswordModal: true });
    }

    closeResetPasswordModal = () => {
        this.setState({ showResetPasswordModal: false });
    }

    openExtraMenu() {
        const changeTo = this.state.display === 'none' ? 'block' : 'none';
        this.setState({ display: changeTo });
    }

    closeExtraMenu() {
        this.setState({ display: 'none' });
    }

    getNotificationList(userId:number) {
        notificationService.getNotificationsList(userId).then((data: any) => {
            if (data.success) {
                this.setState({ notificationData: data.data });
                this.setState({ bagdeNotification: data.data.countBadgeNotRead });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);

        })
    }

    //this method creates extra menu
    generateExtraMenu(roleId: number): Array<any> {
        let roleIdAux = this.state.child ? this.state.child.roleId : roleId;
        let auxList: Array<any> = [];
        switch (roleIdAux) {
            case userRole.Admin:
                auxList = [
                    { name: "Change Password", href: "#", icon: "" },
                    { name: "Administration", href: "/childrenlist", icon: "" },
                    { name: "Help", href: config.helpUrl, icon: "" },
                    { name: "Log Out", href: "/login", icon: "" },
                ];
                break;
            case userRole.teamMember:
                auxList = [
                    { name: "Change Password", href: "#", icon: "" },
                    { name: "Help", href: config.helpUrl, icon: "" },
                    { name: "Log Out", href: "/login", icon: "" },
                ];
                break;
            case userRole.child:
                auxList = [
                    { name: "Change Password", href: "#", icon: "" },
                    { name: "Log Out", href: "/login", icon: "" },
                ];
                break;
            default:
                break;
        }
        return auxList;

    }

    render() {
        const { pathname, bagdeNotification, child, notificationData, showNotification, showNotificationModal , showResetPasswordModal} = this.state;
        const { currentUser } = this.props;
        const currentUserId = child ? child.userId : currentUser.userId;
        let menuItems: Array<any> = [];
        if (child || currentUser.roleId === userRole.child) {
            menuItems = [
                { name: "Friends", href: `/newsfeed/${currentUserId}`, icon: "" },
                { name: "Avatar", href: `/avatar/${currentUserId}`, icon: "" },
                { name: "Profile", href: `/profile/${currentUserId}`, icon: "" },
                { name: "Dream Circle", href: `/mydreamcircle/${currentUserId}`, icon: "" },
            ];
        } else {
            menuItems = [
                { name: "Friends", href: `/newsfeed/${currentUserId}`, icon: "" },
                { name: "Avatar", href: `/avatar/${currentUserId}`, icon: "" },
                { name: "Profile", href: `/profile/${currentUserId}`, icon: "" },
            ];
        }
        extraMenuItems = this.generateExtraMenu(currentUser.roleId);
        return (
            <div className={"header"}>
                <Router history={history}>
                    <Grid className={this.props.className}>
                        <Grid item xs={12}>
                            <Box className="header-box-menu">
                                {menuItems.map((menuItem, index) => (
                                    <Link
                                        key={index}
                                        className={pathname === menuItem.href ? "menu-selected" : ''}
                                        onClick={() => this.handleClick(menuItem, currentUserId)}
                                        href={menuItem.href}
                                        style={{ position: "relative" }}
                                    >{menuItem.name}</Link>
                                ))}
                                <MenuItem className="user-name-menu" onClick={() => this.openNotification(bagdeNotification, currentUserId)}>
                                    <Badge color="secondary" badgeContent={bagdeNotification !== 0 ? bagdeNotification : null}>
                                        <img src={require('../../../assets/images/notification_icon.svg')} ></img>
                                    </Badge>
                                </MenuItem>
                                <Popper open={showNotification} transition disablePortal className="notification">
                                    <Paper style={{ borderBottomLeftRadius: "25px", borderBottomRightRadius: "25px" }}>
                                        <ClickAwayListener onClickAway={() => this.closeNotification()}>
                                            {
                                                notificationData ?
                                                    <Grid item xs={12} >
                                                        <NotificationList data={notificationData} isModal={false} currentUser={currentUser} closeNotification={this.closeNotification}></NotificationList>
                                                        <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                                                            <Link component="button" onClick={() => this.openNotificationModal()}>
                                                                <Box component="span" style={{ fontWeight: 600 }}>See all</Box>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>

                                                    : null}
                                        </ClickAwayListener>
                                    </Paper>
                                </Popper>
                                <MenuItem className="user-name-menu" onClick={this.openExtraMenu.bind(this)}>{child ? child.userName : userInfo.fullName}</MenuItem>
                            </Box>
                            {
                                extraMenuItems.length > 0
                                    ? <Box className="extra-menu">
                                        <Box className="extra-box-menu" style={this.state}>
                                            {extraMenuItems.map((menuItem, index) => (
                                                <MenuItem key={index} onClick={() => this.handleClick(menuItem, currentUserId)}>{menuItem.name}</MenuItem>
                                            ))}
                                        </Box>
                                    </Box>
                                    : null
                            }
                        </Grid>

                    </Grid>
                </Router>
              
                {showNotificationModal ?
                    <ModalNotificationList
                        data={notificationData}
                        backGroundColor={""}
                        callback={this.closeNotificationModal}
                        childInfo={""}
                        currentUser={currentUser}
                        setOpen={showNotificationModal}
                    ></ModalNotificationList> : null}
         
               {showResetPasswordModal ?
                    <ModalResetPassword
                        data={notificationData}
                        backGroundColor={""}
                        titleFirst="Reset Password"
                        callback={this.closeResetPasswordModal}
                        logo="backgroud-fish"
                        currentUser={currentUser}
                        setOpen={showResetPasswordModal}
                    ></ModalResetPassword> : null}
            
            </div>
        );
    }
}

export default HeaderNav;
