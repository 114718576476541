import React from 'react';
import './formStory.scss';
import { Form, Formik } from 'formik/dist/index';
import { Grid, Typography, InputBase, Select, TextField, MenuItem, FormControl, withStyles, Theme, createStyles, FormControlLabel, Button, makeStyles, CircularProgress, Box } from '@material-ui/core';
import { FormatDate } from '../../../common/formatDate';
import { behavoursService, storyService } from '../../../services';
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Notifier } from '../notifier/notifier';
import { green } from '@material-ui/core/colors';


export interface StoryInformation {
    id: number
    story: string,
    childId: number,
    teamMemberId: number,
    behaviourId: number,
    isPublished: number, // 0 = false and 1 = true
    isCompleted: number, // 0 = false and 1 = true
    creationOn: string,
    submittedBy: string,
    submittedFor: string
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props {
    className: any;
    childId: number;
    currentUser: any;
    clallback: any;
    data?: any;
    behaviour?: any
}

interface PropsSwitch extends SwitchProps {
    classes: Styles,
}

export interface State {
    behavioursList: Array<any>;
    setOpen: boolean;
    isPublished: boolean;
    isCompleted: boolean,
    selectedDate: Date,
    snackBarStatus: any;
    isLoading: boolean;
    data: any;
    isNewRegister: boolean;

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
    }),
);

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1)
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#52d869",
                    opacity: 1,
                    border: "none"
                }
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff"
            }
        },
        thumb: {
            width: 24,
            height: 24
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.primary[400]}`,
            backgroundColor: theme.palette.primary[50],
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"])
        },
        checked: {},
        focusVisible: {}
    })
)(({ classes, ...props }: PropsSwitch) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

let formatDate: FormatDate = new FormatDate();

class FormStory extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: null,
            date: new Date(),
            variant: 'error'
        }
        this.setState({ snackBarStatus: stateObject });
        this.state = {
            behavioursList: [],
            setOpen: false,
            isPublished: false,
            isCompleted: false,
            selectedDate: new Date(),
            snackBarStatus: stateObject,
            isLoading: false,
            data: null,
            isNewRegister: false,
        };
    }

    async componentWillMount() {
        await this.getData();
        if (this.props.data) {
            this.setState({
                data: this.props.data,
                selectedDate: new Date(this.props.data.creationOn),
                isCompleted: this.props.data.isCompleted === 1 ? true : false,
                isPublished: this.props.data.isPublished === 1 ? true : false,
                isNewRegister: false,

            });
        } else {
            this.setState({ isNewRegister: true });
        }

    }

    async getData() {
        let locations: Array<any> = [];
        await behavoursService.getAllBehavours().then((data: any) => {
            if (data.success) {
                data.data.map(behaviour => {
                    let data: any = {
                        value: behaviour.id,
                        label: behaviour.behaviourName
                    }
                    locations.push(data);
                }
                );
                this.setState({ behavioursList: locations });
            } else {
                //ERROR
            }
        })
    }

    handleClose = () => {
        this.setState({ setOpen: false });
    };

    handleOpen = () => {
        this.setState({ setOpen: true });
    };

    handleDateChange = (date: any) => {
        if(date){
        this.setState({ selectedDate: date });
        }
    }

    handleSelectChange = (values: any, type: any) => {
        if (type === "Completed") {
            if (values.isCompleted === 1) {
                values.isCompleted = 0;
                this.setState({ isCompleted: false });
            } else {
                values.isCompleted = 1;
                this.setState({ isCompleted: true });
            }
        } else if (type === "Published") {
            if (values.isPublished === 1) {
                values.isPublished = 0;
                this.setState({ isPublished: false });
            } else {
                values.isPublished = 1;
                this.setState({ isPublished: true });
            }
        }
    }

    submitCompleted(object: StoryInformation, isNewRegister: boolean){
        object.creationOn = formatDate.trasformDate(this.state.selectedDate);
        object.isCompleted = 1;
        this.submitStory(object, isNewRegister);
    }

    submitStory = async (object: StoryInformation, isNewRegister: boolean) => {
        if (object) {
            this.setState({ isLoading: true });
            const stateObject = {
                msg: "",
                status: true,
                date: new Date(),
                variant: ''
            }
            if (isNewRegister) {
                const obj = {
                    childId: object.childId,
                    creationOn: object.creationOn,
                    behaviourId: object.behaviourId,
                    teamMemberId: object.teamMemberId,
                    isCompleted: object.isCompleted,
                    story: object.story
                }
                await storyService.addStory(obj).then((data: any) => {
                    if (data.success) {
                        stateObject.msg = data.message;
                        stateObject.variant = "success";
                    } else {
                        stateObject.msg = data.message;
                        stateObject.variant = "error";
                    }
                    this.setState({ snackBarStatus: stateObject });
                    this.setState({ isLoading: false });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                        //if (data.success) this.props.clallback(true);
                    }, 2000);
                }, error => {
                    //ERROR
                    console.log(error.message);
                    this.setState({ isLoading: false });
                });
            } else {
                const obj = {
                    creationOn: object.creationOn,
                    behaviourId: object.behaviourId,
                    isPublished: object.isPublished,
                    isCompleted: object.isCompleted,
                    story: object.story
                }
                storyService.updateStory(object.id, obj).then((data: any) => {
                    if (data.success) {
                        stateObject.msg = data.message;
                        stateObject.variant = "success";
                    } else {
                        stateObject.msg = data.message;
                        stateObject.variant = "error";
                    }
                    this.setState({ snackBarStatus: stateObject });
                    this.setState({ isLoading: false });
                    setTimeout(() => {
                        stateObject.status = false;
                        this.setState({ snackBarStatus: stateObject });
                        //if (data.success) this.props.clallback(true);
                    }, 2000);
                }, error => {
                    //ERROR
                    console.log(error.message);
                    this.setState({ isLoading: false });
                });
            }

        } else {
            //ERROR
        }
    }

    render() {

        const { className, childId, currentUser, data, behaviour } = this.props;
        const { behavioursList, setOpen, isLoading, isCompleted, isPublished, selectedDate, isNewRegister } = this.state;
        return (
            <div className={className}>
                <Formik<StoryInformation>
                    initialValues={{
                        id: data ? data.id : "",
                        childId: childId,
                        creationOn: data ? data.creationOn : "",
                        behaviourId: data ? data.behaviour.id : behaviour ? behaviour.id : 0,
                        teamMemberId: currentUser.userId,
                        isPublished: data ? data.isPublished : 0,
                        isCompleted: data ? data.isCompleted : 0,
                        story: data ? data.story : "",
                        submittedBy: data && data.submittedBy ? data.submittedBy : data && data.teamMember ? data.teamMember.firstName+" "+ data.teamMember.lastName : currentUser.userName,
                        submittedFor: data ? data.submittedFor : "",
                    }}
                    onSubmit={(values: StoryInformation) => {
                        values.creationOn = formatDate.trasformDate(this.state.selectedDate);
                        this.submitStory(values, isNewRegister);
                    }}
                    render={({ handleChange, submitForm, values, handleBlur, errors }) => (
                        <form className={"form-new-story"}>
                            <Grid container xs={12} item >
                                <Grid item xs={8} className={"row row-form-story"}>
                                    <InputBase
                                        defaultValue="Date"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        className={"label"}
                                    />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            name="date"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="dd/MM/yyyy"
                                            disabled={isLoading}
                                            value={selectedDate}
                                            onChange={(date) => this.handleDateChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={8} className={"row row-form-story"}>
                                    <InputBase
                                        defaultValue="Behaviour"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        className={"label"}
                                    />
                                    <FormControl>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            name="behaviourId"
                                            open={setOpen}
                                            onClose={this.handleClose}
                                            onOpen={this.handleOpen}
                                            value={values.behaviourId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isLoading}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {behavioursList.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.behaviourId && <div>{errors.behaviourId}</div>}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={8} className={"row row-form-story"}>
                                <InputBase
                                        defaultValue="Submitted By"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="submittedBy"
                                        defaultValue={values.submittedBy}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} className={"row row-form-story"}>
                                    <InputBase
                                        defaultValue="Completed"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        className={"label"}
                                    />
                                    <FormControlLabel
                                        name="isCompleted"
                                        disabled={isLoading}
                                        control={
                                            <IOSSwitch
                                                onChange={() => this.handleSelectChange(values, "Completed")}
                                                checked={isCompleted}
                                            />
                                        }
                                        label=""
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} className={"row row-form-story"}>
                                    <InputBase
                                        defaultValue="Published"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        className={"label"}
                                    />
                                    <FormControlLabel
                                        disabled
                                        name="isPublished"
                                        control={
                                            <IOSSwitch
                                                onChange={() => this.handleSelectChange(values, "Published")}
                                                checked={isPublished}
                                            />
                                        }
                                        label=""
                                    />
                                </Grid> */}
                                <Grid item xs={12} className={"row row-form-story"}>
                                    <TextField
                                        className={"text-story"}
                                        id="filled-multiline-static"
                                        label="Story"
                                        multiline
                                        rows="10"
                                        variant="filled"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.story}
                                        type="text"
                                        name="story"
                                        disabled={isLoading || currentUser.id === childId}
                                    />
                                </Grid>
                                { !isCompleted ?
                                    <Grid item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px", display: "flex" }}>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                className={"green-button"}
                                                disabled={isLoading}
                                                onClick={submitForm}>Save for Later {isLoading && <CircularProgress size={24} className={"loading"} />}</Button>
                                        </Box>
                                        <Box style={{ paddingLeft: "10px" }}>
                                            <Button
                                                variant="contained"
                                                className={"blue-button"}
                                                disabled={isLoading}
                                                onClick={() => this.submitCompleted(values, isNewRegister)}>Submit {isLoading && <CircularProgress size={24} className={"loading"} />}</Button>
                                        </Box>

                                    </Grid> : null
                                }

                            </Grid>
                        </form>
                    )}
                />
                {this.state.snackBarStatus.status ?
                    <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </div>
        );
    }
}

export default FormStory;