import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const starService = {
    grantNewStar
  
    
};


function grantNewStar(values:any)
{
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() ,  'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/grantNewStar/`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}



