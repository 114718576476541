import React, {useState, useContext} from 'react'
import AdminLayout from './components/adminLayout'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { Formik, Field, Form } from 'formik';
import { LinearProgress ,
    MenuItem,  
     Theme,
    createStyles, } from '@material-ui/core';
import {TextField } from 'formik-material-ui'
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as yup from 'yup';
import { userService } from '../../services';
import Wrapper from '../baseComponents/wrapper';
import { FormatDate } from '../../common/formatDate';
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib]
import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
  

// import { InputBase } from '../src/InputBase';
// import FormValues from '../baseComponents/formValues';
import {officeService, categoryService} from '../../services'
import {Notifier} from '../components/notifier/notifier';
import { stat } from 'fs';
import { parsePhoneNumber } from 'libphonenumber-js'
import ProgressBar from '../components/progressBar/progressBar';



export interface  Props {
    className:string;
    location:any;
    history:any;
    
}

export interface  State {
    selectedStartDate:any,
    selectedDobDate:any,
    handleDateChange:any,
    snackBarStatus:any,
    variant:any,
    locationList:valueLableData[],
    loading:boolean,
    categoryList:any[],
    textValue:string,
}
 
interface valueLableData {
    value:number,
    label:string
}

interface selectData {
    value:number,
    text:string
}


export class UserCreate extends React.Component<Props, State>{
    //  export default  function UserCreate(){

        constructor(props) {
            super(props);
            
            this.state = {
                selectedStartDate: new Date(),
                selectedDobDate: new Date(),
                handleDateChange: null,
                snackBarStatus: null,
                categoryList:[],
                variant: null,
                locationList:[],
                loading: false,
                textValue:""
            }
            // this.setState({handleDateChange:moment()});
            //this.setState({selectedStartDate: new Date()});
            //this.setState({selectedDobDate: new Date()});
            // this.cancelItem_onClick = this.cancelItem_onClick.bind(this);
          
          }
          cancelItem_onClick=()=>{
            // openSnackbar({ message: 'Incorrect answer.' });
            window.history.back();
            // const stateObject={
            //     msg:"there is some error ",
            //     status:true,
            //     date:new Date(),
            //     variant:'success'
            // }
            // this.setState({snackBarStatus:stateObject} );
          }

          handleChange(e){
              console.log(e);
            }

        componentWillMount() {
            let locations:valueLableData[]=[];
            let categoryData:selectData[]=[];      
            officeService.getAll().then((data:any)=>{
               if(data.success)
               {
                   data.data.forEach((element:any) => {
                       let office:valueLableData = {
                        value:element.id,
                        label: element.officeName  
                       }
                       locations.push(office);
                   });
                   this.setState({locationList:locations})
               }
            })

            categoryService.getAll().then((categories:any)=>{
                let categoryData:valueLableData[]=[];
                if(categories.success)
                {
                 categories.data.map(category=>{
                           let data:any={
                                 value:category.id,
                                 text: category.categoryName    
                             }
                             categoryData.push(data);
                      }
                 )
                }
                this.setState({categoryList:categoryData});
             })
            // this.setState({handleDateChange:moment()});
            this.setState({selectedStartDate: new Date()});
            this.setState({selectedDobDate: new Date()});
            const stateObject={
                msg:" ",
                status:null,
                date:new Date(),
                variant:'error'
            }
            this.setState({snackBarStatus:stateObject} );
        }
          
    render(){
        // const [selectedDate, setSelectedDate] = useState<Date | null>(
        //     new Date('2014-08-18T21:11:54'),
        //   );
        
        //   const handleDateChange = (date: Date | null) => {
        //     setSelectedDate(date);
        //   };
         // The first commit of Material-UI
        //  const [selectedDate, handleDateChange] = React.useState(new Date);

     
     
        interface Values {
            uniqueId:string,
            userName:string,
            firstName:string,
            lastName:string,
            dateOfBirth:string,
            email:string,
            streetAddress:string,
            suburb:string,
            state:string,
            postCode:number,
            phoneNumber:string,
            mobileNumber:string,
            startDate:string,
            aboutMe:string,
            gender:string,
            officeId:number,
            roleId:number,
            jobTitle?:any
          }

          const schema = yup.object().shape({
             uniqueId:yup.string().required(),
             userName:yup.string().required(),
             firstName:yup.string().required(),
             lastName:yup.string(),
            //  dateOfBirth:yup.string(),
             email: yup
                .string()
                .email()
                .required(),
            streetAddress:yup.string().required(),
            suburb:yup.string().required(),
            state:yup.string().required(),
            postCode:yup.number().required(),
            phoneNumber: this.props.location.search!=='?child'?yup.string().required()
            .test('is-valid-phone', "Phone Number invalid", value => {
                if(!value) return false;
                let number = parsePhoneNumber(value, "AU");
                return number.isValid();
            }):yup.string(),
            mobileNumber:yup.string()
            .required()
            .test('is-valid-mobile', "Mobile Number invalid", value => {
                if(!value) return false;
                let number = parsePhoneNumber(value, "AU");
                return number.isValid();
            }),
            // startDate:yup.string().required(),
            aboutme:yup.string(),
            location:yup.number().positive()
          });

            const styles = (theme: Theme) =>
            createStyles({
                container: {
                display: 'flex',
                flexWrap: 'wrap',
                },
                textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: 200,
                },
                dense: {
                marginTop: 19,
                },
                menu: {
                width: 200,
                },
            });
            const states = [
                {
                    value: "Canberra",
                    label:"Canberra"

                },
                {
                  value: 'NSW',
                  label: 'New South Wales',
                },
                {
                    value: "NT",
                    label:"Northern Territory"

                },
                {
                  value: 'Queensland',
                  label: 'Queensland',
                },
                {
                  value: 'SA',
                  label: 'South Australia',
                },
                {
                  value: 'Tasmania',
                  label: 'Tasmania',
                },
                {
                  value: 'Victoria',
                  label: 'Victoria',
                },
                {
                  value: 'WA',
                  label: 'Western Australia',
                }
              ];

              const gender = [
                {
                    value: 'm',
                    label: 'Male',
                  },
                  {
                    value: 'f',
                    label: 'Female',
                  },
              ]

      


        
            // const [selectedDate, setSelectedDate] = React.useState<Date | null>(
            //     new Date('2014-08-18T21:11:54'),
            //   );
            
            // const [selectedDate, handleDateChange] = React.useState(moment());
            const handleDateChange = val => {               
                if(val){
                this.setState({selectedStartDate:val._d})
            //    this.state.handleDateChange(val);
                }
              };
              const handleDOBDateChange = val => {
                  if(val){
                this.setState({selectedDobDate:val._d})
            //    this.state.handleDateChange(val);
                  }
              };


           const { locationList, loading } = this.state;
        return(
            <AdminLayout className={this.props.className} value={this.props.location.search==='?child'?"child":"team"} history={this.props.history}>
                {loading ? <ProgressBar></ProgressBar> : null}
                <Wrapper title={ this.props.location.search==='?child'?"Add Child":"Add Team Member"} >
                    <Formik<Values>
                    initialValues={{
                        uniqueId:"",
                        userName:"",
                        firstName:'',
                        lastName:'', 
                        dateOfBirth:"",
                        email:'',
                        streetAddress:'',
                        suburb:'',
                        state:'',
                        postCode:1234,
                        jobTitle: "",
                        phoneNumber:'',
                        mobileNumber:'',
                        startDate:"",
                        aboutMe:'',
                        officeId:1,
                        gender:'m',
                        roleId:this.props.location.search==='?child'?1:2,
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        this.setState({loading:true});
                        values.dateOfBirth=FormatDate.trasformDate(this.state.selectedDobDate);
                        values.startDate=FormatDate.trasformDate(this.state.selectedStartDate);
                        values.jobTitle = this.props.location.search==='?team' ? this.state.textValue : "";
                        setTimeout(() => {
                            userService.createUser(values).then((data:any)=>{
                                setSubmitting(false);
                                const stateObject={
                                    msg:data.message ,
                                    status:true,
                                    date:new Date(),
                                    variant:data.success?'success':'error'
                                }
                                this.setState({snackBarStatus:stateObject} );
                                if(data.success){
                                    this.setState({loading:false});
                                   setTimeout(() => {
                                    this.props.location.search==='?team' ? this.props.history.push({pathname:'/teammember'}) : this.props.history.push({pathname:'/childrenlist'});
                                    },1000);
                                } else {
                                    this.setState({loading:false});
                                    setTimeout(() => {
                                        stateObject.status = false;
                                        this.setState({snackBarStatus:stateObject})
                                    }, 5000);
                                }
                            }).catch((error) => {
                                console.log("ERROR",error);
                                setSubmitting(false);
                                this.setState({loading:false});
                                const stateObject={
                                    msg:error ,
                                    status:true,
                                    date:new Date(),
                                    variant:'error'
                                }
                                this.setState({snackBarStatus:stateObject} );
                                setTimeout(() => {
                                    stateObject.status = false;
                                    this.setState({snackBarStatus:stateObject})
                                }, 5000);
                            })
                        }, 2000);
                    }}
                    render={({ setFieldTouched, handleChange,  submitForm, isSubmitting, values }) => (
                        <Form className="margin-top" style={{ paddingBottom: "50px"}}>
                            <Grid container spacing={3} lg={6} className={"add-user"} >
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="Unique Id *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="uniqueId"
                                        component={TextField}
                                        variant="filled"
                                        autoFocus={true}
                                        disableUnderline={false}
                                        style={{width:"100%"}}
                                        onChange={e => {
                                            setFieldTouched('uniqueId');
                                            handleChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="User Name *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="userName"
                                        component={TextField}
                                        variant="filled"
                                        style={{width:"100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}> 
                                    <InputBase
                                        defaultValue="First Name *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="firstName"
                                        component={TextField}
                                        variant="filled"
                                        style={{width:"100%"}}
                                    />
                                
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="Last Name"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="lastName"
                                        component={TextField}
                                        variant="filled"
                                        style={{width:"100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="Date of Birth *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            name="dateOfBirth"
                                            format="DD/MM/YYYY"
                                            //variant="inline"
                                            value={this.state.selectedDobDate}
                                            onChange={handleDOBDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="Gender *"
                                      inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                      className={"label"}
                                  />
                                 <Field
                                    type="text"
                                    name="gender"
                                    select
                                    variant="filled"
                                    style={{width:"100%"}}
                                    SelectProps={{
                                    MenuProps: {
                                        className: "classes.menu",
                                    },
                                    }}
                                    // helperText="Please select gender"
                                    component={TextField}
                                    >
                                {gender.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="Email *"
                                      inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                      className={"label"}
                                  />
                                  <Field
                                      type="email"
                                      name="email"
                                      component={TextField}
                                      variant="filled"
                                      style={{width:"100%"}}
                                  />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="Street Address *"
                                        inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="streetAddress"
                                        component={TextField}
                                        variant="filled"
                                        style={{width:"100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="Suburb *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="suburb"
                                        component={TextField}
                                        variant="filled"
                                        style={{width:"100%"}}
                                    />
                                </Grid>
                               
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                    <InputBase
                                        defaultValue="State *"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                    <Field
                                        type="text"
                                        name="state"
                                        select
                                        variant="filled"
                                        style={{width:"100%"}}
                                        SelectProps={{
                                        MenuProps: {
                                            className: "classes.menu",
                                        },
                                        }}
                                        // helperText="Please select Range"
                                        // margin="normal"
                                        component={TextField}
                                    >
                                        {states.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                        ))}
                                    </Field>
                                </Grid> 
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                        <InputBase
                                            defaultValue="Post Code *"
                                            inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                            className={"label"}
                                        />
                                        <Field
                                            type="text"
                                            name="postCode"
                                            component={TextField}
                                            variant="filled"
                                            style={{width:"100%"}}
                                        />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue={this.props.location.search==='?child'? "Phone Number": "Phone Number *"}
                                      inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                      className={"label"}
                                  />
                                  <Field
                                      type="text"
                                      name="phoneNumber"
                                      inputProps={{maxLength: 10}}
                                      component={TextField}
                                      variant="filled"
                                      style={{width:"100%"}}
                                  />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="Mobile Number *"
                                      inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                      className={"label"}
                                  />
                                  <Field
                                      type="tel"
                                      name="mobileNumber"
                                      inputProps={{maxLength: 10,}}
                                      component={TextField}
                                      variant="filled"
                                      style={{width:"100%"}}
                                  />
                                </Grid>
                              
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="Start Date *"
                                      inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                      className={"label"}
                                  />
                                   <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            name="startDate"
                                            format="DD/MM/YYYY"
                                            //variant="inline"
                                            value={this.state.selectedStartDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {this.props.location.search==='?team' ? 
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                <InputBase
                                    defaultValue="Type"
                                    inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                    className={"label"}
                                /> 
                                    <Autocomplete
                                    id="categoryId"
                                    freeSolo
                                    className={"auto-complete"}
                                    options={this.state.categoryList}
                                    getOptionLabel={(option => typeof option === 'string' ? option : option.text)}
                                    //onChange={onCategoryChange}
                                    onInputChange={(event, newInputValue) => {
                                        this.setState({textValue:newInputValue});
                                      }}
                                    renderInput={params => (
                                        <Field {...params} 
                                            type="text"
                                            name="jobTitle"
                                            margin="normal"
                                            variant="filled"
                                            component={TextField}
                                            style={{width:"100%"}}
                                        />
                                    )}
                                  />
                            </Grid>
                            : null}
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="About Me"
                                      inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                      className={"label about-me-label"}
                                  />
                                  <Field
                                      type="text"
                                      name="aboutMe"
                                      multiline={true}
                                      rows={4}
                                      component={TextField}
                                      variant="filled"
                                      style={{width:"100%"}}
                                  />
                                </Grid>
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                  <InputBase
                                      defaultValue="Location *"
                                      inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                      className={"label"}
                                  />
                                 <Field
                                    type="text"
                                    name="officeId"
                                    select
                                    variant="filled"
                                    style={{width:"100%"}}
                                    SelectProps={{
                                    MenuProps: {
                                        className: "classes.menu",
                                    },
                                    }}
                                    // helperText="Please select location"
                                    component={TextField}
                                    >
                                {locationList.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid container className={"margin-top button-container"}  xs={12}>
                        {isSubmitting && <LinearProgress />}
                                <Grid item style={{margin:'0 20px 0 0'}}>

                                <Button
                                        variant="contained"
                                        className={"cancel-button"}
                                        onClick={this.cancelItem_onClick}
                                        >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                       className={"save-button"}
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        >
                                        Save
                                    </Button>

                                </Grid>
                        </Grid>
                        </Grid>
                        </Form>
                    )}
                    />
                     {this.state.snackBarStatus.status ? 
                     <Notifier   variant={this.state.snackBarStatus.variant}  key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg}  /> : null}
                </Wrapper>
            </AdminLayout>
        )
    }
}

