
import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import ButtonComponent from './buttonComponent'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import CustomToolbar from "../components/customToolbar";
import InputBase from '@material-ui/core/InputBase';


export interface  Props {
    // className:string;
    dreamCirleList:any;
    history:any;
    childId:number;
    // isChildrenStory:boolean;
}
 
export interface  State {
    isLoading:boolean;
    teamMemberData:any;

}
class DreamCircleComponent  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
       
    }

    onClick(){
        console.log('this is clicked ')
    }

    render(){
     
      let dreamCircle=  this.props.dreamCirleList
     
        const columns = [
          
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },{
                name:"circleName",
                label:"Circle",
                options:{
                    sort:true,
                    customBodyRender : (value, tableMeta, updateValue) => {
                        return (
                            <Typography component={'span'} noWrap={false}>
                                {value}
                            </Typography>
                        )
                    }
                }
            },{
                name:"stories",
                label: "Stories",
                options:{
                    sort:true
                }
            },
            {
                name:"stars",
                label:"Stars",
                options:{
                    sort:true,
                }
            },
            {
                name:"progress",
                label:"Progression",
                options:{
                    sort:true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography component={'span'} noWrap={true}>
                                {value?value+'%':'0%'}
                            </Typography>
                        );
                      }
                  
                }
            },{
                name: "",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      
                      <Button  variant="contained"  className="grid-button" 
                      onClick={() => { this.props.history.push({pathname:'/dreamcircle', data:{childId:this.props.childId,BehaviourId:tableMeta.rowData[0]}})}}
                      >
                            View
                      </Button>
                    );
                  }
                }
              },
           
        ];
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            search:false,
            selectableRowsHeader:false, 
            selectableRows:"none",
            pagination:false,
            caseSensitive:true,
            searchPlaceholder:"Search",
            // onRowClick: this.onRowClick,
            // customToolbar: () => {
            //     return (
            //       <CustomToolbar text={"View Dream Circle"} clickHandler={()=>this.onClick() } cssClasses={"blue-button"}/>
            //     );
            //   }
          };
       return( 
        <Grid  container>
                {/* <Grid  container>
                    <Grid item style={{width:'250px'}} className="detail-header">
                        <Typography variant="h4">
                                Dream Circles
                        </Typography>
                    </Grid>
                    <Grid>
                          <ButtonComponent toolTip="View Dream Circle" text="View Dream Circle" onClick={()=>this.onClick()} cssClasses={"blue-button"}></ButtonComponent> 
                    </Grid>
                </Grid> */}
                <MUIDataTable
                title={"Dream Circles"}
                data={dreamCircle}
                options={options}
                columns={columns}
                className={"trasnparent-muipaper-root"}
                />
        </Grid>
       )
    };
}
export default DreamCircleComponent;