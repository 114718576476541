import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface KanagrooProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface KanagrooState {
    height:number
  
}


export class Kanagroo extends React.Component<KanagrooProps, KanagrooState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:520})
       
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1" x="0px" y="0px" viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                   <path fill={backGroundColor} d="M403.3,58c0,0,4.4-34.6,0-49.7c0,0-3.1-8.2-14-8.1c-2.8,0.1-5.6,0.6-8.3,1.5c-13.6,4.6-42.2,17-58.6,35.2
                        c-17.7,19.6-25.9,94.1-28.3,120.8c-28.5-10.3-59.7-10.3-88.2,0c-2.4-26.6-10.5-101.1-28.3-120.8c-16.4-18.2-45-30.6-58.6-35.2
                        c-2.7-0.9-5.5-1.4-8.3-1.5c-10.9-0.1-14,8.1-14,8.1c-4.4,15,0,49.7,0,49.7c8.7,62.8,57.1,111.4,68.5,122
                        c-30.2,23.5-49.4,56.9-49.4,86.4c0,53.2,23.7,89.3,78.1,103c-9.6,9.7-51.2,53.9-25.5,69.3c8.3,5,15,3.5,20.5-1
                        c-0.3,6.2-0.4,12.8-0.3,19.5c0,0,0.1,22.4,1.4,34.4c0.4,3.8,1.2,7.5,2.3,11.1c-22.3,5.7-58.2,6.9-77,8.5
                        c-21.7,1.8-15.4,8.8,2.3,8.5c12.2-0.2,32.7,0,56.1,0.1c0.4,0.2,0.9,0.2,1.3,0.2h40.8c0.5,0,1-0.1,1.5-0.3l0,0
                        c26.2-0.9,29.6-5.5,35.9-12.6c8.5-9.4,10-31.8,10-31.8v-0.7l0,0c3.1-0.3,6.6-0.6,10-1.1c0,0-2.1,31.5,7.9,42.6c-0.9,1.8,0.5,4,3,4
                        h40.8c3.1,0,4.4-3.4,2-5.1c-4.2-2.9-11.1-6-21.7-6.2c2.6-5,4.1-11.2,4.8-17.3c1.4-11.9,1.4-34.4,1.4-34.4
                        c0.1-6.8-0.1-13.3-0.3-19.5c5.4,4.5,12.2,5.9,20.4,1c25.7-15.3-15.8-59.5-25.5-69.3c54.4-13.7,78.2-49.8,78.2-103
                        c0-29.5-19.3-62.9-49.4-86.4C346.2,169.4,394.6,120.8,403.3,58z M301.4,370.6L301.4,370.6L301.4,370.6z"/>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M318.1,179.5c0,0,32.1-29.3,37.1-64.9c0,0,2.2-17.3,0-24.8
                        c0,0-1.5-4.1-7-4c-1.4,0-2.8,0.3-4.1,0.7c-6.8,2.3-21.1,8.5-29.3,17.6c-10.9,12-14.5,65.1-14.5,65.1L318.1,179.5z"/>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M181.9,179.5c0,0-32.1-29.3-37-64.9c0,0-2.2-17.3,0-24.8
                        c0,0,1.5-4.1,7-4c1.4,0,2.8,0.3,4.1,0.7c6.8,2.3,21.1,8.5,29.3,17.6c10.9,12,14.5,65.1,14.5,65.1L181.9,179.5z"/>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M250,375.5c-18.7,0-35.3-1.6-49.9-4.7
                        c-8.4,38.2-11.9,98.1,49.9,98.1s58.4-59.9,49.9-98.1C285.4,373.9,268.8,375.5,250,375.5z"/>
                    <path opacity="0.4" fill="#BFBFBF" enable-background="new    " d="M339.3,319.3c-11.3,0-20.4,5.8-20.4,13c0,7.2,9.1,13,20.4,13
                        c11.3,0,20.4-5.8,20.4-13C359.7,325.1,350.5,319.3,339.3,319.3z"/>
                    <path opacity="0.4" fill="#BFBFBF" enable-background="new    " d="M160.8,319.3c11.3,0,20.4,5.8,20.4,13c0,7.2-9.1,13-20.4,13
                        c-11.3,0-20.4-5.8-20.4-13C140.3,325.1,149.5,319.3,160.8,319.3z"/>
                    <path fill="#FFFFFF" d="M175.9,263.8c15.3,0,27.8,12.4,27.8,27.8s-12.4,27.8-27.8,27.8s-27.8-12.4-27.8-27.8l0,0
                        C148.1,276.2,160.5,263.8,175.9,263.8"/>
                    <path fill="#662F00" d="M180.2,271.7c-11,0-19.8,8.9-19.8,19.9s8.9,19.8,19.9,19.8c11,0,19.8-8.9,19.8-19.9
                        C200.1,280.6,191.2,271.7,180.2,271.7C180.2,271.7,180.2,271.7,180.2,271.7z"/>
                    <path fill="#FFFFFF" d="M324.2,263.8c15.3,0,27.8,12.4,27.8,27.8s-12.4,27.8-27.8,27.8s-27.8-12.4-27.8-27.8l0,0
                        C296.4,276.2,308.8,263.8,324.2,263.8"/>
                    <path fill="#662F00" d="M319.8,271.7c11,0,19.8,8.9,19.8,19.9c0,11-8.9,19.8-19.9,19.8c-11,0-19.8-8.9-19.8-19.9
                        C299.9,280.6,308.8,271.7,319.8,271.7C319.8,271.7,319.8,271.7,319.8,271.7z"/>
                    <ellipse fill="#FFFFFF" cx="250" cy="324.3" rx="46.4" ry="29.6"/>
                    <path fill="#662F00" d="M274.8,311.7c-1.7,0-3.1,1.4-3.1,3.1c0,0,0,0,0,0c-0.3,5.1-4.7,9-9.9,8.7c-4.7-0.3-8.4-4-8.7-8.7v-1.6
                        c2.5-1.9,5.2-5.1,5.2-6.7c0-2.3-1.8-4.1-4.1-4.1c0,0,0,0,0,0h-8.3c-2.3,0-4.1,1.8-4.1,4.1l0,0c0,1.5,2.7,4.7,5.2,6.7v1.6
                        c-0.3,5.1-4.7,9-9.9,8.7c-4.7-0.3-8.4-4-8.7-8.7c0.1-1.7-1.2-3.2-2.9-3.3s-3.2,1.2-3.3,2.9c0,0.2,0,0.3,0,0.5
                        c0.1,8.6,7,15.4,15.6,15.4c4.8,0,9.3-2.3,12.2-6.1c5.1,6.9,14.8,8.3,21.7,3.1c3.9-2.9,6.2-7.5,6.2-12.4
                        C277.9,313.1,276.5,311.7,274.8,311.7z"/>
                    <g opacity="0.3">
                        <path fill="#1E1E1E" d="M324.9,520h-40.8c-3.1,0-4.4-3.4-2-5.1c4.3-3,11.4-6.2,22.4-6.2c11,0,18.1,3.2,22.4,6.2
                            C329.3,516.7,328,520,324.9,520z"/>
                        <path fill="#1E1E1E" d="M175.1,520h40.8c3.1,0,4.4-3.4,2-5.1c-4.3-3-11.4-6.2-22.4-6.2s-18.1,3.2-22.4,6.2
                            C170.8,516.7,172.1,520,175.1,520z"/>
                    </g>
                    <g opacity="0.5">
                        <path fill="#1E1E1E" d="M173.2,515c4.2-2.9,11.1-6,21.7-6.2c-1-2-1.8-4.1-2.5-6.2c-22.3,5.7-58.2,6.9-77,8.5
                            c-21.7,1.8-15.4,8.8,2.3,8.5c12.2-0.2,32.7,0,56.1,0.1c-1.4-0.4-2.2-1.9-1.7-3.3C172.3,515.8,172.6,515.3,173.2,515z"/>
                        <path fill="#1E1E1E" d="M250,475.3c-7.8-0.2-15.6-0.8-23.3-1.8c0,0,2.1,31.5-7.9,42.6c0.6,1.4,0,3-1.3,3.6c0,0-0.1,0-0.1,0
                            c26.2-0.9,29.6-5.5,35.9-12.6c8.5-9.4,10-31.8,10-31.8v-0.7C255.9,475.2,250,475.3,250,475.3z"/>
                    </g>
                </svg>
  
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid= <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path  fill={backGroundColor} d="M403.3,58c0,0,4.4-34.6,0-49.7c0,0-3.1-8.2-14-8.1c-2.8,0.1-5.6,0.6-8.3,1.5c-13.6,4.6-42.2,17-58.6,35.2
                        c-17.7,19.6-25.9,94.1-28.3,120.8c-28.5-10.3-59.7-10.3-88.2,0c-2.4-26.6-10.5-101.1-28.3-120.8c-16.4-18.2-45-30.6-58.6-35.2
                        c-2.7-0.9-5.5-1.4-8.3-1.5c-10.9-0.1-14,8.1-14,8.1c-4.4,15,0,49.7,0,49.7c8.7,62.8,57.1,111.4,68.5,122
                        c-30.2,23.5-49.4,56.9-49.4,86.4c0,54.5,24.9,91.2,82.4,104c-20,2.6-66.6,11.3-57.7,36.4c8.9,25.1,39.6,5.6,51.6-3.3
                        c-2.5,17.8-3.7,35.7-3.5,53.6c0,0,0.1,22.4,1.4,34.4c0.4,3.8,1.2,7.5,2.3,11.1c-22.3,5.7-58.2,6.9-77,8.5
                        c-21.7,1.8-15.4,8.8,2.3,8.5c12.2-0.2,32.7,0,56.1,0.1c0.4,0.2,0.9,0.2,1.3,0.2h40.8c0.5,0,1-0.1,1.5-0.3l0,0
                        c26.2-0.9,29.6-5.5,35.9-12.6c8.5-9.4,10-31.8,10-31.8v-0.7l0,0c3.1-0.3,6.6-0.6,10-1.1c0,0-2.1,31.5,7.9,42.6c-0.9,1.8,0.5,4,3,4
                        h40.8c3.1,0,4.4-3.4,2-5.1c-4.2-2.9-11.1-6-21.7-6.2c2.6-5,4.1-11.2,4.8-17.3c1.4-11.9,1.4-34.4,1.4-34.4
                        c0.1-6.8-0.1-13.3-0.3-19.5c5.4,4.5,12.2,5.9,20.4,1c25.7-15.3-15.8-59.5-25.5-69.3c54.4-13.7,78.2-49.8,78.2-103
                        c0-29.5-19.3-62.9-49.4-86.4C346.2,169.4,394.6,120.8,403.3,58z M301.4,370.6L301.4,370.6L301.4,370.6z"/>
                    <path fill="#FFFFFF" d="M175.9,263.8c15.3,0,27.8,12.4,27.8,27.8s-12.4,27.8-27.8,27.8s-27.8-12.4-27.8-27.8l0,0
                        C148.1,276.2,160.5,263.8,175.9,263.8"/>
                    <path fill="#662F00" d="M180.2,271.7c-11,0-19.8,8.9-19.8,19.9s8.9,19.8,19.9,19.8c11,0,19.8-8.9,19.8-19.9
                        C200.1,280.6,191.2,271.7,180.2,271.7C180.2,271.7,180.2,271.7,180.2,271.7z"/>
                    <path fill="#FFFFFF" d="M324.2,263.8c15.3,0,27.8,12.4,27.8,27.8s-12.4,27.8-27.8,27.8s-27.8-12.4-27.8-27.8l0,0
                        C296.4,276.2,308.8,263.8,324.2,263.8"/>
                    <path fill="#662F00" d="M319.8,271.7c11,0,19.8,8.9,19.8,19.9c0,11-8.9,19.8-19.9,19.8c-11,0-19.8-8.9-19.8-19.9
                        C299.9,280.6,308.8,271.7,319.8,271.7C319.8,271.7,319.8,271.7,319.8,271.7z"/>
                    <ellipse fill="#FFFFFF" cx="250" cy="324.3" rx="46.4" ry="29.6"/>
                    <path fill="#662F00" d="M274.8,311.7c-1.7,0-3.1,1.4-3.1,3.1c0,0,0,0,0,0c-0.3,5.1-4.7,9-9.9,8.7c-4.7-0.3-8.4-4-8.7-8.7v-1.6
                        c2.5-1.9,5.2-5.1,5.2-6.7c0-2.3-1.8-4.1-4.1-4.1c0,0,0,0,0,0h-8.3c-2.3,0-4.1,1.8-4.1,4.1l0,0c0,1.5,2.7,4.7,5.2,6.7v1.6
                        c-0.3,5.1-4.7,9-9.9,8.7c-4.7-0.3-8.4-4-8.7-8.7c0.1-1.7-1.2-3.2-2.9-3.3s-3.2,1.2-3.3,2.9c0,0.2,0,0.3,0,0.5
                        c0.1,8.6,7,15.4,15.6,15.4c4.8,0,9.3-2.3,12.2-6.1c5.1,6.9,14.8,8.3,21.7,3.1c3.9-2.9,6.2-7.5,6.2-12.4
                        C277.9,313.1,276.5,311.7,274.8,311.7z"/>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M250,375.5c-18.7,0-35.3-1.6-49.9-4.7
                        c-8.4,38.2-11.9,98.1,49.9,98.1s58.4-59.9,49.9-98.1C285.4,373.9,268.8,375.5,250,375.5z"/>
                    <g opacity="0.3">
                        <path fill="#1E1E1E" d="M324.9,520h-40.8c-3.1,0-4.4-3.4-2-5.1c4.3-3,11.4-6.2,22.4-6.2c11,0,18.1,3.2,22.4,6.2
                            C329.3,516.7,328,520,324.9,520z"/>
                        <path fill="#1E1E1E" d="M175.1,520h40.8c3.1,0,4.4-3.4,2-5.1c-4.3-3-11.4-6.2-22.4-6.2s-18.1,3.2-22.4,6.2
                            C170.8,516.7,172.1,520,175.1,520z"/>
                    </g>
                    <g opacity="0.5">
                        <path fill="#1E1E1E" d="M173.2,515c4.2-2.9,11.1-6,21.7-6.2c-1-2-1.8-4.1-2.5-6.2c-22.3,5.7-58.2,6.9-77,8.5
                            c-21.7,1.8-15.4,8.8,2.3,8.5c12.2-0.2,32.7,0,56.1,0.1c-1.4-0.4-2.2-1.9-1.7-3.3C172.3,515.8,172.6,515.3,173.2,515z"/>
                        <path fill="#1E1E1E" d="M250,475.3c-7.8-0.2-15.6-0.8-23.3-1.8c0,0,2.1,31.5-7.9,42.6c0.6,1.4,0,3-1.3,3.6c0,0-0.1,0-0.1,0
                            c26.2-0.9,29.6-5.5,35.9-12.6c8.5-9.4,10-31.8,10-31.8v-0.7C255.9,475.2,250,475.3,250,475.3z"/>
                    </g>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M318.1,179.5c0,0,32.1-29.3,37.1-64.9c0,0,2.2-17.3,0-24.8
                        c0,0-1.5-4.1-7-4c-1.4,0-2.8,0.3-4.1,0.7c-6.8,2.3-21.1,8.5-29.3,17.6c-10.9,12-14.5,65.1-14.5,65.1L318.1,179.5z"/>
                    <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M181.9,179.5c0,0-32.1-29.3-37-64.9c0,0-2.2-17.3,0-24.8
                        c0,0,1.5-4.1,7-4c1.4,0,2.8,0.3,4.1,0.7c6.8,2.3,21.1,8.5,29.3,17.6c10.9,12,14.5,65.1,14.5,65.1L181.9,179.5z"/>
                    <path opacity="0.4" fill="#BFBFBF" enable-background="new    " d="M339.3,319.3c-11.3,0-20.4,5.8-20.4,13c0,7.2,9.1,13,20.4,13
                        c11.3,0,20.4-5.8,20.4-13C359.7,325.1,350.5,319.3,339.3,319.3z"/>
                    <path opacity="0.4" fill="#BFBFBF" enable-background="new    " d="M160.8,319.3c11.3,0,20.4,5.8,20.4,13c0,7.2-9.1,13-20.4,13
                        c-11.3,0-20.4-5.8-20.4-13C140.3,325.1,149.5,319.3,160.8,319.3z"/>
                    </svg>
 
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height} >
                        <path fill={backGroundColor} d="M373.8,47c0,0,3.5-28,0-40.1c0,0-2.5-6.7-11.3-6.5c-2.3,0.1-4.5,0.4-6.7,1.2c-11,3.7-34.1,13.7-47.4,28.4
                            c-14.3,15.9-20.9,76.1-22.8,97.6c-23-8.3-48.2-8.3-71.2,0c-1.9-21.5-8.5-81.7-22.8-97.6c-13.3-14.7-36.4-24.8-47.4-28.5
                            c-2.2-0.7-4.4-1.1-6.7-1.2c-8.8-0.1-11.3,6.5-11.3,6.5c-3.5,12.1,0,40.1,0,40.1c7,50.7,46.1,90,55.3,98.6
                            c-24.4,18.9-39.9,45.9-39.9,69.8c0,47.1,23.1,77.7,76.7,85.9l-2.2,7.5l-8.3,3c-9.9,0.8-32.7,5.2-35.4,28.4
                            c-5.2,44.2-26.9,85.5-7.5,95.4c11.4,5.8,18.3-5.1,22.5-20.8c0,2.2,0,4.5,0,6.8c0,0,1.8,47.2,3.3,65.9c0.3,3.5,0.6,6.7,1,9.7
                            c-22.8,9.4-73.4,11-98.1,13.1c-24.8,2.1-17.5,10,2.7,9.7c16.1-0.2,44.9,0.1,76.9,0.2c0.2,0,0.4,0.1,0.6,0.1h39.8
                            c0.6,0,1.3-0.1,1.8-0.5c24.6-1.5,28.4-6.6,35.1-14c9.6-10.7,11.4-36.1,11.4-36.1v-1.8c2.1-0.3,4.5-0.7,7.1-1.3
                            c0,0,1.4,40.8,14.8,51.2c0,1.3,1.2,2.6,3.1,2.6h39.8c3,0,4.3-3.1,1.9-4.6c-4.2-2.7-11.1-5.6-21.8-5.6h-1.3
                            c2.3-5.4,3.6-12.8,4.3-22.4c1.4-18.7,3.3-65.9,3.3-65.9c0-2.4,0-4.7,0-7c4.7,15.8,11.9,26.6,22.8,21c19.4-10-2.3-51.2-7.5-95.4
                            c-2.8-23.4-26-27.7-35.7-28.4l-8.2-3l-2.2-7.5c53.6-8.2,76.7-38.7,76.7-85.9c0-23.8-15.6-50.8-39.9-69.8
                            C327.7,136.9,366.8,97.7,373.8,47z"/>
                        <path opacity="0.1" fill="#CECECE" enable-background="new    " d="M305,145.2c0,0,25.9-23.6,29.9-52.4c0,0,1.8-14,0-20.1
                            c0,0-1.2-3.3-5.7-3.3c-1.1,0-2.3,0.2-3.4,0.6c-5.5,1.9-17,6.9-23.7,14.2c-8.8,9.7-11.7,52.6-11.7,52.6L305,145.2z"/>
                        <path opacity="0.1" fill="#CECECE" enable-background="new    " d="M195,145.2c0,0-25.9-23.6-29.9-52.4c0,0-1.8-14,0-20.1
                            c0,0,1.2-3.3,5.7-3.3c1.1,0,2.3,0.2,3.4,0.6c5.5,1.9,17,6.9,23.7,14.2c8.8,9.7,11.7,52.6,11.7,52.6L195,145.2z"/>
                        <path fill="#662F00" d="M171.5,194.6c1.3,0.4,2.7,0.3,3.9-0.4c0.5-0.3,10.8-5.8,24.3-0.2c2.4,1,5.1-0.1,6.1-2.5s-0.1-5.1-2.5-6.1
                            l0,0c-18.1-7.5-32,0.4-32.5,0.8c-2.2,1.3-3,4.2-1.7,6.4C169.6,193.5,170.5,194.2,171.5,194.6L171.5,194.6z"/>
                        <path fill="#662F00" d="M326.2,175c-1.3,0.5-2.7,0.4-3.9-0.2c-0.5-0.3-11-5.3-24.3,0.9c-2.4,1-5.1-0.1-6.1-2.5
                            c-0.9-2.2,0-4.8,2.2-5.9c17.7-8.3,31.9-1,32.5-0.7c2.3,1.2,3.2,4,2,6.3C328.1,173.9,327.3,174.6,326.2,175L326.2,175z"/>
                        <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M215.3,515.5c-4-2.6-10.5-5.4-20.5-5.6h-1.3
                            c-10.7,0-17.7,2.9-21.8,5.6c-2.3,1.5-1.1,4.6,1.9,4.6h39.8c1.9,0,3.1-1.3,3.1-2.6C216.5,516.7,216,515.9,215.3,515.5z"/>
                        <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M284.7,515.5c4-2.6,10.5-5.4,20.5-5.6h1.3
                            c10.7,0,17.6,2.9,21.8,5.6c2.3,1.5,1.1,4.6-1.9,4.6h-39.8c-1.9,0-3.1-1.3-3.1-2.6C283.5,516.7,284,515.9,284.7,515.5z"/>
                        <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M295.8,338.1c-0.7-8.3-6.1-15.4-13.9-18.2l0,0
                            c-20.6-7.5-43.1-7.5-63.6,0l0,0c-7.8,2.8-13.3,9.9-14,18.2c-2.7,32.8-4.1,108.7,45.8,108.8C299.9,446.8,298.4,371,295.8,338.1z"/>
                        <path opacity="0.5" fill="#1E1E1E" enable-background="new    " d="M250,468.5c-0.9,0-7.8,0.3-18.8-2.1c0,0-1.4,40.8-14.7,51.2
                            c-0.1,1-0.7,1.9-1.6,2.3c24.9-1.5,28.7-6.6,35.4-14.1c9.6-10.7,11.4-36.1,11.4-36.1v-1.9C257.8,468.3,253.9,468.5,250,468.5z"/>
                        <path opacity="0.5" fill="#1E1E1E" enable-background="new    " d="M171.7,515.5c4.2-2.7,11.1-5.6,21.8-5.6h1.3
                            c-1.6-4-2.7-8.3-3.2-12.6c-22.8,9.4-73.4,11-98.1,13c-24.8,2-17.5,10,2.7,9.7s77.5,0.1,77.5,0.1C170.6,520.1,169.3,517,171.7,515.5
                            z"/>
                        <path fill="#662F00" d="M272.8,230.1c-1.3,0-2.3,1-2.3,2.3c0,3.8-4.1,6.9-9.1,6.9c-5,0-9.1-3.1-9.1-6.8c0-0.1,0-0.1,0-0.2
                            c3.8-2,8.2-5.4,10.4-8.8c1.2-1.7,1.7-3.7,1.4-5.8c-1.1-3.3-4.3-2.9-7.6-2.7c-2.2,0.3-4.3,0.4-6.5,0.4c-2.2,0-4.3-0.2-6.5-0.4
                            c-3.3-0.2-6.5-0.5-7.6,2.7c-0.2,2,0.3,4.1,1.4,5.8c2.2,3.4,6.6,6.8,10.4,8.8c0,0.1,0,0.1,0,0.2c0,3.8-4.1,6.8-9.1,6.8
                            c-5,0-9.2-3.1-9.2-6.9c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3c0,6.3,6.1,11.4,13.6,11.4c4.4,0.1,8.5-1.8,11.4-5.1
                            c2.8,3.3,7,5.2,11.4,5.1c7.5,0,13.6-5.1,13.6-11.4C275,231.1,274,230.1,272.8,230.1C272.8,230.1,272.8,230.1,272.8,230.1z
                            M259.5,222.4c-1.6,2.6-3.6,4.9-5.9,4.9C253.3,223.7,255.9,222.1,259.5,222.4L259.5,222.4z M246.4,227.3c-2.3,0-4.3-2.3-5.9-4.9
                            C244.1,222.1,246.7,223.7,246.4,227.3L246.4,227.3z"/>
                        <path fill="#FFFFFF" d="M264.2,218.5c0,1.8-0.5,3.5-1.5,5c-2.2,3.4-6.6,6.8-10.4,8.8c0,0.1,0,0.1,0,0.2c0,3.8,4.1,6.9,9.1,6.9
                            c5,0,9.1-3.1,9.1-6.9c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,6.3-6.1,11.4-13.6,11.4c-4.4,0.1-8.5-1.8-11.4-5.1
                            c-2.8,3.3-7,5.2-11.4,5.1c-7.5,0-13.6-5.1-13.6-11.4c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,3.8,4.1,6.9,9.1,6.9s9.1-3,9.1-6.8
                            c0-0.1,0-0.1,0-0.2c-3.8-2-8.2-5.4-10.4-8.8c-1-1.5-1.5-3.2-1.5-4.9c-11,3.8-18.6,11.8-18.6,21.1c0,12.9,14.7,23.4,32.7,23.4
                            s32.7-10.5,32.7-23.4C282.7,230.3,275.1,222.2,264.2,218.5z"/>
                        <path fill="#FFFFFF" d="M253.6,227.3c2.3,0,4.3-2.3,5.9-4.9C255.9,222.1,253.3,223.7,253.6,227.3z"/>
                        <path fill="#FFFFFF" d="M240.5,222.4c1.6,2.6,3.6,4.9,5.9,4.9C246.7,223.7,244.1,222.1,240.5,222.4z"/>
                        <path fill="#FFFFFF" d="M210.6,206h-4.5c1,1.8,1.5,3.9,1.5,5.9c-0.2,6.8-5.9,12.2-12.7,12c-6.6-0.2-11.8-5.5-12-12h4.3
                            c2.7,0,4.8-2.2,4.8-4.8c0-0.4-0.1-0.8-0.2-1.1h-27.9c-0.3,1.4-0.4,2.8-0.4,4.3c0,13.1,10.6,23.7,23.7,23.7
                            c13.1,0,23.6-10.6,23.7-23.7C211,208.9,210.8,207.4,210.6,206z"/>
                        <path fill="#FFFFFF" d="M335.9,206H308c-0.1,0.4-0.1,0.7-0.2,1.1c0,2.7,2.2,4.8,4.8,4.8h4.3c0,6.8-5.5,12.4-12.3,12.4
                            c-6.8,0-12.4-5.5-12.4-12.3c0-2.1,0.5-4.1,1.5-6h-4.5c-0.3,1.4-0.4,2.8-0.4,4.3c0,13.1,10.6,23.7,23.7,23.7
                            c13.1,0,23.6-10.6,23.7-23.7C336.3,208.9,336.2,207.4,335.9,206z"/>
                        <path fill="#662F00" d="M312.7,212c-2.7,0-4.8-2.2-4.8-4.8c0-0.4,0.1-0.8,0.2-1.1h-14.2c-3.3,6-1.1,13.5,4.9,16.7
                            c6,3.3,13.5,1.1,16.7-4.9c1-1.8,1.5-3.8,1.5-5.9L312.7,212z"/>
                        <path fill="#662F00" d="M206.1,206H192c0.1,0.4,0.1,0.7,0.2,1.1c0,2.7-2.2,4.8-4.8,4.8H183c0.2,6.8,5.9,12.2,12.7,12
                            c6.6-0.2,11.8-5.5,12-12C207.6,209.9,207.1,207.8,206.1,206z"/>
                    </svg>
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                            <path fill={backGroundColor} d="M373.8,47c0,0,3.5-28,0-40.1c0,0-2.5-6.7-11.3-6.5c-2.3,0.1-4.5,0.4-6.7,1.2c-11,3.7-34.1,13.7-47.4,28.4
                            c-14.3,15.9-20.9,76.1-22.8,97.6c-23-8.3-48.2-8.3-71.2,0c-1.9-21.5-8.5-81.7-22.8-97.5c-13.3-14.7-36.4-24.8-47.4-28.5
                            c-2.2-0.7-4.4-1.1-6.7-1.2c-8.8-0.1-11.3,6.5-11.3,6.5c-3.5,12.1,0,40.1,0,40.1c7,50.7,46.1,90,55.3,98.6
                            c-24.4,18.9-39.9,45.9-39.9,69.8c0,47.1,23.1,77.7,76.6,85.8l-2.2,7.5l-7.1,2.6c-4.6,0.3-9.1,1.6-13.2,4
                            c-38.6,21.8-84.6,28.6-81.2,50c4,25.7,54.8,6.4,76.6-3.2c-2.3,15.5-4,35.1-4,59.5c0.3,2.3,1.9,57.6,4.3,75.7l0,0
                            c-30.7,13.4-116.8,9.5-113.8,18.3c3,7.4,41,3.4,58.3,4.4c37.4-0.1,44.7,0.8,78.7-0.1c42.1-0.1,44.4-31.2,46.8-50.2v-1.9l0,0
                            c2.1-0.3,4.5-0.7,7.1-1.3c0,0,1.4,40.8,14.7,51.1c1.4,4.3,5.7,2,11.4,2.6H326c12.2-3.3-9.8-11.4-20.8-10.2
                            c7.2-20.5,5.6-63.6,7.4-83.9c6.3,9.2,13.9,14.1,22.9,9.6c19.4-10-2.3-51.2-7.5-95.4c-2.8-23.4-25.9-27.7-35.7-28.4l-8.3-3l-2.2-7.5
                            c53.6-8.2,76.6-38.7,76.6-85.8c0-23.8-15.6-50.8-39.9-69.8C327.7,136.9,366.8,97.7,373.8,47z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M305,145.1c0,0,25.9-23.6,29.9-52.4c0,0,1.8-14,0-20.1
                            c0,0-1.2-3.3-5.7-3.3c-1.1,0-2.3,0.2-3.4,0.6c-5.5,1.9-17,6.9-23.7,14.2c-8.8,9.7-11.7,52.6-11.7,52.6L305,145.1z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M195,145.1c0,0-25.9-23.6-29.9-52.4c0,0-1.8-14,0-20.1
                            c0,0,1.2-3.3,5.7-3.3c1.1,0,2.3,0.2,3.4,0.6c5.5,1.9,17,6.9,23.7,14.2c8.8,9.7,11.7,52.6,11.7,52.6L195,145.1z"/>
                        <path fill="#662F00" d="M171.6,194.5c1.3,0.4,2.7,0.3,3.9-0.4c0.5-0.3,10.8-5.8,24.3-0.2c2.4,1,5.1-0.1,6.1-2.5s-0.1-5.1-2.5-6.1
                            l0,0c-18.1-7.5-32,0.4-32.5,0.8c-2.2,1.3-3,4.2-1.7,6.4C169.6,193.5,170.5,194.2,171.6,194.5L171.6,194.5z"/>
                        <path fill="#FFFFFF" d="M210.6,206h-4.5c1,1.8,1.5,3.9,1.5,5.9c-0.2,6.8-5.9,12.2-12.7,12c-6.6-0.2-11.8-5.5-12-12h4.3
                            c2.7,0,4.8-2.2,4.8-4.8c0-0.4-0.1-0.8-0.2-1.1h-27.9c-0.3,1.4-0.4,2.8-0.4,4.3c0,13.1,10.6,23.6,23.6,23.6
                            c13.1,0,23.6-10.6,23.6-23.6C211,208.8,210.9,207.4,210.6,206z"/>
                        <path fill="#662F00" d="M326.2,175c-1.3,0.5-2.7,0.4-3.9-0.2c-0.5-0.3-11-5.3-24.3,0.9c-2.4,1-5.1-0.1-6.1-2.5
                            c-0.9-2.2,0-4.8,2.2-5.9c17.7-8.3,31.9-1,32.5-0.7c2.3,1.2,3.2,4,2,6.3C328.1,173.8,327.3,174.6,326.2,175L326.2,175z"/>
                        <path fill="#FFFFFF" d="M335.9,206H308c-0.1,0.4-0.1,0.7-0.2,1.1c0,2.7,2.2,4.8,4.8,4.8h4.3c0,6.8-5.5,12.4-12.3,12.4
                            c-6.8,0-12.4-5.5-12.4-12.3c0-2.1,0.5-4.1,1.5-6h-4.5c-0.3,1.4-0.4,2.8-0.4,4.3c0,13.1,10.6,23.6,23.6,23.6
                            c13.1,0,23.6-10.6,23.6-23.6C336.3,208.8,336.2,207.4,335.9,206z"/>
                        <path fill="#662F00" d="M272.8,230c-1.3,0-2.3,1-2.3,2.3c0,3.8-4.1,6.9-9.1,6.9c-5,0-9.1-3.1-9.1-6.8c0-0.1,0-0.1,0-0.2
                            c3.8-2,8.2-5.4,10.4-8.8c1.2-1.7,1.7-3.7,1.4-5.8c-1.1-3.3-4.3-2.9-7.6-2.7c-2.2,0.3-4.3,0.4-6.5,0.4c-2.2,0-4.3-0.2-6.5-0.4
                            c-3.3-0.2-6.5-0.5-7.6,2.7c-0.2,2,0.3,4.1,1.4,5.8c2.2,3.4,6.6,6.8,10.4,8.8c0,0.1,0,0.1,0,0.2c0,3.8-4.1,6.8-9.1,6.8
                            s-9.1-3.1-9.1-6.9c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3c0,6.3,6.1,11.4,13.6,11.4c4.4,0.1,8.5-1.8,11.4-5.1
                            c2.8,3.3,7,5.2,11.4,5.1c7.5,0,13.6-5.1,13.6-11.4C275.1,231.1,274.1,230.1,272.8,230C272.8,230,272.8,230,272.8,230z M259.5,222.4
                            c-1.6,2.6-3.6,4.9-5.9,4.9C253.3,223.7,255.9,222,259.5,222.4L259.5,222.4z M246.4,227.3c-2.3,0-4.3-2.3-5.9-4.9
                            C244.1,222,246.8,223.7,246.4,227.3L246.4,227.3z"/>
                        <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M284.7,515.4c4-2.6,10.5-5.4,20.5-5.6h1.3
                            c10.7,0,17.6,2.9,21.8,5.6c2.3,1.5,1.1,4.6-1.9,4.6h-39.8c-1.9,0-3.1-1.3-3.1-2.6C283.5,516.6,284,515.8,284.7,515.4z"/>
                        <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M295.8,338.1c-0.7-8.3-6.1-15.4-13.9-18.2l0,0
                            c-20.5-7.5-43.1-7.5-63.6,0l0,0c-7.8,2.8-13.3,9.9-14,18.2c-2.7,32.8-4.1,108.7,45.8,108.8C299.9,446.7,298.5,370.9,295.8,338.1z"
                            />
                        <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M216.5,517.5c0-0.9-0.5-1.6-1.2-2.1c-4-2.6-10.5-5.4-20.5-5.6
                            h-1.3c-10.7,0-17.7,2.9-21.8,5.6c-2.3,1.5-1.1,4.6,1.9,4.6h39.8c0.3,0,0.5,0,0.8-0.1h0.1C215.5,519.8,216.4,518.7,216.5,517.5z"/>
                        <path opacity="0.5" fill="#1E1E1E" enable-background="new    " d="M250,468.4c-0.9,0-7.8,0.3-18.8-2.1c0,0-1.4,40.8-14.7,51.1
                            c-0.1,1-0.7,1.9-1.6,2.3c24.8-1.5,28.7-6.5,35.4-14.1c9.6-10.7,11.4-36.1,11.4-36.1v-1.9C257.8,468.2,253.9,468.5,250,468.4z"/>
                        <path opacity="0.5" fill="#1E1E1E" enable-background="new    " d="M171.7,515.4c4.2-2.7,11.1-5.6,21.8-5.6h1.3
                            c-1.6-4-2.7-8.3-3.2-12.6c-22.8,9.4-73.3,11-98.1,13c-24.8,2-17.5,10,2.7,9.7c20.2-0.3,77.5,0.1,77.5,0.1
                            C170.6,520,169.3,516.9,171.7,515.4z"/>
                        <path fill="#FFFFFF" d="M264.2,218.4c0,1.8-0.5,3.5-1.5,5c-2.2,3.4-6.6,6.8-10.4,8.8c0,0.1,0,0.1,0,0.2c0,3.8,4.1,6.9,9.1,6.9
                            c5,0,9.1-3.1,9.1-6.9c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,6.3-6.1,11.4-13.6,11.4c-4.4,0.1-8.5-1.8-11.4-5.1
                            c-2.8,3.3-7,5.2-11.4,5.1c-7.5,0-13.6-5.1-13.6-11.4c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,3.8,4.1,6.9,9.1,6.9
                            c5,0,9.1-3,9.1-6.8c0-0.1,0-0.1,0-0.2c-3.8-2-8.2-5.4-10.4-8.8c-1-1.5-1.5-3.2-1.5-4.9c-11,3.8-18.6,11.8-18.6,21.1
                            c0,12.9,14.6,23.4,32.7,23.4s32.7-10.5,32.7-23.4C282.7,230.2,275.2,222.2,264.2,218.4z"/>
                        <path fill="#FFFFFF" d="M253.6,227.3c2.3,0,4.3-2.3,5.9-4.9C255.9,222,253.3,223.7,253.6,227.3z"/>
                        <path fill="#FFFFFF" d="M240.5,222.4c1.6,2.6,3.6,4.9,5.9,4.9C246.8,223.7,244.1,222,240.5,222.4z"/>
                        <path fill="#662F00" d="M312.7,211.9c-2.7,0-4.8-2.2-4.8-4.8c0-0.4,0.1-0.8,0.2-1.1h-14.2c-3.3,6-1.1,13.5,4.9,16.7
                            s13.5,1.1,16.7-4.9c1-1.8,1.5-3.8,1.5-5.9L312.7,211.9z"/>
                        <path fill="#662F00" d="M206.1,206H192c0.1,0.4,0.1,0.7,0.2,1.1c0,2.7-2.2,4.8-4.8,4.8H183c0.2,6.8,5.9,12.2,12.7,12
                            c6.6-0.2,11.8-5.5,12-12C207.7,209.9,207.2,207.8,206.1,206z"/>
                    </svg>
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
