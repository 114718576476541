import React from 'react';
import { Grid, Box, ButtonBase, Button, rgbToHex } from '@material-ui/core';
import './colorPicker.scss';
import { AnyAaaaRecord } from 'dns';
import { load } from 'dotenv/types';

export interface colorPlatter {
    id: string, dataName: string, d: string, transform: string, fill: string
}

export interface colorPicker {
    groupContent: colorPlatter[],
    groupId: string,
    groupDataName: string,
    transform: string

}

export interface Props {
    //     isSelected: boolean;
    //     label: string,
    onClick: (color: string) => void
}

export interface State {
    colors: colorPicker[];
    secoundColor: any;
    firstColor:boolean;
}

let canvasRef: any;
let secoundColorPickerRef: any;



class ColorPicker extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        canvasRef = React.createRef();
        secoundColorPickerRef = React.createRef();
    }


    async componentWillMount() {

        let colors: colorPicker[] = [];
        let groupFirst: colorPlatter[] = [];
        groupFirst.push({ id: "Path_121", dataName: "Path 121", d: "M640.243,587.771l-7.381,1.978a70.443,70.443,0,0,0,7.088,17.1l6.613-3.818A62.425,62.425,0,0,1,640.243,587.771Z", transform: "translate(-4597.463 -13287.518)", fill: "#cc0001" })
        groupFirst.push({ id: "Path_147", dataName: "Path 147", d: "M679.824,577.155l-7.39,1.98a62.425,62.425,0,0,0,6.319,15.256l6.626-3.825A54.881,54.881,0,0,1,679.824,577.155Z", transform: "translate(-4629.653 -13278.882)", fill: "#e50100" })
        groupFirst.push({ id: "Path_167", dataName: "Path 167", d: "M719.441,566.54l-7.39,1.98a54.881,54.881,0,0,0,5.555,13.411l6.626-3.825A47.328,47.328,0,0,1,719.441,566.54Z", transform: "translate(-4661.881 -13270.246)", fill: "#fd0100" })
        groupFirst.push({ id: "Path_177", dataName: "Path 177", d: "M759.058,555.925l-7.39,1.98a47.328,47.328,0,0,0,4.791,11.566l6.626-3.825A39.775,39.775,0,0,1,759.058,555.925Z", transform: "translate(-4694.108 -13261.611)", fill: "#fd2b2d" })
        groupFirst.push({ id: "Path_202", dataName: "Path 202", d: "M798.675,545.309l-7.39,1.98a39.773,39.773,0,0,0,4.027,9.721l6.626-3.825A32.223,32.223,0,0,1,798.675,545.309Z", transform: "translate(-4726.335 -13252.976)", fill: "#f35459" })
        groupFirst.push({ id: "Path_227", dataName: "Path 227", d: "M838.292,534.693l-7.39,1.98a32.22,32.22,0,0,0,3.262,7.876l6.626-3.825A24.681,24.681,0,0,1,838.292,534.693Z", transform: "translate(-4758.562 -13244.34)", fill: "#fe7a83" })
        groupFirst.push({ id: "Path_249", dataName: "Path 249", d: "M877.912,524.074l-7.393,1.981a24.676,24.676,0,0,0,2.5,6.031l6.628-3.827A17.131,17.131,0,0,1,877.912,524.074Z", transform: "translate(-4790.79 -13235.701)", fill: "#ffb2b1" })
        groupFirst.push({ id: "Path_270", dataName: "Path 270", d: "M917.542,513.46l-7.389,1.98a17.132,17.132,0,0,0,1.733,4.184l6.624-3.824A9.58,9.58,0,0,1,917.542,513.46Z", transform: "translate(-4823.03 -13227.067)", fill: "#fed4d5" })
        colors.push({
            groupId: "Group_9601",
            groupDataName: "Group 9601",
            transform: "translate(-283 -83)",
            groupContent: groupFirst
        })
        let groupTwo: colorPlatter[] = [];
        groupTwo.push({ id: "Path_124", dataName: "Path 124", d: "M627.648,500H620a70.982,70.982,0,0,0,2.4,18.35l7.381-1.978A63.586,63.586,0,0,1,627.648,500Z", transform: "translate(-4587 -13216.117)", fill: "#cb2800" })
        groupTwo.push({ id: "Path_139", dataName: "Path 139", d: "M668.648,500H661a63.583,63.583,0,0,0,2.133,16.372l7.39-1.98A55.889,55.889,0,0,1,668.648,500Z", transform: "translate(-4620.352 -13216.117)", fill: "#e62c01" })
        groupTwo.push({ id: "Path_156", dataName: "Path 156", d: "M709.648,500H702a55.894,55.894,0,0,0,1.875,14.392l7.39-1.98A48.2,48.2,0,0,1,709.648,500Z", transform: "translate(-4653.705 -13216.117)", fill: "#ff2f01" })
        groupTwo.push({ id: "Path_179", dataName: "Path 179", d: "M750.648,500H743a48.2,48.2,0,0,0,1.617,12.412l7.39-1.98A40.513,40.513,0,0,1,750.648,500Z", transform: "translate(-4687.057 -13216.117)", fill: "#ff5429" })
        groupTwo.push({ id: "Path_203", dataName: "Path 203", d: "M791.648,500H784a40.513,40.513,0,0,0,1.359,10.432l7.39-1.98A32.825,32.825,0,0,1,791.648,500Z", transform: "translate(-4720.409 -13216.117)", fill: "#fc7854" })
        groupTwo.push({ id: "Path_225", dataName: "Path 225", d: "M832.648,500H825a32.825,32.825,0,0,0,1.1,8.452l7.39-1.98A25.137,25.137,0,0,1,832.648,500Z", transform: "translate(-4753.761 -13216.117)", fill: "#fe9881" })
        groupTwo.push({ id: "Path_247", dataName: "Path 247", d: "M873.648,500H866a25.137,25.137,0,0,0,.843,6.471l7.393-1.981A17.441,17.441,0,0,1,873.648,500Z", transform: "translate(-4787.113 -13216.117)", fill: "#ffbaa8" })
        groupTwo.push({ id: "Path_271", dataName: "Path 271", d: "M914.648,500H907a17.441,17.441,0,0,0,.588,4.491l7.389-1.98A9.739,9.739,0,0,1,914.648,500Z", transform: "translate(-4820.465 -13216.117)", fill: "#ffded6" })
        colors.push({
            groupId: "Group_9600",
            groupDataName: "Group 9600",
            transform: "translate(-283 -83)",
            groupContent: groupTwo
        })

        let groupThree: colorPlatter[] = [];
        groupThree.push({ id: "Path_122", dataName: "Path 122", d: "M677.472,669.56l-6.613,3.818a71.145,71.145,0,0,0,11.274,14.675l5.408-5.408A63.581,63.581,0,0,1,677.472,669.56Z", transform: "translate(-4628.372 -13354.05)", fill: "#b00339" })
        groupThree.push({ id: "Path_146", dataName: "Path 146", d: "M712.938,649.053l-6.626,3.825a63.585,63.585,0,0,0,10.069,13.085l5.408-5.408A55.906,55.906,0,0,1,712.938,649.053Z", transform: "translate(-4657.212 -13337.368)", fill: "#cc0041" })
        groupThree.push({ id: "Path_169", dataName: "Path 169", d: "M748.458,628.545l-6.625,3.825a55.9,55.9,0,0,0,8.851,11.5l5.408-5.408A48.206,48.206,0,0,1,748.458,628.545Z", transform: "translate(-4686.106 -13320.686)", fill: "#e40049" })
        groupThree.push({ id: "Path_195", dataName: "Path 195", d: "M783.978,608.038l-6.626,3.825a48.211,48.211,0,0,0,7.633,9.92l5.408-5.408A40.509,40.509,0,0,1,783.978,608.038Z", transform: "translate(-4715.001 -13304.004)", fill: "#e72664" })
        groupThree.push({ id: "Path_206", dataName: "Path 206", d: "M819.5,587.53l-6.625,3.825a40.505,40.505,0,0,0,6.415,8.337l5.408-5.408A32.823,32.823,0,0,1,819.5,587.53Z", transform: "translate(-4743.896 -13287.321)", fill: "#ec5082" })
        groupThree.push({ id: "Path_226", dataName: "Path 226", d: "M855.018,567.023l-6.626,3.825a32.826,32.826,0,0,0,5.2,6.755L859,572.2A25.141,25.141,0,0,1,855.018,567.023Z", transform: "translate(-4772.79 -13270.64)", fill: "#f0789e" })
        groupThree.push({ id: "Path_248", dataName: "Path 248", d: "M890.54,546.507l-6.628,3.827a25.137,25.137,0,0,0,3.98,5.172L893.3,550.1A17.436,17.436,0,0,1,890.54,546.507Z", transform: "translate(-4801.684 -13253.95)", fill: "#f3a5bd" })
        groupThree.push({ id: "Path_272", dataName: "Path 272", d: "M926.07,526l-6.624,3.824a17.434,17.434,0,0,0,2.76,3.591l5.408-5.408A9.758,9.758,0,0,1,926.07,526Z", transform: "translate(-4830.59 -13237.271)", fill: "#f9d0df" })
        colors.push({
            groupId: "Group_9602",
            groupDataName: "Group 9602",
            transform: "translate(-283 -83)",
            groupContent: groupThree
        })
        let groupFour: colorPlatter[] = [];
        groupFour.push({ id: "Path_125", dataName: "Path 125", d: "M711.53,771.525l-5.408,5.408A71.166,71.166,0,0,0,720.8,788.207l3.818-6.613A63.585,63.585,0,0,1,711.53,771.525Z", transform: "translate(-4657.058 -13436.995)", fill: "#9d0164" })
        groupFour.push({ id: "Path_149", dataName: "Path 149", d: "M740.521,742.534h0l-5.408,5.408A63.584,63.584,0,0,0,748.2,758.01l3.825-6.626A55.877,55.877,0,0,1,740.521,742.534Z", transform: "translate(-4680.641 -13413.412)", fill: "#b20070" })
        groupFour.push({ id: "Path_171", dataName: "Path 171", d: "M769.512,713.542h0L764.1,718.95h0a55.882,55.882,0,0,0,11.5,8.851l3.825-6.625A48.2,48.2,0,0,1,769.512,713.542Z", transform: "translate(-4704.224 -13389.828)", fill: "#c3017c" })
        groupFour.push({ id: "Path_192", dataName: "Path 192", d: "M798.5,684.551h0l-5.408,5.408h0a48.207,48.207,0,0,0,9.92,7.633l3.825-6.626A40.516,40.516,0,0,1,798.5,684.551Z", transform: "translate(-4727.808 -13366.245)", fill: "#d02390" })
        groupFour.push({ id: "Path_215", dataName: "Path 215", d: "M827.495,655.56h0l-5.408,5.408h0a40.522,40.522,0,0,0,8.337,6.415l3.825-6.626A32.822,32.822,0,0,1,827.495,655.56Z", transform: "translate(-4751.392 -13342.661)", fill: "#d9499f" })
        groupFour.push({ id: "Path_230", dataName: "Path 230", d: "M856.487,626.568h0l-5.408,5.408h0a32.822,32.822,0,0,0,6.754,5.2l3.825-6.626A25.136,25.136,0,0,1,856.487,626.568Z", transform: "translate(-4774.976 -13319.077)", fill: "#dd6bb5" })
        groupFour.push({ id: "Path_250", dataName: "Path 250", d: "M885.478,597.576h0l-5.408,5.408h0a25.138,25.138,0,0,0,5.172,3.98l3.827-6.628A17.442,17.442,0,0,1,885.478,597.576Z", transform: "translate(-4798.559 -13295.493)", fill: "#ed9ccf" })
        groupFour.push({ id: "Path_273", dataName: "Path 273", d: "M914.47,568.585h0l-5.408,5.408h0a17.441,17.441,0,0,0,3.591,2.759l3.825-6.624A9.747,9.747,0,0,1,914.47,568.585Z", transform: "translate(-4822.143 -13271.91)", fill: "#eec9e2" })
        colors.push({
            groupId: "Group_9603",
            groupDataName: "Group 9603",
            transform: "translate(-278.305 -88.935)",
            groupContent: groupFour
        })

        let groupFive: colorPlatter[] = [];
        groupFive.push({ id: "Path_120", dataName: "Path 120", d: "M629.781,403.6l-7.381-1.978a70.98,70.98,0,0,0-2.4,18.35h7.648A63.582,63.582,0,0,1,629.781,403.6Z", transform: "translate(-4587 -13136.094)", fill: "#cc4e01" })
        groupFive.push({ id: "Path_133", dataName: "Path 133", d: "M670.523,414.209l-7.39-1.98A63.58,63.58,0,0,0,661,428.6h7.648A55.9,55.9,0,0,1,670.523,414.209Z", transform: "translate(-4620.352 -13144.719)", fill: "#e55800" })
        groupFive.push({ id: "Path_152", dataName: "Path 152", d: "M711.265,424.824l-7.39-1.98A55.9,55.9,0,0,0,702,437.236h7.648A48.2,48.2,0,0,1,711.265,424.824Z", transform: "translate(-4653.705 -13153.354)", fill: "#fe6203" })
        groupFive.push({ id: "Path_174", dataName: "Path 174", d: "M752.007,435.439l-7.39-1.98A48.2,48.2,0,0,0,743,445.871h7.648A40.522,40.522,0,0,1,752.007,435.439Z", transform: "translate(-4687.057 -13161.989)", fill: "#ff7c2a" })
        groupFive.push({ id: "Path_201", dataName: "Path 201", d: "M792.749,446.055l-7.39-1.98A40.522,40.522,0,0,0,784,454.507h7.648A32.825,32.825,0,0,1,792.749,446.055Z", transform: "translate(-4720.409 -13170.625)", fill: "#ff9552" })
        groupFive.push({ id: "Path_223", dataName: "Path 223", d: "M833.491,456.67l-7.39-1.98a32.825,32.825,0,0,0-1.1,8.452h7.648A25.138,25.138,0,0,1,833.491,456.67Z", transform: "translate(-4753.761 -13179.26)", fill: "#feb281" })
        groupFive.push({ id: "Path_246", dataName: "Path 246", d: "M874.236,467.287l-7.393-1.981a25.138,25.138,0,0,0-.843,6.471h7.648A17.441,17.441,0,0,1,874.236,467.287Z", transform: "translate(-4787.113 -13187.896)", fill: "#ffcbad" })
        groupFive.push({ id: "Path_274", dataName: "Path 274", d: "M914.977,477.9l-7.388-1.98a17.441,17.441,0,0,0-.588,4.491h7.648A9.75,9.75,0,0,1,914.977,477.9Z", transform: "translate(-4820.465 -13196.534)", fill: "#fde6d7" })
        colors.push({
            groupId: "Group_9599",
            groupDataName: "Group 9599",
            transform: "translate(-283 -83)",
            groupContent: groupFive
        })
        let groupSix: colorPlatter[] = [];
        groupSix.push({ id: "Path_123", dataName: "Path 123", d: "M813.788,793.688,809.97,800.3a70.469,70.469,0,0,0,17.1,7.088l1.978-7.381A62.434,62.434,0,0,1,813.788,793.688Z", transform: "translate(-4741.535 -13455.024)", fill: "#8e0090" })
        groupSix.push({ id: "Path_148", dataName: "Path 148", d: "M834.264,758.168l-3.825,6.626a62.441,62.441,0,0,0,15.256,6.319l1.98-7.39A54.878,54.878,0,0,1,834.264,758.168Z", transform: "translate(-4758.186 -13426.13)", fill: "#9f00a2" })
        groupSix.push({ id: "Path_170", dataName: "Path 170", d: "M854.771,722.648l-3.825,6.626a54.879,54.879,0,0,0,13.411,5.555l1.98-7.39A47.328,47.328,0,0,1,854.771,722.648Z", transform: "translate(-4774.867 -13397.235)", fill: "#ae01b3" })
        groupSix.push({ id: "Path_194", dataName: "Path 194", d: "M875.279,687.129l-3.825,6.625a47.325,47.325,0,0,0,11.566,4.791l1.98-7.39A39.784,39.784,0,0,1,875.279,687.129Z", transform: "translate(-4791.55 -13368.342)", fill: "#be20bf" })
        groupSix.push({ id: "Path_218", dataName: "Path 218", d: "M895.786,651.608l-3.825,6.626a39.779,39.779,0,0,0,9.721,4.026l1.98-7.39A32.229,32.229,0,0,1,895.786,651.608Z", transform: "translate(-4808.232 -13339.446)", fill: "#cb42cd" })
        groupSix.push({ id: "Path_239", dataName: "Path 239", d: "M916.293,616.089l-3.825,6.625a32.231,32.231,0,0,0,7.876,3.262l1.98-7.39A24.677,24.677,0,0,1,916.293,616.089Z", transform: "translate(-4824.914 -13310.553)", fill: "#d96cd9" })
        groupSix.push({ id: "Path_251", dataName: "Path 251", d: "M936.8,580.554l-3.827,6.628a24.675,24.675,0,0,0,6.031,2.5l1.981-7.393A17.131,17.131,0,0,1,936.8,580.554Z", transform: "translate(-4841.596 -13281.646)", fill: "#e799e8" })
        groupSix.push({ id: "Path_275", dataName: "Path 275", d: "M957.317,545.041l-3.825,6.624a17.128,17.128,0,0,0,4.185,1.733l1.98-7.389A9.575,9.575,0,0,1,957.317,545.041Z", transform: "translate(-4858.285 -13252.758)", fill: "#f3caf4" })
        colors.push({
            groupId: "Group_9604",
            groupDataName: "Group 9604",
            transform: "translate(-283 -83)",
            groupContent: groupSix
        })
        let group7: colorPlatter[] = [];
        group7.push({ id: "Path_102", dataName: "Path 102", d: "M919.975,127.648V120a70.981,70.981,0,0,0-18.35,2.4l1.978,7.381A63.588,63.588,0,0,1,919.975,127.648Z", transform: "translate(-4816.093 -12907)", fill: "#cbb701" })
        group7.push({ id: "Path_129", dataName: "Path 129", d: "M928.6,168.648V161a63.587,63.587,0,0,0-16.372,2.133l1.98,7.39A55.9,55.9,0,0,1,928.6,168.648Z", transform: "translate(-4824.718 -12940.353)", fill: "#e6cb00" })
        group7.push({ id: "Path_154", dataName: "Path 154", d: "M937.236,209.648V202a55.9,55.9,0,0,0-14.392,1.875l1.98,7.39A48.207,48.207,0,0,1,937.236,209.648Z", transform: "translate(-4833.354 -12973.704)", fill: "#fee300" })
        group7.push({ id: "Path_180", dataName: "Path 180", d: "M945.871,250.648V243a48.208,48.208,0,0,0-12.412,1.617l1.98,7.39A40.521,40.521,0,0,1,945.871,250.648Z", transform: "translate(-4841.989 -13007.057)", fill: "#ffe82a" })
        group7.push({ id: "Path_209", dataName: "Path 209", d: "M954.507,291.648V284a40.52,40.52,0,0,0-10.432,1.359l1.98,7.39A32.828,32.828,0,0,1,954.507,291.648Z", transform: "translate(-4850.625 -13040.409)", fill: "#feec53" })
        group7.push({ id: "Path_232", dataName: "Path 232", d: "M963.142,332.648V325a32.827,32.827,0,0,0-8.452,1.1l1.98,7.39A25.131,25.131,0,0,1,963.142,332.648Z", transform: "translate(-4859.26 -13073.761)", fill: "#fef181" })
        group7.push({ id: "Path_255", dataName: "Path 255", d: "M971.778,373.648V366a25.13,25.13,0,0,0-6.471.843l1.981,7.393A17.433,17.433,0,0,1,971.778,373.648Z", transform: "translate(-4867.896 -13107.113)", fill: "#fdf7a4" })
        group7.push({ id: "Path_276", dataName: "Path 276", d: "M977.906,414.977a9.739,9.739,0,0,1,2.511-.329V407a17.433,17.433,0,0,0-4.49.588Z", transform: "translate(-4876.535 -13140.466)", fill: "#fefad5" })
        colors.push({
            groupId: "Group_9594",
            groupDataName: "Group 9594",
            transform: "translate(-283 -83)",
            groupContent: group7
        })
        let group8: colorPlatter[] = [];
        group8.push({ id: "Path_110", dataName: "Path 110", d: "M646.563,313.789l-6.613-3.818a70.448,70.448,0,0,0-7.088,17.1l7.381,1.978A62.427,62.427,0,0,1,646.563,313.789Z", transform: "translate(-4597.463 -13061.535)", fill: "#cd6100" })
        group8.push({ id: "Path_130", dataName: "Path 130", d: "M685.379,334.264l-6.626-3.825a62.427,62.427,0,0,0-6.319,15.256l7.39,1.98A54.878,54.878,0,0,1,685.379,334.264Z", transform: "translate(-4629.653 -13078.186)", fill: "#e66d01" })
        group8.push({ id: "Path_155", dataName: "Path 155", d: "M724.232,354.771l-6.626-3.825a54.877,54.877,0,0,0-5.555,13.411l7.39,1.98A47.33,47.33,0,0,1,724.232,354.771Z", transform: "translate(-4661.881 -13094.867)", fill: "#ff7900" })
        group8.push({ id: "Path_175", dataName: "Path 175", d: "M763.084,375.279l-6.626-3.825a47.33,47.33,0,0,0-4.791,11.566l7.39,1.98A39.775,39.775,0,0,1,763.084,375.279Z", transform: "translate(-4694.108 -13111.55)", fill: "#fd902c" })
        group8.push({ id: "Path_200", dataName: "Path 200", d: "M801.937,395.786l-6.626-3.825a39.77,39.77,0,0,0-4.027,9.721l7.39,1.98A32.23,32.23,0,0,1,801.937,395.786Z", transform: "translate(-4726.335 -13128.231)", fill: "#fda553" })
        group8.push({ id: "Path_224", dataName: "Path 224", d: "M840.79,416.293l-6.625-3.825a32.232,32.232,0,0,0-3.262,7.876l7.39,1.98A24.682,24.682,0,0,1,840.79,416.293Z", transform: "translate(-4758.562 -13144.914)", fill: "#febc83" })
        group8.push({ id: "Path_253", dataName: "Path 253", d: "M879.645,436.8l-6.628-3.827a24.679,24.679,0,0,0-2.5,6.031l7.393,1.981A17.128,17.128,0,0,1,879.645,436.8Z", transform: "translate(-4790.79 -13161.597)", fill: "#ffd3a8" })
        group8.push({ id: "Path_277", dataName: "Path 277", d: "M918.511,457.316l-6.624-3.824a17.131,17.131,0,0,0-1.733,4.184l7.389,1.98A9.582,9.582,0,0,1,918.511,457.316Z", transform: "translate(-4823.03 -13178.285)", fill: "#feebd4" })
        colors.push({
            groupId: "Group_9598",
            groupDataName: "Group 9598",
            transform: "translate(-283 -83)",
            groupContent: group8
        })
        let group9: colorPlatter[] = [];
        group9.push({ id: "Path_116", dataName: "Path 116", d: "M903.6,827.566l-1.978,7.381a70.988,70.988,0,0,0,18.35,2.4V829.7A63.579,63.579,0,0,1,903.6,827.566Z", transform: "translate(-4816.093 -13482.583)", fill: "#4f0080" })
        group9.push({ id: "Path_145", dataName: "Path 145", d: "M914.208,787.949l-1.98,7.39a63.578,63.578,0,0,0,16.372,2.133v-7.648A55.9,55.9,0,0,1,914.208,787.949Z", transform: "translate(-4824.718 -13450.355)", fill: "#580092" })
        group9.push({ id: "Path_173", dataName: "Path 173", d: "M924.824,748.332l-1.98,7.39a55.9,55.9,0,0,0,14.392,1.875v-7.648A48.208,48.208,0,0,1,924.824,748.332Z", transform: "translate(-4833.354 -13418.129)", fill: "#6100a0" })
        group9.push({ id: "Path_193", dataName: "Path 193", d: "M935.439,708.715l-1.98,7.39a48.208,48.208,0,0,0,12.412,1.617v-7.648A40.511,40.511,0,0,1,935.439,708.715Z", transform: "translate(-4841.989 -13385.901)", fill: "#8d40c0" })
        group9.push({ id: "Path_219", dataName: "Path 219", d: "M946.055,669.1l-1.98,7.39a40.513,40.513,0,0,0,10.432,1.359V670.2A32.836,32.836,0,0,1,946.055,669.1Z", transform: "translate(-4850.625 -13353.674)", fill: "#8d40c0" })
        group9.push({ id: "Path_243", dataName: "Path 243", d: "M956.67,629.48l-1.98,7.39a32.839,32.839,0,0,0,8.452,1.1v-7.648A25.131,25.131,0,0,1,956.67,629.48Z", transform: "translate(-4859.26 -13321.446)", fill: "#a967d1" })
        group9.push({ id: "Path_263", dataName: "Path 263", d: "M967.287,589.847l-1.981,7.393a25.13,25.13,0,0,0,6.471.843v-7.648A17.439,17.439,0,0,1,967.287,589.847Z", transform: "translate(-4867.896 -13289.206)", fill: "#c397e4" })
        group9.push({ id: "Path_278", dataName: "Path 278", d: "M977.906,550.236l-1.98,7.389a17.439,17.439,0,0,0,4.49.588v-7.648A9.741,9.741,0,0,1,977.906,550.236Z", transform: "translate(-4876.535 -13256.983)", fill: "#d8c3e8" })
        colors.push({
            groupId: "Group_9605",
            groupDataName: "Group 9605",
            transform: "translate(-283 -83)",
            groupContent: group9
        })
        let group10: colorPlatter[] = [];
        group10.push({ id: "Path_103", dataName: "Path 103", d: "M829.045,140.243l-1.978-7.381a70.452,70.452,0,0,0-17.1,7.088l3.818,6.613A62.43,62.43,0,0,1,829.045,140.243Z", transform: "translate(-4741.536 -12917.463)", fill: "#cca001" })
        group10.push({ id: "Path_127", dataName: "Path 127", d: "M847.675,179.824l-1.98-7.39a62.432,62.432,0,0,0-15.256,6.32l3.825,6.625A54.879,54.879,0,0,1,847.675,179.824Z", transform: "translate(-4758.186 -12949.653)", fill: "#e6b300" })
        group10.push({ id: "Path_150", dataName: "Path 150", d: "M866.338,219.441l-1.98-7.39a54.878,54.878,0,0,0-13.411,5.555l3.825,6.626A47.331,47.331,0,0,1,866.338,219.441Z", transform: "translate(-4774.868 -12981.881)", fill: "#ffc801" })
        group10.push({ id: "Path_178", dataName: "Path 178", d: "M885,259.058l-1.98-7.39a47.331,47.331,0,0,0-11.566,4.791l3.825,6.626A39.786,39.786,0,0,1,885,259.058Z", transform: "translate(-4791.55 -13014.107)", fill: "#fed02c" })
        group10.push({ id: "Path_207", dataName: "Path 207", d: "M903.662,298.675l-1.98-7.39a39.786,39.786,0,0,0-9.721,4.027l3.825,6.626A32.232,32.232,0,0,1,903.662,298.675Z", transform: "translate(-4808.232 -13046.335)", fill: "#fddc59" })
        group10.push({ id: "Path_231", dataName: "Path 231", d: "M922.325,338.292l-1.98-7.39a32.227,32.227,0,0,0-7.875,3.262l3.825,6.626A24.666,24.666,0,0,1,922.325,338.292Z", transform: "translate(-4824.915 -13078.563)", fill: "#ffe281" })
        group10.push({ id: "Path_257", dataName: "Path 257", d: "M940.988,377.913l-1.981-7.393a24.668,24.668,0,0,0-6.03,2.5l3.827,6.628A17.124,17.124,0,0,1,940.988,377.913Z", transform: "translate(-4841.597 -13110.79)", fill: "#fea" })
        group10.push({ id: "Path_279", dataName: "Path 279", d: "M959.656,417.542l-1.98-7.388a17.122,17.122,0,0,0-4.185,1.733l3.825,6.624A9.571,9.571,0,0,1,959.656,417.542Z", transform: "translate(-4858.285 -13143.031)", fill: "#fbf5d4" })
        colors.push({
            groupId: "Group_9595",
            groupDataName: "Group 9595",
            transform: "translate(-283 -83)",
            groupContent: group10
        })
        let group11: colorPlatter[] = [];
        group11.push({ id: "Path_105", dataName: "Path 105", d: "M687.542,236.707l-5.408-5.408a71.16,71.16,0,0,0-11.274,14.675l6.613,3.818A63.6,63.6,0,0,1,687.542,236.707Z", transform: "translate(-4628.373 -12997.538)", fill: "#cd7301" })
        group11.push({ id: "Path_128", dataName: "Path 128", d: "M721.789,265.7h0l-5.408-5.408a63.6,63.6,0,0,0-10.069,13.085l6.626,3.825A55.892,55.892,0,0,1,721.789,265.7Z", transform: "translate(-4657.212 -13021.122)", fill: "#e98202" })
        group11.push({ id: "Path_153", dataName: "Path 153", d: "M756.091,294.69h0l-5.408-5.408h0a55.888,55.888,0,0,0-8.851,11.5l6.625,3.825A48.2,48.2,0,0,1,756.091,294.69Z", transform: "translate(-4686.106 -13044.706)", fill: "#fe9100" })
        group11.push({ id: "Path_185", dataName: "Path 185", d: "M790.393,323.682h0l-5.408-5.408h0a48.208,48.208,0,0,0-7.633,9.92l6.626,3.825A40.51,40.51,0,0,1,790.393,323.682Z", transform: "translate(-4715.001 -13068.29)", fill: "#ffa127" })
        group11.push({ id: "Path_199", dataName: "Path 199", d: "M824.7,352.673h0l-5.408-5.408h0a40.511,40.511,0,0,0-6.415,8.337l6.625,3.825A32.819,32.819,0,0,1,824.7,352.673Z", transform: "translate(-4743.896 -13091.873)", fill: "#ffb457" })
        group11.push({ id: "Path_222", dataName: "Path 222", d: "M859,381.665h0l-5.408-5.408h0a32.821,32.821,0,0,0-5.2,6.754l6.625,3.825A25.135,25.135,0,0,1,859,381.665Z", transform: "translate(-4772.79 -13115.457)", fill: "#ffc882" })
        group11.push({ id: "Path_252", dataName: "Path 252", d: "M893.3,410.656h0l-5.408-5.408h0a25.133,25.133,0,0,0-3.98,5.172l6.628,3.827A17.422,17.422,0,0,1,893.3,410.656Z", transform: "translate(-4801.684 -13139.04)", fill: "#f7daae" })
        group11.push({ id: "Path_280", dataName: "Path 280", d: "M927.613,439.648h0l-5.408-5.408h0a17.429,17.429,0,0,0-2.759,3.591l6.624,3.824A9.755,9.755,0,0,1,927.613,439.648Z", transform: "translate(-4830.59 -13162.624)", fill: "#ffedd7" })
        colors.push({
            groupId: "Group_9597",
            groupDataName: "Group 9597",
            transform: "translate(-283 -83)",
            groupContent: group11
        })
        let group12: colorPlatter[] = [];
        group12.push({ id: "Path_104", dataName: "Path 104", d: "M749.792,177.473l-3.818-6.613A71.164,71.164,0,0,0,731.3,182.134l5.408,5.408A63.58,63.58,0,0,1,749.792,177.473Z", transform: "translate(-4677.538 -12948.373)", fill: "#cc8a02" })
        group12.push({ id: "Path_126", dataName: "Path 126", d: "M777.2,212.938l-3.825-6.625a63.578,63.578,0,0,0-13.085,10.069l5.408,5.408A55.877,55.877,0,0,1,777.2,212.938Z", transform: "translate(-4701.123 -12977.213)", fill: "#e69b02" })
        group12.push({ id: "Path_151", dataName: "Path 151", d: "M804.61,248.458l-3.825-6.626a55.884,55.884,0,0,0-11.5,8.851l5.408,5.408A48.205,48.205,0,0,1,804.61,248.458Z", transform: "translate(-4724.707 -13006.106)", fill: "#ffae02" })
        group12.push({ id: "Path_176", dataName: "Path 176", d: "M832.019,283.978l-3.825-6.626a48.207,48.207,0,0,0-9.92,7.633l5.408,5.408A40.508,40.508,0,0,1,832.019,283.978Z", transform: "translate(-4748.29 -13035.001)", fill: "#fbb92a" })
        group12.push({ id: "Path_198", dataName: "Path 198", d: "M859.428,319.5l-3.825-6.626a40.509,40.509,0,0,0-8.337,6.415l5.408,5.408A32.817,32.817,0,0,1,859.428,319.5Z", transform: "translate(-4771.874 -13063.896)", fill: "#fcc851" })
        group12.push({ id: "Path_233", dataName: "Path 233", d: "M886.837,355.018l-3.825-6.626a32.821,32.821,0,0,0-6.755,5.2L881.665,359A25.135,25.135,0,0,1,886.837,355.018Z", transform: "translate(-4795.457 -13092.79)", fill: "#fed67d" })
        group12.push({ id: "Path_256", dataName: "Path 256", d: "M914.247,390.54l-3.827-6.628a25.133,25.133,0,0,0-5.172,3.98l5.408,5.408A17.445,17.445,0,0,1,914.247,390.54Z", transform: "translate(-4819.041 -13121.685)", fill: "#fee3ac" })
        group12.push({ id: "Path_281", dataName: "Path 281", d: "M941.656,426.07l-3.825-6.624a17.443,17.443,0,0,0-3.591,2.76l5.408,5.408A9.752,9.752,0,0,1,941.656,426.07Z", transform: "translate(-4842.625 -13150.59)", fill: "#fff1d5" })
        colors.push({
            groupId: "Group_9596",
            groupDataName: "Group 9596",
            transform: "translate(-283 -83)",
            groupContent: group12
        })
        let group13: colorPlatter[] = [];
        group13.push({ id: "Path_106", dataName: "Path 106", d: "M1016.372,129.781l1.978-7.381A70.978,70.978,0,0,0,1000,120v7.648A63.589,63.589,0,0,1,1016.372,129.781Z", transform: "translate(-4896.118 -12907)", fill: "#ccca02" })
        group13.push({ id: "Path_136", dataName: "Path 136", d: "M1014.392,170.523l1.98-7.39A63.589,63.589,0,0,0,1000,161v7.648A55.893,55.893,0,0,1,1014.392,170.523Z", transform: "translate(-4896.118 -12940.353)", fill: "#e7e602" })
        group13.push({ id: "Path_160", dataName: "Path 160", d: "M1012.412,211.265l1.98-7.39A55.893,55.893,0,0,0,1000,202v7.648A48.2,48.2,0,0,1,1012.412,211.265Z", transform: "translate(-4896.118 -12973.704)", fill: "#f1f205" })
        group13.push({ id: "Path_184", dataName: "Path 184", d: "M1010.432,252.007l1.98-7.39A48.2,48.2,0,0,0,1000,243v7.648A40.512,40.512,0,0,1,1010.432,252.007Z", transform: "translate(-4896.118 -13007.057)", fill: "#ffff29" })
        group13.push({ id: "Path_208", dataName: "Path 208", d: "M1008.452,292.749l1.98-7.39A40.512,40.512,0,0,0,1000,284v7.648A32.827,32.827,0,0,1,1008.452,292.749Z", transform: "translate(-4896.118 -13040.409)", fill: "#ffff56" })
        group13.push({ id: "Path_229", dataName: "Path 229", d: "M1006.471,333.491l1.98-7.39A32.825,32.825,0,0,0,1000,325v7.648A25.131,25.131,0,0,1,1006.471,333.491Z", transform: "translate(-4896.118 -13073.761)", fill: "#ffff80" })
        group13.push({ id: "Path_254", dataName: "Path 254", d: "M1000,373.648a17.44,17.44,0,0,1,4.49.588l1.981-7.393A25.131,25.131,0,0,0,1000,366Z", transform: "translate(-4896.118 -13107.113)", fill: "#fdffa5" })
        group13.push({ id: "Path_282", dataName: "Path 282", d: "M1002.511,414.977l1.98-7.388A17.44,17.44,0,0,0,1000,407v7.648A9.748,9.748,0,0,1,1002.511,414.977Z", transform: "translate(-4896.118 -13140.466)", fill: "#fcfcd0" })
        colors.push({
            groupId: "Group_9590",
            groupDataName: "Group 9590",
            transform: "translate(-283 -83)",
            groupContent: group13
        })
        let group14: colorPlatter[] = [];
        group14.push({ id: "Path_111", dataName: "Path 111", d: "M1207.822,154.5l5.408-5.408a71.17,71.17,0,0,0-14.675-11.274l-3.818,6.613A63.569,63.569,0,0,1,1207.822,154.5Z", transform: "translate(-5059.531 -12915.492)", fill: "#719f02" })
        group14.push({ id: "Path_134", dataName: "Path 134", d: "M1185.732,188.744l5.408-5.408a63.568,63.568,0,0,0-13.085-10.069l-3.825,6.626A55.892,55.892,0,0,1,1185.732,188.744Z", transform: "translate(-5042.849 -12944.332)", fill: "#7eb300" })
        group14.push({ id: "Path_161", dataName: "Path 161", d: "M1163.643,223.047l5.408-5.408a55.9,55.9,0,0,0-11.5-8.851l-3.825,6.625A48.2,48.2,0,0,1,1163.643,223.047Z", transform: "translate(-5026.167 -12973.227)", fill: "#8bc800" })
        group14.push({ id: "Path_182", dataName: "Path 182", d: "M1141.553,257.348l5.408-5.408a48.208,48.208,0,0,0-9.92-7.633l-3.825,6.626A40.5,40.5,0,0,1,1141.553,257.348Z", transform: "translate(-5009.485 -13002.12)", fill: "#80c41c" })
        group14.push({ id: "Path_205", dataName: "Path 205", d: "M1112.708,286.452a32.817,32.817,0,0,1,6.755,5.2l5.408-5.408a40.5,40.5,0,0,0-8.337-6.415Z", transform: "translate(-4992.803 -13031.015)", fill: "#9cd222" })
        group14.push({ id: "Path_238", dataName: "Path 238", d: "M1097.373,325.952l5.408-5.408a32.816,32.816,0,0,0-6.755-5.2l-3.825,6.626A25.14,25.14,0,0,1,1097.373,325.952Z", transform: "translate(-4976.121 -13059.908)", fill: "#c1e371" })
        group14.push({ id: "Path_264", dataName: "Path 264", d: "M1075.276,360.254l5.408-5.408a25.136,25.136,0,0,0-5.172-3.98l-3.827,6.628A17.438,17.438,0,0,1,1075.276,360.254Z", transform: "translate(-4959.432 -13088.803)", fill: "#d4ed9f" })
        group14.push({ id: "Path_283", dataName: "Path 283", d: "M1053.19,394.568l5.408-5.408a17.435,17.435,0,0,0-3.591-2.759l-3.824,6.624A9.762,9.762,0,0,1,1053.19,394.568Z", transform: "translate(-4942.753 -13117.709)", fill: "#ebf6ce" })
        colors.push({
            groupId: "Group_9588",
            groupDataName: "Group 9588",
            transform: "translate(-282.699 -82.833)",
            groupContent: group14
        })

        let group15: colorPlatter[] = [];
        group15.push({ id: "Path_112", dataName: "Path 112", d: "M1239.709,236.707a63.578,63.578,0,0,1,10.068,13.085l6.613-3.818a71.172,71.172,0,0,0-11.274-14.675l-5.408,5.408Z", transform: "translate(-5091.114 -12997.538)", fill: "#389700" })
        group15.push({ id: "Path_135", dataName: "Path 135", d: "M1210.718,265.7a55.886,55.886,0,0,1,8.851,11.5l6.626-3.825a63.584,63.584,0,0,0-10.069-13.085h0l-5.408,5.408Z", transform: "translate(-5067.531 -13021.122)", fill: "#42a701" })
        group15.push({ id: "Path_158", dataName: "Path 158", d: "M1181.727,294.69a48.211,48.211,0,0,1,7.633,9.92l6.625-3.825a55.882,55.882,0,0,0-8.851-11.5h0l-5.408,5.408Z", transform: "translate(-5043.947 -13044.706)", fill: "#4aba00" })
        group15.push({ id: "Path_181", dataName: "Path 181", d: "M1158.143,318.273l-5.408,5.408h0a40.523,40.523,0,0,1,6.416,8.337l6.625-3.825a48.215,48.215,0,0,0-7.633-9.92Z", transform: "translate(-5020.363 -13068.289)", fill: "#64c422" })
        group15.push({ id: "Path_214", dataName: "Path 214", d: "M1123.744,352.672a32.82,32.82,0,0,1,5.2,6.755l6.626-3.825a40.523,40.523,0,0,0-6.416-8.337h0l-5.408,5.408Z", transform: "translate(-4996.78 -13091.872)", fill: "#7dd149" })
        group15.push({ id: "Path_237", dataName: "Path 237", d: "M1094.753,381.663a25.139,25.139,0,0,1,3.98,5.172l6.626-3.825a32.821,32.821,0,0,0-5.2-6.755h0l-5.408,5.408Z", transform: "translate(-4973.197 -13115.455)", fill: "#9cde70" })
        group15.push({ id: "Path_259", dataName: "Path 259", d: "M1065.762,410.655a17.452,17.452,0,0,1,2.76,3.591l6.628-3.827a25.147,25.147,0,0,0-3.98-5.172h0l-5.408,5.408Z", transform: "translate(-4949.613 -13139.039)", fill: "#b7e99a" })
        group15.push({ id: "Path_284", dataName: "Path 284", d: "M1036.771,439.646a9.751,9.751,0,0,1,1.543,2.008l6.625-3.825a17.449,17.449,0,0,0-2.759-3.591h0l-5.408,5.408Z", transform: "translate(-4926.03 -13162.623)", fill: "#dff8ce" })
        colors.push({
            groupId: "Group_9614",
            groupDataName: "Group 9614",
            transform: "translate(-283 -83)",
            groupContent: group15
        })
        let group16: colorPlatter[] = [];
        group16.push({ id: "Path_115", dataName: "Path 115", d: "M1000,829.7v7.648a70.98,70.98,0,0,0,18.35-2.4l-1.978-7.381A63.58,63.58,0,0,1,1000,829.7Z", transform: "translate(-4896.118 -13482.583)", fill: "#210082" })
        group16.push({ id: "Path_143", dataName: "Path 143", d: "M1000,789.824v7.648a63.58,63.58,0,0,0,16.372-2.133l-1.98-7.39A55.9,55.9,0,0,1,1000,789.824Z", transform: "translate(-4896.118 -13450.355)", fill: "#240193" })
        group16.push({ id: "Path_172", dataName: "Path 172", d: "M1000,749.949V757.6a55.9,55.9,0,0,0,14.392-1.875l-1.98-7.39A48.209,48.209,0,0,1,1000,749.949Z", transform: "translate(-4896.118 -13418.129)", fill: "#2800a6" })
        group16.push({ id: "Path_197", dataName: "Path 197", d: "M1000,710.074v7.648a48.209,48.209,0,0,0,12.412-1.617l-1.98-7.39A40.512,40.512,0,0,1,1000,710.074Z", transform: "translate(-4896.118 -13385.901)", fill: "#421db7" })
        group16.push({ id: "Path_221", dataName: "Path 221", d: "M1000,670.2v7.648a40.514,40.514,0,0,0,10.432-1.359l-1.98-7.39A32.827,32.827,0,0,1,1000,670.2Z", transform: "translate(-4896.118 -13353.674)", fill: "#6041c0" })
        group16.push({ id: "Path_241", dataName: "Path 241", d: "M1000,630.323v7.648a32.827,32.827,0,0,0,8.452-1.1l-1.98-7.39A25.131,25.131,0,0,1,1000,630.323Z", transform: "translate(-4896.118 -13321.446)", fill: "#836ad2" })
        group16.push({ id: "Path_266", dataName: "Path 266", d: "M1000,590.435v7.648a25.131,25.131,0,0,0,6.471-.843l-1.981-7.393A17.44,17.44,0,0,1,1000,590.435Z", transform: "translate(-4896.118 -13289.206)", fill: "#a796e0" })
        group16.push({ id: "Path_285", dataName: "Path 285", d: "M1000,550.566v7.648a17.44,17.44,0,0,0,4.49-.588l-1.98-7.388A9.746,9.746,0,0,1,1000,550.566Z", transform: "translate(-4896.118 -13256.984)", fill: "#d2caf3" })
        colors.push({
            groupId: "Group_9606",
            groupDataName: "Group 9606",
            transform: "translate(-283 -83)",
            groupContent: group16
        })
        let group17: colorPlatter[] = [];
        group17.push({ id: "Path_113", dataName: "Path 113", d: "M1300.007,329.044l7.381-1.978a70.458,70.458,0,0,0-7.088-17.1l-6.613,3.818A62.436,62.436,0,0,1,1300.007,329.044Z", transform: "translate(-5135.024 -13061.535)", fill: "#0c8901" })
        group17.push({ id: "Path_132", dataName: "Path 132", d: "M1263.723,347.675l7.39-1.98a62.441,62.441,0,0,0-6.319-15.256l-6.626,3.825A54.876,54.876,0,0,1,1263.723,347.675Z", transform: "translate(-5106.13 -13078.186)", fill: "#0e9b00" })
        group17.push({ id: "Path_157", dataName: "Path 157", d: "M1222.648,354.771a47.323,47.323,0,0,1,4.791,11.566l7.39-1.98a54.875,54.875,0,0,0-5.555-13.411Z", transform: "translate(-5077.235 -13094.867)", fill: "#0caa01" })
        group17.push({ id: "Path_189", dataName: "Path 189", d: "M1191.156,385l7.39-1.98a47.322,47.322,0,0,0-4.791-11.566l-6.625,3.825A39.779,39.779,0,0,1,1191.156,385Z", transform: "translate(-5048.342 -13111.55)", fill: "#2dba1e" })
        group17.push({ id: "Path_216", dataName: "Path 216", d: "M1154.871,403.662l7.39-1.98a39.788,39.788,0,0,0-4.026-9.721l-6.626,3.825A32.239,32.239,0,0,1,1154.871,403.662Z", transform: "translate(-5019.447 -13128.231)", fill: "#4bc943" })
        group17.push({ id: "Path_236", dataName: "Path 236", d: "M1118.587,422.324l7.39-1.98a32.244,32.244,0,0,0-3.262-7.876l-6.626,3.825A24.674,24.674,0,0,1,1118.587,422.324Z", transform: "translate(-4990.553 -13144.914)", fill: "#74d96a" })
        group17.push({ id: "Path_261", dataName: "Path 261", d: "M1082.288,440.987l7.393-1.981a24.678,24.678,0,0,0-2.5-6.031l-6.628,3.827A17.144,17.144,0,0,1,1082.288,440.987Z", transform: "translate(-4961.647 -13161.597)", fill: "#a1e297" })
        group17.push({ id: "Path_286", dataName: "Path 286", d: "M1046.01,459.655l7.389-1.98a17.14,17.14,0,0,0-1.733-4.185l-6.625,3.825A9.573,9.573,0,0,1,1046.01,459.655Z", transform: "translate(-4932.758 -13178.284)", fill: "#ccf1c6" })
        colors.push({
            groupId: "Group_9613",
            groupDataName: "Group 9613",
            transform: "translate(-283 -83)",
            groupContent: group17
        })
        let group18: colorPlatter[] = [];
        group18.push({ id: "Path_108", dataName: "Path 108", d: "M1103.028,146.563l3.818-6.613a70.458,70.458,0,0,0-17.1-7.088l-1.978,7.381A62.439,62.439,0,0,1,1103.028,146.563Z", transform: "translate(-4967.518 -12917.463)", fill: "#9ab601" })
        group18.push({ id: "Path_137", dataName: "Path 137", d: "M1090.567,185.379l3.825-6.626a62.439,62.439,0,0,0-15.256-6.319l-1.98,7.39A54.885,54.885,0,0,1,1090.567,185.379Z", transform: "translate(-4958.882 -12949.653)", fill: "#adcc03" })
        group18.push({ id: "Path_159", dataName: "Path 159", d: "M1078.106,224.232l3.825-6.626a54.888,54.888,0,0,0-13.411-5.555l-1.98,7.39A47.33,47.33,0,0,1,1078.106,224.232Z", transform: "translate(-4950.247 -12981.881)", fill: "#c1e500" })
        group18.push({ id: "Path_183", dataName: "Path 183", d: "M1065.646,263.084l3.825-6.625a47.331,47.331,0,0,0-11.566-4.791l-1.98,7.39A39.779,39.779,0,0,1,1065.646,263.084Z", transform: "translate(-4941.611 -13014.107)", fill: "#cae825" })
        group18.push({ id: "Path_204", dataName: "Path 204", d: "M1053.186,301.937l3.825-6.626a39.781,39.781,0,0,0-9.721-4.026l-1.98,7.39A32.226,32.226,0,0,1,1053.186,301.937Z", transform: "translate(-4932.977 -13046.335)", fill: "#d5ed4f" })
        group18.push({ id: "Path_228", dataName: "Path 228", d: "M1034.694,338.292a24.669,24.669,0,0,1,6.031,2.5l3.825-6.626a32.218,32.218,0,0,0-7.876-3.262Z", transform: "translate(-4924.341 -13078.563)", fill: "#e0f179" })
        group18.push({ id: "Path_262", dataName: "Path 262", d: "M1028.259,379.646l3.827-6.628a24.67,24.67,0,0,0-6.031-2.5l-1.981,7.393A17.119,17.119,0,0,1,1028.259,379.646Z", transform: "translate(-4915.702 -13110.79)", fill: "#ebf7a3" })
        group18.push({ id: "Path_287", dataName: "Path 287", d: "M1015.8,418.511l3.824-6.624a17.12,17.12,0,0,0-4.185-1.733l-1.98,7.388A9.58,9.58,0,0,1,1015.8,418.511Z", transform: "translate(-4907.068 -13143.03)", fill: "#f5fad1" })
        colors.push({
            groupId: "Group_9589",
            groupDataName: "Group 9589",
            transform: "translate(-283 -83)",
            groupContent: group18
        })
        let group19: colorPlatter[] = [];
        group19.push({ id: "Path_109", dataName: "Path 109", d: "M1329.7,419.976h7.648a70.98,70.98,0,0,0-2.4-18.35l-7.381,1.978A63.58,63.58,0,0,1,1329.7,419.976Z", transform: "translate(-5162.583 -13136.094)", fill: "#019352" })
        group19.push({ id: "Path_131", dataName: "Path 131", d: "M1287.949,414.209a55.9,55.9,0,0,1,1.875,14.392h7.648a63.58,63.58,0,0,0-2.133-16.372Z", transform: "translate(-5130.355 -13144.719)", fill: "#02a75d" })
        group19.push({ id: "Path_166", dataName: "Path 166", d: "M1249.949,437.236h7.648a55.9,55.9,0,0,0-1.875-14.392l-7.39,1.98A48.2,48.2,0,0,1,1249.949,437.236Z", transform: "translate(-5098.128 -13153.354)", fill: "#01b867" })
        group19.push({ id: "Path_191", dataName: "Path 191", d: "M1210.074,445.871h7.648a48.209,48.209,0,0,0-1.617-12.412l-7.39,1.98A40.511,40.511,0,0,1,1210.074,445.871Z", transform: "translate(-5065.901 -13161.989)", fill: "#22c47b" })
        group19.push({ id: "Path_211", dataName: "Path 211", d: "M1170.2,454.507h7.648a40.517,40.517,0,0,0-1.359-10.432l-7.39,1.98A32.83,32.83,0,0,1,1170.2,454.507Z", transform: "translate(-5033.674 -13170.625)", fill: "#45d091" })
        group19.push({ id: "Path_235", dataName: "Path 235", d: "M1130.323,463.142h7.648a32.83,32.83,0,0,0-1.1-8.452l-7.39,1.98A25.127,25.127,0,0,1,1130.323,463.142Z", transform: "translate(-5001.446 -13179.26)", fill: "#6ddda6" })
        group19.push({ id: "Path_260", dataName: "Path 260", d: "M1090.435,471.777h7.648a25.133,25.133,0,0,0-.843-6.471l-7.393,1.981A17.439,17.439,0,0,1,1090.435,471.777Z", transform: "translate(-4969.206 -13187.896)", fill: "#9ae7c5" })
        group19.push({ id: "Path_288", dataName: "Path 288", d: "M1050.565,480.416h7.648a17.438,17.438,0,0,0-.588-4.491l-7.389,1.98A9.738,9.738,0,0,1,1050.565,480.416Z", transform: "translate(-4936.983 -13196.534)", fill: "#caf3e3" })
        colors.push({
            groupId: "Group_9612",
            groupDataName: "Group 9612",
            transform: "translate(-283 -83)",
            groupContent: group19
        })
        let group20: colorPlatter[] = [];
        group20.push({ id: "Path_114", dataName: "Path 114", d: "M1087.772,800.007l1.978,7.381a70.465,70.465,0,0,0,17.1-7.088l-3.818-6.613A62.44,62.44,0,0,1,1087.772,800.007Z", transform: "translate(-4967.518 -13455.024)", fill: "#000e85" })
        group20.push({ id: "Path_144", dataName: "Path 144", d: "M1077.156,763.723l1.98,7.39a62.445,62.445,0,0,0,15.256-6.319l-3.825-6.626A54.879,54.879,0,0,1,1077.156,763.723Z", transform: "translate(-4958.882 -13426.13)", fill: "#010e9c" })
        group20.push({ id: "Path_168", dataName: "Path 168", d: "M1066.541,727.439l1.98,7.39a54.882,54.882,0,0,0,13.411-5.555l-3.825-6.626A47.323,47.323,0,0,1,1066.541,727.439Z", transform: "translate(-4950.247 -13397.235)", fill: "#0011a8" })
        group20.push({ id: "Path_196", dataName: "Path 196", d: "M1055.925,691.156l1.98,7.39a47.323,47.323,0,0,0,11.566-4.791l-3.825-6.625A39.785,39.785,0,0,1,1055.925,691.156Z", transform: "translate(-4941.611 -13368.342)", fill: "#1e2fb5" })
        group20.push({ id: "Path_220", dataName: "Path 220", d: "M1045.31,654.871l1.98,7.39a39.783,39.783,0,0,0,9.721-4.026l-3.825-6.626A32.23,32.23,0,0,1,1045.31,654.871Z", transform: "translate(-4932.977 -13339.447)", fill: "#434fc6" })
        group20.push({ id: "Path_242", dataName: "Path 242", d: "M1034.694,618.588l1.98,7.39a32.233,32.233,0,0,0,7.876-3.262l-3.825-6.625A24.664,24.664,0,0,1,1034.694,618.588Z", transform: "translate(-4924.341 -13310.554)", fill: "#6974d5" })
        group20.push({ id: "Path_267", dataName: "Path 267", d: "M1024.074,582.288l1.981,7.393a24.669,24.669,0,0,0,6.031-2.5l-3.827-6.628A17.121,17.121,0,0,1,1024.074,582.288Z", transform: "translate(-4915.702 -13281.647)", fill: "#989fdf" })
        group20.push({ id: "Path_289", dataName: "Path 289", d: "M1013.461,546.01l1.98,7.389a17.119,17.119,0,0,0,4.185-1.733l-3.824-6.624A9.585,9.585,0,0,1,1013.461,546.01Z", transform: "translate(-4907.068 -13252.758)", fill: "#c4ccf0" })
        colors.push({
            groupId: "Group_9607",
            groupDataName: "Group 9607",
            transform: "translate(-283 -83)",
            groupContent: group20
        })
        let group21: colorPlatter[] = [];
        group21.push({ id: "Path_117", dataName: "Path 117", d: "M1169.561,749.778l3.818,6.613a71.151,71.151,0,0,0,14.675-11.274l-5.408-5.408A63.579,63.579,0,0,1,1169.561,749.778Z", transform: "translate(-5034.051 -13411.114)", fill: "#002d8b" })
        group21.push({ id: "Path_138", dataName: "Path 138", d: "M1149.054,719.569l3.825,6.626a63.584,63.584,0,0,0,13.085-10.069l-5.408-5.408A55.9,55.9,0,0,1,1149.054,719.569Z", transform: "translate(-5017.369 -13387.53)", fill: "#00339a" })
        group21.push({ id: "Path_162", dataName: "Path 162", d: "M1128.547,689.36l3.825,6.626a55.889,55.889,0,0,0,11.5-8.851l-5.408-5.408A48.222,48.222,0,0,1,1128.547,689.36Z", transform: "translate(-5000.687 -13363.947)", fill: "#0037ab" })
        group21.push({ id: "Path_190", dataName: "Path 190", d: "M1108.039,659.151l3.825,6.625a48.216,48.216,0,0,0,9.92-7.633l-5.408-5.408A40.515,40.515,0,0,1,1108.039,659.151Z", transform: "translate(-4984.004 -13340.363)", fill: "#1d50b9" })
        group21.push({ id: "Path_217", dataName: "Path 217", d: "M1087.532,628.942l3.825,6.626a40.513,40.513,0,0,0,8.337-6.416l-5.408-5.408A32.82,32.82,0,0,1,1087.532,628.942Z", transform: "translate(-4967.323 -13316.78)", fill: "#446ccc" })
        group21.push({ id: "Path_244", dataName: "Path 244", d: "M1067.024,598.733l3.825,6.625a32.822,32.822,0,0,0,6.755-5.2l-5.408-5.408A25.146,25.146,0,0,1,1067.024,598.733Z", transform: "translate(-4950.64 -13293.197)", fill: "#7393dc" })
        group21.push({ id: "Path_268", dataName: "Path 268", d: "M1050.1,565.761a17.442,17.442,0,0,1-3.591,2.759l3.827,6.628a25.148,25.148,0,0,0,5.172-3.98l-5.408-5.408Z", transform: "translate(-4933.951 -13269.612)", fill: "#99b0e4" })
        group21.push({ id: "Path_290", dataName: "Path 290", d: "M1028.013,536.77a9.749,9.749,0,0,1-2.008,1.543l3.824,6.624a17.44,17.44,0,0,0,3.591-2.759h0l-5.408-5.408Z", transform: "translate(-4917.272 -13246.029)", fill: "#c7d7f0" })
        colors.push({
            groupId: "Group_9608",
            groupDataName: "Group 9608",
            transform: "translate(-283 -83)",
            groupContent: group21
        })
        let group22: colorPlatter[] = [];
        group22.push({ id: "Path_118", dataName: "Path 118", d: "M1239.71,682.645l5.408,5.408a71.158,71.158,0,0,0,11.274-14.675l-6.613-3.818A63.573,63.573,0,0,1,1239.71,682.645Z", transform: "translate(-5091.115 -13354.05)", fill: "#014c8f" })
        group22.push({ id: "Path_140", dataName: "Path 140", d: "M1210.719,660.556l5.408,5.408a63.587,63.587,0,0,0,10.069-13.085l-6.626-3.825A55.9,55.9,0,0,1,1210.719,660.556Z", transform: "translate(-5067.532 -13337.368)", fill: "#00549f" })
        group22.push({ id: "Path_164", dataName: "Path 164", d: "M1181.728,638.466l5.408,5.408a55.9,55.9,0,0,0,8.851-11.5l-6.626-3.825A48.2,48.2,0,0,1,1181.728,638.466Z", transform: "translate(-5043.948 -13320.687)", fill: "#015fb2" })
        group22.push({ id: "Path_188", dataName: "Path 188", d: "M1152.736,616.375l5.408,5.408a48.213,48.213,0,0,0,7.633-9.92l-6.626-3.825A40.515,40.515,0,0,1,1152.736,616.375Z", transform: "translate(-5020.364 -13304.004)", fill: "#1f76bd" })
        group22.push({ id: "Path_213", dataName: "Path 213", d: "M1123.744,594.286l5.408,5.408a40.517,40.517,0,0,0,6.416-8.337l-6.626-3.825A32.816,32.816,0,0,1,1123.744,594.286Z", transform: "translate(-4996.78 -13287.322)", fill: "#458ccc" })
        group22.push({ id: "Path_245", dataName: "Path 245", d: "M1094.753,572.2l5.408,5.408a32.821,32.821,0,0,0,5.2-6.755l-6.625-3.825A25.145,25.145,0,0,1,1094.753,572.2Z", transform: "translate(-4973.197 -13270.64)", fill: "#6ca5d8" })
        group22.push({ id: "Path_269", dataName: "Path 269", d: "M1065.762,550.1l5.408,5.408a25.148,25.148,0,0,0,3.98-5.172l-6.628-3.827A17.448,17.448,0,0,1,1065.762,550.1Z", transform: "translate(-4949.613 -13253.951)", fill: "#9ac2e5" })
        group22.push({ id: "Path_291", dataName: "Path 291", d: "M1036.771,528.012l5.408,5.408a17.441,17.441,0,0,0,2.759-3.591L1038.314,526A9.753,9.753,0,0,1,1036.771,528.012Z", transform: "translate(-4926.03 -13237.271)", fill: "#c9e0f4" })
        colors.push({
            groupId: "Group_9609",
            groupDataName: "Group 9609",
            transform: "translate(-283 -83)",
            groupContent: group22
        })
        let group23: colorPlatter[] = [];
        group23.push({ id: "Path_107", dataName: "Path 107", d: "M1329.7,500h0a63.578,63.578,0,0,1-2.133,16.372l7.381,1.978a70.982,70.982,0,0,0,2.4-18.35H1329.7Z", transform: "translate(-5162.583 -13216.117)", fill: "#01809d" })
        group23.push({ id: "Path_141", dataName: "Path 141", d: "M1289.824,500a55.894,55.894,0,0,1-1.875,14.392l7.39,1.98A63.578,63.578,0,0,0,1297.472,500h-7.648Z", transform: "translate(-5130.355 -13216.117)", fill: "#008ca9" })
        group23.push({ id: "Path_163", dataName: "Path 163", d: "M1249.949,500a48.2,48.2,0,0,1-1.617,12.412l7.39,1.98A55.9,55.9,0,0,0,1257.6,500h-7.648Z", transform: "translate(-5098.128 -13216.117)", fill: "#00a0c5" })
        group23.push({ id: "Path_186", dataName: "Path 186", d: "M1210.074,500a40.513,40.513,0,0,1-1.359,10.432l7.39,1.98A48.209,48.209,0,0,0,1217.721,500h-7.648Z", transform: "translate(-5065.901 -13216.117)", fill: "#21adcd" })
        group23.push({ id: "Path_210", dataName: "Path 210", d: "M1170.2,500a32.825,32.825,0,0,1-1.1,8.452l7.39,1.98A40.518,40.518,0,0,0,1177.847,500H1170.2Z", transform: "translate(-5033.674 -13216.117)", fill: "#4abdda" })
        group23.push({ id: "Path_234", dataName: "Path 234", d: "M1130.323,500a25.127,25.127,0,0,1-.843,6.471l7.39,1.98a32.825,32.825,0,0,0,1.1-8.452h-7.648Z", transform: "translate(-5001.446 -13216.117)", fill: "#6fcfe0" })
        group23.push({ id: "Path_265", dataName: "Path 265", d: "M1090.435,500a17.439,17.439,0,0,1-.588,4.491l7.393,1.981a25.132,25.132,0,0,0,.843-6.471h-7.648Z", transform: "translate(-4969.206 -13216.117)", fill: "#9edceb" })
        group23.push({ id: "Path_292", dataName: "Path 292", d: "M1050.566,500a9.755,9.755,0,0,1-.329,2.511l7.388,1.98a17.439,17.439,0,0,0,.588-4.491h-7.648Z", transform: "translate(-4936.984 -13216.117)", fill: "#d6f1f7" })
        colors.push({
            groupId: "Group_9611",
            groupDataName: "Group 9611",
            transform: "translate(-283 -83)",
            groupContent: group23
        })
        let group24: colorPlatter[] = [];
        group24.push({ id: "Path_119", dataName: "Path 119", d: "M1293.689,603.027l6.613,3.818a70.443,70.443,0,0,0,7.087-17.1l-7.381-1.978A62.424,62.424,0,0,1,1293.689,603.027Z", transform: "translate(-5135.025 -13287.518)", fill: "#016496" })
        group24.push({ id: "Path_142", dataName: "Path 142", d: "M1258.169,590.566l6.626,3.825a62.435,62.435,0,0,0,6.319-15.256l-7.39-1.98A54.878,54.878,0,0,1,1258.169,590.566Z", transform: "translate(-5106.13 -13278.882)", fill: "#016ea7" })
        group24.push({ id: "Path_165", dataName: "Path 165", d: "M1222.649,578.106l6.625,3.825a54.878,54.878,0,0,0,5.555-13.411l-7.39-1.98A47.326,47.326,0,0,1,1222.649,578.106Z", transform: "translate(-5077.236 -13270.246)", fill: "#007ebb" })
        group24.push({ id: "Path_187", dataName: "Path 187", d: "M1187.129,565.646l6.626,3.825a47.328,47.328,0,0,0,4.791-11.566l-7.39-1.98A39.773,39.773,0,0,1,1187.129,565.646Z", transform: "translate(-5048.342 -13261.611)", fill: "#2290c7" })
        group24.push({ id: "Path_212", dataName: "Path 212", d: "M1151.609,553.185l6.626,3.825a39.779,39.779,0,0,0,4.026-9.721l-7.39-1.98A32.232,32.232,0,0,1,1151.609,553.185Z", transform: "translate(-5019.447 -13252.976)", fill: "#2290c7" })
        group24.push({ id: "Path_240", dataName: "Path 240", d: "M1116.09,540.724l6.625,3.825a32.229,32.229,0,0,0,3.262-7.876l-7.39-1.98A24.674,24.674,0,0,1,1116.09,540.724Z", transform: "translate(-4990.554 -13244.34)", fill: "#6ebadc" })
        group24.push({ id: "Path_258", dataName: "Path 258", d: "M1080.555,528.259l6.628,3.827a24.673,24.673,0,0,0,2.5-6.031l-7.393-1.981A17.118,17.118,0,0,1,1080.555,528.259Z", transform: "translate(-4961.647 -13235.701)", fill: "#9ccfe6" })
        group24.push({ id: "Path_293", dataName: "Path 293", d: "M1045.042,515.8l6.624,3.825a17.117,17.117,0,0,0,1.733-4.185l-7.389-1.98A9.583,9.583,0,0,1,1045.042,515.8Z", transform: "translate(-4932.758 -13227.068)", fill: "#c5dff0" })
        colors.push({
            groupId: "Group_9610",
            groupDataName: "Group 9610",
            transform: "translate(-283 -83)",
            groupContent: group24
        })
        this.setState({ colors: colors, secoundColor: null, firstColor:false })
    }


    componentDidMount() {
        //Listen
        let secondSvg: any = secoundColorPickerRef.current;
        let canvas: any = canvasRef.current;
        let x;
        let y;
        secondSvg.addEventListener('click', (e: any) => {
            if(e.offsetX) {
                x = e.offsetX;
                y = e.offsetY; 
                }
                else if(e.layerX) {
                x = e.layerX;
                y = e.layerY;
                }
            this.useCanvas(canvas, secondSvg, () => {
                let p: any = canvas.getContext('2d').getImageData(x, y, 1, 1).data;
                let colorHex = this.rgbToHex(p[0], p[1], p[2]);
                if(!this.state.firstColor){
                    this.props.onClick(colorHex);
                }
                this.setState({firstColor:false});
            });
        }, false);

    }

    createColorPlatter(object: colorPlatter) {
        let path = <path id={object.id} data-name={object.dataName} d={object.d} transform={object.transform} fill={object.fill} onClick={() => {
            this.setState({ secoundColor: object.fill });
            this.setState({ firstColor: true });
            this.props.onClick(object.fill);
        }} />
        return path;

    }
    createGroup(object: colorPicker) {
        const group = <g id={object.groupId} data-name={object.groupDataName} transform={object.transform}>
            {object.groupContent.map(path => (
                this.createColorPlatter(path)
            ))}
        </g>
        return group;
    }

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }

    findPos(obj) {
        var curleft = 0, curtop = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return { x: curleft, y: curtop };
        }
        return undefined;
    }


    useCanvas(el, svg, callback) {
        let xml = new XMLSerializer().serializeToString(svg);
        let svg64 = btoa(xml);
        let img = new Image();
        img.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
        img.width = svg.clientWidth;
        img.height = svg.clientHeight;
        el.width = img.width;
        el.height = img.height;
        setTimeout(() => {
            el.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
            return callback();
        }, 50);
    }

    getPosition(obj) {
        var curleft = 0, curtop = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return { x: curleft, y: curtop };
        }
        return undefined;
    }

    render() {
        const { onClick } = this.props;
        const { secoundColor } = this.state;
        return (
            <div className="wheel">
                <svg className="second-color-picker" ref={secoundColorPickerRef} id="second-color-picker" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 980 980">
                    <defs>
                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#fff" />
                            <stop offset="0.80" stop-color={secoundColor} />
                            <stop offset="1" />
                        </linearGradient>
                    </defs>
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="459.5" cy="459.5" r="459.5" transform="translate(31 31)" fill="#fff" />
                    <path id="Path_1" data-name="Path 1" className="circle" d="M491.342,0C221.463,0,2.684,218.78,2.684,488.658S221.463,977.316,491.342,977.316,980,758.537,980,488.658,761.22,0,491.342,0Zm0,901.065c-227.766,0-412.407-184.641-412.407-412.407S263.576,76.252,491.342,76.252s412.407,184.64,412.407,412.406S719.108,901.065,491.342,901.065Z" transform="translate(-1.684 1)" fill="url(#linear-gradient)" />
                    <svg className="first-color-picker" xmlns="http://www.w3.org/2000/svg" width="900" height="900" viewBox="0 0 180 180">
                        <g id="Group_9669" data-name="Group 9669" transform="translate(4236 12885)">
                            <g id="Rectangle_856" data-name="Rectangle 856" transform="translate(-4219 -12868)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                                <rect width="160" height="160" stroke="none" />
                                <rect x="0.5" y="0.5" width="159" height="159" fill="none" />
                            </g>
                            <g id="Group_9640" data-name="Group 9640" transform="translate(40 11)">
                                {this.state.colors.map(g => (
                                    this.createGroup(g)
                                ))}
                                <path id="Path_294" data-name="Path 294" d="M 1004, 493 a 8,8 0 1,1 -8,0" transform="translate(-5179.118 -13299.118)" fill="#fff" onClick={() => {
                                    onClick("#fff")
                                    this.setState({ secoundColor: "#fff" })
                                }} />
                            </g>
                        </g>
                    </svg>
                </svg>
                <canvas id="cs" ref={canvasRef}></canvas>
            </div>
        );
    }
}

export default ColorPicker;