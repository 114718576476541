
export class FormatDate{
    
    trasformDate(date: Date): string {
        let dateAux: any = null;
        let mm: any = date.getMonth() + 1;
        let dd: any = date.getDate();
        const yyyy: number = date.getFullYear();
        dd = dd < 10 ? "0" + dd : dd;
        mm = mm < 10 ? "0" + mm : mm;
        dateAux = dd + "/" + mm + "/" + yyyy;
        return dateAux;
    }

    trasformDateToField(date: Date): string {
        let dateAux: any = null;
        let mm: any = date.getMonth() + 1;
        let dd: any = date.getDate();
        const yyyy: number = date.getFullYear();
        dd = dd < 10 ? "0" + dd : dd;
        mm = mm < 10 ? "0" + mm : mm;
        dateAux = yyyy + "-" + mm + "-" + dd;
        return dateAux;
    }
   static trasformDate(date: Date): string {
        let dateAux: any = null;
        let mm: any = date.getMonth() + 1;
        let dd: any = date.getDate();
        const yyyy: number = date.getFullYear();
        dd = dd < 10 ? "0" + dd : dd;
        mm = mm < 10 ? "0" + mm : mm;
        dateAux = dd + "/" + mm + "/" + yyyy;
        return dateAux;
    } 
   
}