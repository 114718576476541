import * as React from 'react';
import { Box, Grid, Typography, Link } from '@material-ui/core'
import './story.scss';
import ButtonReaction from '../buttonReaction/buttonReaction';
import { object } from 'prop-types';
import { storyService, authenticationService } from '../../../services';
import { Notifier } from '../notifier/notifier';
import { Common } from '../../../common';

const avatar  = Common.getAvadarDefaut();
const limit = 100;

export interface Props {
    reactionsList: Array<any>
    feed: any;
    currentUser: any,
}

export interface State {
    reactionsListStory: Array<any>;
    hasReaction: boolean;
    emotions: Array<any>;
    messageBar:any;
}

let childUser;

class Story extends React.Component<Props, State>{

    constructor(props) {
        super(props);
        const stateObject={
            msg: "",
            status:false,
            date:new Date(),
            variant:''
        }
        this.state = {
            reactionsListStory: [],
            hasReaction: false,
            emotions: [],
            messageBar: stateObject
        };
    }

    callbackFunctionLike = (object: any) => {
        let listAux: Array<any> = this.state.reactionsListStory;
        for (var i = 0; i < listAux.length; i++) {
            if (object.reactById === listAux[i].reactById) {
                listAux.splice(i, 1, object);
                this.setState({
                    reactionsListStory: listAux,
                    emotions: this.getListEmotions(listAux)
                });
                return;
            }
        }
        listAux.push(object);
        this.setState({
            reactionsListStory: listAux,
            hasReaction: true,
            emotions: this.getListEmotions(listAux)
        });
    }

    // unlike = (userId: number, reactionsListStory:Array<any>) => {
    //     let listAux: Array<any> = reactionsListStory;
    //     for (var i = 0; i < listAux.length; i++) {
    //         if (userId === listAux[i].reactById) {
    //             storyService.removeReactionFromStory(listAux[i].storyId, listAux[i].reactionId).then((data: any) => {
    //                 const stateObject={
    //                     msg: data.message ,
    //                     status:true,
    //                     date:new Date(),
    //                     variant:''
    //                 }
    //                 if (data.success) {
    //                     stateObject.variant ='success'
    //                     listAux.splice(listAux[i], 1);
    //                     this.setState({
    //                         reactionsListStory: listAux,
    //                         hasReaction: false,
    //                         emotions: this.getListEmotions(listAux
    //                         )
    //                     });
    //                 } else {
    //                     //ERROR
    //                     stateObject.variant ='error'
    //                 }
    //                 this.setState({messageBar:stateObject});                   
    //                 setTimeout(() => {
    //                     const stateObject={
    //                         msg: "",
    //                         status:false,
    //                         date:new Date(),
    //                         variant:''
    //                     }
    //                     this.setState({messageBar:stateObject});
    //                 }, 2000);  
    //             }, error => {
    //                 //ERROR
    //                 console.log("ERROR", error.message);

    //             })
    //         }
    //     }
    // }

    componentWillMount() {
        childUser = authenticationService.isTeamMemberChildValue;
        let currentUserId = childUser ? childUser.userId : this.props.currentUser.userId;
        const hasReaction = this.checkHasReaction(currentUserId, this.props.feed.reactions);
        this.setState({
            reactionsListStory: this.props.feed.reactions,
            hasReaction: hasReaction,
            emotions: this.getListEmotions(this.props.feed.reactions)
        });
    }

    checkHasReaction = (userId: number, reactionsListStory: Array<any>): boolean => {
        for (var i = 0; i < reactionsListStory.length; i++) {
            if (reactionsListStory[i].reactById === userId) {
                return true;
            }
        }
        return false;
    }

    getListEmotions(reactionsListStory: Array<any>) {
        let auxList: Array<any> = [];
        reactionsListStory.forEach(element => {
            auxList.push(element.reactionId);
        });
        return auxList.filter((el, index, arr) => arr.indexOf(el) === index);
    }

    render() {
        const { reactionsList, feed, currentUser } = this.props;
        const { reactionsListStory, hasReaction, emotions, messageBar } = this.state;

        return (
            <div style={{ padding: "8px", display: "flex" }} id={feed.id}>
                <Grid item xs={3} style={{ }}>
                    <img src={feed.childAvatarFile ? feed.childAvatarFile : avatar} style={{ maxWidth: "70%", margin: "auto" }}></img>
                </Grid>
                <Grid item xs={9} className="information-story">
                    <Link className="name" href={`/mydreamcircle/${feed.childId}`}>{feed.childName}</Link>
                    <Typography className="creation-on">
                        {feed.creationDate}
                    </Typography>
                    <Typography className="story">
                        {feed.message.length <= limit ? feed.message : feed.message.substring(0, limit) + "..."}
                    </Typography>
                    <Box style={{ display: "flex" }}>
                        <Grid item xs={7}>
                            <Box>
                                <Typography className={hasReaction ? "has-reaction" : ''} style={{ marginTop: "auto", marginBottom: "auto", fontSize: "14px" }}>
                                    {reactionsListStory.length + " Reaction" + (reactionsListStory.length === 1 ? "" : "s")}
                            </Typography>
                            </Box>
                            {reactionsListStory.length > 0 ?
                                <Box style={{ display: "felx" }}>
                                    {emotions.map(item => (
                                        <img src={require('../../../assets/images/emotions/' + item + '.svg')} style={{ width: "8%", paddingRight: "3px" }}></img>
                                    ))}
                                </Box> : null}

                        </Grid>
                        <Grid item xs={5}>
                            <Typography className="select-reaction">
                                <ButtonReaction feed={feed} reactionsList={reactionsList} parentCallback={this.callbackFunctionLike} title="Add Reaction"></ButtonReaction>
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
                {this.state.messageBar.status ? 
                     <Notifier
                        variant={this.state.messageBar.variant}  
                        key={this.state.messageBar.date} 
                        status={this.state.messageBar.status} 
                        message={this.state.messageBar.msg}  /> : null}
            </div>
        );
    }
}

export default Story;