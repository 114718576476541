// import config from 'config';
import dotenv from 'dotenv'


// Set the NODE_ENV to 'development' by default
// process.env.NODE_ENV = process.env.NODE_ENV || 'development';

const envFound = dotenv.config();

// if (!envFound) {
//   // This error should crash whole process

//   throw new Error("⚠️  Couldn't find .env file  ⚠️ ");
// }

export default {

  
  //  apiURL:config.apiURL
   apiURL:process.env.REACT_APP_API_URL,
   apiURLSocket:process.env.REACT_APP_API_URL_SOCKET,
   apiSocketPath: process.env.REACT_APP_SOCKET_PATH,

   helpUrl :process.env.REACT_APP_HELP_URL,

}