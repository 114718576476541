export enum userRole{
    child=1,
    teamMember=2,
    Admin=3
}

export enum archievementType{
    story='Story',
    star='Star',
    dreamCircle='dreamCircle'
}

export enum dreamCircle{
    cooperation=5,
    empathy=1,
    selfLove=3,
    spiritual=4,
    thinkingSmart=2
}

export enum notificationAction{
    gotReaction="gotReaction",
    newFollower="newFollower",
    newStory="newStory",
    gotStar="gotStar",
    completeDreamCircle="completeDreamCircle"
}

export enum menuTypes{
    age=1,
    hair=2,
    hat=3,
    face=4,
    shirt=5,
    tool=6,
    background=7,
    suit=8,
    character=9,
}
export enum characterName{

    personFemale="Person Female",
    personMale="Person Male",
    Goanna="Goanna",
    Kanagroo="Kanagroo",
    Echidna="Echidna",
    Dolphin="Dolphin",
    Dingo="Dingo",
    Turtle="Turtle",
    Dinasaur="Dinasaur",
    Platypus="Platypus",
    Dana="Dana",
    Rob="Rob",
    MyKol="MyKol",
    Cory="Cory",
    Russell="Russell"
}

export enum characterDefaultColor{
    platypusDefaultColor="#842f3f",
    goannaDefaultColor="#4b8757",
    turtleDefaultColor="#d2e968",
    dolphinDefaultColor="#4fb1c1",
    dingoDefaultColor="#e8821a",
    dinasaurDefaultColor="#41842F",
    kangarooDefaultColor="#b7462c",
    personMaleDefaultColor="#cc8b6e",
    personFemaleDefaultColor="#cc8b6e",
    echidnaDefaultColor="#FFA81D"
}

export enum backGroundName{
   moon="Moon",
   jungle="Jungle",
   forest="Forest"
}

export enum positionChefTool{
    top=456,
    left=163,
    width=98,
    height=58
}

export enum positionAboriginalTool{
    top=396,
    left=160,
    width=100,
    height=60
}

export enum positionSuperHeroTool{
    top=230,
    left=185,
    width=335,
    height=200
}

export enum positionDancerTool{
    top=473,
    left=137,
    width=100,
    height=60
}

export enum positionSurvivorTool{
    top=540,
    left=110,
    width=165,
    height=100
}

export enum positionChickenLittleTool{
    top=437,
    left=177,
    width=87,
    height=52
}
export enum positionMonsterOnesieTool{
    top=541,
    left=154,
    width=156,
    height=93
}
export enum positionScubaTool{
    top=390,
    left=151,
    width=118,
    height=70
}

export enum positionAmeliaTool{
    top=520,
    left=165,
    width=128,
    height=76
}

export enum positionBBoyTool{
    top=550,
    left=120,
    width=210,
    height=125
}

export enum positionDapperTool{
    top=473,
    left=196,
    width=50,
    height=30
}

export enum nameTool{
    chefTool="Chef - Tool",
    aboriginalTool='Aboriginal - Tool',
    dancerTool='Dancer - Tool',
    survivorTool='Survivor - Tool',
    chickenLittleTool='Chicken Little - Tool',
    monsterOnesieGreenTool='Monster Onesie Green - Tool',
    monsterOnesiePinkTool='Monster Onesie Pink - Tool',
    scubaTool='Scuba - Tool',
    ameliaTool='Amelia - Tool',
    bBoyTool='BBoy - Tool',
    dapperTool='Dapper - Tool',
    superheroTool='Superhero - Tool'
}

export enum nameSuit{
    chefSet="Chef Set",
    aboriginalSet='Aboriginal Set',
    dancerSet='Dancer Set',
    survivorSet='Survivor Set',
    chickenLittleSet='Chicken Little Set',
    monsterOnesieGreenSet='Monster Onesie Green Set',
    monsterOnesiePinkSet='Monster Onesie Pink Set',
    scubaSet='Scuba Set',
    ameliaSet='Amelia Earhart Set',
    bBoySet='BBoy Set',
    dapperSet='Dapper Set',
    superheroSet='Superhero Set'
}

export enum typeImage{
    toPng="png",
    toJpeg="jpeg",
    toBlob="blob",
    toPixelData="pixel",
    toSvgDataURL="svg"
}

    
     
     