import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import {Link} from "@material-ui/core"
// import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const defaultToolbarStyles = {
  iconButton: {
  },
};

export interface Props{
    classes:any;
    onClick:any;
    toolTip:string;
    text:string;
    cssClasses:any;
}

export interface  State {
    
}

class ButtonComponent extends React.Component<Props, State>{
  
   
      render() {
     console.log(this.props.onClick)
    //     const useStyles = makeStyles((theme: Theme) =>
    //     createStyles({
    //       button: {
    //         margin: theme.spacing(1),
    //       },
    //     }),
    //   );
    //   const classes = useStyles();
        return (
          <React.Fragment>
            <Tooltip title={ this.props.toolTip}>
                <Button variant="contained" onClick={()=>this.props.onClick()} className={this.props.cssClasses}>
                     {this.props.text}
                </Button>
            </Tooltip>
          </React.Fragment>
        );
      }
    
    }
export default withStyles(defaultToolbarStyles, { name: "ButtonComponent" })(ButtonComponent);