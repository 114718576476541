import React from 'react';
import './formProfile.scss';
import { Formik, Form } from 'formik/dist/index';
import { Grid, InputBase, TextField, Button, CircularProgress, Box, FormControlLabel, withStyles, createStyles, Theme, Switch, makeStyles } from '@material-ui/core';
import { FormatDate } from '../../../common/formatDate';
import { childrenService } from '../../../services';
import { userRole } from '../../../enums';
import { SwitchProps, SwitchClassKey } from '@material-ui/core/Switch';
import { green } from '@material-ui/core/colors';
import { Notifier } from '../notifier/notifier';

export interface Profile {
    id: number,
    uniqueId: string,
    email: string,
    firstName: string,
    lastName: string,
    fullName: string,
    dateOfBirth: string,
    gender: string,
    office: string,
    phoneNumber: number,
    mobileNumber: number,
    andress: string,
    streetAddress: string,
    suburb: string,
    state: string,
    postCode: number,
    type: string,
    isAdmin: boolean,
    startDate: string,
    aboutMe: string,
    roleId: number,
    officeId: number
}


interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface PropsSwitch extends SwitchProps {
    classes: Styles,
}

interface Props {
    className: any;
    data: any;
    roleId: any
}

export interface State {
    data: any,
    isLoading: boolean,
    snackBarStatus: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
    }),
);

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1)
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#52d869",
                    opacity: 1,
                    border: "none"
                }
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff"
            }
        },
        thumb: {
            width: 24,
            height: 24
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.primary[400]}`,
            backgroundColor: theme.palette.primary[50],
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"])
        },
        checked: {},
        focusVisible: {}
    })
)(({ classes, ...props }: PropsSwitch) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

class FormProfile extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: 'error'
        }

        this.state = {
            data: "",
            isLoading: false,
            snackBarStatus: stateObject
        }
    }

    componentWillMount() {
        this.setState({ data: this.props.data });
    }

    submitProfile(profile: any) {
        const stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: ''
        }
        if (profile) {
            console.log("TESTING", profile);
            const profileAux:any = {
                id: profile.id,
                uniqueId: profile.uniqueId,
                email: profile.email,
                firstName: profile.firstName,
                lastName: profile.lastName,
                dateOfBirth: profile.dateOfBirth,
                streetAddress: profile.streetAddress,
                suburb: profile.suburb,
                state: profile.state,
                postCode: profile.postCode,
                phoneNumber: profile.phoneNumber,
                startDate: profile.startDate,
                mobileNumber: profile.mobileNumber,
                aboutMe: profile.aboutMe,
                officeId: profile.officeId,
                gender: profile.gender.toUpperCase()
            };
            childrenService.updateChildDetails(profileAux).then((data: any) => {
                if (data.success) {
                    stateObject.msg = data.message;
                    stateObject.variant = "success";
                    stateObject.status = true;
                } else {
                    //ERROR
                    stateObject.msg = data.message;
                    stateObject.variant = "error";
                    stateObject.status = true;
                }
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 2000);
            }, error => {
                //ERROR
                console.log(error.message);
                stateObject.msg = "This error is on server, try again";
                stateObject.variant = "error";
                stateObject.status = true;
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 2000);

            })
            console.log(profile);
        }
    }

    render() {
        const { className, roleId } = this.props;
        const { isLoading, data } = this.state;
        return (
            <div className={className}>
                <Formik<Profile>
                    initialValues={{
                        id: data.id ? data.id : 0,
                        uniqueId: data.uniqueId ? data.uniqueId : "",
                        email: data.email ? data.email : "",
                        firstName: data.firstName ? data.firstName : "",
                        lastName: data.lastName ? data.lastName : "",
                        fullName: data.fullName ? data.fullName : "",
                        dateOfBirth: data.dateOfBirth ? FormatDate.trasformDate(new Date(data.dateOfBirth)) : "",
                        gender: data.gender ? data.gender.toUpperCase() === "M" ? "Male" : data.gender.toUpperCase() === "F" ? "Female" : "No Gender" : "",
                        office: data.Office ? data.Office.officeName : "",
                        phoneNumber: data.phoneNumber ? data.phoneNumber : "",
                        andress: data.address ? data.address : "",
                        streetAddress: data.streetAddress ? data.streetAddress : "",
                        suburb: data.suburb ? data.suburb : "",
                        state: data.state ? data.state : "",
                        postCode: data.postCode ? data.postCode : 0,
                        startDate: data.startDate ? FormatDate.trasformDate(new Date(data.startDate)) : "",
                        aboutMe: data.aboutMe ? data.aboutMe : "",
                        mobileNumber: data.mobileNumber ? data.mobileNumber : "",
                        isAdmin: roleId === userRole.Admin ? true : false,
                        type: data.jobTitle ? data.jobTitle : "",
                        roleId: roleId,
                        officeId: data.Office ? data.Office.id : 0,
                    }}
                    //validationSchema={schema}
                    onSubmit={(values: Profile) => {
                        this.submitProfile(values);
                    }}
                    render={({ handleChange, submitForm, values, handleBlur, errors }) => (
                        <Form>
                            <Grid container xs={12} item >
                                {values.roleId !== userRole.child ?
                                    <Grid item xs={12} style={{ display: "flex" }} className={"row-title"}>
                                        <Grid item xs={4}>
                                            <InputBase
                                                defaultValue="Details"
                                                inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                                className={"label profile-title"}
                                            />
                                        </Grid>
                                        <Grid item xs={8} style={{ display: "flex" }}>
                                            <InputBase
                                                defaultValue="Admin Member?"
                                                inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                                className={"label label-admin"}
                                            />
                                            <FormControlLabel
                                                style={{ float: "right" }}
                                                name="isCompleted"
                                                disabled={true}
                                                control={
                                                    <IOSSwitch
                                                        checked={values.isAdmin}
                                                    />
                                                }
                                                label=""
                                            />
                                        </Grid>
                                    </Grid>
                                    : null}
                                <Grid item xs={12} className={"row"}>
                                    <InputBase
                                        defaultValue="Unique ID"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        value={values.uniqueId}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>

                                <Grid item xs={12} className={"row"} >
                                    <InputBase
                                        defaultValue="Name"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="fullName"
                                        defaultValue={values.fullName}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>
                                {values.roleId === userRole.child ?
                                    <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                                        <InputBase
                                            defaultValue="Date of Birth"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            name="dateOfBirth"
                                            defaultValue={values.dateOfBirth}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        />
                                    </Grid>
                                    : null}

                                {values.roleId === userRole.child ?
                                    <Grid item xs={12} className={"row"}>
                                        <InputBase
                                            defaultValue="Gender"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            name="gender"
                                            defaultValue={values.gender}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        />
                                    </Grid>
                                    : null}

                                <Grid item xs={12} className={"row"} >
                                    <InputBase
                                        defaultValue="Office"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="office"
                                        value={values.office}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>

                                <Grid item xs={12} className={"row"} >
                                    <InputBase
                                        defaultValue="Phone Number"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>

                                {values.roleId !== userRole.child ?
                                    <Grid item xs={12} className={"row"} >
                                        <InputBase
                                            defaultValue="Mobile Number"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            name="mobileNumber"
                                            value={values.mobileNumber}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        />
                                    </Grid>
                                    : null}

                                <Grid item xs={12} className={"row"} >
                                    <InputBase
                                        defaultValue="Address"
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="andress"
                                        value={values.andress}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        multiline
                                        rowsMax="3"
                                        className={"andress"}
                                    />
                                </Grid>

                                {values.roleId !== userRole.child ?
                                    <Grid item xs={12} className={"row"} >
                                        <InputBase
                                            defaultValue="Type"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            name="type"
                                            value={values.type}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        />
                                    </Grid>
                                    : null}

                                <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                                    <InputBase
                                        defaultValue={values.roleId === userRole.child ? "Start of Healing Pathway" : "Start Date"}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        className={"label"}
                                    />
                                    <InputBase
                                        name="startDate"
                                        value={values.startDate}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                    />
                                </Grid>
                                {values.roleId === userRole.child ?
                                    <Box style={{ width: "100%" }}>
                                        <Grid item xs={12} className={"row"}>
                                            <TextField
                                                className={"text-about-me"}
                                                id="filled-multiline-static"
                                                label="About Me"
                                                multiline
                                                rows="4"
                                                variant="filled"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.aboutMe}
                                                type="text"
                                                name="aboutMe"
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                            <Button
                                                variant="contained"
                                                className={"green-button"}
                                                style={{ float: "right" }}
                                                onClick={submitForm}>Save {isLoading && <CircularProgress size={24} className={"loading"} />}</Button>

                                        </Grid>
                                    </Box>
                                    : null}
                            </Grid>
                        </Form>
                    )}
                />
                {this.state.snackBarStatus.status ?
                    <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </div>
        );
    }
}

export default FormProfile;