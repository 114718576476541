import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface PlatypusProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface PlatypusState {
    height:string
}


export class Platypus extends React.Component<PlatypusProps, PlatypusState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                        <path fill={backGroundColor}  d="M384,258.9c-0.9-8.1-3-16.1-6.1-23.7c-17.5-43.5-68.1-85.3-127.8-85.3c-59.6,0-110.1,41.6-127.7,84.9
                                c-4.3,10.6-6.6,21.4-6.6,31.6c0,6.4,0.4,12.9,1.1,19.3c0.4,2.9,0.8,5.8,1.3,8.5c1.9,10.4,5.4,20.4,10.5,29.6
                                c9,16.2,22.9,28.9,42.2,37.7c0.5,7,6.3,11.8,15.5,15.1c-1.3,1.5-2.7,3-4.1,4.7c-3.3-2.4-6.9-4.1-10.8-5.1
                                c-19.2-4.9-40.5-1.3-52.5,12l0.1-0.1c0,0-9.4,7.2-11.7,24.1c-2.3,16.9,9.9,52.6,45.7,71.5c12.3,6.4,25.2,11.5,38.6,15.2l0,0
                                c0.3,0.9,0.6,1.8,1.1,2.7l0,0c2.3,0.2,4.8,0.5,7.5,1c16.6,2.9,17.8,6.1,17.9,6.4c0.1,0,0.1,0,0.2-0.1c3.5-1.7,6.1-4.7,7.3-8.4
                                c3.8,0.2,7.7,0.2,11.5,0.1c12.4-0.7,21.5-4.9,27.1-10.7l0,0c2.9-0.2,6-0.6,9.1-1c0,0-2.2,15.1,8.4,20c0.1,0,0.2,0.1,0.4,0.1
                                c0-0.2,0.8-3.5,17.9-6.5c2.7-0.5,5.1-0.8,7.3-0.9l0,0c1.5-3.2,2.3-6.6,2.7-10.1c1.4-11.9,1.4-34.3,1.4-34.3
                                c0.1-7-0.1-13.8-0.4-20.2c5.4,4.4,12.1,5.7,20.2,0.8c20.3-12.1-1.5-42.3-16.2-59.2c8.6-2.2,15.3-5.3,18.9-9.6c2-2.2,3.1-5.1,3.2-8
                                c0-0.1,0-0.2,0-0.3c0-0.1,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6v-0.5V359c0-0.4-0.1-0.8-0.2-1.1c15.4-8.5,26.9-19.9,34.7-34
                                c5-9.2,8.6-19.2,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8l0,0c0.3-2.6,0.6-5.2,0.8-7.9c0.3-3.9,0.4-7.8,0.4-11.9
                                C384.4,264,384.3,261.5,384,258.9z"/>
                            <path opacity="0.55" fill="#333333" d="M190,491.6c-1.4-11.9-1.4-34.3-1.4-34.3c-0.1-7.1,0.1-13.9,0.4-20.4c-5.4,4.6-12.2,6-20.5,1
                                c-19.3-11.5-0.6-39.3,13.9-56.5c-3.3-2.4-6.9-4.1-10.8-5.1c-19.2-4.9-40.5-1.3-52.5,12l0.1-0.1c0,0-9.4,7.3-11.7,24.2
                                c-2.3,16.9,9.9,52.6,45.7,71.5c12.3,6.4,25.2,11.5,38.6,15.2C190.8,496.7,190.2,494.2,190,491.6z"/>
                            <path opacity="0.55" fill="#333333" d="M249.9,490.9c-7.8-0.2-15.6-0.8-23.3-1.8c0.5,3.9,0.1,7.9-1.1,11.6
                                c3.8,0.2,7.7,0.2,11.5,0.1c12.4-0.7,21.5-4.9,27.1-10.7C256.2,490.8,249.9,490.9,249.9,490.9z"/>
                            <path opacity="0.8" fill="#2B2B2B" d="M190,491.6c-0.1-0.7-0.2-1.4-0.2-2.2c-15.7-4.8-35-12.4-50-24.2
                                c-28.5-22.2-38-57.9-20.7-77.1c0,0-9.4,7.3-11.7,24.2c-2.3,16.9,9.9,52.6,45.7,71.5c12.3,6.4,25.2,11.5,38.6,15.2
                                C190.8,496.7,190.2,494.2,190,491.6z"/>
                            <path fill="#442815" d="M218,509.2c0,0-0.3-3.4-17.9-6.5c-30.2-5.2-36.6,8.6-31.4,12c4.3,2.8,15.2-3.2,26.7-5.2
                                c-3.9,0.8-7.7,2-11.3,3.5c-6.1,2.8-5.4,6.4,0.7,6.9c6.1,0.5,13.5-6,20.3-8.7c2.2-0.9,4.6-1.6,7-2
                                C217.2,509.4,218.1,509.4,218,509.2z"/>
                            <path fill="#331D0E" d="M179.6,502.3c-1.2,0-2.3-0.1-3.5-0.1c-8.5,0-13.8,2.9-14.1,4.9c-0.3,2.4,1.8,3.8,5,4.3
                                C167.2,508.2,170.9,503.9,179.6,502.3z"/>
                            <path fill="#442815" d="M299.8,502.7c-17.6,3.1-17.9,6.5-17.9,6.5c-0.1,0.2,0.8,0.2,5.8,0c2.4,0.4,4.7,1.1,6.9,2
                                c6.8,2.8,14.2,9.2,20.3,8.7c6.1-0.5,6.8-4.1,0.7-6.9c-3.8-1.6-7.7-2.8-11.7-3.6c11.7,1.9,22.8,8.1,27.3,5.3
                                C336.5,511.3,330,497.5,299.8,502.7z"/>
                            <path fill="#331D0E" d="M337.7,507.1c-0.3-2-5.6-4.9-14.1-4.9c-1.1,0-2.2,0-3.3,0.1c8.7,1.6,12.4,5.9,12.6,9.2
                                C335.9,510.8,338,509.6,337.7,507.1z"/>
                            
                                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="165.8751" y1="156.707" x2="165.5567" y2="174.7584" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_13_)" d="M178.3,316.7l9.7,2.8l12.2-33.9c0,0-37.1-10-69.3,5.5c0.9,5.6,1.4,11.2,1.3,16.9l24.3,6.3
                                L178.3,316.7z"/>
                            
                                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-7456.873" y1="156.7073" x2="-7457.1826" y2="174.7587" gradientTransform="matrix(-1 0 0 -1 -7122.5498 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_14_)" d="M321.9,316.7l-9.7,2.8L300,285.6c0,0,37.1-10,69.2,5.5c-0.9,5.6-1.3,11.2-1.3,16.9l-24.3,6.3
                                L321.9,316.7z"/>
                            <path fill="#FFFFFF" d="M190,298.7c0,8.4-6.8,15.3-15.3,15.3c-8.4,0-15.3-6.8-15.3-15.3h-4.8c-3.3,0-5.9-2.7-6-6
                                c0-0.5,0.1-0.9,0.2-1.4h-12.8c-2.9,15.9,7.6,31.1,23.5,34s31.1-7.6,34-23.5c0.3-1.7,0.5-3.5,0.5-5.2c0-1.8-0.2-3.6-0.5-5.3h-5.5
                                C189.4,293.6,190,296.2,190,298.7z"/>
                            <path fill="#662F00" d="M170.8,292.8c0,3.3-2.7,5.9-5.9,6h-5.4c0,8.4,6.8,15.3,15.3,15.3s15.3-6.8,15.3-15.3c0-2.6-0.6-5.1-1.9-7.4
                                h-17.5C170.7,291.9,170.8,292.3,170.8,292.8z"/>
                            <path fill="#FFFFFF" d="M154.7,298.7h4.8c0-2.6,0.6-5.1,1.9-7.3H149c-0.1,0.5-0.2,0.9-0.2,1.4C148.8,296.1,151.4,298.7,154.7,298.7
                                z"/>
                            <path fill="#FFFFFF" d="M170.8,292.8c0-0.5-0.1-0.9-0.2-1.4h-9.3c-1.2,2.3-1.9,4.8-1.9,7.3h5.4
                                C168.1,298.7,170.8,296.1,170.8,292.8z"/>
                            <path fill="#FFFFFF" d="M310.2,298.7c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.2-6.8,15.3-15.3h4.8c3.3,0,5.9-2.7,5.9-6
                                c0-0.5-0.1-0.9-0.2-1.4H364c3,15.9-7.5,31.2-23.3,34.2c-15.9,3-31.2-7.5-34.2-23.3c-0.7-3.6-0.7-7.2,0-10.8h5.5
                                C310.8,293.6,310.2,296.2,310.2,298.7z"/>
                            <path fill="#662F00" d="M329.4,292.8c0,3.3,2.7,5.9,6,6h5.4c0,8.4-6.8,15.3-15.3,15.3c-8.4,0-15.3-6.8-15.3-15.3
                                c0-2.6,0.6-5.1,1.9-7.3h17.5C329.4,291.9,329.4,292.3,329.4,292.8z"/>
                            <path fill="#FFFFFF" d="M345.5,298.7h-4.8c0-2.6-0.6-5.1-1.9-7.3h12.4c0.1,0.5,0.2,0.9,0.2,1.4
                                C351.5,296.1,348.8,298.7,345.5,298.7z"/>
                            <path fill="#FFFFFF" d="M329.4,292.8c0-0.5,0.1-0.9,0.2-1.4h9.3c1.2,2.3,1.9,4.8,1.9,7.3h-5.4C332,298.7,329.4,296.1,329.4,292.8z"
                                />
                            <path opacity="0.65" fill="#212121" enable-background="new    " d="M204.9,344.7c13.8-18.4,58.9-8.8,76.9-25.4
                                c6.6-6-2-15.3-12.4-23.2c-4.6-0.9-9.2-1.4-13.9-1.3h-3.1c-27.2,0.1-24.7,10.2-33.3,11.3c-11,1.4-17.9,5.5-20.8,16.8
                                c-2.3,9-17.7,12.9-24.8,28.4c-0.6,1.2-1.2,2.4-1.6,3.7c-0.4,1.3-0.7,2.6-0.9,4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.5
                                c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.5c0,0.2,0,0.2,0,0.3c0.8,19.5,41.7,22.6,83,22.6h0.2c34.8,0,69.3-2.2,79.8-14.6
                                C277.5,387.6,191.6,362.5,204.9,344.7z"/>
                            <path opacity="0.3" fill="#303030" enable-background="new    " d="M337.1,360.9c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6v-0.5V359
                                c-0.2-1.3-0.5-2.7-0.9-4c-0.4-1.3-1-2.5-1.6-3.7c-7.2-15.4-22.5-19.3-24.8-28.4c-2.9-11.3-9.8-15.5-20.8-16.8
                                c-6.8-0.9-6.7-7.2-19.5-10c10.5,7.9,19,17.1,12.4,23.2c-18,16.6-63.2,7.1-76.9,25.4c-13.3,17.8,72.5,42.9,128.9,24.4
                                c2-2.2,3.1-5.1,3.2-8C337.1,361,337.1,361,337.1,360.9z"/>
                            <path fill="#251E18" d="M245.2,371.6c0,4-3.3,0.3-7.1,1.7c-3.8,1.4-7.5,2.4-7.5-1.7c0-4,3.5-5.6,7.5-5.6S245.2,367.6,245.2,371.6z"
                                />
                            <path fill="#251E18" d="M262.7,371.6c0,4,3.3,0.3,7.1,1.7c3.8,1.4,7.5,2.4,7.5-1.7c0-4-2.9-5.6-7-5.6
                                C266.4,366,262.7,367.6,262.7,371.6z"/>
                            <path opacity="0.2" fill="#EAEAEA" enable-background="new    " d="M254.1,383.7h-0.2c-20.2,0-40.2-0.8-55.6-3.8
                                c-6.8,37.9-5.5,89.2,51.6,89.2c56.5,0,58.4-50.1,51.8-87.8C287.7,383.2,270.9,383.7,254.1,383.7z"/>
                        </svg>
  
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=     <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path  fill={backGroundColor} d="M384,258.9c-0.9-8.1-3-16.1-6.1-23.7C360.4,191.7,309.8,150,250,150c-59.6,0-110.1,41.6-127.7,85
                                c-4.3,10.6-6.7,21.4-6.7,31.6c0,6.4,0.4,12.8,1.1,19.2c0.4,2.9,0.8,5.8,1.3,8.6c1.9,10.4,5.4,20.4,10.5,29.6
                                c9,16.2,22.9,29,42.2,37.7c0.4,4.8,3.3,8.6,8,11.6c-3.2,0.7-6.5,1.6-9.8,2.6c-18.6-4.1-38.6-0.2-50.2,12.5c-8.7,9.6-8.7,9.6,0,0
                                c13-14.5-9.4,7.3-11.8,24.2s9.9,52.6,45.7,71.5c12.3,6.4,25.2,11.5,38.6,15.2l0,0c0.3,0.9,0.7,1.8,1.1,2.7
                                c3.1,6.3,23.6,8.3,25.6,7.4c3.5-1.7,6.1-4.7,7.3-8.4c3.8,0.2,7.7,0.2,11.5,0c12.4-0.7,21.5-4.9,27.1-10.7c2.9-0.2,6-0.6,9.1-1
                                c0,0-2.2,15.1,8.4,20c2,0.9,22.5-1.1,25.6-7.4c1.5-3.2,2.3-6.6,2.7-10.1c1.4-11.9,1.4-34.3,1.4-34.3c0.1-7-0.1-13.8-0.4-20.2
                                c5.4,4.4,12.1,5.7,20.2,0.8c20.3-12.1-1.5-42.4-16.2-59.2c8.6-2.2,15.3-5.3,19-9.6c2-2.2,3.1-5.1,3.2-8c0-0.1,0-0.2,0-0.3
                                c0-0.1,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.5V359c0-0.4-0.1-0.8-0.2-1.1c15.4-8.5,26.9-19.9,34.7-34
                                c5-9.2,8.6-19.2,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8l0,0c0.3-2.6,0.6-5.2,0.8-7.9c0.3-3.9,0.4-7.8,0.4-11.9
                                C384.4,264,384.3,261.5,384,258.9z"/>
                            <path fill="#442815" d="M217.9,509.2c0,0-0.3-3.4-17.9-6.5c-30.2-5.2-36.6,8.6-31.4,12c4.3,2.8,15.2-3.2,26.7-5.2
                                c-3.9,0.8-7.7,2-11.3,3.5c-6.1,2.8-5.4,6.4,0.7,6.9c6.1,0.5,13.5-6,20.3-8.7c2.2-0.9,4.6-1.6,7-2
                                C217.1,509.4,218,509.4,217.9,509.2z"/>
                            <path fill="#331D0E" d="M179.6,502.3c-1.2,0-2.3-0.1-3.5-0.1c-8.5,0-13.8,2.9-14.1,4.9c-0.3,2.4,1.8,3.8,5,4.3
                                C167.1,508.2,170.8,503.9,179.6,502.3z"/>
                            <path fill="#442815" d="M299.7,502.7c-17.6,3.1-17.9,6.5-17.9,6.5c-0.1,0.2,0.8,0.2,5.8,0c2.4,0.4,4.7,1.1,6.9,2
                                c6.8,2.8,14.2,9.2,20.3,8.7c6.1-0.5,6.8-4.1,0.7-6.9c-3.8-1.6-7.7-2.8-11.7-3.6c11.7,1.9,22.8,8.1,27.3,5.3
                                C336.4,511.3,329.9,497.5,299.7,502.7z"/>
                            <path fill="#331D0E" d="M337.6,507.1c-0.3-2-5.6-4.9-14.1-4.9c-1.1,0-2.2,0-3.3,0.1c8.7,1.6,12.4,5.9,12.6,9.2
                                C335.9,510.8,337.9,509.6,337.6,507.1z"/>
                            
                                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="165.7955" y1="156.707" x2="165.4771" y2="174.7584" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_11_)" d="M178.2,316.7l9.7,2.8l12.2-33.9c0,0-37.1-10-69.3,5.5c0.9,5.6,1.4,11.2,1.3,16.9l24.3,6.3
                                L178.2,316.7z"/>
                            
                                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-7456.7939" y1="156.7073" x2="-7457.103" y2="174.7587" gradientTransform="matrix(-1 0 0 -1 -7122.5498 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_12_)" d="M321.9,316.7l-9.7,2.8l-12.2-33.9c0,0,37.1-10,69.2,5.5c-0.9,5.6-1.3,11.2-1.3,16.9l-24.3,6.3
                                L321.9,316.7z"/>
                            <path fill="#662F00" d="M170.7,292.8c0,3.3-2.7,5.9-5.9,6h-5.4c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3
                                c0-2.6-0.6-5.1-1.9-7.4h-17.5C170.7,291.9,170.7,292.3,170.7,292.8z"/>
                            <path fill="#FFFFFF" d="M193.6,291.4H188c4.1,7.4,1.4,16.7-6,20.7s-16.7,1.4-20.7-6c-1.2-2.3-1.9-4.8-1.9-7.4h5.4
                                c3.3,0,5.9-2.7,5.9-6c0-0.5-0.1-0.9-0.2-1.4h-34.5c-2.9,15.9,7.6,31.1,23.5,34s31.1-7.6,34-23.5c0.3-1.7,0.5-3.5,0.5-5.2
                                C194,294.9,193.9,293.1,193.6,291.4z"/>
                            <path fill="#662F00" d="M329.3,292.8c0,3.3,2.7,5.9,6,6h5.4c0,8.4-6.8,15.3-15.3,15.3c-8.4,0-15.3-6.8-15.3-15.3
                                c0-2.6,0.6-5.1,1.9-7.3h17.5C329.4,291.9,329.3,292.3,329.3,292.8z"/>
                            <path fill="#FFFFFF" d="M364,291.4h-34.5c-0.1,0.5-0.2,0.9-0.2,1.4c0,3.3,2.7,5.9,6,6h5.4c0,8.4-6.8,15.3-15.3,15.3
                                c-8.4,0-15.3-6.8-15.3-15.3c0-2.6,0.6-5.1,1.9-7.3h-5.5c-3,15.9,7.5,31.2,23.3,34.2c15.9,3,31.2-7.5,34.2-23.3
                                C364.6,298.6,364.6,295,364,291.4L364,291.4z"/>
                            <path opacity="0.65" fill="#212121" enable-background="new    " d="M204.9,344.7c13.8-18.4,58.9-8.8,76.9-25.4
                                c6.6-6-2-15.3-12.4-23.2c-4.6-0.9-9.2-1.4-13.9-1.3h-3.1c-27.2,0.1-24.7,10.2-33.3,11.3c-11,1.4-17.9,5.5-20.8,16.8
                                c-2.3,9-17.7,12.9-24.8,28.4c-0.6,1.2-1.2,2.4-1.6,3.7c-0.4,1.3-0.7,2.6-0.9,4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.5
                                c0,0.2,0,0.4,0,0.6s0,0.4,0,0.5c0,0.2,0,0.2,0,0.3c0.8,19.5,41.7,22.6,83,22.6h0.2c34.8,0,69.3-2.2,79.8-14.6
                                C277.4,387.6,191.5,362.5,204.9,344.7z"/>
                            <path opacity="0.3" fill="#303030" enable-background="new    " d="M337,360.9c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6v-0.5V359
                                c-0.2-1.3-0.5-2.7-0.9-4c-0.4-1.3-1-2.5-1.6-3.7c-7.2-15.4-22.5-19.3-24.8-28.4c-2.9-11.3-9.8-15.5-20.8-16.8
                                c-6.8-0.9-6.7-7.2-19.5-10c10.5,7.9,19,17.1,12.4,23.2c-18,16.6-63.2,7.1-76.9,25.4c-13.3,17.8,72.5,42.9,128.9,24.4
                                c2-2.2,3.1-5.1,3.2-8C337,361,337,361,337,360.9z"/>
                            <path fill="#251E18" d="M245.1,371.6c0,4-3.3,0.3-7.1,1.7c-3.8,1.4-7.5,2.4-7.5-1.7c0-4,3.5-5.6,7.5-5.6S245.1,367.6,245.1,371.6z"
                                />
                            <path fill="#251E18" d="M262.7,371.6c0,4,3.3,0.3,7.1,1.7c3.8,1.4,7.5,2.4,7.5-1.7c0-4-2.9-5.6-7-5.6
                                C266.3,366,262.7,367.6,262.7,371.6z"/>
                            <path opacity="0.2" fill="#EAEAEA" enable-background="new    " d="M254,383.7h-0.2c-20.2,0-40.2-0.8-55.6-3.8
                                c-6.8,37.9-5.5,89.2,51.6,89.2c56.5,0,58.4-50.1,51.8-87.8C287.6,383.2,270.9,383.7,254,383.7z"/>
                            <path opacity="0.55" fill="#333333" enable-background="new    " d="M249.8,490.9c-7.8-0.2-15.6-0.8-23.3-1.8
                                c0.5,3.9,0.1,7.9-1.1,11.6c3.8,0.2,7.7,0.2,11.5,0c12.4-0.7,21.5-4.9,27.1-10.7C256.1,490.8,249.8,490.9,249.8,490.9z"/>
                            <path opacity="0.55" fill="#333333" d="M139.6,465.4c15.1,11.7,34.3,19.4,50.1,24.2c-1.2-12.3-1.2-32.2-1.2-32.2
                                c-0.2-18.2,1-36.5,3.6-54.5c-11.9,8.8-42.7,28.4-51.6,3.4c-5.6-15.7,10.7-25,28.6-30.4c-18.6-4.1-38.6-0.2-50.2,12.5
                                C101.6,407.5,111.2,443.2,139.6,465.4z"/>
                            <path fill="#2B2B2B" d="M189.9,491.7c-0.1-0.7-0.2-1.4-0.2-2.2c-15.7-4.8-35-12.4-50.1-24.2c-28.5-22.2-38-57.9-20.7-77.1
                                c0,0-9.4,7.3-11.8,24.2s9.9,52.6,45.7,71.5c12.3,6.4,25.2,11.5,38.6,15.2C190.7,496.8,190.1,494.3,189.9,491.7z"/>
                        </svg>
                        
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill={backGroundColor} d="M291.8,311.4l-8-2.9l-1.1-3.7c15-2.1,25.8-3.8,30.6-12.6c34.9-14.9,47.1-48.7,44.7-83.2
                                c-7.4-54.6-73.3-102-136.5-84c-101.2,32.2-98.7,140.5-34.9,167.1c3.1,7.2,14.9,10.8,30.3,12.5l-1.1,3.8l-6.9,2.5
                                c-2.2,0.1-4.4,0.5-6.5,1.1l0,0c-11.5,1.9-24.9,8-29,21.8c-3.1,17.6-6.1,35.5-11.2,53.7c-14.5-1.2-28.9,2.9-37.8,12.7l0.1-0.1
                                c-19,17.7-11.8,50.6,12.4,74.2c17.5,16.2,38.4,22.6,53.3,27.1c5.1-0.2,10.2,0.1,15.2,1.1c16.4,2.8,17.8,6,17.9,6.4h0.4
                                c1.1-1.8,2.1-3.7,3-5.6c16.5,0.9,30.6-6.1,35-15.7c2.1-0.3,4.4-0.7,6.9-1.2c0,0,0.8,12.2,7.4,22.5h0.3c0.1-0.4,1.5-3.6,17.9-6.4
                                c4.3-0.8,8.7-1.2,13-1.1c4.7-22.3,3.6-58.3,5.2-75.9c6.3,9.3,14,14.3,22.9,9.7c19.4-10-2.3-51.2-7.5-95.4
                                C325.1,316.2,301.4,312.1,291.8,311.4z"/>
                            
                                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="188.6552" y1="217.5669" x2="188.3776" y2="233.0881" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_17_)" d="M199.3,254.2l8.3,2.4l10.5-29.1c0,0-31.9-8.6-59.5,4.7c0.8,4.8,1.2,9.6,1.1,14.5l20.9,5.4
                                L199.3,254.2z"/>
                            
                                <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-7433.9063" y1="216.0443" x2="-7434.1753" y2="231.5655" gradientTransform="matrix(-1 0 0 -1 -7122.5498 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_18_)" d="M300.7,255.7l-8.3,2.4L281.9,229c0,0,31.9-8.6,59.5,4.7c-0.8,4.8-1.2,9.6-1.1,14.5l-20.9,5.4
                                L300.7,255.7z"/>
                            <path fill="#FFFFFF" d="M211.2,232.5H207c0.9,1.7,1.4,3.6,1.4,5.6c0,6.4-5.2,11.5-11.5,11.5c-6.4,0-11.5-5.2-11.5-11.5h4.1
                                c2.5,0,4.5-2,4.5-4.5c0-0.4-0.1-0.7-0.1-1.1h-26c-0.2,1.3-0.4,2.7-0.4,4c0,12.2,9.9,22.1,22.1,22.1c12.2,0,22.1-9.9,22.1-22.1
                                c0,0,0,0,0,0C211.5,235.2,211.4,233.8,211.2,232.5z"/>
                            <path fill="#FFFFFF" d="M332.3,234h-26c-0.1,0.3-0.1,0.7-0.1,1.1c0,2.5,2,4.5,4.5,4.5h4.1c0,6.4-5.2,11.5-11.5,11.5
                                c-6.4,0-11.5-5.2-11.5-11.5c0-1.9,0.5-3.9,1.4-5.6h-4.2c-0.2,1.3-0.4,2.7-0.4,4c0,12.2,9.9,22.1,22.1,22.1
                                c12.2,0,22.1-9.9,22.1-22.1c0,0,0,0,0,0C332.6,236.7,332.5,235.3,332.3,234z"/>
                            <path opacity="0.5" fill="#212121" enable-background="new    " d="M212.2,276.1c10.6-14.2,45.5-6.8,59.3-19.6
                                c5.1-4.7-1.5-11.8-9.6-17.9c-3.5-0.7-7.1-1.1-10.7-1h-2.4c-20.9,0.1-19,7.9-25.7,8.7c-8.5,1.1-13.8,4.3-16.1,13
                                c-1.8,7-13.6,10-19.2,21.9c-0.5,0.9-0.9,1.9-1.2,2.9c-0.3,1-0.5,2-0.7,3.1c0,0.1,0,0.1,0,0.2v0.4c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4
                                c0,0.1,0,0.1,0,0.2c0.6,15,32.1,17.4,64,17.4h0.2c26.9,0,53.5-1.7,61.5-11.2C268.1,309.2,201.9,289.8,212.2,276.1z"/>
                            <path opacity="0.3" fill="#303030" enable-background="new    " d="M314.1,288.6c0-0.1,0-0.3,0-0.4s0-0.3,0-0.4c0-0.1,0-0.2,0-0.3
                                v-0.2c-0.1-1-0.3-2.1-0.7-3.1c-0.3-1-0.7-2-1.2-2.9c-5.5-11.9-17.4-14.9-19.2-21.9c-2.2-8.7-7.6-11.9-16.1-13
                                c-5.2-0.7-5.2-5.6-15-7.7c8.1,6.1,14.7,13.2,9.6,17.9c-13.9,12.8-48.7,5.4-59.3,19.6c-10.3,13.7,55.9,33.1,99.4,18.8
                                c1.5-1.7,2.4-3.9,2.5-6.2C314.1,288.7,314.1,288.6,314.1,288.6z"/>
                            <path fill="#251E18" d="M243.2,296.9c0,3.1-2.6,0.2-5.5,1.3c-2.9,1.1-5.8,1.8-5.8-1.3c0-3.1,2.7-4.3,5.8-4.3
                                C240.9,292.6,243.2,293.8,243.2,296.9z"/>
                            <path fill="#251E18" d="M256.8,296.9c0,3.1,2.6,0.2,5.5,1.3c2.9,1.1,5.8,1.8,5.8-1.3c0-3.1-2.3-4.3-5.4-4.3
                                S256.8,293.8,256.8,296.9z"/>
                            <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M296.6,368.6c0.4-9.7,0.3-19.4-0.8-27.5
                                c-0.1-4-1.1-9.4-3.7-13.2c-0.8-1.7-2.1-3.1-3.6-4.2l0,0c-2-1.8-4.3-3.1-6.8-4.1l0,0c-20.5-7.4-43-7.4-63.6,0l0,0
                                c-16.7,6.3-14.2,23.4-15,39c0,21,0.9,43.4,10.4,63.7c9.3,23.5,43.5,34.7,64.5,12.5c10.7-11.8,14.8-28.1,17.1-44.5
                                c0.2-2.1,0.4-4.3,0.6-6.5c0,0.1,0.1,0.3,0.1,0.4C296.3,378.9,296.5,373.7,296.6,368.6z"/>
                            <path opacity="0.4" fill="#333333" enable-background="new    " d="M249.9,488.6c-0.9,0-7.8,0.3-18.8-2.1
                                c-0.6,5.8-2.1,11.5-4.4,16.9c2.1,0,4.1,0,6-0.1c15.2-0.8,24.9-7.5,29-15.6C257.8,488.4,253.8,488.7,249.9,488.6z"/>
                            <path opacity="0.4" fill="#333333" enable-background="new    " d="M187.3,426.1c-0.2,0.3-0.4,0.5-0.5,0.8
                                c-6.1,8.4-13.4,12.7-22,8.3c-12-6.2-8.3-24.3-2.4-47.6c-14.5-1.2-28.9,2.9-37.8,12.7l0.1-0.1c0,0-8.6,6.7-10.7,22.1
                                s9.1,48.2,41.8,65.5c11.7,6.1,24,10.9,36.7,14.3c-1-4.9-1.7-9.9-2-15C189.3,472.2,187.8,438.6,187.3,426.1z"/>
                            <path opacity="0.4" fill="#2B2B2B" enable-background="new    " d="M143.5,470.9c-26.1-20.3-34.9-53.1-19-70.7
                                c0,0-8.6,6.7-10.7,22.1c-2.1,15.5,9.1,48.2,41.8,65.5c11.7,6.1,24,10.9,36.7,14.3c-0.6-2.9-1.1-5.8-1.4-8.7
                                C176.3,489.2,157.9,482,143.5,470.9z"/>
                            <path fill="#662F00" d="M207,232.5h-13.2c0.1,0.3,0.1,0.7,0.1,1.1c0,2.5-2,4.5-4.5,4.5h-4.1c0,6.4,5.2,11.5,11.5,11.5
                                c6.4,0,11.5-5.2,11.5-11.5C208.4,236.1,207.9,234.2,207,232.5z"/>
                            <path fill="#662F00" d="M310.6,239.6c-2.5,0-4.5-2-4.5-4.5c0-0.4,0.1-0.7,0.1-1.1H293c-3.1,5.6-1,12.6,4.6,15.7
                                c5.6,3.1,12.6,1,15.7-4.6c0.9-1.7,1.4-3.6,1.4-5.6L310.6,239.6z"/>
                            <path fill="#442815" d="M314.8,502.1c-5.1-1-11.8-1.1-20.5,0.5c-17.6,3.1-17.9,6.5-17.9,6.5c-0.1,0.2,0.8,0.2,6.1,0
                                c2.4,0.4,4.8,1.1,7,2c6.8,2.8,14.2,9.2,20.3,8.7c6.1-0.5,6.8-4.1,0.7-6.9c-3.3-1.4-6.6-2.5-10.1-3.2c10.9,2.2,21,7.5,25.2,4.9
                                c1.1-0.7,1.7-1.9,1.7-3.2C327.2,508.1,323.6,503.8,314.8,502.1z"/>
                            <path fill="#331D0E" d="M332.6,507c-0.3-2-5.6-4.9-14.1-4.9c-1.2,0-2.4,0-3.7,0.1c8.8,1.7,12.4,6,12.5,9.2
                                C330.6,510.9,332.9,509.5,332.6,507z"/>
                            <path fill="#442815" d="M223.4,509.1c0,0-0.3-3.4-17.9-6.5c-8.7-1.5-15.5-1.4-20.5-0.5c-8.8,1.7-12.4,6-12.5,9.2
                                c-0.1,1.3,0.6,2.5,1.7,3.2c4.2,2.6,14.3-2.7,25.2-4.9c-3.4,0.8-6.8,1.8-10.1,3.2c-6.1,2.8-5.4,6.4,0.7,6.9c6.1,0.5,13.5-6,20.3-8.7
                                c2.3-0.9,4.6-1.6,7-2C222.6,509.2,223.5,509.3,223.4,509.1z"/>
                            <path fill="#331D0E" d="M185,502.1c-1.3,0-2.5-0.1-3.7-0.1c-8.5,0-13.9,2.9-14.1,4.9c-0.3,2.5,2,3.9,5.3,4.4
                                C172.5,508.1,176.2,503.8,185,502.1z"/>
                        </svg>
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill={backGroundColor} d="M358,209.2c-0.8-6.6-2.4-13-4.9-19.1C339,155,298.1,121.3,250,121.3s-88.8,33.5-103,68.5
                                c-3.4,8.1-5.2,16.8-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5c0.3,2.3,0.6,4.6,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9
                                c7.4,13.3,18.7,23.6,34.6,30.7c3.1,7.2,14.9,10.8,30.3,12.5l-1.1,3.8l-6.9,2.5c-4.7,0.3-9.2,1.6-13.2,4
                                c-38.6,21.8-84.5,28.5-81.1,50c4,25.6,54.6,6.5,76.5-3.1c-1.8,12.1-2.9,24.3-3.5,36.5c-4.4-4.3-9.1-7.5-15-9
                                c-17.6-4.5-37.1-1.1-48.1,11l0.1-0.1c0,0-8.6,6.7-10.7,22.1s9.1,48.2,41.8,65.5c11,5.7,22.5,10.3,34.4,13.7
                                c5.1-0.2,10.3,0.1,15.4,1.1c16.4,2.8,17.8,6,17.9,6.4h0.4c1.1-1.8,2.1-3.7,3-5.6c2.1,0,4.1,0,6-0.1c15.2-0.8,24.9-7.5,29-15.6
                                c2.1-0.3,4.4-0.7,6.9-1.2c0,0,0.8,12.2,7.4,22.5h0.3c0.1-0.4,1.5-3.6,17.9-6.4c4.4-0.8,8.8-1.2,13.3-1.1c0.9-4.7,1.5-9.5,1.8-14.3
                                c1.1-15.3,2.6-49.7,3.1-61.7c6.3,9.3,14,14.3,22.9,9.7c19.4-10-2.3-51.2-7.5-95.4c-2.8-23.7-26.5-27.8-36-28.4l-8-2.9l-1.1-3.7
                                c13.4-1.5,24.2-4.4,28.9-9.9c0.7-0.8,1.3-1.8,1.7-2.8c15.9-7.1,27.3-17.4,34.6-30.7c4.1-7.5,6.9-15.5,8.4-23.9
                                c0.4-2.1,0.8-4.3,1-6.5c0.3-2.1,0.5-4.2,0.6-6.3c0.2-3.1,0.3-6.3,0.3-9.6C358.3,213.3,358.2,211.3,358,209.2z"/>
                            
                                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="188.6076" y1="217.3866" x2="188.33" y2="232.9057" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95"  stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_15_)" d="M199.3,254.4l8.3,2.4l10.5-29.1c0,0-31.9-8.6-59.5,4.7c0.8,4.8,1.2,9.6,1.1,14.5l20.9,5.4
                                L199.3,254.4z"/>
                            
                                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="-7433.8418" y1="215.8643" x2="-7434.1108" y2="231.3834" gradientTransform="matrix(-1 0 0 -1 -7122.5498 461.88)">
                                <stop  offset="0" stop-color="#323232" stop-opacity="0"/>
                                <stop  offset="0.95" stop-color="#323232" stop-opacity="0.5"/>
                            </linearGradient>
                            <path fill="url(#SVGID_16_)" d="M300.6,255.9l-8.3,2.4l-10.5-29.1c0,0,31.9-8.6,59.5,4.7c-0.8,4.8-1.2,9.6-1.1,14.5l-20.9,5.4
                                L300.6,255.9z"/>
                            <path fill="#FFFFFF" d="M211.1,232.7h-4.2c0.9,1.7,1.4,3.6,1.4,5.6c0,6.4-5.2,11.5-11.5,11.5c-6.4,0-11.5-5.2-11.5-11.5h4.1
                                c2.5,0,4.5-2,4.5-4.5c0-0.4-0.1-0.7-0.1-1.1h-26c-0.2,1.3-0.4,2.7-0.4,4c0,12.2,9.9,22.1,22.1,22.1c12.2,0,22.1-9.9,22.1-22.1
                                c0,0,0,0,0,0C211.5,235.3,211.4,234,211.1,232.7z"/>
                            <path fill="#FFFFFF" d="M332.2,234.2h-26c-0.1,0.3-0.1,0.7-0.1,1.1c0,2.5,2,4.5,4.5,4.5h4.1c0,6.4-5.2,11.5-11.5,11.5
                                c-6.4,0-11.5-5.2-11.5-11.5c0-1.9,0.5-3.8,1.4-5.6h-4.2c-0.2,1.3-0.4,2.7-0.4,4c0,12.2,9.9,22.1,22.1,22.1
                                c12.2,0,22.1-9.9,22.1-22.1c0,0,0,0,0,0C332.6,236.8,332.4,235.5,332.2,234.2z"/>
                            <path opacity="0.5" fill="#212121" enable-background="new    " d="M212.1,276.3c10.6-14.2,45.5-6.8,59.3-19.6
                                c5.1-4.7-1.5-11.8-9.6-17.9c-3.5-0.7-7.1-1.1-10.7-1h-2.4c-20.9,0.1-19,7.9-25.7,8.7c-8.5,1.1-13.8,4.3-16.1,13
                                c-1.8,7-13.6,10-19.2,21.9c-0.5,0.9-0.9,1.9-1.2,2.9c-0.3,1-0.5,2-0.7,3.1c0,0.1,0,0.1,0,0.2v0.4c0,0.1,0,0.3,0,0.4
                                c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0.6,15,32.1,17.4,64,17.4h0.2c26.9,0,53.5-1.7,61.5-11.2C268.1,309.4,201.8,290,212.1,276.3z
                                "/>
                            <path opacity="0.3" fill="#303030" enable-background="new    " d="M314,288.8c0-0.1,0-0.3,0-0.4s0-0.3,0-0.4s0-0.2,0-0.3v-0.2
                                c-0.1-1-0.3-2.1-0.7-3.1c-0.3-1-0.7-1.9-1.2-2.9c-5.5-11.9-17.4-14.9-19.2-21.9c-2.2-8.7-7.6-11.9-16.1-13c-5.2-0.7-5.2-5.6-15-7.7
                                c8.1,6.1,14.7,13.2,9.6,17.9c-13.9,12.8-48.7,5.4-59.3,19.6c-10.3,13.7,55.9,33.1,99.4,18.8c1.5-1.7,2.4-3.9,2.5-6.2
                                C314.1,288.9,314.1,288.8,314,288.8z"/>
                            <path fill="#251E18" d="M243.2,297.1c0,3.1-2.6,0.2-5.5,1.3c-2.9,1.1-5.8,1.8-5.8-1.3c0-3.1,2.7-4.3,5.8-4.3
                                C240.8,292.7,243.2,293.9,243.2,297.1z"/>
                            <path fill="#251E18" d="M256.7,297.1c0,3.1,2.6,0.2,5.5,1.3c2.9,1.1,5.8,1.8,5.8-1.3c0-3.1-2.3-4.3-5.4-4.3
                                S256.7,293.9,256.7,297.1z"/>
                            <path fill="#331D0E" d="M332.5,507.1c-0.3-2-5.6-4.9-14.1-4.9c-1.2,0-2.4,0-3.7,0.1c8.7,1.7,12.4,6,12.5,9.2
                                C330.6,511,332.8,509.7,332.5,507.1z"/>
                            <path fill="#442815" d="M314.7,502.3c-5.1-1-11.8-1.1-20.5,0.5c-17.6,3.1-17.9,6.5-17.9,6.5c-0.1,0.2,0.8,0.2,6.1,0
                                c2.4,0.4,4.8,1.1,7,2c6.8,2.8,14.2,9.2,20.3,8.7c6.1-0.5,6.8-4.1,0.7-6.9c-3.2-1.4-6.6-2.5-10.1-3.2c10.9,2.2,21,7.5,25.2,4.9
                                c1.1-0.7,1.7-1.9,1.7-3.2C327.1,508.3,323.5,504,314.7,502.3z"/>
                            <path fill="#331D0E" d="M184.9,502.3c-1.3,0-2.5-0.1-3.7-0.1c-8.5,0-13.9,2.9-14.1,4.9c-0.3,2.5,2,3.9,5.3,4.4
                                C172.5,508.3,176.2,504,184.9,502.3z"/>
                            <path fill="#442815" d="M223.3,509.2c0,0-0.3-3.4-17.9-6.5c-8.7-1.5-15.5-1.4-20.5-0.5c-8.8,1.7-12.4,6-12.5,9.2
                                c-0.1,1.3,0.6,2.5,1.7,3.2c4.2,2.6,14.3-2.7,25.2-4.9c-3.4,0.8-6.8,1.8-10.1,3.2c-6.1,2.8-5.4,6.4,0.7,6.9s13.5-6,20.3-8.7
                                c2.3-0.9,4.6-1.6,7-2C222.5,509.4,223.4,509.4,223.3,509.2z"/>
                            <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M295.6,338.1c-0.7-8.3-6.1-15.4-13.9-18.2l0,0
                                c-20.5-7.5-43-7.5-63.6,0l0,0c-7.8,2.8-13.3,9.9-13.9,18.2c-2.7,32.8-4.1,108.7,45.8,108.7C299.7,446.7,298.3,370.9,295.6,338.1z"
                                />
                            <path opacity="0.3" fill="#333333" enable-background="new    " d="M187.1,421.5c0-8.2,0.2-15.9,0.5-23c-4.4-4.3-9.1-7.5-15-9
                                c-17.6-4.5-37.1-1.1-48.1,11l0.1-0.1c0,0-8.6,6.7-10.7,22.1s9.1,48.2,41.8,65.5c11.7,6.1,24,10.9,36.7,14.3c-1-4.9-1.7-9.9-2-15
                                C189,468.7,187.1,421.5,187.1,421.5z"/>
                            <path opacity="0.3" fill="#333333" enable-background="new    " d="M249.8,488.7c-0.9,0-7.8,0.3-18.8-2.1
                                c-0.6,5.8-2.1,11.5-4.4,16.9c2.1,0,4.1,0,6-0.1c15.2-0.8,24.9-7.5,29-15.6C257.7,488.5,253.8,488.8,249.8,488.7z"/>
                            <path opacity="0.4" fill="#2B2B2B" enable-background="new    " d="M143.5,471c-26.1-20.3-34.9-53.1-18.9-70.6
                                c0,0-8.6,6.7-10.7,22.1s9.1,48.2,41.8,65.5c11.7,6.1,24,10.9,36.7,14.3c-0.6-2.9-1.1-5.8-1.4-8.7
                                C176.3,489.3,157.8,482.2,143.5,471z"/>
                            <path fill="#662F00" d="M310.5,239.7c-2.5,0-4.5-2-4.5-4.5c0-0.4,0.1-0.7,0.1-1.1h-13.2c-3.1,5.6-1,12.6,4.6,15.7
                                c5.6,3.1,12.6,1,15.7-4.6c0.9-1.7,1.4-3.6,1.4-5.6L310.5,239.7z"/>
                            <path fill="#662F00" d="M206.9,232.7h-13.2c0.1,0.3,0.1,0.7,0.1,1.1c0,2.5-2,4.5-4.5,4.5h-4.1c0,6.4,5.2,11.5,11.5,11.5
                                c6.4,0,11.5-5.2,11.5-11.5C208.4,236.3,207.9,234.4,206.9,232.7z"/>
                        </svg>
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
