import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import {Link} from "@material-ui/core"
// import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { State } from "./adminLayout";
// import SvgIcon from '@material-ui/core/SvgIcon';
import  addBtn  from '../../../assets/images/add-button.svg'

const defaultToolbarStyles = {
  iconButton: {
  },
};

export interface Props{
    classes:any,
    action:any,
    toolTip
}

export interface  State {
    
}


class AddUserIconButton extends React.Component<Props, State>{
    handleClick = (action:any) => {
        console.log("clicked on icon!");
        window.location.href=action;
      }
    
      render() {
        const { classes, action, toolTip } = this.props;
    
        return (
          <React.Fragment>
            <Tooltip title={toolTip}>
                    <IconButton className={classes.iconButton} onClick={()=>this.handleClick(action)}>
                        {/* <AddIcon className={classes.deleteIcon} /> */}
                        {/* <SvgIcon> */}
                            <img src={addBtn} alt="" width="40"  />
                        {/* </SvgIcon> */}
                    </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
    
    }
export default withStyles(defaultToolbarStyles, { name: "AddUserButton" })(AddUserIconButton);