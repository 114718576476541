import React,{Fragment} from "react";
import styled from 'styled-components'
import { AvatarInfo,AvatarAccessory, imageProps } from "../../../enums/AvatarInfo";
import { from } from "rxjs";





export interface Props {
  imageProps:imageProps
}

export interface State {
  avatarDetailState: AvatarInfo;
  move: string
}

class StyledImageComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }
  render() {
      const {width,height, isPercentage, zindex, position, src,top,left}=this.props.imageProps;
      console.log(((position==="relative" || position === "absolute")), position, top)
      const HeaderImg  = styled.img.attrs({
                  src: src
              })`
            width:${width}${isPercentage?"%":"px"};
           ${height==0?"" : "height:"+height+"px;"} 
            z-index: ${zindex};
            ${position!==""?("position:"+position):""}
            ${((position==="relative" || position === "absolute")) ?("top:"+top+"px"):""}
            ${((position==="relative" || position === "absolute"))?("left:"+left+"px"):""}
        `;
        
    return(
      <HeaderImg   />
    )
  }
}
export default StyledImageComponent;