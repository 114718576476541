import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const officeService = {
    getAll,
    getById
};

function getAll() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/offices/getOffices`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
   
    }

function getById(id) {
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/offices/getOffices/${id}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
  
}


