import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helper';
import config from '../config';

export const teamMemberService = {
    getAll,
    getById,
    getteamMemberList,
    getTeamNewsFeed,
    getNiecesNephewsAchievements,
    getChildrenTeamMember,
    getMyNiecesNephews,
};


function getAll() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getTeamMembers`, requestOptions)
            .then(
                response => {

                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })

}
function getById(id) {

    console.log("this land here teamMember server ");
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getTeamMemberDetails/${id}`, requestOptions)
            .then(
                response => {
                    response.json()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            )
    })

}

function getteamMemberList() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getTeamMembersList`, requestOptions)
            .then(
                response => {

                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })

}

function getTeamNewsFeed(teamId: number) {
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(teamId);
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getTeamMemberNewsFeed/${teamId}`, requestOptions)
            .then(
                response => {
                    response.json()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            )
    })
}

function getNiecesNephewsAchievements(teamId: number) {
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(teamId);
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getNiecesNephewsAchievements/${teamId}`, requestOptions)
            .then(
                response => {
                    response.json()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            )
    })
}

function getChildrenTeamMember(teamId: number) {
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(teamId);
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getChildrenTeamMember/${teamId}`, requestOptions)
            .then(
                response => {
                    response.json()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            )
    })
}

function getMyNiecesNephews(teamId: number) {
    const requestOptions = { method: 'GET', headers: { Authorization: authHeader() } };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(teamId);
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/teamMembers/getMyNiecesNephews/${teamId}`, requestOptions)
            .then(
                response => {
                    response.json()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            )
    })
}