import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helper';
import config from '../config';

export const avatarService = {
    getTypes,
    getOptionsByType,
    saveUserAvatar,
    getUserAvatar
};

function getTypes() {

    const requestOptions = {
        method: 'GET',
        headers: { Authorization: authHeader(), 'Content-Type': 'application/json' },
    };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/avatar/getTypes/`, requestOptions)
            .then(
                response => {

                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })
}

function getOptionsByType(userId: number, typeId: number) {

    const requestOptions = {
        method: 'GET',
        headers: { Authorization: authHeader(), 'Content-Type': 'application/json' },
    };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/avatar/getOptionsByType/${typeId}/${userId}`, requestOptions)
            .then(
                response => {

                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })
}

function saveUserAvatar(values: any) {
    const requestOptions = {
        method: 'POST',
        headers: { authorization: authHeader() },
        body: values,
    };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/userAvatar/saveUserAvatar/`, requestOptions)
            .then(
                response => {
                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })
}

function getUserAvatar(userId: number) {
    const requestOptions = {
        method: 'GET',
        headers: { authorization: authHeader() }
    };
    return new Promise((resolve, reject) => {
        fetch(`${config.apiURL}/userAvatar/getUserAvatar/${userId}`, requestOptions)
            .then(
                response => {
                    response.json()
                        .then(data => {
                            resolve(data)
                        })
                })
            .catch(error => {
                reject(error);
            })
    })
}

