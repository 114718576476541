import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helper';
import config from '../config';
import { userRole } from '../enums';
const currentUserJson = localStorage.getItem('currentUser') || '{}';
const isTeamMemberChildJson = localStorage.getItem('isTeamMemberChild') || "null";
const currentUserSubject = new BehaviorSubject(JSON.parse(currentUserJson));
const isTeamMemberAsChild = new BehaviorSubject(JSON.parse(isTeamMemberChildJson));
// const currentUserSubject = new BehaviorSubject(currentUserJson !== null ? JSON.parse(currentUserJson) : new User());

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    asLoginAsChild,
    logoutLoginAsChild,
    isTeamMemberChild: isTeamMemberAsChild.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get isTeamMemberChildValue() { return isTeamMemberAsChild.value }
};

function login(userName, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, password })
    };

    console.log(userName, password)
    return fetch(`${config.apiURL}/auth/signin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.data));
            currentUserSubject.next(user.data);
            return user;
        });
}

function logout() {
    const requestOptions = {
        method: 'POST',
        headers: { authorization: authHeader(), 'Content-Type': 'application/json' },
    };
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    return fetch(`${config.apiURL}/auth/logout`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function asLoginAsChild(child: any) {
    const obj = {
        userId: child.id,
        roleId: userRole.child,
        userName: child.firstName+" "+child.lastName
    }
    localStorage.setItem('isTeamMemberChild', JSON.stringify(obj));
    isTeamMemberAsChild.next(obj);
    return obj;
}

function logoutLoginAsChild() {
    localStorage.removeItem('isTeamMemberChild');
    isTeamMemberAsChild.next(null);
}
