import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const followesService = {
    getMyFriends,
    followMe,
    unfollowMre
};

function getMyFriends(userId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/followers/myFriends/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function followMe(values:any)
{
    const requestOptions = { method: 'POST', headers:{ Authorization:authHeader(), 'Content-Type': 'application/json'}, body: JSON.stringify(values)  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/followers/follow`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function unfollowMre(followedId:number, followedById:number )
{
    const requestOptions = { method: 'DELETE', headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/followers/unFollow/${followedId}/${followedById}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}