import React from 'react'
import AdminLayout from './components/adminLayout'
import { Grid, InputBase, Typography } from '@material-ui/core'
import AvatarComponent from './components/avatarComponent'
import { childrenService, storyService } from '../../services'
import MUIDataTable from "mui-datatables";
import ButtonComponent from './components/buttonComponent'
import BehaviouralPlansComponent from './components/behaviouralPlansComponent'
import UserStoriesListComponent from './components/userStoriesListComponent'
import CustomToolbar from "./components/customToolbar";
import { NewStoryDialog, newStoryDialogProps } from "./components/popups/newStory/newStoryDialog"
import { GrantNewStarDialog, grantNewStarDialogProps } from './components/popups/grantNewStar/grantNewStarDialog'
import { EditStoriesDialog, EditStoriesDialogProps } from "./components/popups/newStory/editStoryDialog"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { FormatDate } from '../../common/formatDate';
import { Notifier } from '../components/notifier/notifier'
import ProgressBar from "../components/progressBar/progressBar"


export interface Props {
    className: string;
    location: any;
    history: any
}

export interface State {
    isLoading: boolean,
    dreamCircleData: any,
    newStoryDialogData: any,
    grantNewStar: any,
    editStoryDiaLogData: any,
    snackBarStatus: any,
}

export class DreamCircle extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        let stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: ''
        }

        this.state = {
            isLoading: false,
            dreamCircleData: null,
            newStoryDialogData: null,
            grantNewStar: null,
            editStoryDiaLogData: null,
            snackBarStatus: stateObject,
        }

        this.setState({ newStoryDialogData: null })
        this.setState({ grantNewStar: null })
        this.setState({ editStoryDiaLogData: null })
        // this.setStoryDialog=this.setStoryDialog.bind(this);
    }

    componentWillMount() {
        this.getData().then(dreamCircleData => {
            this.setStoryDialog(false, dreamCircleData);
        }).catch(error => {
            console.log(error.message)
        });

    }

    getData() {
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true });
            childrenService.getDreamCircleOfChild(this.props.location.data.childId, this.props.location.data.BehaviourId).then((dreamCircle: any) => {
                if (dreamCircle.success) {
                    dreamCircle.data.stories.forEach(item => {
                        item.creationOn = item.date;
                    });

                    // dreamCircle.data.behaviourPlans.forEach(item => {
                    //     item.date = FormatDate.trasformDate(new Date(item.date));
                    // })
                    // dreamCircle.data.stars.forEach(item => {
                    //     item.dateGranted = FormatDate.trasformDate(new Date(item.dateGranted));
                    // })
                    this.setState({ dreamCircleData: dreamCircle.data, isLoading: false })

                    resolve(dreamCircle.data);
                } else {
                    reject(new Error(dreamCircle.message))
                }
            })
        })

    }

    setStoryDialog = (isOpen: boolean, dreamCircleData: any) => {
        console.log(dreamCircleData)
        const stateObject = {
            openAction: isOpen,
            storyListData: dreamCircleData
        }
        this.setState({ newStoryDialogData: stateObject });
    }

    setGrantNewStarDialog = (isOpen: boolean, dreamCircleData: any) => {

        const stateObject = {
            openAction: isOpen,
            grantNewStarData: dreamCircleData
        }
        this.setState({ grantNewStar: stateObject });
    }
    setEditSotryDialog = (isOpen: boolean, storyData: any) => {

        const stateObject = {
            openAction: isOpen,
            storyData: storyData
        }
        this.setState({ editStoryDiaLogData: stateObject });
    }
    closeStoryDialog = () => {
        const stateObject = {
            openAction: false,
            storyListData: this.state.dreamCircleData.dreamCircleDetails,

        }
        // on close refresh the story
        this.refreshStories()
        this.setState({ newStoryDialogData: stateObject });

    }
    refreshStories = () => {
        this.getData();
    }
    closeGrantNewStarDialog = () => {
        this.setGrantNewStarDialog(false, this.state.dreamCircleData);

    }

    openEditStory(story: any) {
        console.log(story);
    }

    onRowClickHandelLocal = (rowData) => {
        this.setEditSotryDialog(true, rowData);
    }
    closeEditStoryDialog = () => {
        this.setEditSotryDialog(false, null);
        this.getData();

    }
    onViewNewStoriesClick = () => {
        this.setStoryDialog(true, this.state.dreamCircleData);
    }

    onViewGrantNewStarsClick = () => {
        if (this.state.dreamCircleData.dreamCircleDetails.progress < 100) {
            this.setGrantNewStarDialog(true, this.state.dreamCircleData);
        } else {
            let stateObject = {
                msg: "This Behaviour already had completed 100%!",
                status: true,
                date: new Date(),
                variant: 'warning'
            }
            this.setState({ snackBarStatus: stateObject });
            setTimeout(() => {
                stateObject.msg = "";
                stateObject.status = false;
                stateObject.variant = "";
                this.setState({ snackBarStatus: stateObject });
            }, 3000);
        }
    }

    render() {
        const { snackBarStatus } = this.state;
        const storyColumns = [
            {
                name: "id",
                label: "id",
                options: {
                    display: false
                }
            }, {
                name: "creationOn",
                label: "Date",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(value){
                            return (
                                <div>{FormatDate.trasformDate(new Date(value))}</div>               
                            );
                        }
                        
                    }
                }
            }, {
                name: "storyBy",
                label: "Story By",
                options: {
                    sort: true
                }
            },
            {
                name: "linkedToStar",
                label: "Linked to Star",
                options: {
                    sort: true,
                }
            }

        ];
        const starColumns = [
            {
                name: "id",
                label: "id",
                options: {
                    display: false
                }
            }, {
                name: "starName",
                label: "Star Name",
                options: {
                    sort: true
                }
            }, {
                name: "dateGranted",
                label: "Date Granted",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(value){
                            return (
                                <div>{FormatDate.trasformDate(new Date(value))}</div>               
                            );
                        }
                        
                    }
                }
            },
            {
                name: "numberOfStories",
                label: "Number of stories",
                options: {
                    sort: true,
                }
            }, {
                name: "progress",
                label: "Progression",
                options: {
                    sort: true,
                }
            }

        ];
        const storyOptions = {
            print: false,
            download: false,
            filter: false,
            viewColumns: false,
            // selectableRowsOnClick:true,
            selectableRowsHeader: false,
            selectableRows: 'none',
            // onRowClick: this.onRowClick,
            rowsPerPage: 5,
            caseSensitive: true,
            searchPlaceholder: "Search",
            onRowClick: (rowData) => {
                this.onRowClickHandelLocal(rowData)
            },
            customToolbar: () => {
                return (
                    <CustomToolbar text={"View New Stories"} clickHandler={() => this.onViewNewStoriesClick()} cssClasses={"blue-button"} />
                );
            }
        };
        const starOptions = {
            print: false,
            download: false,
            filter: false,
            viewColumns: false,
            // selectableRowsOnClick:true,
            selectableRowsHeader: false,
            selectableRows: 'none',
            // onRowClick: this.onRowClick,
            rowsPerPage: 5,
            caseSensitive: true,
            searchPlaceholder: "Search",
            customToolbar: () => {
                return (
                    <CustomToolbar text={"Grant New Star"} clickHandler={() => this.onViewGrantNewStarsClick()} cssClasses={"blue-button"} />
                );
            }
        };
        return (
            <AdminLayout className={this.props.className} value="child" history={this.props.history}>
                {this.state.isLoading ? 
                    <ProgressBar></ProgressBar>
                :
                    <Grid container>
                        <Grid item xs={12} className={""}>
                            <AvatarComponent childId={this.props.location.data.childId} memberDetails={this.state.dreamCircleData} isChildPageCall labelName={"Child - Dream Circle"} history={this.props.history}></AvatarComponent>
                        </Grid>
                        {/* <Grid item xs={6} > */}
                            <Grid item xs={11}  lg={6} style={{padding:"10px"}}>
                                <Grid container className={"user-detail-header"}>
                                    <Grid item style={{ width: '172px' }}>
                                        <Typography variant="h4">
                                            Details
                                        </Typography>
                                    </Grid>
                                    {/* <Grid>
                                        <ButtonComponent toolTip="Edit Details" text="Edit Details" onClick={()=>this.onClick()} cssClasses={"blue-button"}></ButtonComponent> 
                                    </Grid> */}
                                </Grid>
                                <Grid container className={"user-detail-container"}>
                                    <Grid item xs={12} className={"row"}>
                                        <InputBase
                                            defaultValue="Dream Circle"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            defaultValue={this.state.dreamCircleData.dreamCircleDetails.circleName}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={"row"}>
                                        <InputBase
                                            defaultValue="Stories"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            defaultValue={this.state.dreamCircleData.dreamCircleDetails.stories ? this.state.dreamCircleData.dreamCircleDetails.stories : 0}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={"row"}>
                                        <InputBase
                                            defaultValue="Stars"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            defaultValue={this.state.dreamCircleData.dreamCircleDetails.stars ? this.state.dreamCircleData.dreamCircleDetails.stars : 0}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={"row"}>
                                        <InputBase
                                            defaultValue="Progression"
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                            className={"label"}
                                        />
                                        <InputBase
                                            defaultValue={this.state.dreamCircleData.dreamCircleDetails.progress ? this.state.dreamCircleData.dreamCircleDetails.progress + '%' : '0%'}
                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={"remove-top-margin-dream-circle "} xs={11}  lg={6} style={{padding:"10px"}}>
                                <MUIDataTable
                                    title={"Stories"}
                                    data={this.state.dreamCircleData.stories}
                                    options={storyOptions}
                                    columns={storyColumns}
                                    className={"trasnparent-muipaper-root user-stroies-header"}
                                />
                                {(this.state.newStoryDialogData && this.state.newStoryDialogData.openAction) ?
                                    // <NewStoryDialog  onClose={this.closeStoryDialog} onRowClickHandel={()=>this.openEditStory} isBehaviourNewStories={true} openAction ={this.state.newStoryDialogData.openAction} storyListData={this.state.newStoryDialogData.storyListData}  /> : null} 
                                    <NewStoryDialog onClose={this.closeStoryDialog} isBehaviourNewStories={true} openAction={this.state.newStoryDialogData.openAction} storyListData={this.state.newStoryDialogData.storyListData} /> : null}
                            </Grid>
                        {/* </Grid> */}
                        {/* <Grid item xs={6} > */}
                            <Grid item xs={11}  lg={6} style={{padding:"10px"}}>
                                <BehaviouralPlansComponent behaviouralPlansData={this.state.dreamCircleData} behaviourId={this.props.location.data.BehaviourId}></BehaviouralPlansComponent>
                                {/* <UserStoriesListComponent storyListData={this.state.dreamCircleData} isChildrenStory={true}></UserStoriesListComponent> */}
                            </Grid>
                            <Grid item className={"user-stroies-header"} xs={11}  lg={6} style={{padding:"10px"}}>
                                <MUIDataTable
                                    title={"Stars"}
                                    data={this.state.dreamCircleData.stars}
                                    options={starOptions}
                                    columns={starColumns}
                                    className={"trasnparent-muipaper-root"}
                                />

                                {(this.state.grantNewStar && this.state.grantNewStar.openAction) ?
                                    <GrantNewStarDialog onClose={this.closeGrantNewStarDialog} refreshFuntion={this.refreshStories} openAction={this.state.grantNewStar.openAction} grantStartData={this.state.grantNewStar.grantNewStarData} /> : null}

                                {this.state.editStoryDiaLogData && this.state.editStoryDiaLogData.openAction ?
                                    <EditStoriesDialog onClose={this.closeEditStoryDialog} refreshFuntion={this.refreshStories} storyData={this.state.editStoryDiaLogData.storyData} openAction={this.state.editStoryDiaLogData.openAction} /> : null}
                            </Grid>
                        {/* </Grid> */}
                    </Grid>

                }
                {snackBarStatus.status ?
                    <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
            </AdminLayout>
        )
    };
}

