
import React,{FunctionComponent, useEffect} from 'react'
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    InputBase
 } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import {storyService} from '../../../../../services'
import {Notifier} from '../../../../components/notifier/notifier'
import { EditStoriesDialog, EditStoriesDialogProps} from "./editStoryDialog"
import { FormatDate } from "../../../../../common/formatDate";

const closeIcon= require('../../../../../assets/images/close_icon.png');



export interface  newStoryDialogProps {
    openAction:boolean;
    storyListData:any;
    isBehaviourNewStories:Boolean;
    onClose: () => void;
    // onRowClickHandel:(rowdata)=>void;
}
const initialState={
    snackBarStatus:[],
    newStoriesdata:[],
    open:false,
   
}
 
const editStoryDiaLogDataType:any=""

export const NewStoryDialog: FunctionComponent<newStoryDialogProps> = ({ 
    openAction,
    storyListData,
    isBehaviourNewStories,
    onClose,
    // onRowClickHandel
 }) =>{
 
    const [{snackBarStatus,newStoriesdata,open, } , setState] = React.useState(initialState)
    const [editStoryDiaLogData , seteditStoryDiaLogData] = React.useState(editStoryDiaLogDataType)
  

    useEffect(
        () => {
           
          storyService.getNewStoriesByUserAndBehaviour(storyListData.id, isBehaviourNewStories?storyListData.dreamCircleDetails.circleId:undefined).then((newstories:any)=>{
              if(newstories.success)
              {
                setState( {
                    newStoriesdata:newstories.data,
                    open:openAction,
                    snackBarStatus:[]
                }
                    );
              }
              else{

              }
           })
         },[]
      );
    

        newStoriesdata.forEach((item:any)=>{
            const dob=(new Date(item.creationOn));
           item.createdDate= dob;

       })

     const  closeEditStoryDialog=()=>{
        const stateObject={
            openAction:false,
            storyData: null,
            
        }
        seteditStoryDiaLogData({editStoryDiaLogData:stateObject} );
    
       }
       const  refreshStories=()=>{
      
       }

       const onRowClickHandelLocal=(rowData)=>{
        const stateObject={
            openAction:true,
            storyData: rowData,
            
        }
            seteditStoryDiaLogData({editStoryDiaLogData:stateObject} );
       }
     
        const columns = [
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },  {
                name:"createdDate",
                label:"Date",
                options:{
                    sort:true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                        <div>{FormatDate.trasformDate(value)}</div>
                        );
                    }
                }
            },{
                name:"storyBy",
                label: "Story By",
                options:{
                    sort:true
                }
            },
            {
                name:"behaviourName",
                label:"Behaviour",
                options:{
                    sort:true,
                }
            }, {
                name:"story",
                label:" Story",
                options:{
                    sort:true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div 
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "21vw",
                            textOverflow: "ellipsis",
                        }}
                      >{value}
                      </div>
                    );
                }
            },
            },
           
        ];
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            selectableRowsHeader:false, 
            selectableRows:'none',
            // onRowClick: this.onRowClick,
            rowsPerPage:5,
            caseSensitive:true,
            searchPlaceholder:"Search",
            onRowClick:  (rowData) => {
                onRowClickHandelLocal(rowData)
            },
            }
         
       return( 
           <>
        <Dialog open={open} className="popup-edit-details child-background">
        <DialogContent className={isBehaviourNewStories ?"edit-child-sotries-shells":"edit-child-tools"}>
            <Grid  container >
                   
                    <Grid item xs={12}>
                        
                     <h2>
                          <img src={closeIcon} onClick={onClose} />
                        </h2>
                        <h2 className={"popup-heading margin-top"} > {storyListData.fullName } </h2>
                        <h2 className={"popup-heading"}> {isBehaviourNewStories ?storyListData.dreamCircleDetails.circleName + " New Stories":"New Stories"} </h2>
                    </Grid>
                    <Grid item xs={10}>
                        <MUIDataTable
                        data={newStoriesdata}
                        options={options}
                        columns={columns}
                        className={"trasnparent-muipaper-root"}
                        />
                    </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Button color="primary" onClick={onSubmit}>
                    Save
                </Button> */}
            </DialogActions>
            {/* {this.state.snackBarStatus.status && 
                     <Notifier   variant={this.state.snackBarStatus.variant}  key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg}  /> } */}
      
        {editStoryDiaLogData && editStoryDiaLogData.editStoryDiaLogData.openAction   ? 
                        <EditStoriesDialog  onClose={closeEditStoryDialog}  refreshFuntion={refreshStories}   storyData={editStoryDiaLogData.editStoryDiaLogData.storyData}  openAction ={editStoryDiaLogData.editStoryDiaLogData.openAction}  /> : null}
                       
        </Dialog>
        </> 
       )
    };
