
import React,{FunctionComponent, useEffect, useState} from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    InputBase,
    TextField,
    MenuItem, 
    LinearProgress,
     
 } from '@material-ui/core';
 import Autocomplete from "@material-ui/lab/Autocomplete";
 import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {childrenService,teamMemberService,categoryService} from '../../../../../services'
import Wrapper from '../../../../baseComponents/wrapper';
import { Formik, Field, Form } from 'formik';
import {Notifier, notifierProps} from '../../../../components/notifier/notifier'
import { FormatDate } from '../../../../../common/formatDate';
import * as yup from 'yup';
import MomentUtils from "@date-io/moment";

const closeIcon= require('../../../../../assets/images/close_icon.png');



export interface  teamMemberDialogProps {
    openAction: boolean;
    childData:any;
    onClose: () => void;
}



const storyDataInitialState :any=""
const snackBarStatusInitialState:any="";
 
export const TeamMemberDialog: FunctionComponent<teamMemberDialogProps> = ({ 
    openAction,
    childData,
    onClose }) =>{
 
        const [selectedDate, setSelectedDate] = React.useState<Date>(
            new Date(),
          );
        const [storyUpdatedData, setStoryData]= useState(storyDataInitialState);
        const [snackBarStatus, setsnackBarStatus]= useState(snackBarStatusInitialState);
        const [teamMemberList, setTeamMemberList]= useState([] as any)
        const [categoryList, setcategoryList]= useState([] as any)
        const [selectedStartDate, setSelectedStartDate] = React.useState<Date >(
        new Date( ),
        );
        const [teamMemberValue, setTeamMemberValue] = React.useState({value:0,text:""});
        const [categoryValue, setCategoryValue] = React.useState({value:0,text:""});
        const [textValue, setTextValue] = React.useState("");
        const schema = yup.object().shape({
        childId:yup.number().required(),
        startDate:yup.string(),
        
        
        });

        const handleDateChange =  (date: any) => {
            if(date!==null){
              setSelectedDate(date)
            }
        //    this.state.handleDateChange(val);
          };
        
        interface selectData {
              value:number,
              text:string
          }
          //  console.log(storyData)
        
        
        
          const handleStartDateChange = date => {
           if(date){
            setSelectedStartDate(date._d);
           }
          };
        
          const onTeamMemberChange = (event, values) => {
            setTeamMemberValue(values)
          }
          const onCategoryChange = (event, values) => {
            setCategoryValue(values)
          }
          const handleTextChange = event => {
            setTextValue(event.target.value);
          };
        
        const closePopup=()=>{
            if (typeof onClose === 'function') {
              onClose();
            }
        }
        
          useEffect(
              () => {
        
                let teamMemberListData:selectData[]=[];
               
                  teamMemberService.getteamMemberList().then((teamMembers:any)=>{
                    if(teamMembers.success)
                    {
                        teamMembers.data.map(teamMember=>{
                               let data:any={
                                     value:teamMember.id,
                                     text: teamMember.fullName    
                                 }
                                 teamMemberListData.push(data);
                          }
                     )
                    }
                 })
                 setTeamMemberList( {teamMemberList:teamMemberListData});
               
              
               
                let categoryData:selectData[]=[];
        
              
                categoryService.getAll().then((categories:any)=>{
                   if(categories.success)
                   {
                    categories.data.map(category=>{
                              let data:any={
                                    value:category.id,
                                    text: category.categoryName    
                                }
                                categoryData.push(data);
                         }
                    )
                   }
                })
                setcategoryList( {categoryList:categoryData});
              
                },[]
          
            );
      interface Values {
    
        childId:number,
        categoryId:number,
        categoryName:string,
        teamMemberId:number,
        startDate:string,
        addTeam:string
        // startDate:Date,
      
      }
     
       return( 
        <Dialog open={openAction} className="popup-edit-details child-background">
        <DialogContent className={"edit-child-add-team"}>
        <Wrapper title={""} >
                    <Formik<Values>
                    initialValues={{
                        childId:childData.id,
                        categoryId:0,
                        categoryName:"",
                        teamMemberId:0,
                        startDate: "",
                        addTeam:"t"
                    
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                      console.log(setSubmitting)
                        console.log(values)
                        if(teamMemberValue && teamMemberValue.value >0)
                        {
                          values.teamMemberId=teamMemberValue.value
                        }
                        if(categoryValue && categoryValue.value >0)
                        {
                          values.categoryId=categoryValue.value
                        }else if(textValue!==""){
                          values.categoryId=0;
                          values.categoryName=textValue
                        }
                        values.startDate=FormatDate.trasformDate(selectedStartDate);
                        setTimeout(() => {
                        setSubmitting(false);
                        
                          openAction=false;
                            childrenService.addChildToTeamMember(values).then((data:any)=>{
                              if(data.success)
                              {
                                const stateObject={
                                  msg: data.message ,
                                  status:true,
                                  date:new Date(),
                                  variant:'success'
                              }
                              setsnackBarStatus(stateObject);
                                // closePopup()
                              }else{
                                console.log(data)
                                const stateObject={
                                  msg: data.message ,
                                  status:true,
                                  date:new Date(),
                                  variant:'error'
                              }
                              setsnackBarStatus(stateObject );
                              console.log(stateObject)
                              }

                            })
                        }, 2000)
                       
                    }}
                    render={({ setFieldTouched, handleChange,  submitForm, isSubmitting, values }) => (
                        <>
                        <h2>
                          <img src={closeIcon} onClick={closePopup} />
                        </h2>
                        <h2 className={"popup-heading margin-top"} > {childData.fullName } </h2>
                        <h2 className={"popup-heading"}> {"Add New Team Member"} </h2>
                      
                        
                        <Form className="edit-detail-form "  style={{marginTop:"50px"}}>
                            <Grid container spacing={3} xs={8} item className={"add-user"} >
                                <Grid item xs={12}className={"auto-complete-row"}>
                                    <InputBase
                                        defaultValue="Team member"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    />
                                      <Autocomplete
                                        id="teamMemberId"
                                        options={teamMemberList.teamMemberList}
                                        getOptionLabel={(option: selectData) => option.text}
                                        onChange={onTeamMemberChange}
                                        renderInput={params => (
                                          <TextField {...params} 
                                           placeholder="Select Team Member" 
                                           margin="normal"
                                           variant="outlined"
                                              />
                                        )}
                                      />
                                 
                                </Grid>
                                <Grid item xs={12} className={"auto-complete-row"}>
                                    <InputBase
                                        defaultValue="Type"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    /> 
                                        <Autocomplete
                                        id="categoryId"
                                        freeSolo
                                        className={"auto-complete"}
                                        options={categoryList.categoryList}
                                        getOptionLabel={(option: selectData) => option.text}
                                        onChange={onCategoryChange}
                                        renderInput={params => (
                                          <TextField {...params}   
                                          placeholder="Enter the Team Member Type" 
                                          margin="normal"
                                          variant="outlined"
                                          onChange={handleTextChange} 
                                         />
                                        )}
                                      />
                                </Grid>
                                <Grid item xs={12} className={"row"}>
                                    <InputBase
                                        defaultValue="Start Date"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    /> 
                                     <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            name="dateOfBirth"
                                            format="DD/MM/YYYY"
                                            value={selectedStartDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                      
                        </Grid>
                         
                                <Grid item className={"margin-top button-container"}  xs={12}>
                                    {isSubmitting && <LinearProgress />}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                              className={"orange-button"}
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                                >
                                                Add
                                            </Button>

                                        </Grid>
                                </Grid>
                        </Form>
                        </>
                    )}
                    />
                       {snackBarStatus.status ? 
                     <Notifier   variant={snackBarStatus.variant}  key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg}  /> : null}
                </Wrapper>
        </DialogContent>
            <DialogActions>
                {/* <Button color="primary" onClick={onSubmit}>
                    Save
                </Button> */}
            </DialogActions>
        </Dialog>
       )
    };
