import React from "react";
import "./avatarComponent.scss";
import { Box } from "@material-ui/core";
import ButtonRound from "../buttonRound/buttonRound";
import ColorPicker from "../colorPicker/colorPicker";
import { AvatarInfo, imageProps } from "../../../enums/AvatarInfo";
import { characterName, backGroundName, menuTypes, typeImage } from "../../../enums/index";
import {
  Boy,
  Dingo,
  Girl,
  Goanna,
  Kanagroo,
  Dolphin,
  Dinasaur,
  Echidna,
  Turtle,
  Platypus,
  Cory,
  Dana,
  Mykol,
  Rob,
  Russell
} from "../avatarCharater";
import AvatarAccessoriesComponent from './avatarAccessoriesComponent'
import { Common } from "../../../common";
import { Notifier } from "../notifier/notifier";
import { authenticationService, avatarService } from "../../../services";
import StyledImageComponent from './styledImageComponent'
import { Position } from "./poistions"
import { relative } from "path";
import { any } from "prop-types";
import ProgressBar from "../progressBar/progressBar";


const close = require("../../../assets/images/close_icon.svg");

export interface Props {
  avatarDetails: AvatarInfo;
  onClourPickerClick: (color: string) => void;
  showAlertMessage: (snackBarStatus: any) => void
}

export interface State {
  avatarDetailState: AvatarInfo;
  snackBarStatus: any,
  isLoading: boolean,
}

const avatarDimension = {
  width: 710,
  height: 881
}

class AvatarComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.setState({ isLoading: false });
  }

  getCurrentUser() {
    let currentUser = authenticationService.currentUserValue.user;
    let childUser = authenticationService.isTeamMemberChildValue;
    let currentUserInfo = childUser ? childUser : currentUser;
    return currentUserInfo;
  }

  getCharacter(avatarDetails: AvatarInfo) {
    const withTool = avatarDetails.tool.id > 0 ? true : false;
    const color = avatarDetails.character.colour;
    const isKid = avatarDetails.age.name === "kid" ? true : false;
    switch (avatarDetails.character.name) {
      case characterName.personMale:
        return (
          <Boy backGroundColor={color} isKid={isKid} withTool={withTool}></Boy>
        );
      case characterName.personFemale:
        return (
          <Girl
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Girl>
        );
      case characterName.Goanna:
        return (
          <Goanna
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Goanna>
        );
      case characterName.Kanagroo:
        console.log("this land here in kangaroo")
        return (
          <Kanagroo
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Kanagroo>
        );
      case characterName.Echidna:
        return (
          <Echidna
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Echidna>
        );
      case characterName.Dolphin:
        return (
          <Dolphin
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Dolphin>
        );
      case characterName.Dingo:
        return (
          <Dingo
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Dingo>
        );
      case characterName.Dinasaur:
        return (
          <Dinasaur
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Dinasaur>
        );
      case characterName.Turtle:
        return (
          <Turtle
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Turtle>
        );
      case characterName.Platypus:
        return (
          <Platypus
            backGroundColor={color}
            isKid={isKid}
            withTool={withTool}
          ></Platypus>
        );
      case characterName.Dana:
        return (
          <Dana
          ></Dana>
        );
      case characterName.Rob:
        return (
          <Rob
          ></Rob>
        );
      case characterName.Cory:
        return (
          <Cory
          ></Cory>
        );
      case characterName.MyKol:
        return (
          <Mykol
          ></Mykol>
        );
      case characterName.Russell:
        return (
          <Russell
          ></Russell>
        );
    }
  }
  getBackGround(avatarDetails: AvatarInfo) {
    if (avatarDetails.background.name) {
      const imageProp: imageProps = {
        width: 100,
        height: 0,
        zindex: 0,
        position: "absolute",
        top: 0,
        left: 0,
        isPercentage: true,
        src: require(`../../../assets/images/avatar/background/${avatarDetails.background.name}.png`)
      }
      return (
        <StyledImageComponent imageProps={imageProp}></StyledImageComponent>
      );
    } else {
      return null;
    }
  }

  getShirt(avatarDetails: AvatarInfo) {
    if (avatarDetails.shirt.name) {
      const withTool = avatarDetails.tool.id > 0 ? "w Tool " : "";
      const isKid = avatarDetails.age.name === "kid" ? "Kid " : "Teen ";
      const path = isKid + withTool;

      const imageProp: imageProps = {
        width: 500,
        height: 0,
        zindex: 1,
        position: "relative",
        top: 70,
        left: 0,
        isPercentage: false,
        src: require(`../../../assets/images/avatar/torso/${path}${avatarDetails.shirt.name}.png`)
      }
      return (
        <StyledImageComponent imageProps={imageProp}></StyledImageComponent>
      );
    } else {
      return null;
    }
  }
  // getComponent<T, U>(arg: T, condition : U): any {
  //   const menuType : menuTypes = condition
  //   console.log(menuTypes[menuType])
  //   return null
  // }
  getHair(avatarDetails: AvatarInfo) {
    if (avatarDetails.hair.name) {

      return (
        <AvatarAccessoriesComponent
          ref={'hair'}
          menu={'hair'}
          OnDrag={(deltaX, deltaY) => this.handleDrag(deltaX, deltaY, avatarDetails.hair.typeId)}
          OnResize={(style, isShiftKey, type) => this.handleResize(style, isShiftKey, type, avatarDetails.hair.typeId)}
          acessory={avatarDetails.hair}
          avatarDetails={this.props.avatarDetails}>
        </AvatarAccessoriesComponent>
      );
    } else {
      return null;
    }
  }
  getFace(avatarDetails: AvatarInfo) {
    if (avatarDetails.face.name) {
      return (
        <AvatarAccessoriesComponent
          ref={'face'}
          menu={'face'}
          OnDrag={(deltaX, deltaY) => this.handleDrag(deltaX, deltaY, avatarDetails.face.typeId)}
          OnResize={(style, isShiftKey, type) => this.handleResize(style, isShiftKey, type, avatarDetails.face.typeId)}
          acessory={avatarDetails.face} avatarDetails={this.props.avatarDetails}>
        </AvatarAccessoriesComponent>
      );
    } else {
      return null;
    }
  }
  getHat(avatarDetails: AvatarInfo) {
    if (avatarDetails.hat.name) {
      return (
        <AvatarAccessoriesComponent
          ref={'hat'}
          menu={'hat'}
          OnDrag={(deltaX, deltaY) => this.handleDrag(deltaX, deltaY, avatarDetails.hat.typeId)}
          OnResize={(style, isShiftKey, type) => this.handleResize(style, isShiftKey, type, avatarDetails.hat.typeId)}
          acessory={avatarDetails.hat} avatarDetails={this.props.avatarDetails}>
        </AvatarAccessoriesComponent>
      );
    } else {
      return null;
    }
  }

  getTools(avatarDetails: AvatarInfo) {
    if (avatarDetails.tool.name) {
      return (
        <AvatarAccessoriesComponent
          ref={'tool'}
          menu={'tool'}
          OnDrag={(deltaX, deltaY) => this.handleDrag(deltaX, deltaY, avatarDetails.tool.typeId)}
          OnResize={(style, isShiftKey, type) => this.handleResize(style, isShiftKey, type, avatarDetails.tool.typeId)}
          acessory={avatarDetails.tool} avatarDetails={this.props.avatarDetails}>
        </AvatarAccessoriesComponent>
      );
    } else {
      return null;
    }
  }

  handleDrag = (deltaX, deltaY, typeId) => {
    let avatarDetails: AvatarInfo = this.state.avatarDetailState;
    switch (typeId) {
      case menuTypes.hat:
        avatarDetails.hat.left = avatarDetails.hat.left >= 0 ? avatarDetails.hat.left + avatarDetails.hat.width <= avatarDimension.width ? avatarDetails.hat.left + deltaX : avatarDimension.width - avatarDetails.hat.width : 0;
        avatarDetails.hat.top = avatarDetails.hat.top >= 0 ? avatarDetails.hat.top + deltaY : 0;
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.face:
        avatarDetails.face.left = avatarDetails.face.left >= 0 ? avatarDetails.face.left + avatarDetails.face.width <= avatarDimension.width ? avatarDetails.face.left + deltaX : avatarDimension.width - avatarDetails.face.width : 0;
        avatarDetails.face.top = avatarDetails.face.top >= 0 ? avatarDetails.face.top + deltaY : 0;
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.hair:
        avatarDetails.hair.left = avatarDetails.hair.left >= 0 ? avatarDetails.hair.left + avatarDetails.hair.width <= avatarDimension.width ? avatarDetails.hair.left + deltaX : avatarDimension.width - avatarDetails.hair.width : 0;
        avatarDetails.hair.top = avatarDetails.hair.top >= 0 ? avatarDetails.hair.top + deltaY : 0;
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.tool:
        avatarDetails.tool.left = avatarDetails.tool.left >= 0 ? avatarDetails.tool.left + avatarDetails.tool.width <= avatarDimension.width ? avatarDetails.tool.left + deltaX : avatarDimension.width - avatarDetails.tool.width : 0;
        avatarDetails.tool.top = avatarDetails.tool.top >= 0 ? avatarDetails.tool.top + deltaY : 0;
        this.setState({ avatarDetailState: avatarDetails });
        break;
    }
  }
  handleResize = (style, isShiftKey, type, typeId) => {
    const { top, left, width, height } = style;
    let avatarDetails: AvatarInfo = this.state.avatarDetailState;
    switch (typeId) {
      case menuTypes.hat:
        avatarDetails.hat.height = Math.round(height);
        avatarDetails.hat.width = Math.round(width);
        avatarDetails.hat.top = Math.round(top);
        avatarDetails.hat.left = Math.round(left);
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.face:
        avatarDetails.face.height = Math.round(height);
        avatarDetails.face.width = Math.round(width);
        avatarDetails.face.top = Math.round(top);
        avatarDetails.face.left = Math.round(left);
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.hair:
        avatarDetails.hair.height = Math.round(height);
        avatarDetails.hair.width = Math.round(width);
        avatarDetails.hair.top = Math.round(top);
        avatarDetails.hair.left = Math.round(left);
        this.setState({ avatarDetailState: avatarDetails });
        break;

      case menuTypes.tool:
        avatarDetails.tool.height = Math.round(height);
        avatarDetails.tool.width = Math.round(width);
        avatarDetails.tool.top = Math.round(top);
        avatarDetails.tool.left = Math.round(left);
        this.setState({ avatarDetailState: avatarDetails });
        break;
    }
  }
  async componentWillMount() {
    const stateObject = {
      msg: " ",
      status: null,
      date: new Date(),
      variant: 'error'
    }
    this.setState({ avatarDetailState: this.props.avatarDetails, snackBarStatus: stateObject });
  }

  async save(avatarInfo: AvatarInfo) {
    this.setState({ isLoading: true });
    console.log("avatarInfo", JSON.stringify(avatarInfo));
    let stateObject = {
      msg: "",
      status: false,
      date: new Date(),
      variant: ''
    }
    // if(!this.checkIfAwatarCanSaved(avatarInfo)){
    //   stateObject.msg = "You can't save default Character!";
    //   stateObject.variant = "warning";
    //   this.props.showAlertMessage(stateObject);
    // }else
    if (avatarInfo && avatarInfo.character.name) {
      let currentUser = this.getCurrentUser();
      const avatarHtml = document.getElementById("character-container");
      const img: any = await Common.convertToImage(avatarHtml, typeImage.toBlob);
      if (img) {
        let avatarDetails = JSON.stringify(avatarInfo);
        const data = new FormData();
        const fileName = authenticationService.isTeamMemberChildValue ? authenticationService.isTeamMemberChildValue.userName.replace(" ", "") + Common.randomNumber(10) : authenticationService.currentUserValue.user.userName.replace(" ", "") + Common.randomNumber(10);
        data.append("avatarDetails", avatarDetails);
        data.append("file", img, fileName + ".png");
        data.append("loggedAs", currentUser.userId)
        avatarService.saveUserAvatar(data).then((data: any) => {
          this.setState({ isLoading: false });
          if (data.success) {
            stateObject.msg = data.message;
            stateObject.variant = 'success';
            let user = authenticationService.currentUserValue;
            let child = authenticationService.isTeamMemberChildValue;
            if (!child) {
              data.data.forEach(element => {
                if (element.avatarFileURL) {
                  user.avatarFile = element.avatarFileURL;
                  localStorage.setItem('currentUser', JSON.stringify(user));
                }
              });
            }
          } else {
            //ERROR
            stateObject.msg = data.message;
            stateObject.variant = 'error';
          }
          this.props.showAlertMessage(stateObject);
        }, error => {
          //ERROR
          this.setState({ isLoading: false });
          console.log("ERROR", error.message);
          stateObject.msg = "We don't have a problem on Server, Try again later!";
          stateObject.variant = 'error';
          this.props.showAlertMessage(stateObject);
        })
        console.log(fileName);
      } else {
        this.setState({ isLoading: false });
        stateObject.msg = "We have a problem when it creates avatar, try again later!";
        stateObject.variant = "error";
        this.props.showAlertMessage(stateObject);
      }
    } else {
      this.setState({ isLoading: false });
      stateObject.msg = "You need to select a Character before!";
      stateObject.variant = "warning";
      this.props.showAlertMessage(stateObject);
    }
  }

  checkIfAwatarCanSaved(avatarInfo: any) {
    let result = true;
    switch (avatarInfo.character.name) {
      case characterName.Cory:
      case characterName.Dana:
      case characterName.MyKol:
      case characterName.Rob:
      case characterName.Russell: {
        result = false
        break;
      }
      default:
        result = true;

    }
    return result;
  }

  render() {
    const { onClourPickerClick } = this.props;
    const { avatarDetailState, snackBarStatus, isLoading } = this.state;
    return (
      <div
        style={{ height: avatarDimension.height + "px", width: avatarDimension.width + "px", padding: "50px 0 0 50px" }}
      >
        {isLoading ?
          <ProgressBar></ProgressBar>
          : null}
        <Box className="avatar-on-page">
          <ColorPicker onClick={onClourPickerClick}></ColorPicker>
          <div className="avatar-on-page" id="character-container">
            <Box className="character-container">
              {this.getBackGround(avatarDetailState)}
              {this.getCharacter(avatarDetailState)}
              {this.getShirt(avatarDetailState)}
              {/* {this.getComponent<AvatarInfo, menuTypes>(
                avatarDetails,
                menuTypes.tool
              )} */}
              {this.getHair(avatarDetailState)}
              {this.getTools(avatarDetailState)}
              {this.getFace(avatarDetailState)}
              {this.getHat(avatarDetailState)}
            </Box>
          </div>
          <ButtonRound
            className={"button-close"}
            callback={() => { window.location.reload(false) }}
            icon={close}
          ></ButtonRound>
          <ButtonRound
            className={"button-save"}
            callback={() => this.save(avatarDetailState)}
            title={"Save"}
          ></ButtonRound>
        </Box>
        {/* {snackBarStatus.status ?
          <Notifier className="notification"  variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null} */}
      </div>
    );
  }
}

export default AvatarComponent;
