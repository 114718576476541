import React from 'react';
import './newsFeed.scss';
import { childrenService, teamMemberService, adminMemberService, authenticationService, reactionsService, newsFeedService } from '../../services';
import { userRole } from '../../enums/index';
import Layout from '../components/layout/layout';
import ArchievementsList from '../components/archievementstList/archievementsList';
import { Grid, Box, Typography, Link, InputBase, Fade, CircularProgress } from '@material-ui/core';
import UserList from '../components/userList/userList';
import Story from '../components/story/story';
import NewsFeedLoader from '../components/newsFeedLoader/newsFeedLoader'
import ButtonRound from '../components/buttonRound/buttonRound';
import HeaderNav from '../components/header/headerNav';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Common } from '../../common';

const avatar = Common.getAvadarDefaut();
const titleIcon = require('../../assets/images/menuSelected.svg');
const searchIcon = require('../../assets/images/search.svg');


export interface Props {
    history: any,
    className: string,
    location: any,
    match: any,
}

export interface State {
    isLoading: boolean,
    currentUser: any,
    myPeople: Array<any>,
    newsFeed: Array<any>,
    allNewsFeedSearch: Array<any>,
    myFriends: Array<any>,
    achievements: Array<any>,
    reactionsList: Array<any>,
    search: string,
    showSearch: boolean,
    child: any,
    page: number,
    totalPage: number,
    isPagination: boolean,
    avatarFile: string,
    refLeft: any,
    styleLeft: any;
    refRight: any,
    styleRight: any;
}
let currentUser;
let childUser;
export class NewsFeed extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentUser: "",
            myPeople: [],
            newsFeed: [],
            allNewsFeedSearch: [],
            myFriends: [],
            achievements: [],
            reactionsList: [],
            search: "",
            showSearch: false,
            child: null,
            totalPage: 2,
            page: 2,
            isPagination: true,
            avatarFile: "",
            refLeft: React.createRef(),
            styleLeft: { paddingLeft: "35%", paddingTop: "65px", position: "sticky", top: "115px" },
            refRight: React.createRef(),
            styleRight: { paddingLeft: "10%", paddingTop: "65px", position: "sticky", top: "115px" },

        }
    }

    async componentWillMount() {

        this.setState({ isLoading: true });
        childUser = authenticationService.isTeamMemberChildValue
        currentUser = authenticationService.currentUserValue.user;
        if (currentUser) {
            const roleIdAux = childUser ? userRole.child : currentUser.roleId;
            const userId = this.props.match.params.id ? this.props.match.params.id : childUser ? childUser.userId : currentUser.userId;
            await this.getData(roleIdAux, userId);
        } else {
            console.log("this is news feed page redirect")
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.handleScroll();
        }, 1000);

        //window.addEventListener('scroll', this.handleScroll);
        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        //window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        try {
            let margin = 100;
            let windowHeight = window.innerHeight;
            let clientLeftHeight = this.state.refLeft.current.clientHeight;
            let clientRightHeight = this.state.refRight.current.clientHeight;
            let topLeft = windowHeight > clientLeftHeight+margin ? "0" : (windowHeight - (clientLeftHeight+margin)) + "px";
            let topRight = windowHeight > clientRightHeight+margin ? "0" : (windowHeight - (clientRightHeight+margin)) + "px";
            if (topLeft !== "0") {
                let cssLeft = { ...this.state.styleLeft };
                cssLeft.top = topLeft;
                this.setState({ styleLeft: cssLeft });
            }
            if (topRight != "0") {
                let cssRight = { ...this.state.styleRight };
                cssRight.top = topRight;
                this.setState({ styleRight: cssRight });
            }
        } catch (ex) {
            console.log("ERROR", ex);
        }
    }

    getData(roleId: number, id: number) {
        reactionsService.getReactionList().then((res: any) => {
            if (res.success) {
                this.setState({ reactionsList: res.data });
            } else {
                //here we need to put anything about error
            }
        });

        switch (roleId) {
            case userRole.Admin:
                adminMemberService.getAdminNewsFeed(id).then((res: any) => {
                    if (res.success) {
                        if (res.data.avatarFile) {
                            this.setState({
                                avatarFile: res.data.avatarFile,
                                myPeople: res.data.myNiecesNephews,
                                //newsFeed: res.data.newsFeed,
                                newsFeed: res.data.newsFeed.newsFeedList,
                                //allNewsFeedSearch: res.data.newsFeed,
                                allNewsFeedSearch: res.data.newsFeed.newsFeedList,
                                achievements: res.data.niecesNephewsAchievements,
                                myFriends: res.data.myFriends,
                                totalPage: res.data.newsFeed.totalPages,
                                //avatatFile: res.data.avatarFile
                            });
                        } else {
                            this.props.history.push({ pathname: `/avatar/${id}` });
                        }

                    } else {
                        //here we need to put anything about error
                    }
                });
                break;

            case userRole.teamMember:
                teamMemberService.getTeamNewsFeed(id).then((res: any) => {
                    if (res.success) {
                        this.setState({
                            avatarFile: res.data.avatarFile,
                            myPeople: res.data.myNiecesNephews,
                            //newsFeed: res.data.newsFeed,
                            newsFeed: res.data.newsFeed.newsFeedList,
                            //allNewsFeedSearch: res.data.newsFeed,
                            allNewsFeedSearch: res.data.newsFeed.newsFeedList,
                            achievements: res.data.niecesNephewsAchievements,
                            myFriends: res.data.myFriends,
                            totalPage: res.data.newsFeed.totalPages,
                            //avatatFile: res.data.avatarFile
                        });
                    } else {
                        //here we need to put anything about error
                    }
                });
                break;

            case userRole.child:
                childrenService.getChildNewsFeed(id).then((res: any) => {
                    if (res.success) {
                        if (res.data.avatarFile) {
                            this.setState({
                                avatarFile: res.data.avatarFile,
                                myPeople: res.data.myPeople,
                                //newsFeed: res.data.newsFeed,
                                newsFeed: res.data.newsFeed.newsFeedList,
                                //allNewsFeedSearch: res.data.newsFeed,
                                allNewsFeedSearch: res.data.newsFeed.newsFeedList,
                                achievements: res.data.myAchievements,
                                myFriends: res.data.myFriends,
                                totalPage: res.data.newsFeed.totalPages,
                                //avatatFile: res.data.avatarFile
                            });
                        } else {
                            this.props.history.push({ pathname: `/avatar/${id}` });
                        }
                    } else {
                        //here we need to put anything about error
                    }
                });
                break;

            default:
                break;
        }
    }

    fetchMoreData = () => {
        if (this.state.page < this.state.totalPage) {
            setTimeout(() => {
                newsFeedService.getAllNewsFeedUpdates(this.state.page).then((data: any) => {
                    if (data.success) {
                        this.setState({
                            newsFeed: this.state.newsFeed.concat(data.data.newsFeedList),
                            allNewsFeedSearch: this.state.allNewsFeedSearch.concat(data.data.newsFeedList),
                            totalPage: data.data.totalPages,
                            page: (this.state.page + 1),
                        });
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);

                });
            }, 1500)
        } else {
            this.setState({ isPagination: false })
        }
    }

    onChange = (event, newFeedList: Array<any>) => {
        const val: string = event.target.value;
        if (val && val.trim() != '') {
            let auxList = newFeedList.filter(item => {
                return (item.childName.toLowerCase().indexOf(val.toLowerCase()) > -1 || item.message.toLowerCase().indexOf(val.toLowerCase()) > -1);
            })
            this.setState({ newsFeed: auxList, isPagination: false });
        } else {
            this.setState({ newsFeed: newFeedList, isPagination: true });
        }
    }

    handleChangeSearch = () => {
        this.setState({ showSearch: !this.state.showSearch });

    };

    render() {
        const { styleLeft, refLeft, styleRight, refRight, achievements, myFriends, myPeople, reactionsList, newsFeed, allNewsFeedSearch, showSearch, isPagination, avatarFile } = this.state;
        const { location, className, history } = this.props;
        return (
            <div className={className}>
                <Layout className={className} currentUser={currentUser} location={location} history={this.props.history}></Layout>
                <Grid className={"header-" + className}>
                    {/* <Layout className={className} currentUser={currentUser} location={location}></Layout> */}
                    <HeaderNav className="menu-header" currentUser={currentUser} location={location}></HeaderNav>
                </Grid>
                <Grid container>
                    <Grid className="box-left" item xs={4}>
                        <Grid ref={refLeft} item xs style={styleLeft} className={""}>
                            <Box className="avatar">
                                <Typography className="title">
                                    <img src={titleIcon}></img>
                                    <span>My Avatar</span>
                                </Typography>
                                <img src={avatarFile ? avatarFile : avatar} style={{ width: "70%", margin: "auto" }}></img>
                            </Box>
                            <Box style={{ height: "20px" }}></Box>
                            <Box style={{ width: "100%" }}>
                                {currentUser ?
                                    <UserList userList={myPeople} action={currentUser.roleId === userRole.child || childUser ? false : true} history={history} currentUser={currentUser} title={currentUser.roleId === userRole.child || childUser ? "Burrun Dalai Mob" : "My Nieces and Nephews"}></UserList> : null}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid className="feed-list" item xs={4}>
                        <Grid className={"row-search-" + className}>
                            <Grid xs={10} item>
                                <Fade in={showSearch}>
                                    <InputBase
                                        autoFocus={showSearch}
                                        placeholder="Search…"
                                        className={"search-text"}
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={(event) => this.onChange(event, allNewsFeedSearch)}

                                    />
                                </Fade>
                            </Grid>
                            <Grid xs={2} >
                                <ButtonRound className={"button"} icon={searchIcon} callback={this.handleChangeSearch} backgroundColor={"transparent"}></ButtonRound>
                            </Grid>
                        </Grid>
                        <Grid style={{ position: "relative" }}>
                            <InfiniteScroll
                                dataLength={this.state.newsFeed.length}
                                next={this.fetchMoreData}
                                hasMore={isPagination}
                                loader={
                                    <NewsFeedLoader></NewsFeedLoader>
                                }
                            >
                                {newsFeed.map((item, index) => (
                                    <Story key={index} feed={item} reactionsList={reactionsList} currentUser={currentUser}></Story>
                                ))}
                            </InfiniteScroll>
                        </Grid>
                    </Grid>
                    <Grid className="box-right" item xs={4}>
                        <Grid ref={refRight} item xs style={styleRight}>
                            <Box>
                                <ArchievementsList archievementsList={achievements} currentUser={currentUser}></ArchievementsList>
                            </Box>
                            <Box style={{ height: "20px" }}></Box>
                            <Box>
                                <UserList userList={myFriends} action={true} currentUser={currentUser} title="My Friends" history={history} link={true}></UserList>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default NewsFeed;