
import React from 'react'
import { Typography, Grid, Box } from '@material-ui/core';
import ButtonComponent from '../components/buttonComponent'
   
import {EditDetailsButton} from './popups/editUserDetails/editDetailsButton'
import { ConfirmationServiceProvider } from "./popups/editUserDetails/editDetailsDialogService";
import InputBase from '@material-ui/core/InputBase';
import { from } from 'rxjs';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { adminMemberService, childrenService } from '../../../services';
import { userRole } from '../../../enums/index';
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { Notifier, notifierProps } from '../../components/notifier/notifier';
import { FormatDate } from '../../../common/formatDate';
import ModalResetPassword from '../../components/modal/modalResetPassword';


interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
  }

interface switchProps extends SwitchProps {
    classes: Styles;
  }
const IOSSwitch = withStyles((theme: Theme) =>
createStyles({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.primary[400]}`,
    backgroundColor: theme.palette.primary[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
})
)(({ classes, ...props }: switchProps) => {
return (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }}
    {...props}
  />
);
});
interface behaviourData {
value: number,
label: string
}

export interface  Props {
    // className:string;
    memberDetails:any;
    isChildPageCall:boolean;
    onClick:any;
    refresh: () => void;
}
 
export interface  State {
    isLoading:boolean;
    teamMemberData:any;
    isAdmin:boolean;
    snackBarStatus:any;
    showResetPasswordModal:boolean;
}
class UserDetailsComponent  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
       
    }

    componentWillMount() {
            this.setNotification(false,"","error")
            if(this.props.memberDetails){
                this.setState({
                    isAdmin: this.props.memberDetails.roleId === userRole.Admin,
                    showResetPasswordModal: false,
                })
            }else{
                this.setState({
                    isAdmin:false, 
                    showResetPasswordModal:false
                });
            }
   
    }
    
    setNotification(status:boolean, message:string, varient:string){
        const stateObject = {
            msg: message,
            status: status,
            date: new Date(),
            variant: varient
        }
        this.setState({ snackBarStatus: stateObject });
    }

    onClick(memberDetails:any){
        console.log(memberDetails)
        console.log('this is clicked brijender');
    }
    
     handleChangeSelect = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isAdmin:!this.state.isAdmin});
        const object={
            isAdmin:this.state.isAdmin?0:1
        }
       adminMemberService.makeTeamMemberAnAdmin(object,this.props.memberDetails.id).then((data:any)=>{
            if(data.success ){
                this.setNotification(true,data.message,"success")
            }else{
                this.setNotification(true,"There is something wrong, Please try again after some time","error")
            }

       })
      };

      changePassword = () => {
          this.setState({showResetPasswordModal:true});
      }

    closeResetPasswordModal = () => {
        this.setState({ showResetPasswordModal: false });
    }

      close(){
          this.props.refresh();
      }

    render(){
        const {memberDetails} = this.props;
        const { showResetPasswordModal } = this.state;
        return(
            <>
          <Grid container>
               <Grid  container className={"user-detail-header"}>
                   <Grid className = "user-detail-container-details" style={{width:'50%'}}>
                        <Grid item style={{width:'50%'}}>
                            <Typography variant="h4">
                                    Details
                            </Typography>
                        </Grid>
                        <Grid style={{width:'50%'}}>
                            <ConfirmationServiceProvider>
                                <EditDetailsButton updated={() => this.close()} userData={this.props.memberDetails} isChildPageCall={this.props.isChildPageCall}/>
                            </ConfirmationServiceProvider>
                            <Box style={{marginTop:'10px', display:"flex"}} className="change-password">
                            <ButtonComponent text="Change password" toolTip="Change password" onClick={()=> this.changePassword()} cssClasses={"save-button"}></ButtonComponent>                
                            </Box>
                        </Grid>

                    </Grid>
                    {!this.props.isChildPageCall ?
                     <>  <Grid className = "user-detail-container-isAdmin" >
                                <Grid item style={{width:'172px'}}> 
                                    <Typography variant="body1" align-items-xs-flex-end align-content-xs-flex-end>
                                        Admin Member? 
                                    </Typography>      
                                </Grid>                 
                                <Grid> 
                                <IOSSwitch
                                // checked={true}
                                onChange={this.handleChangeSelect()}
                                value="checkedB"
                                checked={this.state.isAdmin}
                            />          
                                </Grid>
                            </Grid>
                        </> : null}
                </Grid>
            <Grid container className={"user-detail-container"}>
                <Grid item xs={12}  className={"row"}>
                    <InputBase
                        defaultValue="Unique ID"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={this.props.memberDetails.uniqueId}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12}  className={"row"}>
                    <InputBase
                        defaultValue="Name"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={this.props.memberDetails.fullName}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12}  className={"row"}>
                    <InputBase
                        defaultValue="User Name"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={this.props.memberDetails.userName}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12} className={"row"} >
                    <InputBase
                        defaultValue="Date Of Birth"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={FormatDate.trasformDate(new Date(memberDetails.dateOfBirth))}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12} className={"row"}>
                    <InputBase
                        defaultValue="Location"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={this.props.memberDetails.location}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12} className={"row"}>
                    <InputBase
                        defaultValue="Phone Number"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={this.props.memberDetails.phoneNumber}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
                <Grid item xs={12} className={"row"}>
                    <InputBase
                        defaultValue="Address"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                    />
                        <InputBase
                                        name="andress"
                                        value={this.props.memberDetails.address}
                                        inputProps={{ 'aria-label': 'naked', 'disabled': true }}
                                        multiline
                                        rowsMax="3"
                                        className={"andress"}
                                
                        />
                </Grid>
                {
                   this.props.isChildPageCall
                   ? null
                   :(
                        <Grid item xs={12} className={"row"}>
                        <InputBase
                            defaultValue="Type"
                            inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                            className={"label"}
                            />
                        <InputBase
                            defaultValue={this.props.memberDetails.jobTitle}
                            inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                            />
                        </Grid>
                   ) 
                }
                {/* {()=>{this.props.isChildPageCall ?
                 */}
                   
                    {/* :
                    ""
                }} */}
                <Grid item xs={12} className={"row"}>
                    <InputBase
                        defaultValue="Start Date"
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        className={"label"}
                        />
                    <InputBase
                        defaultValue={memberDetails.startDate ? FormatDate.trasformDate(new Date(memberDetails.startDate)) : null}
                        inputProps={{ 'aria-label': 'naked' , 'disabled':true}}
                        />
                </Grid>
            </Grid>
         </Grid>
         {showResetPasswordModal ?
                    <ModalResetPassword
                        data={[]} //change
                        backGroundColor={""}
                        titleFirst="Reset Password"
                        titleSecond={memberDetails.fullName}
                        callback={this.closeResetPasswordModal}
                        logo="backgroud-fish"
                        currentUser={memberDetails} //change
                        setOpen={showResetPasswordModal}
                        fromAdminPage={true}
                    ></ModalResetPassword> : null}
         {this.state.snackBarStatus && this.state.snackBarStatus.status ?
            <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
        </>
        )};
}
export default UserDetailsComponent;