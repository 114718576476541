import React from 'react';
import PropTypes from "prop-types";

import GetUrl from "./getUrl";
import {Box,Container, Link, Grid,Typography } from '@material-ui/core'
import AdminMenu from '../components/adminMenu'
import AdminHeaderNav from '../components/adminHeaderNav';
import {  authenticationService } from '../../../services';
import Hidden from '@material-ui/core/Hidden';
import '../../../assets/css/style.scss';
import './adminLayout.scss';
import Button from '@material-ui/core/Button';
import ButtonRound from '../../components/buttonRound/buttonRound';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const backIcon = require('../../../assets/images/back.png');
const logo  =require( '../../../assets/images/logo.png');
const bird = require('../../../assets/images/bird.png');
const fish = require('../../../assets/images/fish.png');
const platypus = require('../../../assets/images/platypus.png');

export interface Props {
    className:string, 
    value:string
    history:any
   
 }
  
 export interface State {
    anchorEl:any
 }
 
 class AdminLayout  extends React.Component<Props, State> {
    constructor(props){
        super(props);
        // this.state={showBack:location.hash.split('?')[0]!=="#/"};
        // this.hook = hashHistory.listenBefore(loc=>
        //     this.setState({showBack:loc.pathname!=="/"})
        // );
        this.setState(
            {anchorEl:undefined}
        )
    }
    comeBack() {
        setTimeout(() => {
            this.props.history.goBack();
        }, 500);

        console.log(window.location.pathname)
    }
    handleClick (event:any){
        console.log(event);
        this.setState({anchorEl:event.currentTarget});
    };

    componentWillMount(){
       const currentUser = authenticationService.currentUserValue.user;
        if (!currentUser){
            const { from } =  { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
    }

    render() { 
       const pageName=this.props.value ;
        return (
       <div className={this.props.className}>
            <Hidden only={'sm'}>
                    <Grid container style={{height:'100%'}} >
                    <Grid  className="admin-left-panel-background" item style={{width:'421px'}}>
                        <Grid className="admin-logo">
                            <Grid style={{width:"100%"}}>
                                <ButtonRound callback={() => this.comeBack()} className={"back-button"} backgroundColor={"transparent"} icon={backIcon} title="Back"></ButtonRound>
                               
                            </Grid>
                            <Grid>
                                <img src={logo} alt="Logo" />
                            </Grid>
                        </Grid>
                            <Grid className="admin-menu">
                                <AdminMenu pageName={pageName}></AdminMenu>
                            </Grid>
                            <div  className={"admin-image " + (this.props.value==="child" ? 'fish' : this.props.value==="team"? 'platypus' : 'bird')}>
                                    <img src={this.props.value==="child" ? fish : this.props.value==="team"? platypus : bird} alt="Logo" />
                            </div>
                    </Grid>
                    <Grid  container className="admin-layout-blank-right-panel" >
                        <Grid style={{width:'100%'}}>
                            <AdminHeaderNav className='admin-top-menu' ></AdminHeaderNav>
                        </Grid>
                        <Grid style={{width:"100%"}}>
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Grid>
        </Hidden>
        <Hidden only={['lg', 'xl', "md", ]}>
                <Grid container style={{height:'100%'}} >
                    <Grid  item className="background-white-top-tablet" style={{width:'230px'}}>
                        <Grid className="admin-logo">
                            <Grid style={{width:"100%"}}>
                                <ButtonRound callback={() => this.comeBack()} className={"back-button"} backgroundColor={"transparent"} icon={backIcon} title="Back"></ButtonRound>
                                    <Button color="secondary" aria-controls="simple-menu" aria-haspopup="true"    startIcon={<MenuIcon />} onClick={event=>this.handleClick(event)}    />
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state && this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state&& this.state.anchorEl)}
                                        // onClose={this.handleClose()}
                                    >
                                         <AdminMenu pageName={pageName}></AdminMenu>
                                    </Menu>
                            </Grid>
                            {/* <Grid>
                                <img src={logo} style={{width:"125px"}} alt="Logo" />
                            </Grid> */}
                        </Grid>
                        <Grid className="admin-menu">
                                    <ButtonRound callback={() => this.comeBack()} className={"back-button"} backgroundColor={"transparent"} icon={backIcon} title="Back"></ButtonRound>
                                    <Button color="secondary" aria-controls="simple-menu" aria-haspopup="true"    startIcon={<MenuIcon />} onClick={event=>this.handleClick(event)}    />
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state && this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state&& this.state.anchorEl)}
                                        // onClose={this.handleClose()}
                                    >
                                         <AdminMenu pageName={pageName}></AdminMenu>
                                    </Menu>       
               

                         </Grid>
                    </Grid>
                    <Grid xs={8}>
                            <AdminHeaderNav className='admin-top-menu-small-screen' ></AdminHeaderNav>
                    </Grid>
                    <Grid  container className="admin-layout-blank-right-panel" >
                       
                        <Grid>
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Grid>
        </Hidden>
      </div>
       );
    }
  }
   
  export default AdminLayout;