import React, {useRef} from 'react';
import { Button, MenuItem, MenuList, ClickAwayListener, Paper, Grow, Popper, Icon } from '@material-ui/core'
import './buttonReaction.scss';
import  {Notifier,notifierProps} from "../notifier/notifier"
import { reactionsService, authenticationService } from '../../../services';


export interface Props {
    title: string;
    reactionsList: Array<any>;
    feed: any;
    parentCallback: any
}

export interface State {
    setOpen: boolean;
    messageBar:any;
}

let currentUser = authenticationService.currentUserValue.user;
let childUser = authenticationService.isTeamMemberChildValue;

class ButtonReaction extends React.Component<Props, State>{

    constructor(props) {
        super(props);
        const stateObject={
            msg: "",
            status:false,
            date:new Date(),
            variant:''
        }
    this.state={messageBar:stateObject, setOpen:false};
    }

    componentWillMount() {
        this.setState({ setOpen: false });

    }

    handleClose = () => {
        this.setState({ setOpen: false });
    };

    handleOpen = () => {
        this.setState({ setOpen: true });
    };

    sendData = (obj:any) => {
        this.props.parentCallback(obj);
   }

    sendReaction(typeId:number, type:string, reactionToId:number, reactionId:number){
        const reationById = childUser ? childUser.userId : currentUser.userId;
        this.handleClose();
        reactionsService.addReaction(type, typeId, reationById, reactionToId, reactionId).then((data:any) => {
            const stateObject={
                msg: data.message ,
                status:false,
                variant:''
            }
            if(data.success){
                if(data.data.reactionStory){
                    stateObject.status = true;
                    stateObject.variant ='success'
                    this.sendData(data.data.reactionStory);
                }else{
                    stateObject.status = true;
                    stateObject.variant ='warning'
                }
            }else{
                stateObject.status = true;
                stateObject.variant ='error'
            }
            this.setState({messageBar:stateObject});
            setTimeout(() => {
                const stateObject={
                    msg: "",
                    status:false,
                    date:new Date(),
                    variant:''
                }
                this.setState({messageBar:stateObject});
            }, 2000);         
        });
    }

    render() {
        const {reactionsList, feed} = this.props;
        return (
            <div className="button-reaction" style={{float: "right"}}>
                <Button className="btn"
                    startIcon={<Icon style={{ fontSize: 30 }} className={this.state.setOpen ? "icon open" : "icon no-open"} />}
                    onClick={this.handleOpen}>
                    {this.props.title}
                </Button>
                <Popper open={this.state.setOpen} role={undefined} anchorEl={null} transition disablePortal style={{position: "absolute", top:"none", left: "none", right: "0", zIndex:1, width:"250px",}}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px"  }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList id="menu-list-grow">
                                        {reactionsList.map((item, index) => (
                                            <MenuItem key={index} onClick={()=>this.sendReaction(feed.id, feed.type, feed.childId, item.id)} style={{display: "block"}}><img src={require('../../../assets/images/emotions/'+item.id+'.svg')} style={{width:"15%", float: "right"}}></img><span style={{verticalAlign: "middle"}}>{item.reactionsName}</span></MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                {this.state.messageBar.status ? 
                     <Notifier
                        variant={this.state.messageBar.variant}  
                        key={this.state.messageBar.date} 
                        status={this.state.messageBar.status} 
                        message={this.state.messageBar.msg}  /> : null}
            </div>
        );
    }

}

export default ButtonReaction;