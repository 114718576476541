import * as React from 'react';
import { authenticationService } from '../../../services';
import {Button,ClickAwayListener , Typography, Grid} from '@material-ui/core'
import { Router, Route, Link} from 'react-router-dom'
import * as ReactDOM from 'react-dom';
import { history, Role } from '../../../helper';
import { Common } from '../../../common';
const avatar  = Common.getAvadarDefaut();
const logOutImage=require('../../../assets/images/logout-foot-print.png');


export interface Props {
    className:string
}
export interface State {
    currentUser: any,
    isAdmin:boolean,
    avatarFile: any
}

let userInfo: any = null
class AdminHeaderNav extends React.Component<Props, State>{
    constructor(props){
       super(props);
       
       this.state = {
            currentUser: null,
            isAdmin: false, 
            avatarFile: null
        };
    }
    componentDidMount() {
        const currentUser=authenticationService.currentUserValue.user;
        const avatarFile = authenticationService.currentUserValue.avatarFile;
        userInfo = authenticationService.currentUserValue.userData;
        this.setState({
            currentUser: currentUser,
            isAdmin: currentUser && currentUser.role === Role.Admin,
            avatarFile: avatarFile
        })
      
    }


    logout=()=> {
        authenticationService.logout();
        history.push('/login');
    }

    render() { 
        const {currentUser, avatarFile}=this.state;
        return ( 
            <Router history={history}>
                <Grid className = {this.props.className} alignItems="center">
                    <Grid  xs={1}  alignItems="center" className="user-name-container" >
                        <Typography className="user-name">
                            {userInfo && userInfo.fullName}
                        </Typography>
                    </Grid>
                    <Grid  xs={1} className="avatar-logo">
                            <img src={avatarFile ? avatarFile : avatar} style={{ width: "100%", margin: "auto" }}></img>
                    </Grid>
                    {/* <ClickAwayListener onClickAway={this.logout}> */}
                            <Grid  xs={1}  justify="flex-end">
                                <Button  onClick={this.logout} className="logout">
                                    Log Out
                                </Button>
                            </Grid>
                            <Grid  xs={1} className="logout-logo">
                                <img src={logOutImage} style={{ width: "100%", margin: "auto" }}></img>
                            </Grid>
                    {/* </ClickAwayListener> */}
                </Grid>
                {/* <a onClick={this.logout} className="nav-item nav-link"></a> */}
            </Router>
         );
    }
}
 
export default AdminHeaderNav;