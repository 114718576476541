import * as React from 'react';
import './storyTable.scss';
import { FormatDate } from '../../../common/formatDate';
import MUIDataTable from "mui-datatables";
import { InputBase } from '@material-ui/core';
import  ButtonRound  from "../buttonRound/buttonRound";
import CustomToolbar from '../../adminPages/components/customToolbar';
import ModalStory from '../modal/modalStory';
import { storyService } from '../../../services';
import { StoryInformation } from '../formStory';

const logo = "backgroud-fish";
const formatDate: FormatDate = new FormatDate();

export interface Story {
    id: number;
    story: string;
    childId: number;
    teamMemberId: number;
    approvedById: number;
    behaviourId: number;
    approvedDate: Date;
    isPublished: boolean;
    creationOn: Date;
    deleteOn: Date;
    updatedOn: Date;
    behaviour: Array<any>;
    formatDate: string;
}

export interface Props {
    data: Array<Story>,
    buttonAdd?: boolean,
    currentUser: any,
    childInfo: any,
    titleCol: Array<{name:string, label:string}>,
    isUsedForStar?:boolean,
}

export interface State {
    data: Array<Story>,
    modal: boolean;
    storyInfo: any;
    titleSecond: string;
    
}

class StoryTable extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {data: this.props.data, modal: false, storyInfo: null, titleSecond: ""};

    }

    componentWillMount(){
        console.log("this.state.data",this.state.data);
        
    }

    openModal = () => {
        this.setState({titleSecond: "New Story"});
        this.setState({modal: true});
    }

    closeModal = (update?:boolean) => {
        if(update === true){
            this.getData(this.props.childInfo.id);
        }
        this.setState({modal: false});
        this.setState({storyInfo:null});
    }

    getStory = async(story:any) => {
        // only open story if pop is story
        if(!this.props.isUsedForStar){
            await storyService.getById(story[0]).then((data:any) => {
                if(data.success){
                    this.setState({storyInfo: data.data});
                    this.setState({titleSecond: "Edit Story"});
                    this.setState({modal: true});
                }else{
                    //ERROR
                }
            },error =>{
                //ERROR
                console.log(error.message);
            })
        }
    }

    getData = async (childId:number) => {
        storyService.getUserStoriesIWrote(childId).then((data:any) => {
            if(data.success){
                this.setState({data: data.data});
            }else{
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
            
        })
    }

    createcolumns(columns:Array<{name:string, label:string}>){
        let newColumns:Array<any> = [];
        newColumns.push({
            name:"id",
            label:"ID",
            options: {
                filter: true,
                sort: true,
               }
        });
        columns.forEach(element =>{
            const object = {
                name:element.name,
                label:element.label,
                options:{
                    sort:true,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div
                            style={{width: "100%"}}
                            className={element.name === "name" ? "cell-edit-story" : ""}
                        >
                            {value}
                        </div>
                        );
                    }
                },
            }
            newColumns.push(object);
        })
        return newColumns;
    }

    render() {
        const { data, modal, storyInfo, titleSecond } = this.state;
        const { currentUser, childInfo, titleCol } = this.props;

        console.log(this.state, this.props)
        const columns = this.createcolumns(titleCol);
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            selectableRowsHeader:false,
            selectableRows:'none',
            rowsPerPage:5,
            caseSensitive:true,
            searchPlaceholder:"Search",
            onRowClick: (rowData) => {
                    this.getStory(rowData);
            },
            // customToolbar: () => {
            //     if(this.props.buttonAdd){
            //     return (
            //         <CustomToolbar text={"Add New Story"} cssClasses={"blue-button"} clickHandler={this.openModal}/>
            //     );
            //   }
            // else{
            //     return (
            //         null
            //     )
            // }
            // }
          };
        return (
            <div className={"story-table"}>
                <MUIDataTable
                        data={data}
                        options={options}
                        columns={columns}
                        className={"trasnparent-muipaper-root"}
                        />
                {modal ? <ModalStory 
                    setOpen={modal} 
                    callback={this.closeModal} 
                    className={storyInfo ? "new-story" : "editStory"}
                    currentUser={currentUser}
                    childInfo={childInfo}
                    titleFirst={childInfo.fullName?childInfo.fullName: childInfo.firstName +" "+childInfo.lastName}
                    titleSecond={titleSecond}
                    logo={logo}
                    data={storyInfo}
                     ></ModalStory> : null}

            </div >
        );
    }

}

export default StoryTable;