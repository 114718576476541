import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';
import {AvatarInfo} from '../../../enums/AvatarInfo'
import {characterName ,menuTypes } from '../../../enums';



export class Position{
 
 private  static hair = {
      teen:[
        { name: "default", width:191,height:100, top:275, left:259},
        { name: "Spiky Hair", width:277,height:229, top:167, left:220},
        { name: "High Top", width:207,height:215, top:130, left:259},
        { name: "Faux Hawk", width:235,height:100, top:269, left:250},
        { name: "Long Side Part", width:205,height:219, top:264, left:253},
        { name: "Short and Spiky", width:222,height:93, top:274, left:246},
        { name: "Mohawk", width:39,height:127, top:229, left:339},
        { name: "Short & Curly", width:246,height:124, top:270, left:234},
       
      ],
      kid:[
        { name: "default", width:234,height:100, top:315, left:237},
        { name: "Spiky Hair", width:277,height:229, top:219, left:218},
        { name: "High Top", width:292,height:225, top:168, left:219},
        { name: "Faux Hawk", width:250,height:100, top:300, left:225},
        { name: "Long Side Part", width:250,height:219, top:293, left:225},
        { name: "Short and Spiky", width:241,height:93, top:299, left:235},
        { name: "Mohawk", width:39,height:127, top:268, left:339},
        { name: "Short & Curly", width:308,height:150, top:304, left:200},
      ]
    }
  
private  static hat ={
            teen:[
              { name: "default", width:200,height:100, top:270, left:260},
              { name: "top", width:202,height:140, top:184, left:245},
              { name: "Antenna", width:225,height:243, top:124, left:254},
              { name: "Hessian", width:224,height:242, top:273, left:248},
              { name: "Conical", width:366,height:120, top:224, left:165},
              { name: "Kofia", width:202,height:82, top:275, left:260},
              { name: "chef", width:255,height:151, top:200, left:220},
              { name: "chef", width:254,height:132, top:253, left:257},
              { name: "Dancer", width:150,height:119, top:244, left:328},
              { name: "Survivor", width:211,height:113, top:257, left:252},
              { name: "Chicken", width:252,height:156, top:210, left:237},
              // { name: "Chicken", width:189,height:134, top:223, left:264},
              { name: "Monster Onesie", width:210,height:119, top:251, left:253},
              { name: "Scuba ", width:165,height:35, top:305, left:275},
              { name: "Amelia", width:256,height:184, top:260, left:227},
              { name: "BBoy", width:214,height:113, top:255, left:273},
              { name: "dapper", width:202,height:140, top:184, left:245},
              { name: "Superhero", width:226,height:226, top:273, left:248},
              { name: "Nurse", width:200,height:78, top:278, left:254},
              { name: "Pilot", width:233,height:150, top:231, left:243},
              { name: "Police",width:200,height:100, top:262, left:256},
              { name: "Surfer",width:230,height:145, top:230, left:234},
              { name: "Warrior",width:229,height:118, top:255, left:264},
            ],
            kid:[
              { name: "default", width:250,height:100, top:325, left:225},
              { name: "top", width:202,height:140, top:217, left:242},
              { name: "Antenna", width:225,height:243, top:141, left:254},
              { name: "Hessian", width:240,height:250, top:344, left:240},
              { name: "Conical", width:366,height:120, top:259, left:169},
              { name: "Kofia", width:218,height:84, top:298, left:254},
              { name: "chef", width:255,height:151, top:219, left:233},
              { name: "chef", width:254,height:132, top:283, left:254},
              { name: "Dancer", width:150,height:119, top:293, left:341},
              { name: "Survivor", width:252,height:156, top:273, left:230},
              { name: "Chicken", width:245,height:155, top:251, left:236},
              { name: "Monster Onesie", width:260,height:148, top:268, left:230},
              { name: "Scuba ", width:170,height:35, top:330, left:271},
              { name: "Amelia", width:263,height:188, top:296, left:228},
              { name: "BBoy", width:299,height:127, top:296, left:228},
              { name: "dapper", width:202,height:140, top:184, left:245},
              { name: "Superhero", width:258,height:264, top:301, left:228},
              { name: "Nurse", width:239,height:93, top:302, left:236},
              { name: "Pilot", width:274,height:177, top:246, left:222},
              { name: "Police", width:270,height:145, top:270, left:220},
              { name: "Surfer",width:288,height:181, top:253, left:206},
              { name: "Warrior",width:290,height:150, top:275, left:237},
            ]
      }
  private  static face ={
              teen:
                {
                
                personFemale:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:166,height:52, top:387, left:273},
                  { name: "chef", width:44,height:36, top:440, left:398},
                  { name: "Aboriginal", width:154,height:58, top:434, left:282},
                  { name: "Survivor",width:216,height:145, top:340, left:248},
                  { name: "Dancer", width:222,height:63, top:382, left:247},
                  { name: "Chicken", width:176,height:17, top:440, left:267},
                  { name: "Monster Onesie", width:74,height:40, top:429, left:322},
                  { name: "Scuba", width:236,height:202, top:272, left:237},
                  { name: "Amelia", width:176,height:60, top:387, left:268},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:126,height:87, top:412, left:307},
                  { name: "Superhero", width:55,height:44, top:350, left:331},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                  { name: "Nurse", width:240,height:95, top:400, left:236},
                  { name: "Police", width:159,height:50, top:377, left:277},
                  { name: "Pilot", width:159,height:50, top:377, left:277},
                ],
                personMale:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:166,height:52, top:380, left:273},
                  { name: "chef", width:44,height:36, top:425, left:407},
                  { name: "Aboriginal", width:167,height:60, top:430, left:272},
                  { name: "Survivor", width:216,height:145, top:340, left:248},
                  { name: "Dancer", width:222,height:63, top:368, left:245},
                  { name: "Chicken", width:176,height:17, top:440, left:267},
                  { name: "Monster Onesie", width:87,height:44, top:409, left:312},
                  { name: "Scuba", width:236,height:202, top:260, left:233},
                  { name: "Amelia", width:159,height:50, top:377, left:277},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:126,height:87, top:381, left:308},
                  { name: "Superhero", width:55,height:44, top:350, left:331},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                  { name: "Nurse", width:220,height:95, top:400, left:245},
                  { name: "Police", width:159,height:50, top:377, left:277},
                  { name: "Pilot", width:159,height:50, top:377, left:277},
                ],
                Goanna:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Kanagroo:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Echidna:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Dolphin:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Dingo:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Turtle:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Dinasaur:[
                  { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:221,height:64, top:332, left:244},
                  { name: "chef", width:44,height:36, top:410, left:374},
                  { name: "Aboriginal", width:154,height:63, top:431, left:281},
                  { name: "Survivor", width:274,height:132, top:307, left:222},
                  { name: "Dancer", width:278,height:95, top:313, left:220},
                  { name: "Chicken", width:216,height:16, top:416, left:248},
                  { name: "Monster Onesie", width:123,height:35, top:449, left:294},
                  { name: "Scuba", width:236,height:202, top:240, left:233},
                  { name: "Amelia", width:237,height:72, top:376, left:235},
                  { name: "BBoy", width:80,height:33, top:481, left:316},
                  { name: "Dapper", width:124,height:87, top:377, left:257},
                  { name: "Superhero", width:55,height:44, top:316, left:332},
                  { name: "Warrior", width:80,height:33, top:481, left:316},
                  { name: "Surfer", width:40,height:20, top:390, left:335},
                ],
                Platypus:[
                  { name: "default", width:240,height:95, top:323, left:236},
                ]
              }
                         
              ,
              kid:
                {
                  personFemale:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  { name: "Round Sunnies", width:226,height:67, top:443, left:242},
                  { name: "chef", width:44,height:36, top:511, left:404},
                  { name: "Aboriginal", width:162,height:56, top:504, left:279},
                  { name: "Survivor", width:274,height:180, top:380, left:220},
                  { name: "Dancer", width:278,height:95, top:432, left:220},
                  { name: "Chicken", width:198,height:17, top:518, left:258},
                  { name: "Monster Onesie", width:87,height:50, top:495, left:314},
                  { name: "Scuba", width:236,height:202, top:333, left:237},
                  { name: "Amelia", width:214,height:73, top:442, left:249},
                  { name: "BBoy", width:118,height:45, top:555, left:297},
                  { name: "Dapper", width:126,height:87, top:478, left:306},
                  { name: "Superhero", width:55,height:44, top:398, left:335},
                  { name: "chef", width:44,height:36, top:508, left:398},
                  { name: "Nurse", width:240,height:95, top:470, left:245},
                  { name: "Pilot", width:210,height:70, top:448, left:255},
                  { name: "Police", width:210,height:70, top:448, left:255},
                  { name: "Surfer", width:40,height:20, top:470, left:335},
                  { name: "Warrior", width:120,height:33, top:551, left:300},
                  ],
                  personMale:[
                    { name: "default", width:240,height:95, top:323, left:236},
                    { name: "Round Sunnies", width:226,height:67, top:443, left:242},
                    { name: "Aboriginal", width:162,height:56, top:504, left:279},
                    { name: "Aboriginal", width:154,height:63, top:431, left:281},
                    { name: "Survivor",width:274,height:180, top:380, left:220},
                    { name: "Dancer", width:278,height:95, top:432, left:220},
                    { name: "Chicken", width:198,height:17, top:518, left:258},
                    { name: "Monster Onesie", width:87,height:50, top:495, left:314},
                    { name: "Scuba", width:236,height:202, top:333, left:237},
                    { name: "Amelia", width:214,height:73, top:442, left:249},
                    { name: "BBoy", width:118,height:45, top:555, left:297},
                    { name: "Dapper", width:126,height:87, top:478, left:306},
                    { name: "Superhero", width:55,height:44, top:398, left:335},
                    { name: "chef", width:44,height:36, top:508, left:398},
                    { name: "Nurse", width:240,height:95, top:470, left:245},
                    { name: "Pilot", width:210,height:70, top:448, left:255},
                    { name: "Police", width:210,height:70, top:448, left:255},
                    { name: "Surfer", width:40,height:20, top:470, left:335},
                    { name: "Warrior", width:120,height:33, top:551, left:300},
                  ],
                  Goanna:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Kanagroo:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Echidna:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Dolphin:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Dingo:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Turtle:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ],
                  Dinasaur:[
                    { name: "default", width:240,height:95, top:323, left:236},
                    { name: "Round Sunnies", width:233,height:69, top:382, left:242},
                    { name: "chef", width:44,height:36, top:476, left:379},
                    { name: "Aboriginal", width:196,height:79, top:484, left:252},
                    { name: "Survivor", width:317,height:175, top:336, left:196},
                    { name: "Dancer", width:297,height:95, top:366, left:206},
                    { name: "Chicken", width:257,height:16, top:476, left:226},
                    { name: "Monster Onesie", width:143,height:48, top:511, left:284},
                    { name: "Scuba", width:236,height:202, top:284, left:234},
                    { name: "Amelia", width:242,height:85, top:380, left:239},
                    { name: "BBoy ", width:122,height:41, top:553, left:293},
                    { name: "Dapper", width:124,height:52, top:552, left:297},
                    { name: "Superhero", width:55,height:44, top:368, left:326},
                  ],
                  Platypus:[
                    { name: "default", width:240,height:95, top:323, left:236},
                  ]
                }
             
          }
  private  static tool= {
              teen:[
                { name: "default", width:100,height:100, top:396, left:195},
                { name: "Superhero", width:460,height:200, top:500, left:140},
                { name: "Dapper", width:42,height:215, top:543, left:207},
                { name: "Chef", width:75,height:205, top:446, left:180},
                { name: "Dancer", width:97,height:235, top:480, left:157},
                { name: "Aboriginal", width:75,height:138, top:457, left:210},
                { name: "Dancer", width:75,height:172, top:474, left:156},
                { name: "Survivor", width:121,height:81, top:499, left:139},
                { name: "Chicken Little", width:75,height:264, top:417, left:181},
                { name: "Monster", width:119,height:102, top:506, left:158},
                { name: "Scuba", width:218,height:255, top:374, left:86},
                { name: "Amelia", width:120,height:59, top:531, left:134},
                { name: "BBoy", width:131,height:106, top:524, left:155},
                { name: "Nurse", width:129,height:99, top:550, left:156},
                { name: "Pilot", width:143,height:118, top:537, left:157},
                { name: "Police", width:50,height:134, top:463, left:211},
                { name: "Warrior", width:55,height:501, top:210, left:188},
                { name: "Surfer", width:108,height:361, top:347, left:143},
              ],
              kid:[
                { name: "default", width:75,height:60, top:460, left:205},
                { name: "Superhero", width:429,height:448, top:249, left:145},
                { name: "Dapper", width:52,height:161, top:525, left:198},
                { name: "Chef", width:75,height:205, top:478, left:203},
                { name: "Aboriginal", width:75,height:138, top:457, left:210},
                { name: "Dancer", width:70,height:151, top:552, left:226},
                { name: "Survivor", width:121,height:81, top:543, left:160},
                { name: "Chicken Little", width:75,height:181, top:505, left:198},
                { name: "Monster", width:119,height:102, top:539, left:158},
                { name: "Scuba", width:159,height:184, top:462, left:143},
                { name: "Amelia", width:55,height:501, top:563, left:158},
                { name: "BBoy", width:131,height:106, top:563, left:155},
                { name: "Nurse", width:129,height:99, top:580, left:185},
                { name: "Pilot", width:110,height:90, top:584, left:206},
                { name: "Police", width:50,height:134, top:497, left:223},
                { name: "Warrior", width:55,height:501, top:205, left:213},
                { name: "Surfer", width:108,height:361, top:347, left:143},
              ]
          }

    static getPositionfromArray(postions:any, name:string){
        const result= postions.filter(item=>name.toLowerCase().includes(item.name.toLowerCase()));
        console.log(result);
          if(result.length>0)
            {
              return  result[0];
            }
            else{
              return postions.find(item=>item.name==="default");
            }
    }
static getfaces(age:any, char:any){
  switch(char.name){
    case characterName.personMale:
     return age.name==="kid"?this.face.kid.personMale:this.face.teen.personMale;
    case characterName.personFemale:
      return age.name==="kid"?this.face.kid.personFemale:this.face.teen.personFemale;
    case characterName.Goanna:
      return age.name==="kid"?this.face.kid.Goanna:this.face.teen.Goanna;
    case characterName.Kanagroo:
      return age.name==="kid"?this.face.kid.Goanna:this.face.teen.Goanna;
    case characterName.Echidna:
      return age.name==="kid"?this.face.kid.Echidna:this.face.teen.Echidna;
    case characterName.Dolphin:
      return age.name==="kid"?this.face.kid.Dolphin:this.face.teen.Dolphin;
    case characterName.Dingo:
      return age.name==="kid"?this.face.kid.Dingo:this.face.teen.Dingo;
    case characterName.Dinasaur:
      return age.name==="kid"?this.face.kid.Dinasaur:this.face.teen.Dinasaur;
    case characterName.Turtle:
      return age.name==="kid"?this.face.kid.Turtle:this.face.teen.Turtle;
    case characterName.Platypus:
      return age.name==="kid"?this.face.kid.Platypus:this.face.teen.Platypus;
  }
}
static getPosition(age: any, type: any, char?: any){
  console.log("TYPE",type);
  
  switch(type.typeId){
    case menuTypes.hair:
    const hairs= age.name==="kid"?this.hair.kid:this.hair.teen;
     return this.getPositionfromArray(hairs, type.name);
    break;
    case menuTypes.hat:
      const hats= age.name==="kid"?this.hat.kid:this.hat.teen;
      return this.getPositionfromArray(hats, type.name);
    break;
    case menuTypes.face: 
      const faces= this.getfaces(age, char);
      return this.getPositionfromArray(faces, type.name);
    break;
    case menuTypes.tool:
      const tools= age.name==="kid"?this.tool.kid:this.tool.teen;
      return this.getPositionfromArray(tools, type.name);
    break;
    default:
      break;
  }
}

}