import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface DinasaurProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface DinasaurState {
    height:string
}


export class Dinasaur extends React.Component<DinasaurProps, DinasaurState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getkidWithTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                        <path fill={backGroundColor} d="M421.3,441.5c-1.3-2-2.8-3.9-4.5-5.7c-10-10.5-34.7-9.1-70.7,14.8c-0.2-1.2-0.5-2.3-0.9-3.4
                            c-1-7.7-3.7-13.2-6.7-17c7.1-19.1-24.5-52.7-32.7-61c30.9-8.1,51.7-23.5,63.8-45.3c5-9.2,8.6-19.2,10.5-29.6c0.5-2.7,0.9-5.3,1.3-8
                            c0.3-2.6,0.6-5.2,0.8-7.9c0.2-3,0.4-6.1,0.4-9.3c13.7-1.6,24-7.7,22.7-16.7c-2.2-15.9-18.4-24.1-7.1-48.5c8.8-18.9,9.6-21,9.6-21
                            s19.8-22.6-12.9-21.4c-22.1,0.8-59,3.4-82.2,5.2c-40-22.3-88.7-22.3-128.7,0c-23.3-1.8-60.1-4.4-82.2-5.2
                            C69,160.4,88.8,183,88.8,183s0.9,2.1,9.6,21c11.3,24.4-4.9,32.6-7.1,48.5c-1.3,9,9,15.1,22.7,16.7c0.1,5.6,0.4,11.1,1.1,16.7
                            c0.4,2.9,0.8,5.8,1.3,8.6c1.9,10.4,5.4,20.4,10.5,29.6c12.8,23.2,35.6,39.2,69.9,46.7c0,0,0,0,0,0c-20.6,2.8-65.2,11.6-56.5,36.2
                            c6.2,17.4,22.9,13.3,36.5,6.2c-3.9,3.6-7.5,7.1-9.6,9.3c0,0-13.8,5.1-16.3,24.8c-5.5,17,8.8,32.8,27.1,25.3
                            c0.1,0.1,0.1,0.2,0.2,0.2c1.3,1.2,2.8,2,4.4,2.4c-10.7,33.4-23.2,14.6-30.3,27.8c3.3,0,4.8,7.6,4,8h1.6c2.1-6.8,16.9-10.5,16.4,1.5
                            h1.1c5.2-11.1,16.5-5,12.4,3.8c6.7-3.2,6.1-10.4,6.4-13.2c2.3-3.8,3.3-9.8,3.3-9.8c3.8-5.1,4.5-8.2,4.5-8.2s-0.1-0.6-0.2-1.6v0.2
                            c-0.2-1.3-1-11.6-2.3-15.7c0.3-0.4,0.5-0.8,0.8-1.1c1.9,1.6,5.2,3.7,5.6,3.9c6.7,19.9,22.6,47.3,58.9,49.2
                            c13.4,0.7,26.9-2.1,39.6-6.7c1,1.3,2.2,2.4,3.7,3.1c-4.2-11.6,9.3-12.6,12.4-3.8h1.2c-0.2-13.3,15.4-6.8,16.4-1.5h1.6
                            c-0.9-1.4,1.1-7.9,4-8c-0.2-0.2-3.9-5.4-6.2-5.6c-0.4,0.1-0.8,0.1-1.2,0.1c15.8-9.9,30.5-21.6,43.8-34.8c0,0,26.2-20.5,45.8-9.7
                            C426,453,425.4,447.9,421.3,441.5z M169.1,501.5L169.1,501.5L169.1,501.5L169.1,501.5z M186.4,503.8L186.4,503.8L186.4,503.8
                            L186.4,503.8z M295.1,476.2L295.1,476.2c-2.7-0.9-5.3-2.2-7.7-3.8c-0.3,0.2,5.5-3.3,8.3-5.5c0.2,0.4,0.5,0.8,0.8,1.1
                            C295.8,470.7,295.3,473.4,295.1,476.2z"/>
                        <path fill="#EAEAEA" d="M341.1,504.8c-2.1,2.4-1.3,6.1-1.3,6.1s16.8,0.3,16.7,0c-1.6-4.6-9.6-7.3-12.7-8l0,0
                            C343.8,502.9,342.7,503,341.1,504.8z"/>
                        <path fill="#EAEAEA" d="M340.1,517.9c0.5-0.4-1.1-5.1-1.9-7.1l0,0c-1-2.7-3.5-4.5-4.4-5.1c-0.9-0.6-12.8-4.4-12,6.6
                            C323.5,514.4,339.7,518.3,340.1,517.9z"/>
                        <path fill="#EAEAEA" d="M313.3,506.2c-1.3-0.2-3.4,0.9-4.9,2.8c-2.5,3.3-0.1,7.2-0.1,7.2c5.5,1.8,14.7,4,15,3.7
                            c0.3-0.2-1.8-5.7-2.8-7.7C320.1,511.3,317.5,506.8,313.3,506.2z"/>
                        <path fill="#FFFFFF" d="M314.3,507.4c0,0-3.1,0.7-2.1,1c4,1.1,8.8,8.1,9.1,8C321.5,516.3,318.6,508.6,314.3,507.4z"/>
                        <path fill="#FFFFFF" d="M331.4,505.9c0,0-3.1,0.7-2.1,1c4,1.1,8.8,8.1,9.1,8C338.6,514.8,335.7,507,331.4,505.9z"/>
                        <path fill="#FFFFFF" d="M343.9,503.3c-0.6,0.3-1.2,0.8-1.5,1.4c0,0,8.2,1.9,11.2,3.4C356.6,509.6,351.1,504.9,343.9,503.3z"/>
                        <path fill="#EAEAEA" d="M155.1,504.8c2.1,2.4,1.3,6.1,1.3,6.1s-16.8,0.3-16.7,0c1.6-4.6,9.6-7.3,12.7-8l0,0
                            C152.5,502.9,153.6,503,155.1,504.8z"/>
                        <path fill="#EAEAEA" d="M156.1,517.9c-0.5-0.4,1.1-5.1,1.9-7.1l0,0c1-2.7,3.5-4.5,4.4-5.1c0.9-0.6,12.8-4.4,12,6.6
                            C172.8,514.4,156.6,518.3,156.1,517.9z"/>
                        <path fill="#EAEAEA" d="M183,506.2c1.3-0.2,3.4,0.9,4.9,2.8c2.5,3.3,0.1,7.2,0.1,7.2c-5.5,1.8-14.7,4-15,3.7
                            c-0.4-0.2,1.8-5.7,2.8-7.7C176.2,511.3,178.7,506.8,183,506.2z"/>
                        <path fill="#FFFFFF" d="M182,507.4c0,0,3.1,0.7,2.1,1c-4,1.1-8.8,8.1-9.1,8C174.7,516.3,177.6,508.6,182,507.4z"/>
                        <path fill="#FFFFFF" d="M164.8,505.9c0,0,3.1,0.7,2.1,1c-4,1.1-8.8,8.1-9.1,8S160.5,507,164.8,505.9z"/>
                        <path fill="#FFFFFF" d="M152.3,503.3c0.6,0.3,1.2,0.8,1.5,1.4c0,0-8.2,1.9-11.2,3.4C139.6,509.6,145.1,504.9,152.3,503.3z"/>
                        <path fill="#FF3672" d="M166.9,380.3c-1.4-0.2-2.8-0.4-4.1-0.7C164.2,380,165.6,380.2,166.9,380.3z"/>
                        <path fill="#FF86AA" d="M162.3,379.5l0.5,0.1L162.3,379.5L162.3,379.5z"/>
                        <path fill="#FFFFFF" d="M203.8,341.7c-1.7-0.3-3.4-0.7-5-1.1c-6.2-1.5-12.1-4-17.4-7.4c4.5,11.7,24.4,33.3,22.5,21
                            C203.2,350.1,203.2,345.8,203.8,341.7z"/>
                        <path fill="#FFFFFF" d="M301.4,340.5c-1.6,0.4-3.3,0.8-5,1.1c0.6,4.2,0.6,8.4,0,12.5c-2,12.3,18-9.3,22.5-21
                            C313.5,336.6,307.6,339.1,301.4,340.5z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M344.8,255.9c-5-0.1-10,1.2-14.3,3.7c2.1,3.4,3.8,7,5,10.7
                            c0.9,2.8,1.3,5.7,1.1,8.7c-0.1,0.6-0.1,1.1-0.2,1.7c2.7,0.8,5.5,1.2,8.4,1.1c11.3,0,20.4-5.8,20.4-13S356.1,255.9,344.8,255.9z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M163.5,279c-0.2-2.8,0.2-5.6,1-8.2c1-3.3,2.4-6.4,4.1-9.3
                            c-3.7-3.4-9.8-5.6-16.8-5.6c-11.3,0-20.4,5.8-20.4,13s9.1,13,20.4,13c4,0,8.1-0.8,11.8-2.4L163.5,279z"/>
                        <path fill="#FFFFFF" d="M333.9,200.3c-16.2,0-29.2,13.1-29.2,29.2c0,0.7,0,1.5,0.1,2.2c10.5,8.2,19.4,17.7,25.1,26.7
                            c16,2.3,30.8-8.8,33.1-24.8s-8.8-30.8-24.8-33.1C336.7,200.3,335.3,200.2,333.9,200.3L333.9,200.3z"/>
                        <path fill="#FFFFFF" d="M170.4,258.4c5.7-9,14.6-18.5,25.1-26.7c0.1-0.7,0.1-1.5,0.1-2.2c0-16.2-13.1-29.3-29.2-29.3
                            s-29.3,13.1-29.3,29.2c0,16.2,13.1,29.3,29.2,29.3c0,0,0,0,0,0C167.7,258.7,169,258.6,170.4,258.4z"/>
                        <path fill="#21323F" d="M320.6,246.4c8.2,2,16.4-3.1,18.3-11.3c0.3-1.2,0.4-2.4,0.4-3.6h-5.4c-3.3,0-5.9-2.7-5.9-6
                            c0-3.2,2.5-5.7,5.6-5.9c-6.5-5.3-16.1-4.3-21.4,2.2c-3.1,3.8-4.2,8.9-2.9,13.6C313.3,238.9,317.1,242.5,320.6,246.4z"/>
                        <path fill="#21323F" d="M176.2,246.8c1.2,0,2.4-0.1,3.5-0.4c3.5-3.9,7.3-7.5,11.2-10.9c2.2-8.1-2.6-16.5-10.8-18.7
                            c-4.7-1.3-9.8-0.2-13.5,2.9c3.3,0.2,5.8,3,5.6,6.3c-0.2,3.1-2.8,5.6-5.9,5.6h-5.4C160.9,240,167.7,246.8,176.2,246.8z"/>
                        <path opacity="0.3" fill="#E0E0E0" enable-background="new    " d="M329.8,306c2-9.2,5.9-17.8,6.9-27c0.2-2.9-0.2-5.9-1.1-8.7
                            c-3.7-12-15.6-26.8-31-38.8c-16.2-12.5-36.1-22-54.5-22l0,0c-18.3,0-38.3,9.5-54.4,22c-15.6,12.1-27.6,27.1-31.1,39.2
                            c-0.8,2.7-1.2,5.4-1,8.2c1,9.2,4.9,17.8,6.9,27c0.1,0.9,0,1.8-0.1,2.8c-0.5,4.4-2.4,7.3,0.1,11.7c4.5,10.5,15.2,16.5,28.4,20
                            c7.9,1.9,16.1,3.1,24.2,3.5c9,0.5,18.1,0.5,27.1,0.1l0,0c9,0.4,18.1,0.4,27.1-0.1c8.2-0.4,16.3-1.6,24.2-3.5
                            c13.2-3.4,23.9-9.5,28.4-20c2.4-4.4,0.5-7.4,0.1-11.7C329.7,307.9,329.7,307,329.8,306z"/>
                        <path fill="#414042" d="M192.5,267.3c0.2-0.9,0.4-1.8,0.7-2.7c0.5-1.2,1.1-2.3,1.7-3.4c1.9,2.5,6.1,5.5,4.3,9.1
                            c-0.6,1.1-1.6,2.4-2,3.6c-1.4-1.3-2.7-2.7-3.9-4.1C192.7,269.1,192.4,268.2,192.5,267.3z"/>
                        <path fill="#383839" d="M307.7,267.3c-0.2-0.9-0.4-1.8-0.7-2.7c-0.5-1.2-1.1-2.3-1.7-3.4c-1.9,2.5-6.1,5.5-4.3,9.1
                            c0.6,1.1,1.6,2.4,2,3.6c1.4-1.3,2.7-2.7,3.9-4.1C307.4,269.1,307.8,268.2,307.7,267.3z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M319.4,460.6c-14.3,2,0-13.7,0-13.7c-3.7-1.5-7-3.7-9.9-6.5
                            c0,0.2,0,0.4,0,0.6c0,10.4-5.4,19.1-13.7,25.8c0.2,0.4,0.5,0.8,0.8,1.1c-0.2,0.8-0.4,1.5-0.6,2.3c-0.7,3.4-1.7,13.4-1.7,13.4
                            s1.8-7.8,3.3-10.9c3.4-6.8,5.7-7.7,5.7-7.7l6.4,8.8l0.2-7.1l1.9,1.5l5.4,4.3l0.7,0.2l0.2-0.2c4.6,1.9,13.5,4,21.4-2.4l0.1-0.2
                            C314.8,474.5,319.4,460.6,319.4,460.6z"/>
                        <g opacity="0.3">
                            <path fill="#EAEAEA" d="M318.1,472.5l-0.2,0.2l0,0c-1.3,1.2-2.8,2-4.4,2.4c0.7,2.5,1.6,5,2.7,7.5c3.9-1.4,7.8-3,11.5-5
                                c2-1.2,3.8-2.5,5.6-4C329,474.9,324,474.7,318.1,472.5z"/>
                            <path fill="#EAEAEA" d="M416.8,435.8c-10-10.5-34.7-9.1-70.7,14.8c0.7,4.6,0.1,9.2-1.9,13.4c10.8-9.6,24.7-21.2,38.4-25.5
                                c20-6.2,35.2,1.1,38.7,3C420,439.5,418.4,437.6,416.8,435.8z"/>
                            <path fill="#EAEAEA" d="M294,485.1c0,0,0.1-0.6,0.2-1.6v0.2l0,0c0-0.1,0.4-3.7,0.9-7.5c-2.7-0.9-5.3-2.2-7.6-3.8
                                c-0.3,0.2-0.7,0.4-1,0.5l-0.9,0.5c-0.6,0.3-1.2,0.6-1.9,0.9l-0.7,0.3c-0.9,0.4-1.8,0.8-2.7,1.1l-0.8,0.3c-0.5,0.2-1.1,0.4-1.6,0.6
                                c2.5,6.6,10.4,9.7,16.8,10.3C294.5,486.3,294.2,485.7,294,485.1z"/>
                        </g>
                        <g opacity="0.4">
                            <path fill="#353535" d="M309.7,503.8L309.7,503.8L309.7,503.8L309.7,503.8z"/>
                            <path fill="#353535" d="M327,501.5L327,501.5L327,501.5L327,501.5z"/>
                            <path fill="#353535" d="M301.8,503.1c-2.3-3.8-3.3-9.8-3.3-9.8c-1.5-2-2.8-4.1-3.8-6.3c-6.4-0.6-14.3-3.7-16.8-10.3l-0.3,0.1
                                c-0.9,0.3-1.9,0.7-2.8,1l-0.4,0.2c-1.1,0.4-2.3,0.7-3.4,1.1l-0.5,0.1c-1,0.3-2,0.5-3,0.8l-1,0.2c-0.8,0.2-1.6,0.4-2.5,0.5
                                l-1.2,0.2c-0.8,0.2-1.5,0.3-2.3,0.4l-1.3,0.2c-0.8,0.1-1.5,0.2-2.3,0.3l-1.3,0.2l-2.5,0.2l-1.1,0.1c-1.2,0.1-2.4,0.2-3.5,0.2h-0.6
                                c-1,0-2.1-0.1-3.1-0.2l-1-0.1l-2.1-0.2l-1.1-0.1l-2-0.2l-1.1-0.2l-2.1-0.3l-1-0.2c-0.8-0.1-1.6-0.3-2.4-0.5l-0.6-0.1
                                c-2.1-0.4-4.1-0.9-6.1-1.5l-0.5-0.1c-0.8-0.2-1.7-0.5-2.5-0.8l-0.6-0.2c-0.8-0.3-1.6-0.5-2.4-0.8l-0.6-0.2c-0.8-0.3-1.7-0.6-2.5-1
                                l-0.3-0.1c-3.4-1.4-6.7-3.1-9.9-5c6.7,19.9,22.6,47.3,58.9,49.1c13.4,0.7,26.9-2.1,39.6-6.7C302.7,510.8,301.6,507.4,301.8,503.1z
                                "/>
                            <path fill="#353535" d="M421.2,441.5c-3.5-2-18.7-9.3-38.7-3c-13.8,4.3-27.6,15.9-38.4,25.5c-2.1,4.6-6.1,8.1-10.9,9.6
                                c-1.7,1.5-3.6,2.9-5.6,4c-3.7,2-7.5,3.6-11.5,5c3.6,8.3,9.5,15.4,20.2,14.8c15.9-9.9,30.6-21.6,43.8-34.8c0,0,26.2-20.5,45.8-9.7
                                C425.9,453,425.4,447.9,421.2,441.5z"/>
                        </g>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M331.3,438.5c-13.7,8.2-23.3-1.1-29.5-12.9
                            c5.3,12.5,14.3,23.8,28.8,15.2c4.1-2.4,6.4-5.6,7.4-9.2C336.7,434.2,334.6,436.5,331.3,438.5z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M174.3,415.6c8.1-3.9,15.5-9.1,19.4-12.1c0-0.4,0-0.8,0.1-1.2
                            c-3.5,2.7-9.8,7.2-16.9,10.9C176,414,175.2,414.8,174.3,415.6z"/>
                        <path fill="#6DD7A4" d="M171.7,384.7c1-2.3,3.2-4.2,4.8-5.2c-4.1,1.4-9.4,1.2-14,0c1.5,0.6,4.7,2.2,5.8,5.6c0,0,0,0,0,0
                            c0.4,1.4,0.5,2.8,0.2,4.2c-1.3,7.3-4.1,19.1,1.6,19.1c0.4,0,0.8-0.1,1.2-0.2c3.8-0.9,2.5-13.3,0.3-19.5
                            C171.1,387.5,171.1,386.1,171.7,384.7C171.7,384.7,171.7,384.7,171.7,384.7z"/>
                        <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M298,370.9c-5.1,1.1-10.4,2-16,2.7c-3.1,0.4-6.2,0.7-9.4,1
                            c-7.6,0.6-15.7,1-24.1,1c-8.5,0-16.5-0.3-24.1-1c-3.1-0.3-6.1-0.6-9.1-1c-2-0.2-3.9-0.5-5.8-0.8c-2.8-0.4-5.6-0.9-8.2-1.4
                            c-0.9-0.2-1.9-0.4-2.8-0.6c-0.1,0.3-0.1,0.6-0.2,0.9c-2.1,9.7-3.9,20.7-4.3,31.7c-1.4,32.6,8.6,65.5,54.3,65.5
                            C309.9,468.9,306.4,409.1,298,370.9z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M171.5,398.5c-0.5,0-0.4,1.8-0.6,4c-0.2,2.3-0.6,4-0.1,4
                            c0.5,0,1.6-1.8,1.8-4C172.8,400.3,172,398.5,171.5,398.5z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M170.7,395c-0.4,0.5-0.5,1.3-0.3,1.9c0.2,0.7,0.7,1,1,0.7
                            c0.4-0.5,0.5-1.2,0.3-1.9C171.5,395,171,394.7,170.7,395z"/>
                        <path fill="#FF3672" d="M181.3,333.1c-4.8-3-8.6-7.4-10.9-12.6c-2.5-4.4-0.5-7.4-0.1-11.7c0.1-0.5,0.1-1.1,0.1-1.6
                            c-2.1,0.2-4.1,0.8-6,1.8c-19.3,9.3-16,57.6-11.8,65.2c1.3,2.3,5.1,4.3,9.7,5.4c2.6-0.1,4.8-1.9,5-3.5c0-0.4,0.2-0.5,0.3-0.5
                            c0.9,0,3.3,3.3,6.8,4.5c2.7-0.4,5.2-1.7,7.1-3.7C185,372.3,182.6,347.6,181.3,333.1z"/>
                        <path fill="#FF5E8E" d="M155.8,335.4L155.8,335.4c-0.8,0-2.7,6.1-3,13c-0.2,5.4,0.9,10.1,1.6,10.1c0.1,0,0.1,0,0.2-0.1
                            c1.4-1.3,0.8-5.9,1-11.7C155.8,340.9,156.7,335.4,155.8,335.4"/>
                        <path fill="#FF5E8E" d="M155.7,361.9c-0.1,0-0.2,0.1-0.3,0.2c-0.5,0.6-0.8,2.5-0.5,4.4c0.2,1.5,0.7,2.4,1.1,2.4
                            c0.1,0,0.2-0.1,0.3-0.2c0.5-0.6,0.8-2.5,0.5-4.4C156.6,362.8,156.1,361.9,155.7,361.9"/>
                        <path fill="#FF86AA" d="M167.4,376.1c-0.2,1.6-2.4,3.4-5,3.5l0.4,0.1c1.3,0.3,2.7,0.5,4.1,0.7c2.6,0.3,5.1,0.2,7.7-0.3
                            c-3.6-1.2-6-4.5-6.8-4.5C167.5,375.6,167.4,375.7,167.4,376.1z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M200.3,470.3c-0.1-0.8-0.3-1.5-0.6-2.3c0.3-0.4,0.5-0.8,0.8-1.1
                            c-7.9-6.4-13.1-14.5-13.7-24.2c-1.7,1.7-4.4,3.9-8.8,5.7c3.1,3.8,10.5,13.9-1.2,12.3c0,0,4.6,13.9-20.2,9.3l0.1,0.2
                            c7.8,6.4,16.8,4.3,21.4,2.4c0.1,0.1,0.1,0.2,0.2,0.2l0.7-0.2l5.4-4.3l1.9-1.5l0.2,7.1l6.4-8.8c0,0,2.4,0.9,5.7,7.7
                            c1.5,3.1,3.3,10.9,3.3,10.9S200.9,473.7,200.3,470.3z"/>
                    </svg>
                    
        return kid;     
    }

    getKidWithOutTool(backGroundColor:string){
        const kid=   <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                        <path fill={backGroundColor} d="M421.3,441.5c-1.3-2-2.8-3.9-4.5-5.7c-10-10.5-34.7-9.1-70.7,14.8c-0.2-1.2-0.5-2.3-0.9-3.4
                            c-1-7.7-3.7-13.2-6.7-17c7.1-19.1-24.5-52.7-32.7-61c30.9-8.1,51.7-23.5,63.8-45.3c5-9.2,8.6-19.2,10.5-29.6c0.5-2.7,0.9-5.3,1.3-8
                            c0.3-2.6,0.6-5.2,0.8-7.9c0.2-3,0.4-6.1,0.4-9.3c13.7-1.6,24-7.7,22.7-16.7c-2.2-15.9-18.4-24.1-7.1-48.5c8.8-18.9,9.6-21,9.6-21
                            s19.8-22.6-12.9-21.4c-22.1,0.8-59,3.4-82.2,5.2c-40-22.3-88.7-22.3-128.7,0c-23.3-1.8-60.1-4.4-82.2-5.2
                            C69,160.4,88.8,183,88.8,183s0.9,2.1,9.6,21c11.3,24.4-4.9,32.6-7.1,48.5c-1.3,9,9,15.1,22.7,16.7c0.1,5.6,0.4,11.1,1.1,16.7
                            c0.4,2.9,0.8,5.8,1.3,8.6c1.9,10.4,5.4,20.4,10.5,29.6c12.4,22.3,34,38,66.2,45.9c-8.6,8.8-36,38.4-32.8,57.3
                            c-3.8,3.7-8.1,9.9-9.3,20c-5.5,17,8.8,32.8,27.1,25.3c0.1,0.1,0.1,0.2,0.2,0.2c1.3,1.2,2.8,2,4.4,2.4
                            c-10.7,33.4-23.2,14.6-30.3,27.8c3.3,0,4.8,7.6,4,8h1.6c2.1-6.8,16.9-10.5,16.4,1.5h1.1c5.2-11.1,16.5-5,12.4,3.8
                            c6.7-3.2,6.1-10.4,6.4-13.2c2.3-3.8,3.3-9.8,3.3-9.8c3.8-5.1,4.5-8.2,4.5-8.2s-0.1-0.6-0.2-1.6v0.2c-0.2-1.3-1-11.6-2.3-15.7
                            c0.3-0.4,0.5-0.8,0.8-1.1c1.9,1.6,5.2,3.7,5.6,3.9c6.7,19.9,22.6,47.3,58.9,49.2c13.4,0.7,26.9-2.1,39.6-6.7c1,1.3,2.2,2.4,3.7,3.1
                            c-4.2-11.6,9.3-12.6,12.4-3.8h1.2c-0.2-13.3,15.4-6.8,16.4-1.5h1.6c-0.9-1.4,1.1-7.9,4-8c-0.2-0.2-3.9-5.4-6.2-5.6
                            c-0.4,0.1-0.8,0.1-1.2,0.1c15.8-9.9,30.5-21.6,43.8-34.8c0,0,26.2-20.5,45.8-9.7C426,453,425.4,447.9,421.3,441.5z M169.1,501.5
                            L169.1,501.5L169.1,501.5L169.1,501.5z M186.4,503.8L186.4,503.8L186.4,503.8L186.4,503.8z M295.1,476.2L295.1,476.2
                            c-2.7-0.9-5.3-2.2-7.7-3.8c-0.3,0.2,5.5-3.3,8.3-5.5c0.2,0.4,0.5,0.8,0.8,1.1C295.8,470.7,295.3,473.4,295.1,476.2z"/>
                        <path fill="#EAEAEA" d="M341.1,504.8c-2.1,2.4-1.3,6.1-1.3,6.1s16.8,0.3,16.7,0c-1.6-4.6-9.6-7.3-12.7-8l0,0
                            C343.8,502.9,342.7,503,341.1,504.8z"/>
                        <path fill="#EAEAEA" d="M340.1,517.9c0.5-0.4-1.1-5.1-1.9-7.1l0,0c-1-2.7-3.5-4.5-4.4-5.1c-0.9-0.6-12.8-4.4-12,6.6
                            C323.5,514.4,339.7,518.3,340.1,517.9z"/>
                        <path fill="#EAEAEA" d="M313.3,506.2c-1.3-0.2-3.4,0.9-4.9,2.8c-2.5,3.3-0.1,7.2-0.1,7.2c5.5,1.8,14.7,4,15,3.7
                            c0.3-0.2-1.8-5.7-2.8-7.7C320.1,511.3,317.5,506.8,313.3,506.2z"/>
                        <path fill="#FFFFFF" d="M314.3,507.4c0,0-3.1,0.7-2.1,1c4,1.1,8.8,8.1,9.1,8C321.5,516.3,318.6,508.6,314.3,507.4z"/>
                        <path fill="#FFFFFF" d="M331.4,505.9c0,0-3.1,0.7-2.1,1c4,1.1,8.8,8.1,9.1,8C338.6,514.8,335.7,507,331.4,505.9z"/>
                        <path fill="#FFFFFF" d="M343.9,503.3c-0.6,0.3-1.2,0.8-1.5,1.4c0,0,8.2,1.9,11.2,3.4C356.6,509.6,351.1,504.9,343.9,503.3z"/>
                        <path fill="#EAEAEA" d="M155.1,504.8c2.1,2.4,1.3,6.1,1.3,6.1s-16.8,0.3-16.7,0c1.6-4.6,9.6-7.3,12.7-8l0,0
                            C152.5,502.9,153.6,503,155.1,504.8z"/>
                        <path fill="#EAEAEA" d="M156.1,517.9c-0.5-0.4,1.1-5.1,1.9-7.1l0,0c1-2.7,3.5-4.5,4.4-5.1c0.9-0.6,12.8-4.4,12,6.6
                            C172.8,514.4,156.6,518.3,156.1,517.9z"/>
                        <path fill="#EAEAEA" d="M183,506.2c1.3-0.2,3.4,0.9,4.9,2.8c2.5,3.3,0.1,7.2,0.1,7.2c-5.5,1.8-14.7,4-15,3.7
                            c-0.4-0.2,1.8-5.7,2.8-7.7C176.2,511.3,178.7,506.8,183,506.2z"/>
                        <path fill="#FFFFFF" d="M182,507.4c0,0,3.1,0.7,2.1,1c-4,1.1-8.8,8.1-9.1,8C174.7,516.3,177.6,508.6,182,507.4z"/>
                        <path fill="#FFFFFF" d="M164.8,505.9c0,0,3.1,0.7,2.1,1c-4,1.1-8.8,8.1-9.1,8S160.5,507,164.8,505.9z"/>
                        <path fill="#FFFFFF" d="M152.3,503.3c0.6,0.3,1.2,0.8,1.5,1.4c0,0-8.2,1.9-11.2,3.4C139.6,509.6,145.1,504.9,152.3,503.3z"/>
                        <path fill="#FF3672" d="M166.9,380.3c-1.4-0.2-2.8-0.4-4.1-0.7C164.2,380,165.6,380.2,166.9,380.3z"/>
                        <path fill="#FF3672" d="M181.3,333.1c-4.8-3-8.6-7.4-10.9-12.6c-2.5-4.4-0.5-7.4-0.1-11.7c0.1-0.5,0.1-1.1,0.1-1.6
                            c-2.1,0.2-4.1,0.8-6,1.8c-19.3,9.3-16,57.6-11.8,65.2c1.3,2.3,5.1,4.3,9.7,5.4c2.6-0.1,4.8-1.9,5-3.5c0-0.4,0.2-0.5,0.3-0.5
                            c0.9,0,3.3,3.3,6.8,4.5c2.7-0.4,5.2-1.7,7.1-3.7C185,372.3,182.6,347.6,181.3,333.1z"/>
                        <path fill="#FF86AA" d="M162.3,379.5l0.5,0.1L162.3,379.5L162.3,379.5z"/>
                        <path fill="#FF5E8E" d="M155.8,335.4L155.8,335.4c-0.8,0-2.7,6.1-3,13c-0.2,5.4,0.9,10.1,1.6,10.1c0.1,0,0.1,0,0.2-0.1
                            c1.4-1.3,0.8-5.9,1-11.7C155.8,340.9,156.7,335.4,155.8,335.4"/>
                        <path fill="#FF5E8E" d="M155.7,361.9c-0.1,0-0.2,0.1-0.3,0.2c-0.5,0.6-0.8,2.5-0.5,4.4c0.2,1.5,0.7,2.4,1.1,2.4
                            c0.1,0,0.2-0.1,0.3-0.2c0.5-0.6,0.8-2.5,0.5-4.4C156.6,362.8,156.1,361.9,155.7,361.9"/>
                        <path fill="#FFFFFF" d="M203.8,341.7c-1.7-0.3-3.4-0.7-5-1.1c-6.2-1.5-12.1-4-17.4-7.4c4.5,11.7,24.4,33.3,22.5,21
                            C203.2,350.1,203.2,345.8,203.8,341.7z"/>
                        <path fill="#FFFFFF" d="M301.4,340.5c-1.6,0.4-3.3,0.8-5,1.1c0.6,4.2,0.6,8.4,0,12.5c-2,12.3,18-9.3,22.5-21
                            C313.5,336.6,307.6,339.1,301.4,340.5z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M344.8,255.9c-5-0.1-10,1.2-14.3,3.7c2.1,3.4,3.8,7,5,10.7
                            c0.9,2.8,1.3,5.7,1.1,8.7c-0.1,0.6-0.1,1.1-0.2,1.7c2.7,0.8,5.5,1.2,8.4,1.1c11.3,0,20.4-5.8,20.4-13S356.1,255.9,344.8,255.9z"/>
                        <path opacity="0.15" fill="#EAEAEA" enable-background="new    " d="M163.5,279c-0.2-2.8,0.2-5.6,1-8.2c1-3.3,2.4-6.4,4.1-9.3
                            c-3.7-3.4-9.8-5.6-16.8-5.6c-11.3,0-20.4,5.8-20.4,13s9.1,13,20.4,13c4,0,8.1-0.8,11.8-2.4L163.5,279z"/>
                        <path fill="#FFFFFF" d="M333.9,200.3c-16.2,0-29.2,13.1-29.2,29.2c0,0.7,0,1.5,0.1,2.2c10.5,8.2,19.4,17.7,25.1,26.7
                            c16,2.3,30.8-8.8,33.1-24.8s-8.8-30.8-24.8-33.1C336.7,200.3,335.3,200.2,333.9,200.3L333.9,200.3z"/>
                        <path fill="#FFFFFF" d="M170.4,258.4c5.7-9,14.6-18.5,25.1-26.7c0.1-0.7,0.1-1.5,0.1-2.2c0-16.2-13.1-29.3-29.2-29.3
                            s-29.3,13.1-29.3,29.2c0,16.2,13.1,29.3,29.2,29.3c0,0,0,0,0,0C167.7,258.7,169,258.6,170.4,258.4z"/>
                        <path fill="#21323F" d="M320.6,246.4c8.2,2,16.4-3.1,18.3-11.3c0.3-1.2,0.4-2.4,0.4-3.6h-5.4c-3.3,0-5.9-2.7-5.9-6
                            c0-3.2,2.5-5.7,5.6-5.9c-6.5-5.3-16.1-4.3-21.4,2.2c-3.1,3.8-4.2,8.9-2.9,13.6C313.3,238.9,317.1,242.5,320.6,246.4z"/>
                        <path fill="#21323F" d="M176.2,246.8c1.2,0,2.4-0.1,3.5-0.4c3.5-3.9,7.3-7.5,11.2-10.9c2.2-8.1-2.6-16.5-10.8-18.7
                            c-4.7-1.3-9.8-0.2-13.5,2.9c3.3,0.2,5.8,3,5.6,6.3c-0.2,3.1-2.8,5.6-5.9,5.6h-5.4C160.9,240,167.7,246.8,176.2,246.8z"/>
                        <path opacity="0.3" fill="#E0E0E0" enable-background="new    " d="M329.8,306c2-9.2,5.9-17.8,6.9-27c0.2-2.9-0.2-5.9-1.1-8.7
                            c-3.7-12-15.6-26.8-31-38.8c-16.2-12.5-36.1-22-54.5-22l0,0c-18.3,0-38.3,9.5-54.4,22c-15.6,12.1-27.6,27.1-31.1,39.2
                            c-0.8,2.7-1.2,5.4-1,8.2c1,9.2,4.9,17.8,6.9,27c0.1,0.9,0,1.8-0.1,2.8c-0.5,4.4-2.4,7.3,0.1,11.7c4.5,10.5,15.2,16.5,28.4,20
                            c7.9,1.9,16.1,3.1,24.2,3.5c9,0.5,18.1,0.5,27.1,0.1l0,0c9,0.4,18.1,0.4,27.1-0.1c8.2-0.4,16.3-1.6,24.2-3.5
                            c13.2-3.4,23.9-9.5,28.4-20c2.4-4.4,0.5-7.4,0.1-11.7C329.7,307.9,329.7,307,329.8,306z"/>
                        <path fill="#414042" d="M192.5,267.3c0.2-0.9,0.4-1.8,0.7-2.7c0.5-1.2,1.1-2.3,1.7-3.4c1.9,2.5,6.1,5.5,4.3,9.1
                            c-0.6,1.1-1.6,2.4-2,3.6c-1.4-1.3-2.7-2.7-3.9-4.1C192.7,269.1,192.4,268.2,192.5,267.3z"/>
                        <path fill="#383839" d="M307.7,267.3c-0.2-0.9-0.4-1.8-0.7-2.7c-0.5-1.2-1.1-2.3-1.7-3.4c-1.9,2.5-6.1,5.5-4.3,9.1
                            c0.6,1.1,1.6,2.4,2,3.6c1.4-1.3,2.7-2.7,3.9-4.1C307.4,269.1,307.8,268.2,307.7,267.3z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M319.4,460.6c-14.3,2,0-13.7,0-13.7c-3.7-1.5-7-3.7-9.9-6.5
                            c0,0.2,0,0.4,0,0.6c0,10.4-5.4,19.1-13.7,25.8c0.2,0.4,0.5,0.8,0.8,1.1c-0.2,0.8-0.4,1.5-0.6,2.3c-0.7,3.4-1.7,13.4-1.7,13.4
                            s1.8-7.8,3.3-10.9c3.4-6.8,5.7-7.7,5.7-7.7l6.4,8.8l0.2-7.1l1.9,1.5l5.4,4.3l0.7,0.2l0.2-0.2c4.6,1.9,13.5,4,21.4-2.4l0.1-0.2
                            C314.8,474.5,319.4,460.6,319.4,460.6z"/>
                        <g opacity="0.3">
                            <path fill="#EAEAEA" d="M318.1,472.5l-0.2,0.2l0,0c-1.3,1.2-2.8,2-4.4,2.4c0.7,2.5,1.6,5,2.7,7.5c3.9-1.4,7.8-3,11.5-5
                                c2-1.2,3.8-2.5,5.6-4C329,474.9,324,474.7,318.1,472.5z"/>
                            <path fill="#EAEAEA" d="M416.8,435.8c-10-10.5-34.7-9.1-70.7,14.8c0.7,4.6,0.1,9.2-1.9,13.4c10.8-9.6,24.7-21.2,38.4-25.5
                                c20-6.2,35.2,1.1,38.7,3C420,439.5,418.4,437.6,416.8,435.8z"/>
                            <path fill="#EAEAEA" d="M294,485.1c0,0,0.1-0.6,0.2-1.6v0.2l0,0c0-0.1,0.4-3.7,0.9-7.5c-2.7-0.9-5.3-2.2-7.6-3.8
                                c-0.3,0.2-0.7,0.4-1,0.5l-0.9,0.5c-0.6,0.3-1.2,0.6-1.9,0.9l-0.7,0.3c-0.9,0.4-1.8,0.8-2.7,1.1l-0.8,0.3c-0.5,0.2-1.1,0.4-1.6,0.6
                                c2.5,6.6,10.4,9.7,16.8,10.3C294.5,486.3,294.2,485.7,294,485.1z"/>
                        </g>
                        <g opacity="0.4">
                            <path fill="#353535" d="M309.7,503.8L309.7,503.8L309.7,503.8L309.7,503.8z"/>
                            <path fill="#353535" d="M327,501.5L327,501.5L327,501.5L327,501.5z"/>
                            <path fill="#353535" d="M301.8,503.1c-2.3-3.8-3.3-9.8-3.3-9.8c-1.5-2-2.8-4.1-3.8-6.3c-6.4-0.6-14.3-3.7-16.8-10.3l-0.3,0.1
                                c-0.9,0.3-1.9,0.7-2.8,1l-0.4,0.2c-1.1,0.4-2.3,0.7-3.4,1.1l-0.5,0.1c-1,0.3-2,0.5-3,0.8l-1,0.2c-0.8,0.2-1.6,0.4-2.5,0.5
                                l-1.2,0.2c-0.8,0.2-1.5,0.3-2.3,0.4l-1.3,0.2c-0.8,0.1-1.5,0.2-2.3,0.3l-1.3,0.2l-2.5,0.2l-1.1,0.1c-1.2,0.1-2.4,0.2-3.5,0.2h-0.6
                                c-1,0-2.1-0.1-3.1-0.2l-1-0.1l-2.1-0.2l-1.1-0.1l-2-0.2l-1.1-0.2l-2.1-0.3l-1-0.2c-0.8-0.1-1.6-0.3-2.4-0.5l-0.6-0.1
                                c-2.1-0.4-4.1-0.9-6.1-1.5l-0.5-0.1c-0.8-0.2-1.7-0.5-2.5-0.8l-0.6-0.2c-0.8-0.3-1.6-0.5-2.4-0.8l-0.6-0.2c-0.8-0.3-1.7-0.6-2.5-1
                                l-0.3-0.1c-3.4-1.4-6.7-3.1-9.9-5c6.7,19.9,22.6,47.3,58.9,49.1c13.4,0.7,26.9-2.1,39.6-6.7C302.7,510.8,301.6,507.4,301.8,503.1z
                                "/>
                            <path fill="#353535" d="M421.2,441.5c-3.5-2-18.7-9.3-38.7-3c-13.8,4.3-27.6,15.9-38.4,25.5c-2.1,4.6-6.1,8.1-10.9,9.6
                                c-1.7,1.5-3.6,2.9-5.6,4c-3.7,2-7.5,3.6-11.5,5c3.6,8.3,9.5,15.4,20.2,14.8c15.9-9.9,30.6-21.6,43.8-34.8c0,0,26.2-20.5,45.8-9.7
                                C425.9,453,425.4,447.9,421.2,441.5z"/>
                        </g>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M331.3,438.5c-13.7,8.2-23.3-1.1-29.5-12.9
                            c5.3,12.5,14.3,23.8,28.8,15.2c4.1-2.4,6.4-5.6,7.4-9.2C336.7,434.2,334.6,436.5,331.3,438.5z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M168.4,438.5c-4.8-2.9-7.2-6.7-8-11.2
                            c0.3,5.2,2.8,11.1,8.1,14.3c10.8,6.5,18.4,0.4,23.9-7.6C186.4,441,178.5,444.5,168.4,438.5z"/>
                        <path opacity="0.4" fill="#E0E0E0" enable-background="new    " d="M281.9,373.6c-3.1,0.4-6.2,0.7-9.4,1c-7.6,0.6-15.7,1-24.1,1
                            c-8.5,0-16.5-0.3-24.1-1c-3.1-0.3-6.1-0.6-9.1-1c-5.9-0.7-11.5-1.7-16.8-2.8c-8.5,38.2-12,98.1,49.8,98.1s58.3-59.8,49.8-98
                            C292.9,372,287.5,372.9,281.9,373.6z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M200.3,470.3c-0.1-0.8-0.3-1.5-0.6-2.3c0.3-0.4,0.5-0.8,0.8-1.1
                            c-7.9-6.4-13.1-14.5-13.7-24.2c-1.7,1.7-4.4,3.9-8.8,5.7c3.1,3.8,10.5,13.9-1.2,12.3c0,0,4.6,13.9-20.2,9.3l0.1,0.2
                            c7.8,6.4,16.8,4.3,21.4,2.4c0.1,0.1,0.1,0.2,0.2,0.2l0.7-0.2l5.4-4.3l1.9-1.5l0.2,7.1l6.4-8.8c0,0,2.4,0.9,5.7,7.7
                            c1.5,3.1,3.3,10.9,3.3,10.9S200.9,473.7,200.3,470.3z"/>
                        <path fill="#6DD7A4" d="M171.7,384.7c1-2.3,3.2-4.2,4.8-5.2c-4.1,1.4-9.4,1.2-14,0c1.5,0.6,4.7,2.2,5.8,5.6c0,0,0,0,0,0
                            c0.4,1.4,0.5,2.8,0.2,4.2c-1.3,7.3-4.1,19.1,1.6,19.1c0.4,0,0.8-0.1,1.2-0.2c3.8-0.9,2.5-13.3,0.3-19.5
                            C171.1,387.5,171.1,386.1,171.7,384.7C171.7,384.7,171.7,384.7,171.7,384.7z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M171.5,398.5c-0.5,0-0.4,1.8-0.6,4c-0.2,2.3-0.6,4-0.1,4
                            c0.5,0,1.6-1.8,1.8-4C172.8,400.3,172,398.5,171.5,398.5z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M170.7,395c-0.4,0.5-0.5,1.3-0.3,1.9c0.2,0.7,0.7,1,1,0.7
                            c0.4-0.5,0.5-1.2,0.3-1.9C171.5,395,171,394.7,170.7,395z"/>
                        <path fill="#FF86AA" d="M167.4,376.1c-0.2,1.6-2.4,3.4-5,3.5l0.4,0.1c1.3,0.3,2.7,0.5,4.1,0.7c2.6,0.3,5.1,0.2,7.7-0.3
                            c-3.6-1.2-6-4.5-6.8-4.5C167.5,375.6,167.4,375.7,167.4,376.1z"/>
                    </svg>
        return kid; 
    }
    getTeenWithTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                    <path fill={backGroundColor} d="M426.9,435.5c-3.6-3.6-7.9-6.5-12.5-8.4c-14.5-7-32.3-0.2-52.3,11.9c0.4-4.4,0-8.9-1.2-13.2
                        c-3.2-25.5-21.1-32.1-21.1-32.1c-0.2-0.2-0.5-0.5-0.7-0.7c-3.8-15.5-8.9-33.7-11.1-52.4c-2.5-21-21.4-26.6-32.3-28.1
                        c-0.5-0.3-1-0.5-1.5-0.8l-0.1-0.1l-10.3-3.7l-2-6.7c33.1-5.1,54.5-18.7,66-39.5c4-7.4,6.9-15.5,8.4-23.8c0.4-2.1,0.8-4.3,1-6.5
                        c0.3-2.1,0.5-4.2,0.6-6.3c0.2-2.2,0.2-4.1,0.3-6.2c7.8-2.7,12.8-7.4,11.8-13.4c-2.3-14-16.8-20.8-7.4-42.6c7.3-16.9,8-18.7,8-18.7
                        s16.9-20.4-11.9-18.6c-17.1,1.1-44.2,3.6-64.5,5.7c-28.1-13-60.6-13-88.7,0c-20.3-2-47.4-4.6-64.5-5.7
                        c-28.8-1.8-11.9,18.6-11.9,18.6s0.7,1.8,8.1,18.7c9.4,21.8-5.1,28.7-7.4,42.6c-1,6,4,10.8,11.8,13.4c0.1,4.2,0.4,8.2,0.9,12.1
                        c0.3,2.3,0.6,4.6,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9c11.6,20.9,33.1,34.5,66.3,39.6l-2,6.7l-10.3,3.7c-0.3,0.1-0.5,0.3-0.8,0.4
                        c-3.1,0.6-6.3,1.7-9.6,3.6c-38.7,21.8-84.7,28.6-81.3,50c4.1,25.9,55.5,6.2,77.1-3.3c-0.1,0.8-0.2,1.6-0.3,2.4
                        c-3.7,3.4-24,21.8-30.9,28.9c0,0-17.8,6.6-21.1,32.1c0,0-5.5,16.8,5.2,27.6c0.7,0.7,1.5,1.4,2.3,2.1c10.1,8.3,21.7,5.6,27.7,3.1
                        l0.3,0.3c1.6,1.5,3.6,2.6,5.7,3.1c-3,10.1-9,24.4-20.2,27.7c-5.3,1.6-8,0.7-11.1,1c-3,0.3-7.7,7-8,7.3c0,0,1.4,0.1,3.5,2.4
                        c2.7,3.1,1.8,7.9,1.8,7.9h2c1.3-3.5,4.6-5.8,5.7-6.6c1.1-0.8,16.6-5.7,15.6,8.5h1.5l0.1-0.2c0.6-1.2,3.9-7.2,9.4-7.8
                        c1.7-0.2,4.5,1.1,6.4,3.7c3.2,4.2,0.2,9.4,0.2,9.4s5.5-3.6,6.6-6.2c1.1-2.5,1.6-10.9,1.6-10.9c3-4.9,4.3-12.7,4.3-12.7
                        c4.9-6.6,5.9-10.6,5.9-10.6s-0.1-0.8-0.2-2.1v0.2c0,0-1.4-12.9-2.2-17.3c-0.2-1-0.5-2-0.8-3c0.6-0.8,1.1-1.7,1.6-2.5
                        c0.6,0.6,1.1,1.2,1.8,1.7c1.5,1.4,3.1,2.8,4.8,4c4.7,19.1,19.2,55.4,61.1,57.6c11.6,0.6,23.3-1.4,34.5-5c0.1,0.2,0.1,0.4,0.2,0.6
                        c1.1,2.5,6.6,6.2,6.6,6.2s-2.9-5,0.1-9.2c2.8-1.1,5.5-2.2,8.2-3.4c4.5,1.5,7.2,6.3,7.7,7.4c0.1,0.1,0.1,0.2,0.1,0.2h1.5
                        c-1-14.2,14.4-9.3,15.6-8.5c1.1,0.8,4.4,3.1,5.7,6.6h2c0,0-1-4.8,1.7-7.9c2-2.3,3.5-2.4,3.5-2.4c-0.2-0.3-5-7-8-7.3
                        c-2.3-0.2-4.3,0.2-7.4-0.2c15.4-9.7,29.6-21.1,42.5-33.9c0,0,26.1-20.4,45.6-9.6C431.6,446.9,431,441.8,426.9,435.5z M162.5,496.1
                        L162.5,496.1L162.5,496.1L162.5,496.1z M184.9,499L184.9,499L184.9,499L184.9,499z M295.7,464.8c-3.6-1.7-7-3.8-10-6.5
                        c3.1-1.8,6-4,8.6-6.5c1.1,3.6,1.4,8.3,1.4,12.5C295.7,464.5,295.7,464.7,295.7,464.8z M327.1,462.3c-1.9,1.4-4,2.5-6.2,3.4
                        c-0.5-1.3-0.9-2.6-1.2-3.8c2.1-0.5,4.1-1.6,5.7-3.1l0,0l0.3-0.3c1.5,0.6,3.1,1.1,4.7,1.5L327.1,462.3z"/>
                    <path fill="#BBD354" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                    <path fill="none" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                    <path fill="none" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                    <path fill="#F5F5F5" d="M355.4,500.3c-2.7,3.1-1.7,7.9-1.7,7.9s21.8,0.4,21.6,0c-2.1-6-12.5-9.5-16.4-10.3l0,0
                        C358.9,497.9,357.5,498,355.4,500.3z"/>
                    <path fill="#F5F5F5" d="M354.2,517.3c0.6-0.5-1.5-6.6-2.5-9.1l0,0c-1.3-3.5-4.6-5.8-5.7-6.6c-1.1-0.8-16.6-5.7-15.6,8.5
                        C332.6,512.7,353.6,517.9,354.2,517.3z"/>
                    <path fill="#F5F5F5" d="M319.4,502.1c-1.7-0.2-4.5,1.1-6.4,3.7c-3.2,4.2-0.2,9.4-0.2,9.4c7.2,2.3,19,5.1,19.5,4.8s-2.3-7.3-3.6-10
                        C328.2,508.8,324.9,502.9,319.4,502.1z"/>
                    <path fill="#BBD354" d="M337.3,496.1L337.3,496.1L337.3,496.1L337.3,496.1z"/>
                    <path fill="none" d="M337.4,496.1L337.4,496.1L337.4,496.1L337.4,496.1z"/>
                    <path fill="none" d="M337.4,496.1L337.4,496.1L337.4,496.1L337.4,496.1z"/>
                    <path fill="#EAEAEA" d="M320.7,503.7c0,0-4,0.9-2.7,1.3c5.1,1.4,11.4,10.5,11.8,10.4S326.3,505.2,320.7,503.7z"/>
                    <path fill="#EAEAEA" d="M342.8,501.7c0,0-4,0.9-2.7,1.3c5.1,1.4,11.4,10.5,11.8,10.4S348.5,503.2,342.8,501.7z"/>
                    <path fill="#EAEAEA" d="M359.1,498.4c-0.8,0.4-1.5,1-2,1.8c0,0,10.6,2.4,14.5,4.4S368.4,500.4,359.1,498.4z"/>
                    <path fill="#F5F5F5" d="M144.4,500.3c2.7,3.1,1.8,7.9,1.8,7.9s-21.8,0.4-21.6,0c2.1-6,12.5-9.5,16.4-10.3l0,0
                        C141,497.9,142.4,498,144.4,500.3z"/>
                    <path fill="#F5F5F5" d="M145.7,517.3c-0.6-0.5,1.5-6.6,2.5-9.1l0,0c1.3-3.5,4.6-5.8,5.7-6.6c1.1-0.8,16.6-5.7,15.6,8.5
                        C167.3,512.7,146.3,517.9,145.7,517.3z"/>
                    <path fill="#F5F5F5" d="M180.5,502.1c1.7-0.2,4.5,1.1,6.4,3.7c3.2,4.2,0.2,9.4,0.2,9.4c-7.2,2.3-19,5.1-19.5,4.8
                        c-0.5-0.3,2.3-7.3,3.6-10C171.7,508.8,174.9,502.9,180.5,502.1z"/>
                    <path fill="#BBD354" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                    <path fill="#BBD354" d="M185,499L185,499L185,499L185,499z"/>
                    <path fill="none" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                    <path fill="none" d="M184.9,499L184.9,499L184.9,499L184.9,499z"/>
                    <path fill="none" d="M184.9,499L184.9,499L184.9,499L184.9,499z"/>
                    <path fill="none" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                    <path fill="#EAEAEA" d="M179.2,503.7c0,0,4,0.9,2.7,1.3c-5.1,1.4-11.4,10.5-11.8,10.4S173.5,505.2,179.2,503.7z"/>
                    <path fill="#EAEAEA" d="M157,501.7c0,0,4,0.9,2.7,1.3c-5.1,1.4-11.4,10.5-11.8,10.4C147.6,513.3,151.4,503.2,157,501.7z"/>
                    <path fill="#EAEAEA" d="M140.8,498.4c0.8,0.4,1.5,1,2,1.8c0,0-10.6,2.4-14.5,4.4S131.5,500.4,140.8,498.4z"/>
                    <path opacity="0.3" fill="#E0E0E0" enable-background="new    " d="M323,242.6c1.8-8.4,5.4-16.2,6.3-24.6c0.1-2.7-0.2-5.4-1-7.9
                        c-3.4-11-14.2-24.4-28.2-35.3c-14.7-11.4-32.9-20.1-49.6-20.1l0,0c-16.7,0-34.9,8.6-49.6,20.1c-14.2,11-25.1,24.7-28.3,35.8
                        c-0.8,2.4-1.1,5-0.9,7.5c0.9,8.4,4.5,16.2,6.3,24.6c0.1,0.8,0,1.7-0.1,2.5c-0.4,4-2.2,6.7,0.1,10.7c4.1,9.5,13.9,15.1,25.8,18.2
                        c7.2,1.8,14.6,2.9,22.1,3.3c8.2,0.5,16.5,0.5,24.7,0.1l0,0c8.2,0.4,16.5,0.4,24.7-0.1c7.4-0.4,14.8-1.5,22-3.3
                        c12-3.1,21.8-8.7,25.9-18.2c2.2-4,0.5-6.7,0.1-10.7C323,244.3,323,243.5,323,242.6z"/>
                    <path fill="#414042" d="M198,207.3c0.1-0.8,0.4-1.7,0.7-2.5c0.4-1.1,1-2.1,1.6-3.1c1.8,2.2,5.6,5,3.9,8.3c-0.5,1-1.4,2.2-1.8,3.3
                        c-1.3-1.2-2.5-2.4-3.6-3.8C198.2,208.9,197.9,208.1,198,207.3z"/>
                    <path fill="#383839" d="M302.9,207.3c-0.1-0.8-0.4-1.7-0.7-2.5c-0.4-1.1-1-2.1-1.5-3.1c-1.8,2.2-5.6,5-3.9,8.3
                        c0.5,1,1.4,2.2,1.8,3.3c1.3-1.2,2.5-2.4,3.6-3.8C302.8,208.9,303,208.1,302.9,207.3z"/>
                    <path fill="#F5F5F5" d="M208.2,275.1c-1.5-0.3-3.1-0.7-4.6-1c-5.6-1.4-11-3.6-15.9-6.7c4.1,10.6,22.3,30.3,20.5,19.1
                        C207.7,282.7,207.7,278.9,208.2,275.1z"/>
                    <path fill="#F5F5F5" d="M297.2,274.1c-1.5,0.4-3,0.7-4.5,1c0.5,3.8,0.5,7.6,0,11.4c-1.8,11.2,16.4-8.5,20.5-19.2
                        C308.2,270.5,302.8,272.7,297.2,274.1z"/>
                    <path fill="#FF5E8E" d="M177.8,255.8c-0.2-0.4-0.4-0.7-0.5-1.1c-0.3,0.1-0.7,0.4-0.9,0.7c-0.7,0.7-0.7,1.9,0,2.7c0,0,0,0,0,0
                        c0.3,0.2,0.6,0.3,0.9,0.3c0.5,0,1-0.2,1.4-0.6C178.4,257.1,178.1,256.5,177.8,255.8z"/>
                    <path fill="#FF3672" d="M174.6,310.3c-1.3-0.2-2.5-0.4-3.7-0.6C172.1,310,173.3,310.2,174.6,310.3z"/>
                    <path fill="#FF3672" d="M187.7,267.3c-4.4-2.8-7.8-6.8-9.9-11.5c-2.2-4-0.5-6.7-0.1-10.7c0.1-0.5,0.1-1,0.1-1.5
                        c-1.9,0.2-3.7,0.7-5.4,1.6c-17.6,8.5-14.6,52.5-10.8,59.4c1.2,2.1,4.7,4,8.8,5c2.4-0.1,4.4-1.7,4.6-3.2c0-0.3,0.1-0.5,0.3-0.5
                        c0.8,0,3,3,6.3,4.1c2.5-0.4,4.7-1.6,6.5-3.4C191.1,303,188.9,280.5,187.7,267.3z"/>
                    <path fill="#FF86AA" d="M170.4,309.6l0.4,0.1L170.4,309.6L170.4,309.6z"/>
                    <path fill="#FF86AA" d="M175,306.4c-0.2,1.5-2.2,3.1-4.6,3.2l0.4,0.1c1.2,0.3,2.5,0.5,3.7,0.6c2.3,0.3,4.7,0.2,7-0.3
                        c-3.3-1.1-5.4-4.1-6.3-4.1C175.2,306,175.1,306.1,175,306.4z"/>
                    <path fill="#FF5E8E" d="M164.5,269.4L164.5,269.4c-0.7,0-2.5,5.6-2.7,11.8c-0.2,5,0.8,9.2,1.5,9.2c0.1,0,0.1,0,0.1-0.1
                        c1.3-1.2,0.8-5.4,0.9-10.6C164.5,274.4,165.3,269.4,164.5,269.4"/>
                    <path fill="#FF5E8E" d="M164.4,293.4c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.5-0.7,2.3-0.5,4c0.2,1.4,0.6,2.2,1,2.2c0.1,0,0.2-0.1,0.3-0.1
                        c0.5-0.5,0.7-2.3,0.5-4C165.2,294.3,164.8,293.4,164.4,293.4"/>
                    <path fill="#FFFFFF" d="M330.5,160c10.9,0,19.8,8.9,19.8,19.8c0,10.9-8.9,19.8-19.8,19.8c-10.9,0-19.8-8.9-19.8-19.8c0,0,0,0,0,0
                        C310.7,168.8,319.6,160,330.5,160"/>
                    <path fill="#662F00" d="M323.5,172.4c4,0,7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2s-7.2-3.2-7.2-7.2S319.6,172.4,323.5,172.4z"/>
                    <path fill="#FFFFFF" d="M333,179.6h-4.8c-1.5,0-2.8-1.2-2.8-2.8v0c0-1.5,1.2-2.8,2.8-2.8h4.8c1.5,0,2.8,1.2,2.8,2.8v0
                        C335.7,178.3,334.5,179.6,333,179.6z"/>
                    <path fill="#FFFFFF" d="M169.2,160c-10.9,0-19.8,8.9-19.8,19.8c0,10.9,8.9,19.8,19.8,19.8c10.9,0,19.8-8.9,19.8-19.8c0,0,0,0,0,0
                        C189,168.8,180.1,160,169.2,160"/>
                    <path fill="#662F00" d="M176.2,172.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S180.1,172.4,176.2,172.4z"/>
                    <path fill="#FFFFFF" d="M166.7,174h4.8c1.5,0,2.8,1.2,2.8,2.8v0c0,1.5-1.2,2.8-2.8,2.8h-4.8c-1.5,0-2.8-1.2-2.8-2.8v0
                        C163.9,175.3,165.2,174,166.7,174z"/>
                    <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M202.2,452.6c0.6-0.8,1.1-1.7,1.6-2.5l-0.3-0.3
                        c-2.5-2.6-4.7-5.4-6.6-8.3c-4.2-6.4-7.1-13.5-8.6-20.9c-0.3-1.5-0.5-3-0.6-4.5c0-0.5-0.1-1-0.1-1.5c-4.1,4.7-9.2,8.4-15,10.8
                        c0,0,18.6,20.3,0,17.7c0,0,6,18-26.2,12.1l0.2,0.2c10.1,8.3,21.7,5.6,27.7,3.1l0.3,0.3l0.9-0.3l6.9-5.5l2.5-2l0.3,9.2l8.2-11.4
                        c0,0,3,1.1,7.4,9.9c2,4,4.3,14.1,4.3,14.1s-1.4-12.9-2.2-17.3C202.7,454.6,202.4,453.6,202.2,452.6z"/>
                    <path opacity="0.4" fill="#353535" enable-background="new    " d="M426.9,435.5c-3.5-1.9-18.6-9.2-38.5-3
                        c-11.2,3.5-22.5,11.9-32.1,20c-3.6,2.9-6.9,6-11.6,10.2c-3.4,3-6.6,5.9-9,7.5c-3.3,2.1-6.9,3.8-10.6,5.1c0,0,9.6,13.1,18.5,15
                        c15.4-9.7,29.6-21.1,42.5-33.9c0,0,26.1-20.4,45.6-9.6C431.6,446.9,431,441.8,426.9,435.5z M337.4,496.1L337.4,496.1L337.4,496.1
                        L337.4,496.1z"/>
                    <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M356.3,452.6c9.6-8.1,20.9-16.5,32.1-20
                        c19.8-6.2,34.9,1.1,38.5,3c-3.6-3.6-7.9-6.5-12.5-8.4c-14.5-7-32.3-0.2-52.3,11.9C361.6,444,359.6,448.7,356.3,452.6z"/>
                    <path opacity="0.4" fill="#353535" enable-background="new    " d="M305.6,498.1c-2-4.2-3.6-8.5-4.9-12.9c-4.6-4.7-4-5.3-4-5.3
                        c-6.3-1.7-12.6-5.7-13.2-13c-0.3-4.3,0.9-6.6,2.8-7.8l-0.7-0.9c-5.2,3.1-11,5.2-17,6.1l0,0c-10.9,2.3-17.8,2.1-18.6,2.1
                        c-0.9,0-7.6,0.3-18.5-2c-0.1,0-0.2,0-0.3,0c-7.5-1.2-14.7-4.1-20.9-8.6c4.7,19.1,19.2,55.4,61.1,57.6c11.6,0.6,23.3-1.4,34.5-5
                        L305.6,498.1z"/>
                    <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M320.9,465.7c1.1,3.3,2.5,6.6,4.1,9.7c3-1.2,5.9-2.5,8.8-4
                        c3.7-2,9.9-7.8,17.5-14.5c-7.4,4.9-15.2,4.6-20.9,3.1l-3.2,2.3C325.2,463.7,323.1,464.8,320.9,465.7z"/>
                    <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M327.3,443.1c-7.6,1-9-1.7-8-5.3c4.5,3.2,9.6,4,15.1,1.2
                        c3.9-2,6.1-5.3,7.2-9.6c-1.3,2.9-3.2,5.1-6.2,6.6c-5.5,2.8-10.4,2-14.9-1.1c-3.5-2.4-6.7-6.3-9.7-11.1
                        c-13.5-22.1-20.4-62.6-20.4-62.6l-0.6-11l-0.4,14c0,0,6.9,40.7,20.5,62.8c-1.6,5.1-3.9,10-6.9,14.5c-1.9,3-4.2,5.8-6.6,8.3
                        l-0.3,0.3c0.5,0.8,1.1,1.7,1.6,2.5c-0.3,1-0.5,2-0.8,3c-0.9,4.4-2.2,17.3-2.2,17.3s2.3-10.1,4.3-14.1c4.4-8.8,7.4-9.9,7.4-9.9
                        l8.2,11.4l0.3-9.2l2.5,2l6.9,5.5l0.9,0.3l0.3-0.3c5.5,2.3,15.9,4.8,25.5-1.5c0.7-0.5,1.4-1.1,2-1.7
                        C321.4,460.9,327.3,443.1,327.3,443.1z"/>
                    <path fill="#E0E0E0" d="M204.5,423.6c0.7,8.2,6.1,15.3,13.8,18.1l0,0c10.2,3.7,20.9,6.2,31.6,6.2c10.7,0,21.4-2.5,31.6-6.2l0,0
                        c7.8-2.8,13.2-9.9,13.8-18.1c2.7-32.6,4.1-107.9-45.5-108C200.4,315.7,201.8,391,204.5,423.6z"/>
                    <path fill="#6EE5A6" d="M183.3,309.6c-3.8,1.2-8.6,1-12.8,0c1.3,0.5,4.3,2,5.3,5.1l0,0c0.4,1.3,0.5,2.6,0.2,3.9
                        c-1.2,6.6-3.8,17.4,1.5,17.4c0.4,0,0.7,0,1.1-0.1c3.5-0.8,2.3-12.1,0.3-17.7c-0.4-1.3-0.4-2.6,0.1-3.9
                        C180.2,312.6,181.7,311,183.3,309.6z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M179.2,326.9c-0.4,0-0.4,1.6-0.6,3.6c-0.2,2-0.5,3.6-0.1,3.6
                        c0.4,0,1.5-1.6,1.6-3.6C180.4,328.5,179.7,326.9,179.2,326.9z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M178.5,323.8c-0.4,0.5-0.5,1.1-0.2,1.7c0.2,0.6,0.6,0.9,0.9,0.6
                        c0.4-0.5,0.4-1.1,0.2-1.7C179.2,323.8,178.8,323.4,178.5,323.8z"/>
                </svg>
               
                return teen;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen= <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height} >
                        <path fill={backGroundColor} d="M426.9,435.5c-3.6-3.6-7.9-6.5-12.5-8.4c-14.5-7-32.3-0.2-52.3,11.9c0.4-4.4,0-8.9-1.2-13.2
                            c-3.2-25.5-21.1-32.1-21.1-32.1c-0.2-0.2-0.5-0.5-0.7-0.7c-3.8-15.5-8.9-33.7-11.1-52.4c-2.5-21-21.4-26.6-32.3-28.1
                            c-0.5-0.3-1-0.5-1.5-0.8l-0.1-0.1l-10.3-3.7l-2-6.7c33.1-5.1,54.5-18.7,66-39.5c4-7.4,6.9-15.5,8.4-23.8c0.4-2.1,0.8-4.3,1-6.5
                            c0.3-2.1,0.5-4.2,0.6-6.3c0.2-2.2,0.2-4.1,0.3-6.2c7.8-2.7,12.8-7.4,11.8-13.4c-2.3-14-16.8-20.8-7.4-42.6c7.3-16.9,8-18.7,8-18.7
                            s16.9-20.4-11.9-18.6c-17.1,1.1-44.2,3.6-64.5,5.7c-28.1-13-60.6-13-88.7,0c-20.3-2-47.4-4.6-64.5-5.7
                            c-28.8-1.8-11.9,18.6-11.9,18.6s0.7,1.8,8.1,18.7c9.4,21.8-5.1,28.7-7.4,42.6c-1,6,4,10.8,11.8,13.4c0.1,4.2,0.4,8.2,0.9,12.1
                            c0.3,2.3,0.6,4.6,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9c11.6,20.9,33.1,34.5,66.3,39.6l-2,6.7l-10.3,3.7c-0.5,0.2-1,0.5-1.5,0.8
                            c-10.9,1.4-30,7-32.5,28.1c-2.2,18.7-7.4,36.9-11.1,52.4c-0.3,0.3-0.5,0.5-0.8,0.8c0,0-17.8,6.6-21.1,32.1c0,0-5.5,16.8,5.2,27.6
                            c0.7,0.7,1.5,1.4,2.3,2.1c10.1,8.3,21.7,5.6,27.7,3.1l0.3,0.3c1.6,1.5,3.6,2.6,5.7,3.1c-3,10.1-9,24.4-20.2,27.7
                            c-5.3,1.6-8,0.7-11.1,1c-3,0.3-7.7,7-8,7.3c0,0,1.4,0.1,3.5,2.4c2.7,3.1,1.8,7.9,1.8,7.9h2c1.3-3.5,4.6-5.8,5.7-6.6
                            c1.1-0.8,16.6-5.7,15.6,8.5h1.5l0.1-0.2c0.6-1.2,3.9-7.2,9.4-7.8c1.7-0.2,4.5,1.1,6.4,3.7c3.2,4.2,0.2,9.4,0.2,9.4s5.5-3.6,6.6-6.2
                            c1.1-2.5,1.6-10.9,1.6-10.9c3-4.9,4.3-12.7,4.3-12.7c4.9-6.6,5.9-10.6,5.9-10.6s-0.1-0.8-0.2-2.1v0.2c0,0-1.4-12.9-2.2-17.3
                            c-0.2-1-0.5-2-0.8-3c0.6-0.8,1.1-1.7,1.6-2.5c0.6,0.6,1.1,1.2,1.8,1.7c1.5,1.4,3.1,2.8,4.8,4c4.7,19.1,19.2,55.4,61.1,57.6
                            c11.6,0.6,23.3-1.4,34.5-5c0.1,0.2,0.1,0.4,0.2,0.6c1.1,2.5,6.6,6.2,6.6,6.2s-2.9-5,0.1-9.2c2.8-1.1,5.5-2.2,8.2-3.4
                            c4.5,1.5,7.2,6.3,7.7,7.4c0.1,0.1,0.1,0.2,0.1,0.2h1.5c-1-14.2,14.4-9.3,15.6-8.5c1.1,0.8,4.4,3.1,5.7,6.6h2c0,0-1-4.8,1.7-7.9
                            c2-2.3,3.5-2.4,3.5-2.4c-0.2-0.3-5-7-8-7.3c-2.3-0.2-4.3,0.2-7.4-0.2c15.4-9.7,29.6-21.1,42.5-33.9c0,0,26.1-20.4,45.6-9.6
                            C431.6,446.9,431,441.8,426.9,435.5z M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z M184.9,499L184.9,499L184.9,499L184.9,499
                            z M295.7,464.8c-3.6-1.7-7-3.8-10-6.5c3.1-1.8,6-4,8.6-6.5c1.1,3.6,1.4,8.3,1.4,12.5C295.7,464.5,295.7,464.7,295.7,464.8z
                            M327.1,462.3c-1.9,1.4-4,2.5-6.2,3.4c-0.5-1.3-0.9-2.6-1.2-3.8c2.1-0.5,4.1-1.6,5.7-3.1l0,0l0.3-0.3c1.5,0.6,3.1,1.1,4.7,1.5
                            L327.1,462.3z"/>
                        <path fill="#BBD354" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                        <path fill="none" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                        <path fill="none" d="M315.5,499L315.5,499L315.5,499L315.5,499z"/>
                        <path fill="#F5F5F5" d="M355.4,500.3c-2.7,3.1-1.7,7.9-1.7,7.9s21.8,0.4,21.6,0c-2.1-6-12.5-9.5-16.4-10.3l0,0
                            C358.9,497.9,357.5,498,355.4,500.3z"/>
                        <path fill="#F5F5F5" d="M354.2,517.3c0.6-0.5-1.5-6.6-2.5-9.1l0,0c-1.3-3.5-4.6-5.8-5.7-6.6c-1.1-0.8-16.6-5.7-15.6,8.5
                            C332.6,512.7,353.6,517.9,354.2,517.3z"/>
                        <path fill="#F5F5F5" d="M319.4,502.1c-1.7-0.2-4.5,1.1-6.4,3.7c-3.2,4.2-0.2,9.4-0.2,9.4c7.2,2.3,19,5.1,19.5,4.8s-2.3-7.3-3.6-10
                            C328.2,508.8,324.9,502.9,319.4,502.1z"/>
                        <path fill="#BBD354" d="M337.3,496.1L337.3,496.1L337.3,496.1L337.3,496.1z"/>
                        <path fill="none" d="M337.4,496.1L337.4,496.1L337.4,496.1L337.4,496.1z"/>
                        <path fill="none" d="M337.4,496.1L337.4,496.1L337.4,496.1L337.4,496.1z"/>
                        <path fill="#EAEAEA" d="M320.7,503.7c0,0-4,0.9-2.7,1.3c5.1,1.4,11.4,10.5,11.8,10.4S326.3,505.2,320.7,503.7z"/>
                        <path fill="#EAEAEA" d="M342.8,501.7c0,0-4,0.9-2.7,1.3c5.1,1.4,11.4,10.5,11.8,10.4S348.5,503.2,342.8,501.7z"/>
                        <path fill="#EAEAEA" d="M359.1,498.4c-0.8,0.4-1.5,1-2,1.8c0,0,10.6,2.4,14.5,4.4S368.4,500.4,359.1,498.4z"/>
                        <path fill="#F5F5F5" d="M144.4,500.3c2.7,3.1,1.8,7.9,1.8,7.9s-21.8,0.4-21.6,0c2.1-6,12.5-9.5,16.4-10.3l0,0
                            C141,497.9,142.4,498,144.4,500.3z"/>
                        <path fill="#F5F5F5" d="M145.7,517.3c-0.6-0.5,1.5-6.6,2.5-9.1l0,0c1.3-3.5,4.6-5.8,5.7-6.6c1.1-0.8,16.6-5.7,15.6,8.5
                            C167.3,512.7,146.3,517.9,145.7,517.3z"/>
                        <path fill="#F5F5F5" d="M180.5,502.1c1.7-0.2,4.5,1.1,6.4,3.7c3.2,4.2,0.2,9.4,0.2,9.4c-7.2,2.3-19,5.1-19.5,4.8
                            c-0.5-0.3,2.3-7.3,3.6-10C171.7,508.8,174.9,502.9,180.5,502.1z"/>
                        <path fill="#BBD354" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                        <path fill="#BBD354" d="M185,499L185,499L185,499L185,499z"/>
                        <path fill="none" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                        <path fill="none" d="M184.9,499L184.9,499L184.9,499L184.9,499z"/>
                        <path fill="none" d="M184.9,499L184.9,499L184.9,499L184.9,499z"/>
                        <path fill="none" d="M162.5,496.1L162.5,496.1L162.5,496.1L162.5,496.1z"/>
                        <path fill="#EAEAEA" d="M179.2,503.7c0,0,4,0.9,2.7,1.3c-5.1,1.4-11.4,10.5-11.8,10.4S173.5,505.2,179.2,503.7z"/>
                        <path fill="#EAEAEA" d="M157,501.7c0,0,4,0.9,2.7,1.3c-5.1,1.4-11.4,10.5-11.8,10.4C147.6,513.3,151.4,503.2,157,501.7z"/>
                        <path fill="#EAEAEA" d="M140.8,498.4c0.8,0.4,1.5,1,2,1.8c0,0-10.6,2.4-14.5,4.4S131.5,500.4,140.8,498.4z"/>
                        <path opacity="0.3" fill="#E0E0E0" enable-background="new    " d="M323,242.6c1.8-8.4,5.4-16.2,6.3-24.6c0.1-2.7-0.2-5.4-1-7.9
                            c-3.4-11-14.2-24.4-28.2-35.3c-14.7-11.4-32.9-20.1-49.6-20.1l0,0c-16.7,0-34.9,8.6-49.6,20.1c-14.2,11-25.1,24.7-28.3,35.8
                            c-0.8,2.4-1.1,5-0.9,7.5c0.9,8.4,4.5,16.2,6.3,24.6c0.1,0.8,0,1.7-0.1,2.5c-0.4,4-2.2,6.7,0.1,10.7c4.1,9.5,13.9,15.1,25.8,18.2
                            c7.2,1.8,14.6,2.9,22.1,3.3c8.2,0.5,16.5,0.5,24.7,0.1l0,0c8.2,0.4,16.5,0.4,24.7-0.1c7.4-0.4,14.8-1.5,22-3.3
                            c12-3.1,21.8-8.7,25.9-18.2c2.2-4,0.5-6.7,0.1-10.7C323,244.3,323,243.5,323,242.6z"/>
                        <path fill="#414042" d="M198,207.3c0.1-0.8,0.4-1.7,0.7-2.5c0.4-1.1,1-2.1,1.6-3.1c1.8,2.2,5.6,5,3.9,8.3c-0.5,1-1.4,2.2-1.8,3.3
                            c-1.3-1.2-2.5-2.4-3.6-3.8C198.2,208.9,197.9,208.1,198,207.3z"/>
                        <path fill="#383839" d="M302.9,207.3c-0.1-0.8-0.4-1.7-0.7-2.5c-0.4-1.1-1-2.1-1.5-3.1c-1.8,2.2-5.6,5-3.9,8.3
                            c0.5,1,1.4,2.2,1.8,3.3c1.3-1.2,2.5-2.4,3.6-3.8C302.8,208.9,303,208.1,302.9,207.3z"/>
                        <path fill="#F5F5F5" d="M208.2,275.1c-1.5-0.3-3.1-0.7-4.6-1c-5.6-1.4-11-3.6-15.9-6.7c4.1,10.6,22.3,30.3,20.5,19.1
                            C207.7,282.7,207.7,278.9,208.2,275.1z"/>
                        <path fill="#F5F5F5" d="M297.2,274.1c-1.5,0.4-3,0.7-4.5,1c0.5,3.8,0.5,7.6,0,11.4c-1.8,11.2,16.4-8.5,20.5-19.2
                            C308.2,270.5,302.8,272.7,297.2,274.1z"/>
                        <path fill="#FF5E8E" d="M177.8,255.8c-0.2-0.4-0.4-0.7-0.5-1.1c-0.3,0.1-0.7,0.4-0.9,0.7c-0.7,0.7-0.7,1.9,0,2.7c0,0,0,0,0,0
                            c0.3,0.2,0.6,0.3,0.9,0.3c0.5,0,1-0.2,1.4-0.6C178.4,257.1,178.1,256.5,177.8,255.8z"/>
                        <path fill="#FF3672" d="M174.6,310.3c-1.3-0.2-2.5-0.4-3.7-0.6C172.1,310,173.3,310.2,174.6,310.3z"/>
                        <path fill="#FF3672" d="M187.7,267.3c-4.4-2.8-7.8-6.8-9.9-11.5c-2.2-4-0.5-6.7-0.1-10.7c0.1-0.5,0.1-1,0.1-1.5
                            c-1.9,0.2-3.7,0.7-5.4,1.6c-17.6,8.5-14.6,52.5-10.8,59.4c1.2,2.1,4.7,4,8.8,5c2.4-0.1,4.4-1.7,4.6-3.2c0-0.3,0.1-0.5,0.3-0.5
                            c0.8,0,3,3,6.3,4.1c2.5-0.4,4.7-1.6,6.5-3.4C191.1,303,188.9,280.5,187.7,267.3z"/>
                        <path fill="#FF86AA" d="M170.4,309.6l0.4,0.1L170.4,309.6L170.4,309.6z"/>
                        <path fill="#FF86AA" d="M175,306.4c-0.2,1.5-2.2,3.1-4.6,3.2l0.4,0.1c1.2,0.3,2.5,0.5,3.7,0.6c2.3,0.3,4.7,0.2,7-0.3
                            c-3.3-1.1-5.4-4.1-6.3-4.1C175.2,306,175.1,306.1,175,306.4z"/>
                        <path fill="#FF5E8E" d="M164.5,269.4L164.5,269.4c-0.7,0-2.5,5.6-2.7,11.8c-0.2,5,0.8,9.2,1.5,9.2c0.1,0,0.1,0,0.1-0.1
                            c1.3-1.2,0.8-5.4,0.9-10.6C164.5,274.4,165.3,269.4,164.5,269.4"/>
                        <path fill="#FF5E8E" d="M164.4,293.4c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.5-0.7,2.3-0.5,4c0.2,1.4,0.6,2.2,1,2.2c0.1,0,0.2-0.1,0.3-0.1
                            c0.5-0.5,0.7-2.3,0.5-4C165.2,294.3,164.8,293.4,164.4,293.4"/>
                        <path fill="#FFFFFF" d="M330.5,160c10.9,0,19.8,8.9,19.8,19.8c0,10.9-8.9,19.8-19.8,19.8c-10.9,0-19.8-8.9-19.8-19.8c0,0,0,0,0,0
                            C310.7,168.8,319.6,160,330.5,160"/>
                        <path fill="#662F00" d="M323.5,172.4c4,0,7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2s-7.2-3.2-7.2-7.2S319.6,172.4,323.5,172.4z"/>
                        <path fill="#FFFFFF" d="M333,179.6h-4.8c-1.5,0-2.8-1.2-2.8-2.8v0c0-1.5,1.2-2.8,2.8-2.8h4.8c1.5,0,2.8,1.2,2.8,2.8v0
                            C335.7,178.3,334.5,179.6,333,179.6z"/>
                        <path fill="#FFFFFF" d="M169.2,160c-10.9,0-19.8,8.9-19.8,19.8c0,10.9,8.9,19.8,19.8,19.8c10.9,0,19.8-8.9,19.8-19.8c0,0,0,0,0,0
                            C189,168.8,180.1,160,169.2,160"/>
                        <path fill="#662F00" d="M176.2,172.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S180.1,172.4,176.2,172.4z"/>
                        <path fill="#FFFFFF" d="M166.7,174h4.8c1.5,0,2.8,1.2,2.8,2.8v0c0,1.5-1.2,2.8-2.8,2.8h-4.8c-1.5,0-2.8-1.2-2.8-2.8v0
                            C163.9,175.3,165.2,174,166.7,174z"/>
                        <path opacity="0.4" fill="#353535" enable-background="new    " d="M426.9,435.5c-3.5-1.9-18.6-9.2-38.5-3
                            c-11.2,3.5-22.5,11.9-32.1,20c-3.6,2.9-6.9,6-11.6,10.2c-3.4,3-6.6,5.9-9,7.5c-3.3,2.1-6.9,3.8-10.6,5.1c0,0,9.6,13.1,18.5,15
                            c15.4-9.7,29.6-21.1,42.5-33.9c0,0,26.1-20.4,45.6-9.6C431.6,446.9,431,441.8,426.9,435.5z M337.4,496.1L337.4,496.1L337.4,496.1
                            L337.4,496.1z"/>
                        <path fill="#6EE5A6" d="M183.3,309.6c-3.8,1.2-8.6,1-12.8,0c1.3,0.5,4.3,2,5.3,5.1l0,0c0.4,1.3,0.5,2.6,0.2,3.9
                            c-1.2,6.6-3.8,17.4,1.5,17.4c0.4,0,0.7,0,1.1-0.1c3.5-0.8,2.3-12.1,0.3-17.7c-0.4-1.3-0.4-2.6,0.1-3.9
                            C180.2,312.6,181.7,311,183.3,309.6z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M179.2,326.9c-0.4,0-0.4,1.6-0.6,3.6c-0.2,2-0.5,3.6-0.1,3.6
                            c0.4,0,1.5-1.6,1.6-3.6C180.4,328.5,179.7,326.9,179.2,326.9z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M178.5,323.8c-0.4,0.5-0.5,1.1-0.2,1.7c0.2,0.6,0.6,0.9,0.9,0.6
                            c0.4-0.5,0.4-1.1,0.2-1.7C179.2,323.8,178.8,323.4,178.5,323.8z"/>
                        <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M356.3,452.6c9.6-8.1,20.9-16.5,32.1-20
                            c19.8-6.2,34.9,1.1,38.5,3c-3.6-3.6-7.9-6.5-12.5-8.4c-14.5-7-32.3-0.2-52.3,11.9C361.6,444,359.6,448.7,356.3,452.6z"/>
                        <path opacity="0.4" fill="#353535" enable-background="new    " d="M305.6,498.1c-2-4.2-3.6-8.5-4.9-12.9c-4.6-4.7-4-5.3-4-5.3
                            c-6.3-1.7-12.6-5.7-13.2-13c-0.3-4.3,0.9-6.6,2.8-7.8l-0.7-0.9c-5.2,3.1-11,5.2-17,6.1l0,0c-10.9,2.3-17.8,2.1-18.6,2.1
                            c-0.9,0-7.6,0.3-18.5-2c-0.1,0-0.2,0-0.3,0c-7.5-1.2-14.7-4.1-20.9-8.6c4.7,19.1,19.2,55.4,61.1,57.6c11.6,0.6,23.3-1.4,34.5-5
                            L305.6,498.1z"/>
                        <path opacity="0.3" fill="#EAEAEA" enable-background="new    " d="M320.9,465.7c1.1,3.3,2.5,6.6,4.1,9.7c3-1.2,5.9-2.5,8.8-4
                            c3.7-2,9.9-7.8,17.5-14.5c-7.4,4.9-15.2,4.6-20.9,3.1l-3.2,2.3C325.2,463.7,323.1,464.8,320.9,465.7z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M202.2,452.6c0.6-0.8,1.1-1.7,1.6-2.5l-0.3-0.3
                            c-2.5-2.6-4.7-5.4-6.6-8.3c-2.9-4.5-5.2-9.3-6.9-14.4c13.6-22,20.5-62.9,20.5-62.9l-1-3.4l0,0.4c0,0-6.9,40.7-20.5,62.7
                            c-2.9,4.7-6.1,8.6-9.7,11c-4.5,3.1-9.4,3.8-14.9,1c-2.9-1.5-4.9-3.8-6.2-6.6c1,4.3,3.3,7.6,7.2,9.6c5.5,2.8,10.5,2,15-1.1
                            c0.9,3.6-0.5,6.2-8,5.2c0,0,6,18-26.2,12.1l0.2,0.2c10.1,8.3,21.7,5.6,27.7,3.1l0.3,0.3l0.9-0.3l6.9-5.5l2.5-2l0.3,9.2l8.2-11.4
                            c0,0,3,1.1,7.4,9.9c2,4,4.3,14.1,4.3,14.1s-1.4-12.9-2.2-17.3C202.7,454.6,202.4,453.6,202.2,452.6z"/>
                        <path opacity="0.3" fill="#1C1C1C" enable-background="new    " d="M327.3,443.1c-7.6,1-9-1.7-8-5.3c4.5,3.2,9.6,4,15.1,1.2
                            c3.9-2,6.1-5.3,7.2-9.6c-1.3,2.9-3.2,5.1-6.2,6.6c-5.5,2.8-10.4,2-14.9-1.1c-3.5-2.4-6.7-6.3-9.7-11.1
                            c-13.5-22.1-20.4-62.6-20.4-62.6l-0.6-11l-0.4,14c0,0,6.9,40.7,20.5,62.8c-1.6,5.1-3.9,10-6.9,14.5c-1.9,3-4.2,5.8-6.6,8.3
                            l-0.3,0.3c0.5,0.8,1.1,1.7,1.6,2.5c-0.3,1-0.5,2-0.8,3c-0.9,4.4-2.2,17.3-2.2,17.3s2.3-10.1,4.3-14.1c4.4-8.8,7.4-9.9,7.4-9.9
                            l8.2,11.4l0.3-9.2l2.5,2l6.9,5.5l0.9,0.3l0.3-0.3c5.5,2.3,15.9,4.8,25.5-1.5c0.7-0.5,1.4-1.1,2-1.7
                            C321.4,460.9,327.3,443.1,327.3,443.1z"/>
                        <path fill="#E0E0E0" d="M204.5,423.6c0.7,8.2,6.1,15.3,13.8,18.1l0,0c10.2,3.7,20.9,6.2,31.6,6.2c10.7,0,21.4-2.5,31.6-6.2l0,0
                            c7.8-2.8,13.2-9.9,13.8-18.1c2.7-32.6,4.1-107.9-45.5-108C200.4,315.7,201.8,391,204.5,423.6z"/>
                    </svg>
        return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
        //     <>
        //     { isKid ?
        //            (  
                           
        //                 this.getkid(backGroundColor) 
                      
        //            )
                    
        //         :
        //             (
                       
        //                 this.getTeen(backGroundColor) 
                      
        //             )
        //         }
        // </>
        <>
        { isKid ?
               (  
                        withTool ?
                    this.getkidWithTool(backGroundColor)  :
                    this.getKidWithOutTool(backGroundColor)
               )
                
            :
                (
                    withTool ?
                    this.getTeenWithTool(backGroundColor) :
                    this.getTeenWithOutTool(backGroundColor)
                )
            }
    </>
        )
    }
}
