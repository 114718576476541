import React from 'react';
import './profile.scss';
import Layout from '../components/layout/layout';
import { authenticationService, childrenService, teamMemberService, adminMemberService, followesService } from '../../services';
import { Grid, Box, Typography } from '@material-ui/core';
import HeaderNav from '../components/header/headerNav';
import ArchievementsList from '../components/archievementstList/archievementsList';
import UserList from '../components/userList/userList';
import { userRole } from '../../enums';
import FormProfile from '../components/formProfile/formProfile';
import { Common } from '../../common';
import Skeleton from '@material-ui/lab/Skeleton';

const avatar = Common.getAvadarDefaut();
const titleIcon = require('../../assets/images/menuSelected.svg');

export interface Props {
    className: any,
    history: any,
    location: any,
    match: any
}

export interface State {
    userInfo: any,
    myPeople: Array<any>,
    achievements: Array<any>,
    myFriends: Array<any>,
    isLoading: boolean,
    roleId: number,
    refLeft: any,
    styleLeft: any;
    refRight: any,
    styleRight: any;

}

let currentUser;
let childUser;

class Profile extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        this.state = {
            userInfo: null,
            myPeople: [],
            achievements: [],
            myFriends: [],
            isLoading: true,
            roleId: 0,
            refLeft: React.createRef(),
            styleLeft: { paddingLeft: "35%", paddingTop: "0", position: "sticky", top: "0" },
            refRight: React.createRef(),
            styleRight: { paddingLeft: "10%", paddingTop: "0", position: "sticky", top: "0" },
        }
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        currentUser = authenticationService.currentUserValue.user;
        childUser = authenticationService.isTeamMemberChildValue;
        if (currentUser) {
            const roleIdAux = childUser ? userRole.child : currentUser.roleId;
            const userId = this.props.match.params.id ? this.props.match.params.id : childUser ? childUser.userId : currentUser.userId;
            this.setState({ roleId: roleIdAux })
            this.getData(roleIdAux, userId);
        } else {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
    }

    getData(roleId: number, userId: number) {
        followesService.getMyFriends(userId).then((data: any) => {
            if (data.success) {
                this.setState({ myFriends: data.data })
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);
        });
        switch (roleId) {
            case userRole.child:
                childrenService.getById(userId).then((data: any) => {
                    if (data.success) {
                        if (data.data.avatarFile) {
                            this.setState({ userInfo: data.data });
                        } else {
                            this.props.history.push({ pathname: `/avatar/${userId}` });
                        }
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                childrenService.getMyAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ achievements: data.data })
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                childrenService.getMyPeople(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ myPeople: data.data })
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                break;

            case userRole.teamMember:
                teamMemberService.getById(userId).then((data: any) => {
                    if (data.success) {
                        if (data.data.avatarFile) {
                            this.setState({ userInfo: data.data });
                        } else {
                            this.props.history.push({ pathname: `/avatar/${userId}` });
                        }
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                teamMemberService.getMyNiecesNephews(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ myPeople: data.data });
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                teamMemberService.getNiecesNephewsAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ achievements: data.data });
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                break;

            case userRole.Admin:
                adminMemberService.getById(userId).then((data: any) => {
                    if (data.success) {
                        if (data.data.avatarFile) {
                            this.setState({ userInfo: data.data });
                        } else {
                            this.props.history.push({ pathname: `/avatar/${userId}` });
                        }
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                adminMemberService.getNiecesNephewsAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ achievements: data.data });
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                adminMemberService.getChildrenAdminMember(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ myPeople: data.data });
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                });
                break;

            default:
                break;

        }
    }

    render() {
        const { userInfo, myFriends, myPeople, achievements, roleId, refLeft, styleLeft, refRight, styleRight } = this.state;
        const { className, location, history } = this.props;
        return (
            <div className={className}>
                <Layout className={className} currentUser={currentUser} location={location} history={this.props.history}></Layout>
                <Grid className={"header-" + className}>
                    <HeaderNav className="menu-header" currentUser={currentUser} location={location}  ></HeaderNav>
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: "65px" }}>
                    <Grid className="box-left" item xs={4}>
                        <Grid item xs style={styleLeft}>
                            <Box className="avatar">
                                <Typography className="title">
                                    <img src={titleIcon}></img>
                                    <span>My Avatar</span>
                                </Typography>
                                {userInfo ? <img src={userInfo.avatarFile ? userInfo.avatarFile : avatar} style={{ width: "70%", margin: "auto" }}></img> : null}
                            </Box>
                            <Box style={{ height: "20px" }}></Box>
                            <Box style={{ width: "100%" }}>
                                <UserList history={history} action={currentUser.roleId === userRole.child || childUser ? false : true} userList={myPeople} currentUser={currentUser} title={currentUser.roleId === userRole.child || childUser ? "Burrun Dalai Mob" : "My Nieces and Nephews"}></UserList>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid className="feed-list" item xs={4}>
                        {userInfo ? <FormProfile className={"form-profile"} data={userInfo} roleId={roleId}></FormProfile>
                            : <div className={"loding-skeleton"}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </div>}

                    </Grid>
                    <Grid className="box-right" item xs={4}>
                        <Grid item xs style={styleRight}>
                            <Box>
                                <ArchievementsList archievementsList={achievements} currentUser={currentUser}></ArchievementsList>
                            </Box>
                            <Box style={{ height: "20px" }}></Box>
                            <Box>
                                <UserList history={history} action={true} userList={myFriends} currentUser={currentUser} title="My Friends" link={true}></UserList>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Profile;
