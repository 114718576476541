import React from 'react';
import './modalComponent.scss';
import { Fade, Modal, Box, Grid, Typography, Dialog } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Scrollbars } from 'react-custom-scrollbars';
import ButtonRound from '../buttonRound/buttonRound';
import FormStory from '../formStory/formStory';

const close = require('../../../assets/images/close_icon.svg');


export interface Props {
    currentUser: any;
    className: string;
    setOpen: boolean,
    childInfo: any;
    titleFirst?: string,
    titleSecond?: string,
    logo?: string,
    callback: any,
    data?: any,
    behaviour?:any,
}

export interface State {
    setOpen: boolean
    isCompleteStory: boolean
}

class ModalStory extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = { setOpen: false, isCompleteStory:false };
    }

    async componentWillMount() {
        this.setState({ setOpen: this.props.setOpen });
        if(this.props.data){
            this.setState({isCompleteStory: this.props.data.isCompleted});
        }
    }

    render() {
        const { titleFirst, logo, titleSecond, callback, data, childInfo, currentUser, behaviour  } = this.props;
        console.log(currentUser, childInfo);
        const { isCompleteStory } = this.state;
        return (
            <div className={"modal-component"}>
                <Dialog
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.setOpen}
                    onClose={callback}
                    closeAfterTransition
                    className={"modal-component"}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.setOpen}>
                        <Box className={isCompleteStory ? "modal-box background-A" : "modal-box background-B"}>
                            <Grid container className={logo} style={{ padding: "30px", height: "100%" }}>
                                <Grid xs={12} style={{ display: "flex", height: "25%" }}>
                                    <Grid xs={8} style={{ position: "relative" }}>
                                        <Box className={"modal-title modal-title-story"}>
                                            {titleFirst ?
                                                <Box>
                                                    <span>{titleFirst}</span>
                                                </Box> : null}
                                            {titleSecond ?
                                                <Box>
                                                    <span className={"details"}>{"Details"}</span>
                                                </Box> : null}
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box style={{ float: "right" }}>
                                            <ButtonRound className={"button-close"} callback={callback} icon={close}></ButtonRound>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} style={{ paddingTop: "20px", height: "75%" }}>
                                    <Scrollbars style={{ width: "100%", height: "100%" }}>
                                        <FormStory className={"form-story"} childId={childInfo.id} currentUser={currentUser} behaviour={behaviour} clallback={callback} data={data}></FormStory>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Dialog>
            </div>
        );
    }
}

export default ModalStory;