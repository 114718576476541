import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const childrenService = {
    getAll,
    getById,
    getDreamCircleOfChild,
    getChildrenAll,
    addChildToTeamMember,
    getChildNewsFeed,
    getMyAchievements,
    getChildLastStarsForDreamCircle,
    getChildStoriesByStar,
    getMyPeople,
    getChildDreamCircleNewsFeed,
    updateChildDetails,
    getChildStars
};


function getAll() {
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET' };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildren`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
   
}
function getById(id) {

    console.log("this land here ");
    // const requestOptions = { method: 'GET' };
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildDetails/${id}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildrenAll() {

     const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
        // const requestOptions = { method: 'GET' };
        return new Promise((resolve, reject)=>{
            fetch(`${config.apiURL}/children/getChildrenList`, requestOptions)
            .then(
                response=>{
                
                    response.json()
                    .then(data=>{
                    resolve(data)
                    })
                })
                .catch(error=>{
                    reject(error);
                })
        })

}
function getDreamCircleOfChild(childId, behaviourId){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId,behaviourId )
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildDreamCircle/${childId}/${behaviourId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function addChildToTeamMember(values:any){
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/teams/addTeam`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    }) 
}

function getChildNewsFeed(childId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildNewsFeed/${childId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getMyAchievements(childId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getMyAchievements/${childId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildStoriesByStar(childId:number, starId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildStoriesByStar/${childId}/${starId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildLastStarsForDreamCircle(childId:number, behaviourId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildLastStarsForDreamCircle/${childId}/${behaviourId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getMyPeople(childId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getMyPeople/${childId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildDreamCircleNewsFeed(childId:number, currentId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildDreamCircleNewsFeed/${childId}/${currentId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function updateChildDetails(value:number){
    const requestOptions = { 
        method: 'PATCH', 
        headers:{ Authorization:authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(value) };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/updateChildDetails/`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildStars(childId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/children/getChildStars/${childId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}
