import React from 'react';
import './notificationList.scss';
import { Grid, Box, Link, ButtonBase } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import ModalNotificationList from '../modal/modalNotificationList';
import { notificationService } from '../../../services/notificationService';
import { authenticationService } from '../../../services';
import { Notifier } from '../notifier/notifier';
import { notificationAction } from '../../../enums';
import { history } from '../../../helper';
import { Common } from '../../../common';

const avatar  = Common.getAvadarDefaut();
const notRead = require('../../../assets/images/not-read.png');
const read = require('../../../assets/images/read.png');
const titleIcon = require('../../../assets/images/menuSelected.svg');


export interface Props {
    data: any,
    isModal: boolean,
    currentUser: any,
    closeNotification?: any
}

export interface State {
    newData: Array<any>,
    earlierData: Array<any>,
    snackBarStatus: any;
}

let childUser;

class NotificationList extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: null,
            date: new Date(),
            variant: 'error'
        }
        childUser = authenticationService.isTeamMemberChildValue;
        this.state = {
            newData: this.props.data.newNotificationList,
            earlierData: this.props.data.earlierNotificationList,
            snackBarStatus: stateObject,
        }

    }

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.data !== newProps.data) {
          this.setState({ 
            newData: newProps.data.newNotificationList,
            earlierData: newProps.data.earlierNotificationList,
           })
        }
      }

    componentWillMount(){
    }

    markAllAsRead = async (notificationsNew: Array<any>, notificationsEarlier: Array<any>, notification?: any) => {
        const stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: ''
        }
        if (notification) { //mark a notification as read
            // We don't need to call API again and again, as database can update command with where 
            await notificationsNew.forEach(element => {
                if (notification.id === element.id) {
                    notificationService.markNotificationAsRead(notification.id).then((data: any) => {
                        if (data.success) {
                            element.markAsRead = true;
                            this.setState({ newData: notificationsNew });
                        } else {
                            //ERROR
                            stateObject.status = true;
                            stateObject.msg = data.message;
                            stateObject.variant = "error";
                        }
                        this.setState({ snackBarStatus: stateObject });
                        setTimeout(() => {
                            stateObject.status = false;
                            this.setState({ snackBarStatus: stateObject });
                        }, 2000);
                    }, error => {
                        //ERROR
                        console.log(error.message);
                        stateObject.status = true;
                        stateObject.msg = "There is error on Server!";
                        stateObject.variant = "error";
                        this.setState({ snackBarStatus: stateObject });
                        setTimeout(() => {
                            stateObject.status = false;
                            this.setState({ snackBarStatus: stateObject });
                        }, 3000);
                    })
                }
            });
            notificationsEarlier.forEach(element => {
                if (notification.id === element.id) {
                    notificationService.markNotificationAsRead(notification.id).then((data: any) => {
                        if (data.success) {
                            element.markAsRead = true;
                            this.setState({ earlierData: notificationsEarlier });
                        } else {
                            //ERROR
                            stateObject.status = true;
                            stateObject.msg = data.message;
                            stateObject.variant = "error";
                        }
                        this.setState({ snackBarStatus: stateObject });
                        setTimeout(() => {
                            stateObject.status = false;
                            this.setState({ snackBarStatus: stateObject });
                        }, 2000);
                    }, error => {
                        //ERROR
                        console.log(error.message);
                        stateObject.status = true;
                        stateObject.msg = "There is error on Server!";
                        stateObject.variant = "error";
                        this.setState({ snackBarStatus: stateObject });
                        setTimeout(() => {
                            stateObject.status = false;
                            this.setState({ snackBarStatus: stateObject });
                        }, 3000);
                    })
                }
            });
        } else { //mark all notification as read
            const currentUserId = childUser ? childUser.userId : this.props.currentUser.userId;
            notificationService.markNotificationAllAsRead(currentUserId).then((data: any) => {
                if (data.success) {
                    notificationsNew.forEach(element => {
                        element.markAsRead = true;
                    });
                    this.setState({ newData: notificationsNew });
                    notificationsEarlier.forEach(element => {
                        element.markAsRead = true;
                    });
                    this.setState({ earlierData: notificationsEarlier });
                } else {
                    //ERROR
                    stateObject.status = true;
                    stateObject.msg = data.message;
                    stateObject.variant = "error";
                }
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 2000);
            }, error => {
                //ERROR
                console.log(error.message);
                stateObject.status = true;
                stateObject.msg = "There is error on Server!";
                stateObject.variant = "error";
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 3000);
            })
        }
    }

    openNotification = (notification: any) => {
        notificationService.markNotificationAsRead(notification.id).then((data: any) => {
            switch (notification.notificationAction) {
                case notificationAction.newStory:
                case notificationAction.gotStar:
                case notificationAction.completeDreamCircle: {
                    history.push(`/mydreamcircle/${notification.toUserId}`);
                    break;
                }
                case notificationAction.newFollower:
                    history.push(`/mydreamcircle/${notification.fromUserId}`);
                    break;
                case notificationAction.gotReaction:
                    history.push({ pathname: `/newsfeed/${notification.toUserId}`, notification: notification });
                    break;
            }
            if(this.props.closeNotification){
                this.props.closeNotification();
            }
        }, error => {
            //ERROR
        })
    }

    render() {
        const { newData, earlierData } = this.state;
        const { data, isModal, currentUser } = this.props;
        return (
            <div className={"notification-box"}>
                <Grid className={isModal ? "red-border-bottom" : "red-border-top"} item xs={12} style={{ padding: "10px", display: "flex" }}>
                    <Grid item xs={6} style={{ display: "flex" }}>
                        {isModal ? <img src={titleIcon} style={{ width: "35px", paddingRight: "10px" }}></img> : null}
                        <Box component="span" style={{ fontWeight: 600, marginTop: "auto", marginBottom: "auto" }}>Notifications</Box>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Link
                            component="button"
                            onClick={() => this.markAllAsRead(newData, earlierData)}
                        >
                            <Box component="span">Mark all as read</Box>
                        </Link>
                    </Grid>
                </Grid>
                {
                    newData.length > 0 || earlierData.length > 0 ?
                        <Scrollbars
                            style={{ width: "100%" }}
                            autoHide
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={500}
                            thumbMinSize={30}>
                            {
                                newData.length > 0 ?
                                    <Box>
                                        <Grid item xs={12} className={"title-notification"}>
                                            <Box component="span">New</Box>
                                        </Grid>
                                        {
                                            newData.map((item, index) => (
                                                <Grid key={index} item xs={12} className={item.markAsRead ? "" : "background-notification"}>
                                                    <ButtonBase>
                                                        <Grid item xs={12} className={"row-notification"}>
                                                            <Grid item xs={2} style={{ display: "flex" }} onClick={() => this.openNotification(item)}>
                                                                <img src={item.fromUserAvatar ? item.fromUserAvatar : avatar} style={{ width: "40px", margin: "auto" }}></img>
                                                            </Grid>
                                                            <Grid item xs={8} style={{ display: "flex" }} onClick={() => this.openNotification(item)}>
                                                                <Box component="span" style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto", fontSize: "14px" }}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}></div>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={2} style={{ display: "flex" }}>
                                                                <img src={item.markAsRead ? read : notRead} style={{ width: "8px", height: "8px", margin: "auto" }} onClick={() => this.markAllAsRead(newData, earlierData, item)}></img>
                                                            </Grid>
                                                        </Grid>
                                                    </ButtonBase>
                                                </Grid>
                                            ))
                                        }
                                    </Box> : null}
                            {
                                earlierData.length > 0 ?
                                    <Box>
                                        <Grid item xs={12} className={"title-notification"}>
                                            <Box component="span">Earlier</Box>
                                        </Grid>
                                        {
                                            earlierData.map((item, index) => (
                                                <Grid key={index} item xs={12} className={item.markAsRead ? "" : "background-notification"}>
                                                    <ButtonBase>
                                                        <Grid item xs={12} className={"row-notification"}>
                                                            <Grid item xs={2} style={{ display: "flex" }} onClick={() => this.openNotification(item)}>
                                                                <img src={item.fromUserAvatar ? item.fromUserAvatar : avatar} style={{ width: "40px", margin: "auto" }}></img>
                                                            </Grid>
                                                            <Grid item xs={8} style={{ display: "flex" }} onClick={() => this.openNotification(item)}>
                                                            <Box component="span" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "14px",  textAlign: "start" }}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}></div>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={2} style={{ display: "flex" }}>
                                                                <img src={item.markAsRead ? read : notRead} style={{ width: "8px", height: "8px", margin: "auto" }} onClick={() => this.markAllAsRead(newData, earlierData, item)}></img>
                                                            </Grid>
                                                        </Grid>
                                                    </ButtonBase>
                                                </Grid>
                                            ))
                                        }
                                    </Box> : null}
                        </Scrollbars>
                        : <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}><Box component="span">There aren't notifications</Box></Grid>}
                {this.state.snackBarStatus.status ?
                    <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </div>
        );
    }
}

export default NotificationList;