import React from 'react'
import { withRouter   } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { Typography, Grid } from '@material-ui/core';
import AddUserButton from './components/addUserIconButton'
import AdminLayout from './components/adminLayout'
import CustomToolbar from "./components/customToolbar";
import {teamMemberService} from '../../services';
import { FormatDate } from '../../common/formatDate';


export interface  Props {
    className:string;
    history:any
   
}
 
export interface  State {
    isLoading:boolean;
    teamMemberData:any
}
 
class TeamMemberList  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
       
    }

  
    componentDidMount(){
    console.log("this is locad")
        this.getData();
    }

    componentWillMount() {
        this.forceUpdate();
        this.getData();
    }

 

    getData(){
        this.setState({isLoading: true});
        teamMemberService.getAll().then((teamMember:any)=>{
            if(teamMember.success)
            {
                this.setState({teamMemberData: teamMember.data, isLoading: false})   
            }
         })
    }

   
   render(){
    const columns = [
        {
            name:"id",
            label:"id",
            options:{
               display:false
            }
        }, {
            name:"uniqueId",
            label:"Unique ID",
            options:{
                sort:true
            }
        },{
            name:"fullName",
            label:"Full Name",
            options:{
                sort:true
            }
        },
        {
            name:"dateOfBirth",
            label:"Date of Birth",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value){
                        return (
                            <div>{FormatDate.trasformDate(new Date(value))}</div>               
                        );
                    }
                    
                }
            }
        },{
            name:"location",
            label:"Location",
            options:{
                sort:true
            }
        },{
            name:"phoneNumber",
            label:"Phone Number"
        },{
            name:"childCount",
            label:"Children",
            options:{
                sort:true
            }
        }
    ];

    const options = {
        print: false,
        download:false,
        filter:false,
        viewColumns:false,
        // selectableRowsOnClick:true,
        selectableRowsHeader:false, 
        selectableRows:'none',
        onRowClick: (rowData) => {
            console.log("rowData",rowData);
            
            this.props.history.push({pathname:'/teammemberdetails/'+rowData[0], data:rowData})}//this.onRowClick,
        // customToolbar: () => {
        //     return (
        //       <CustomToolbar />
        //     );
        //   }
      };
   return( 
        <AdminLayout className={this.props.className} value="team" history={this.props.history}>
                <Grid  container>
                    <Grid item style={{width:'332px'}}>
                        {/* <Typography> */}
                            <h2>
                                Team Members
                            </h2>
                        {/* </Typography> */}
                    </Grid>
                    <Grid>
                        <AddUserButton toolTip="Add Team Member" action="/adduser?team" ></AddUserButton>
                    </Grid>
                </Grid>
                <MUIDataTable
                 className={"trasnparent-muipaper-root"}
                data={this.state.teamMemberData}
                options={options}
                columns={columns}
                />
        </AdminLayout>
    
    )};
}

    
export default withRouter(TeamMemberList);