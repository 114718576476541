import React from 'react';
import './avatar.scss';
import Layout from '../components/layout/layout';
import { authenticationService, followesService, adminMemberService, teamMemberService, childrenService, avatarService } from '../../services';
import { Box, Grid, Typography, Grow, Collapse } from '@material-ui/core';
import UserList from '../components/userList/userList';
import ArchievementsList from '../components/archievementstList/archievementsList';
import {
    userRole,
    menuTypes,
    characterName,
    characterDefaultColor,
    nameTool,
    positionChefTool,
    positionSuperHeroTool,
    positionAboriginalTool,
    positionDancerTool,
    positionAmeliaTool,
    positionBBoyTool,
    positionChickenLittleTool,
    positionDapperTool,
    positionMonsterOnesieTool,
    positionScubaTool,
    positionSurvivorTool
} from '../../enums/index';
import AvatarComponent from '../components/avatar/avatarComponent';
import HeaderNav from '../components/header/headerNav';
import AvatarMenu from '../components/avatarMenus/avatarMenu';
import AvatarSubMenu from '../components/avatarMenus/avatarSubMenu';
import { AvatarInfo, } from '../../enums/AvatarInfo'
import { Notifier } from '../components/notifier/notifier';
import { Theme , createStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { Position } from "../components/avatar/poistions";
import $ from "jquery";


const path = "../../assets/images/avatar/";
const formate = ".png";


export interface Props {
    className: any,
    history: any,
    location: any,
}



export interface State {
    myFriendList: Array<any>,
    archievementList: Array<any>,
    isloading: boolean,
    menuList: Array<any>,
    subMenuList: Array<any>,
    menuSelected: any,
    showSubMenu: boolean,
    currentUserInfo: any,
    avatarDetails: AvatarInfo,
    snackBarStatus: any;
}

let currentUser;
let currentUserInfo;
let childUser;
// const styles = (theme: Theme) =>
//   createStyles({
//     scrollBar:{
//         overflowY: "auto",
//         margin: 0,
//         padding: 0,
//         listStyle: "none",
//         height: "86vh",
//         '&::-webkit-scrollbar': {
//           width: '0.4em'
//         },
//         '&::-webkit-scrollbar-track': {
//           boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
//           webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
//         },
//         '&::-webkit-scrollbar-thumb': {
//           backgroundColor: 'rgba(0,0,0,.1)',
//           outline: '1px solid slategrey'
//         }
//       }
//   });
class Avatar extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: 'error'
        }


        this.state = {
            snackBarStatus: stateObject,
            myFriendList: [],
            archievementList: [],
            isloading: true,
            menuList: [],
            subMenuList: [],
            menuSelected: null,
            showSubMenu: false,
            currentUserInfo: null,
            avatarDetails: {
                //colour: "",
                age: { id: 0, name: "", typeId: 0, typeName: "" },
                hair: { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 },
                hat: { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 },
                face: { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 },
                shirt: { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 },
                tool: { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 },
                background: { id: 0, name: "", typeId: 0, typeName: "" },
                suit: { id: 0, name: "", typeId: 0, typeName: "", options: [] },
                character: { id: 0, name: "", typeId: 0, typeName: "", colour: "" },
            }
        }
    }

    getData(userId: number, roleId: number) {
        avatarService.getUserAvatar(userId).then((data: any) => {
            if (data.success) {
                let avatar: AvatarInfo = this.state.avatarDetails;
                if(data.data.length > 0){
                data.data.forEach(element => {
                    switch (element.typeId) {
                        case menuTypes.age:
                            avatar.age = element;
                            break;

                        case menuTypes.background:
                            avatar.background = element;
                            break;

                        case menuTypes.character:
                            avatar.character = element;
                            break;

                        case menuTypes.face:
                            avatar.face = element;
                            break;

                        case menuTypes.hair:
                            avatar.hair = element;
                            break;

                        case menuTypes.hat:
                            avatar.hat = element;
                            break;

                        case menuTypes.shirt:
                            avatar.shirt = element;
                            break;

                        case menuTypes.suit:
                            avatar.suit = element;
                            break;

                        case menuTypes.tool:
                            avatar.tool = element;
                            break;
                    }
                });
                this.setState({avatarDetails: avatar});
            }else {
                let stateObject = {
                    msg: "You need to create Avatar to continue to access!",
                    status: false,
                    date: new Date(),
                    variant: 'warning'
                }
                this.showAlertMessage(stateObject);
            }
                
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log("ËRROR", error.message);
            let stateObject = {
                msg: "We have a problem on Server, try again later!",
                status: false,
                date: new Date(),
                variant: 'error'
            }
            this.showAlertMessage(stateObject);
        });


        followesService.getMyFriends(userId).then((data: any) => {
            if (data.success) {
                this.setState({ myFriendList: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log("ËRROR", error.message);
            let stateObject = {
                msg: "We have a problem on Server, try again later!",
                status: false,
                date: new Date(),
                variant: 'error'
            }
            this.showAlertMessage(stateObject);
        });

        switch (roleId) {
            case userRole.child:
                childrenService.getMyAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ archievementList: data.data });
                    } else {
                        //ERROR
                    }
                });
                break;
            case userRole.Admin:
                adminMemberService.getNiecesNephewsAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ archievementList: data.data });
                    } else {
                        //ERROR
                    }
                });
                break;
            case userRole.teamMember:
                teamMemberService.getNiecesNephewsAchievements(userId).then((data: any) => {
                    if (data.success) {
                        this.setState({ archievementList: data.data });
                    } else {
                        //ERROR
                    }
                });
                break;
            default:
                break;

        }
    }

    selectMenu(menu: any) {
        this.setState({ showSubMenu: false });
        this.setState({ subMenuList: [] })
        let menuList = this.state.menuList;
        menuList.forEach(element => {
            if (menu.id === element.id) {
                element.isSelected = true;
                this.setState({ menuSelected: menu });
                setTimeout(() => {
                    this.getSubMenu(menu, currentUserInfo.userId);
                }, 200);

            }
            element.isSelected = menu.id === element.id ? true : false;
        })
        this.setState({ menuList: menuList });
    }

    selectSubMenu(subMenuList: Array<any>, subMenu: any, menu: any) {
        console.log("subMenu", subMenu);
        console.log("menu", menu);

        subMenuList.forEach(element => {
            if (subMenu.id === element.id) {
                element.isSelected = true;
                this.changeAvatarItem(menu, subMenu);

            } else {
                element.isSelected = false;
            }

        })
        this.setState({ subMenuList: subMenuList });
    }

    async componentWillMount() {
        this.setState({ isloading: true });
        currentUser = authenticationService.currentUserValue.user;
        childUser = authenticationService.isTeamMemberChildValue;
        currentUserInfo = childUser ? childUser : currentUser;
        if (currentUserInfo) {
            await this.getData(currentUserInfo.userId, currentUserInfo.roleId);
            await this.getMenu();
        } else {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
    }

    getMenu() {
        avatarService.getTypes().then((data: any) => {
            if (data.success) {
                data.data.forEach(element => {
                    element.isSelected = false;
                });
                // Move Charachter at first place 
                data.data.unshift(data.data.pop());
                //Move suit menu between shirt and tool
                data.data.splice(6, 0, data.data.pop());
                this.setState({ menuList: data.data });
            } else {
                //ERROR
            }
        }, error => {
            //ERROR
            console.log(error.message);

        });
    }

    async getSubMenu(menu: any, currentUserId: number) {
        avatarService.getOptionsByType(currentUserId, menu.id).then((data: any) => {
            if (data.success) {
                data.data.forEach(element => {
                    let itemSelected = this.getItemSelected(this.state.avatarDetails, menu);
                    if (menu.id === menuTypes.age && itemSelected.name === "") {
                        let avatarAux: AvatarInfo = this.state.avatarDetails;
                        avatarAux.age = {
                            id: element.id,
                            name: element.name,
                            typeId: element.typeId,
                            typeName: menu.name
                        }
                        itemSelected = avatarAux.age
                        this.setState({ avatarDetails: avatarAux });
                        console.log('itemSelected', itemSelected);
                    }
                    if (itemSelected.id === element.id) {
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });

                // the reset menu will not work for character and ae
                if (menu.id !== menuTypes.age && menu.id !== menuTypes.character) {
                    const emptyObject = {
                        id: 0,
                        typeId: 0,
                        ruleId: 0,
                        number: 0,
                        name: ""
                    };
                    data.data.splice(0, 0, emptyObject);
                }

                this.setState({ subMenuList: data.data });
                this.setState({ showSubMenu: true });
            } else {
                //ERROR
                this.setState({ showSubMenu: true });
            }
        }, error => {
            //ERROR
            console.log(error.message);
            this.setState({ showSubMenu: true });

        });
    }

    changeAvatarItem(menu: any, subMenu: any) {
        let stateObject = {
            msg: "You need to select the Character before!",
            status: false,
            date: new Date(),
            variant: 'warning'
        }
        let avatarDetails: AvatarInfo = this.state.avatarDetails
        switch (menu.id) {
            case menuTypes.age:
                avatarDetails.age = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name };
                if(avatarDetails.hair.name){
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.hair);
                    avatarDetails.hair.top = position.top;
                    avatarDetails.hair.left = position.left;
                    avatarDetails.hair.width = position.width;
                    avatarDetails.hair.height = position.height;
                }
                if(avatarDetails.face.name){
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.face, avatarDetails.character);
                    avatarDetails.face.top = position.top;
                    avatarDetails.face.left = position.left;
                    avatarDetails.face.width = position.width;
                    avatarDetails.face.height = position.height;
                }
                this.setState({ avatarDetails: avatarDetails });
                break;
            case menuTypes.hair:
                if (avatarDetails.character.name) {
                    avatarDetails.hair = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: avatarDetails.hair.width, height: avatarDetails.hair.height, top: avatarDetails.hair.top, left: avatarDetails.hair.left };
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.hair);
                    avatarDetails.hair.top = position.top;
                    avatarDetails.hair.left = position.left;
                    avatarDetails.hair.width = position.width;
                    avatarDetails.hair.height = position.height;
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.hat:
                if (avatarDetails.character.name) {
                    avatarDetails.hat = avatarDetails.hat.name ? { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: avatarDetails.hat.width, height: avatarDetails.hat.height, top: avatarDetails.hat.top, left: avatarDetails.hat.left } : { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: 200, height: 100, top: 160, left: 260 };
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.hat)
                    avatarDetails.hat.top = position.top;
                    avatarDetails.hat.left = position.left;
                    avatarDetails.hat.width = position.width;
                    avatarDetails.hat.height = position.height;
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.face:
                if (avatarDetails.character.name) {
                    avatarDetails.face = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: avatarDetails.face.width, height: avatarDetails.face.height, top: avatarDetails.face.top, left: avatarDetails.face.left };
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.face, avatarDetails.character)
                    avatarDetails.face.top = position.top;
                    avatarDetails.face.left = position.left;
                    avatarDetails.face.width = position.width;
                    avatarDetails.face.height = position.height;
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.shirt:
                if (avatarDetails.character.name) {
                    avatarDetails.shirt = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: 100, height: 100, top: 100, left: 100 };
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.tool:
                if (avatarDetails.character.name) {
                    avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: avatarDetails.tool.width, height: avatarDetails.tool.height, top: avatarDetails.tool.top, left: avatarDetails.tool.left };
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.tool);
                    avatarDetails.tool.top = position.top;
                    avatarDetails.tool.left = position.left;
                    avatarDetails.tool.width = position.width;
                    avatarDetails.tool.height = position.height;
                    this.setState({ avatarDetails: avatarDetails });
                    // switch (subMenu.name) {
                    //     case nameTool.chefTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionChefTool.width, height: positionChefTool.height, top: positionChefTool.top, left: positionChefTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.superheroTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionSuperHeroTool.width, height: positionSuperHeroTool.height, top: positionSuperHeroTool.top, left: positionSuperHeroTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.aboriginalTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionAboriginalTool.width, height: positionAboriginalTool.height, top: positionAboriginalTool.top, left: positionAboriginalTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.dancerTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionDancerTool.width, height: positionDancerTool.height, top: positionDancerTool.top, left: positionDancerTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.ameliaTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionAmeliaTool.width, height: positionAmeliaTool.height, top: positionAmeliaTool.top, left: positionAmeliaTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.bBoyTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionBBoyTool.width, height: positionBBoyTool.height, top: positionBBoyTool.top, left: positionBBoyTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.chickenLittleTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionChickenLittleTool.width, height: positionChickenLittleTool.height, top: positionChickenLittleTool.top, left: positionChickenLittleTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.dapperTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionDapperTool.width, height: positionDapperTool.height, top: positionDapperTool.top, left: positionDapperTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.monsterOnesieGreenTool:
                    //     case nameTool.monsterOnesiePinkTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionMonsterOnesieTool.width, height: positionMonsterOnesieTool.height, top: positionMonsterOnesieTool.top, left: positionMonsterOnesieTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.scubaTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionScubaTool.width, height: positionScubaTool.height, top: positionScubaTool.top, left: positionScubaTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     case nameTool.survivorTool:
                    //         avatarDetails.tool = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: positionSurvivorTool.width, height: positionSurvivorTool.height, top: positionSurvivorTool.top, left: positionSurvivorTool.left };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;

                    //     default:
                    //         avatarDetails.tool = avatarDetails.tool.name ? { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: avatarDetails.tool.width, height: avatarDetails.tool.height, top: avatarDetails.tool.top, left: avatarDetails.tool.left } : { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, width: 100, height: 100, top: 400, left: 150 };
                    //         this.setState({ avatarDetails: avatarDetails });
                    //         break;
                    // }
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.background:
                if (avatarDetails.character.name) {
                    avatarDetails.background = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name };
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.suit:
                if (avatarDetails.character.name) {
                    avatarDetails.suit = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, options: subMenu.options };
                    if (avatarDetails.suit.options && avatarDetails.suit.options.length > 0) {
                        avatarDetails.suit.options.forEach((element: any) => {
                            let subMenuAux = {
                                name: element.suitName,
                                id: element.id,
                            }
                            let menuAux = {
                                name: menu.name,
                                id: element.typeId
                            }
                            this.changeAvatarItem(menuAux, subMenuAux);
                        });
                    } else if (avatarDetails.hat.name && avatarDetails.face.name && avatarDetails.shirt.name && avatarDetails.tool.name) {
                        let objAux = { id: 0, name: "", typeId: 0, typeName: "", width: 0, height: 0, top: 0, left: 0 };
                        avatarDetails.hat = objAux;
                        avatarDetails.face = objAux;
                        avatarDetails.shirt = objAux;
                        avatarDetails.tool = objAux;
                    }
                    this.setState({ avatarDetails: avatarDetails });
                } else {
                    this.showAlertMessage(stateObject);
                }
                break;
            case menuTypes.character:
                avatarDetails.character = { id: subMenu.id, name: subMenu.name, typeId: menu.id, typeName: menu.name, colour: "" };
                if(avatarDetails.face.name){
                    const position = Position.getPosition(avatarDetails.age, avatarDetails.face, avatarDetails.character);
                    avatarDetails.face.top = position.top;
                    avatarDetails.face.left = position.left;
                    avatarDetails.face.width = position.width;
                    avatarDetails.face.height = position.height;
                }
                this.setState({ avatarDetails: avatarDetails });
                this.setDefaultColor();
                break;
            default:
        }
    }

    showAlertMessage  = (stateObject: any) => {
        stateObject.status = true;
        this.setState({ snackBarStatus: stateObject});
        setTimeout(() => {
            stateObject.msg = "";
            stateObject.status = false;
            stateObject.variant = "";
            this.setState({ snackBarStatus: stateObject });
        }, 3000);
    }

    setDefaultColor() {
        let avatarDetails: AvatarInfo = this.state.avatarDetails
        switch (avatarDetails.character.name) {
            case characterName.personMale:
                avatarDetails.character.colour = characterDefaultColor.personMaleDefaultColor;
                break;
            case characterName.personFemale:
                avatarDetails.character.colour = characterDefaultColor.personFemaleDefaultColor;
                break;
            case characterName.Goanna:
                avatarDetails.character.colour = characterDefaultColor.goannaDefaultColor;
                break;
            case characterName.Kanagroo:
                avatarDetails.character.colour = characterDefaultColor.kangarooDefaultColor;
                break;
            case characterName.Echidna:
                avatarDetails.character.colour = characterDefaultColor.echidnaDefaultColor;
                break;
            case characterName.Dolphin:
                avatarDetails.character.colour = characterDefaultColor.dolphinDefaultColor
                break;
            case characterName.Dingo:
                avatarDetails.character.colour = characterDefaultColor.dingoDefaultColor
                break;
            case characterName.Turtle:
                avatarDetails.character.colour = characterDefaultColor.turtleDefaultColor
                break;
            case characterName.Platypus:
                avatarDetails.character.colour = characterDefaultColor.platypusDefaultColor
                break;
            case characterName.Dinasaur:
                avatarDetails.character.colour = characterDefaultColor.dinasaurDefaultColor
                break;

        }
        this.setState({ avatarDetails: avatarDetails });
    }

    getItemSelected(avatarDetails: AvatarInfo, menuSelect: any): any {
        switch (menuSelect.id) {
            case menuTypes.age:
                return avatarDetails.age;
            case menuTypes.hair:
                return avatarDetails.hair;
            case menuTypes.hat:
                return avatarDetails.hat;
            case menuTypes.face:
                return avatarDetails.face;
            case menuTypes.shirt:
                return avatarDetails.shirt;
            case menuTypes.tool:
                return avatarDetails.tool;
            case menuTypes.background:
                return avatarDetails.background;
            case menuTypes.suit:
                return avatarDetails.suit;
            case menuTypes.character:
                return avatarDetails.character;
            default:
                return { id: 0, name: "", typeId: 0, typeName: "" };
        }
    }

    getColorFromPicker = (colour: string) => {
        let avatarDetails: AvatarInfo = this.state.avatarDetails;
        avatarDetails.character.colour = colour;
        this.setState({ avatarDetails: avatarDetails });
    }

    adjustWidth () {
        var parentwidth = $(".grid-menu").width();      
        $(".box-menu").width(parentwidth);        
    }

    render() {
        this.adjustWidth();
        const { myFriendList, archievementList, menuList, subMenuList, menuSelected, showSubMenu, avatarDetails, snackBarStatus } = this.state;
        const { location, className, history } = this.props;
        console.log(menuSelected && menuSelected.name)
        return (
            <div className={className} style={{maxHeight: '100%', overflow: 'auto'}} >
                <Layout className={className} currentUser={currentUser} location={location} history={history}></Layout>
                <Grid className={"header-" + className}>
                    <HeaderNav className="menu-header" currentUser={currentUser} location={location}  ></HeaderNav>
                </Grid>
                <Grid container>
                    <Grid style={{ display: "flex", }} item xs={3}>
                        
                        <Grid className="grid-menu" style={{ paddingTop: "150px", paddingLeft: "20px" }} item xs={6}>
                        {/* <Box className="box-menu" style={{position:"fixed"}}> */}
                        <Box className="box-menu">
                            {
                                menuList.map(menu => (
                                    <AvatarMenu isSelected={menu.isSelected} label={menu.name} onClick={() => this.selectMenu(menu)}></AvatarMenu>
                                ))
                            }
                            </Box>
                        </Grid>
                        
                        <Grid className="sub-manu-scroll-style" item xs={6}  >
                            {
                                subMenuList && subMenuList.length > 0 ?
                                    <Box>
                                        <Grid style={{ paddingTop: "50px", display: "flex" }} item xs={12}>
                                            <Collapse in={showSubMenu}>
                                                <AvatarSubMenu isSelected={true} image={this.getItemSelected(avatarDetails, menuSelected).id !== 0 ? require(`../../assets/images/avatar/menu/${menuSelected && menuSelected.name}/${this.getItemSelected(avatarDetails, menuSelected).name}.png`) : ""} onClick={() => { this.setState({}) }}></AvatarSubMenu>
                                            </Collapse >
                                        </Grid>
                                        <Grid style={{ flexFlow: "row wrap", display: "flex" }} item xs={12}>
                                            {

                                                subMenuList.map(subMenu => (
                                                    <Collapse in={showSubMenu}>
                                                        <AvatarSubMenu isSelected={subMenu.isSelected} image={subMenu.id !== 0 ? require(`../../assets/images/avatar/menu/${menuSelected && menuSelected.name}/${subMenu.name}.png`) : ""} onClick={() => this.selectSubMenu(subMenuList, subMenu, menuSelected)}></AvatarSubMenu>
                                                    </Collapse >
                                                ))}
                                        </Grid>
                                    </Box>
                                    : null}

                        </Grid>
                    </Grid>
                    <Grid className="feed-list" item xs={5}>
                        {/* <Box style={{position:"fixed"}}> */}
                        <AvatarComponent onClourPickerClick={this.getColorFromPicker} avatarDetails={this.state.avatarDetails} showAlertMessage={this.showAlertMessage}></AvatarComponent>
                        {/* </Box> */}
                    </Grid>
                    <Hidden >
                        <Grid className="box-right" item xs={4}>
                            <Grid item xs={4} style={{  paddingTop: "65px", paddingLeft: "40px" }}>
                                {/* <Box>
                                    <ArchievementsList archievementsList={archievementList} currentUser={currentUser}></ArchievementsList>
                                </Box>
                                <Box style={{ height: "20px" }}></Box>
                                <Box>
                                    <UserList userList={myFriendList} action={true} currentUser={currentUser} title="My Friends" history={history} link={true}></UserList>
                                </Box> */}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                {snackBarStatus.status ?
                    <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
            </div>
        );
    }
}

export default Avatar;
