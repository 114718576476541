import React from 'react';
import './objectTable.scss';
import { TableBody, TableHead, TableCell, TableRow, Table, Box, Typography, Link } from "@material-ui/core/";
import { FormatDate } from '../../../common/formatDate';
import ModalStoryList from '../modal/modalStoryList';
import { authenticationService } from '../../../services';
import { userRole } from '../../../enums';
const titleIconShow = require('../../../assets/images/show_all.svg');
const logo = "backgroud-behaviour";

export interface Props {
    data: Array<any>,
    titleCol: Array<any>,
    currentUser: any,
    className: string,
    maxShow: number,
    callback?: any,
    anyStarSelect?:boolean,
    title: string,
    childInfo: any,
}

export interface State {
    modal: boolean
}

let formatDate: FormatDate = new FormatDate();
let childUser:any = null;

class ObjectTable extends React.Component<Props, State>{
    constructor(props) {
        super(props);

        this.state = {modal: false}
        childUser = authenticationService.isTeamMemberChildValue;
    }



    async componentWillMount() {

    }

    createObjectList(objList: Array<any>): Array<any> {
        let dataAux: Array<any> = [];
        objList.forEach(element => {
            let obj = {
                id: element.id,
                date: formatDate.trasformDate(new Date(element.creationOn)),
                name: element.story ? element.story : element.starName,
                writtenBy: element.storyBy,
                isSelect: element.isSelect ? element.isSelect : false,
            }
            dataAux.push(obj);
        });
        return dataAux;
    }

    sendData = (obj:any) => {
        this.props.callback(obj);
   }

   openModal = () =>{
    //    if(childUser || this.props.currentUser.roleId === userRole.child){
    //        //do nothing
    //    }else{
        this.setState({modal: true})
    //    }
   }

   closeModal = () =>{
    this.setState({modal: false})
}

    render() {
        const { className, maxShow, data, titleCol, anyStarSelect, currentUser, title, childInfo  } = this.props;
        const { modal } = this.state;
        let uptadeData = this.createObjectList(data);   
        return (
            <div className={className}>
                    <Box style={{paddingLeft: "60px"}}>
                    {data.length > 0 ?
                        <Table aria-label="sticky table" className="object-table">
                            <TableHead>
                                <TableRow style={{ borderTop: "#707070", borderTopStyle: "solid", borderTopWidth: "1px" }}>
                                    {
                                        titleCol.map(el => (
                                            <TableCell align="left">{el.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uptadeData.map((item, index) => (
                                    index < maxShow ?
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => this.sendData(item)} >
                                            <TableCell style={{width:"10px"}} className={item.isSelect || anyStarSelect ? "item-select": ""}>
                                                {item.date}
                                            </TableCell>
                                            {
                                                titleCol.length > 2 ?
                                                    <TableCell align="left" className={item.isSelect || anyStarSelect ? "item-select": ""}>
                                                        {item.writtenBy}
                                                    </TableCell> : null
                                            }
                                            <TableCell align="left" className={item.isSelect || anyStarSelect? "cell-name item-select": "cell-name"}>
                                                {item.name}
                                            </TableCell>
                                            

                                        </TableRow> : null
                                ))}
                            </TableBody>
                        </Table> : <span>There isn't any registered!</span>}
                    </Box>
                {uptadeData.length > maxShow ?
                    <Box>
                        <Typography className="title">
                            <img src={titleIconShow}></img>
                            <Link
                                onClick={this.openModal}
                                component="button"
                                variant="body2" ><span>Show All</span></Link>
                        </Typography>
                    </Box> : null}
                    {
                        modal ?
                        <ModalStoryList
                            titleCol={titleCol}
                            titleSecond={title}
                            titleFirst={childInfo.firstName+" "+childInfo.lastName}
                            currentUser={currentUser}
                            setOpen={modal}
                            childInfo={childInfo}
                            data={uptadeData}
                            backGroundColor={""}
                            callback={this.closeModal}
                            logo={logo}
                        ></ModalStoryList>:null
                    }
            </div>
        );
    }
}

export default ObjectTable;