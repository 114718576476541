import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface Props {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface State {
    height:number
    width:number
}


export class Boy extends React.Component<Props, State>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:520})
      
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1" x="0px" y="0px" 	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M403.1,266.5c-2.1-5.4-7.5-12.1-13.4-11.9c-2,0.1-4,0.6-5.8,1.3l0,0C376.3,206,319.3,150,250,150
                                s-126.3,56-133.8,105.9c-1.9-0.8-3.9-1.2-5.9-1.3c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8
                                c3.7-0.4,7.2-1.7,10.3-3.7c9.7,31.6,33,53.5,72.5,63.6c-10.8,11-49.9,53.5-24.8,68.5c8.3,4.9,15,3.5,20.5-1
                                c-0.2,5.4-0.1,10.3,0.2,15.7c11.7,5.1,34.6,8.6,60.9,8.6s49.1-3.5,60.9-8.6c0.2-4.4,0.4-9.3,0.2-15.7c5.4,4.5,12.2,5.9,20.5,1
                                c25.1-15-14-57.5-24.8-68.5c39.5-10,62.7-31.9,72.5-63.6l0,0c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                                C402.4,300.2,410.5,285.6,403.1,266.5z"/>
                            <path fill="#6A3113" d="M250,339.5c-13,0-23.6-10.9-23.6-24.3c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                                c0,10.5,8.3,19.1,18.4,19.1c10.2,0,18.4-8.6,18.4-19.1c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                                C273.6,328.6,263,339.5,250,339.5z"/>
                            <path d="M311,234.6c0,0,0.2-0.2,0.4-0.4s0.7-0.7,1.3-1.1l0.9-0.8c0.4-0.3,0.8-0.5,1.3-0.8c0.5-0.3,1-0.5,1.5-0.8
                                c0.6-0.3,1.2-0.5,1.8-0.7c0.6-0.2,1.3-0.4,1.9-0.6s1.4-0.2,2.1-0.4c1.6-0.2,3.2-0.3,4.7-0.2c0.8,0.1,1.7,0.2,2.5,0.3
                                c0.4,0.1,0.9,0.1,1.3,0.2l1.3,0.4c1.7,0.5,3.4,1.1,5,1.9c1.6,0.8,3,1.8,4.4,2.9c0.7,0.5,1.3,1.1,1.9,1.8c0.6,0.6,1.2,1.2,1.8,1.8
                                c0.6,0.6,1,1.3,1.4,2c0.5,0.6,0.8,1.3,1.2,2c0.3,0.7,0.7,1.3,1,2s0.5,1.3,0.7,1.9c0.2,0.6,0.4,1.2,0.5,1.8c0.1,0.6,0.2,1.1,0.2,1.7
                                c0.1,0.5,0.1,1,0.1,1.5c0,0.5,0,0.9,0,1.3c0,0.6-0.1,1.2-0.2,1.8c-0.1,0.4-0.1,0.6-0.1,0.6v0.2c0,0.2-0.2,0.3-0.4,0.2
                                c-0.1,0-0.2-0.1-0.2-0.2l-0.8-1.9c-0.1-0.3-0.3-0.6-0.4-1l-0.6-1.1c-0.5-0.9-1-1.8-1.5-2.7l-0.9-1.5l-1.1-1.5l-0.6-0.8
                                c-0.2-0.2-0.5-0.5-0.7-0.7l-1.4-1.5c-1.1-1-2.2-1.8-3.3-2.7c-1.2-0.8-2.5-1.6-3.8-2.3c-1.3-0.7-2.7-1.2-4-1.7
                                c-1.3-0.5-2.7-0.9-4.1-1.2l-2-0.3c-0.3,0-0.7-0.1-1-0.1l-1,0l-1.8-0.1l-1.7,0.1c-1,0-2,0.1-3,0.3l-1.2,0.2l-1.1,0.2l-2,0.5
                                c-0.2,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3L311,234.6z"/>
                            <path d="M188.9,235.3l-2-0.5l-1.1-0.2l-1.2-0.2c-1-0.2-2-0.3-3-0.3l-1.7-0.1l-1.8,0.1l-1,0c-0.3,0-0.6,0.1-1,0.1l-2,0.3
                                c-1.4,0.3-2.8,0.7-4.1,1.1c-1.4,0.5-2.7,1.1-4,1.7c-1.3,0.7-2.6,1.4-3.8,2.3c-1.2,0.8-2.3,1.7-3.3,2.7l-1.4,1.5
                                c-0.2,0.2-0.5,0.5-0.7,0.7l-0.6,0.8l-1.1,1.4l-0.9,1.5c-0.6,0.9-1.1,1.7-1.5,2.7c-0.2,0.4-0.4,0.8-0.6,1.1l-0.4,1l-0.8,1.9
                                c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2v-0.2c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,0-0.8,0-1.3
                                c0-0.5,0.1-0.9,0.1-1.5s0.2-1.1,0.2-1.7c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.6,0.4-1.3,0.7-1.9c0.2-0.7,0.6-1.3,1-2
                                c0.8-1.4,1.6-2.7,2.7-4c0.5-0.6,1.1-1.2,1.8-1.8c0.3-0.3,0.6-0.6,0.9-0.9l1-0.8c2.7-2.3,6-3.9,9.4-4.8c0.8-0.3,1.6-0.5,2.4-0.6
                                c0.9-0.1,1.7-0.2,2.5-0.3c0.8-0.1,1.6,0,2.4,0c0.8,0,1.5,0.1,2.3,0.2c0.7,0.1,1.5,0.2,2.1,0.4c0.7,0.2,1.3,0.4,1.9,0.6
                                c0.6,0.2,1.2,0.4,1.8,0.7s1.1,0.5,1.5,0.8c0.5,0.3,0.9,0.5,1.3,0.8l0.9,0.8c0.6,0.4,1,0.9,1.3,1.1c0.3,0.2,0.4,0.4,0.4,0.4l0.1,0.1
                                c0.1,0.1,0.1,0.3,0,0.4C189,235.2,188.9,235.3,188.9,235.3L188.9,235.3z"/>
                            <path fill="#FFFFFF" d="M175.7,264.8c-15.4,0-27.8,12.5-27.8,27.8s12.5,27.8,27.8,27.8s27.8-12.5,27.8-27.8l0,0
                                C203.6,277.2,191.1,264.8,175.7,264.8C175.7,264.8,175.7,264.8,175.7,264.8z"/>
                            
                                <radialGradient id="SVGID_3_" cx="157.7636" cy="180.9213" r="28.43" gradientTransform="matrix(0.8843 0 0 -0.8843 49.3704 444.395)" gradientUnits="userSpaceOnUse">
                                <stop  offset="9.000000e-02" stop-color="#261F20"/>
                                <stop  offset="0.33" stop-color="#141011"/>
                                <stop  offset="0.54" stop-color="#000000"/>
                            </radialGradient>
                            <path fill="url(#SVGID_3_)" d="M180.1,272.7c-11,0-19.9,8.9-19.9,19.9c0,11,8.9,19.9,19.9,19.9c11,0,19.9-8.9,19.9-19.9l0,0
                                C200,281.6,191.1,272.7,180.1,272.7z"/>
                            <path fill="#FFFFFF" d="M324.3,264.8c15.4,0,27.8,12.5,27.8,27.8s-12.5,27.8-27.8,27.8s-27.8-12.5-27.8-27.8l0,0
                                C296.5,277.2,308.9,264.8,324.3,264.8C324.3,264.8,324.3,264.8,324.3,264.8z"/>
                            
                                <radialGradient id="SVGID_4_" cx="-66.2337" cy="180.9213" r="28.43" gradientTransform="matrix(-0.8843 0 0 -0.8843 252.5695 444.395)" gradientUnits="userSpaceOnUse">
                                <stop  offset="9.000000e-02" stop-color="#261F20"/>
                                <stop  offset="0.33" stop-color="#141011"/>
                                <stop  offset="0.54" stop-color="#000000"/>
                            </radialGradient>
                            <path fill="url(#SVGID_4_)" d="M319.9,272.7c11,0,19.9,8.9,19.9,19.9c0,11-8.9,19.9-19.9,19.9c-11,0-19.9-8.9-19.9-19.9
                                c0,0,0,0,0,0C300,281.6,308.9,272.7,319.9,272.7z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M403.1,266.5c-2.1-5.4-7.5-12.1-13.4-11.9
                                c-2,0.1-4,0.6-5.8,1.3c0.6,3.6,0.9,7.3,0.9,11c0,14.5-1.8,27.8-5.4,39.7c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                                C402.4,300.2,410.5,285.6,403.1,266.5z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M115.3,266.9c0-3.7,0.3-7.3,0.9-11c-1.9-0.8-3.9-1.2-5.9-1.3
                                c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8c3.7-0.4,7.2-1.7,10.3-3.7
                                C117.1,294.7,115.3,281.4,115.3,266.9z"/>
                            <path fill="#141414" d="M249.9,456.2l-60.7-2.9c0,0,4.2,55.3,18.1,62.4c4.5,2.3,8.9,4.3,20,4.3c9.6,0,19.5-1.8,18.2-6.4
                                c-2.2-8.4-3.5-32.2-3.2-37.2c2.4,0.2,5,0.3,7.6,0.3s5.2-0.1,7.6-0.3c0.2,5-1,28.8-3.2,37.2c-1.2,4.7,8.6,6.4,18.2,6.4
                                c11.1,0,15.4-1.9,20-4.3c13.9-7.1,18.4-62.4,18.4-62.4L249.9,456.2z"/>
                        </svg>
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=     <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M403.1,266.4c-2.1-5.4-7.5-12.1-13.4-11.9c-2,0.1-4,0.6-5.8,1.3l0,0c-7.5-50-64.6-106-133.9-106
                        s-126.4,56-133.9,106c-1.9-0.8-3.9-1.2-5.9-1.3c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8
                        c3.7-0.4,7.2-1.7,10.3-3.7c10,32.3,34.1,54.5,75.1,64.2c-21.1,3-63.8,12-55.2,36.1c8.9,25.1,39.9,5.4,51.8-3.4
                        c-0.9,5.1-1.7,11-2.3,17.6c-0.2,2.3-0.4,4.1-0.4,4.9c-0.8,9.1-0.9,18.2-0.4,27.3c11.7,5.1,34.6,8.6,60.9,8.6
                        c26.3,0,49.1-3.5,60.9-8.6c0.2-4.4,0.4-9.3,0.2-15.7c5.4,4.5,12.2,5.9,20.5,1c25.1-15-14-57.6-24.8-68.6
                        c39.5-10,62.7-31.9,72.5-63.6l0,0c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8C402.5,300.1,410.6,285.6,403.1,266.4z"/>
                    <path fill="#141414" d="M249.9,456.2l-60.7-2.9c0,0,4.2,55.3,18.1,62.4c4.5,2.3,8.9,4.3,20,4.3c9.7,0,19.5-1.8,18.2-6.4
                        c-2.2-8.4-3.5-32.2-3.2-37.2c2.4,0.2,5,0.3,7.6,0.3c2.7,0,5.2-0.1,7.6-0.3c0.2,5-1,28.8-3.2,37.2c-1.2,4.7,8.6,6.4,18.2,6.4
                        c11.1,0,15.4-1.9,20-4.3c13.9-7.1,18.4-62.4,18.4-62.4L249.9,456.2z"/>
                    <path fill="#6A3113" d="M250,339.4c-13,0-23.6-10.9-23.6-24.3c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                        c0,10.5,8.3,19.1,18.4,19.1c10.2,0,18.4-8.6,18.4-19.1c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                        C273.7,328.5,263,339.4,250,339.4z"/>
                    <path d="M311,234.6c0,0,0.2-0.2,0.4-0.4c0.3-0.3,0.7-0.7,1.3-1.1l0.9-0.8c0.4-0.3,0.8-0.5,1.3-0.8c0.5-0.3,1-0.5,1.5-0.8
                        c0.6-0.3,1.2-0.5,1.8-0.7c0.6-0.2,1.3-0.4,1.9-0.6c0.7-0.2,1.4-0.2,2.1-0.4c1.6-0.2,3.2-0.3,4.7-0.2c0.8,0.1,1.7,0.2,2.5,0.3
                        c0.4,0.1,0.9,0.1,1.3,0.2l1.3,0.4c1.7,0.5,3.4,1.1,5,1.9c1.6,0.8,3,1.8,4.4,2.9c0.7,0.5,1.3,1.1,1.9,1.8c0.6,0.6,1.2,1.2,1.8,1.8
                        s1,1.3,1.4,2c0.5,0.6,0.8,1.3,1.2,2c0.3,0.7,0.7,1.3,1,2c0.3,0.6,0.5,1.3,0.7,1.9c0.2,0.6,0.4,1.2,0.5,1.8c0.1,0.6,0.2,1.1,0.2,1.7
                        s0.1,1,0.1,1.5c0,0.5,0,0.9,0,1.3c0,0.6-0.1,1.2-0.2,1.8c-0.1,0.4-0.1,0.6-0.1,0.6v0.2c0,0.2-0.2,0.3-0.4,0.2
                        c-0.1,0-0.2-0.1-0.2-0.2l-0.8-1.9c-0.1-0.3-0.3-0.6-0.4-1l-0.6-1.1c-0.5-0.9-1-1.8-1.5-2.7l-0.9-1.5l-1.1-1.5l-0.6-0.8
                        c-0.2-0.2-0.5-0.5-0.7-0.7l-1.4-1.5c-1.1-1-2.2-1.8-3.3-2.7c-1.2-0.8-2.5-1.6-3.8-2.3c-1.3-0.7-2.7-1.2-4-1.7
                        c-1.3-0.5-2.7-0.9-4.1-1.2l-2-0.3c-0.3,0-0.7-0.1-1-0.1l-1,0l-1.8-0.1l-1.7,0.1c-1,0-2,0.1-3,0.3l-1.2,0.2l-1.1,0.2l-2,0.5
                        c-0.2,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3L311,234.6z"/>
                    <path d="M188.8,235.2l-2-0.5l-1.1-0.2l-1.2-0.2c-1-0.2-2-0.3-3-0.3l-1.7-0.1l-1.8,0.1l-1,0c-0.3,0-0.6,0.1-1,0.1l-2,0.3
                        c-1.4,0.3-2.8,0.7-4.1,1.1c-1.4,0.5-2.7,1.1-4,1.7c-1.3,0.7-2.6,1.4-3.8,2.3c-1.2,0.8-2.3,1.7-3.3,2.7l-1.4,1.5
                        c-0.2,0.2-0.5,0.5-0.7,0.7l-0.6,0.8l-1.1,1.4l-0.9,1.5c-0.6,0.9-1.1,1.7-1.5,2.7c-0.2,0.4-0.4,0.8-0.6,1.1l-0.4,1l-0.8,1.9
                        c-0.1,0.2-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2v-0.2c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,0-0.8,0-1.3
                        s0.1-0.9,0.1-1.5s0.2-1.1,0.2-1.7c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.6,0.4-1.3,0.7-1.9c0.2-0.7,0.6-1.3,1-2c0.8-1.4,1.6-2.7,2.7-4
                        c0.5-0.6,1.1-1.2,1.8-1.8c0.3-0.3,0.6-0.6,0.9-0.9l1-0.8c2.7-2.3,6-3.9,9.4-4.8c0.8-0.3,1.6-0.5,2.4-0.6c0.9-0.1,1.7-0.2,2.5-0.3
                        c0.8-0.1,1.6,0,2.4,0c0.8,0,1.5,0.1,2.3,0.2c0.7,0.1,1.5,0.2,2.1,0.4c0.7,0.2,1.3,0.4,1.9,0.6c0.6,0.2,1.2,0.4,1.8,0.7
                        c0.6,0.3,1.1,0.5,1.5,0.8c0.5,0.3,0.9,0.5,1.3,0.8l0.9,0.8c0.6,0.4,1,0.9,1.3,1.1c0.3,0.2,0.4,0.4,0.4,0.4l0.1,0.1
                        c0.1,0.1,0.1,0.3,0,0.4C189,235.2,188.9,235.2,188.8,235.2L188.8,235.2z"/>
                    <path fill="#FFFFFF" d="M175.7,264.7c-15.4,0-27.8,12.5-27.8,27.8s12.5,27.8,27.8,27.8s27.8-12.5,27.8-27.8l0,0
                        C203.5,277.2,191.1,264.7,175.7,264.7C175.7,264.7,175.7,264.7,175.7,264.7z"/>
                    
                        <radialGradient id="SVGID_1_" cx="-2172.9395" cy="223.4368" r="25.1472" gradientTransform="matrix(1 0 0 -1 2361.8 507.78)" gradientUnits="userSpaceOnUse">
                        <stop  offset="9.000000e-02" stop-color="#261F20"/>
                        <stop  offset="0.33" stop-color="#141011"/>
                        <stop  offset="0.54" stop-color="#000000"/>
                    </radialGradient>
                    <path fill="url(#SVGID_1_)" d="M180.1,272.6c-11,0-19.9,8.9-19.9,19.9s8.9,19.9,19.9,19.9c11,0,19.9-8.9,19.9-19.9l0,0
                        C200,281.6,191.1,272.6,180.1,272.6z"/>
                    <path fill="#FFFFFF" d="M324.3,264.7c15.4,0,27.8,12.5,27.8,27.8s-12.5,27.8-27.8,27.8s-27.8-12.5-27.8-27.8l0,0
                        C296.5,277.2,308.9,264.7,324.3,264.7C324.3,264.7,324.3,264.7,324.3,264.7z"/>
                    
                        <radialGradient id="SVGID_2_" cx="2280.4341" cy="223.4368" r="25.1472" gradientTransform="matrix(-1 0 0 -1 2591.5901 507.78)" gradientUnits="userSpaceOnUse">
                        <stop  offset="9.000000e-02" stop-color="#261F20"/>
                        <stop  offset="0.33" stop-color="#141011"/>
                        <stop  offset="0.54" stop-color="#000000"/>
                    </radialGradient>
                    <path fill="url(#SVGID_2_)" d="M319.9,272.6c11,0,19.9,8.9,19.9,19.9c0,11-8.9,19.9-19.9,19.9c-11,0-19.9-8.9-19.9-19.9
                        c0,0,0,0,0,0C300,281.6,309,272.6,319.9,272.6z"/>
                    <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M403.1,266.4c-2.1-5.4-7.5-12.1-13.4-11.9
                        c-2,0.1-4,0.6-5.8,1.3c0.6,3.6,0.9,7.3,0.9,11c0,14.5-1.8,27.8-5.4,39.7c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                        C402.5,300.1,410.6,285.6,403.1,266.4z"/>
                    <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M115.3,266.8c0-3.7,0.3-7.3,0.9-11c-1.9-0.8-3.9-1.2-5.9-1.3
                        c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8c3.7-0.4,7.2-1.7,10.3-3.7
                        C117,294.6,115.3,281.3,115.3,266.8z"/>
                    </svg>
       
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px" id="Teen_Male_wo_Tool" viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c-6.2-40.2-52.1-85.2-107.8-85.2
                            s-101.6,44.9-107.9,85.1c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                            c2.9-0.3,5.6-1.3,8.1-2.9c8.9,29.1,32,47.9,72.3,54.1l-2.2,7.5l-8.3,3c-9.9,0.8-32.7,5.2-35.5,28.4c-5.2,44.2-26.9,85.6-7.5,95.6
                            c8.9,4.5,16.4-0.3,22.7-9.3v0.4l62.7,3.6l62.5-3.6v-0.7c6.3,9.2,14,14.2,22.9,9.6c19.4-10-2.3-51.3-7.5-95.6
                            c-2.8-23.4-26-27.7-35.8-28.5l-8.3-3l-2.2-7.5c40.5-6.2,63.6-25,72.5-54.2c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3
                            C372.6,242.1,379.2,230.4,373.2,215z"/>
                        <path fill="#56002F" d="M209.9,232.6c-13.4,20.4,12.2,42,43.1,41.1c30.7-0.9,47.2-23.1,38.5-38.9c-8.8-15.8-22.4,3-41.8,2.6
                            S216.6,222.2,209.9,232.6z"/>
                        <path d="M280,251.8c0.7,9.4-13.5,17.1-30.2,17.1s-30.2-7.6-30.2-17.1c0-16.2,13.8-10.8,30.5-10.8S278.6,233.6,280,251.8z"/>
                        <path fill="#FFFFFF" d="M212.9,237.1c2.1,2.1,14.6,11.3,37.2,11.4c24.7,0.1,34.8-9.4,37.1-12.4c0.9-1.2,0.7-3.6-0.1-6.3
                            c-8.7-5.6-21,7.9-37.5,7.6c-16.3-0.3-28.5-10.9-36-8.1C212.6,232.4,211.8,235.9,212.9,237.1z"/>
                        <path fill="#E0DEDF" d="M287.1,229.8c-8.7-5.6-21,7.9-37.5,7.6c-16.3-0.3-28.5-10.9-36-8.1c-1,3-1.8,6.6-0.7,7.7
                            c1.2,1.1,2.5,2.1,3.9,2.9c0.6-2.4,1.9-5.8,4.4-6.2c3.8-0.7,19.4,8.1,32.1,7c12.7-1.1,19.4-7.5,22.2-7.1c2,0.4,5.6,2.7,8.3,5.6
                            c1.3-1,2.4-2,3.4-3.3C288.1,234.9,287.9,232.5,287.1,229.8z"/>
                        <path fill="#D661A6" d="M249.8,258.4c-12.6,0-23,3.9-25.5,9.1c8.9,4.4,18.8,6.5,28.7,6.1c7.8-0.1,15.5-2,22.6-5.4
                            C273.8,262.7,262.9,258.4,249.8,258.4z"/>
                        <path fill="#B23F89" d="M234.2,260.7c5.4,1.7,11.2,2.4,16.9,2c4.6-0.3,9.1-1,13.5-2.3c-4.8-1.4-9.8-2.1-14.9-2.1
                            C244.5,258.3,239.2,259.1,234.2,260.7z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c0.5,3,0.7,6,0.7,9.1c0.1,10.8-1.3,21.5-4.3,31.8
                            c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3C372.6,242.1,379.2,230.4,373.2,215z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M142.2,206.5c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                            c2.9-0.3,5.6-1.3,8.1-2.9c-3-10.3-4.5-21.1-4.4-31.8C141.4,212.5,141.7,209.5,142.2,206.5z"/>
                        <path fill="#141414" d="M283.3,428.5l-33.3,1.6l-33.6-1.6l-29.2-1.4c0,0.4,0,0.8,0,1.2c0.7,9.1,5.2,65.8,15.3,82.9
                            c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c3.6,0.6,7.2,0.9,10.8,0.9h0.3c9.8,0,19.7-1.8,18.5-6.5c-2.3-8.5-3.5-39.6-3.2-44.8
                            h0.3c2.3,0.2,4.6,0.3,7.1,0.3h0.3c2.2,0,4.4-0.1,6.5-0.2l1-0.1c0.3,5.2-1,36.3-3.2,44.8c-1.3,4.7,8.7,6.5,18.5,6.5h0.3
                            c3.6,0.1,7.1-0.2,10.6-0.8c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                            L283.3,428.5z"/>
                        <path fill="#44240E" d="M293.5,191.9c6.6-0.8,14.3-2.4,21.5-1.5c0.2,0.1,0.3,0,1.1,0.3c0.2,0,0.3,0.1,0.5,0.2l0.3,0.1l0.3,0.1
                            c8.9,6.1,22.6,15.2,6.6-3.6c-0.3-0.3-0.7-0.7-1.1-1c-0.5-0.4-1.7-1.5-3.1-2.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3-0.2-0.6-0.3-0.9-0.4
                            c-0.5-0.2,0.2,0-1.9-0.7c-7.1-1.7-16.7-0.7-24,0.8C291.7,184.4,292.6,191.8,293.5,191.9z"/>
                        <path fill="#44240E" d="M205.2,191.9c-6.6-0.8-14.3-2.4-21.5-1.5c-0.2,0.1-0.3,0-1.1,0.3c-0.2,0-0.3,0.1-0.5,0.2l-0.3,0.1l-0.3,0.1
                            c-8.9,6.1-22.6,15.2-6.6-3.6c0.3-0.3,0.7-0.7,1.1-1c0.5-0.4,1.7-1.5,3.1-2.3c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.6-0.3,0.9-0.4
                            c0.5-0.2-0.2,0,1.9-0.7c7.1-1.7,16.7-0.7,24,0.8C207,184.4,206.1,191.8,205.2,191.9z"/>
                        <g>
                            <path fill="#FFFFFF" d="M178.1,225.6c8,0,15.4,2.6,21.4,7c6.3-3.4,10.6-10.1,10.6-17.8c0-11.2-9-20.2-20.2-20.2s-20.2,9-20.2,20.2
                                c0,4.1,1.2,7.9,3.3,11.1C174.7,225.7,176.4,225.6,178.1,225.6z"/>
                            <g>
                                <path fill="#21323F" d="M196.7,205.7c-5.8,0-10.5,4.7-10.5,10.5s4.7,10.5,10.5,10.5s10.5-4.7,10.5-10.5S202.5,205.7,196.7,205.7z"/>
                                <path fill="#FFFFFF" d="M189.8,216.3h-7c-2.3,0-4.1-1.8-4.1-4.1l0,0c0-2.3,1.8-4.1,4.1-4.1h7c2.3,0,4.1,1.8,4.1,4.1l0,0
                                    C193.9,214.4,192.1,216.3,189.8,216.3z"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#FFFFFF" d="M321.9,225.6c-8,0-15.4,2.6-21.4,7c-6.3-3.4-10.6-10.1-10.6-17.8c0-11.2,9-20.2,20.2-20.2s20.2,9,20.2,20.2
                                c0,4.1-1.2,7.9-3.3,11.1C325.3,225.7,323.6,225.6,321.9,225.6z"/>
                            <g>
                                <path fill="#21323F" d="M303.3,205.7c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5S297.5,205.7,303.3,205.7z"/>
                                <path fill="#FFFFFF" d="M310.2,216.3h7c2.3,0,4.1-1.8,4.1-4.1l0,0c0-2.3-1.8-4.1-4.1-4.1h-7c-2.3,0-4.1,1.8-4.1,4.1l0,0
                                    C306.1,214.4,307.9,216.3,310.2,216.3z"/>
                            </g>
                        </g>
                    </svg>
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px" id="Teen_Male_w_Tool" viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill={backGroundColor}  d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c-6.2-40.2-52.1-85.2-107.8-85.2
                                s-101.6,44.9-107.9,85.1c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                                c2.9-0.3,5.6-1.3,8.1-2.9c8.9,29.1,32,47.9,72.3,54.1l-2.2,7.5l-7.1,2.6c-4.6,0.3-9.2,1.6-13.2,4c-38.7,21.8-84.7,28.6-81.3,50.1
                                c4,25.7,54.9,6.4,76.8-3.2c-2.3,15.5-4,35.1-4,59.5c0,0,0.1,1.9,0.2,5.1l62.7,3.6l62.5-3.6v-0.7c6.3,9.2,14,14.2,22.9,9.6
                                c19.4-10-2.3-51.3-7.5-95.6c-2.8-23.4-26-27.7-35.7-28.5l-8.3-3l-2.2-7.5c40.5-6.2,63.6-25,72.5-54.2c2.4,1.6,5.2,2.5,8.1,2.9
                                c1.5,0.1,6.1-0.8,9.3-6.3C372.6,242.1,379.2,230.4,373.2,215z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c0.5,3,0.7,6,0.7,9.1c0.1,10.8-1.3,21.5-4.3,31.8
                                c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3C372.6,242.1,379.2,230.4,373.2,215z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M142.2,206.5c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                                c2.9-0.3,5.6-1.3,8.1-2.9c-3-10.3-4.5-21.1-4.4-31.8C141.4,212.5,141.7,209.5,142.2,206.5z"/>
                            <path fill="#141414" d="M283.3,428.5l-33.3,1.6l-33.6-1.6l-29.2-1.4c0,0.4,0,0.8,0,1.2c0.7,9.1,5.2,65.8,15.3,82.9
                                c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c3.6,0.6,7.2,0.9,10.8,0.9h0.3c9.8,0,19.7-1.8,18.5-6.5c-2.3-8.5-3.5-39.6-3.2-44.8
                                h0.3c2.3,0.2,4.6,0.3,7.1,0.3h0.3c2.2,0,4.4-0.1,6.5-0.2l1-0.1c0.3,5.2-1,36.3-3.2,44.8c-1.3,4.7,8.7,6.5,18.5,6.5h0.3
                                c3.6,0.1,7.1-0.2,10.6-0.8c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                                L283.3,428.5z"/>
                            <path fill="#56002F" d="M209.9,232.6c-13.4,20.4,12.2,42,43.1,41.1c30.7-0.9,47.2-23.1,38.5-38.9c-8.8-15.8-22.4,3-41.8,2.6
                                S216.6,222.2,209.9,232.6z"/>
                            <path d="M280,251.8c0.7,9.4-13.5,17.1-30.2,17.1s-30.2-7.6-30.2-17.1c0-16.2,13.8-10.8,30.5-10.8S278.6,233.6,280,251.8z"/>
                            <path fill="#FFFFFF" d="M212.9,237.1c2.1,2.1,14.6,11.3,37.2,11.4c24.7,0.1,34.8-9.4,37.1-12.4c0.9-1.2,0.7-3.6-0.1-6.3
                                c-8.7-5.6-21,7.9-37.5,7.6c-16.3-0.3-28.5-10.9-36-8.1C212.6,232.4,211.8,235.9,212.9,237.1z"/>
                            <path fill="#E0DEDF" d="M287.1,229.8c-8.7-5.6-21,7.9-37.5,7.6c-16.3-0.3-28.5-10.9-36-8.1c-1,3-1.8,6.6-0.7,7.7
                                c1.2,1.1,2.5,2.1,3.9,2.9c0.6-2.4,1.9-5.8,4.4-6.2c3.8-0.7,19.4,8.1,32.1,7c12.7-1.1,19.4-7.5,22.2-7.1c2,0.4,5.6,2.7,8.3,5.6
                                c1.3-1,2.4-2,3.4-3.3C288.1,234.9,287.9,232.5,287.1,229.8z"/>
                            <path fill="#D661A6" d="M249.8,258.4c-12.6,0-23,3.9-25.5,9.1c8.9,4.4,18.8,6.5,28.7,6.1c7.8-0.1,15.5-2,22.6-5.4
                                C273.8,262.7,262.9,258.4,249.8,258.4z"/>
                            <path fill="#B23F89" d="M234.2,260.7c5.4,1.7,11.2,2.4,16.9,2c4.6-0.3,9.1-1,13.5-2.3c-4.8-1.4-9.8-2.1-14.9-2.1
                                C244.5,258.3,239.2,259.1,234.2,260.7z"/>
                            <path fill="#44240E" d="M293.5,191.9c6.6-0.8,14.3-2.4,21.5-1.5c0.2,0.1,0.3,0,1.1,0.3c0.2,0,0.3,0.1,0.5,0.2l0.3,0.1l0.3,0.1
                                c8.9,6.1,22.6,15.2,6.6-3.6c-0.3-0.3-0.7-0.7-1.1-1c-0.5-0.4-1.7-1.5-3.1-2.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3-0.2-0.6-0.3-0.9-0.4
                                c-0.5-0.2,0.2,0-1.9-0.7c-7.1-1.7-16.7-0.7-24,0.8C291.7,184.4,292.6,191.8,293.5,191.9z"/>
                            <path fill="#44240E" d="M205.2,191.9c-6.6-0.8-14.3-2.4-21.5-1.5c-0.2,0.1-0.3,0-1.1,0.3c-0.2,0-0.3,0.1-0.5,0.2l-0.3,0.1l-0.3,0.1
                                c-8.9,6.1-22.6,15.2-6.6-3.6c0.3-0.3,0.7-0.7,1.1-1c0.5-0.4,1.7-1.5,3.1-2.3c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.6-0.3,0.9-0.4
                                c0.5-0.2-0.2,0,1.9-0.7c7.1-1.7,16.7-0.7,24,0.8C207,184.4,206.1,191.8,205.2,191.9z"/>
                            <g>
                                <path fill="#FFFFFF" d="M178.1,225.6c8,0,15.4,2.6,21.4,7c6.3-3.4,10.6-10.1,10.6-17.8c0-11.2-9-20.2-20.2-20.2s-20.2,9-20.2,20.2
                                    c0,4.1,1.2,7.9,3.3,11.1C174.7,225.7,176.4,225.6,178.1,225.6z"/>
                                <g>
                                    <path fill="#21323F" d="M196.7,205.7c-5.8,0-10.5,4.7-10.5,10.5s4.7,10.5,10.5,10.5s10.5-4.7,10.5-10.5S202.5,205.7,196.7,205.7z"/>
                                    <path fill="#FFFFFF" d="M189.8,216.3h-7c-2.3,0-4.1-1.8-4.1-4.1l0,0c0-2.3,1.8-4.1,4.1-4.1h7c2.3,0,4.1,1.8,4.1,4.1l0,0
                                        C193.9,214.4,192.1,216.3,189.8,216.3z"/>
                                </g>
                            </g>
                            <g>
                                <path fill="#FFFFFF" d="M321.9,225.6c-8,0-15.4,2.6-21.4,7c-6.3-3.4-10.6-10.1-10.6-17.8c0-11.2,9-20.2,20.2-20.2s20.2,9,20.2,20.2
                                    c0,4.1-1.2,7.9-3.3,11.1C325.3,225.7,323.6,225.6,321.9,225.6z"/>
                                <g>
                                    <path fill="#21323F" d="M303.3,205.7c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5S297.5,205.7,303.3,205.7z"/>
                                    <path fill="#FFFFFF" d="M310.2,216.3h7c2.3,0,4.1-1.8,4.1-4.1l0,0c0-2.3-1.8-4.1-4.1-4.1h-7c-2.3,0-4.1,1.8-4.1,4.1l0,0
                                        C306.1,214.4,307.9,216.3,310.2,216.3z"/>
                                </g>
                            </g>
                        </svg>
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
