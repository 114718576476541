import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface GirlProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface GirlState {
    height:string
}


export class Girl extends React.Component<GirlProps, GirlState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                <path fill={backGroundColor} d="M403.1,266.5c-2.1-5.5-7.5-12.1-13.4-11.9c-2.2,0.1-4.2,0.6-5.8,1.3l0,0C376.4,206,319.3,150,250,150
                    c-69.2,0-126.3,56-133.9,105.9c-1.7-0.6-3.6-1.2-5.8-1.3c-5.8-0.3-11.2,6.4-13.4,11.9c-7.4,19.2,0.6,33.7,1.9,36
                    c4,6.9,9.6,8,11.6,7.8c4.9-0.4,8.9-2.8,10.3-3.7c9.7,31.7,33.1,53.5,72.5,63.6c-10.8,11-49.9,53.5-24.8,68.5c8.3,5,15,3.5,20.4-1
                    c-0.2,5.4-0.1,10.3,0.2,15.7c11.8,5.1,34.6,8.6,60.8,8.6s49.2-3.4,60.8-8.6c0.3-4.4,0.4-9.4,0.2-15.7c5.4,4.5,12.2,5.9,20.4,1
                    c25.1-15-14-57.6-24.8-68.5c39.4-10.1,62.7-31.9,72.5-63.6l0,0c1.3,0.9,5.5,3.4,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                    C402.4,300.1,410.6,285.6,403.1,266.5z"/>
                <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M403.1,266.5c-2.1-5.5-7.5-12.1-13.4-11.9
                    c-2.2,0.1-4.2,0.6-5.8,1.3c0.5,3.7,0.9,7.3,0.9,11c0,14.5-1.8,27.8-5.4,39.6c1.3,0.9,5.5,3.4,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                    C402.4,300.1,410.6,285.6,403.1,266.5z"/>
                <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M115.3,266.9c0-3.6,0.3-7.3,0.9-11c-1.7-0.6-3.6-1.2-5.8-1.3
                    c-5.8-0.3-11.2,6.4-13.4,11.9c-7.4,19.2,0.6,33.7,1.9,36c4,6.9,9.6,8,11.6,7.8c4.9-0.4,8.9-2.8,10.3-3.7
                    C117.1,294.7,115.3,281.4,115.3,266.9z"/>
                <path opacity="0.18" fill="#CECECE" d="M339.2,319.2c-11.3,0-20.4,5.8-20.4,13c0,7.2,9.1,13,20.4,13c11.3,0,20.4-5.8,20.4-13
                    C359.6,325.1,350.5,319.2,339.2,319.2z"/>
                <path opacity="0.18" fill="#CECECE" d="M160.7,319.2c11.3,0,20.4,5.8,20.4,13c0,7.2-9.1,13-20.4,13s-20.4-5.8-20.4-13
                    C140.3,325.1,149.4,319.2,160.7,319.2z"/>
                <path fill="#141414" d="M249.9,456.2l-60.8-2.9c0,0,4.2,55.3,18.1,62.4c4.5,2.3,8.9,4.2,20,4.2c9.6,0,19.5-1.8,18.2-6.4
                    c-2.2-8.4-3.4-32.2-3.2-37.2c2.5,0.2,5,0.3,7.6,0.3s5.2-0.1,7.6-0.3c0.3,5-1,28.8-3.2,37.2c-1.2,4.7,8.6,6.4,18.2,6.4
                    c11.1,0,15.5-1.9,20-4.2c13.9-7.2,18.4-62.4,18.4-62.4L249.9,456.2z"/>
                <path fill="#6A3113" d="M250,339.4c-13,0-23.6-10.9-23.6-24.2c0-1.4,1.1-2.6,2.6-2.6s2.6,1.1,2.6,2.6c0,10.5,8.3,19.1,18.4,19.1
                    c10.2,0,18.4-8.6,18.4-19.1c0-1.4,1.1-2.6,2.6-2.6c1.4,0,2.6,1.1,2.6,2.6C273.6,328.5,263,339.4,250,339.4z"/>
                <path d="M311,234.6c0,0,0.2-0.2,0.4-0.4c0.3-0.3,0.7-0.7,1.2-1.1c0.3-0.3,0.6-0.4,0.9-0.8c0.4-0.3,0.8-0.4,1.2-0.7
                    c0.4-0.3,1-0.5,1.5-0.8c0.5-0.3,1.1-0.4,1.8-0.7c0.6-0.2,1.2-0.4,1.9-0.6c0.7-0.2,1.4-0.3,2.1-0.4c1.5-0.3,3.1-0.2,4.8-0.2
                    c0.8,0.1,1.7,0.2,2.5,0.4c0.4,0.1,0.9,0.1,1.2,0.2c0.4,0.1,0.8,0.3,1.2,0.4c1.7,0.4,3.4,1.1,5,1.9c1.5,0.9,3.1,1.8,4.4,2.9
                    c0.7,0.5,1.3,1.1,1.9,1.7c0.6,0.6,1.1,1.2,1.7,1.9c0.5,0.7,1,1.3,1.4,1.9c0.4,0.6,0.9,1.3,1.1,2c0.4,0.7,0.7,1.3,1,1.9
                    c0.3,0.7,0.4,1.3,0.6,1.9s0.4,1.2,0.4,1.8c0.1,0.6,0.2,1.1,0.3,1.7c0.1,0.5,0.1,1,0.2,1.4c0,0.4,0,0.9,0,1.2c0,0.7-0.1,1.3-0.2,1.8
                    c-0.1,0.4-0.1,0.6-0.1,0.6v0.2c0,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0-0.3-0.7-0.8-1.9c-0.1-0.3-0.3-0.6-0.4-1
                    s-0.4-0.7-0.6-1.1c-0.4-0.8-0.9-1.7-1.5-2.7c-0.3-0.4-0.6-1-1-1.4c-0.4-0.4-0.8-1-1.1-1.4c-0.2-0.3-0.4-0.5-0.6-0.8
                    c-0.2-0.3-0.4-0.4-0.7-0.7c-0.4-0.4-1-1-1.4-1.5c-1.1-0.9-2.1-1.9-3.4-2.7c-1.1-0.9-2.5-1.5-3.7-2.3c-1.3-0.6-2.7-1.3-4.1-1.7
                    c-1.3-0.5-2.7-0.8-4.1-1.1c-0.7-0.1-1.3-0.2-2-0.3c-0.4,0-0.6-0.1-1-0.2c-0.4,0-0.6,0-1-0.1c-0.6,0-1.2-0.1-1.9-0.1
                    c-0.6,0-1.1,0.1-1.8,0.1c-1.1,0-2.1,0.2-3,0.3c-0.4,0.1-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-1.2,0.3-2,0.4-2,0.4
                    c-0.2,0-0.4-0.1-0.4-0.2s0-0.2,0.1-0.3L311,234.6z"/>
                <path d="M188.8,235.2c0,0-0.7-0.2-2-0.4c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.1-1.2-0.2c-0.9-0.1-1.9-0.3-3-0.3
                    c-0.5,0-1.1-0.1-1.8-0.1c-0.6,0-1.2,0.1-1.9,0.1c-0.4,0-0.6,0-1,0.1c-0.4,0-0.6,0.1-1,0.2c-0.6,0.1-1.3,0.2-2,0.3
                    c-1.3,0.4-2.7,0.6-4.1,1.1c-1.4,0.4-2.7,1.1-4.1,1.7c-1.2,0.7-2.6,1.4-3.7,2.3c-1.2,0.8-2.3,1.8-3.4,2.7c-0.4,0.5-1,1-1.4,1.5
                    c-0.3,0.3-0.4,0.4-0.7,0.7c-0.2,0.3-0.4,0.5-0.6,0.8c-0.4,0.5-0.8,1-1.1,1.4c-0.4,0.5-0.6,1-1,1.4c-0.6,0.9-1.1,1.9-1.5,2.7
                    c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.4-0.3,0.7-0.4,1c-0.5,1.2-0.8,1.9-0.8,1.9c-0.1,0.2-0.3,0.3-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2v-0.2
                    c0,0-0.1-0.2-0.1-0.6c0-0.4-0.2-1-0.2-1.8c0-0.4,0-0.8-0.1-1.2c0-0.4,0.1-0.9,0.2-1.4c0.1-0.5,0.2-1.1,0.3-1.7
                    c0.1-0.6,0.3-1.1,0.4-1.8c0.2-0.6,0.4-1.2,0.6-1.9c0.3-0.6,0.6-1.2,1-1.9c0.6-1.4,1.6-2.7,2.7-4c0.5-0.6,1.1-1.2,1.8-1.9
                    c0.3-0.3,0.6-0.6,0.9-0.9l1.1-0.8c1.3-1.1,2.9-2,4.4-2.9c1.7-0.7,3.3-1.5,5-1.9c0.8-0.3,1.7-0.4,2.6-0.5s1.7-0.3,2.5-0.4
                    c0.8,0,1.7,0,2.5,0c0.8,0,1.6,0.1,2.3,0.2c0.7,0.1,1.5,0.3,2.1,0.4c0.7,0.2,1.3,0.4,1.9,0.6c0.6,0.3,1.1,0.4,1.8,0.7
                    c0.5,0.3,1.1,0.5,1.5,0.8c0.4,0.3,0.9,0.5,1.2,0.7c0.4,0.3,0.6,0.5,0.9,0.8c0.5,0.4,1,0.9,1.2,1.1c0.3,0.3,0.4,0.4,0.4,0.4l0.1,0.2
                    c0.1,0.1,0.1,0.3,0,0.4C189,235.2,188.9,235.2,188.8,235.2z"/>
                <path fill="#FFFFFF" d="M175.7,264.8c-15.4,0-27.9,12.5-27.9,27.9c0,15.4,12.5,27.9,27.9,27.9s27.9-12.5,27.9-27.9
                    C203.5,277.2,191.1,264.8,175.7,264.8z"/>
                
                    <radialGradient id="SVGID_7_" cx="188.8412" cy="177.4838" r="25.1401" gradientTransform="matrix(1 0 0 -1 0 461.88)" gradientUnits="userSpaceOnUse">
                    <stop  offset="8.504260e-02" stop-color="#261F20"/>
                    <stop  offset="0.3258" stop-color="#141011"/>
                    <stop  offset="0.5357" stop-color="#000000"/>
                </radialGradient>
                <path fill="url(#SVGID_7_)" d="M180,272.7c-11,0-19.9,8.9-19.9,19.9c0,11,8.9,19.9,19.9,19.9s19.9-8.9,19.9-19.9
                    C199.9,281.6,191.1,272.7,180,272.7z"/>
                <path fill="#FFFFFF" d="M324.3,264.8c15.4,0,27.9,12.5,27.9,27.9c0,15.4-12.5,27.9-27.9,27.9s-27.9-12.5-27.9-27.9
                    C296.5,277.2,308.9,264.8,324.3,264.8z"/>
                
                    <radialGradient id="SVGID_8_" cx="-81.3234" cy="177.4838" r="25.1401" gradientTransform="matrix(-1 0 0 -1 229.7944 461.88)" gradientUnits="userSpaceOnUse">
                    <stop  offset="8.504260e-02" stop-color="#261F20"/>
                    <stop  offset="0.3258" stop-color="#141011"/>
                    <stop  offset="0.5357" stop-color="#000000"/>
                </radialGradient>
                <path fill="url(#SVGID_8_)" d="M319.9,272.7c11,0,19.9,8.9,19.9,19.9c0,11-8.9,19.9-19.9,19.9c-11,0-19.9-8.9-19.9-19.9
                    C300,281.6,308.9,272.7,319.9,272.7z"/>
                <path fill="#141414" d="M194.2,393c-1.2,6-2.7,13.6-3.7,22.9h118.9c-1.1-9.6-2.4-17.8-3.4-22.9H194.2z"/>
            </svg>
  
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid= <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                    <path fill={backGroundColor} d="M403.1,266.5c-2.1-5.4-7.5-12.1-13.4-11.9c-2,0.1-4,0.6-5.8,1.3l0,0C376.3,206,319.3,150,250,150
                            s-126.3,56-133.8,105.9c-1.9-0.8-3.9-1.2-5.9-1.3c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8
                            c3.7-0.4,7.2-1.7,10.3-3.7c10,32.3,34,54.4,75,64.2c-21.1,3-63.7,12-55.2,36.1c8.9,25.1,39.9,5.4,51.7-3.4
                            c-0.9,5.1-1.7,11-2.3,17.6c-0.2,2.3-0.4,4.1-0.4,4.9c-0.8,9.1-0.9,18.2-0.4,27.3c11.7,5.1,34.6,8.6,60.9,8.6s49.1-3.5,60.9-8.6
                            c0.2-4.4,0.4-9.3,0.2-15.7c5.4,4.5,12.2,5.9,20.5,1c25.1-15-14-57.5-24.8-68.5c39.5-10,62.7-31.9,72.5-63.6l0,0
                            c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8C402.4,300.2,410.5,285.6,403.1,266.5z"/>
                        <path opacity="0.18" fill="#CECECE" d="M339.2,319.3c-11.3,0-20.4,5.8-20.4,13c0,7.2,9.1,13,20.4,13s20.4-5.8,20.4-13
                            C359.7,325.1,350.5,319.3,339.2,319.3z"/>
                        <path opacity="0.18" fill="#CECECE" d="M160.8,319.3c11.3,0,20.4,5.8,20.4,13c0,7.2-9.1,13-20.4,13s-20.4-5.8-20.4-13
                            C140.4,325.1,149.5,319.3,160.8,319.3z"/>
                        <path fill="#141414" d="M249.9,456.2l-60.7-2.9c0,0,4.2,55.3,18.1,62.4c4.5,2.3,8.9,4.3,20,4.3c9.6,0,19.5-1.8,18.2-6.4
                            c-2.2-8.4-3.5-32.2-3.2-37.2c2.4,0.2,5,0.3,7.6,0.3s5.2-0.1,7.6-0.3c0.2,5-1,28.8-3.2,37.2c-1.2,4.7,8.6,6.4,18.2,6.4
                            c11.1,0,15.4-1.9,20-4.3c13.9-7.1,18.4-62.4,18.4-62.4L249.9,456.2z"/>
                        <path fill="#6A3113" d="M250,339.5c-13,0-23.6-10.9-23.6-24.3c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                            c0,10.5,8.3,19.1,18.4,19.1c10.2,0,18.4-8.6,18.4-19.1c0.1-1.4,1.3-2.5,2.8-2.5c1.3,0.1,2.4,1.1,2.5,2.5
                            C273.6,328.6,263,339.5,250,339.5z"/>
                        <path d="M311,234.6c0,0,0.2-0.2,0.4-0.4s0.7-0.7,1.3-1.1l0.9-0.8c0.4-0.3,0.8-0.5,1.3-0.8c0.5-0.3,1-0.5,1.5-0.8
                            c0.6-0.3,1.2-0.5,1.8-0.7c0.6-0.2,1.3-0.4,1.9-0.6s1.4-0.2,2.1-0.4c1.6-0.2,3.2-0.3,4.7-0.2c0.8,0.1,1.7,0.2,2.5,0.3
                            c0.4,0.1,0.9,0.1,1.3,0.2l1.3,0.4c1.7,0.5,3.4,1.1,5,1.9c1.6,0.8,3,1.8,4.4,2.9c0.7,0.5,1.3,1.1,1.9,1.8c0.6,0.6,1.2,1.2,1.8,1.8
                            c0.6,0.6,1,1.3,1.4,2c0.5,0.6,0.8,1.3,1.2,2c0.3,0.7,0.7,1.3,1,2s0.5,1.3,0.7,1.9c0.2,0.6,0.4,1.2,0.5,1.8c0.1,0.6,0.2,1.1,0.2,1.7
                            c0.1,0.5,0.1,1,0.1,1.5c0,0.5,0,0.9,0,1.3c0,0.6-0.1,1.2-0.2,1.8c-0.1,0.4-0.1,0.6-0.1,0.6v0.2c0,0.2-0.2,0.3-0.4,0.2
                            c-0.1,0-0.2-0.1-0.2-0.2l-0.8-1.9c-0.1-0.3-0.3-0.6-0.4-1l-0.6-1.1c-0.5-0.9-1-1.8-1.5-2.7l-0.9-1.5l-1.1-1.5l-0.6-0.8
                            c-0.2-0.2-0.5-0.5-0.7-0.7l-1.4-1.5c-1.1-1-2.2-1.8-3.3-2.7c-1.2-0.8-2.5-1.6-3.8-2.3c-1.3-0.7-2.7-1.2-4-1.7
                            c-1.3-0.5-2.7-0.9-4.1-1.2l-2-0.3c-0.3,0-0.7-0.1-1-0.1l-1,0l-1.8-0.1l-1.7,0.1c-1,0-2,0.1-3,0.3l-1.2,0.2l-1.1,0.2l-2,0.5
                            c-0.2,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3L311,234.6z"/>
                        <path d="M188.9,235.3l-2-0.5l-1.1-0.2l-1.2-0.2c-1-0.2-2-0.3-3-0.3l-1.7-0.1l-1.8,0.1l-1,0c-0.3,0-0.6,0.1-1,0.1l-2,0.3
                            c-1.4,0.3-2.8,0.7-4.1,1.1c-1.4,0.5-2.7,1.1-4,1.7c-1.3,0.7-2.6,1.4-3.8,2.3c-1.2,0.8-2.3,1.7-3.3,2.7l-1.4,1.5
                            c-0.2,0.2-0.5,0.5-0.7,0.7l-0.6,0.8l-1.1,1.4l-0.9,1.5c-0.6,0.9-1.1,1.7-1.5,2.7c-0.2,0.4-0.4,0.8-0.6,1.1l-0.4,1l-0.8,1.9
                            c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2v-0.2c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,0-0.8,0-1.3
                            c0-0.5,0.1-0.9,0.1-1.5s0.2-1.1,0.2-1.7c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.6,0.4-1.3,0.7-1.9c0.2-0.7,0.6-1.3,1-2
                            c0.8-1.4,1.6-2.7,2.7-4c0.5-0.6,1.1-1.2,1.8-1.8c0.3-0.3,0.6-0.6,0.9-0.9l1-0.8c2.7-2.3,6-3.9,9.4-4.8c0.8-0.3,1.6-0.5,2.4-0.6
                            c0.9-0.1,1.7-0.2,2.5-0.3c0.8-0.1,1.6,0,2.4,0c0.8,0,1.5,0.1,2.3,0.2c0.7,0.1,1.5,0.2,2.1,0.4c0.7,0.2,1.3,0.4,1.9,0.6
                            c0.6,0.2,1.2,0.4,1.8,0.7s1.1,0.5,1.5,0.8c0.5,0.3,0.9,0.5,1.3,0.8l0.9,0.8c0.6,0.4,1,0.9,1.3,1.1c0.3,0.2,0.4,0.4,0.4,0.4l0.1,0.1
                            c0.1,0.1,0.1,0.3,0,0.4C189,235.2,188.9,235.3,188.9,235.3L188.9,235.3z"/>
                        <path fill="#FFFFFF" d="M175.7,264.8c-15.4,0-27.8,12.5-27.8,27.8s12.5,27.8,27.8,27.8s27.8-12.5,27.8-27.8l0,0
                            C203.6,277.2,191.1,264.8,175.7,264.8C175.7,264.8,175.7,264.8,175.7,264.8z"/>
                        
                            <radialGradient id="SVGID_5_" cx="188.8779" cy="177.4704" r="25.1401" gradientTransform="matrix(1 0 0 -1 0 461.88)" gradientUnits="userSpaceOnUse">
                            <stop  offset="9.000000e-02" stop-color="#261F20"/>
                            <stop  offset="0.33" stop-color="#141011"/>
                            <stop  offset="0.54" stop-color="#000000"/>
                        </radialGradient>
                        <path fill="url(#SVGID_5_)" d="M180.1,272.7c-11,0-19.9,8.9-19.9,19.9c0,11,8.9,19.9,19.9,19.9c11,0,19.9-8.9,19.9-19.9l0,0
                            C200,281.6,191.1,272.7,180.1,272.7z"/>
                        <path fill="#FFFFFF" d="M324.3,264.8c15.4,0,27.8,12.5,27.8,27.8s-12.5,27.8-27.8,27.8s-27.8-12.5-27.8-27.8l0,0
                            C296.5,277.2,308.9,264.8,324.3,264.8C324.3,264.8,324.3,264.8,324.3,264.8z"/>
                        
                            <radialGradient id="SVGID_6_" cx="-81.3488" cy="177.4704" r="25.1401" gradientTransform="matrix(-1 0 0 -1 229.79 461.88)" gradientUnits="userSpaceOnUse">
                            <stop  offset="9.000000e-02" stop-color="#261F20"/>
                            <stop  offset="0.33" stop-color="#141011"/>
                            <stop  offset="0.54" stop-color="#000000"/>
                        </radialGradient>
                        <path fill="url(#SVGID_6_)" d="M319.9,272.7c11,0,19.9,8.9,19.9,19.9c0,11-8.9,19.9-19.9,19.9c-11,0-19.9-8.9-19.9-19.9
                            c0,0,0,0,0,0C300,281.6,308.9,272.7,319.9,272.7z"/>
                        <path fill="#141414" d="M194.2,393c-1.2,6-2.7,13.6-3.7,22.9h118.9c-1.1-9.7-2.4-17.8-3.4-22.9H194.2z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M403.1,266.5c-2.1-5.4-7.5-12.1-13.4-11.9
                            c-2,0.1-4,0.6-5.8,1.3c0.6,3.6,0.9,7.3,0.9,11c0,14.5-1.8,27.8-5.4,39.7c3.1,2,6.6,3.3,10.3,3.7c1.9,0.2,7.6-1,11.6-7.8
                            C402.4,300.2,410.5,285.6,403.1,266.5z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M115.3,266.9c0-3.7,0.3-7.3,0.9-11c-1.9-0.8-3.9-1.2-5.9-1.3
                            c-5.9-0.2-11.3,6.4-13.4,11.9c-7.5,19.2,0.7,33.7,2,36c4,6.9,9.6,8,11.6,7.8c3.7-0.4,7.2-1.7,10.3-3.7
                            C117.1,294.7,115.3,281.4,115.3,266.9z"/>
                </svg>
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                        <path fill={backGroundColor} d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c-6.2-40.2-52.1-85.2-107.8-85.2
                            s-101.6,44.9-107.9,85.1c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                            c2.9-0.3,5.6-1.3,8.1-2.9c8.9,29.1,32,47.9,72.3,54.1l-2.2,7.5l-8.3,3c-9.9,0.8-32.7,5.2-35.5,28.4c-5.2,44.2-26.9,85.6-7.5,95.6
                            c8.9,4.5,16.4-0.3,22.7-9.3v0.4l62.7,3.6l62.5-3.6v-0.7c6.3,9.2,14,14.2,22.9,9.6c19.4-10-2.3-51.3-7.5-95.6
                            c-2.8-23.4-26-27.7-35.8-28.5l-8.3-3l-2.2-7.5c40.5-6.2,63.6-25,72.5-54.2c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3
                            C372.6,242.1,379.2,230.4,373.2,215z"/>
                        <path fill="#141414" d="M283.3,428.5l-33.3,1.6l-33.6-1.6l-29.2-1.4c0,0.4,0,0.8,0,1.2c0.7,9.1,5.2,65.8,15.3,82.9
                            c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c3.6,0.6,7.2,0.9,10.8,0.9h0.3c9.8,0,19.7-1.8,18.5-6.5c-2.3-8.5-3.5-39.6-3.2-44.8
                            h0.3c2.3,0.2,4.6,0.3,7.1,0.3h0.3c2.2,0,4.4-0.1,6.5-0.2l1-0.1c0.3,5.2-1,36.3-3.2,44.8c-1.3,4.7,8.7,6.5,18.5,6.5h0.3
                            c3.6,0.1,7.1-0.2,10.6-0.8c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                            L283.3,428.5z"/>
                        <path fill="#141414" d="M196.4,336.3c-2.8,10.4-5.7,24.6-7.5,43.3h121.8c-1.8-18.7-4.7-33-7.5-43.3L196.4,336.3z"/>
                        <path fill="#280806" d="M168.5,198.3c1,0.1,2-0.3,2.7-1c0.3-0.3,6.6-6.2,17.2-4.8c1.9,0.2,3.6-1.2,3.8-3.1c0.2-1.8-1.1-3.4-2.9-3.7
                            c-14.3-1.9-22.7,6.4-23,6.7c-1.3,1.3-1.3,3.5,0,4.8c0,0,0,0,0,0C166.9,197.9,167.7,198.3,168.5,198.3z"/>
                        <path fill="#280806" d="M331.5,198.3c-1,0.1-2-0.3-2.7-1c-0.3-0.3-6.6-6.2-17.2-4.8c-1.9,0.2-3.6-1.2-3.8-3.1
                            c-0.2-1.8,1.1-3.4,2.9-3.7c14.3-1.9,22.6,6.4,23,6.7c1.3,1.3,1.3,3.5,0,4.8c0,0,0,0,0,0C333.1,197.9,332.3,198.3,331.5,198.3z"/>
                        <path fill="#280806" d="M337.6,234.5c-2.4,1.5-3.6,1.6-5.3,1.4c2.8-0.9,5.6-3.5,5.7-6.7c0.1-1.3-0.2-1-0.8-0.3
                            c-1.6,1.9-4.2,1.6-5,1.5c-1.6-15.5-13-21.1-21.1-21.9c-9.1-0.8-14.4,2.4-15.4,3.8c-0.5,0.8-0.6,1.9-0.1,2.8
                            c10.1-6.5,23.5-3.6,30,6.5c4.9,7.7,4.5,17.6-1,24.9l-0.1,0.2h4.4c1.2-1.6,2.1-3.4,2.7-5.3c4.3-1.1,6.1-3.9,6.6-5.8
                            C338.6,234.2,338.6,233.9,337.6,234.5z"/>
                        <path fill="#FFFFFF" d="M307.3,211.8c-12,0-21.7,9.7-21.7,21.7c0,4.8,1.6,9.5,4.6,13.3h34.3l0.1-0.2c7.2-9.6,5.3-23.2-4.2-30.4
                            C316.6,213.3,312,211.8,307.3,211.8L307.3,211.8z"/>
                        <path d="M307.8,229.8c-2.7,0-4.9-2.2-4.9-4.9l0,0c0-2.7,2.2-4.9,4.9-4.9h1.4c-5.9-3.8-13.7-2.2-17.5,3.7
                            c-3.8,5.9-2.2,13.7,3.7,17.5c5.9,3.8,13.7,2.2,17.5-3.7c1.3-2.1,2.1-4.5,2-6.9c0-0.3,0-0.5,0-0.8H307.8z"/>
                        <path fill="#280806" d="M162.4,234.8c2.4,1.6,3.6,1.6,5.3,1.4c-2.8-0.9-5.6-3.5-5.7-6.7c-0.1-1.3,0.2-1,0.8-0.3
                            c1.6,1.9,4.2,1.6,5,1.5c1.5-15.5,13-21.1,21.1-21.9c9.1-0.8,14.4,2.4,15.4,3.8c0.5,0.8,0.6,1.9,0.1,2.8c-10.1-6.5-23.5-3.6-30,6.5
                            c-4.9,7.7-4.5,17.6,1,24.9l0.1,0.2H171c-1.2-1.6-2.1-3.4-2.7-5.3c-4.3-1.1-6.1-3.9-6.6-5.8C161.4,234.5,161.4,234.1,162.4,234.8z"
                            />
                        <path fill="#FFFFFF" d="M192.7,212c12,0,21.7,9.7,21.7,21.7c0,4.8-1.6,9.5-4.5,13.3h-34.3l-0.1-0.2c-7.2-9.6-5.3-23.2,4.2-30.4
                            C183.4,213.6,188,212,192.7,212L192.7,212z"/>
                        <path d="M197.7,218.2c-2.5,0-4.9,0.7-6.9,2.1h1.4c2.7,0,4.9,2.2,4.9,4.9s-2.2,4.9-4.9,4.9c0,0,0,0,0,0h-7.1c0,0.3,0,0.5,0,0.8
                            c0,7,5.7,12.7,12.7,12.7c7,0,12.7-5.7,12.7-12.7C210.4,223.9,204.7,218.2,197.7,218.2L197.7,218.2z"/>
                        <path opacity="0.18" fill="#CECECE" d="M326.2,251.9c-6.9,0-12.4,3.5-12.4,7.9c0,4.4,5.6,7.9,12.4,7.9s12.4-3.5,12.4-7.9
                            C338.6,255.5,333,251.9,326.2,251.9z"/>
                        <path opacity="0.18" fill="#CECECE" d="M173.8,251.9c-6.9,0-12.4,3.5-12.4,7.9c0,4.4,5.6,7.9,12.4,7.9c6.9,0,12.4-3.5,12.4-7.9
                            C186.3,255.5,180.7,251.9,173.8,251.9z"/>
                        
                            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="249.3847" y1="215.5888" x2="249.3847" y2="228.8347" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                            <stop  offset="0" stop-color="#646464" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#141414" stop-opacity="0.3"/>
                        </linearGradient>
                        <path fill="url(#SVGID_10_)" d="M239.5,245.4c0,0,4.4-7,10.5-7.2c6.2-0.2,9.3,7.2,9.3,7.2C252.8,247.2,246,247.2,239.5,245.4z"/>
                        <path opacity="0.2" fill="#383838" enable-background="new    " d="M275.3,253.1c1-0.5,1.5-0.8,1.5-0.8s-17.5,0.3-25.4,0.3
                            s-25.9,0.7-25.9,0.7s12.8,3.7,25.5,3.7C261.1,257.1,271.5,254.2,275.3,253.1z"/>
                        <path opacity="0.2" fill="#111111" enable-background="new    " d="M275.3,253.1c-3.8,1.1-14.2,4-24.3,4c-12.7,0-25.5-3.7-25.5-3.7
                            s12.1,12,25.5,12s25.8-12.7,25.8-12.7S276.2,252.8,275.3,253.1z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1
                            c0.5,3,0.7,6,0.7,9.1c0.1,10.8-1.3,21.5-4.3,31.8c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3
                            C372.6,242.1,379.2,230.4,373.2,215z"/>
                        <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M142.2,206.5c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5
                            c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3c2.9-0.3,5.6-1.3,8.1-2.9c-3-10.3-4.5-21.1-4.4-31.8
                            C141.4,212.5,141.7,209.5,142.2,206.5z"/>
                    </svg>
   
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill={backGroundColor} d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1c-6.2-40.2-52.1-85.2-107.8-85.2
                                s-101.6,44.9-107.9,85.1c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3
                                c2.9-0.3,5.6-1.3,8.1-2.9c8.9,29.1,32,47.9,72.3,54.1l-2.2,7.5l-7.1,2.6c-4.6,0.3-9.2,1.6-13.2,4c-38.7,21.8-84.7,28.6-81.3,50.1
                                c4,25.7,54.9,6.4,76.8-3.2c-0.8,5.3-1.5,11-2.1,17.2c-1.2,12.1-1.9,26.2-1.9,42.3c0,0,0.1,1.9,0.2,5.1l62.7,3.6l62.5-3.6v-0.7
                                c6.3,9.2,14,14.2,22.9,9.6c19.4-10-2.3-51.3-7.5-95.6c-2.8-23.4-26-27.7-35.7-28.5l-8.3-3l-2.2-7.5c40.5-6.2,63.6-25,72.5-54.2
                                c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3C372.6,242.1,379.2,230.4,373.2,215z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M373.2,215c-1.7-4.4-6.1-9.7-10.8-9.5c-1.6,0.1-3.1,0.4-4.6,1
                                c0.5,3,0.7,6,0.7,9.1c0.1,10.8-1.3,21.5-4.3,31.8c2.4,1.6,5.2,2.5,8.1,2.9c1.5,0.1,6.1-0.8,9.3-6.3
                                C372.6,242.1,379.2,230.4,373.2,215z"/>
                            <path opacity="0.12" fill="#2B2B2B" enable-background="new    " d="M142.2,206.5c-1.5-0.6-3-0.9-4.6-1c-4.7-0.2-9,5.2-10.8,9.5
                                c-6,15.4,0.5,27.1,1.6,28.9c3.2,5.5,7.8,6.4,9.3,6.3c2.9-0.3,5.6-1.3,8.1-2.9c-3-10.3-4.5-21.1-4.4-31.8
                                C141.4,212.5,141.7,209.5,142.2,206.5z"/>
                            <path fill="#280806" d="M168.5,198.3c1,0.1,2-0.3,2.7-1c0.3-0.3,6.6-6.2,17.2-4.8c1.9,0.2,3.6-1.2,3.8-3.1c0.2-1.8-1.1-3.4-2.9-3.7
                                c-14.3-1.9-22.7,6.4-23,6.7c-1.3,1.3-1.3,3.5,0,4.8c0,0,0,0,0,0C166.9,197.9,167.7,198.3,168.5,198.3z"/>
                            <path fill="#280806" d="M331.5,198.3c-1,0.1-2-0.3-2.7-1c-0.3-0.3-6.6-6.2-17.2-4.8c-1.9,0.2-3.6-1.2-3.8-3.1
                                c-0.2-1.8,1.1-3.4,2.9-3.7c14.3-1.9,22.6,6.4,23,6.7c1.3,1.3,1.3,3.5,0,4.8c0,0,0,0,0,0C333.1,197.9,332.3,198.3,331.5,198.3z"/>
                            <path fill="#280806" d="M337.6,234.5c-2.4,1.5-3.6,1.6-5.3,1.4c2.8-0.9,5.6-3.5,5.7-6.7c0.1-1.3-0.2-1-0.8-0.3
                                c-1.6,1.9-4.2,1.6-5,1.5c-1.6-15.5-13-21.1-21.1-21.9c-9.1-0.8-14.4,2.4-15.4,3.8c-0.5,0.8-0.6,1.9-0.1,2.8
                                c10.1-6.5,23.5-3.6,30,6.5c4.9,7.7,4.5,17.6-1,24.9l-0.1,0.2h4.4c1.2-1.6,2.1-3.4,2.7-5.3c4.3-1.1,6.1-3.9,6.6-5.8
                                C338.6,234.2,338.6,233.9,337.6,234.5z"/>
                            <path fill="#FFFFFF" d="M307.3,211.8c-12,0-21.7,9.7-21.7,21.7c0,4.8,1.6,9.5,4.6,13.3h34.3l0.1-0.2c7.2-9.6,5.3-23.2-4.2-30.4
                                C316.6,213.3,312,211.8,307.3,211.8L307.3,211.8z"/>
                            <path d="M307.8,229.8c-2.7,0-4.9-2.2-4.9-4.9l0,0c0-2.7,2.2-4.9,4.9-4.9h1.4c-5.9-3.8-13.7-2.2-17.5,3.7
                                c-3.8,5.9-2.2,13.7,3.7,17.5c5.9,3.8,13.7,2.2,17.5-3.7c1.3-2.1,2.1-4.5,2-6.9c0-0.3,0-0.5,0-0.8H307.8z"/>
                            <path fill="#280806" d="M162.4,234.8c2.4,1.6,3.6,1.6,5.3,1.4c-2.8-0.9-5.6-3.5-5.7-6.7c-0.1-1.3,0.2-1,0.8-0.3
                                c1.6,1.9,4.2,1.6,5,1.5c1.5-15.5,13-21.1,21.1-21.9c9.1-0.8,14.4,2.4,15.4,3.8c0.5,0.8,0.6,1.9,0.1,2.8c-10.1-6.5-23.5-3.6-30,6.5
                                c-4.9,7.7-4.5,17.6,1,24.9l0.1,0.2H171c-1.2-1.6-2.1-3.4-2.7-5.3c-4.3-1.1-6.1-3.9-6.6-5.8C161.4,234.5,161.4,234.1,162.4,234.8z"
                                />
                            <path fill="#FFFFFF" d="M192.7,212c12,0,21.7,9.7,21.7,21.7c0,4.8-1.6,9.5-4.5,13.3h-34.3l-0.1-0.2c-7.2-9.6-5.3-23.2,4.2-30.4
                                C183.4,213.6,188,212,192.7,212L192.7,212z"/>
                            <path d="M197.7,218.2c-2.5,0-4.9,0.7-6.9,2.1h1.4c2.7,0,4.9,2.2,4.9,4.9s-2.2,4.9-4.9,4.9c0,0,0,0,0,0h-7.1c0,0.3,0,0.5,0,0.8
                                c0,7,5.7,12.7,12.7,12.7c7,0,12.7-5.7,12.7-12.7C210.4,223.9,204.7,218.2,197.7,218.2L197.7,218.2z"/>
                            <path opacity="0.18" fill="#CECECE" d="M326.2,251.9c-6.9,0-12.4,3.5-12.4,7.9c0,4.4,5.6,7.9,12.4,7.9s12.4-3.5,12.4-7.9
                                C338.6,255.5,333,251.9,326.2,251.9z"/>
                            <path opacity="0.18" fill="#CECECE" d="M173.8,251.9c-6.9,0-12.4,3.5-12.4,7.9c0,4.4,5.6,7.9,12.4,7.9c6.9,0,12.4-3.5,12.4-7.9
                                C186.3,255.5,180.7,251.9,173.8,251.9z"/>
                            
                                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="249.3847" y1="215.5888" x2="249.3847" y2="228.8347" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#646464" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#141414" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_9_)" d="M239.5,245.4c0,0,4.4-7,10.5-7.2c6.2-0.2,9.3,7.2,9.3,7.2C252.8,247.2,246,247.2,239.5,245.4z"/>
                            <path opacity="0.2" fill="#383838" enable-background="new    " d="M275.3,253.1c1-0.5,1.5-0.8,1.5-0.8s-17.5,0.3-25.4,0.3
                                s-25.9,0.7-25.9,0.7s12.8,3.7,25.5,3.7C261.1,257.1,271.5,254.2,275.3,253.1z"/>
                            <path opacity="0.2" fill="#111111" enable-background="new    " d="M275.3,253.1c-3.8,1.1-14.2,4-24.3,4c-12.7,0-25.5-3.7-25.5-3.7
                                s12.1,12,25.5,12s25.8-12.7,25.8-12.7S276.2,252.8,275.3,253.1z"/>
                            <path fill="#141414" d="M283.3,428.5l-33.3,1.6l-33.6-1.6l-29.2-1.4c0,0.4,0,0.8,0,1.2c0.7,9.1,5.2,65.8,15.3,82.9
                                c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c3.6,0.6,7.2,0.9,10.8,0.9h0.3c9.8,0,19.7-1.8,18.5-6.5c-2.3-8.5-3.5-39.6-3.2-44.8
                                h0.3c2.3,0.2,4.6,0.3,7.1,0.3h0.3c2.2,0,4.4-0.1,6.5-0.2l1-0.1c0.3,5.2-1,36.3-3.2,44.8c-1.3,4.7,8.7,6.5,18.5,6.5h0.3
                                c3.6,0.1,7.1-0.2,10.6-0.8c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                                L283.3,428.5z"/>
                            <path fill="#141414" d="M196.4,336.3c-2.8,10.4-5.7,24.6-7.5,43.3h121.8c-1.8-18.7-4.7-33-7.5-43.3L196.4,336.3z"/>
                        </svg>
       
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
