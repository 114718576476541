import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
// import {Link} from "@material-ui/core"
// import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { State } from "./adminLayout";
import SvgIcon from '@material-ui/core/SvgIcon';
import  addBtn  from '../../../assets/images/add-button.svg'

const defaultToolbarStyles = {
  iconButton: {
    backgroundColor:"#376CA7",
    color:"white"
   
  },
};

export interface Props{
    cssClasses:any;
    clickHandler:any;
    text:string;
}

export interface  State {
    
}

class CustomToolbar extends React.Component<Props, State> {
  
  handleClick = () => {
    console.log("clicked on icon!");
    window.location.href="/addUser"
  }

  render() {
    // const { classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={this.props.text}>
               
                <Button onClick={() => this.props.clickHandler()} className={this.props.cssClasses}>
                    {/* <AddIcon className={classes.deleteIcon} /> */}
                    {/* <SvgIcon> */}
                      {this.props.text}
                    {/* </SvgIcon> */}
                </Button>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);