import React from 'react';
import './avatarMenus.scss';
import { Grid, Box, ButtonBase, Button } from '@material-ui/core';

export interface Props {
    isSelected: boolean;
    label: string,
    onClick: () => void
}

export interface State {
}

class AvatarMenu extends React.Component<Props, State>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
    }

    render() {
        const { isSelected, label, onClick } = this.props;
        return (
            <div className={"item-menu"}>
                <Button style={{width:"100%"}}>
                    <Grid item className={isSelected ? "is-selected" : "not-selected"} onClick={() => onClick()}>
                        <Box component="span" className={"label-manu"}>{label}</Box>
                    </Grid>
                </Button>
            </div>
        );
    }
}

export default AvatarMenu;