import React from 'react';
import './modalComponent.scss';
import { Fade, Box, Grid, Dialog } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Scrollbars } from 'react-custom-scrollbars';
import ButtonRound from '../buttonRound/buttonRound';
import MUIDataTable from "mui-datatables";
import { Common } from '../../../common';

const avatar  = Common.getAvadarDefaut();
const close = require('../../../assets/images/close_icon.svg');


export interface Props {
    currentUser: any;
    setOpen: boolean,
    backGroundColor: any,
    childInfo: any;
    titleFirst?: string,
    titleSecond?: string,
    logo?: string,
    callback: any,
    data: Array<any>,
    titleCol: Array<any>,
    withAction?: boolean,
    history:any,
}

export interface State {
    setOpen: boolean
}

class ModalUserList extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = { setOpen: false };
    }

    async componentWillMount() {
        this.setState({ setOpen: this.props.setOpen });
    }

    goToDreamCircle(object:any){
        if(this.props.withAction){
            this.props.history.push(`/mydreamcircle/${object[0]}`)
        }
    }

    createColumns(withCategory:boolean):any{
        let columns = [
            {
                name:"id",
                label:"ID",
                options: {
                    filter: true,
                    sort: true,
                   }
            },
            {
                name:"avatarFile",
                label:"Avatar",
                options:{
                    sort:true,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div
                            style={{width: "100%"}}
                            className={"cell-edit-story"}
                        >
                            <img src={value ? value : avatar}></img>
                        </div>
                        );
                    }
                },
            },
            {
                name:"fullName",
                label:"Name",
                options:{
                    sort:true,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div
                            style={{width: "100%"}}
                            className={"cell-edit-story"}
                        >
                            {value}
                        </div>
                        );
                    }
                },
            }
        ];

        if(withCategory){
            columns.push({
                name:"categoryName",
                label:"Category",
                options:{
                    sort:true,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div
                            style={{width: "100%"}}
                            className={"cell-edit-story"}
                        >
                            {value}
                        </div>
                        );
                    }
                }
            })
        }
        return columns;
    }

    render() {
        const { titleFirst, logo, titleSecond, callback, data, childInfo, currentUser, titleCol } = this.props;
        const columns = this.createColumns(data[0].categoryName);
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            selectableRowsHeader:false,
            selectableRows:'none',
            rowsPerPage:5,
            caseSensitive:true,
            searchPlaceholder:"Search",
            onRowClick: (rowData) => {
                    this.goToDreamCircle(rowData);
            },
          };
        return (
            <div className={"modal-component"}>
                <Dialog
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.setOpen}
                    onClose={callback}
                    className={"modal-component"}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.setOpen}>
                        <Box className={"modal-box background-A"}>
                            <Grid container className={logo} style={{ padding: "30px", height: "100%" }}>
                                <Grid xs={12} style={{ display: "flex", height: "25%" }}>
                                    <Grid xs={8} style={{ position: "relative" }}>
                                        <Box className={"modal-title"}>
                                            {titleFirst ?
                                                <Box>
                                                    <span>{titleFirst}</span>
                                                </Box> : null}
                                            {titleSecond ?
                                                <Box>
                                                    <span>{titleSecond}</span>
                                                </Box> : null}
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box style={{ float: "right" }}>
                                            <ButtonRound className={"button-close"} callback={callback} icon={close}></ButtonRound>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} style={{ paddingTop: "20px", height: "75%" }}>
                                    <Scrollbars style={{ width: "100%", height: "100%" }}>
                                        <MUIDataTable
                                            data={data}
                                            options={options}
                                            columns={columns}
                                            className={"trasnparent-muipaper-root user-table-list"}
                                        ></MUIDataTable>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Dialog>
            </div>
        );
    }
}

export default ModalUserList;