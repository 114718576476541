import React from 'react'
import AdminLayout from './components/adminLayout'
import Grid from '@material-ui/core/Grid'
import UserDetailsComponent from './components/userDetailsComponent'
import UserStoriesListComponent from './components/userStoriesListComponent'
import UserChildListComponent from './components/userChildListComponent'
import  AvatarComponent from './components/avatarComponent'
import {teamMemberService} from '../../services'
import  ProgressBar from '../components/progressBar/progressBar'


export interface  Props {
    className:string,
    location:any
    history:any;
    match: any;
}
 
export interface  State {
    dates:any
    isLoading:boolean;
    teamMemberData:any;
}

let userId:any = null;


export class TeamMemberDetails extends React.Component<Props, State>{
    // export default  function UserCreate(){
        constructor(props) {
            super(props);
          
          }

          componentWillMount() {
            userId = this.props.match.params.id;
            console.log("USERID",userId);
            this.getData(userId);
        }
    
     
    
        getData(id){
            this.setState({isLoading: true});
            teamMemberService.getById(id).then((teamMember:any)=>{
                if(teamMember.success)
                {
                   
                    this.setState({teamMemberData: teamMember.data, isLoading: false})
                   
                }
             })
        }

        onEditDetailsClick(memberDetails:any){
            console.log(memberDetails);
            console.log('this is clicked ')
        }
    render(){
      console.log(this.props.location)
        return(
            <AdminLayout className={this.props.className} value={this.props.location.pathname.includes('/teammemberdetails')?"team":"child"} history={this.props.history}>
                {this.state.isLoading? 
                   <ProgressBar></ProgressBar>
                :
                <Grid container>
                    <Grid item xs={12}  className={""}>
                        <AvatarComponent memberDetails={this.state.teamMemberData} isChildPageCall={false} labelName={"Team Member"} history={this.props.history}></AvatarComponent>
                    </Grid>
                        {/* <Grid item xs={6} > */}
                            <Grid item xs={11} lg={6} style={{padding:"10px"}}>
                                <UserDetailsComponent refresh={() => this.componentWillMount()} memberDetails={this.state.teamMemberData} isChildPageCall={false} onClick={()=>this.onEditDetailsClick(this.state.teamMemberData)}></UserDetailsComponent>
                            </Grid>
                            <Grid item xs={11} lg={6} className="remove-top-margin-team-member" style={{padding:"10px"}}>
                                <UserStoriesListComponent storyListData={this.state.teamMemberData} userId={userId} isChildrenStory={false}></UserStoriesListComponent>
                            </Grid>
                        {/* </Grid> */}
                        {/* <Grid item xs={6} > */}
                            <Grid item  xs={11} lg={6} style={{padding:"10px"}}>
                            <UserChildListComponent refresh={() => this.componentWillMount()} childListData={this.state.teamMemberData} history={this.props.history}></UserChildListComponent>
                            </Grid>
                        {/* </Grid> */}
                    </Grid>
               }
            </AdminLayout>
        )
    }
}

