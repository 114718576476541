import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';
import { backGroundName } from '../../../enums';

export interface DingoProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface DingoState {
    height:string
}


export  class Dingo extends React.Component<DingoProps, DingoState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M394.1,406.5C381,417,367,414.3,367,414.3c20.2-21.7,11-46.5,11-46.5c-12.9,21.2-26.8,36.1-40.4,46.6
                            c0,0,0,0,0,0c-6.5-18.2-26.2-38.8-32-44.6c32-7.9,53.5-23.6,65.8-45.8c4.9-8.8,8.4-18.7,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8
                            c-0.6-0.9-1.3-1.8-2-2.7c-0.7-0.9-1.5-1.7-2.2-2.5c0.8,0.8,1.5,1.7,2.2,2.5c0.7,0.9,1.4,1.8,2,2.7c0.3-2.6,0.6-5.2,0.8-7.9
                            c0.3-3.9,0.4-7.8,0.4-11.9c0-2.5-0.1-5.1-0.4-7.7c-0.8-7.8-2.9-15.8-6.1-23.7c-0.4-1.1-0.9-2.2-1.4-3.4
                            c6.6-12.1,13.5-25.1,15.1-36.7c5-36-16.2-92.5-33.7-102c-17-9.3-26.3,50.9-41,67c-1.8,2-3.1,3.9-3.9,5.7
                            c-18.8-9.9-40.1-15.9-62.8-15.9c-22.8,0-44.3,6.1-63.2,16.1c-0.8-1.8-2.1-3.8-4-5.9c-14.7-16.2-24-76.3-41-67
                            c-17.5,9.5-38.7,66-33.7,102c1.6,11.8,8.6,24.9,15.3,37.1c-0.4,0.9-0.8,1.7-1.1,2.6c-4.3,10.6-6.6,21.4-6.6,31.6
                            c0,6.7,0.4,13.1,1.1,19.2c0.4,2.9,0.8,5.8,1.3,8.5c2.1,10.9,5.6,20.8,10.5,29.6c12.3,22.2,33.6,37.8,65.4,45.7
                            c-8.8,8.8-52,54.1-25.9,69.7c8.3,5,15.1,3.5,20.6-1.1c-0.3,6.1-0.4,12.4-0.3,19c0,0,0.1,22.4,1.4,34.3c0.2,1.7,0.5,3.4,0.8,5.1
                            c2.5-0.4,9.5,1,19.4,3.1c0,0,6.7,5.6,8.2,16.6c10.4-10.7,8.2-42.9,8.2-42.9c11.5,1.6,23.3,1.8,23.3,1.8s11.8-0.2,23.3-1.8
                            c0,0-2.2,33.2,8.7,43.4c1.5-11.1,8.2-16.6,8.2-16.6c9.2-2,16-3.4,18.9-3.2c0.4-1.8,0.7-3.7,0.9-5.5c0.4-3.4,0.7-7.7,0.9-12
                            c0,0,0,0,0,0c3.3-0.1,5.2-0.5,5.2-0.5c-2.3-1.5-3.9-2.9-5-4.3c0.1-3.3,0.2-6.5,0.2-9.2c42.6,8.4,54.3-14.6,54.3-14.6
                            c-14.7,3.2-27.2-1.8-27.2-1.8C390.1,432.3,394.1,406.5,394.1,406.5z M252.4,245.9c2.3,0.3,4.3,0.6,6.2,1.1
                            C256.7,246.6,254.7,246.2,252.4,245.9c-0.1,0-0.1,0-0.2,0C252.2,245.9,252.3,245.9,252.4,245.9z M247.7,245.9
                            c0.4,0,0.7,0.1,1.1,0.1C248.4,246,248,246,247.7,245.9c-0.1,0-0.1,0-0.2,0C247.5,245.9,247.6,245.9,247.7,245.9z M187.9,261.3
                            c1.3,0.1,2.7,0.3,4,0.4c1.3,0.1,2.6,0.2,3.9,0.3c1.9,0.1,3.8,0.2,5.6,0.2c0.6,0,1.1,0,1.7,0c-0.5,0-1.1,0-1.7,0
                            c-1.8,0-3.6-0.1-5.6-0.2c-1.3-0.1-2.6-0.2-3.9-0.3C190.6,261.6,189.2,261.4,187.9,261.3z M301.1,370.8c0.2,0,0.4-0.1,0.6-0.1
                            l-0.5,0.6C301.2,371.1,301.2,370.9,301.1,370.8z"/>
                        <path opacity="0.2" fill="#FFFFFF" d="M304,368.2l-2.8,3c-4.2-16.2-8.1-25.2-8.1-25.2l-0.5-0.1c-1.2-3.7-2-5.8-2-5.8l-40.8-10.2
                            L209,340.1c0,0-0.8,2.1-2,5.8l-0.5,0.1c0,0-3.4,7.8-7.2,22l-3.6,0.1c0,0-56.4,53.9-27.4,71.2c8.3,5,15.1,3.5,20.6-1.1
                            c-0.3,6.1-0.4,12.4-0.3,19c0,0,0.1,22.4,1.4,34.3c0.2,1.7,0.5,3.4,0.8,5.1c2.5-0.4,9.5,1,19.4,3.1c0,0,6.7,5.6,8.2,16.6
                            c10.4-10.7,8.2-42.9,8.2-42.9c11.5,1.6,23.3,1.8,23.3,1.8s11.8-0.2,23.3-1.8c0,0-2.2,33.2,8.7,43.4c1.5-11.1,8.2-16.6,8.2-16.6
                            c9.2-2,16-3.4,18.9-3.2c0.4-1.8,0.7-3.7,0.9-5.5c1.4-11.9,1.4-34.3,1.4-34.3c0.1-6.6-0.1-12.9-0.3-19c5.4,4.6,12.2,6,20.6,1.1
                            C360.4,422.1,304,368.2,304,368.2z"/>
                        <path opacity="0.2" fill="#FFFFFF" d="M357.8,93.2c-17-9.3-26.3,50.9-41,67c-1.8,2-3.1,3.9-3.9,5.7c-18.8-9.9-40.1-15.9-62.8-15.9
                            c-22.8,0-44.3,6.1-63.2,16.1c-0.8-1.8-2.1-3.8-4-5.9c-14.7-16.2-24-76.3-41-67c-17.5,9.5-38.7,66-33.7,102
                            c1.6,11.8,8.6,24.9,15.3,37.1c-0.4,0.9-0.8,1.7-1.1,2.6c-4.3,10.6-6.6,21.4-6.6,31.6c0,6.7,0.4,13.1,1.1,19.2
                            c13.8-19.3,44.8-27.8,63.6-25.3c7.1,0.9,14.6,1.6,20.9,1.7c4.6,0.1,8.5-0.1,11-0.6c15.2-3.3,15.2-13.4,34.9-15.6c0.1,0,0.1,0,0.2,0
                            c0.1,0,0.1,0,0.2,0c0.8,0.1,1.5,0.2,2.2,0.3c0.7-0.1,1.4-0.2,2.2-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                            c19.7,2.2,19.7,12.3,34.9,15.6c2.5,0.5,6.4,0.7,11,0.6c6.3-0.1,13.9-0.8,20.9-1.7c19-2.5,50.4,6.2,64,25.9c0.3-2.6,0.6-5.2,0.8-7.9
                            c0.3-3.9,0.4-7.8,0.4-11.9c0-2.5-0.1-5.1-0.4-7.7c-0.8-7.8-2.9-15.8-6.1-23.7c-0.4-1.1-0.9-2.2-1.4-3.4
                            c6.6-12.1,13.5-25.1,15.1-36.7C396.5,159.2,375.3,102.7,357.8,93.2z M123.8,163.5c1.9-8.7,15.6-38.6,21.4-45.3
                            c5.8,13.8,32.6,56.2,11.4,67.5c-4.1-3.1-6.6-7.4-7.1-11.9c-1.1,6.3-1.4,12.4-1.6,18.9c-2.8-2.8-5.1-4.7-6.4-8.1
                            c-1.8,10.5-5.7,19.8-9.4,30.6C121.5,200.2,120.2,180,123.8,163.5z M367.4,215.1c-3.6-10.8-7.6-20-9.4-30.6
                            c-1.3,3.4-3.6,5.4-6.4,8.1c-0.2-6.5-0.5-12.6-1.6-18.9c-0.5,4.6-3,8.8-7.1,11.9c-21.2-11.3,5.6-53.8,11.4-67.5
                            c5.8,6.7,19.5,36.6,21.4,45.3C379.5,180,378.1,200.2,367.4,215.1z"/>
                        <path fill="#EEE6D9" d="M290.6,340.1l-40.8-10.2L209,340.1c0,0-49.8,129,40.8,129C340.4,469.1,290.6,340.1,290.6,340.1z"/>
                        
                            <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="3780.4626" y1="574.9702" x2="3779.2019" y2="551.0143" gradientTransform="matrix(-0.9995 -3.020000e-02 -3.020000e-02 0.9995 4094.8784 60.2872)">
                            <stop  offset="0" stop-color="#D2BCA9"/>
                            <stop  offset="1" stop-color="#E4D0BD"/>
                        </linearGradient>
                        <path fill="url(#SVGID_51_)" d="M316.7,517.4c0,0-1.3-15.1-6.5-19.8c-1.2-1.1-8.9,0.3-20.2,2.8c0,0-6.8,5.6-8.2,16.9
                            C281.8,517.3,300.9,523.3,316.7,517.4z"/>
                        <path fill="#AD9C8B" d="M310,519.3c-1.1-5.8-2.2-11.5-3.8-17.2c2.1,5.5,3.8,11.2,5.3,16.9L310,519.3z"/>
                        <path fill="#AD9C8B" d="M296.2,519.9c0.2-1.4,0.3-2.7,0.4-4c0.1-1.3,0.1-2.7,0.1-4c0-2.7-0.3-5.4-0.7-8.1c0.7,2.6,1.2,5.3,1.5,8
                            c0.2,1.4,0.3,2.7,0.3,4.1c0.1,1.4,0.1,2.8,0,4.1L296.2,519.9z"/>
                        
                            <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="9797.1729" y1="756.2965" x2="9795.9121" y2="732.3407" gradientTransform="matrix(0.9995 -3.020000e-02 3.020000e-02 0.9995 -9613.9277 60.2872)">
                            <stop  offset="0" stop-color="#D2BCA9"/>
                            <stop  offset="1" stop-color="#E4D0BD"/>
                        </linearGradient>
                        <path fill="url(#SVGID_52_)" d="M183.4,517c0,0,1.3-15.1,6.5-19.8c1.2-1.1,8.9,0.3,20.2,2.8c0,0,6.8,5.6,8.2,16.9
                            C218.4,516.8,199.3,522.8,183.4,517z"/>
                        <path fill="#AD9C8B" d="M190.2,518.8c1.1-5.8,2.2-11.5,3.8-17.2c-2.1,5.5-3.8,11.2-5.3,16.9L190.2,518.8z"/>
                        <path fill="#AD9C8B" d="M204,519.4c-0.2-1.4-0.3-2.7-0.4-4c-0.1-1.3-0.1-2.7-0.1-4c0-2.7,0.3-5.4,0.7-8.1c-0.7,2.6-1.2,5.3-1.5,8
                            c-0.2,1.4-0.3,2.7-0.3,4.1c-0.1,1.4-0.1,2.8,0,4.1L204,519.4z"/>
                        <path fill="#45150B" d="M287.5,182.4l-3.1,13.1c0,0,42.4-3.5,64.5,10.3c-0.9-3.5-1.9-5.3-1.9-5.3S320,183.3,287.5,182.4z"/>
                        <path fill="#45150B" d="M213.9,182.4l1.3,13.1c0,0-42.4-3.5-64.5,10.3c-0.4-1.7,0.5-4.4,0.5-4.4S181.4,183.3,213.9,182.4z"/>
                        <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="321.9036" y1="237.8759" x2="323.7079" y2="204.7971">
                            <stop  offset="4.469238e-03" stop-color="#EAEAEA" stop-opacity="0"/>
                            <stop  offset="0.9927" stop-color="#1E3A3A"/>
                        </linearGradient>
                        <path opacity="0.3" fill="url(#SVGID_53_)" d="M349,205.8c-22.1-13.9-64.5-10.3-64.5-10.3l-2.5,26.5l-4.1,20.4l28.5,6l43.9-3.2
                            l15.1-0.7C365.4,244.6,360.6,225.1,349,205.8z"/>
                        <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="178.4636" y1="235.9919" x2="180.3042" y2="202.249">
                            <stop  offset="4.469238e-03" stop-color="#EAEAEA" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#3A3A3A"/>
                        </linearGradient>
                        <path opacity="0.3" fill="url(#SVGID_54_)" d="M217.7,222l-2.5-26.5c0,0-42.4-3.5-64.5,10.3c-11.7,19.3-16.4,38.7-16.4,38.7
                            l15.1,0.7l43.9,3.2l28.5-6L217.7,222z"/>
                        <path fill="#FFFFFF" d="M319.4,207.6c16.2,0,29.2,13.1,29.2,29.2c0,16.2-13.1,29.2-29.2,29.2c-16.1,0-29.2-13.1-29.2-29.2
                            C290.2,220.7,303.3,207.6,319.4,207.6"/>
                        <path fill="#301E1E" d="M313.1,219c11,0,19.8,8.9,19.8,19.8c0,11-8.9,19.8-19.8,19.8c-11,0-19.8-8.9-19.8-19.8
                            C293.2,227.9,302.1,219,313.1,219z"/>
                        <path fill="#FFFFFF" d="M321.6,238.8h13.7c4.4,0,8-3.6,8-8v0c0-4.4-3.6-8-8-8h-13.7c-4.4,0-8,3.6-8,8v0
                            C313.5,235.2,317.1,238.8,321.6,238.8z"/>
                        <path fill="#FFFFFF" d="M180.6,207.6c-16.2,0-29.2,13.1-29.2,29.2c0,16.2,13.1,29.2,29.2,29.2c16.1,0,29.2-13.1,29.2-29.2
                            C209.9,220.7,196.8,207.6,180.6,207.6"/>
                        <path fill="#301E1E" d="M187,219c-11,0-19.8,8.9-19.8,19.8c0,11,8.9,19.8,19.8,19.8c11,0,19.8-8.9,19.8-19.8
                            C206.8,227.9,197.9,219,187,219z"/>
                        <path fill="#FFFFFF" d="M178.5,238.8h-13.7c-4.4,0-8-3.6-8-8v0c0-4.4,3.6-8,8-8h13.7c4.4,0,8,3.6,8,8v0
                            C186.5,235.2,182.9,238.8,178.5,238.8z"/>
                        <path fill="#EEE6D9" d="M319.1,260.4c-7.1,0.9-14.6,1.6-20.9,1.7c-4.6,0.1-8.5-0.1-11-0.6c-15.2-3.3-15.2-13.4-34.9-15.6
                            c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.5,0.2-2.2,0.3c-0.7-0.1-1.4-0.2-2.2-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
                            c-19.7,2.2-19.7,12.3-34.9,15.6c-2.5,0.5-6.4,0.7-11,0.6c-6.3-0.1-13.9-0.8-20.9-1.7c-18.9-2.5-49.8,6-63.6,25.3
                            c0.4,2.9,0.8,5.8,1.3,8.5c2.1,10.9,5.6,20.8,10.5,29.6c15,27.1,43.5,44.3,88.1,49.8c3,0.4,6,0.7,9.1,1c7.6,0.6,15.7,1,24.1,1
                            c8.5,0,16.5-0.3,24.1-1c3.2-0.3,6.4-0.6,9.4-1c44.4-5.6,72.9-22.8,87.8-49.9c4.9-8.8,8.4-18.7,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8
                            C369.5,266.6,338.2,257.9,319.1,260.4z"/>
                        <path fill="#FFFFFF" d="M289.4,320.2c-0.4-1-2.5-5.6-5.9-5.8c-3.1-0.1-5.3,3.5-6.1,5c-1.5,2.5-1.9,5.1-1.9,6.8c0.1,0,0.1,0,0.2,0
                            c0.7,0,1.5,0.1,2.2,0.1c0.3,0,0.6,0,0.9,0c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
                            c0.1,0,0.1,0,0.2,0c2.5,0,4.8-0.1,7.1-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.5-0.1,0.9-0.1,1.3-0.2
                            c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.6-0.1C290.4,324.3,290.3,322.3,289.4,320.2z"/>
                        <path fill="#FFFFFF" d="M217,314.3c-2.8-0.3-5.4,3.5-6.3,6.4c-0.7,2.1-0.5,3.9-0.3,5.2c0,0,0,0,0.1,0c0.4,0.1,0.9,0.1,1.3,0.2
                            c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c2.3,0.2,4.7,0.3,7.1,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
                            c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0,0.9,0c0.4,0,0.9,0,1.3-0.1c-0.1-1.7-0.5-4-1.6-6.4
                            C220.8,317.6,219.4,314.5,217,314.3z"/>
                        <path fill="#45150B" d="M324.2,278.5C324.2,278.5,324.2,278.5,324.2,278.5c-6.6-6.3-16,2-31.9,1.5c-6.9-0.2-14-1.2-23-7.8
                            c0,0,0,0,0,0c-1.6-1.2-3.4-2.2-5.3-3c-2.8-1.2-5.9-2-9-2.4c-1.3-2.6-2.2-5-2.8-7.2c-0.7,0.3-1.4,0.6-2,0.8c-0.6-0.2-1.3-0.5-2-0.8
                            c-0.6,2.2-1.5,4.6-2.8,7.2c-3.1,0.3-6.2,1.2-9,2.4c-1.9,0.8-3.7,1.9-5.3,3c0,0,0,0,0,0c-9,6.6-16.2,7.6-23,7.8
                            c-16,0.4-25.3-7.8-31.9-1.5c0,0,0,0,0,0c-0.9,0.8-1.4,3.5-1.3,6.1c0,0.4,0,0.7,0.1,1.1c0,0.1,0,0.2,0.1,0.4
                            c2.1,12.5,5.7,24.6,16.6,32.8c3.9,2.9,9.4,5.2,15.9,6.4c0.3,0.1,0.6,0.1,0.8,0.2c0.7,0.1,1.5,0.2,2.2,0.3c0,0,0.1,0,0.1,0
                            c-0.2-1.2-0.4-3.1,0.3-5.2c0.9-2.8,3.5-6.6,6.3-6.4c2.4,0.2,3.8,3.3,4.9,5.6c1.1,2.4,1.5,4.7,1.6,6.4c0.3,0,0.6,0,0.9,0
                            c0.4,0,0.9-0.1,1.3-0.1c0.7-0.1,1.5-0.1,2.2-0.2c0.5-0.1,0.9-0.1,1.4-0.2c0.7-0.1,1.4-0.2,2.2-0.3c0.5-0.1,1-0.2,1.5-0.3
                            c0.7-0.1,1.4-0.2,2.1-0.4c0.6-0.1,1.1-0.3,1.7-0.4c0.7-0.1,1.3-0.3,2-0.5c0.6-0.2,1.3-0.4,1.9-0.5c0.6-0.2,1.2-0.3,1.8-0.5
                            c0.8-0.2,1.6-0.5,2.3-0.8c0.5-0.2,0.9-0.3,1.4-0.5c1.3-0.4,2.5-0.9,3.8-1.4c0,0,0,0,0,0c0,0,0,0,0,0c1.3,0.5,2.5,1,3.8,1.4
                            c0.5,0.2,0.9,0.3,1.4,0.5c0.8,0.3,1.6,0.5,2.3,0.8c0.6,0.2,1.2,0.3,1.8,0.5c0.6,0.2,1.3,0.4,1.9,0.5c0.7,0.2,1.3,0.3,2,0.5
                            c0.6,0.1,1.1,0.3,1.7,0.4c0.7,0.1,1.4,0.3,2.1,0.4c0.5,0.1,1,0.2,1.5,0.3c0.7,0.1,1.5,0.2,2.2,0.3c0.5,0.1,0.9,0.1,1.4,0.2
                            c0.7,0.1,1.5,0.1,2.2,0.2c0.4,0,0.7,0.1,1.1,0.1c0-1.7,0.4-4.2,1.9-6.8c0.9-1.5,3-5.1,6.1-5c3.4,0.1,5.4,4.8,5.9,5.8
                            c0.9,2.1,1.1,4.1,1,5.5c0.5-0.1,1.1-0.2,1.6-0.3c0.3,0,0.5-0.1,0.8-0.2c6.5-1.3,12-3.5,15.9-6.4c10.9-8.2,14.5-20.3,16.6-32.8
                            c0-0.1,0-0.2,0.1-0.4c0-0.4,0.1-0.7,0.1-1.1C325.6,282,325.1,279.3,324.2,278.5z"/>
                        <path d="M234.2,298.2c4.2,1.5,7.4,4.8,11.7,6.1c0.7,0.2,1.3,0.3,2,0.4c0,0,0,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.5,0
                            c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0.1,0
                            c0.7-0.1,1.3-0.2,2-0.4c4.2-1.3,7.5-4.5,11.7-6.1c3.2-1.2,6.1-1.4,8.7-1c-4-6.5-11.3-12.7-17.8-12c-8.1,0.9-1.6,5.3-3,10.5
                            c-1.4,5.2-7.6,5-8.2-0.7c-0.4-4,2.8-6.4,1.6-8.4c-0.8-1.3-7.1-4.9-16.6,3.8c-2.2,2-4.4,4.4-5.9,7
                            C227.4,296.7,230.7,296.9,234.2,298.2z"/>
                        <path fill="#FF828B" d="M274.4,297c0,0.1,0.1,0.1,0.1,0.2c-2.6-0.4-5.5-0.2-8.7,1c-4.2,1.5-7.4,4.8-11.7,6.1
                            c-0.7,0.2-1.3,0.3-2,0.4c0,0,0,0-0.1,0c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6,0
                            c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c0,0,0,0-0.1,0c-0.7-0.1-1.3-0.2-2-0.4
                            c-4.2-1.3-7.5-4.5-11.7-6.1c-3.4-1.2-6.5-1.5-9.2-0.9c-5.6,2.7-8.9,9.6-8.8,17.7c0,5.3,1.5,11.1,4.7,16.6
                            c6.2,10.5,17.5,16.9,29.1,17.4c11.7-0.5,23-6.9,29.1-17.4c3.2-5.4,4.7-11.3,4.7-16.6C283.9,306.6,280.4,299.5,274.4,297z"/>
                        <path fill="#FF6E76" d="M257.4,306.2c-2.1-0.6-4.9,1.3-7,4.6c-2.1-4.6-7.4-8.8-10.4,0.7c-3.1,9.7,3.3,24.3,9.7,24.4
                            c6,0.1,11.9-12.2,11.1-21.9C260.7,312.2,260.2,307,257.4,306.2z"/>
                        <path fill="#230905" d="M263.4,245.7c-1-3.1-4-2.8-7.2-2.6c-2.3,0.3-4.2,0.4-6.1,0.4c-1.9,0-3.8-0.1-6.1-0.4
                            c-3.2-0.2-6.2-0.5-7.2,2.6c-0.3,1.9,0.3,3.8,1.3,5.5c2.3,3.5,7.1,7.1,10.9,8.8c0.4,0.2,0.7,0.3,1.1,0.5c0.4-0.1,0.7-0.3,1.1-0.5
                            c3.8-1.7,8.6-5.3,10.9-8.8C263.1,249.6,263.7,247.7,263.4,245.7z M246.6,254.9c-2.2,0-4.1-2.2-5.6-4.7
                            C244.5,250,247,251.5,246.6,254.9z M253.4,254.9c-0.3-3.4,2.2-5,5.6-4.7C257.5,252.8,255.6,254.9,253.4,254.9z"/>
                    </svg>

        return kid;     
    }
    getkidWithTool(backGroundColor){
      const kid=    <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M394.1,406.5C381,417,367,414.3,367,414.3c20.2-21.7,11-46.5,11-46.5c-12.9,21.2-26.8,36.1-40.4,46.6
                            c0,0,0,0,0,0c-6.5-18.2-26.2-38.8-32-44.6c32-7.9,53.5-23.6,65.8-45.8c4.9-8.8,8.4-18.7,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8
                            c-0.3-0.5-0.6-0.9-1-1.4c0.3,0.4,0.7,0.9,1,1.4c0.3-2.6,0.6-5.2,0.8-7.9c0.3-3.9,0.4-7.8,0.4-11.9c0-2.5-0.1-5.1-0.4-7.7
                            c-0.8-7.8-2.9-15.8-6.1-23.7c-0.4-1.1-0.9-2.2-1.4-3.4c6.6-12.1,13.5-25.1,15.1-36.7c5-36-16.2-92.5-33.7-102
                            c-17-9.3-26.3,50.9-41,67c-1.8,2-3.1,3.9-3.9,5.7c-18.8-9.9-40.1-15.9-62.8-15.9c-22.8,0-44.3,6.1-63.2,16.1
                            c-0.8-1.8-2.1-3.8-4-5.9c-14.7-16.2-24-76.3-41-67c-17.5,9.5-38.7,66-33.7,102c1.6,11.8,8.6,24.9,15.3,37.1
                            c-0.4,0.9-0.8,1.7-1.1,2.6c-4.3,10.6-6.6,21.4-6.6,31.6c0,6.7,0.4,13.1,1.1,19.2c0.4,2.9,0.8,5.8,1.3,8.5
                            c2.1,10.9,5.6,20.8,10.5,29.6c12.3,22.2,33.6,37.8,65.4,45.7l3.6,0.9l-5.2,0.8c-21.9,3.4-60,12.7-51.9,35.6
                            c8.8,24.8,39.2,5.8,51.3-3.1c-1.5,10.3-2.6,21.9-3.2,34.5l0,0c-0.1,1.4-0.1,2.8-0.2,4.2c0,0.3,0,0.6,0,0.9c0,1.2-0.1,2.5-0.1,3.7
                            c0,0.2,0,0.4,0,0.6c0,1.3,0,2.6,0,3.9c0,0.5,0,1.1,0,1.6c0,1,0,2,0,3c0,0.3,0,0.7,0,1c0,0,0.1,22.4,1.4,34.3
                            c0.2,1.6,0.4,3.2,0.7,4.7c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.5-0.1,0.8-0.1c3.1,0,9.6,1.3,18.3,3.2
                            c0,0,6.8,5.6,8.2,16.9l0.1,0.1c10.6-10.4,8.4-42.9,8.4-43.2c11.5,1.6,23.2,1.8,23.2,1.8s0.8,0,2.1-0.1c3.8-0.1,12.6-0.5,21.2-1.7
                            c0,0.3-2.2,33.2,8.7,43.4c1.5-11.1,8.2-16.6,8.2-16.6c8.4-1.9,14.8-3.1,18-3.2c0.4,0,0.7,0,1,0.1c0.4-1.8,0.7-3.7,0.9-5.6
                            c0.4-3.4,0.7-7.7,0.9-12.1c3.2-0.1,5-0.5,5-0.5c-2.1-1.4-3.7-2.7-4.9-4c0.1-3.4,0.2-6.6,0.3-9.4c42.4,8.3,54.2-14.6,54.2-14.6
                            c-14.7,3.2-27.2-1.8-27.2-1.8C390.1,432.3,394.1,406.5,394.1,406.5z"/>
                        <path fill="#EEE6D9" d="M319.1,260.4c-7.1,0.9-14.6,1.6-20.9,1.7c-4.6,0.1-8.5-0.1-11-0.6c-15.2-3.3-15.2-13.4-34.9-15.6
                            c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.5,0.2-2.2,0.3c-0.7-0.1-1.4-0.2-2.2-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
                            c-19.7,2.2-19.7,12.3-34.9,15.6c-2.5,0.5-6.4,0.7-11,0.6c-6.3-0.1-13.9-0.8-20.9-1.7c-18.9-2.5-49.8,6-63.6,25.3
                            c0.4,2.9,0.8,5.8,1.3,8.5c2.1,10.9,5.6,20.8,10.5,29.6c15,27.1,43.5,44.3,88.1,49.8c3,0.4,6,0.7,9.1,1c7.6,0.6,15.7,1,24.1,1
                            c8.5,0,16.5-0.3,24.1-1c3.2-0.3,6.4-0.6,9.4-1c44.4-5.6,72.9-22.8,87.8-49.9c4.9-8.8,8.4-18.7,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8
                            C369.5,266.6,338.2,257.9,319.1,260.4z"/>
                        <path opacity="0.2" fill="#FFFFFF" d="M357.8,93.2c-17-9.3-26.3,50.9-41,67c-1.8,2-3.1,3.9-3.9,5.7c-18.8-9.9-40.1-15.9-62.8-15.9
                            c-22.8,0-44.3,6.1-63.2,16.1c-0.8-1.8-2.1-3.8-4-5.9c-14.7-16.2-24-76.3-41-67c-17.5,9.5-38.7,66-33.7,102
                            c1.6,11.8,8.6,24.9,15.3,37.1c-0.4,0.9-0.8,1.7-1.1,2.6c-4.3,10.6-6.6,21.4-6.6,31.6c0,6.7,0.4,13.1,1.1,19.2
                            c13.8-19.3,44.8-27.8,63.6-25.3c7.1,0.9,14.6,1.6,20.9,1.7c4.6,0.1,8.5-0.1,11-0.6c15.2-3.3,15.2-13.4,34.9-15.6c0.1,0,0.1,0,0.2,0
                            c0.1,0,0.1,0,0.2,0c0.8,0.1,1.5,0.2,2.2,0.3c0.7-0.1,1.4-0.2,2.2-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                            c19.7,2.2,19.7,12.3,34.9,15.6c2.5,0.5,6.4,0.7,11,0.6c6.3-0.1,13.9-0.8,20.9-1.7c19-2.5,50.4,6.2,64,25.9c0.3-2.6,0.6-5.2,0.8-7.9
                            c0.3-3.9,0.4-7.8,0.4-11.9c0-2.5-0.1-5.1-0.4-7.7c-0.8-7.8-2.9-15.8-6.1-23.7c-0.4-1.1-0.9-2.2-1.4-3.4
                            c6.6-12.1,13.5-25.1,15.1-36.7C396.5,159.2,375.3,102.7,357.8,93.2z M123.8,163.5c1.9-8.7,15.6-38.6,21.4-45.3
                            c5.8,13.8,32.6,56.2,11.4,67.5c-4.1-3.1-6.6-7.4-7.1-11.9c-1.1,6.3-1.4,12.4-1.6,18.9c-2.8-2.8-5.1-4.7-6.4-8.1
                            c-1.8,10.5-5.7,19.8-9.4,30.6C121.5,200.2,120.2,180,123.8,163.5z M367.4,215.1c-3.6-10.8-7.6-20-9.4-30.6
                            c-1.3,3.4-3.6,5.4-6.4,8.1c-0.2-6.5-0.5-12.6-1.6-18.9c-0.5,4.6-3,8.8-7.1,11.9c-21.2-11.3,5.6-53.8,11.4-67.5
                            c5.8,6.7,19.5,36.6,21.4,45.3C379.5,180,378.1,200.2,367.4,215.1z"/>
                        <path fill="#EEE6D9" d="M290.6,340.1l-40.8-10.2L209,340.1c0,0-49.8,129,40.8,129C340.4,469.1,290.6,340.1,290.6,340.1z"/>
                        <path fill="#FFFFFF" d="M289.4,320.2c-0.4-1-2.5-5.6-5.9-5.8c-3.1-0.1-5.3,3.5-6.1,5c-1.5,2.5-1.9,5.1-1.9,6.8c0.1,0,0.1,0,0.2,0
                            c0.7,0,1.5,0.1,2.2,0.1c0.3,0,0.6,0,0.9,0c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
                            c0.1,0,0.1,0,0.2,0c2.5,0,4.8-0.1,7.1-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.5-0.1,0.9-0.1,1.3-0.2
                            c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.6-0.1C290.4,324.3,290.3,322.3,289.4,320.2z"/>
                        <path fill="#FFFFFF" d="M217,314.3c-2.8-0.3-5.4,3.5-6.3,6.4c-0.7,2.1-0.5,3.9-0.3,5.2c0,0,0,0,0.1,0c0.4,0.1,0.9,0.1,1.3,0.2
                            c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c2.3,0.2,4.7,0.3,7.1,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
                            c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0,0.9,0c0.4,0,0.9,0,1.3-0.1c-0.1-1.7-0.5-4-1.6-6.4
                            C220.8,317.6,219.4,314.5,217,314.3z"/>
                        <path fill="#230905" d="M263.4,245.7c-1-3.1-4-2.8-7.2-2.6c-2.3,0.3-4.2,0.4-6.1,0.4c-1.9,0-3.8-0.1-6.1-0.4
                            c-3.2-0.2-6.2-0.5-7.2,2.6c-0.3,1.9,0.3,3.8,1.3,5.5c2.3,3.5,7.1,7.1,10.9,8.8c0.4,0.2,0.7,0.3,1.1,0.5c0.4-0.1,0.7-0.3,1.1-0.5
                            c3.8-1.7,8.6-5.3,10.9-8.8C263.1,249.6,263.7,247.7,263.4,245.7z M246.6,254.9c-2.2,0-4.1-2.2-5.6-4.7
                            C244.5,250,247,251.5,246.6,254.9z M253.4,254.9c-0.3-3.4,2.2-5,5.6-4.7C257.5,252.8,255.6,254.9,253.4,254.9z"/>
                        <path fill="#45150B" d="M324.2,278.5C324.2,278.5,324.2,278.5,324.2,278.5c-6.6-6.3-16,2-31.9,1.5c-6.9-0.2-14-1.2-23-7.8
                            c0,0,0,0,0,0c-1.6-1.2-3.4-2.2-5.3-3c-2.8-1.2-5.9-2-9-2.4c-1.3-2.6-2.2-5-2.8-7.2c-0.7,0.3-1.4,0.6-2,0.8c-0.6-0.2-1.3-0.5-2-0.8
                            c-0.6,2.2-1.5,4.6-2.8,7.2c-3.1,0.3-6.2,1.2-9,2.4c-1.9,0.8-3.7,1.9-5.3,3c0,0,0,0,0,0c-9,6.6-16.2,7.6-23,7.8
                            c-16,0.4-25.3-7.8-31.9-1.5c0,0,0,0,0,0c-0.9,0.8-1.4,3.5-1.3,6.1c0,0.4,0,0.7,0.1,1.1c0,0.1,0,0.2,0.1,0.4
                            c2.1,12.5,5.7,24.6,16.6,32.8c3.9,2.9,9.4,5.2,15.9,6.4c0.3,0.1,0.6,0.1,0.8,0.2c0.7,0.1,1.5,0.2,2.2,0.3c0,0,0.1,0,0.1,0
                            c-0.2-1.2-0.4-3.1,0.3-5.2c0.9-2.8,3.5-6.6,6.3-6.4c2.4,0.2,3.8,3.3,4.9,5.6c1.1,2.4,1.5,4.7,1.6,6.4c0.3,0,0.6,0,0.9,0
                            c0.4,0,0.9-0.1,1.3-0.1c0.7-0.1,1.5-0.1,2.2-0.2c0.5-0.1,0.9-0.1,1.4-0.2c0.7-0.1,1.4-0.2,2.2-0.3c0.5-0.1,1-0.2,1.5-0.3
                            c0.7-0.1,1.4-0.2,2.1-0.4c0.6-0.1,1.1-0.3,1.7-0.4c0.7-0.1,1.3-0.3,2-0.5c0.6-0.2,1.3-0.4,1.9-0.5c0.6-0.2,1.2-0.3,1.8-0.5
                            c0.8-0.2,1.6-0.5,2.3-0.8c0.5-0.2,0.9-0.3,1.4-0.5c1.3-0.4,2.5-0.9,3.8-1.4c0,0,0,0,0,0c0,0,0,0,0,0c1.3,0.5,2.5,1,3.8,1.4
                            c0.5,0.2,0.9,0.3,1.4,0.5c0.8,0.3,1.6,0.5,2.3,0.8c0.6,0.2,1.2,0.3,1.8,0.5c0.6,0.2,1.3,0.4,1.9,0.5c0.7,0.2,1.3,0.3,2,0.5
                            c0.6,0.1,1.1,0.3,1.7,0.4c0.7,0.1,1.4,0.3,2.1,0.4c0.5,0.1,1,0.2,1.5,0.3c0.7,0.1,1.5,0.2,2.2,0.3c0.5,0.1,0.9,0.1,1.4,0.2
                            c0.7,0.1,1.5,0.1,2.2,0.2c0.4,0,0.7,0.1,1.1,0.1c0-1.7,0.4-4.2,1.9-6.8c0.9-1.5,3-5.1,6.1-5c3.4,0.1,5.4,4.8,5.9,5.8
                            c0.9,2.1,1.1,4.1,1,5.5c0.5-0.1,1.1-0.2,1.6-0.3c0.3,0,0.5-0.1,0.8-0.2c6.5-1.3,12-3.5,15.9-6.4c10.9-8.2,14.5-20.3,16.6-32.8
                            c0-0.1,0-0.2,0.1-0.4c0-0.4,0.1-0.7,0.1-1.1C325.6,282,325.1,279.3,324.2,278.5z"/>
                        <path d="M234.2,298.2c4.2,1.5,7.4,4.8,11.7,6.1c0.7,0.2,1.3,0.3,2,0.4c0,0,0,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.5,0
                            c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0.1,0
                            c0.7-0.1,1.3-0.2,2-0.4c4.2-1.3,7.5-4.5,11.7-6.1c3.2-1.2,6.1-1.4,8.7-1c-4-6.5-11.3-12.7-17.8-12c-8.1,0.9-1.6,5.3-3,10.5
                            c-1.4,5.2-7.6,5-8.2-0.7c-0.4-4,2.8-6.4,1.6-8.4c-0.8-1.3-7.1-4.9-16.6,3.8c-2.2,2-4.4,4.4-5.9,7
                            C227.4,296.7,230.7,296.9,234.2,298.2z"/>
                        <path fill="#FF828B" d="M274.4,297c0,0.1,0.1,0.1,0.1,0.2c-2.6-0.4-5.5-0.2-8.7,1c-4.2,1.5-7.4,4.8-11.7,6.1
                            c-0.7,0.2-1.3,0.3-2,0.4c0,0,0,0-0.1,0c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6,0
                            c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c0,0,0,0-0.1,0c-0.7-0.1-1.3-0.2-2-0.4
                            c-4.2-1.3-7.5-4.5-11.7-6.1c-3.4-1.2-6.5-1.5-9.2-0.9c-5.6,2.7-8.9,9.6-8.8,17.7c0,5.3,1.5,11.1,4.7,16.6
                            c6.2,10.5,17.5,16.9,29.1,17.4c11.7-0.5,23-6.9,29.1-17.4c3.2-5.4,4.7-11.3,4.7-16.6C283.9,306.6,280.4,299.5,274.4,297z"/>
                        <path fill="#FF6E76" d="M257.4,306.2c-2.1-0.6-4.9,1.3-7,4.6c-2.1-4.6-7.4-8.8-10.4,0.7c-3.1,9.7,3.3,24.3,9.7,24.4
                            c6,0.1,11.9-12.2,11.1-21.9C260.6,312.2,260.2,307,257.4,306.2z"/>
                        <path fill="#45150B" d="M287.5,182.4l-3.1,13.1c0,0,42.4-3.5,64.5,10.3c-0.9-3.5-1.9-5.3-1.9-5.3S320,183.3,287.5,182.4z"/>
                        <path fill="#45150B" d="M213.9,182.4l1.3,13.1c0,0-42.4-3.5-64.5,10.3c-0.4-1.7,0.5-4.4,0.5-4.4S181.4,183.3,213.9,182.4z"/>
                        <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="321.9029" y1="237.8771" x2="323.7072" y2="204.798">
                            <stop  offset="4.469238e-03" stop-color="#EAEAEA" stop-opacity="0"/>
                            <stop  offset="0.9927" stop-color="#1E3A3A"/>
                        </linearGradient>
                        <path opacity="0.3" fill="url(#SVGID_55_)" d="M349,205.8c-22.1-13.9-64.5-10.3-64.5-10.3l-2.5,26.5l-4.1,20.4l28.5,6l43.9-3.2
                            l15.1-0.7C365.4,244.6,360.6,225.1,349,205.8z"/>
                        <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="178.4617" y1="235.9931" x2="180.3022" y2="202.2498">
                            <stop  offset="4.469238e-03" stop-color="#EAEAEA" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#3A3A3A"/>
                        </linearGradient>
                        <path opacity="0.3" fill="url(#SVGID_56_)" d="M217.7,222l-2.5-26.5c0,0-42.4-3.5-64.5,10.3c-11.7,19.3-16.4,38.7-16.4,38.7
                            l15.1,0.7l43.9,3.2l28.5-6L217.7,222z"/>
                        <path fill="#FFFFFF" d="M319.4,207.6c16.2,0,29.2,13.1,29.2,29.2c0,16.2-13.1,29.2-29.2,29.2c-16.1,0-29.2-13.1-29.2-29.2
                            C290.2,220.7,303.3,207.6,319.4,207.6"/>
                        <path fill="#301E1E" d="M313.1,219c11,0,19.8,8.9,19.8,19.8c0,11-8.9,19.8-19.8,19.8c-11,0-19.8-8.9-19.8-19.8
                            C293.2,227.9,302.1,219,313.1,219z"/>
                        <path fill="#FFFFFF" d="M321.6,238.9h13.7c4.4,0,8-3.6,8-8v0c0-4.4-3.6-8-8-8h-13.7c-4.4,0-8,3.6-8,8v0
                            C313.5,235.2,317.1,238.9,321.6,238.9z"/>
                        <path fill="#FFFFFF" d="M180.6,207.6c-16.2,0-29.2,13.1-29.2,29.2c0,16.2,13.1,29.2,29.2,29.2c16.1,0,29.2-13.1,29.2-29.2
                            C209.9,220.7,196.8,207.6,180.6,207.6"/>
                        <path fill="#301E1E" d="M187,219c-11,0-19.8,8.9-19.8,19.8c0,11,8.9,19.8,19.8,19.8c11,0,19.8-8.9,19.8-19.8
                            C206.8,227.9,197.9,219,187,219z"/>
                        <path fill="#FFFFFF" d="M178.5,238.9h-13.7c-4.4,0-8-3.6-8-8v0c0-4.4,3.6-8,8-8h13.7c4.4,0,8,3.6,8,8v0
                            C186.5,235.2,182.9,238.9,178.5,238.9z"/>
                        <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="299.1129" y1="520.8943" x2="297.91" y2="498.0385">
                            <stop  offset="0" stop-color="#D2BCA9"/>
                            <stop  offset="1" stop-color="#E4D0BD"/>
                        </linearGradient>
                        <path fill="url(#SVGID_57_)" d="M316.4,517.4c0,0-1.3-15.1-6.5-19.8c-0.2-0.2-0.5-0.3-0.8-0.3c-2.5-0.4-9.6,1-19.3,3.1
                            c0,0-6.6,5.4-8.2,16.3c0,0.2-0.1,0.4-0.1,0.5c0,0,0.3,0.1,1,0.3c1.7,0.5,5.8,1.5,10.8,2c0.8,0.1,1.7,0.2,2.6,0.2
                            c0.1-0.4,0.1-0.8,0.1-1.2c0.1-0.9,0.2-1.8,0.2-2.8c0.1-1.3,0.1-2.7,0.1-4c0-2.7-0.3-5.4-0.7-8.1c0.7,2.6,1.2,5.3,1.5,8
                            c0.2,1.4,0.3,2.7,0.3,4.1c0,0.6,0,1.3,0,2c0,0.7,0,1.4,0,2.1c3.8,0.2,8,0.1,12.2-0.7c-0.8-4.6-1.8-9.2-2.9-13.8
                            c-0.3-1.1-0.6-2.2-0.9-3.4c0.4,1,0.7,1.9,1,2.9c1.6,4.6,3,9.2,4.3,13.9l-0.8,0.2C312.4,518.7,314.4,518.2,316.4,517.4z"/>
                        <path fill="#AD9C8B" d="M310.3,519.1l0.8-0.2c-1.3-4.7-2.7-9.4-4.3-13.9c-0.3-1-0.7-2-1-2.9c0.3,1.1,0.6,2.2,0.9,3.4
                            c1.1,4.6,2.1,9.2,2.9,13.8c0,0,0,0,0,0L310.3,519.1z"/>
                        <path fill="#AD9C8B" d="M297.5,517.8c0-0.7,0-1.3,0-2c-0.1-1.4-0.2-2.7-0.3-4.1c-0.3-2.7-0.8-5.4-1.5-8c0.4,2.7,0.6,5.4,0.7,8.1
                            c0,1.3,0,2.7-0.1,4c0,0.9-0.1,1.8-0.2,2.8c0,0.4-0.1,0.8-0.1,1.2c0,0,0,0,0,0l1.5,0.1c0,0,0,0,0,0
                            C297.4,519.2,297.5,518.5,297.5,517.8z"/>
                        <linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse" x1="200.7655" y1="520.2621" x2="199.5218" y2="496.6319">
                            <stop  offset="0" stop-color="#D2BCA9"/>
                            <stop  offset="1" stop-color="#E4D0BD"/>
                        </linearGradient>
                        <path fill="url(#SVGID_58_)" d="M209.8,499.9c-9.5-2.1-16.4-3.5-19.1-3.2c-0.5,0.1-0.9,0.2-1.1,0.3c-5.2,4.8-6.5,19.8-6.5,19.8
                            c2,0.7,4,1.3,6.1,1.7l-0.8-0.2c1.3-4.8,2.7-9.5,4.4-14.2c0.3-0.9,0.6-1.8,1-2.7c-0.3,1-0.5,2.1-0.8,3.1c-1.2,4.6-2.1,9.3-3,14
                            c4.2,0.8,8.4,0.9,12.2,0.7c0-0.6,0-1.2,0-1.8c0-0.7,0-1.5,0-2.2c0.1-1.4,0.2-2.7,0.3-4.1c0.3-2.7,0.8-5.4,1.5-8
                            c-0.4,2.7-0.6,5.4-0.7,8.1c0,1.3,0,2.7,0.1,4c0.1,1,0.1,2,0.3,3.1c0,0.3,0.1,0.6,0.1,0.9c0.6,0,1.1-0.1,1.7-0.1
                            c7.4-0.7,12.7-2.4,12.7-2.4C216.6,505.6,209.8,499.9,209.8,499.9z"/>
                        <path fill="#AD9C8B" d="M193.7,501.6c-0.3,0.9-0.6,1.8-1,2.7c-1.6,4.7-3.1,9.4-4.4,14.2l0.8,0.2l0.7,0.1c0,0,0,0,0,0
                            c0.9-4.7,1.8-9.4,3-14C193.1,503.7,193.4,502.6,193.7,501.6z"/>
                        <path fill="#AD9C8B" d="M203.3,515.4c-0.1-1.3-0.1-2.7-0.1-4c0-2.7,0.3-5.4,0.7-8.1c-0.7,2.6-1.2,5.3-1.5,8
                            c-0.2,1.4-0.3,2.7-0.3,4.1c0,0.7,0,1.5,0,2.2c0,0.6,0,1.2,0,1.8c0,0,0,0,0,0l1.5-0.1c0,0,0,0,0,0c0-0.3-0.1-0.6-0.1-0.9
                            C203.4,517.4,203.3,516.4,203.3,515.4z"/>
                        <path fill="none" d="M249.8,469.1c60.6,0,58.4-57.7,50.3-95.9c0,0,0,0,0,0c-0.2-0.7-0.3-1.5-0.5-2.2c-14.4,3-30.9,4.6-49.4,4.6
                            c-18.9,0-35.6-1.6-50.2-4.7C191.6,409,188,469.1,249.8,469.1z"/>
                        <path fill="none" d="M301.8,370.6c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3L301.8,370.6z"/>
                        <path opacity="0.2" fill="#FFFFFF" d="M301.3,370.7c-0.6,0.1-1.1,0.2-1.7,0.4c0.2,0.7,0.3,1.4,0.5,2.2c0,0,0,0,0,0
                            c8.1,38.2,10.3,95.9-50.3,95.9c-61.9,0-58.3-60.1-49.8-98.2c-0.6-0.1-1.2-0.3-1.8-0.4c-20.1,2.6-66.5,11.3-57.6,36.4
                            c8.8,24.8,39.1,5.8,51.3-3.1c-2.2,15.3-3.7,33.3-3.5,53.5c0,0,0.1,22.5,1.4,34.4c0.2,1.7,0.5,3.4,0.8,5.1c2.7-0.3,9.6,1.1,19.1,3.2
                            c0,0,6.8,5.6,8.2,16.9l0.1,0.1c10.6-10.5,8.4-43.2,8.4-43.2c11.5,1.6,23.3,1.8,23.3,1.8s11.8-0.2,23.3-1.8c0,0-2.2,32.4,8.3,43
                            c1.6-10.9,8.2-16.3,8.2-16.3c9.8-2.1,16.8-3.5,19.3-3.1c0.4-1.8,0.7-3.7,0.9-5.6c1.4-11.9,1.4-34.4,1.4-34.4
                            c0.1-6.5-0.1-12.8-0.3-18.9c5.4,4.4,12.1,5.8,20.3,0.9c26.1-15.5-16.9-60.7-25.8-69.6c-1.2,0.3-2.5,0.6-3.8,0.9
                            C301.8,370.6,301.4,370.8,301.3,370.7z"/>
                    </svg>

        return kid;

    }
    getTeenWithOutTool(color){ 
        const man =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill={color} d="M395.5,411.3c-7.4,6.1-17,8.8-26.4,7.6c11.6-12.1,15.7-29.5,10.7-45.4c-11.6,19.2-24.2,33.1-36.6,43.2
                                c0-0.6,0-1.1-0.1-1.7c0,0,0-0.1,0-0.1c0-0.5-0.1-1.1-0.2-1.6v-0.2c-0.1-1.2-0.3-2.4-0.5-3.6c0,0,0-0.1,0-0.1
                                c-0.1-0.6-0.2-1.2-0.3-1.8c0-0.1,0-0.1,0-0.2c-0.2-1.3-0.5-2.6-0.7-4v-0.1c-0.1-0.7-0.3-1.3-0.4-2v-0.1c-1.6-7.5-3.8-15.8-5.9-24.6
                                c-0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.2-0.9-0.3-1.3l-0.3-1.1c-0.1-0.4-0.2-0.7-0.3-1.1c-0.2-0.7-0.3-1.4-0.5-2.1
                                c-0.1-0.3-0.1-0.6-0.2-0.9l-0.3-1.5l-0.2-0.9l-0.4-1.6l-0.2-0.7c-0.2-0.8-0.3-1.5-0.5-2.3l-0.2-0.7c-0.1-0.6-0.2-1.1-0.4-1.7
                                c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.6-0.3-1.3-0.4-1.9c0-0.2-0.1-0.3-0.1-0.5l-0.5-2.4c0-0.2-0.1-0.5-0.1-0.7
                                c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.8c-0.1-0.7-0.3-1.5-0.4-2.2c0-0.1,0-0.2,0-0.3c-0.1-0.8-0.3-1.6-0.4-2.5c0-0.2-0.1-0.5-0.1-0.7
                                c-0.1-0.6-0.2-1.2-0.3-1.9c0-0.3-0.1-0.5-0.1-0.7c-0.1-0.8-0.2-1.7-0.3-2.5c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.5
                                c-0.1-0.5-0.2-1-0.3-1.5c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.3-1.1-0.4-1.6c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.4-0.3-0.8-0.4-1.2l-0.1-0.3
                                c-0.2-0.5-0.4-1-0.6-1.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.3-0.7-0.5-1.1l-0.2-0.3c-0.2-0.4-0.5-0.9-0.7-1.3L324,328
                                c-0.2-0.3-0.4-0.7-0.6-1l-0.2-0.3c-0.3-0.4-0.5-0.8-0.8-1.1c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.3-0.5-0.6-0.8-0.9l-0.3-0.3
                                c-0.3-0.3-0.6-0.7-0.9-1l-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-0.9L319,322l-0.9-0.8l-0.2-0.1c-0.3-0.3-0.7-0.6-1-0.8l-0.3-0.2
                                c-0.3-0.2-0.6-0.4-0.9-0.6l-0.2-0.2c-0.4-0.3-0.7-0.5-1.1-0.7l-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.1-0.1-0.2-0.1-0.3-0.2
                                c-0.4-0.2-0.7-0.4-1.1-0.6l-0.2-0.1l-0.9-0.5l-0.3-0.1c-0.4-0.2-0.8-0.4-1.1-0.5l-0.1-0.1l-1-0.4l-0.3-0.1
                                c-0.8-0.3-1.6-0.6-2.3-0.8l-0.3-0.1l-1.1-0.3h-0.1l-1.2-0.3l-0.2-0.1l-1-0.3h-0.1l-1.2-0.3h-0.1l-1.1-0.2h-0.1
                                c-0.8-0.2-1.5-0.3-2.2-0.4h-0.2c-0.7-0.1-1.4-0.2-2.1-0.3l0,0l-0.1-0.1l0,0l0,0l-10.3-3.7l-1.7-6.9c32.8-5.2,54-18.8,65.5-39.5
                                c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.9-5.8,0.9-5.8c0.3-2.1,0.6-4.8,0.7-7c0.2-3.1,0.3-6.3,0.3-9.6c0-2.1-0.1-4.1-0.3-6.2
                                c-0.8-6.6-2.4-13-4.9-19.1c-0.4-0.9-0.8-1.8-1.2-2.7c5.4-9.8,10.8-20.2,12.1-29.6c4-29.1-13.1-74.6-27.2-82.3
                                c-13.7-7.5-21.2,41-33,54.1c-1.3,1.4-2.3,2.9-3.2,4.6c-31.7-17.2-70-17.1-101.7,0.1c-0.8-1.7-1.9-3.3-3.2-4.7
                                c-11.8-13.1-19.3-61.6-33-54.1c-14.1,7.7-31.2,53.3-27.2,82.3c1.3,9.5,6.9,20,12.3,29.9c-0.3,0.7-0.6,1.4-0.9,2.1
                                c-3.4,8.1-5.2,16.8-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5c0.3,2.3,0.7,4.7,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9
                                c11.5,20.8,32.9,34.4,65.9,39.5l-2,6.8l-9.9,3.6c-0.4,0.1-0.8,0.1-1.2,0.2h-0.1c-0.4,0.1-0.7,0.2-1.1,0.3l-0.2-0.2l-0.9,0.3
                                c-1.3,0.2-2.7,0.5-4.1,0.9h0c-1.4,0.3-2.9,0.8-4.4,1.3l0,0c-9.7,3.4-19.9,10.5-21.7,25.6c-1.4,10.6-3.4,21.1-6,31.5
                                c-1.3,5.3-2.6,10.5-3.8,15.4l0,0c-0.2,0.9-0.5,1.8-0.7,2.7l-0.2,0.8l-0.3,1.1c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,0.4-0.2,0.6-0.2,0.9
                                c-0.2,0.7-0.3,1.3-0.5,2c-0.1,0.3-0.1,0.5-0.2,0.8l-0.3,1.5c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,0.7-0.3,1.3-0.4,2
                                c0,0.2-0.1,0.4-0.1,0.7l-0.3,1.6l-0.1,0.6c-0.1,0.7-0.3,1.3-0.4,1.9c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.5-0.2,1.1-0.3,1.6l-0.1,0.4
                                c-0.1,0.6-0.2,1.3-0.3,1.9c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.5-0.1,1-0.2,1.6c0,0.1,0,0.2,0,0.3c-0.1,0.6-0.1,1.2-0.2,1.8
                                c0,0.1,0,0.3,0,0.4c0,0.5-0.1,1-0.1,1.5c0,0.1,0,0.2,0,0.3c0,0.6,0,1.1-0.1,1.7c0,0.1,0,0.3,0,0.4c0,0.5,0,1,0,1.4
                                c0,0.1,0,0.2,0,0.2c0,0.5,0,1,0.1,1.5v0.4c0,0.4,0.1,0.9,0.1,1.3v0.2c0.1,0.5,0.2,0.9,0.2,1.4c0,0.1,0,0.2,0.1,0.3
                                c0.1,0.4,0.2,0.8,0.3,1.2c0,0,0,0.1,0,0.1c0.1,0.4,0.3,0.8,0.4,1.3l0.1,0.3c0.2,0.4,0.3,0.8,0.5,1.1l0,0.1c0.2,0.4,0.4,0.7,0.6,1.1
                                l0.2,0.3c0.2,0.3,0.4,0.7,0.7,1l0,0.1c0.3,0.3,0.5,0.6,0.8,0.9l0.2,0.2c0.3,0.3,0.6,0.6,0.9,0.8l0,0c0.3,0.3,0.7,0.5,1,0.8l0.3,0.2
                                c0.4,0.2,0.8,0.5,1.2,0.7c0.4,0.2,0.8,0.4,1.3,0.6l0.2,0.1c0.4,0.2,0.8,0.3,1.2,0.4l0.2,0.1c0.4,0.1,0.8,0.2,1.2,0.3h0.1
                                c0.4,0.1,0.8,0.1,1.1,0.2h0.2c0.4,0,0.7,0.1,1.1,0.1h0.2c0.4,0,0.7,0,1.1-0.1h0.2l1-0.2h0.2c0.4-0.1,0.7-0.2,1-0.3l0.2-0.1
                                c0.3-0.1,0.7-0.2,1-0.4l0.2-0.1l0.9-0.3l0.2-0.1c0.3-0.2,0.7-0.4,1-0.6l0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6l0.2-0.2
                                c0.3-0.2,0.6-0.4,0.9-0.7l0.2-0.1c0.3-0.3,0.6-0.5,0.9-0.8l0.3-0.2c0.3-0.3,0.6-0.6,0.9-0.9l0.3-0.3c0.3-0.3,0.5-0.6,0.8-0.9
                                c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.3,0.5-0.6,0.8-0.9l0.3-0.3c0.3-0.3,0.5-0.7,0.8-1l0.3-0.4c0.3-0.4,0.5-0.7,0.8-1.1
                                c0.5,12.6,1.9,46,3.1,61.1c0.3,3.5,0.6,6.8,1,9.7c2.2,15.2,23.4,21.8,25.4,20.1c4.4-3.7,7.4-10.7,9.6-18.3l0.1-0.5l0.1-0.3
                                c3.7-13.8,4.5-16.6,4.6-18.8c11.8,2.5,18.8,2.1,18.8,2.1s7,0.4,18.8-2.1c0.1,1.9,0.6,0.7,3.1,12.5c0,0.1,0,0.1,0,0.2
                                c0,0.1,0.1,0.4,0.1,0.6c2.1,9.9,5.5,19.9,11.1,24.7c2.1,1.7,23.3-4.5,25.4-20.1c0.1-0.5,0.1-1,0.2-1.5c0.3-2.6,0.6-5.4,0.9-8.4
                                c0-0.3,0.1-0.7,0.1-1.1l0.1-0.7c0-0.1,0-0.2,0-0.3c0-0.3,0-0.7,0.1-1V484c0-0.4,0-0.7,0.1-1.1l0,0c3,0.2,6.1,0,9.1-0.5
                                c-10.9-7-6.7-13.5-6.7-13.5c43.3,9.3,55.1-13.8,55.1-13.8c-8.9,1.8-18.1,1.2-26.6-1.7C391.6,436.6,395.5,411.3,395.5,411.3z
                                M312.4,434.9v-0.7c0-0.2,0-0.3,0-0.5c0.1-1.9,0.2-3.7,0.2-5.3c0-0.6,0.1-1.2,0.1-1.7c0-0.2,0-0.3,0-0.5c0.4,0.6,0.8,1.1,1.2,1.6
                                c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.4,0.6,0.8,1,1.2l0.3,0.3c0.4,0.4,0.7,0.8,1.1,1.2l0.1,0.1c0.4,0.5,0.9,0.9,1.4,1.3
                                C316.2,433.4,314.3,434.2,312.4,434.9z"/>
                            <path opacity="0.25" fill="#D3D3D3" enable-background="new    " d="M327.9,340.1c-2.5-21.6-22.5-26.9-33.3-28.2
                                c0-0.1-0.1-0.1-0.1-0.2l-10.3-3.7l-5.5-21.9h-1.1l-0.2-0.5h-54.9L216,308l-9.9,3.6c-1.5,0.2-2.9,0.5-4.4,0.9
                                c-11.1,2.1-27.1,8.3-29.4,27.6c-5.2,44.2-26.9,85.4-7.5,95.4c8.8,4.5,16.3-0.2,22.5-9.1c0.5,12.6,1.9,46,3.1,61.1
                                c0.3,3.5,0.6,6.8,1,9.7c2.2,15.2,23.4,21.8,25.4,20.1c4.4-3.7,7.4-10.7,9.6-18.3l0.1-0.5l0.1-0.3c3.7-13.8,4.5-16.6,4.6-18.8
                                c11.8,2.5,18.8,2.1,18.8,2.1s7,0.4,18.8-2.1c0.1,1.9,0.6,0.7,3.1,12.5c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.4,0.1,0.6
                                c2.2,9.9,5.6,19.9,11.2,24.6c2.1,1.7,23.3-4.5,25.4-20.1c0.1-0.5,0.1-1,0.2-1.5c0.3-2.6,0.6-5.4,0.9-8.4c0.1-1.5,0.2-3.1,0.3-4.9
                                c0.8-13.1,1.8-33.7,2.4-47.3c0.1-3.2,0.3-5.9,0.3-8.2c0-0.2,0-0.3,0-0.5c6.3,9,13.8,13.7,22.6,9.2
                                C354.8,425.5,333.1,384.3,327.9,340.1z M296.7,363.4c0,2.1,0,4.2-0.1,6.4v0.2c-0.1,2.2-0.2,4.4-0.3,6.6c0,0.1,0,0.1,0,0.2
                                c-0.1,2.2-0.3,4.4-0.5,6.6c0,0.1,0,0.1,0,0.2c-0.1,1.1-0.2,2.2-0.3,3.4l0,0l-0.1,0.7l0,0c-0.1,0.7-0.2,1.4-0.3,2.2l-0.1,0.6l0,0
                                c-0.1,0.7-0.2,1.4-0.3,2.1l0,0l-0.1,0.4c-0.3,2.1-0.7,4.1-1.1,6.2l0,0l0,0c-0.2,0.8-0.3,1.6-0.5,2.3l-0.1,0.3l0,0
                                c-0.4,1.7-0.8,3.4-1.2,5.1l0,0c-0.1,0.2-0.1,0.5-0.2,0.7l0,0c-0.2,0.7-0.4,1.5-0.6,2.2l-0.1,0.4l0,0c-0.2,0.6-0.4,1.2-0.6,1.8l0,0
                                l-0.2,0.6l0,0c-0.5,1.6-1.1,3.2-1.7,4.7l0,0c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.6-0.5,1.3-0.8,1.9l0,0c-0.1,0.2-0.2,0.5-0.3,0.7l0,0
                                c-0.3,0.7-0.6,1.3-0.9,1.9l0,0l-0.1,0.3l0,0c-0.4,0.7-0.7,1.4-1.1,2.1l0,0c0,0,0,0,0,0.1c-0.9,1.6-1.8,3.1-2.7,4.5l-0.1,0.1l0,0
                                c-0.5,0.7-0.9,1.3-1.4,2c0,0-0.1,0.1-0.1,0.1l0,0c-0.4,0.5-0.8,1-1.2,1.5l0,0l-0.2,0.2c-1.6,1.9-3.3,3.6-5.2,5.2l-0.4,0.3l0,0
                                c-2.5,2-5.2,3.6-8.1,4.8l-0.4,0.2l0,0c-0.5,0.2-1,0.4-1.6,0.6l-0.7,0.2l0,0c-0.6,0.2-1.3,0.4-2,0.6l-0.3,0.1l0,0
                                c-0.8,0.2-1.5,0.4-2.3,0.6h-0.1c-1.9,0.4-3.9,0.6-5.8,0.7l0,0c-0.9,0.1-1.8,0.1-2.7,0.1c-0.9,0-1.9,0-2.8-0.1l0,0
                                c-0.5,0-0.9-0.1-1.4-0.1l0,0l-1.3-0.2l-0.8-0.1l0,0l-0.7-0.1l0,0l-0.5-0.1c-1.3-0.2-2.5-0.5-3.8-0.8c-0.5-0.1-0.9-0.3-1.4-0.4l0,0
                                c-1.4-0.4-2.8-1-4.1-1.6l0,0l-1.1-0.5l0,0c-0.4-0.2-0.9-0.5-1.3-0.7l-0.4-0.2l0,0l-0.8-0.5c-0.4-0.2-0.7-0.4-1.1-0.7l0,0l-0.3-0.2
                                l0,0c-0.4-0.3-0.8-0.5-1.1-0.8l0,0c-0.2-0.1-0.3-0.3-0.5-0.4l-0.9-0.7l0,0l-0.5-0.4l0,0l-0.9-0.8l0,0l-0.5-0.4l0,0
                                c-0.3-0.3-0.6-0.6-0.9-0.9l0,0l-0.3-0.3l0,0l-0.3-0.4c-0.6-0.6-1.1-1.2-1.6-1.8l-0.4-0.5l0,0l-0.4-0.5l0,0l-0.2-0.3
                                c-0.4-0.6-0.9-1.1-1.3-1.7c-0.1-0.1-0.2-0.3-0.3-0.4l0,0c-0.3-0.4-0.5-0.7-0.8-1.1c-0.1-0.2-0.2-0.3-0.3-0.5l0,0
                                c-0.6-0.9-1.1-1.8-1.6-2.7c-0.2-0.3-0.4-0.7-0.6-1l0,0c-0.3-0.5-0.5-1-0.8-1.5c0-0.1-0.1-0.2-0.1-0.2l0,0l-0.3-0.7
                                c-0.2-0.4-0.3-0.7-0.5-1.1l0,0c-0.2-0.4-0.4-0.8-0.5-1.2l-0.3-0.7l0,0c-0.3-0.8-0.7-1.6-1-2.4l0,0c-0.2-0.6-0.5-1.2-0.7-1.8l0,0
                                c-0.3-0.9-0.6-1.7-0.9-2.6l0,0c-0.1-0.4-0.3-0.8-0.4-1.2l0,0c-0.1-0.3-0.2-0.5-0.2-0.8l0,0c-0.1-0.2-0.1-0.4-0.2-0.6
                                c-0.4-1.4-0.8-2.8-1.2-4.2c-0.1-0.4-0.2-0.7-0.3-1.1l0,0l-0.1-0.6l0,0c-0.1-0.5-0.2-1-0.3-1.4l0,0c-0.1-0.3-0.1-0.5-0.2-0.8l0,0
                                c-0.1-0.4-0.2-0.8-0.3-1.2l0,0l-0.3-1.4l-0.1-0.7l0,0c-0.5-2.6-0.9-5.3-1.3-8l0,0c0-0.4-0.1-0.8-0.1-1.2l0,0c0-0.3-0.1-0.7-0.1-1
                                l0,0c0-0.4-0.1-0.7-0.1-1.1l0,0l-0.1-1.2c0-0.1,0-0.3,0-0.4l0,0c-1.8-17.8-1-35.7,0-47.8c0.5-5.7,3.2-10.9,7.6-14.6l0,0
                                c0.4-0.4,0.9-0.7,1.4-1l0.3-0.2c0.4-0.3,0.8-0.5,1.2-0.8l0.4-0.2c0.4-0.2,0.8-0.4,1.2-0.6l0.4-0.2c0.5-0.2,1.1-0.5,1.6-0.7l0,0
                                c1.1-0.4,2.3-0.8,3.5-1.2l0.6-0.2c1-0.3,2-0.6,3-0.9l1.4-0.4c0.8-0.2,1.6-0.4,2.4-0.6c0.7-0.2,1.4-0.3,2-0.4l0.2,0
                                c1.6-0.3,3.1-0.6,4.7-0.8l0.5-0.1l0,0c1-0.2,2.1-0.3,3.1-0.4c0.7-0.1,1.4-0.2,2-0.2l0,0c0.4,0,0.9-0.1,1.3-0.1l0,0l1.3-0.1
                                c1.9-0.1,3.9-0.2,5.8-0.2c10.8,0,21.6,1.9,31.8,5.6l0,0c0.6,0.2,1.2,0.5,1.7,0.7l0,0l0.4,0.2l0,0c0.5,0.3,1,0.5,1.5,0.8l0.1,0.1
                                l0,0c1.1,0.7,2.1,1.4,3.1,2.2l0,0c0.5,0.4,0.9,0.8,1.3,1.3l0.2,0.3c0.2,0.2,0.4,0.4,0.6,0.6l0.2,0.2c0.3,0.4,0.6,0.8,0.9,1.1
                                c0.1,0.1,0.2,0.3,0.3,0.4l0.4,0.6l0.4,0.6c0.2,0.3,0.3,0.5,0.4,0.8s0.2,0.4,0.3,0.7l0.2,0.5c0.2,0.3,0.3,0.7,0.4,1
                                c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.3,1l0.1,0.4c0.1,0.4,0.2,0.8,0.3,1.3v0.1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.1,0,0.2,0.1,0.4
                                c0.1,0.5,0.1,0.9,0.2,1.4c0.1,0.7,0.1,1.4,0.2,2.1c0,0.2,0,0.3,0,0.5c0.1,0.7,0.1,1.5,0.2,2.3l0,0c0,0.8,0.1,1.6,0.1,2.4
                                c0,0.2,0,0.3,0,0.5c0.1,1.7,0.2,3.4,0.3,5.2c0,0.1,0,0.3,0,0.4c0.1,1.8,0.1,3.7,0.2,5.7c0,0.1,0,0.2,0,0.3c0,2,0.1,4,0.1,6.1
                                L296.7,363.4z"/>
                            <path opacity="0.25" fill="#D3D3D3" enable-background="new    " d="M337,75.6c-13.7-7.5-21.2,41-33,54.1c-1.3,1.4-2.3,2.9-3.2,4.6
                                c-31.7-17.2-70-17.1-101.7,0.1c-0.8-1.7-1.9-3.3-3.2-4.7c-11.8-13.1-19.3-61.6-33-54.1c-14.1,7.7-31.2,53.3-27.2,82.3
                                c1.3,9.5,6.9,20,12.3,29.9c-0.3,0.7-0.6,1.4-0.9,2.1c-3.4,8.1-5.2,16.8-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5
                                c11.2-15.6,36.1-22.5,51.4-20.4c5.6,0.8,11.2,1.2,16.9,1.4c3,0.1,5.9,0,8.9-0.5c12.3-2.7,12.3-10.8,28.2-12.6h0.3
                                c0.6,0.1,1.2,0.2,1.8,0.2c0.6-0.1,1.1-0.2,1.7-0.2h0.2c15.9,1.8,15.9,9.9,28.2,12.6c2.9,0.5,5.9,0.7,8.9,0.5
                                c5.6-0.2,11.3-0.6,16.9-1.4c15.4-2,40.6,5,51.6,20.9c0.3-2.1,0.5-4.2,0.6-6.3c0.2-3.1,0.3-6.3,0.3-9.6c0-2.1-0.1-4.1-0.3-6.2
                                c-0.8-6.6-2.4-13-4.9-19.1c-0.4-0.9-0.8-1.8-1.2-2.7c5.4-9.8,10.8-20.2,12.1-29.6C368.2,128.8,351,83.3,337,75.6z M148.3,132.3
                                c1.5-7,12.6-31.1,17.3-36.6c4.7,11.1,26.3,45.3,9.2,54.5c-3.2-2.3-5.2-5.8-5.7-9.6c-0.8,5-1.2,10.1-1.3,15.2
                                c-2.2-2.2-4.1-3.8-5.2-6.5c-1.4,8.5-4.6,15.9-7.6,24.7C146.4,161.9,145.3,145.7,148.3,132.3L148.3,132.3z M344.8,173.9
                                c-2.9-8.7-6.1-16.2-7.6-24.7c-1,2.7-2.9,4.3-5.2,6.5c-0.1-5.1-0.5-10.2-1.3-15.2c-0.5,3.9-2.5,7.4-5.7,9.6
                                c-17.1-9.2,4.5-43.4,9.2-54.5c4.7,5.4,15.7,29.6,17.3,36.6C354.4,145.7,353.4,161.9,344.8,173.9L344.8,173.9z"/>
                            <path fill="#EEE6D9" d="M295.6,337.7c-0.7-8.3-6.1-15.4-13.9-18.2l0,0c-20.5-7.5-43.1-7.5-63.6,0l0,0c-7.8,2.8-13.3,10-13.9,18.2
                                c-2.7,32.8-4.1,108.7,45.8,108.8C299.8,446.4,298.3,370.5,295.6,337.7z"/>
                            <path fill="#FFFFFF" d="M281.8,267.4c-0.4-0.8-2-4.6-4.7-4.6c-2.5-0.1-4.2,2.8-4.9,4c-1,1.7-1.5,3.5-1.5,5.5h0.1
                                c0.6,0,1.2,0.1,1.8,0.1h0.7h1.3c1.9,0,3.8-0.1,5.7-0.3h0.2h0.1l0.4,0c0.4,0,0.7-0.1,1.1-0.1h0.1l0.5-0.1
                                C282.6,270.4,282.4,268.8,281.8,267.4z"/>
                            <path fill="#FFFFFF" d="M223.4,262.7c-2.3-0.2-4.3,2.8-5.1,5.1c-0.4,1.3-0.5,2.8-0.3,4.2h0.1l1.1,0.1l0.4,0h0.1h0.2
                                c1.9,0.2,3.8,0.3,5.7,0.3h1.3h0.7l1-0.1c-0.1-1.8-0.6-3.5-1.3-5.1C226.4,265.4,225.3,262.9,223.4,262.7z"/>
                            <path fill="#EEE6D9" d="M305.8,210.5c-5.6,0.8-11.2,1.2-16.9,1.4c-3,0.1-5.9,0-8.9-0.5c-12.2-2.7-12.3-10.8-28.2-12.6h-0.3
                                c-0.6,0.1-1.2,0.2-1.7,0.2c-0.6-0.1-1.1-0.2-1.8-0.2h-0.3c-15.9,1.8-15.9,9.9-28.2,12.6c-2.9,0.5-5.9,0.7-8.9,0.5
                                c-5.6-0.2-11.3-0.6-16.9-1.4c-15.2-2-40.2,4.9-51.4,20.4c0.3,2.3,0.7,4.7,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9
                                c12.1,21.8,35.1,35.8,71.1,40.2c2.4,0.3,4.8,0.6,7.3,0.8c6.1,0.5,12.6,0.8,19.5,0.8c6.8,0,13.3-0.3,19.5-0.8
                                c2.6-0.2,5.1-0.5,7.6-0.8c35.8-4.5,58.8-18.4,70.9-40.2c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.8-4.3,1-6.5
                                C346.4,215.5,321.2,208.5,305.8,210.5z"/>
                            <path fill="none" d="M236.7,204.1c2.3,3.7,5.1,6.9,8.3,6.9C245.5,206,241.8,203.7,236.7,204.1z"/>
                            <path fill="none" d="M255,211c3.2,0,6-3.2,8.3-6.9C258.3,203.7,254.6,206,255,211z"/>
                            <path fill="#230905" d="M245,211c-3.2,0-6-3.2-8.3-6.9C241.8,203.7,245.5,206,245,211z M255,211c-0.5-5.1,3.2-7.4,8.3-6.9
                                C261,207.8,258.3,211,255,211z M269.7,197.5c-1.5-4.6-5.9-4.1-10.6-3.8c-3,0.4-6,0.6-9.1,0.6c-3,0-6.1-0.2-9.1-0.6
                                c-4.7-0.3-9.1-0.8-10.6,3.8c-0.3,2.8,0.4,5.7,2,8.1c3.4,5.2,10.5,10.5,16.1,13c0.6,0.2,1.1,0.5,1.6,0.7c0.5-0.2,1.1-0.4,1.6-0.7
                                c5.6-2.5,12.7-7.9,16.1-13C269.4,203.2,270.1,200.3,269.7,197.5z"/>
                            <path fill="#45150B" d="M309.9,233.8L309.9,233.8c-5.4-5.1-12.9,1.6-25.8,1.2c-6.7,0-13.3-2.2-18.6-6.3l0,0c-1.3-1-2.8-1.8-4.3-2.4
                                c-2.3-1-4.8-1.6-7.3-1.9c-1-1.9-1.7-3.8-2.3-5.8c-0.6,0.2-1.1,0.5-1.6,0.7c-0.5-0.2-1.1-0.4-1.6-0.7c-0.5,2-1.3,4-2.3,5.8
                                c-2.5,0.3-5,0.9-7.3,1.9c-1.5,0.7-3,1.5-4.3,2.4l0,0c-5.3,4.1-11.9,6.3-18.6,6.3c-12.9,0.4-20.4-6.3-25.8-1.2l0,0
                                c-0.7,0.7-1.2,2.8-1.1,4.9c0,0.3,0,0.6,0.1,0.9c0,0.1,0,0.2,0,0.3c1.7,10.1,4.6,19.8,13.4,26.5c3.1,2.4,7.6,4.2,12.8,5.2l0.7,0.1
                                l1.8,0.3h0.1c-0.3-1.4-0.2-2.8,0.3-4.2c0.7-2.3,2.8-5.4,5.1-5.1c1.9,0.2,3.1,2.7,3.9,4.5c0.7,1.6,1.2,3.4,1.3,5.1h0.7l1-0.1
                                c0.6-0.1,1.2-0.1,1.8-0.2l1.1-0.1c0.6-0.1,1.2-0.2,1.8-0.2l1.2-0.2c0.6-0.1,1.1-0.2,1.7-0.3s0.9-0.2,1.4-0.3
                                c0.5-0.1,1.1-0.2,1.6-0.4l1.6-0.4c0.5-0.1,0.9-0.3,1.4-0.4c0.6-0.2,1.3-0.4,1.9-0.6c0.4-0.1,0.8-0.2,1.1-0.4c1-0.4,2-0.7,3.1-1.2h0
                                c1,0.4,2,0.8,3.1,1.2c0.4,0.1,0.8,0.2,1.1,0.4c0.6,0.2,1.3,0.4,1.9,0.6c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.3,1.6,0.4
                                c0.5,0.1,1.1,0.2,1.6,0.4c0.5,0.1,0.9,0.2,1.4,0.3c0.5,0.1,1.1,0.2,1.7,0.3l1.2,0.2l1.8,0.2l1.1,0.1c0.6,0.1,1.2,0.1,1.8,0.2
                                l0.9,0.1c0-1.9,0.6-3.8,1.5-5.5c0.7-1.2,2.5-4.1,4.9-4c2.8,0.1,4.4,3.8,4.7,4.6c0.6,1.4,0.9,2.9,0.8,4.4l1.3-0.2l0.7-0.1
                                c5.3-1,9.7-2.8,12.8-5.2c8.8-6.6,11.7-16.4,13.4-26.5c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-0.9C311,236.6,310.6,234.5,309.9,233.8z
                                "/>
                            <path fill="#FF828B" d="M260.3,248.8c-2.7,1-4.8,3.1-7.6,3.9c-0.4,0.1-0.9,0.2-1.3,0.3h0l-0.4,0h-0.3h-1.2h-0.3l-0.4,0l0,0
                                c-0.4-0.1-0.9-0.2-1.3-0.3c-2.7-0.8-4.9-2.9-7.6-3.9c-8.7-3.2-14.5,3.9-14.4,13c0,4.3,1.2,8.5,3.4,12.1
                                c4.5,7.5,12.5,12.3,21.3,12.7c8.8-0.4,16.8-5.2,21.3-12.7c2.2-3.7,3.4-7.8,3.4-12.1C274.8,252.7,269,245.6,260.3,248.8z"/>
                            <path fill="#FF6E76" d="M255.4,255.4c-1.5-0.4-3.6,0.9-5.1,3.4c-1.5-3.3-5.4-6.5-7.6,0.5c-2.2,7,2.4,17.8,7.1,17.9
                                c4.4,0.1,8.7-9,8.1-16C257.8,259.8,257.5,256,255.4,255.4z"/>
                            <path d="M239.7,248.8c2.7,1,4.8,3.1,7.6,3.9c0.4,0.1,0.9,0.2,1.3,0.3l0,0l0.4,0h0.3h1.2h0.3l0.4,0h0c0.4-0.1,0.9-0.2,1.3-0.3
                                c2.7-0.8,4.9-2.9,7.6-3.9c5.3-1.9,9.5-0.1,11.9,3.7c-0.7-6.5-9.3-17-16.9-16.1c-6.5,0.7-1.3,4.3-2.4,8.5s-6.1,4-6.6-0.6
                                c-0.4-3.2,2.2-5.2,1.3-6.8c-0.6-1.1-5.7-4-13.4,3.1c-3.5,3.2-7.1,7.8-6.5,11.9C230.3,248.7,234.5,246.9,239.7,248.8z"/>
                            
                                <linearGradient id="SVGID_63_" gradientUnits="userSpaceOnUse" x1="199.3046" y1="-53.3513" x2="199.3046" y2="-6.8827" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#D2BCA9"/>
                                <stop  offset="1" stop-color="#E4D0BD"/>
                            </linearGradient>
                            <path fill="url(#SVGID_63_)" d="M181.8,517.4c0,0,1.3-15.1,6.5-19.9c1.2-1.1,9,0.3,20.3,2.8c0,0,6.8,5.6,8.3,16.9
                                C216.8,517.3,197.7,523.3,181.8,517.4z"/>
                            <path fill="#AD9C8B" d="M188,519.2c1.1-5.9,2.3-11.9,3.9-17.7c-2.1,5.7-3.9,11.5-5.5,17.3L188,519.2z"/>
                            <path fill="#AD9C8B" d="M202.2,519.8c-0.2-1.4-0.3-2.7-0.4-4.1c-0.1-1.4-0.1-2.8-0.1-4.1c0-2.8,0.3-5.5,0.7-8.3
                                c-0.7,2.7-1.2,5.5-1.5,8.2c-0.2,1.4-0.3,2.8-0.3,4.2c-0.1,1.4-0.1,2.8,0,4.2L202.2,519.8z"/>
                            
                                <linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse" x1="300.7688" y1="-53.3513" x2="300.7688" y2="-6.8827" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#D2BCA9"/>
                                <stop  offset="1" stop-color="#E4D0BD"/>
                            </linearGradient>
                            <path fill="url(#SVGID_64_)" d="M318.3,517.4c0,0-1.3-15.1-6.5-19.9c-1.2-1.1-9,0.3-20.3,2.8c0,0-6.8,5.6-8.3,16.9
                                C283.2,517.3,302.4,523.3,318.3,517.4z"/>
                            <path fill="#AD9C8B" d="M311.9,519.2c-1.1-5.8-2.3-11.6-3.9-17.4c2.1,5.6,3.8,11.3,5.4,17.1L311.9,519.2z"/>
                            <path fill="#AD9C8B" d="M297.9,519.8c0.2-1.4,0.3-2.7,0.4-4.1c0.1-1.4,0.1-2.7,0.1-4.1c0-2.7-0.3-5.5-0.7-8.2
                                c0.7,2.7,1.2,5.4,1.5,8.1c0.2,1.4,0.3,2.7,0.3,4.1c0.1,1.4,0.1,2.8,0,4.1L297.9,519.8z"/>
                            <path fill="#45150B" d="M279.6,142.4l-1.7,15.6c0,0,34.2-2.8,52.1,8.3c-0.8-6.7-2.2-9.4-2.2-9.4S305.8,143.2,279.6,142.4z"/>
                            <path fill="#45150B" d="M220.2,142.4l1.7,15.6c0,0-34.2-2.8-52.1,8.3c0.8-6.7,2.2-9.4,2.2-9.4S193.9,143.2,220.2,142.4z"/>
                            
                                <linearGradient id="SVGID_65_" gradientUnits="userSpaceOnUse" x1="308.0475" y1="269.5895" x2="309.5075" y2="296.2812" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#FFFFFF" stop-opacity="0"/>
                                <stop  offset="0.44" stop-color="#D3D3D3" stop-opacity="7.000000e-02"/>
                                <stop  offset="0.96" stop-color="#545454" stop-opacity="0.28"/>
                                <stop  offset="1" stop-color="#464646" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_65_)" d="M329.9,166.4c-17.9-11.2-52-8.3-52-8.3l-2,21.4l-3.3,16.5l23,4.9l35.4-2.6l12.2-0.5
                                C340.2,186.7,335.7,176.2,329.9,166.4z"/>
                            
                                <linearGradient id="SVGID_66_" gradientUnits="userSpaceOnUse" x1="192.2709" y1="271.1047" x2="193.7577" y2="298.3338" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#FFFFFF" stop-opacity="0"/>
                                <stop  offset="0.44" stop-color="#D3D3D3" stop-opacity="7.000000e-02"/>
                                <stop  offset="0.96" stop-color="#545454" stop-opacity="0.28"/>
                                <stop  offset="1" stop-color="#464646" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_66_)" d="M223.9,179.5l-2-21.4c0,0-34.2-2.8-52.1,8.3c-5.8,9.8-10.3,20.3-13.2,31.3l12.2,0.5l35.4,2.6
                                l23-4.9L223.9,179.5z"/>
                            <path fill="#FFFFFF" d="M308.5,167.8c13,0,23.6,10.6,23.6,23.6c0,13-10.6,23.6-23.6,23.6c-13,0-23.6-10.6-23.6-23.6c0,0,0,0,0,0
                                C284.9,178.4,295.4,167.8,308.5,167.8C308.5,167.8,308.5,167.8,308.5,167.8"/>
                            <path fill="#FFFFFF" d="M191.2,167.8c-13,0-23.6,10.6-23.6,23.6c0,13,10.6,23.6,23.6,23.6c13,0,23.6-10.6,23.6-23.6c0,0,0,0,0,0
                                C214.8,178.4,204.3,167.8,191.2,167.8"/>
                            <path fill="#301E1E" d="M199.2,180.8c-2.8,0-5.6,1-7.7,2.7c2.6,0.2,4.7,2.4,4.5,5.1c-0.2,2.5-2.2,4.5-4.7,4.5h-4.3
                                c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3S206,180.8,199.2,180.8z"/>
                            <path fill="#301E1E" d="M303.7,188.3c0-2.5,2-4.6,4.6-4.8c-5.3-4.3-13-3.4-17.3,1.8c-4.3,5.3-3.4,13,1.8,17.3
                                c5.3,4.3,13,3.4,17.3-1.8c1.8-2.2,2.7-4.9,2.7-7.7h-4.3C305.9,193.1,303.7,190.9,303.7,188.3z"/>
                        </svg>
                return man;

    }
    getTeenWithTool(backGroundColor){
            const man=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                            <path fill={backGroundColor} d="M395.5,411.3c-7.4,6.1-17,8.8-26.4,7.6c11.6-12.1,15.7-29.5,10.7-45.4c-11.6,19.2-24.2,33.1-36.6,43.2
                                c0-0.6,0-1.1-0.1-1.7c0,0,0-0.1,0-0.1c0-0.5-0.1-1.1-0.2-1.6v-0.2c-0.1-1.2-0.3-2.4-0.5-3.6c0,0,0-0.1,0-0.1
                                c-0.1-0.6-0.2-1.2-0.3-1.8c0-0.1,0-0.1,0-0.2c-0.2-1.3-0.5-2.6-0.7-4v-0.1c-0.1-0.7-0.3-1.3-0.4-2v-0.1c-1.6-7.5-3.8-15.8-5.9-24.6
                                c-0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.2-0.9-0.3-1.3l-0.3-1.1c-0.1-0.4-0.2-0.7-0.3-1.1c-0.2-0.7-0.3-1.4-0.5-2.1
                                c-0.1-0.3-0.1-0.6-0.2-0.9l-0.3-1.5l-0.2-0.9l-0.4-1.6l-0.2-0.7c-0.2-0.8-0.3-1.5-0.5-2.3l-0.2-0.7c-0.1-0.6-0.2-1.1-0.4-1.7
                                c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.6-0.3-1.3-0.4-1.9c0-0.2-0.1-0.3-0.1-0.5l-0.5-2.4c0-0.2-0.1-0.5-0.1-0.7
                                c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.8c-0.1-0.7-0.3-1.5-0.4-2.2c0-0.1,0-0.2,0-0.3c-0.1-0.8-0.3-1.6-0.4-2.5c0-0.2-0.1-0.5-0.1-0.7
                                c-0.1-0.6-0.2-1.2-0.3-1.9c0-0.3-0.1-0.5-0.1-0.7c-0.1-0.8-0.2-1.7-0.3-2.5c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.5
                                c-0.1-0.5-0.2-1-0.3-1.5c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.3-1.1-0.4-1.6c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.4-0.3-0.8-0.4-1.2l-0.1-0.3
                                c-0.2-0.5-0.4-1-0.6-1.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.3-0.7-0.5-1.1l-0.2-0.3c-0.2-0.4-0.5-0.9-0.7-1.3L324,328
                                c-0.2-0.3-0.4-0.7-0.6-1l-0.2-0.3c-0.3-0.4-0.5-0.8-0.8-1.1c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.3-0.5-0.6-0.8-0.9l-0.3-0.3
                                c-0.3-0.3-0.6-0.7-0.9-1l-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-0.9L319,322l-0.9-0.8l-0.2-0.1c-0.3-0.3-0.7-0.6-1-0.8l-0.3-0.2
                                c-0.3-0.2-0.6-0.4-0.9-0.6l-0.2-0.2c-0.4-0.3-0.7-0.5-1.1-0.7l-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.1-0.1-0.2-0.1-0.3-0.2
                                c-0.4-0.2-0.7-0.4-1.1-0.6l-0.2-0.1l-0.9-0.5l-0.3-0.1c-0.4-0.2-0.8-0.4-1.1-0.5l-0.1-0.1l-1-0.4l-0.3-0.1
                                c-0.8-0.3-1.6-0.6-2.3-0.8l-0.3-0.1l-1.1-0.3h-0.1l-1.2-0.3l-0.2-0.1l-1-0.3h-0.1l-1.2-0.3h-0.1l-1.1-0.2h-0.1
                                c-0.8-0.2-1.5-0.3-2.2-0.4h-0.2c-0.7-0.1-1.4-0.2-2.1-0.3l0,0l-0.1-0.1l0,0l0,0l-10.3-3.7l-1.7-6.9c32.8-5.2,54-18.8,65.5-39.5
                                c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.9-5.8,0.9-5.8c0.3-2.1,0.6-4.8,0.7-7c0.2-3.1,0.3-6.3,0.3-9.6c0-2.1-0.1-4.1-0.3-6.2
                                c-0.8-6.6-2.4-13-4.9-19.1c-0.4-0.9-0.8-1.8-1.2-2.7c5.4-9.8,10.8-20.2,12.1-29.6c4-29.1-13.1-74.6-27.2-82.3
                                c-13.7-7.5-21.2,41-33,54.1c-1.3,1.4-2.3,2.9-3.2,4.6c-31.7-17.2-70-17.1-101.7,0.1c-0.8-1.7-1.9-3.3-3.2-4.7
                                c-11.8-13.1-19.3-61.6-33-54.1c-14.1,7.7-31.2,53.3-27.2,82.3c1.3,9.5,6.9,20,12.3,29.9c-0.3,0.7-0.6,1.4-0.9,2.1
                                c-3.4,8.1-5.2,16.8-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5c0.3,2.3,0.7,4.7,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9
                                c11.5,20.8,32.9,34.4,65.9,39.5l-2,6.8l-9.9,3.6c-0.4,0.1-0.8,0.1-1.2,0.2h-0.1c-0.4,0.1-0.7,0.2-1.1,0.3l-0.2-0.2l-1,0.3l-0.3,0.1
                                c-0.3,0.1-0.6,0.2-0.9,0.3l-0.3,0.1l-0.9,0.3l-0.4,0.1l-0.9,0.4l-0.4,0.2c-0.3,0.1-0.6,0.3-0.9,0.4l-0.4,0.2
                                c-0.4,0.2-0.9,0.4-1.3,0.7c-38.6,21.7-84.6,28.5-81.2,49.9c0.1,0.5,0.2,0.9,0.3,1.3c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.3,0.9
                                c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.4,0.7,0.6,1.1c0,0.1,0.1,0.2,0.1,0.2
                                c0.2,0.3,0.3,0.5,0.5,0.8l0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.2,0.2,0.2,0.3c0.2,0.3,0.5,0.5,0.8,0.8l0.1,0.1
                                c0.3,0.3,0.6,0.5,0.9,0.7l0.3,0.2l0.7,0.5l0.3,0.2c0.3,0.2,0.5,0.3,0.8,0.4l0.3,0.1c0.4,0.2,0.7,0.3,1.1,0.5l0.2,0.1
                                c0.3,0.1,0.6,0.2,1,0.4l0.4,0.1l0.9,0.3l0.4,0.1c0.4,0.1,0.7,0.2,1.1,0.3h0.2c0.4,0.1,0.9,0.2,1.4,0.2l0.4,0.1l1,0.1l0.5,0
                                c0.4,0,0.7,0.1,1.1,0.1h0.4c0.5,0,1,0,1.5,0.1h2h1.1h0.5l1.4-0.1h0.3l1.7-0.2l0.4,0l1.3-0.2l0.5-0.1l1.3-0.2l0.5-0.1l1.7-0.3h0.1
                                l1.6-0.3l0.5-0.1l1.3-0.3l0.6-0.1l1.4-0.3l0.3-0.1l1.8-0.4l0.4-0.1c0.5-0.1,0.9-0.2,1.4-0.3l0.6-0.1l1.3-0.3l0.5-0.1
                                c1.2-0.3,2.4-0.7,3.6-1l0.5-0.2l1.3-0.4l0.6-0.2l1.4-0.4l0.4-0.1l1.7-0.6l0.4-0.1l1.4-0.5l0.6-0.2l1.2-0.4l0.6-0.2l1.5-0.5l0.1-0.1
                                l1.7-0.6l0.5-0.2l1.1-0.4l0.6-0.2l1.1-0.4l0.5-0.2l1.5-0.6l0.4-0.2l1.1-0.5l0.6-0.2l0.9-0.4l0.6-0.2l1.1-0.4l0.4-0.2l1.3-0.6
                                l0.5-0.2l0.8-0.3l0.6-0.2l0.8-0.3l0.5-0.2l1.1-0.5h0c-0.1,0.9-0.3,1.8-0.4,2.7c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.6-0.2,1.3-0.3,1.9
                                c-0.1,0.4-0.1,0.7-0.1,1.1c-0.1,0.6-0.2,1.2-0.2,1.9l-0.1,1.1c-0.1,0.7-0.2,1.3-0.2,2c0,0.3-0.1,0.7-0.1,1
                                c-0.1,0.8-0.2,1.6-0.3,2.5c0,0.2,0,0.4-0.1,0.6c-0.1,1-0.2,2.1-0.3,3.2c0,0.3,0,0.6-0.1,0.9c-0.1,0.8-0.1,1.6-0.2,2.4
                                c0,0.4-0.1,0.8-0.1,1.2c-0.1,0.7-0.1,1.4-0.2,2.2c0,0.4-0.1,0.9-0.1,1.3c-0.1,0.7-0.1,1.5-0.2,2.3c0,0.4-0.1,0.8-0.1,1.3
                                c-0.1,0.9-0.1,1.7-0.2,2.6l-0.1,1c-0.1,1.2-0.1,2.4-0.2,3.7v0.7c0,1-0.1,2-0.1,3c0,0.4,0,0.9,0,1.3c0,0.8-0.1,1.7-0.1,2.5
                                c0,0.5,0,1,0,1.5l-0.1,2.5v1.5c0,0.9,0,1.8,0,2.8c0,0.4,0,0.9,0,1.3c0,1.4,0,2.8,0,4.2v0.3c0,0,1.9,47.3,3.3,66
                                c0.3,3.5,0.6,6.8,1,9.7c2.2,15.2,23.4,21.8,25.4,20.1c4.4-3.7,7.4-10.7,9.6-18.3l0.1-0.5l0.1-0.3c3.7-13.8,4.5-16.6,4.6-18.8
                                c11.8,2.5,18.8,2.1,18.8,2.1s7,0.4,18.8-2.1c0.1,1.9,0.6,0.7,3.1,12.5c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.4,0.1,0.6
                                c2,9.9,5.4,19.9,11,24.6c2.1,1.7,23.3-4.5,25.4-20.1c0.1-0.5,0.1-1,0.2-1.5c0.3-2.6,0.6-5.4,0.9-8.4c0-0.3,0.1-0.7,0.1-1.1l0.1-0.7
                                c0-0.1,0-0.2,0-0.3c0-0.3,0-0.7,0.1-1V484c0-0.4,0-0.7,0.1-1.1l0,0c3,0.2,6.1,0,9.1-0.5c-10.9-7-6.7-13.5-6.7-13.5
                                c43.3,9.3,55.1-13.8,55.1-13.8c-8.9,1.8-18.1,1.2-26.6-1.7C391.6,436.6,395.5,411.3,395.5,411.3z M312.4,434.9v-0.7
                                c0-0.2,0-0.3,0-0.5c0.1-1.9,0.2-3.7,0.2-5.3c0-0.6,0.1-1.2,0.1-1.7c0-0.2,0-0.3,0-0.5c0.4,0.6,0.8,1.1,1.2,1.6
                                c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.4,0.6,0.8,1,1.2l0.3,0.3c0.4,0.4,0.7,0.8,1.1,1.2l0.1,0.1c0.4,0.5,0.9,0.9,1.4,1.3
                                C316.2,433.4,314.3,434.2,312.4,434.9z"/>
                            <path opacity="0.25" fill="#D3D3D3" enable-background="new    " d="M337,75.6c-13.7-7.5-21.2,41-33,54.1c-1.3,1.4-2.3,2.9-3.2,4.6
                                c-31.7-17.2-70-17.1-101.7,0.1c-0.8-1.7-1.9-3.3-3.2-4.7c-11.8-13.1-19.3-61.6-33-54.1c-14.1,7.7-31.2,53.3-27.2,82.3
                                c1.3,9.5,6.9,20,12.3,29.9c-0.3,0.7-0.6,1.4-0.9,2.1c-3.4,8.1-5.2,16.8-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5
                                c11.2-15.6,36.1-22.5,51.4-20.4c5.6,0.8,11.2,1.2,16.9,1.4c3,0.1,5.9,0,8.9-0.5c12.3-2.7,12.3-10.8,28.2-12.6h0.3
                                c0.6,0.1,1.2,0.2,1.8,0.2c0.6-0.1,1.1-0.2,1.7-0.2h0.2c15.9,1.8,15.9,9.9,28.2,12.6c2.9,0.5,5.9,0.7,8.9,0.5
                                c5.6-0.2,11.3-0.6,16.9-1.4c15.4-2,40.6,5,51.6,20.9c0.3-2.1,0.5-4.2,0.6-6.3c0.2-3.1,0.3-6.3,0.3-9.6c0-2.1-0.1-4.1-0.3-6.2
                                c-0.8-6.6-2.4-13-4.9-19.1c-0.4-0.9-0.8-1.8-1.2-2.7c5.4-9.8,10.8-20.2,12.1-29.6C368.2,128.8,351,83.3,337,75.6z M148.3,132.3
                                c1.5-7,12.6-31.1,17.3-36.6c4.7,11.1,26.3,45.3,9.2,54.5c-3.2-2.3-5.2-5.8-5.7-9.6c-0.8,5-1.2,10.1-1.3,15.2
                                c-2.2-2.2-4.1-3.8-5.2-6.5c-1.4,8.5-4.6,15.9-7.6,24.7C146.4,161.9,145.3,145.6,148.3,132.3L148.3,132.3z M344.8,173.9
                                c-2.9-8.7-6.1-16.2-7.6-24.7c-1,2.7-2.9,4.3-5.2,6.5c-0.1-5.1-0.5-10.2-1.3-15.2c-0.5,3.9-2.5,7.4-5.7,9.6
                                c-17.1-9.2,4.5-43.4,9.2-54.5c4.7,5.4,15.7,29.6,17.3,36.6C354.4,145.6,353.4,161.9,344.8,173.9L344.8,173.9z"/>
                            <path opacity="0.25" fill="#D3D3D3" enable-background="new    " d="M327.9,340.1c-2.5-21.6-22.5-26.9-33.3-28.2
                                c0-0.1-0.1-0.1-0.1-0.2l-10.3-3.7l-5.5-21.9h-1.1l-0.2-0.5h-54.9L216,308l-9.9,3.6c-3.6,0.6-7.1,1.9-10.3,3.7
                                c-38.6,21.7-84.6,28.5-81.2,49.9c4,25.6,54.4,6.5,76.4-3.1c-4.5,31.6-4.6,55.4-2.5,91.7c1.5,18.7,0.5,39.4,4.9,49.3
                                c5.3,9.9,18.4,14.4,22.4,14.3c7.1-0.5,15.4-35.4,15.4-38.2c11.8,2.5,18.8,2.1,18.8,2.1s7,0.4,18.8-2.1c4,12.6,7.2,46.5,23.1,36.4
                                c5.9-2.4,12.9-7.2,15.7-14.6c4.6-25.1,3.4-50.7,5.1-74.7c6.3,9,13.8,13.7,22.6,9.2C354.8,425.5,333.1,384.3,327.9,340.1z
                                M283.8,426.6c-14.3,25.9-54.2,28.1-69.5-2.7c-10.1-19.5-12-45.9-11.1-72.2c0.4-13.7,0.4-26.8,14.8-32.2l0,0
                                c20.6-7.4,43-7.4,63.6,0l0,0c2.5,0.9,4.8,2.3,6.8,4l0,0c10,9.3,7,21.1,8.1,32.8C296.8,381.6,295.6,405.7,283.8,426.6z"/>
                            <path fill="#FFFFFF" d="M281.8,267.4c-0.4-0.8-2-4.6-4.7-4.6c-2.5-0.1-4.2,2.8-4.9,4c-1,1.7-1.5,3.5-1.5,5.5h0.1
                                c0.6,0,1.2,0.1,1.8,0.1h0.7h1.3c1.9,0,3.8-0.1,5.7-0.3h0.2h0.1l0.4,0c0.4,0,0.7-0.1,1.1-0.1h0.1l0.5-0.1
                                C282.6,270.3,282.4,268.8,281.8,267.4z"/>
                            <path fill="#45150B" d="M279.6,142.4l-1.7,15.6c0,0,34.2-2.8,52.1,8.3c-0.8-6.7-2.2-9.4-2.2-9.4S305.8,143.2,279.6,142.4z"/>
                            <path fill="#45150B" d="M220.2,142.4l1.7,15.6c0,0-34.2-2.8-52.1,8.3c0.8-6.7,2.2-9.4,2.2-9.4S193.9,143.2,220.2,142.4z"/>
                            <path fill="#FFFFFF" d="M223.4,262.7c-2.3-0.2-4.3,2.8-5.1,5.1c-0.4,1.3-0.5,2.8-0.3,4.2h0.1l1.1,0.1l0.4,0h0.1h0.2
                                c1.9,0.2,3.8,0.3,5.7,0.3h1.3h0.7l1-0.1c-0.1-1.8-0.6-3.5-1.3-5.1C226.4,265.4,225.3,262.9,223.4,262.7z"/>
                            
                                <linearGradient id="SVGID_59_" gradientUnits="userSpaceOnUse" x1="199.3045" y1="-53.3513" x2="199.3045" y2="-6.8827" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#D2BCA9"/>
                                <stop  offset="1" stop-color="#E4D0BD"/>
                            </linearGradient>
                            <path fill="url(#SVGID_59_)" d="M181.8,517.4c0,0,1.3-15.1,6.5-19.9c1.2-1.1,9,0.3,20.3,2.8c0,0,6.8,5.6,8.3,16.9
                                C216.8,517.3,197.7,523.3,181.8,517.4z"/>
                            <path fill="#AD9C8B" d="M188,519.2c1.1-5.9,2.3-11.9,3.9-17.7c-2.1,5.7-3.9,11.5-5.5,17.3L188,519.2z"/>
                            <path fill="#AD9C8B" d="M202.2,519.8c-0.2-1.4-0.3-2.7-0.4-4.1c-0.1-1.4-0.1-2.8-0.1-4.1c0-2.8,0.3-5.5,0.7-8.3
                                c-0.7,2.7-1.2,5.5-1.5,8.2c-0.2,1.4-0.3,2.8-0.3,4.2c-0.1,1.4-0.1,2.8,0,4.2L202.2,519.8z"/>
                            
                                <linearGradient id="SVGID_60_" gradientUnits="userSpaceOnUse" x1="300.7687" y1="-53.3513" x2="300.7687" y2="-6.8827" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#D2BCA9"/>
                                <stop  offset="1" stop-color="#E4D0BD"/>
                            </linearGradient>
                            <path fill="url(#SVGID_60_)" d="M318.3,517.4c0,0-1.3-15.1-6.5-19.9c-1.2-1.1-9,0.3-20.3,2.8c0,0-6.8,5.6-8.3,16.9
                                C283.2,517.3,302.4,523.3,318.3,517.4z"/>
                            <path fill="#AD9C8B" d="M311.9,519.2c-1.1-5.8-2.3-11.6-3.9-17.4c2.1,5.6,3.8,11.3,5.4,17.1L311.9,519.2z"/>
                            <path fill="#AD9C8B" d="M297.9,519.8c0.2-1.4,0.3-2.7,0.4-4.1c0.1-1.4,0.1-2.7,0.1-4.1c0-2.7-0.3-5.5-0.7-8.2
                                c0.7,2.7,1.2,5.4,1.5,8.1c0.2,1.4,0.3,2.7,0.3,4.1c0.1,1.4,0.1,2.8,0,4.1L297.9,519.8z"/>
                            <path fill="#EEE6D9" d="M295.6,337.7c-0.7-8.3-6.1-15.4-13.9-18.2l0,0c-20.5-7.5-43.1-7.5-63.6,0l0,0c-7.8,2.8-13.3,10-13.9,18.2
                                c-2.7,32.8-4.1,108.7,45.8,108.8C299.8,446.4,298.3,370.5,295.6,337.7z"/>
                            <path fill="#EEE6D9" d="M305.8,210.5c-5.6,0.8-11.2,1.2-16.9,1.4c-3,0.1-5.9,0-8.9-0.5c-12.2-2.7-12.3-10.8-28.2-12.6h-0.3
                                c-0.6,0.1-1.2,0.2-1.7,0.2c-0.6-0.1-1.1-0.2-1.8-0.2h-0.3c-15.9,1.8-15.9,9.9-28.2,12.6c-2.9,0.5-5.9,0.7-8.9,0.5
                                c-5.6-0.2-11.3-0.6-16.9-1.4c-15.2-2-40.2,4.9-51.4,20.4c0.3,2.3,0.7,4.7,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9
                                c12.1,21.8,35.1,35.8,71.1,40.2c2.4,0.3,4.8,0.6,7.3,0.8c6.1,0.5,12.6,0.8,19.5,0.8s13.3-0.3,19.5-0.8c2.6-0.2,5.1-0.5,7.6-0.8
                                c35.8-4.5,58.8-18.4,70.9-40.2c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.8-4.3,1-6.5C346.4,215.5,321.2,208.5,305.8,210.5z"/>
                            <path fill="none" d="M236.7,204.1c2.3,3.7,5.1,6.9,8.3,6.9C245.5,206,241.8,203.7,236.7,204.1z"/>
                            <path fill="none" d="M255,211c3.2,0,6-3.2,8.3-6.9C258.3,203.7,254.6,206,255,211z"/>
                            <path fill="#230905" d="M245,211c-3.2,0-6-3.2-8.3-6.9C241.8,203.7,245.5,206,245,211z M255,211c-0.5-5.1,3.2-7.4,8.3-6.9
                                C261,207.8,258.3,211,255,211z M269.7,197.5c-1.5-4.6-5.9-4.1-10.6-3.8c-3,0.4-6,0.6-9.1,0.6c-3,0-6.1-0.2-9.1-0.6
                                c-4.7-0.3-9.1-0.8-10.6,3.8c-0.3,2.8,0.4,5.7,2,8.1c3.4,5.2,10.5,10.5,16.1,13c0.6,0.2,1.1,0.5,1.6,0.7c0.5-0.2,1.1-0.4,1.6-0.7
                                c5.6-2.5,12.7-7.9,16.1-13C269.4,203.2,270.1,200.3,269.7,197.5z"/>
                            <path fill="#45150B" d="M309.9,233.8L309.9,233.8c-5.4-5.1-12.9,1.6-25.8,1.2c-6.7,0-13.3-2.2-18.6-6.3l0,0c-1.3-1-2.8-1.8-4.3-2.4
                                c-2.3-1-4.8-1.6-7.3-1.9c-1-1.9-1.7-3.8-2.3-5.8c-0.6,0.2-1.1,0.5-1.6,0.7c-0.5-0.2-1.1-0.4-1.6-0.7c-0.5,2-1.3,4-2.3,5.8
                                c-2.5,0.3-5,0.9-7.3,1.9c-1.5,0.7-3,1.5-4.3,2.4l0,0c-5.3,4.1-11.9,6.3-18.6,6.3c-12.9,0.4-20.4-6.3-25.8-1.2l0,0
                                c-0.7,0.7-1.2,2.8-1.1,4.9c0,0.3,0,0.6,0.1,0.9c0,0.1,0,0.2,0,0.3c1.7,10.1,4.6,19.8,13.4,26.5c3.1,2.4,7.6,4.2,12.8,5.2l0.7,0.1
                                l1.8,0.3h0.1c-0.3-1.4-0.2-2.8,0.3-4.2c0.7-2.3,2.8-5.4,5.1-5.1c1.9,0.2,3.1,2.7,3.9,4.5c0.7,1.6,1.2,3.4,1.3,5.1h0.7l1-0.1
                                c0.6-0.1,1.2-0.1,1.8-0.2l1.1-0.1c0.6-0.1,1.2-0.2,1.8-0.2l1.2-0.2c0.6-0.1,1.1-0.2,1.7-0.3c0.6-0.1,0.9-0.2,1.4-0.3
                                c0.5-0.1,1.1-0.2,1.6-0.4l1.6-0.4c0.5-0.1,0.9-0.3,1.4-0.4c0.6-0.2,1.3-0.4,1.9-0.6c0.4-0.1,0.8-0.2,1.1-0.4c1-0.4,2-0.7,3.1-1.2h0
                                c1,0.4,2,0.8,3.1,1.2c0.4,0.1,0.8,0.2,1.1,0.4c0.6,0.2,1.3,0.4,1.9,0.6c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.3,1.6,0.4
                                c0.5,0.1,1.1,0.2,1.6,0.4c0.5,0.1,0.9,0.2,1.4,0.3c0.5,0.1,1.1,0.2,1.7,0.3l1.2,0.2l1.8,0.2l1.1,0.1c0.6,0.1,1.2,0.1,1.8,0.2
                                l0.9,0.1c0-1.9,0.6-3.8,1.5-5.5c0.7-1.2,2.5-4.1,4.9-4c2.8,0.1,4.4,3.8,4.7,4.6c0.6,1.4,0.9,2.9,0.8,4.4l1.3-0.2l0.7-0.1
                                c5.3-1,9.7-2.8,12.8-5.2c8.8-6.6,11.7-16.4,13.4-26.5c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-0.9C311,236.6,310.6,234.5,309.9,233.8z
                                "/>
                            <path fill="#FF828B" d="M260.3,248.8c-2.7,1-4.8,3.1-7.6,3.9c-0.4,0.1-0.9,0.2-1.3,0.3h0l-0.4,0h-0.3h-1.2h-0.3l-0.4,0l0,0
                                c-0.4-0.1-0.9-0.2-1.3-0.3c-2.7-0.8-4.9-2.9-7.6-3.9c-8.7-3.2-14.5,3.9-14.4,13c0,4.3,1.2,8.5,3.4,12.1
                                c4.5,7.5,12.5,12.3,21.3,12.7c8.8-0.4,16.8-5.2,21.3-12.7c2.2-3.7,3.4-7.8,3.4-12.1C274.8,252.7,269,245.6,260.3,248.8z"/>
                            <path fill="#FF6E76" d="M255.4,255.4c-1.5-0.4-3.6,0.9-5.1,3.4c-1.5-3.3-5.4-6.5-7.6,0.5c-2.2,7,2.4,17.8,7.1,17.9
                                c4.4,0.1,8.7-9,8.1-16C257.8,259.8,257.5,256,255.4,255.4z"/>
                            <path d="M239.7,248.8c2.7,1,4.8,3.1,7.6,3.9c0.4,0.1,0.9,0.2,1.3,0.3l0,0l0.4,0h0.3h1.2h0.3l0.4,0h0c0.4-0.1,0.9-0.2,1.3-0.3
                                c2.7-0.8,4.9-2.9,7.6-3.9c5.3-1.9,9.5-0.1,11.9,3.7c-0.7-6.5-9.3-17-16.9-16.1c-6.5,0.7-1.3,4.3-2.4,8.5c-1.1,4.2-6.1,4-6.6-0.6
                                c-0.4-3.2,2.2-5.2,1.3-6.8c-0.6-1.1-5.7-4-13.4,3.1c-3.5,3.2-7.1,7.8-6.5,11.9C230.3,248.7,234.5,246.9,239.7,248.8z"/>
                            <path fill="#FFFFFF" d="M308.5,167.8c-13,0-23.6,10.6-23.6,23.6c0,13,10.6,23.6,23.6,23.6c13,0,23.6-10.6,23.6-23.6c0,0,0,0,0,0
                                C332.1,178.4,321.5,167.8,308.5,167.8z"/>
                            <path fill="#FFFFFF" d="M191.2,167.8c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6c13,0,23.6-10.6,23.6-23.6c0,0,0,0,0,0
                                C214.8,178.4,204.3,167.8,191.2,167.8z"/>
                            <path fill="#301E1E" d="M199.2,180.8c-2.8,0-5.6,1-7.7,2.7c2.6,0.2,4.7,2.4,4.5,5.1c-0.2,2.5-2.2,4.5-4.7,4.5h-4.3
                                c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3C211.5,186.3,206,180.8,199.2,180.8z"/>
                            <path fill="#301E1E" d="M303.7,188.3c0-2.5,2-4.6,4.6-4.8c-5.3-4.3-13-3.4-17.3,1.8c-4.3,5.3-3.4,13,1.8,17.3
                                c5.3,4.3,13,3.4,17.3-1.8c1.8-2.2,2.7-4.9,2.7-7.7h-4.3C305.9,193.1,303.7,190.9,303.7,188.3z"/>
                            
                                <linearGradient id="SVGID_61_" gradientUnits="userSpaceOnUse" x1="308.0475" y1="269.5897" x2="309.5074" y2="296.2814" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#FFFFFF" stop-opacity="0"/>
                                <stop  offset="0.44" stop-color="#D3D3D3" stop-opacity="7.000000e-02"/>
                                <stop  offset="0.96" stop-color="#545454" stop-opacity="0.28"/>
                                <stop  offset="1" stop-color="#464646" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_61_)" d="M329.9,166.4c-17.9-11.2-52-8.3-52-8.3l-2,21.4l-3.3,16.5l23,4.9l35.4-2.6l12.2-0.5
                                C340.2,186.7,335.7,176.2,329.9,166.4z"/>
                            
                                <linearGradient id="SVGID_62_" gradientUnits="userSpaceOnUse" x1="192.2708" y1="271.1049" x2="193.7576" y2="298.334" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#FFFFFF" stop-opacity="0"/>
                                <stop  offset="0.44" stop-color="#D3D3D3" stop-opacity="7.000000e-02"/>
                                <stop  offset="0.96" stop-color="#545454" stop-opacity="0.28"/>
                                <stop  offset="1" stop-color="#464646" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_62_)" d="M223.9,179.5l-2-21.4c0,0-34.2-2.8-52.1,8.3c-5.8,9.8-10.3,20.3-13.2,31.3l12.2,0.5l35.4,2.6
                                l23-4.9L223.9,179.5z"/>
                        </svg>

            return man;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        return (
                <>
                { isKid ?
                       (  
                                withTool ?
                            this.getkidWithTool(backGroundColor)  :
                            this.getKidWithOutTool(backGroundColor)
                       )
                        
                    :
                        (
                            withTool ?
                            this.getTeenWithTool(backGroundColor) :
                            this.getTeenWithOutTool(backGroundColor)
                        )
                    }
            </>
        )
    }
}
