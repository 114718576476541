import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface GoannaProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface GoannaState {
    height:string
}


export class Goanna extends React.Component<GoannaProps, GoannaState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                    <path fill={backGroundColor} d="M380,226.9c-0.3-0.2-0.6-0.3-0.9-0.4c-1.2-0.5-3.5-1.6-6.8-2.6c-14.7-27.4-42.2-52.7-76-65.3
                            c-14.5-5.4-36.1-17.8-46.4-17.8c-10.5,0-32,12.4-46.5,17.9c-33.9,12.7-61.3,38-76.1,65.6c-2.8,1-5,1.9-6.1,2.4
                            c-0.3,0.2-0.6,0.3-0.9,0.4c-12.5,16.3-6.2,39.8-4.7,44.6c0.2,4.9,0.5,9.6,1.1,14.1c0.4,2.9,0.8,5.7,1.3,8.6
                            c2.1,10.9,5.6,20.7,10.5,29.6c9.2,16.6,23.5,29.5,43.5,38.3c6.6,2.9,13.9,5.4,21.9,7.4c-9.6,9.7-51,53.8-25.4,69.1
                            c8.1,4.9,14.7,3.5,20.1-0.8c-0.3,6.2-0.4,12.6-0.4,19.3c0,0,0.1,22.4,1.4,34.3c0.2,1.9,0.5,3.7,0.9,5.6c-0.8,0-1.3,0.2-1.6,0.4
                            c-1.3,1.2-2.4,3.2-3.3,5.3l0,0c-24.9,4.4-57.4,5.6-75.7,7.1c-24.6,2-17.5,10,2.6,9.7c17.3-0.3,49.1,0.2,83.9,0.3
                            c1.1,0,2.1,0,3.1,0c4.6,0,9.3,0,13.9,0c41.1,0,44.9-5.5,53-14.5c2.8-3.2,4.9-7.5,6.6-12.2c0-0.4-0.1-0.9-0.1-1.3
                            c0.9,9.5,3.1,20.2,8.6,25.1h0.1l0,0c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15.1-6.4-19.9c-0.3-0.3-0.8-0.4-1.6-0.4
                            c0.4-1.9,0.7-3.7,0.9-5.6c1.4-11.9,1.4-34.3,1.4-34.3c0.1-6.8-0.1-13.4-0.4-19.7c5.5,4.6,12.3,6.1,20.6,1.1
                            c25.7-15.3-15.8-59.4-25.4-69.1c7.9-2,15.2-4.4,21.9-7.3c20-8.7,34.3-21.7,43.5-38.3c4.9-8.8,8.4-18.7,10.4-29.6
                            c0.5-2.6,1-5.3,1.2-8c0.4-2.6,0.6-5.2,0.8-7.9c0.2-1.9,0.3-3.9,0.4-5.8l0.3-0.1C384.3,272.4,394,245.1,380,226.9z M217.4,517
                            c-0.1,0.1-0.3,0.1-0.4,0.1l0,0C217.2,517.1,217.3,517.1,217.4,517C217.5,517,217.5,517,217.4,517C217.5,517,217.5,517,217.4,517z
                                M217.5,517L217.5,517L217.5,517L217.5,517z M301,370.8c0-0.1,0-0.1,0-0.2c0.1,0,0.2,0,0.3-0.1L301,370.8z"/>
                        
                            <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="150.6994" y1="203.7278" x2="147.2551" y2="241.6765" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                            <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#0A0A0A"/>
                        </linearGradient>
                        <path opacity="0.6" fill="url(#SVGID_39_)" enable-background="new    " d="M121.3,226.4c-0.3,0.2-0.6,0.3-0.9,0.4
                            c-14,18.3-4.3,45.6-4.3,45.6l57.2,14.8c0,0,19.8-18.4,12.5-51.2C161.7,209.8,127.3,223.7,121.3,226.4z"/>
                        <path fill="#5BA564" d="M380,226.9c0,0-38.9-19.7-65.4,9.3c-10.1-8.4-9.5-8.2-9.5-8.2S344.4,200,380,226.9z"/>
                        <path fill="#5BA564" d="M120.4,226.9c0,0,38.9-19.7,65.4,9.3c10.1-8.4,9.5-8.2,9.5-8.2S155.9,200,120.4,226.9z"/>
                        <path fill="#2D4C32" d="M348,297.7c-1.4-0.6-2.9-1.1-4.5-1.3c-1.6-0.2-3.4,0-4.6,0.8c-0.5,0.4-1.9,1.3-3.5,2.6
                            c-0.4,0.2-0.7,0.4-1.1,0.5c-4.2,2-7.5,3.6-12,3.7c-5,0.1-10.1,0-15,0c-2.8,0-5.6,0-8.4,0h-48.9h-0.8h-48.9c-2.8,0-5.6,0-8.4,0
                            c-4.9,0-10.1,0.1-15,0c-4.5-0.1-7.8-1.7-11.9-3.6c-1.8-1.5-3.6-2.8-4.1-3.2c-1.2-0.8-3-1-4.6-0.8c-1.6,0.2-3.1,0.7-4.5,1.3
                            c-1.3,0.6-2.7,1.4-2.8,2.6c-0.1,0.7,0.4,1.5,1.1,2c1.7,1.5,4.3,2.2,6.9,2.6c0.8,0.1,3.2,0.5,5.6,0.8c4.1,1.9,8.5,3.8,14.5,3.9
                            c2.3,0,4.6,0,6.9,0c2.8,0,5.6,0,8.4,0s5.6,0,8.3,0h48.9h0.8h48.9c2.7,0,5.6,0,8.3,0c2.7,0,5.6,0,8.4,0c2.3,0,4.6,0,6.9,0
                            c5.8-0.1,10.2-1.9,14.3-3.8c2.6-0.4,5.3-0.8,6.3-0.9c2.6-0.4,5.2-1.1,6.9-2.6c0.6-0.6,1.1-1.3,1.1-2
                            C350.8,299.1,349.3,298.4,348,297.7z"/>
                        <g>
                            <path fill="#2D4C32" d="M235,299.9l-3.6-2.1c-0.8-0.4-1.1-1.5-0.6-2.3l0,0c0.4-0.8,1.5-1.1,2.3-0.6l3.6,2.1
                                c0.8,0.4,1.1,1.5,0.6,2.3l0,0C236.8,300.1,235.8,300.4,235,299.9z"/>
                            <path fill="#2D4C32" d="M264.8,299.9l3.6-2c0.8-0.4,1.1-1.5,0.6-2.3l0,0c-0.4-0.8-1.5-1.1-2.3-0.6l-3.6,2
                                c-0.8,0.4-1.1,1.5-0.6,2.3l0,0C263,300.1,264,300.4,264.8,299.9z"/>
                        </g>
                        <g>
                            <path fill="#FCAE47" d="M234.2,203c-4.7-5.1-4.3-12.3,0.8-16c5.1-3.7,11.6-3.6,16,6.4c2.6,6.2,6.2,8.5,1,12.1
                                C246.8,209.2,238.9,208.1,234.2,203z"/>
                            <path fill="#FCAE47" d="M266,222.7c-0.4,3.4-3.6,5.9-7.1,5.6c-3.5-0.4-6-3.4-5.6-6.8c0.4-3.4,3.6-5.9,7.1-5.6
                                C263.8,216.2,266.4,219.3,266,222.7z"/>
                            <path fill="#FCAE47" d="M239,227c0.4,4.8-1.9,8.8-5.2,9.1c-3.3,0.3-6.3-3.4-6.7-8.2c-0.4-4.8,1.9-8.8,5.2-9.1
                                C235.6,218.5,238.6,222.2,239,227z"/>
                            <path fill="#FCAE47" d="M264.7,183c0.3,3.2-2.3,6-5.7,6.3c-3.4,0.3-6.4-2.2-6.8-5.4c-0.3-3.2,2.3-6,5.7-6.3
                                C261.5,177.4,264.5,179.8,264.7,183z"/>
                            <path fill="#FCAE47" d="M272,206.7c-1.4,3.3-4.3,5.3-6.5,4.5c-2.2-0.7-2.9-4-1.6-7.2c1.4-3.3,4.3-5.3,6.5-4.5
                                C272.7,200.2,273.4,203.5,272,206.7z"/>
                            <path fill="#FCAE47" d="M258.4,166.8c-1.4,3.3-4.3,5.3-6.5,4.5c-2.2-0.7-2.9-4-1.6-7.2c1.3-3.3,4.3-5.3,6.5-4.5
                                C259.1,160.3,259.8,163.5,258.4,166.8z"/>
                            <path fill="#FCAE47" d="M261.5,247.4c1.6,5.8-4.6,14.2-10.6,15.4c-5.9,1.3-9.4-4.9-10.9-10.8c-1.6-5.8,0.5-14.6,6.5-15.9
                                C252.5,234.9,260,241.5,261.5,247.4z"/>
                        </g>
                        <path fill="#3F7248" d="M370.7,287.9c0,0-19.5-6.4-35.6,0.3C350.8,292.5,370.7,287.9,370.7,287.9z"/>
                        <path fill="#3F7248" d="M167,287.9c0,0-19.5-6.4-35.6,0.3C147,292.5,167,287.9,167,287.9z"/>
                        <g>
                            <path fill="#FCAE47" d="M287.7,316h-75.5c0,0-12.2,27.4-40.1,46.1c12.5,5.6,27.4,9.4,44.6,11.6c3,0.4,6,0.7,9.1,1
                                c7.6,0.6,15.6,1,24.1,1c8.5,0,16.5-0.4,24.1-1c3.3-0.3,6.4-0.6,9.4-1c17.1-2.1,31.8-6,44.3-11.5C299.8,343.4,287.7,316,287.7,316
                                z M216.5,324.6c1.3-1,2.8-1.2,3.4-0.4c0.6,0.8,0.1,2.2-1.2,3.3c-1.3,1.1-2.8,1.2-3.4,0.4C214.6,327,215.2,325.5,216.5,324.6z
                                    M190.4,360.6c-0.9,0.1-1.8-0.7-1.8-1.7c-0.1-1,0.6-1.9,1.6-1.9c0.9-0.1,1.8,0.7,1.8,1.7C192,359.7,191.3,360.6,190.4,360.6z
                                    M199.9,358.8c-0.4,1-1.1,1.6-1.8,1.3c-0.6-0.3-0.8-1.2-0.4-2.2c0.4-1,1.1-1.6,1.8-1.3C200,356.9,200.2,357.8,199.9,358.8z
                                    M199.8,353.2c-2,1.1-4.5,0.6-5.5-1.1c-1.1-1.8,0.3-2.1,1.9-3.9c2.6-2.8,4.4-2.1,5.5-0.3C202.6,349.7,201.7,352.1,199.8,353.2z
                                    M203.8,343.3c-1.4-0.4-2.3-1.6-2-2.9c0.3-1.2,1.1-0.9,2.5-1c2.4-0.2,2.9,0.9,2.6,2.2C206.5,342.8,205.2,343.5,203.8,343.3z
                                    M209.1,351.4c-1.3,0-2.3-1.2-2.3-2.6s1.1-2.6,2.4-2.6s1.1,0.8,1.5,2.2C211.4,350.7,210.5,351.5,209.1,351.4z M211.8,340.7
                                c-0.5,0.3-1.4-0.2-1.9-1.1c-0.5-0.9-0.4-1.9,0.1-2.2c0.5-0.3,1.4,0.2,1.9,1.1C212.4,339.4,212.3,340.4,211.8,340.7z M210.7,332.9
                                c0.2-1.4,1.5-3.3,2.8-3.1c1.3,0.1,2,2.2,1.9,3.6c-0.2,1.4-0.6,3-1.9,2.9C212.2,336.2,210.5,334.4,210.7,332.9z M221.5,341.4
                                c-1.3,2.2-4.1,3.1-6.1,1.9c-2-1.2-0.9-2.3-0.4-4.9c1-4.1,3.2-4.5,5.2-3.4C222.3,336.4,222.8,339.2,221.5,341.4z M222.4,330.8
                                c-0.9,0.1-1.8-0.7-1.8-1.7c-0.1-1,0.6-1.9,1.6-1.9c0.9-0.1,1.8,0.7,1.8,1.7C224,329.9,223.3,330.7,222.4,330.8z M279.9,324.1
                                c0.6-0.8,2.2-0.6,3.4,0.4c1.3,1.1,1.9,2.5,1.2,3.3c-0.6,0.8-2.2,0.6-3.4-0.4C279.8,326.3,279.3,324.9,279.9,324.1z M275.8,329
                                c0.1-1,0.9-1.7,1.8-1.7c1,0.1,1.6,0.9,1.6,1.9c-0.1,1-0.9,1.7-1.8,1.7C276.5,330.7,275.8,329.9,275.8,329z M284.3,343.4
                                c-2,1.2-4.7,0.4-6.1-1.9c-1.3-2.2-0.8-5,1.1-6.3c2-1.2,4.2-0.9,5.2,3.4C285.3,341.1,286.3,342.1,284.3,343.4z M284.3,333.4
                                c-0.2-1.4,0.6-3.4,1.9-3.6c1.3-0.1,2.6,1.7,2.8,3.1c0.2,1.4-1.5,3.3-2.7,3.4C285,336.5,284.5,334.9,284.3,333.4z M288,340.7
                                c-0.5-0.3-0.6-1.3-0.1-2.2s1.3-1.4,1.9-1.1c0.5,0.3,0.6,1.3,0.1,2.2C289.4,340.5,288.6,341,288,340.7z M290.6,351.4
                                c-1.3,0-2.3-0.8-1.6-3.1c0.4-1.4,0.2-2.2,1.5-2.2s2.4,1.1,2.4,2.6C293,350.2,291.9,351.4,290.6,351.4z M296.1,343.3
                                c-1.4,0.4-2.8-0.4-3.1-1.7c-0.3-1.2,0.3-2.4,2.6-2.2c1.4,0.1,2.2-0.3,2.5,1C298.3,341.6,297.5,342.9,296.1,343.3z M301.8,360.1
                                c-0.6,0.3-1.4-0.4-1.8-1.3c-0.4-1-0.3-1.9,0.4-2.2c0.6-0.3,1.4,0.4,1.8,1.3C302.5,358.9,302.3,359.9,301.8,360.1z M305.6,352.1
                                c-1.1,1.8-3.5,2.3-5.5,1.1c-2-1.1-2.8-3.5-1.9-5.4c1.1-1.8,2.9-2.5,5.5,0.3C305.3,349.9,306.6,350.2,305.6,352.1z M309.4,360.6
                                c-1-0.1-1.6-0.9-1.6-1.9c0.1-1,0.9-1.7,1.8-1.7c1,0.1,1.6,0.9,1.6,1.9C311.1,359.9,310.3,360.6,309.4,360.6z"/>
                            <path fill="#ED9935" d="M283.4,373.6c-3.1,0.4-6.2,0.7-9.4,1c-7.6,0.6-15.6,1-24.1,1c-8.5,0-16.5-0.4-24.1-1
                                c-3.1-0.3-6.1-0.6-9.1-1c-5.9-0.7-11.5-1.7-16.8-2.8c-5.2,23.3-8.5,54.7,3.8,75.7c0.3,0.1,0.6,0.2,0.8,0.4
                                c0.6,0.4,0.9,1.2,0.8,2.2c3.8,5.6,8.7,10.2,15.3,13.7c0-0.3-0.1-0.4-0.1-0.7c0.1-2.6,1.9-4.5,4.2-4.5c2.3,0.1,4.1,2.1,4.1,4.7
                                c0,1.4-0.6,2.6-1.5,3.4c6.3,2.1,13.7,3.4,22.4,3.4s16.1-1.2,22.4-3.4c-0.9-0.8-1.5-2-1.5-3.4c-0.1-2.6,1.8-4.6,4.1-4.7
                                c2.3-0.1,4.1,1.9,4.2,4.5c0,0.3,0,0.4-0.1,0.7c6.5-3.4,11.6-8.1,15.3-13.7c-0.1-1,0.2-1.8,0.8-2.2c0.3-0.2,0.5-0.3,0.8-0.4
                                c12.3-20.9,9-52.3,3.8-75.6C294.4,372,289.1,372.9,283.4,373.6z M221,393.2c0.4,2.3-1.1,4.5-3.4,4.9c-2.2,0.4-4.3-1.2-4.7-3.5
                                s1.1-4.5,3.4-4.9C218.5,389.3,220.6,390.9,221,393.2z M210.5,380.2c0.7-2.5,2.5-4.1,3.9-3.7c1.5,0.4,2.1,2.7,1.5,5.2
                                c-0.7,2.5-2.5,4.1-4,3.7C210.5,385,209.8,382.7,210.5,380.2z M212.8,401.6c2.1,4.3,4.5,5.8,1.4,8.8c-3.1,3-8.3,2.6-11.6-0.9
                                c-3.4-3.4-3.5-8.7-0.4-11.6C205.1,394.9,209.3,394.5,212.8,401.6z M199.6,383.5c0.7-2.5,2.5-4.1,3.9-3.7c1.5,0.4,2.1,2.7,1.5,5.2
                                c-0.7,2.5-2.5,4.1-3.9,3.7C199.6,388.2,198.9,385.9,199.6,383.5z M204.3,433.6c-2.1,0.4-4.3-2.2-4.9-5.6
                                c-0.5-3.4,0.7-6.5,2.8-6.9c2.1-0.4,4.2,2.2,4.9,5.6C207.6,430.2,206.4,433.3,204.3,433.6z M217.1,452.2c-3.7,1.2-6.4-3.1-7.8-7.2
                                s-0.5-10.7,3.2-11.9s8.9,3.2,10.3,7.3C224.3,444.5,220.9,450.9,217.1,452.2z M220,426.6c-2.3-0.1-4.1-2.1-4.1-4.7
                                c0.1-2.6,1.9-4.5,4.2-4.5c2.3,0.1,4.1,2.1,4.1,4.7C224.1,424.6,222.3,426.6,220,426.6z M227.1,410.1c-0.7,2.5-2.5,4.1-3.9,3.7
                                c-1.5-0.4-2.1-2.7-1.5-5.2c0.7-2.5,2.5-4.1,3.9-3.7C227.1,405.4,227.8,407.7,227.1,410.1z M297.4,397.8c3.1,3,2.9,8.2-0.4,11.6
                                c-3.4,3.4-8.6,3.9-11.6,0.9c-3.1-3-0.7-4.5,1.4-8.8C290.1,394.5,294.3,394.9,297.4,397.8z M285,376.6c1.5-0.4,3.3,1.2,3.9,3.7
                                c0.7,2.5,0,4.8-1.5,5.2c-1.5,0.4-3.3-1.2-4-3.7C282.8,379.3,283.5,377,285,376.6z M278.4,393.2c0.4-2.3,2.5-3.9,4.7-3.5
                                s3.7,2.6,3.4,4.9c-0.4,2.3-2.5,3.9-4.7,3.5C279.5,397.7,278,395.6,278.4,393.2z M272.2,410.1c-0.7-2.5,0-4.8,1.5-5.2
                                s3.3,1.2,3.9,3.7c0.7,2.5,0,4.8-1.5,5.2C274.7,414.2,272.9,412.5,272.2,410.1z M279.2,417.5c2.3-0.1,4.1,1.9,4.2,4.5
                                c0.1,2.6-1.8,4.6-4.1,4.7c-2.3,0.1-4.1-1.9-4.2-4.5S276.9,417.5,279.2,417.5z M290.1,445c-1.4,4.1-4,8.6-7.8,7.2
                                c-3.7-1.2-7.2-7.7-5.8-11.8c1.4-4.1,6.6-8.6,10.3-7.3C290.6,434.3,291.5,440.9,290.1,445z M299.9,428c-0.5,3.4-2.7,6-4.9,5.6
                                s-3.4-3.4-2.8-6.9c0.5-3.4,2.7-6,4.9-5.6S300.5,424.5,299.9,428z M298.3,388.6c-1.5,0.4-3.3-1.2-3.9-3.7c-0.7-2.5,0-4.8,1.5-5.2
                                c1.5-0.4,3.3,1.2,3.9,3.7C300.5,385.9,299.8,388.2,298.3,388.6z"/>
                        </g>
                        <g opacity="0.4">
                            <path fill="#232323" d="M182.6,517.2c0,0,0.7-8.3,3.2-14.5c-24.9,4.4-57.4,5.6-75.7,7.1c-24.6,2-17.5,10,2.6,9.7
                                c17.3-0.3,49.2,0.2,84,0.3C192.1,519.6,187.3,518.9,182.6,517.2z"/>
                            <path fill="#232323" d="M272.9,473.4c-11.5,1.6-23.3,1.8-23.3,1.8s-11.7-0.2-23.3-1.8c0,0,2.3,33.5-8.8,43.5l0,0l0,0
                                c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.1c-1.9,0.5-8.8,2.4-17.2,2.6c4.6,0,9.3,0,13.9,0c41.1,0,44.9-5.5,53-14.5
                                c2.8-3.2,4.9-7.5,6.6-12.2C272.3,482.8,272.9,473.4,272.9,473.4z"/>
                        </g>
                        
                            <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="299.2708" y1="-53.1177" x2="299.2708" y2="-6.8463" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                            <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#0A0A0A"/>
                        </linearGradient>
                        <path fill="url(#SVGID_40_)" d="M316.7,517.2c0,0-1.3-15.1-6.4-19.9c-1.2-1.1-8.9,0.4-20.2,2.8c0,0-6.8,5.6-8.2,16.9
                            C281.8,517,300.9,523.1,316.7,517.2z"/>
                        
                            <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="200.005" y1="-53.1177" x2="200.005" y2="-6.8463" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                            <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#0A0A0A"/>
                        </linearGradient>
                        <path fill="url(#SVGID_41_)" d="M182.6,517.2c0,0,1.3-15.1,6.4-19.9c1.2-1.1,8.9,0.4,20.2,2.8c0,0,6.8,5.6,8.2,16.9
                            C217.5,517,198.5,523.1,182.6,517.2z"/>
                        <g>
                            <path fill="#223024" d="M187.3,510.2c-0.3-1-5.4-6.4-11.3-4.7c-3.1,0.9-5.8,6.4-7.2,8.3c-1,1.2,2.2,0.3,6.6-3.8
                                c2.3-2,8.2,1.9,9.3,2.6C185.7,513.4,188.1,513.6,187.3,510.2z"/>
                            <path fill="#2D4C32" d="M194.2,515.7c-0.3-1-5.4-6.4-11.3-4.7c-3.1,0.9-5.8,6.4-7.2,8.3c-1,1.2,2.2,0.3,6.6-3.8
                                c2.3-2,8.2,1.9,9.3,2.6C192.6,518.9,195.1,519.1,194.2,515.7z"/>
                        </g>
                        <g>
                            <path fill="#223024" d="M312.6,510.2c0.3-1,5.4-6.4,11.3-4.7c3.1,0.9,5.8,6.4,7.2,8.3c1,1.2-2.2,0.3-6.6-3.8
                                c-2.3-2-8.2,1.9-9.3,2.6C314.1,513.4,311.7,513.6,312.6,510.2z"/>
                            <path fill="#2D4C32" d="M305.6,515.7c0.3-1,5.4-6.4,11.3-4.7c3.1,0.9,5.8,6.4,7.2,8.3c1,1.2-2.2,0.3-6.6-3.8
                                c-2.3-2-8.2,1.9-9.3,2.6C307.2,518.9,304.7,519.1,305.6,515.7z"/>
                        </g>
                        
                            <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="350.7062" y1="205.9573" x2="347.4778" y2="241.5268" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                            <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                            <stop  offset="1" stop-color="#0A0A0A"/>
                        </linearGradient>
                        <path opacity="0.6" fill="url(#SVGID_42_)" enable-background="new    " d="M380,226.9c-0.3-0.2-0.6-0.3-0.9-0.4
                            c-6-2.7-40.4-16.7-64.5,9.6c-7.2,32.7,12.5,51.2,12.5,51.2l57.2-14.8C384.3,272.4,394,245.1,380,226.9z"/>
                        <path fill="#FFFFFF" d="M127.3,283.6c-5-5.2-8-12.4-8-20.1c0-16.1,13.1-29.2,29.2-29.2s29.2,13.1,29.2,29.2
                            c0,7.9-3.1,14.9-8.1,20.1h-0.8c-6.7-1.4-13.7-2.2-20.8-2.2C140.9,281.4,134,282.1,127.3,283.6z"/>
                        <path fill="#514D18" d="M158.3,250.3c-3.6,0-7,1.2-9.5,3.4c3.1,0.2,5.6,2.7,5.6,5.9l0,0c0,3.3-2.6,5.9-5.9,5.9h-5.4
                            c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3C173.6,257.1,166.7,250.3,158.3,250.3z"/>
                        <path fill="#FFFFFF" d="M373,283.6c5-5.2,8-12.4,8-20.1c0-16.1-13.1-29.2-29.2-29.2c-16.1,0-29.2,13.1-29.2,29.2
                            c0,7.9,3.1,14.9,8.1,20.1h0.8c6.7-1.4,13.7-2.2,20.8-2.2C359.4,281.4,366.4,282.1,373,283.6z"/>
                        <path fill="#514D18" d="M346,259.5L346,259.5c0-3.2,2.5-5.7,5.6-5.9c-2.6-2.1-5.9-3.4-9.5-3.4c-8.4,0-15.3,6.8-15.3,15.3
                            c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3H352C348.6,265.4,346,262.8,346,259.5z"/>
                </svg>
  
  
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M379.9,226.9c-0.3-0.2-0.6-0.3-0.9-0.4c-1.2-0.5-3.5-1.6-6.8-2.6c-14.7-27.3-42.2-52.7-76-65.3
                                c-14.5-5.4-36.1-17.8-46.4-17.8c-10.5,0-32,12.4-46.5,17.9c-33.9,12.7-61.3,38-76,65.5c-2.8,1-5,1.9-6.1,2.4
                                c-0.3,0.2-0.6,0.3-0.9,0.4c-12.5,16.3-6.2,39.8-4.7,44.6c0.2,4.9,0.5,9.6,1.1,14.1c0.4,2.9,0.8,5.7,1.3,8.6
                                c2.1,10.9,5.6,20.7,10.5,29.6c9.2,16.6,23.5,29.5,43.5,38.3c7.9,3.4,16.5,6.3,26.1,8.4c-20,2.6-66.3,11.3-57.5,36.3
                                c8.7,24.7,38.9,5.9,51.2-3c-2.2,15.3-3.6,33.2-3.4,53.4c0,0,0.1,22.4,1.4,34.3c0.2,1.9,0.5,3.7,0.9,5.6c-0.8,0-1.3,0.2-1.6,0.4
                                c-1.3,1.2-2.4,3.2-3.3,5.3l0,0c-24.9,4.4-57.3,5.6-75.7,7.1c-24.6,2-17.5,10,2.6,9.7c17.3-0.3,49.1,0.2,83.9,0.3
                                c1.1,0,2.1,0,3.1,0c4.6,0,9.3,0,13.9,0c41.1,0,44.9-5.5,53-14.5c2.8-3.2,4.9-7.5,6.6-12.2c0-0.4-0.1-0.9-0.1-1.3
                                c0.9,9.5,3.1,20.2,8.6,25.1h0.1l0,0c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15.1-6.4-19.8c-0.3-0.3-0.8-0.4-1.6-0.4
                                c0.4-1.9,0.7-3.7,0.9-5.6c1.4-11.9,1.4-34.3,1.4-34.3c0.1-6.8-0.1-13.4-0.4-19.7c5.5,4.6,12.3,6.1,20.6,1.1
                                c25.7-15.3-15.8-59.4-25.4-69.1c7.9-2,15.2-4.4,21.9-7.3c20-8.7,34.3-21.7,43.5-38.3c4.9-8.8,8.4-18.7,10.4-29.6
                                c0.5-2.6,1-5.3,1.2-8c0.4-2.6,0.6-5.2,0.8-7.9c0.2-1.9,0.3-3.9,0.4-5.8l0.3-0.1C384.2,272.4,393.9,245,379.9,226.9z M189.8,518.9
                                L189.8,518.9C189.7,518.9,189.7,518.9,189.8,518.9z M182.6,516.3L182.6,516.3L182.6,516.3z M182.6,517L182.6,517L182.6,517z
                                M187.9,518.6c-0.1,0-0.1,0-0.2,0C187.8,518.6,187.8,518.6,187.9,518.6z M186.4,518.2c-0.2,0-0.4-0.1-0.4-0.1
                                C186,518.1,186.2,518.2,186.4,518.2z M184.7,517.8c-0.2-0.1-0.4-0.1-0.4-0.2C184.3,517.7,184.5,517.7,184.7,517.8z M191.4,519.2
                                c0.1,0,0.2,0,0.3,0C191.5,519.2,191.5,519.2,191.4,519.2z M217.4,516.9c-0.1,0.1-0.3,0.1-0.4,0.1l0,0l0,0
                                C217.1,517,217.3,517,217.4,516.9C217.5,516.9,217.5,516.9,217.4,516.9C217.5,516.9,217.5,516.9,217.4,516.9z M215.4,517.5
                                L215.4,517.5L215.4,517.5z M216,517.4L216,517.4C216.1,517.4,216,517.4,216,517.4z M216.5,517.2L216.5,517.2
                                C216.5,517.2,216.5,517.2,216.5,517.2z M217.5,516.9L217.5,516.9L217.5,516.9L217.5,516.9z M272.9,473.3
                                C272.9,473.3,272.9,473.4,272.9,473.3C272.9,473.4,272.9,473.3,272.9,473.3z M272.9,473.5c0,0.1,0,0.1,0,0.2
                                C272.9,473.6,272.9,473.5,272.9,473.5z M272.9,473.8c0,0.1,0,0.2,0,0.4C272.9,474,272.9,473.9,272.9,473.8z M272.9,474.2
                                c0,0.1,0,0.3,0,0.4C272.9,474.6,272.9,474.4,272.9,474.2z M272.8,474.9c0,0.2,0,0.4,0,0.5C272.8,475.3,272.8,475.1,272.8,474.9z
                                M272.8,475.7c0,0.2,0,0.4,0,0.6C272.8,476.2,272.8,476,272.8,475.7z M272.8,476.7c0,0.2,0,0.4,0,0.7
                                C272.8,477.1,272.8,477,272.8,476.7z M272.8,477.8c0,0.2,0,0.4,0,0.6C272.7,478.2,272.7,478,272.8,477.8z M272.7,479.1
                                c0,0.2,0,0.4,0,0.5C272.7,479.4,272.7,479.2,272.7,479.1z M272.7,480.4c0,0.2,0,0.4,0,0.6C272.7,480.8,272.7,480.7,272.7,480.4z
                                M272.9,487.4c0-0.4,0-0.7-0.1-1.1C272.9,486.7,272.9,487.1,272.9,487.4z M272.8,485.8c0-0.4,0-0.7,0-1.1
                                C272.8,485.1,272.8,485.4,272.8,485.8z M272.8,484.1c0-0.3,0-0.5,0-0.8C272.8,483.6,272.8,483.8,272.8,484.1z M272.7,482.5
                                c0-0.2,0-0.4,0-0.6C272.7,482.1,272.7,482.2,272.7,482.5z M273.1,489.9c0,0.4,0.1,0.8,0.1,1.2
                                C273.2,490.7,273.1,490.3,273.1,489.9z M273,488.2c0,0.4,0.1,0.8,0.1,1.1C273,488.9,273,488.5,273,488.2z M300.9,370.7
                                c0-0.1,0-0.1,0-0.2c0.1,0,0.2,0,0.3-0.1L300.9,370.7z"/>
                            <path fill="#FCAE47" d="M287.6,316h-75.5c0,0-12.2,27.4-40,46.1c12.5,5.6,27.3,9.4,44.5,11.6c3,0.4,6,0.7,9.1,1
                                c7.6,0.6,15.6,1,24.1,1c8.5,0,16.5-0.4,24.1-1c3.3-0.3,6.4-0.6,9.4-1c17.1-2.1,31.8-6,44.3-11.5C299.8,343.4,287.6,316,287.6,316z
                                M216.4,324.5c1.3-1,2.8-1.2,3.4-0.4c0.6,0.8,0.1,2.2-1.2,3.3s-2.8,1.2-3.4,0.4C214.6,327,215.2,325.5,216.4,324.5z M190.4,360.5
                                c-0.9,0.1-1.8-0.7-1.8-1.7c-0.1-1,0.6-1.9,1.6-1.9c0.9-0.1,1.8,0.7,1.8,1.7C192,359.6,191.3,360.5,190.4,360.5z M199.8,358.7
                                c-0.4,1-1.1,1.6-1.8,1.3c-0.6-0.3-0.8-1.2-0.4-2.2c0.4-1,1.1-1.6,1.8-1.3C200,356.8,200.2,357.8,199.8,358.7z M199.8,353.2
                                c-2,1.1-4.5,0.6-5.5-1.1c-1.1-1.8,0.3-2.1,1.9-3.9c2.6-2.8,4.4-2.1,5.5-0.3C202.6,349.7,201.7,352,199.8,353.2z M203.7,343.2
                                c-1.4-0.4-2.3-1.6-2-2.9c0.3-1.2,1.1-0.9,2.5-1c2.4-0.2,2.9,0.9,2.6,2.2C206.5,342.8,205.1,343.5,203.7,343.2z M209.1,351.3
                                c-1.3,0-2.3-1.2-2.3-2.6c0-1.4,1.1-2.6,2.4-2.6s1.1,0.8,1.5,2.2C211.4,350.6,210.4,351.4,209.1,351.3z M211.8,340.7
                                c-0.5,0.3-1.4-0.2-1.9-1.1c-0.5-0.9-0.4-1.9,0.1-2.2c0.5-0.3,1.4,0.2,1.9,1.1C212.4,339.3,212.3,340.3,211.8,340.7z M210.7,332.9
                                c0.2-1.4,1.5-3.3,2.8-3.1c1.3,0.1,2,2.2,1.9,3.6c-0.2,1.4-0.6,3-1.9,2.9C212.2,336.2,210.5,334.3,210.7,332.9z M221.5,341.4
                                c-1.3,2.2-4.1,3.1-6.1,1.9c-2-1.2-0.9-2.3-0.4-4.9c1-4.1,3.2-4.5,5.2-3.4C222.3,336.3,222.8,339.2,221.5,341.4z M222.3,330.8
                                c-0.9,0.1-1.8-0.7-1.8-1.7c-0.1-1,0.6-1.9,1.6-1.9c0.9-0.1,1.8,0.7,1.8,1.7C223.9,329.9,223.2,330.7,222.3,330.8z M279.9,324.1
                                c0.6-0.8,2.2-0.6,3.4,0.4c1.3,1.1,1.9,2.5,1.2,3.3c-0.6,0.8-2.2,0.6-3.4-0.4C279.8,326.3,279.2,324.9,279.9,324.1z M275.8,328.9
                                c0.1-1,0.9-1.7,1.8-1.7c1,0.1,1.6,0.9,1.6,1.9c-0.1,1-0.9,1.7-1.8,1.7C276.4,330.7,275.8,329.9,275.8,328.9z M284.3,343.3
                                c-2,1.2-4.7,0.4-6.1-1.9c-1.3-2.2-0.8-5,1.1-6.3c2-1.2,4.2-0.9,5.2,3.4C285.2,341,286.3,342.1,284.3,343.3z M284.3,333.3
                                c-0.2-1.4,0.6-3.4,1.9-3.6c1.3-0.1,2.6,1.7,2.8,3.1c0.2,1.4-1.5,3.3-2.7,3.4C285,336.4,284.4,334.8,284.3,333.3z M288,340.7
                                c-0.5-0.3-0.6-1.3-0.1-2.2c0.5-0.9,1.3-1.4,1.9-1.1c0.5,0.3,0.6,1.3,0.1,2.2C289.4,340.5,288.5,340.9,288,340.7z M290.5,351.3
                                c-1.3,0-2.3-0.8-1.6-3.1c0.4-1.4,0.2-2.2,1.5-2.2c1.3,0,2.4,1.1,2.4,2.6C292.9,350.2,291.9,351.3,290.5,351.3z M296,343.2
                                c-1.4,0.4-2.8-0.4-3.1-1.7c-0.3-1.2,0.3-2.4,2.6-2.2c1.4,0.1,2.2-0.3,2.5,1C298.3,341.5,297.4,342.9,296,343.2z M301.7,360.1
                                c-0.6,0.3-1.4-0.4-1.8-1.3c-0.4-1-0.3-1.9,0.4-2.2c0.6-0.3,1.4,0.4,1.8,1.3C302.4,358.8,302.3,359.8,301.7,360.1z M305.5,352
                                c-1.1,1.8-3.5,2.3-5.5,1.1c-2-1.1-2.8-3.5-1.9-5.4c1.1-1.8,2.9-2.5,5.5,0.3C305.3,349.8,306.5,350.2,305.5,352z M309.3,360.5
                                c-1-0.1-1.6-0.9-1.6-1.9c0.1-1,0.9-1.7,1.8-1.7c1,0.1,1.6,0.9,1.6,1.9C311.1,359.9,310.3,360.6,309.3,360.5z"/>
                            
                                <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="299.2215" y1="-53.0306" x2="299.2215" y2="-6.77" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#0A0A0A"/>
                            </linearGradient>
                            <path fill="url(#SVGID_35_)" d="M316.6,517.1c0,0-1.3-15.1-6.4-19.8c-1.2-1.1-8.9,0.4-20.2,2.8c0,0-6.8,5.6-8.2,16.8
                                C281.8,516.9,300.9,523,316.6,517.1z"/>
                            
                                <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="199.9796" y1="-53.0306" x2="199.9796" y2="-6.77" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#0A0A0A"/>
                            </linearGradient>
                            <path fill="url(#SVGID_36_)" d="M182.6,517.1c0,0,1.3-15.1,6.4-19.8c1.2-1.1,8.9,0.4,20.2,2.8c0,0,6.8,5.6,8.2,16.8
                                C217.5,516.9,198.4,523,182.6,517.1z"/>
                            <g>
                                <path fill="#223024" d="M187.2,510.1c-0.3-1-5.4-6.4-11.3-4.7c-3.1,0.9-5.8,6.4-7.2,8.3c-1,1.2,2.2,0.3,6.6-3.8
                                    c2.3-2,8.2,1.9,9.3,2.6C185.6,513.3,188.1,513.5,187.2,510.1z"/>
                                <path fill="#2D4C32" d="M194.2,515.6c-0.3-1-5.4-6.4-11.3-4.7c-3.1,0.9-5.8,6.4-7.2,8.3c-1,1.2,2.2,0.3,6.6-3.8
                                    c2.3-2,8.2,1.9,9.3,2.6S195.1,519,194.2,515.6z"/>
                            </g>
                            <g>
                                <path fill="#223024" d="M312.5,510.1c0.3-1,5.4-6.4,11.3-4.7c3.1,0.9,5.8,6.4,7.2,8.3c1,1.2-2.2,0.3-6.6-3.8
                                    c-2.3-2-8.2,1.9-9.3,2.6C314.1,513.3,311.6,513.5,312.5,510.1z"/>
                                <path fill="#2D4C32" d="M305.5,515.6c0.3-1,5.4-6.4,11.3-4.7c3.1,0.9,5.8,6.4,7.2,8.3c1,1.2-2.2,0.3-6.6-3.8
                                    c-2.3-2-8.2,1.9-9.3,2.6S304.6,519,305.5,515.6z"/>
                            </g>
                            
                                <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="150.6859" y1="203.7549" x2="147.2423" y2="241.6949" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#0A0A0A"/>
                            </linearGradient>
                            <path opacity="0.6" fill="url(#SVGID_37_)" enable-background="new    " d="M121.2,226.4c-0.3,0.2-0.6,0.3-0.9,0.4
                                c-14,18.3-4.3,45.6-4.3,45.6l57.2,14.8c0,0,19.8-18.4,12.5-51.2C161.6,209.7,127.2,223.7,121.2,226.4z"/>
                            <path fill="#5BA564" d="M379.9,226.9c0,0-38.9-19.7-65.4,9.3c-10.1-8.4-9.5-8.2-9.5-8.2S344.3,200,379.9,226.9z"/>
                            <path fill="#5BA564" d="M120.4,226.9c0,0,38.9-19.7,65.4,9.3c10.1-8.4,9.5-8.2,9.5-8.2S155.9,200,120.4,226.9z"/>
                            <path fill="#2D4C32" d="M348,297.7c-1.4-0.6-2.9-1.1-4.5-1.3c-1.6-0.2-3.4,0-4.6,0.8c-0.5,0.4-1.9,1.3-3.5,2.6
                                c-0.4,0.2-0.7,0.4-1.1,0.5c-4.2,2-7.5,3.6-12,3.7c-5,0.1-10.1,0-15,0c-2.8,0-5.6,0-8.4,0H250h-0.8h-48.9c-2.8,0-5.6,0-8.4,0
                                c-4.9,0-10.1,0.1-15,0c-4.5-0.1-7.8-1.7-11.9-3.6c-1.8-1.5-3.6-2.8-4.1-3.2c-1.2-0.8-3-1-4.6-0.8c-1.6,0.2-3.1,0.7-4.5,1.3
                                c-1.3,0.6-2.7,1.4-2.8,2.6c-0.1,0.7,0.4,1.5,1.1,2c1.7,1.5,4.3,2.2,6.9,2.6c0.8,0.1,3.2,0.5,5.6,0.8c4.1,1.9,8.5,3.8,14.5,3.9
                                c2.3,0,4.6,0,6.9,0c2.8,0,5.6,0,8.4,0c2.7,0,5.6,0,8.3,0h48.9h0.8h48.9c2.7,0,5.6,0,8.3,0s5.6,0,8.4,0c2.3,0,4.6,0,6.9,0
                                c5.8-0.1,10.2-1.9,14.3-3.8c2.6-0.4,5.3-0.8,6.3-0.9c2.6-0.4,5.2-1.1,6.9-2.6c0.6-0.6,1.1-1.3,1.1-2
                                C350.7,299.1,349.3,298.3,348,297.7z"/>
                            <g>
                                <path fill="#2D4C32" d="M235,299.9l-3.6-2.1c-0.8-0.4-1.1-1.5-0.6-2.3l0,0c0.4-0.8,1.5-1.1,2.3-0.6l3.6,2.1
                                    c0.8,0.4,1.1,1.5,0.6,2.3l0,0C236.8,300.1,235.8,300.3,235,299.9z"/>
                                <path fill="#2D4C32" d="M264.8,299.9l3.6-2c0.8-0.4,1.1-1.5,0.6-2.3l0,0c-0.4-0.8-1.5-1.1-2.3-0.6l-3.6,2
                                    c-0.8,0.4-1.1,1.5-0.6,2.3l0,0C262.9,300.1,264,300.3,264.8,299.9z"/>
                            </g>
                            <g>
                                <path fill="#FCAE47" d="M234.2,203c-4.7-5.1-4.3-12.3,0.8-16c5.1-3.7,11.6-3.6,16,6.4c2.6,6.2,6.2,8.5,1,12.1
                                    C246.8,209.2,238.8,208.1,234.2,203z"/>
                                <path fill="#FCAE47" d="M265.9,222.7c-0.4,3.4-3.6,5.9-7.1,5.6c-3.5-0.4-6-3.4-5.6-6.8c0.4-3.4,3.6-5.9,7.1-5.6
                                    C263.8,216.2,266.4,219.3,265.9,222.7z"/>
                                <path fill="#FCAE47" d="M239,226.9c0.4,4.8-1.9,8.8-5.2,9.1s-6.3-3.4-6.7-8.2c-0.4-4.8,1.9-8.8,5.2-9.1S238.6,222.2,239,226.9z"
                                    />
                                <path fill="#FCAE47" d="M264.7,183c0.3,3.2-2.3,6-5.7,6.3c-3.4,0.3-6.4-2.2-6.8-5.4c-0.3-3.2,2.3-6,5.7-6.3
                                    C261.4,177.4,264.4,179.8,264.7,183z"/>
                                <path fill="#FCAE47" d="M272,206.7c-1.4,3.3-4.3,5.3-6.5,4.5c-2.2-0.7-2.9-4-1.6-7.2c1.4-3.3,4.3-5.3,6.5-4.5
                                    C272.6,200.2,273.3,203.5,272,206.7z"/>
                                <path fill="#FCAE47" d="M258.3,166.8c-1.4,3.3-4.3,5.3-6.5,4.5c-2.2-0.7-2.9-4-1.6-7.2c1.3-3.3,4.3-5.3,6.5-4.5
                                    C259,160.3,259.7,163.5,258.3,166.8z"/>
                                <path fill="#FCAE47" d="M261.5,247.3c1.6,5.8-4.6,14.2-10.6,15.4c-5.9,1.3-9.4-4.9-10.9-10.8c-1.6-5.8,0.5-14.6,6.5-15.9
                                    C252.5,234.9,259.9,241.5,261.5,247.3z"/>
                            </g>
                            <path fill="#3F7248" d="M370.6,287.8c0,0-19.5-6.4-35.6,0.3C350.7,292.5,370.6,287.8,370.6,287.8z"/>
                            <path fill="#FFFFFF" d="M127.2,283.6c-5-5.2-8-12.4-8-20.1c0-16.1,13.1-29.2,29.2-29.2s29.2,13.1,29.2,29.2
                                c0,7.9-3.1,14.9-8.1,20.1h-0.8c-6.7-1.4-13.7-2.2-20.8-2.2C140.9,281.4,134,282.1,127.2,283.6z"/>
                            <path fill="#514D18" d="M158.3,250.2c-3.6,0-7,1.2-9.5,3.4c3.1,0.2,5.6,2.7,5.6,5.9l0,0c0,3.3-2.6,5.9-5.9,5.9h-5.4
                                c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3C173.6,257,166.7,250.2,158.3,250.2z"/>
                            
                                <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="350.6446" y1="205.9839" x2="347.4168" y2="241.5451" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="3.196674e-08" stop-color="#545454" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#0A0A0A"/>
                            </linearGradient>
                            <path opacity="0.6" fill="url(#SVGID_38_)" enable-background="new    " d="M379.9,226.9c-0.3-0.2-0.6-0.3-0.9-0.4
                                c-6-2.7-40.4-16.7-64.5,9.6c-7.2,32.7,12.5,51.2,12.5,51.2l57.2-14.8C384.2,272.4,393.9,245,379.9,226.9z"/>
                            <path fill="#FFFFFF" d="M372.9,283.6c5-5.2,8-12.4,8-20.1c0-16.1-13.1-29.2-29.2-29.2s-29.2,13.1-29.2,29.2
                                c0,7.9,3.1,14.9,8.1,20.1h0.8c6.7-1.4,13.7-2.2,20.8-2.2C359.3,281.4,366.3,282.1,372.9,283.6z"/>
                            <path fill="#514D18" d="M345.9,259.5L345.9,259.5c0-3.2,2.5-5.7,5.6-5.9c-2.6-2.1-5.9-3.4-9.5-3.4c-8.4,0-15.3,6.8-15.3,15.3
                                c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3h-5.4C348.6,265.4,345.9,262.8,345.9,259.5z"/>
                            <path fill="#ED9935" d="M283.4,373.6c-3.1,0.4-6.2,0.7-9.4,1c-7.6,0.6-15.6,1-24.1,1c-8.5,0-16.5-0.4-24.1-1
                                c-3.1-0.3-6.1-0.6-9.1-1c-5.9-0.7-11.5-1.7-16.8-2.8c-5.2,23.3-8.5,54.7,3.8,75.7c0.3,0.1,0.6,0.2,0.8,0.4
                                c0.6,0.4,0.9,1.2,0.8,2.2c3.8,5.6,8.7,10.2,15.3,13.7c0-0.3-0.1-0.4-0.1-0.7c0.1-2.6,1.9-4.5,4.2-4.5c2.3,0.1,4.1,2.1,4.1,4.7
                                c0,1.4-0.6,2.6-1.5,3.4c6.3,2.1,13.7,3.4,22.4,3.4c8.7,0,16.1-1.2,22.4-3.4c-0.9-0.8-1.5-2-1.5-3.4c-0.1-2.6,1.8-4.6,4.1-4.7
                                c2.3-0.1,4.1,1.9,4.2,4.5c0,0.3,0,0.4-0.1,0.7c6.5-3.4,11.6-8.1,15.3-13.7c-0.1-1,0.2-1.8,0.8-2.2c0.3-0.2,0.5-0.3,0.8-0.4
                                c12.3-20.9,9-52.3,3.8-75.6C294.3,372,289,372.9,283.4,373.6z M220.9,393.1c0.4,2.3-1.1,4.5-3.4,4.9c-2.2,0.4-4.3-1.2-4.7-3.5
                                c-0.4-2.3,1.1-4.5,3.4-4.9C218.5,389.3,220.6,390.9,220.9,393.1z M210.4,380.2c0.7-2.5,2.5-4.1,3.9-3.7c1.5,0.4,2.1,2.7,1.5,5.2
                                c-0.7,2.5-2.5,4.1-4,3.7C210.4,384.9,209.7,382.6,210.4,380.2z M212.7,401.5c2.1,4.3,4.5,5.8,1.4,8.8c-3.1,3-8.3,2.6-11.6-0.9
                                c-3.4-3.4-3.5-8.7-0.4-11.6C205.1,394.8,209.3,394.5,212.7,401.5z M199.6,383.4c0.7-2.5,2.5-4.1,3.9-3.7c1.5,0.4,2.1,2.7,1.5,5.2
                                c-0.7,2.5-2.5,4.1-3.9,3.7C199.6,388.1,198.9,385.8,199.6,383.4z M204.3,433.5c-2.1,0.4-4.3-2.2-4.9-5.6c-0.5-3.4,0.7-6.5,2.8-6.9
                                c2.1-0.4,4.2,2.2,4.9,5.6C207.6,430.1,206.4,433.2,204.3,433.5z M217,452.2c-3.7,1.2-6.4-3.1-7.8-7.2c-1.4-4.1-0.5-10.7,3.2-11.9
                                c3.7-1.2,8.9,3.2,10.3,7.3C224.3,444.4,220.8,450.8,217,452.2z M220,426.5c-2.3-0.1-4.1-2.1-4.1-4.7c0.1-2.6,1.9-4.5,4.2-4.5
                                c2.3,0.1,4.1,2.1,4.1,4.7C224.1,424.6,222.3,426.6,220,426.5z M227.1,410.1c-0.7,2.5-2.5,4.1-3.9,3.7c-1.5-0.4-2.1-2.7-1.5-5.2
                                c0.7-2.5,2.5-4.1,3.9-3.7C227.1,405.3,227.8,407.6,227.1,410.1z M297.3,397.7c3.1,3,2.9,8.2-0.4,11.6s-8.6,3.9-11.6,0.9
                                c-3.1-3-0.7-4.5,1.4-8.8C290,394.5,294.2,394.8,297.3,397.7z M285,376.6c1.5-0.4,3.3,1.2,3.9,3.7c0.7,2.5,0,4.8-1.5,5.2
                                s-3.3-1.2-4-3.7C282.8,379.2,283.5,376.9,285,376.6z M278.4,393.1c0.4-2.3,2.5-3.9,4.7-3.5c2.2,0.4,3.7,2.6,3.4,4.9
                                c-0.4,2.3-2.5,3.9-4.7,3.5C279.5,397.6,278,395.5,278.4,393.1z M272.2,410.1c-0.7-2.5,0-4.8,1.5-5.2c1.5-0.4,3.3,1.2,3.9,3.7
                                c0.7,2.5,0,4.8-1.5,5.2C274.7,414.1,272.9,412.5,272.2,410.1z M279.2,417.4c2.3-0.1,4.1,1.9,4.2,4.5c0.1,2.6-1.8,4.6-4.1,4.7
                                c-2.3,0.1-4.1-1.9-4.2-4.5C275,419.5,276.9,417.4,279.2,417.4z M290,444.9c-1.4,4.1-4,8.6-7.8,7.2c-3.7-1.2-7.2-7.7-5.8-11.8
                                c1.4-4.1,6.6-8.6,10.3-7.3C290.5,434.3,291.4,440.8,290,444.9z M299.9,427.9c-0.5,3.4-2.7,6-4.9,5.6s-3.4-3.4-2.8-6.9
                                c0.5-3.4,2.7-6,4.9-5.6C299.2,421.4,300.4,424.5,299.9,427.9z M298.2,388.6c-1.5,0.4-3.3-1.2-3.9-3.7c-0.7-2.5,0-4.8,1.5-5.2
                                s3.3,1.2,3.9,3.7C300.4,385.8,299.7,388.1,298.2,388.6z"/>
                            <g opacity="0.4">
                                <path fill="#232323" d="M182.6,517.1c0,0,0.7-8.3,3.2-14.5c-24.9,4.4-57.3,5.6-75.7,7.1c-24.6,2-17.5,10,2.6,9.7
                                    c17.3-0.3,49.2,0.2,84,0.3C192.1,519.5,187.2,518.8,182.6,517.1z"/>
                                <path fill="#232323" d="M272.9,473.3c-11.5,1.6-23.3,1.8-23.3,1.8s-11.7-0.2-23.3-1.8c0,0,2.3,33.5-8.8,43.5l0,0l0,0
                                    c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.1c-1.9,0.5-8.8,2.4-17.2,2.6c4.6,0,9.3,0,13.9,0c41.1,0,44.9-5.5,53-14.5
                                    c2.8-3.2,4.9-7.5,6.6-12.2C272.3,482.7,272.9,473.3,272.9,473.3z"/>
                            </g>
                    </svg>
 
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M352.6,175.7l-0.7-0.4L352.6,175.7c-3.5-2.6-7.2-4.8-11.3-6.5c-14.4-19.8-34.5-34.7-57.6-42.7
                                c-1.4-4.1-21.5-19.3-34.2-19.3c-11.3,0-32.8,15.2-34.2,19.3c-23.1,8-43.2,22.8-57.5,42.6c-4.1,1.7-7.9,3.8-11.4,6.5
                                c0,0,0.3-0.1,0.7-0.3c-11.7,13.1-5.2,28.2-6,39.6c-0.3,52.9,30,78.8,76.6,85.8l-2,6.9c-15.8,6.8-34.3,4.7-42.1,25.2
                                c-4.9,34.7-18.5,71.9-16.7,90.3c0.5,11.6,17,22.6,30.2,3c0.9,16.9,1.8,52,4.1,70.8c-4.5-0.9-5.3,1.8-7.1,5.7
                                c-24.7,5.6-96.7,4.3-88.9,13c1.3,2,11,3.9,18.6,3.5c7.7-0.1,17.9-0.1,30.2,0c30.6-0.2,47.2,1.2,87.1-0.1
                                c28.4-1.3,34.4-8.7,41.9-22.6c2.1,8.4,5.3,16.4,10.1,20.5c0,0,0,0,0,0.1c7.5,5.4,29,2.4,35.1,0.2c-0.2,0.2-1.4-21.7-9.6-20.3
                                c2.4-18.9,3.3-53.2,4.2-70.9c6.3,9,13.8,13.7,22.6,9.2c19.4-10-2.3-51.3-7.5-95.5c-2.5-21.6-22.6-26.9-33.3-28.2l-10.4-3.9
                                l-1.8-7.1c53.6-8.2,77.7-41,75.5-90.4C358.6,201.2,361.6,187.6,352.6,175.7z"/>
                            <path opacity="0.5" fill="#2B2B2B" enable-background="new    " d="M328.5,233.5c-1.1-0.6-2.4-0.9-3.6-1.1c-1.3-0.2-2.6,0-3.7,0.7
                                c-1,0.7-1.9,1.4-2.8,2.1l-0.8,0.4c-3.4,1.6-6,2.9-9.7,3c-4,0-8.1,0-12.1,0H289h-79.4h-6.7c-4,0-8.1,0-12.1,0
                                c-3.6,0-6.3-1.3-9.6-2.9c-1.1-0.9-2.2-1.8-3.4-2.5c-1.1-0.6-2.4-0.8-3.7-0.7c-1.3,0.2-2.5,0.5-3.6,1.1c-1.1,0.5-2.2,1.2-2.3,2.1
                                c0,0.6,0.3,1.3,0.8,1.6c1.3,1.2,3.5,1.8,5.5,2c0.7,0.1,2.5,0.4,4.5,0.7c3.3,1.6,6.9,3,11.7,3.1c1.8,0,3.7,0,5.5,0h6.7h6.7H289h6.7
                                h6.7c1.8,0,3.7,0,5.5,0c4-0.1,8-1.2,11.5-3c2.1-0.3,4.3-0.7,5-0.7c2.1-0.3,4.2-0.8,5.5-2c0.5-0.4,0.8-1,0.8-1.6
                                C330.7,234.6,329.6,234,328.5,233.5z"/>
                            <path fill="#FFFFFF" d="M350.4,227.3c10.8-11.4,10.4-29.4-1-40.2c-11.4-10.8-29.4-10.4-40.2,1c-10.5,11-10.5,28.3,0,39.3h0.8
                                C323.3,224.5,337.1,224.5,350.4,227.3z"/>
                            <path fill="#FFFFFF" d="M148.6,227.3c-10.8-11.4-10.4-29.4,1-40.2c11.4-10.8,29.4-10.4,40.2,1c10.4,11,10.4,28.2,0,39.2h-0.8
                                C175.7,224.5,162,224.5,148.6,227.3L148.6,227.3z"/>
                            <path fill="#FCAE47" d="M237.4,162.1c-3-3.7-2.8-9,0.5-11.7c3.3-2.7,7.5-2.6,10.4,4.7c1.7,4.5,4,6.2,0.6,8.9
                                S240.5,165.9,237.4,162.1z"/>
                            <path fill="#FCAE47" d="M258,176.6c-0.5,2.2-2.7,3.7-4.9,3.2c-2.2-0.5-3.7-2.7-3.2-4.9c0.4-2.1,2.4-3.5,4.5-3.3
                                C256.8,172,258.4,174.2,258,176.6z"/>
                            <path fill="#FCAE47" d="M240.6,179.7c0.3,3.5-1.2,6.5-3.4,6.6c-2.1,0.2-4.1-2.5-4.3-6c-0.3-3.5,1.2-6.5,3.4-6.6
                                C238.4,173.5,240.3,176.2,240.6,179.7z"/>
                            <path fill="#FCAE47" d="M257.2,147.5c0.1,2.2-1.7,4.1-4,4.2c-2.2,0.1-4.1-1.7-4.2-4c-0.1-2.2,1.6-4,3.7-4.2
                                C255.2,143.5,257.1,145.2,257.2,147.5z"/>
                            <path fill="#FCAE47" d="M262,164.9c-0.9,2.4-2.8,3.9-4.2,3.3c-1.4-0.5-1.9-2.9-1-5.3c0.9-2.4,2.8-3.9,4.2-3.3
                                C262.4,160.1,262.8,162.5,262,164.9z"/>
                            <path fill="#FCAE47" d="M253.1,135.6c-0.9,2.4-2.8,3.9-4.2,3.3c-1.5-0.5-1.9-2.9-1-5.3s2.8-3.9,4.2-3.3
                                C253.5,130.9,254,133.2,253.1,135.6z"/>
                            <path fill="#FCAE47" d="M255.2,194.6c1,4.3-3,10.4-6.8,11.3s-6-3.6-7.1-7.9c-1-4.3,0.4-10.7,4.2-11.7
                                C249.3,185.4,254.1,190.4,255.2,194.6z"/>
                            <g opacity="0.5">
                                <path fill="#2B2B2B" d="M234.6,233.5l-3.6-2.1c-0.8-0.5-1.1-1.5-0.6-2.3l0,0c0.5-0.8,1.5-1.1,2.3-0.6l3.6,2.1
                                    c0.8,0.5,1.1,1.5,0.6,2.3l0,0C236.4,233.7,235.4,234,234.6,233.5C234.6,233.5,234.6,233.5,234.6,233.5z"/>
                                <path fill="#2B2B2B" d="M264.4,233.5l3.6-2.1c0.8-0.5,1.1-1.5,0.6-2.3c0,0,0,0,0,0l0,0c-0.5-0.8-1.5-1.1-2.3-0.6l-3.6,2.1
                                    c-0.8,0.5-1.1,1.5-0.6,2.3l0,0C262.6,233.7,263.6,234,264.4,233.5z"/>
                            </g>
                            <path fill="none" d="M352.6,175.7c0,0-0.3-0.1-0.8-0.4L352.6,175.7z"/>
                            <path fill="none" d="M146.4,175.7l0.8-0.4L146.4,175.7z"/>
                            
                                <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="-8236.6426" y1="262.3235" x2="-8239.4492" y2="293.3059" gradientTransform="matrix(-1 0 0 -1 -8065.3901 461.88)">
                                <stop  offset="0" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.6"/>
                            </linearGradient>
                            <path fill="url(#SVGID_47_)" d="M147.2,175.3l-0.8,0.4c-11.8,15.4-3.7,38.5-3.7,38.5l48.3,12.6c0,0,16.7-15.6,10.6-43.3
                                C181.3,161.3,152.2,173,147.2,175.3z"/>
                            <path opacity="0.3" fill="#DBDBDB" enable-background="new    " d="M289.2,176.6c0,0-0.5-0.1,8.1,7l0,0
                                c20.4-22.2,49.5-10.5,54.5-8.2c0.5,0.2,0.8,0.4,0.8,0.4C322.5,152.9,289.2,176.6,289.2,176.6z"/>
                            <path opacity="0.3" fill="#DBDBDB" enable-background="new    " d="M209.8,176.6c0,0-33.3-23.6-63.4-0.9l0.8-0.4
                                c5-2.3,34.1-14.1,54.5,8.2l0,0C210.3,176.4,209.8,176.6,209.8,176.6z"/>
                            
                                <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="327.7704" y1="262.3237" x2="324.9644" y2="293.3061" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.6"/>
                            </linearGradient>
                            <path fill="url(#SVGID_48_)" d="M352.6,175.7l-0.8-0.4c-5-2.3-34.1-14.1-54.5,8.2c-6.1,27.6,10.6,43.3,10.6,43.3l48.3-12.6
                                C356.3,214.2,364.4,191.1,352.6,175.7z"/>
                            <path fill="#FFFFFF" d="M329.8,179.3c-15.7,0-28.5,12.7-28.5,28.4c0,7.3,2.8,14.4,7.9,19.7h0.8c13.3-2.9,27.1-2.9,40.4,0
                                c10.8-11.4,10.4-29.4-1-40.2C344.1,182.1,337.1,179.3,329.8,179.3L329.8,179.3z"/>
                            <path fill="#FFFFFF" d="M169.2,179.3c-15.7,0-28.5,12.7-28.5,28.4c0,7.3,2.8,14.4,7.8,19.6c13.3-2.9,27.1-2.9,40.4,0h0.8
                                c10.8-11.4,10.4-29.4-1-40.2C183.6,182.1,176.5,179.3,169.2,179.3L169.2,179.3z"/>
                            <path fill="#FCAE47" d="M280.8,252.5h-62.7c0,0-10.3,23.1-33.7,38.5c4.2,2,8.6,3.7,13,5.1c8.2,2.5,16.7,4.3,25.3,5.3l2.7,0.3H225
                                c1.8,0.2,3.6,0.4,5.4,0.5c6.2,0.5,12.7,0.8,19.6,0.8c6.9,0,13.4-0.3,19.6-0.8c1.8-0.2,3.6-0.3,5.4-0.5l-1.5,0
                                c1.1-0.1,2.2-0.2,3.3-0.4c8.5-1,16.9-2.8,25-5.3c4.4-1.4,8.8-3,13-5C291.1,275.7,280.8,252.5,280.8,252.5z M198.1,289.4
                                c-0.8,0.1-1.6-0.5-1.7-1.3s0.5-1.6,1.3-1.7c0.8-0.1,1.6,0.5,1.7,1.3c0,0,0,0.1,0,0.1C199.5,288.6,198.9,289.3,198.1,289.4z
                                M220.6,258.2c1.1-0.9,2.4-1,3-0.4c0.5,0.7,0.1,1.9-1,2.8c-1.1,0.9-2.4,1-3,0.4C219,260.3,219.5,259,220.6,258.2L220.6,258.2z
                                M206.2,287.8c-0.3,0.8-1,1.4-1.5,1.2s-0.7-1.1-0.3-1.9c0.3-0.8,1-1.4,1.6-1.2S206.5,286.9,206.2,287.8L206.2,287.8z M206.2,283
                                c-1.6,1-3.7,0.6-4.8-1c-0.9-1.6,0.2-1.9,1.6-3.4c2.2-2.4,3.8-1.8,4.7-0.3C208.5,280.1,207.8,282.1,206.2,283L206.2,283z
                                M209.6,274.4c-1.2-0.2-2-1.3-1.8-2.5c0.3-1.1,0.9-0.8,2.2-0.9c2-0.2,2.5,0.8,2.3,1.9C211.9,274,210.7,274.7,209.6,274.4
                                C209.6,274.3,209.6,274.3,209.6,274.4L209.6,274.4z M214.3,281.4c-1.3-0.2-2.1-1.3-2-2.5c0.1-1.1,1-1.9,2.1-2
                                c1.1,0,0.9,0.7,1.3,1.9C216.2,280.7,215.4,281.4,214.3,281.4L214.3,281.4z M216.6,272.2c-0.5,0.3-1.2-0.2-1.7-1s-0.4-1.7,0.1-1.9
                                s1.2,0.2,1.7,1C217.1,271.1,217,271.9,216.6,272.2L216.6,272.2z M215.7,265.5c0.1-1.2,1.3-2.8,2.4-2.7c1.1,0.1,1.8,1.9,1.7,3.1
                                c-0.1,1.2-0.6,2.6-1.7,2.5C216.7,268.1,215.7,266.9,215.7,265.5L215.7,265.5z M225,272.8c-1,1.9-3.3,2.6-5.2,1.6
                                c-1.7-1-0.8-2-0.3-4.2c0.8-3.6,2.8-3.9,4.5-2.9C225.7,268.6,226.1,271,225,272.8L225,272.8z M225.7,263.6
                                c-0.8,0.1-1.6-0.5-1.7-1.3s0.5-1.6,1.3-1.7c0.8-0.1,1.6,0.5,1.7,1.3c0,0,0,0.1,0,0.1C227.1,262.8,226.5,263.5,225.7,263.6
                                L225.7,263.6z M275.4,257.8c0.5-0.7,1.9-0.5,3,0.4s1.6,2.1,1,2.8c-0.5,0.7-1.9,0.5-3-0.4C275.4,259.8,274.8,258.5,275.4,257.8
                                L275.4,257.8z M272,262c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.4,0.7,1.4,1.5c0,0,0,0.1,0,0.1c0,0.8-0.7,1.4-1.5,1.4
                                c-0.8,0-1.4-0.7-1.4-1.5C272,262.1,272,262,272,262z M279.3,274.5c-2.1,0.9-4.5,0-5.4-2.1c-0.8-1.7-0.3-3.8,1.2-4.9
                                c1.7-1,3.7-0.7,4.5,2.9C280,272.5,281,273.4,279.3,274.5L279.3,274.5z M279.3,265.9c-0.1-1.2,0.5-3,1.7-3.1s2.3,1.5,2.4,2.7
                                c-0.1,1.4-1,2.6-2.4,2.9C279.8,268.5,279.4,267.1,279.3,265.9L279.3,265.9z M282.5,272.2c-0.5-0.3-0.5-1.1-0.1-1.9s1.2-1.2,1.7-1
                                c0.5,0.3,0.5,1.1,0.1,1.9S282.9,272.4,282.5,272.2L282.5,272.2z M284.7,281.4c-1.1,0-2-0.7-1.4-2.6c0.4-1.2,0.2-1.9,1.3-1.9
                                c1.3,0.1,2.2,1.2,2.1,2.5C286.6,280.4,285.8,281.3,284.7,281.4z M289.4,274.4c-1.1,0.3-2.3-0.3-2.7-1.5c-0.3-1.1,0.2-2.1,2.3-1.9
                                c1.2,0.1,1.9-0.2,2.2,0.9C291.3,273.1,290.5,274.2,289.4,274.4L289.4,274.4z M294.3,288.9c-0.5,0.2-1.2-0.3-1.5-1.2
                                c-0.3-0.8-0.2-1.7,0.3-1.9c0.5-0.2,1.2,0.3,1.5,1.2S294.8,288.7,294.3,288.9L294.3,288.9z M297.6,282c-1.2,1.6-3.5,2-5.1,0.7
                                c-1.3-1-1.8-2.8-1.2-4.3c0.9-1.6,2.5-2.2,4.7,0.3C297.3,280.1,298.5,280.4,297.6,282L297.6,282z M300.9,289.3
                                c-0.8-0.1-1.4-0.8-1.4-1.6c0,0,0,0,0,0c0.1-0.8,0.8-1.4,1.6-1.3c0.8,0.1,1.4,0.8,1.3,1.6C302.4,288.8,301.7,289.4,300.9,289.3z"/>
                            <path fill="#FCAE47" d="M300.6,418.1c-1.5-2.1-1.6-4.5-0.4-5.4c0.9-0.5,2-0.4,2.9,0.2c1.1-41.6-5-103.5-53.1-103.6
                                c-56.7,0.1-55,86.3-52,123.6c0,0.4,0.1,0.7,0.2,1.1c1.6-1.3,3.4-1.7,4.4-0.8c1.1,1,0.7,3.4-1,5.3c0,0,0,0-0.1,0.1
                                c3.6,3.4,9.4,7,15.9,10.4c0.5-2.1,2.4-3.5,4.5-3.4c2.4,0.4,4,2.6,3.6,5c-0.1,0.7-0.3,1.3-0.6,1.9c9.9,4.4,19.8,7.5,25,7.5
                                c6.3,0,19.5-4.6,31.1-10.4c-0.1-3.9,1.4-8.3,4.6-9.1c2.2-0.5,4.6,0.8,6.5,2.7c5.6-3.7,9.5-7.4,9.8-10.3c0.3-3.8,0.6-8.1,0.8-12.8
                                C301.9,419.6,301.1,418.9,300.6,418.1z M223.7,380.9c0.1,2.3-1.7,4.1-4,4.2c-2.3,0.1-4.1-1.7-4.2-4c-0.1-2.2,1.6-4,3.8-4.2
                                C221.6,376.8,223.6,378.6,223.7,380.9C223.7,380.9,223.7,380.9,223.7,380.9z M214.3,367c0.9-2.4,2.8-3.9,4.2-3.3
                                c1.4,0.5,1.9,2.9,1,5.3s-2.8,3.9-4.2,3.3C213.8,371.7,213.4,369.3,214.3,367z M203.1,369.2c0.9-2.4,2.8-3.9,4.2-3.3
                                c1.5,0.5,1.9,2.9,1,5.3s-2.8,3.9-4.2,3.3S202.2,371.6,203.1,369.2z M204.3,383.8c3.4-2.7,7.6-2.6,10.4,4.7c1.7,4.5,4,6.2,0.6,9
                                c-3.4,2.8-8.5,1.9-11.6-1.9C200.7,391.8,201,386.6,204.3,383.8z M203.5,419.9c-2.1,0.2-4.1-2.5-4.4-6.1c-0.3-3.5,1.3-6.5,3.4-6.7
                                c2.1-0.2,4.1,2.6,4.4,6.1C207.2,416.7,205.7,419.7,203.5,419.9z M214.7,439.6c-3.9,1-6.1-3.6-7.1-7.9c-1-4.3,0.4-10.8,4.2-11.7
                                c3.9-0.9,8.7,3.9,9.8,8.2C222.6,432.5,218.6,438.6,214.7,439.6L214.7,439.6z M224.5,410.1c-0.5,2.3-2.7,3.7-4.9,3.2
                                c-2.3-0.5-3.7-2.7-3.2-4.9c0.4-2.1,2.4-3.5,4.5-3.3C223.2,405.5,224.8,407.7,224.5,410.1L224.5,410.1z M228.4,398.3
                                c-0.9,2.4-2.8,3.9-4.2,3.3s-1.9-2.9-1-5.3c0.9-2.4,2.8-3.9,4.2-3.3C228.8,393.6,229.3,395.9,228.4,398.3L228.4,398.3z
                                M294.7,365.7c2.4-0.3,4.5,1.4,4.8,3.7c0.3,2.4-1.4,4.5-3.7,4.8c-2.4,0.3-4.5-1.4-4.8-3.7c0-0.1,0-0.1,0-0.2
                                C290.8,368,292.4,365.9,294.7,365.7L294.7,365.7z M280.4,368.6c1.5-0.5,3.3,1.1,4,3.6c0.8,2.4,0.2,4.8-1.3,5.2s-3.3-1.1-4-3.6
                                C278.3,371.4,278.9,369.1,280.4,368.6L280.4,368.6z M278.4,389.8c1.3-0.8,3.5,0.4,4.7,2.6c1.3,2.2,1.2,4.6-0.1,5.4
                                c-1.3,0.8-3.5-0.4-4.7-2.6C277,392.9,277.1,390.5,278.4,389.8z M276,408.7c0.5-2.3,2.7-3.7,4.9-3.2s3.7,2.7,3.2,4.9
                                c-0.4,2.1-2.4,3.5-4.5,3.3C277.2,413.3,275.6,411.1,276,408.7C276,408.7,276,408.7,276,408.7L276,408.7z M296.1,433.4
                                c-3.3,2.7-8.5,1.9-11.6-1.9c-3-3.8-2.8-9,0.5-11.7c3.3-2.7,7.6-2.7,10.4,4.7C297.2,429,299.5,430.7,296.1,433.4L296.1,433.4z
                                M289.3,407.2c0.9-2.4,2.8-3.9,4.2-3.3c1.5,0.5,1.9,2.9,1,5.3c-0.9,2.4-2.8,3.9-4.2,3.3S288.4,409.6,289.3,407.2z M293.6,397.6
                                c-3.9,1-6.1-3.6-7.1-7.9c-1-4.3,0.4-10.7,4.2-11.7c3.9-0.9,8.7,3.9,9.7,8.2C301.5,390.5,297.5,396.6,293.6,397.6z"/>
                            
                                <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="300.7572" y1="-52.3776" x2="301.9854" y2="-35.4251" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#787878"/>
                                <stop  offset="1" stop-color="#323232"/>
                            </linearGradient>
                            <path opacity="0.3" fill="url(#SVGID_49_)" enable-background="new    " d="M318.1,517.2c0,0-1.3-15.1-6.5-19.9
                                c-1.2-1.1-9,0.3-20.3,2.8c0,0-6.8,5.6-8.3,16.9C283,517.1,302.2,523.2,318.1,517.2z"/>
                            <path fill="#223024" d="M312.1,508.5c0.2-1,5.4-6.4,11.4-4.7c3.1,0.9,5.8,6.5,7.3,8.3c1,1.2-2.2,0.3-6.7-3.8
                                c-2.3-2-8.3,1.9-9.3,2.7C313.7,511.8,311.2,511.9,312.1,508.5z"/>
                            <path fill="#2D4C32" d="M305.1,514c0.2-1,5.4-6.4,11.4-4.7c3.1,0.9,5.8,6.5,7.3,8.3c1,1.2-2.2,0.3-6.7-3.8c-2.3-2-8.3,1.9-9.3,2.6
                                C306.7,517.2,304.2,517.4,305.1,514z"/>
                            <g opacity="0.3">
                                <path fill="#1E1E1E" d="M351.8,175.3l0.7,0.4L351.8,175.3z"/>
                                <path fill="#1E1E1E" d="M146.4,175.7l0.7-0.3C146.7,175.6,146.4,175.7,146.4,175.7z"/>
                                <path fill="#1E1E1E" d="M184,502.7c-24.6,4.1-55.8,5.3-73.7,6.8c-24.7,2-17.5,10,2.7,9.7c16.2-0.2,45,0.1,77.1,0.2
                                    c-3.2-0.4-6.3-1.1-9.3-2.2C180.9,517.2,181.6,509,184,502.7z"/>
                                <path fill="#1E1E1E" d="M203.4,519.5h2.2c-0.2,0-0.5-0.1-0.7-0.2L203.4,519.5z"/>
                                <path fill="#1E1E1E" d="M271.8,491.8c0-0.1,0-0.1,0-0.2c-2.5-11.9-3-10.7-3.1-12.6c-11.8,2.5-18.8,2.1-18.8,2.1
                                    s-7.1,0.4-18.8-2.1c-0.1,2.3-0.9,5-4.6,18.8l-0.1,0.3l-0.1,0.5c-2.8,10-7.2,18.9-14.1,20.7h2.4c41.3,0,45.1-5.5,53.3-14.6
                                    c2.1-2.5,3.9-5.3,5.1-8.4c-0.3-1.4-0.7-2.7-1-4.1C271.9,492.2,271.9,492,271.8,491.8z"/>
                                <path fill="#1E1E1E" d="M209.5,519.8c0.9,0,1.8-0.1,2.6-0.3h-6.5C206.9,519.7,208.2,519.8,209.5,519.8z"/>
                                <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M203.4,519.5h-13.3C194.5,520,199,520,203.4,519.5z"/>
                            </g>
                            
                                <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="198.4021" y1="-53.1755" x2="198.4021" y2="-6.666" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#787878"/>
                                <stop  offset="1" stop-color="#323232"/>
                            </linearGradient>
                            <path opacity="0.3" fill="url(#SVGID_50_)" enable-background="new    " d="M180.9,517.2c0,0,1.3-15.1,6.5-19.9
                                c1.2-1.1,9,0.3,20.3,2.8c0,0,6.8,5.6,8.3,16.9C215.9,517.1,196.8,523.2,180.9,517.2z"/>
                            <path fill="#223024" d="M183.7,508.5c-0.3-1-5.4-6.4-11.4-4.7c-3.1,0.9-5.8,6.5-7.3,8.3c-1,1.2,2.2,0.3,6.7-3.8
                                c2.3-2,8.3,1.9,9.3,2.7C182.1,511.8,184.6,511.9,183.7,508.5z"/>
                            <path fill="#2D4C32" d="M190.7,514c-0.2-1-5.4-6.4-11.4-4.7c-3.1,0.9-5.8,6.5-7.3,8.3c-1,1.2,2.2,0.3,6.7-3.8
                                c2.3-2,8.3,1.9,9.3,2.6C189.1,517.2,191.6,517.4,190.7,514z"/>
                            <path fill="#514D18" d="M323.1,203.9c0-3.2,2.6-5.8,5.8-5.8h0.6c-6.4-5.1-15.7-4.1-20.9,2.3c-5.1,6.4-4.1,15.7,2.3,20.9
                                c6.4,5.1,15.7,4.1,20.9-2.3c2.1-2.6,3.3-5.9,3.3-9.3h-6.2C325.7,209.7,323.1,207.1,323.1,203.9z"/>
                            <path fill="#514D18" d="M178.8,194.9c-3.4,0-6.7,1.2-9.3,3.3h0.5c3.2,0.1,5.7,2.8,5.6,6c-0.1,3-2.5,5.4-5.6,5.6h-6.1
                                c0,8.2,6.6,14.9,14.8,14.9c8.2,0,14.9-6.6,14.9-14.8C193.7,201.5,187,194.9,178.8,194.9C178.8,194.9,178.8,194.9,178.8,194.9
                                L178.8,194.9z"/>
                    </svg>
                        
       
            return teen;
    }

    getTeenWithTool(backGroundColor:string){
      const teen=  <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M352.6,175.7l-0.7-0.4L352.6,175.7c-3.4-2.6-7.2-4.8-11.2-6.5c-14.4-19.8-34.5-34.7-57.6-42.7
                                c-1.4-4.1-21.5-19.3-34.2-19.3c-11.3,0-32.8,15.2-34.2,19.3c-23.1,8-43.2,22.8-57.5,42.6c-4.1,1.7-7.9,3.8-11.4,6.5
                                c0,0,0.3-0.1,0.7-0.3c-11.7,13.1-5.2,28.2-6,39.6c-0.3,52.9,30,78.8,76.6,85.8l-2,6.9l-9.9,3.6c-3.6,0.6-7.1,1.9-10.3,3.7
                                c-38.6,21.8-84.6,28.5-81.2,50c4,25.6,54.5,6.6,76.4-3.1c-5.9,38.7-4.1,83.5,0.4,135.2c-4.5-0.9-5.3,1.8-7.1,5.7
                                c-24.7,5.6-96.7,4.3-88.9,13c1.3,2,11,3.9,18.6,3.5c7.7-0.1,17.9-0.1,30.2,0c30.6-0.2,47.2,1.2,87.1-0.1
                                c28.4-1.3,34.3-8.6,41.9-22.6c2.1,8.4,5.3,16.4,10.1,20.5c0,0,0,0,0,0.1c7.5,5.4,29,2.4,35.1,0.2c-0.2,0.2-1.4-21.6-9.6-20.3
                                c2.3-18.9,3.2-53.2,4.1-70.9c6.3,9,13.8,13.7,22.6,9.2c19.4-10-2.3-51.3-7.5-95.4c-2.5-21.6-22.6-26.9-33.3-28.2l-10.4-3.9
                                l-1.8-7.1c53.6-8.2,77.7-41,75.5-90.4C358.6,201.2,361.6,187.6,352.6,175.7z"/>
                            <path opacity="0.5" fill="#2B2B2B" enable-background="new    " d="M328.5,233.5c-1.1-0.6-2.4-0.9-3.6-1.1c-1.3-0.2-2.6,0-3.7,0.7
                                c-1,0.7-1.9,1.4-2.8,2.1l-0.8,0.4c-3.4,1.6-6,2.9-9.7,3c-4,0-8.1,0-12.1,0H289h-79.3h-6.7c-4,0-8.1,0-12.1,0
                                c-3.6,0-6.3-1.3-9.6-2.9c-1.1-0.9-2.2-1.8-3.4-2.5c-1.1-0.6-2.4-0.8-3.7-0.7c-1.3,0.2-2.5,0.5-3.6,1.1c-1.1,0.5-2.2,1.2-2.3,2.1
                                c0,0.6,0.3,1.3,0.8,1.6c1.3,1.2,3.5,1.8,5.5,2c0.7,0.1,2.5,0.4,4.5,0.7c3.3,1.6,6.9,3,11.7,3.1c1.8,0,3.7,0,5.5,0h6.7h6.7H289h6.7
                                h6.7c1.8,0,3.7,0,5.5,0c4-0.1,8-1.2,11.5-3c2.1-0.3,4.3-0.7,5-0.7c2.1-0.3,4.2-0.8,5.5-2c0.5-0.4,0.8-1,0.8-1.6
                                C330.7,234.6,329.6,233.9,328.5,233.5z"/>
                            <path fill="#FFFFFF" d="M350.4,227.3c10.8-11.4,10.4-29.4-1-40.2c-11.4-10.8-29.4-10.4-40.2,1c-10.5,11-10.5,28.3,0,39.3h0.8
                                C323.3,224.5,337.1,224.5,350.4,227.3z"/>
                            <path fill="#FFFFFF" d="M148.6,227.3c-10.8-11.4-10.4-29.4,1-40.2c11.4-10.8,29.4-10.4,40.2,1c10.4,11,10.4,28.2,0,39.2h-0.8
                                C175.8,224.5,162,224.5,148.6,227.3L148.6,227.3z"/>
                            <path fill="#FCAE47" d="M237.4,162.1c-3-3.7-2.8-9,0.5-11.7c3.3-2.7,7.5-2.6,10.4,4.7c1.7,4.5,4,6.2,0.6,8.9
                                C245.6,166.7,240.5,165.9,237.4,162.1z"/>
                            <path fill="#FCAE47" d="M258,176.6c-0.5,2.2-2.7,3.7-4.9,3.2c-2.2-0.5-3.7-2.7-3.2-4.9c0.4-2.1,2.4-3.5,4.5-3.3
                                C256.8,172,258.4,174.2,258,176.6z"/>
                            <path fill="#FCAE47" d="M240.6,179.7c0.3,3.5-1.2,6.5-3.4,6.6c-2.1,0.2-4.1-2.5-4.3-6c-0.3-3.5,1.2-6.5,3.4-6.6
                                C238.4,173.5,240.3,176.2,240.6,179.7z"/>
                            <path fill="#FCAE47" d="M257.3,147.5c0.1,2.2-1.7,4.1-4,4.2c-2.2,0.1-4.1-1.7-4.2-4c-0.1-2.2,1.6-4,3.7-4.2
                                C255.2,143.4,257.1,145.2,257.3,147.5z"/>
                            <path fill="#FCAE47" d="M262,164.9c-0.9,2.4-2.8,3.9-4.2,3.3s-1.9-2.9-1-5.3c0.9-2.4,2.8-3.9,4.2-3.3S262.8,162.5,262,164.9z"/>
                            <path fill="#FCAE47" d="M253.1,135.6c-0.9,2.4-2.8,3.9-4.2,3.3s-1.9-2.9-1-5.3s2.8-3.9,4.2-3.3C253.6,130.9,254,133.2,253.1,135.6
                                z"/>
                            <path fill="#FCAE47" d="M255.2,194.6c1,4.3-3,10.4-6.8,11.3c-3.8,1-6-3.6-7.1-7.9c-1-4.3,0.4-10.7,4.2-11.7
                                C249.3,185.4,254.1,190.4,255.2,194.6z"/>
                            <g opacity="0.5">
                                <path fill="#2B2B2B" d="M234.6,233.5l-3.6-2.1c-0.8-0.5-1.1-1.5-0.6-2.3l0,0c0.5-0.8,1.5-1.1,2.3-0.6l3.6,2.1
                                    c0.8,0.5,1.1,1.5,0.6,2.3l0,0C236.4,233.7,235.4,234,234.6,233.5C234.6,233.5,234.6,233.5,234.6,233.5z"/>
                                <path fill="#2B2B2B" d="M264.4,233.5l3.6-2.1c0.8-0.5,1.1-1.5,0.6-2.3c0,0,0,0,0,0l0,0c-0.5-0.8-1.5-1.1-2.3-0.6l-3.6,2.1
                                    c-0.8,0.5-1.1,1.5-0.6,2.3l0,0C262.6,233.7,263.6,234,264.4,233.5z"/>
                            </g>
                            <path fill="none" d="M352.6,175.7c0,0-0.3-0.1-0.8-0.4L352.6,175.7z"/>
                            <path fill="none" d="M146.5,175.7l0.8-0.4L146.5,175.7z"/>
                            
                                <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="-8236.667" y1="262.3355" x2="-8239.4727" y2="293.3139" gradientTransform="matrix(-1 0 0 -1 -8065.3901 461.88)">
                                <stop  offset="0" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.6"/>
                            </linearGradient>
                            <path fill="url(#SVGID_43_)" d="M147.2,175.3l-0.8,0.4c-11.8,15.4-3.7,38.5-3.7,38.5l48.3,12.5c0,0,16.7-15.6,10.6-43.3
                                C181.4,161.3,152.3,173,147.2,175.3z"/>
                            <path fill="#FFFFFF" d="M148.6,227.3c-10.8-11.4-10.4-29.4,1-40.2c11.4-10.8,29.4-10.4,40.2,1c10.4,11,10.4,28.2,0,39.2h-0.8
                                C175.8,224.5,162,224.5,148.6,227.3L148.6,227.3z"/>
                            <path opacity="0.3" fill="#DBDBDB" enable-background="new    " d="M289.2,176.6c0,0-0.5-0.1,8.1,7l0,0
                                c20.4-22.2,49.5-10.5,54.5-8.2c0.5,0.2,0.8,0.4,0.8,0.4C322.5,152.9,289.2,176.6,289.2,176.6z"/>
                            <path opacity="0.3" fill="#DBDBDB" enable-background="new    " d="M209.8,176.6c0,0-33.3-23.6-63.4-0.9l0.8-0.4
                                c5-2.3,34.1-14.1,54.5,8.2l0,0C210.3,176.4,209.8,176.6,209.8,176.6z"/>
                            
                                <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="327.7744" y1="262.3357" x2="324.9688" y2="293.3141" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.6"/>
                            </linearGradient>
                            <path fill="url(#SVGID_44_)" d="M352.6,175.7l-0.8-0.4c-5-2.3-34.1-14.1-54.5,8.2c-6.1,27.6,10.6,43.3,10.6,43.3l48.3-12.5
                                C356.3,214.2,364.4,191.1,352.6,175.7z"/>
                            <path fill="#FFFFFF" d="M350.4,227.3c10.8-11.4,10.4-29.4-1-40.2c-11.4-10.8-29.4-10.4-40.2,1c-10.5,11-10.5,28.3,0,39.3h0.8
                                C323.3,224.5,337.1,224.5,350.4,227.3z"/>
                            <path fill="#FCAE47" d="M280.8,252.5h-62.7c0,0-10.3,23.1-33.7,38.5c4.2,2,8.6,3.7,13,5.1c8.2,2.5,16.7,4.3,25.3,5.3l2.7,0.3H225
                                c1.8,0.2,3.6,0.4,5.4,0.5c6.2,0.5,12.7,0.8,19.6,0.8c6.9,0,13.4-0.3,19.5-0.8c1.8-0.2,3.6-0.3,5.4-0.5l-1.5,0
                                c1.1-0.1,2.2-0.2,3.3-0.4c8.5-1,16.9-2.8,25-5.3c4.4-1.4,8.8-3,13-5C291.1,275.7,280.8,252.5,280.8,252.5z M198.1,289.4
                                c-0.8,0.1-1.6-0.5-1.7-1.3c-0.1-0.8,0.5-1.6,1.3-1.7c0.8-0.1,1.6,0.5,1.7,1.3c0,0,0,0.1,0,0.1
                                C199.5,288.6,198.9,289.3,198.1,289.4z M220.6,258.2c1.1-0.9,2.4-1,3-0.4c0.5,0.7,0.1,1.9-1,2.8s-2.4,1-3,0.4
                                S219.5,259,220.6,258.2L220.6,258.2z M206.3,287.8c-0.3,0.8-1,1.4-1.5,1.2c-0.5-0.2-0.7-1.1-0.3-1.9c0.3-0.8,1-1.4,1.6-1.2
                                S206.6,286.9,206.3,287.8L206.3,287.8z M206.2,283c-1.6,1-3.7,0.6-4.8-1c-0.9-1.6,0.2-1.9,1.6-3.4c2.2-2.4,3.8-1.8,4.7-0.3
                                C208.6,280.1,207.8,282.1,206.2,283L206.2,283z M209.6,274.4c-1.2-0.2-2-1.3-1.8-2.5c0.3-1.1,0.9-0.8,2.2-0.9
                                c2-0.2,2.5,0.8,2.3,1.9C211.9,274,210.7,274.7,209.6,274.4C209.6,274.3,209.6,274.3,209.6,274.4L209.6,274.4z M214.3,281.4
                                c-1.3-0.2-2.1-1.3-2-2.5c0.1-1.1,1-1.9,2.1-2c1.1,0,0.9,0.7,1.3,1.9C216.3,280.7,215.4,281.4,214.3,281.4L214.3,281.4z
                                M216.6,272.2c-0.5,0.3-1.2-0.2-1.7-1c-0.4-0.8-0.4-1.7,0.1-1.9c0.5-0.3,1.2,0.2,1.7,1C217.1,271,217,271.9,216.6,272.2
                                L216.6,272.2z M215.7,265.4c0.1-1.2,1.3-2.8,2.4-2.7c1.1,0.1,1.8,1.9,1.7,3.1c-0.1,1.2-0.6,2.6-1.7,2.5
                                C216.7,268.1,215.7,266.8,215.7,265.4L215.7,265.4z M225,272.8c-1,1.9-3.3,2.6-5.2,1.6c-1.7-1-0.8-2-0.3-4.2
                                c0.8-3.6,2.8-3.9,4.5-2.9C225.7,268.6,226.1,271,225,272.8L225,272.8z M225.7,263.6c-0.8,0.1-1.6-0.5-1.7-1.3
                                c-0.1-0.8,0.5-1.6,1.3-1.7s1.6,0.5,1.7,1.3c0,0,0,0.1,0,0.1C227.1,262.8,226.5,263.5,225.7,263.6L225.7,263.6z M275.4,257.8
                                c0.5-0.7,1.9-0.5,3,0.4s1.6,2.1,1,2.8c-0.5,0.7-1.9,0.5-3-0.4S274.8,258.5,275.4,257.8L275.4,257.8z M272,262
                                c0-0.8,0.7-1.4,1.5-1.4s1.4,0.7,1.4,1.5c0,0,0,0.1,0,0.1c0,0.8-0.7,1.4-1.5,1.4s-1.4-0.7-1.4-1.5C272,262.1,272,262,272,262z
                                M279.3,274.4c-2.1,0.9-4.5,0-5.4-2.1c-0.8-1.7-0.3-3.8,1.2-4.9c1.7-1,3.7-0.7,4.5,2.9C280.1,272.4,281,273.4,279.3,274.4
                                L279.3,274.4z M279.3,265.9c-0.1-1.2,0.5-3,1.7-3.1c1.1-0.1,2.3,1.5,2.4,2.7c-0.1,1.4-1,2.6-2.4,2.9
                                C279.8,268.4,279.4,267.1,279.3,265.9L279.3,265.9z M282.5,272.1c-0.5-0.3-0.5-1.1-0.1-1.9c0.4-0.8,1.2-1.2,1.7-1s0.5,1.1,0.1,1.9
                                C283.7,272,282.9,272.4,282.5,272.1L282.5,272.1z M284.7,281.4c-1.1,0-2-0.7-1.4-2.6c0.4-1.2,0.2-1.9,1.3-1.9
                                c1.3,0.1,2.2,1.2,2.1,2.5C286.6,280.4,285.8,281.3,284.7,281.4z M289.4,274.3c-1.1,0.3-2.3-0.3-2.7-1.5c-0.3-1.1,0.2-2.1,2.3-1.9
                                c1.2,0.1,1.9-0.2,2.2,0.9C291.3,273.1,290.5,274.1,289.4,274.3L289.4,274.3z M294.3,288.9c-0.5,0.2-1.2-0.3-1.5-1.2
                                s-0.2-1.7,0.3-1.9c0.5-0.2,1.2,0.3,1.5,1.2C295,287.9,294.8,288.7,294.3,288.9L294.3,288.9z M297.6,282c-1.2,1.6-3.5,2-5.1,0.7
                                c-1.3-1-1.8-2.8-1.2-4.3c0.9-1.6,2.5-2.2,4.7,0.3C297.3,280.1,298.5,280.4,297.6,282L297.6,282z M300.9,289.3
                                c-0.8-0.1-1.4-0.8-1.4-1.6c0,0,0,0,0,0c0.1-0.8,0.8-1.4,1.6-1.3c0.8,0.1,1.4,0.8,1.3,1.6C302.4,288.8,301.7,289.4,300.9,289.3z"/>
                            <path fill="#FCAE47" d="M300.6,418.1c-1.5-2.1-1.6-4.5-0.4-5.4c0.9-0.5,2-0.4,2.9,0.2c1.1-41.6-5-103.5-53.1-103.6
                                c-56.7,0.1-55,86.3-52,123.6c0,0.4,0.1,0.7,0.2,1.1c1.6-1.3,3.4-1.7,4.4-0.8c1.1,1,0.7,3.4-1,5.3c0,0,0,0-0.1,0.1
                                c3.6,3.4,9.4,7,15.9,10.4c0.5-2.1,2.4-3.5,4.5-3.4c2.4,0.4,4,2.6,3.6,5c-0.1,0.7-0.3,1.3-0.6,1.9c9.9,4.4,19.8,7.5,25,7.5
                                c6.3,0,19.5-4.6,31.1-10.4c-0.1-3.9,1.4-8.3,4.6-9.1c2.2-0.5,4.6,0.8,6.5,2.7c5.6-3.7,9.5-7.4,9.8-10.3c0.3-3.8,0.6-8.1,0.8-12.8
                                C301.9,419.6,301.1,418.9,300.6,418.1z M223.7,380.8c0.1,2.3-1.7,4.1-4,4.2c-2.3,0.1-4.1-1.7-4.2-4c-0.1-2.2,1.6-4,3.8-4.2
                                C221.6,376.7,223.6,378.5,223.7,380.8C223.7,380.8,223.7,380.8,223.7,380.8z M214.3,366.9c0.9-2.4,2.8-3.9,4.2-3.3
                                c1.4,0.5,1.9,2.9,1,5.3c-0.9,2.4-2.8,3.9-4.2,3.3C213.9,371.7,213.4,369.3,214.3,366.9z M203.2,369.2c0.9-2.4,2.8-3.9,4.2-3.3
                                c1.5,0.5,1.9,2.9,1,5.3s-2.8,3.9-4.2,3.3C202.8,374,202.3,371.6,203.2,369.2z M204.3,383.8c3.4-2.7,7.6-2.6,10.4,4.7
                                c1.7,4.5,4,6.2,0.6,9c-3.4,2.8-8.5,1.9-11.6-1.9C200.7,391.8,201,386.5,204.3,383.8z M203.5,419.8c-2.1,0.2-4.1-2.5-4.4-6.1
                                c-0.3-3.5,1.3-6.5,3.4-6.7c2.1-0.2,4.1,2.6,4.4,6.1C207.2,416.7,205.7,419.7,203.5,419.8z M214.7,439.6c-3.9,1-6.1-3.6-7.1-7.9
                                c-1-4.3,0.4-10.8,4.2-11.7c3.9-0.9,8.7,3.9,9.8,8.2C222.7,432.5,218.6,438.6,214.7,439.6L214.7,439.6z M224.5,410
                                c-0.5,2.3-2.7,3.7-4.9,3.2c-2.3-0.5-3.7-2.7-3.2-4.9c0.4-2.1,2.4-3.5,4.5-3.3C223.2,405.4,224.8,407.7,224.5,410L224.5,410z
                                M228.4,398.3c-0.9,2.4-2.8,3.9-4.2,3.3c-1.4-0.5-1.9-2.9-1-5.3c0.9-2.4,2.8-3.9,4.2-3.3C228.9,393.5,229.3,395.9,228.4,398.3
                                L228.4,398.3z M294.8,365.6c2.4-0.3,4.5,1.4,4.8,3.7c0.3,2.4-1.4,4.5-3.7,4.8c-2.4,0.3-4.5-1.4-4.8-3.7c0-0.1,0-0.1,0-0.2
                                C290.8,367.9,292.4,365.9,294.8,365.6L294.8,365.6z M280.4,368.6c1.5-0.5,3.3,1.1,4,3.6c0.8,2.4,0.2,4.8-1.3,5.2
                                c-1.5,0.4-3.3-1.1-4-3.6S278.9,369,280.4,368.6L280.4,368.6z M278.4,389.7c1.3-0.8,3.5,0.4,4.7,2.6s1.2,4.6-0.1,5.4
                                c-1.3,0.8-3.5-0.4-4.7-2.6C277,392.9,277.1,390.5,278.4,389.7z M276,408.7c0.5-2.3,2.7-3.7,4.9-3.2s3.7,2.7,3.2,4.9
                                c-0.4,2.1-2.4,3.5-4.5,3.3C277.2,413.3,275.6,411,276,408.7C276,408.6,276,408.6,276,408.7L276,408.7z M296.1,433.4
                                c-3.3,2.7-8.5,1.9-11.6-1.9c-3-3.8-2.8-9,0.5-11.7c3.3-2.7,7.6-2.7,10.4,4.7C297.2,428.9,299.5,430.6,296.1,433.4L296.1,433.4z
                                M289.3,407.2c0.9-2.4,2.8-3.9,4.2-3.3c1.5,0.5,1.9,2.9,1,5.3s-2.8,3.9-4.2,3.3C288.9,411.9,288.4,409.6,289.3,407.2z
                                M293.6,397.5c-3.9,1-6.1-3.6-7.1-7.9c-1-4.3,0.4-10.7,4.2-11.7c3.9-0.9,8.7,3.9,9.7,8.2C301.5,390.5,297.5,396.6,293.6,397.5z"/>
                            
                                <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="300.7647" y1="-52.3246" x2="301.9928" y2="-35.3743" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#787878"/>
                                <stop  offset="1" stop-color="#323232"/>
                            </linearGradient>
                            <path opacity="0.3" fill="url(#SVGID_45_)" enable-background="new    " d="M318.1,517.2c0,0-1.3-15.1-6.5-19.9
                                c-1.2-1.1-9,0.3-20.3,2.8c0,0-6.8,5.6-8.3,16.9C283,517,302.2,523.1,318.1,517.2z"/>
                            <path fill="#223024" d="M312.1,508.4c0.2-1,5.4-6.4,11.4-4.7c3.1,0.9,5.8,6.5,7.3,8.3c1,1.2-2.2,0.3-6.7-3.8
                                c-2.3-2-8.3,1.9-9.3,2.7C313.7,511.7,311.2,511.9,312.1,508.4z"/>
                            <path fill="#2D4C32" d="M305.1,514c0.2-1,5.4-6.4,11.4-4.7c3.1,0.9,5.8,6.5,7.3,8.3c1,1.2-2.2,0.3-6.7-3.8c-2.3-2-8.3,1.9-9.3,2.6
                                C306.7,517.2,304.2,517.4,305.1,514z"/>
                            <g opacity="0.3">
                                <path fill="#1E1E1E" d="M351.8,175.3l0.7,0.4L351.8,175.3z"/>
                                <path fill="#1E1E1E" d="M146.5,175.7l0.7-0.3C146.7,175.6,146.5,175.7,146.5,175.7z"/>
                                <path fill="#1E1E1E" d="M184.1,502.7c-24.6,4.1-55.8,5.3-73.7,6.8c-24.7,2-17.5,10,2.7,9.7c16.2-0.2,45,0.1,77.1,0.2
                                    c-3.2-0.4-6.3-1.1-9.3-2.2C180.9,517.2,181.6,509,184.1,502.7z"/>
                                <path fill="#1E1E1E" d="M203.4,519.4h2.2c-0.2,0-0.5-0.1-0.7-0.2L203.4,519.4z"/>
                                <path fill="#1E1E1E" d="M271.8,491.8c0-0.1,0-0.1,0-0.2c-2.5-11.9-3-10.7-3.1-12.5c-11.8,2.5-18.8,2.1-18.8,2.1
                                    s-7.1,0.4-18.8-2.1c-0.1,2.3-0.9,5-4.6,18.8l-0.1,0.3l-0.1,0.5c-2.8,10-7.2,18.9-14.1,20.7h2.4c41.3,0,45.1-5.5,53.3-14.6
                                    c2.1-2.5,3.9-5.3,5.1-8.4c-0.3-1.4-0.7-2.7-1-4.1C271.9,492.2,271.9,491.9,271.8,491.8z"/>
                                <path fill="#1E1E1E" d="M209.5,519.8c0.9,0,1.8-0.1,2.6-0.3h-6.5C206.9,519.7,208.2,519.8,209.5,519.8z"/>
                                <path opacity="0.3" fill="#1E1E1E" enable-background="new    " d="M203.4,519.4h-13.3C194.6,519.9,199,519.9,203.4,519.4z"/>
                            </g>
                            
                                <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="198.423" y1="-53.1224" x2="198.423" y2="-6.619" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#787878"/>
                                <stop  offset="1" stop-color="#323232"/>
                            </linearGradient>
                            <path opacity="0.3" fill="url(#SVGID_46_)" enable-background="new    " d="M180.9,517.2c0,0,1.3-15.1,6.5-19.9
                                c1.2-1.1,9,0.3,20.3,2.8c0,0,6.8,5.6,8.3,16.9C216,517,196.8,523.1,180.9,517.2z"/>
                            <path fill="#223024" d="M183.7,508.4c-0.3-1-5.4-6.4-11.4-4.7c-3.1,0.9-5.8,6.5-7.3,8.3c-1,1.2,2.2,0.3,6.7-3.8
                                c2.3-2,8.3,1.9,9.3,2.7C182.1,511.7,184.6,511.9,183.7,508.4z"/>
                            <path fill="#2D4C32" d="M190.7,514c-0.2-1-5.4-6.4-11.4-4.7c-3.1,0.9-5.8,6.5-7.3,8.3c-1,1.2,2.2,0.3,6.7-3.8
                                c2.3-2,8.3,1.9,9.3,2.6C189.1,517.2,191.6,517.4,190.7,514z"/>
                            <path fill="#514D18" d="M323.1,203.9c0-3.2,2.6-5.8,5.8-5.8h0.6c-6.4-5.1-15.7-4.1-20.9,2.3c-5.1,6.4-4.1,15.7,2.3,20.9
                                s15.7,4.1,20.9-2.3c2.1-2.6,3.3-5.9,3.3-9.3h-6.2C325.7,209.7,323.1,207.1,323.1,203.9z"/>
                            <path fill="#514D18" d="M178.8,194.9c-3.4,0-6.7,1.2-9.3,3.3h0.5c3.2,0.1,5.7,2.8,5.6,6c-0.1,3-2.5,5.4-5.6,5.6H164
                                c0,8.2,6.6,14.9,14.8,14.9c8.2,0,14.9-6.6,14.9-14.8S187,194.9,178.8,194.9C178.8,194.9,178.8,194.9,178.8,194.9L178.8,194.9z"/>
                    </svg>
        return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
