import React from 'react';
import './notification.scss';
import Layout from '../components/layout/layout';
import { authenticationService } from '../../services';

export interface Props {
    className: any,
    history: any,
    location: any,
    currentUser: any
}
 
export interface State {
}

let currentUser;

class Notification extends React.Component<Props, State>{
    constructor(props){
        super(props);
    }

    async componentWillMount() {
        this.setState({ isloading: true });
        currentUser = authenticationService.currentUserValue.user;
        if (currentUser) {
            
        } else {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
        }
        this.setState({ isloading: false });
    }

    render(){
        console.log("props", this.props);
        
        const {className, location} = this.props;
        return(
            <div className={className}>
                <Layout className={className} currentUser={currentUser} location={location} history={this.props.history}></Layout>
            </div>
        );
    }
}

export default Notification;
