import React from 'react';
import './modalComponent.scss';
import { Button, Fade, Box, Grid, Dialog } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ButtonRound from '../buttonRound/buttonRound';
import { Formik, Field, Form } from 'formik';
import InputBase from '@material-ui/core/InputBase';
import { TextField } from 'formik-material-ui'
import { userService } from '../../../services';
import * as yup from 'yup';
import { Notifier } from '../notifier/notifier';
const close = require('../../../assets/images/close_icon.svg');

export interface Props {
    currentUser: any;
    setOpen: boolean,
    backGroundColor: any,
    titleFirst?: string,
    titleSecond?: string,
    logo?: string,
    callback: () => void,
    data: Array<any>,
    fromAdminPage?: boolean,
}

export interface State {
    setOpen: boolean
    snackBarStatus: any;
    isLoading: boolean;
}

export interface changePassword {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

class ModalResetPassword extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        const stateObject = {
            msg: "",
            status: null,
            date: new Date(),
            variant: 'error'
        }
        this.setState({ snackBarStatus: stateObject });
    }

    componentWillMount() {
        const stateObject = {
            msg: " ",
            status: null,
            date: new Date(),
            variant: 'error'
        }
        this.setState({ snackBarStatus: stateObject });
    }
    cancelItem_onClick = () => {
        // this.props.callback
        if (typeof this.props.callback === 'function') {
            this.props.callback();
        }
    }
    render() {
        const { titleFirst, logo, titleSecond, callback, setOpen, currentUser, fromAdminPage } = this.props;
        console.log("DATA", currentUser);

        const schema = yup.object().shape({
            currentPassword: fromAdminPage ? yup.string() : yup.string().required('Password is required'),
            newPassword: yup.string()
                .required('No password provided.')
                .min(8, 'Password is too short - should be 8 chars minimum.'),
            // .matches(
            //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            //   ),
            confirmPassword: yup.string()
                .oneOf([yup.ref('newPassword'), null], 'Passwords must match').required('Confirm Password is required')
        })
        return (
            <div className={"modal-component"}>
                <Dialog
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={setOpen}
                    onClose={callback}
                    className={"modal-component"}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={setOpen}>
                        <Box className={"modal-box background-A"}>
                            <Grid container className={logo} style={{ padding: "30px", height: "100%" }}>
                                <Grid xs={12} style={{ display: "flex" }}>
                                    <Grid xs={8} style={{ position: "relative" }}>
                                        <Box className={"reset-modal-title"}>
                                            {titleFirst ?
                                                <Box>
                                                    <span>{titleFirst}</span>
                                                </Box> : null}
                                            {titleSecond ?
                                                <Box>
                                                    <span>{titleSecond}</span>
                                                </Box> : null}
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box style={{ float: "right" }}>
                                            <ButtonRound backgroundColor={"#ffffff"} className={"button-close"} callback={callback} icon={close}></ButtonRound>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} style={{ paddingTop: "180px", height: "60%", marginRight: "15%" }}>
                                    <Formik<changePassword>
                                        initialValues={{
                                            currentPassword: "",
                                            newPassword: "",
                                            confirmPassword: ""
                                        }}
                                        validationSchema={schema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            this.setState({ isLoading: true });
                                            setTimeout(() => {
                                                setSubmitting(false);
                                                const stateObject = {
                                                    msg: "",
                                                    status: true,
                                                    date: new Date(),
                                                    variant: ''
                                                }
                                                if (fromAdminPage) {
                                                    const object = {
                                                        userID: currentUser.id,
                                                        newPassword: values.newPassword,
                                                    }
                                                    userService.changePassword(object).then((data: any) => {
                                                        if (data.success) {
                                                            stateObject.msg = data.message;
                                                            stateObject.variant = "success";
                                                        } else {
                                                            stateObject.msg = data.message;
                                                            stateObject.variant = "error";
                                                        }
                                                        this.setState({ snackBarStatus: stateObject });
                                                        this.setState({ isLoading: false });
                                                    })
                                                } else {
                                                    const object = {
                                                        currentPassword: values.currentPassword,
                                                        newPassword: values.newPassword
                                                    }
                                                    userService.resetPassword(object).then((data: any) => {
                                                        if (data.success) {
                                                            stateObject.msg = data.message;
                                                            stateObject.variant = "success";
                                                        } else {
                                                            stateObject.msg = data.message;
                                                            stateObject.variant = "error";
                                                        }
                                                        this.setState({ snackBarStatus: stateObject });
                                                        this.setState({ isLoading: false });
                                                    })
                                                    // this.submitStory(values, isNewRegister);
                                                }
                                            }, 1000);
                                        }}
                                        render={({ setFieldTouched, handleChange, submitForm, isSubmitting, values, errors }) => (
                                            <Form>
                                                {!fromAdminPage ?
                                                    <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                                                        <InputBase
                                                            defaultValue="Current Password *"
                                                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                            className={errors.currentPassword ? "label Mui-error-label" : "label"}
                                                        />
                                                        <Field
                                                            type="password"
                                                            name="currentPassword"
                                                            component={TextField}
                                                            variant="filled"
                                                            style={{ width: "100%" }}
                                                            autoFocus={true}
                                                            onChange={e => {
                                                                setFieldTouched('currentPassword');
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </Grid> : null}

                                                <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                                                    <InputBase
                                                        defaultValue="New Password *"
                                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                        className={errors.newPassword ? "label Mui-error-label" : "label"}
                                                    />
                                                    <Field
                                                        type="password"
                                                        name="newPassword"
                                                        component={TextField}
                                                        variant="filled"
                                                        style={{ width: "100%" }}
                                                        onChange={e => {
                                                            setFieldTouched('newPassword');
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                                                    <InputBase
                                                        defaultValue="Confirm password *"
                                                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                        className={errors.confirmPassword ? "label Mui-error-label" : "label"}
                                                    />
                                                    <Field
                                                        type="password"
                                                        name="confirmPassword"
                                                        component={TextField}
                                                        variant="filled"
                                                        style={{ width: "100%" }}
                                                        onChange={e => {
                                                            setFieldTouched('confirmPassword');
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px", display: "flex" }}>
                                                    <Box>
                                                        <Button
                                                            variant="contained"
                                                            className={"cancel-button"}
                                                            onClick={this.cancelItem_onClick}
                                                        >
                                                            Cancel
                                                                </Button>
                                                    </Box>
                                                    <Box style={{ paddingLeft: "10px" }}>
                                                        <Button
                                                            variant="contained"
                                                            className={"save-button"}
                                                            disabled={isSubmitting}
                                                            onClick={submitForm}
                                                        >
                                                            Save
                                                            </Button>
                                                    </Box>
                                                </Grid>
                                            </Form>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Dialog>
                {this.state.snackBarStatus.status ?
                    <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </div>
        );
    }
}
export default ModalResetPassword;