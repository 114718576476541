import * as React from 'react';
import './progressBar.scss';
import { Box } from '@material-ui/core';
import {  withStyles  } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const loading = require('../../../assets/images/loading_animation.gif');

export interface Props {
  
}

export interface State {
 }
 const ColorCircularProgress = withStyles({
    root: {
      color: '#F6921E',
    },
  })(CircularProgress);

class ProgressBar extends React.Component<Props, State>{


    render() {
        return (
            <Box className="progressBarContainer" >
                {/* <ColorCircularProgress size={100} thickness={5} /> */}
                <img className="loading" src={loading} alt="loading"/>
            </Box>
        )
    }
    
}
export default ProgressBar;