import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { useConfirmation } from "./editDetailsDialogService";

export interface EditDetailButtonOptions {
    userData:any,
    isChildPageCall:boolean,
    updated: () => void,
  }

export const EditDetailsButton: React.FC <EditDetailButtonOptions>=({
    userData,
    isChildPageCall,
    updated
  }) =>{
  const confirm = useConfirmation();

  const tryToKill = (userData, isChildPageCall) => {
    confirm({
      catchOnCancel: true,
      // title: "Are you sure you want to remove this burrito?",
       userData:userData,
       isChildPageCall:isChildPageCall
    })
      .then(() =>{
          console.log("okay clicked")
          updated();
        })
        .catch(() =>{
            console.log("cancel clicked")
              });
  };

 

  return (
    <Grid container alignItems="center" direction="column">

        <Button onClick={()=>tryToKill(userData, isChildPageCall)} className={"blue-button"}> Edit Details </Button>
    </Grid>
  );
};
