import React from 'react';
import clsx from 'clsx';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { authenticationService } from '../../services';
import { Box, FormGroup, FormControlLabel, Typography, Container, Grid, FormControl, TextField, InputLabel, Button, Link, LinearProgress } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LayoutBlank from '../components/layout/layoutBlank';
import './login.scss';
import Wrapper from '../baseComponents/wrapper';
import { Notifier } from '../components/notifier/notifier';
import logo  from '../../assets/images/logo.svg';


export interface Props {
  history: any,
  className: string,
  location: any
}

export interface State {
  showPassword: boolean;
  snackBarStatus: any;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      flexBasis: 200,
    },
  }),
);

let messageAboutBrowser = "Make sure you're using Google Chrome or Microsoft Edge version 85 or above to get the full experience.";
class Login extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    const stateObject = {
      msg: " ",
      status: false,
      date: new Date(),
      variant: 'success'
    }
    this.state = {
      showPassword: false,
      password: "",
      snackBarStatus: stateObject,

    };
    // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    // this.toggleShow = this.toggleShow.bind(this);
  }


  // handlePasswordChange(e) {
  //     this.setState({ password: e.target.value });
  // }

  // toggleShow() {
  //     this.setState({ showPassword: this.state.showPassword });
  // }

  // componentDidMount() {

  //     this.setState({ password: this.state.password });

  // }

  handleChange(e) {
    console.log(e);
  }

  showAlertMessage  = (stateObject: any) => {
    stateObject.status = true;
    this.setState({ snackBarStatus: stateObject});
    setTimeout(() => {
        stateObject.msg = "";
        stateObject.status = false;
        stateObject.variant = "";
        this.setState({ snackBarStatus: stateObject });
    }, 3000);
}

  render() {
    // height of the TextField
    const height = 17
    // magic number which must be set appropriately for height
    const labelOffset = -6

    const classes = useStyles

    interface Values {
      userName: string,
      userPassword: string
    }
    const schema = yup.object().shape({
      userName: yup.string().required(),
      userPassword: yup.string().required()
    });


    //Listen for data on the "outgoing data" namespace and supply a callback for what to do when we get one. In this case, we set a state variable

    // get this from your form library, for instance in
    // react-final-form it's fieldProps.meta.active
    // or provide it yourself - see notes below

    const focused = ''
    return (
      <div className={this.props.className}>
        <Grid container>
          <Grid item xs={4} className={"grid-left-logo"}>
            <LayoutBlank className={this.props.className}></LayoutBlank>
          </Grid>
          <Grid item xs className={"grid-login"}>
              <Grid className='top' style={{margin:"50px 0px"}} >
                  <img src={logo} alt="Logo" />
              </Grid>
            <Wrapper title={''}>
              <Formik<Values>
                initialValues={{
                  userName: "",
                  userPassword: ""
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  const stateObject = {
                    msg: '',
                    status: true,
                    date: new Date(),
                    variant: ''
                  }
                  setTimeout(() => {
                    // setStatus();
                    authenticationService.login(values.userName, values.userPassword)
                      .then(
                        res => {
                          if (res.success) {
                            setSubmitting(false);
                            //const { from } = this.props.location.state || { from: { pathname: "/" } }                                 
                            const { from } = this.props.location.state || { from: { pathname: `/newsfeed/${res.data.user.userId}` } };
                            console.log(from)
                            this.props.history.push(from);
                          } else {
                            setSubmitting(false);
                            const stateObject = {
                              msg: res.message,
                              status: false,
                              date: new Date(),
                              variant: 'error'
                            }
                            this.showAlertMessage(stateObject)
                          }
                        },
                        error => {
                          console.log(error)
                          setSubmitting(false);
                          const stateObject = {
                            msg: error.message,
                            status: true,
                            date: new Date(),
                            variant: 'error'
                          }
                          this.showAlertMessage(stateObject);
                        }
                      );
                  }, 400);
                }}
                render={({ setFieldTouched, handleChange, submitForm, isSubmitting, values, errors }) => (
                  // <Container>

                  <Form className="loginPage">
                    {/* <FormControl fullWidth={true}  >   */}
                    <Box >
                      <Field
                        type="text"
                        name="userName"
                        component={TextField}
                        id="userName"
                        label="Username"
                        className={"loginPage"}
                        // type="text"
                        variant="outlined"
                        fullWidth
                        onChange={e => {
                          setFieldTouched('userName');
                          handleChange(e);
                        }}
                        /* styles the label component */
                        InputLabelProps={{
                          style: {
                            height,
                            ...(!focused && { top: `${labelOffset}px` }),
                          },
                        }}

                        /* styles the input component */
                        inputProps={{
                          style: {
                            height,
                            padding: '0 14px',
                          },
                        }}
                      />
                    </Box>
                    <Box>

                      <Field
                        type="password"
                        name="userPassword"
                        component={TextField}
                        id="userPassword"
                        label="Password"
                        className={"loginPage"}
                        // type="text"
                        variant="outlined"
                        fullWidth
                        onChange={e => {
                          setFieldTouched('userPassword');
                          handleChange(e);
                        }}

                        /* styles the label component */
                        InputLabelProps={{
                          style: {
                            height,
                            ...(!focused && { top: `${labelOffset}px` }),
                          },
                        }}

                        /* styles the input component */
                        inputProps={{
                          style: {
                            height,
                            padding: '0 14px',
                          },
                        }}
                      />
                    </Box>


                    <div className="form-bottom">
                      <FormGroup row>
                        <Grid item md={6} style={{ textAlign: "left" }} className={"forgot-password-section"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                // checked={state.checkedB}
                                // onChange={handleChange('checkedB')}
                                value="checkedB"
                              />
                            }
                            label="Remember me"
                          />
                        </Grid>
                        <Grid item md={6} style={{ textAlign: "right" }}>
                          {/* <Typography variant="body2" gutterBottom className={"forgot-password-section"}> */}
                          <Box mt={"8px"} className={"forgot-password-section"}>
                            <Link href="/forgot" className="color-red" > Forgot Password </Link>
                          </Box>
                          {/* </Typography> */}
                        </Grid>

                      </FormGroup>
                      {isSubmitting && <LinearProgress />}
                      <Button type="submit" className="btn rounded-radius blue-fullwidth-button" variant="contained" disabled={isSubmitting} >Login</Button>
                    </div>
                  </Form>
                  // </Container>
                )}
              />

              {this.state.snackBarStatus.status ?
                <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </Wrapper>
            <Box style={{margin:"15px 0px", textAlign:"center"}}>
              <Typography >
                  <Link href="https://www.burrundalai.org.au/" target="_blank">burrundalai.org</Link>
                  <Link href="https://www.burrundalai.org.au/privacy" target="_blank">privacy</Link>
                  <Link href="#">terms & conditions</Link>
                </Typography>
              </Box>

              <Box style={{margin:"auto", paddingTop:"30px", textAlign:"center",maxWidth: "50%"}}>
              <Typography >{messageAboutBrowser}</Typography>
              </Box>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export { Login };