import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const categoryService = {
    getAll,
    createCategory
};

function getAll() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/categories/getCategoryList`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
   
}
function createCategory(values:any) {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
        method: 'POST',
        headers: { authorization:authHeader() , 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/categories/addCategory`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
   
}
