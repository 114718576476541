import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './views/app/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import createBreakpoints from "./views/app/createBreakpoints"
import { IosSwitchMaterialUiProps } from 'ios-switch-material-ui';

interface breackgrount{
  mybreakpoint:IosSwitchMaterialUiProps
}

const theme = createMuiTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1700,
      xl: 1920
    }
  }
});
//   const muiTheme = createMuiTheme({
//     breakpoints: {
//       keys: ['xs', 'sm', 'md', 'lg', 'xl'],
//       values: [360, 768, 992, 1200, 1440],
//     },
//   );
  
// const thememui = createMuiTheme({
//   breakpoints: {
//     values: {
//       xs:600,
//       sm:960,
//       md:960,
//       lg:1280,
//       llg:1680,
//       xl:1980,

//     }
//   },
//   values: {
//     xl: 1020,
//     xxl: 1500
//   }
// });
// function appObject(props){
  
// }
ReactDOM.render(
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <App /> 
            </MuiThemeProvider>
        </BrowserRouter>, 
       document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
