import React from 'react';
import clsx from 'clsx';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { userService } from '../../services';
import { TextField, Button, Link, Box, LinearProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Layout from '../components/layout/layoutBlank';
import { Notifier } from '../components/notifier/notifier';
import { FormatDate } from '../../common/formatDate';
import LayoutBlank from '../components/layout/layoutBlank';
import logo from '../../assets/images/logo.svg';
import Wrapper from '../baseComponents/wrapper';
// import '../login/login.css';


export interface Props {
    history: any,
    className: string
}

export interface State {
    showPassword: boolean;
    password: string;
    snackBarStatus: any;
}

interface Values {
    userName: string,
    dateOfBirth: string,
    dateOfBirthTest: Date
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            flexBasis: 200,
        },
    }),
);

let messageAboutBrowser = "Make sure you're using Google Chrome or Microsoft Edge version 85 or above to get the full experience.";
export class ForgotPassword extends React.Component<Props, State> {


    constructor(props) {
        super(props);

        const stateObject = {
            msg: "",
            status: null,
            date: new Date(),
            variant: 'error'
        }
        // redirect to home if already logged in
        this.state = {
            showPassword: false,
            password: "",
            snackBarStatus: stateObject
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    toggleShow() {
        this.setState({ showPassword: this.state.showPassword });
    }

    componentDidMount() {
        this.setState({ password: this.state.password });
    }

    resetPassword(object: Values, { setSubmitting }) {
        if (object) {
            const value = { userName: object.userName, dateOfBirth: object.dateOfBirth };
            const stateObject = {
                msg: "",
                status: false,
                date: new Date(),
                variant: ''
            }
            userService.forgotPassword(value).then((data: any) => {
                if (data.success) {
                    stateObject.status = true;
                    stateObject.msg = data.message;
                    stateObject.variant = "success";
                } else {
                    //ERROR
                    stateObject.status = true;
                    stateObject.msg = data.message;
                    stateObject.variant = "error";
                }
                setSubmitting(false);
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 2000);
            }, error => {
                //ERROR
                console.log(error.message);
                setSubmitting(false);
                stateObject.msg = "There is a error on Server!";
                stateObject.variant = "error";
                this.setState({ snackBarStatus: stateObject });
                setTimeout(() => {
                    stateObject.status = false;
                    this.setState({ snackBarStatus: stateObject });
                }, 3000);
            });
        }
    }

    render() {
        // height of the TextField
        const height = 17
        // magic number which must be set appropriately for height
        const labelOffset = -6

        // get this from your form library, for instance in
        // react-final-form it's fieldProps.meta.active
        // or provide it yourself - see notes below
        const focused = ''
        // The first commit of Material-UI
        // const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        //     new Date(),
        // );

        const handleDateChange = (date: Date | null) => {
            // setSelectedDate(date);
        };

        return (
            <div className={this.props.className}>
                <Grid container>
                    <Grid item xs={4} className={"grid-left-logo"}>
                        <LayoutBlank className={this.props.className}></LayoutBlank>
                    </Grid>
                    <Grid item xs className={"grid-login"}>
                        <Grid className='top' style={{ margin: "50px 0px" }} >
                            <img src={logo} alt="Logo" />
                        </Grid>
                        <Wrapper title={""}>
                            <Formik<Values>
                                initialValues={{
                                    userName: "",
                                    dateOfBirth: "",
                                    dateOfBirthTest: new Date()
                                }}
                                //validationSchema={schema}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log(values);
                                    values.dateOfBirth = FormatDate.trasformDate(new Date(values.dateOfBirthTest));
                                    this.resetPassword(values, { setSubmitting });
                                }}
                                render={({ setFieldTouched, handleChange, submitForm, isSubmitting, values, errors }) => (
                                    // <Container>
                                    <Form className="">
                                        <Box style={{ padding: "15px 10px" }}>
                                            <Field
                                                type="text"
                                                name="userName"
                                                component={TextField}
                                                id="userName"
                                                label="Enter your username"
                                                className={"loginPage"}
                                                variant="outlined"
                                                fullWidth
                                                onChange={e => {
                                                    handleChange(e);
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        height,
                                                        ...(!focused && { top: `${labelOffset}px` }),
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        height,
                                                        padding: '0 14px',
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box style={{ padding: "15px 10px" }}>
                                            <Field
                                                type="date"
                                                name="dateOfBirthTest"
                                                component={TextField}
                                                id="dateOfBirthTest"
                                                label="Enter your date of birth"
                                                className={"loginPage"}
                                                variant="outlined"
                                                fullWidth
                                                onChange={e => {
                                                    handleChange(e);
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        height,
                                                        ...(!focused && { top: `${labelOffset}px` }),
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        height,
                                                        padding: '0 14px',
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box style={{ textAlign: "right", paddingRight: "20px" }}>
                                            <Box mt={"8px"} className={"forgot-password-section"}>
                                                <Link href="/login" className="color-red" > Login </Link>
                                            </Box>
                                        </Box>

                                        <Box style={{ padding: "15px 10px" }}>
                                            {isSubmitting && <LinearProgress />}
                                            <Button type="submit" className="btn rounded-radius red-fullwidth-button" variant="contained" disabled={isSubmitting}>Reset</Button>
                                        </Box>
                                    </Form>
                                )}
                            />
                        </Wrapper>
                        <Box style={{ margin: "15px 0px", textAlign: "center" }}>
                            <Typography>
                                <Link href="#">burrundalai.org</Link>
                                <Link href="#">privacy</Link>
                                <Link href="#">terms & conditions</Link>
                            </Typography>
                        </Box>

                        <Box style={{ margin: "auto", paddingTop: "30px", textAlign: "center", maxWidth: "50%" }}>
                            <Typography >{messageAboutBrowser}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {this.state.snackBarStatus.status ?
                    <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
            </div>
        )
    }
}

export default ForgotPassword; 