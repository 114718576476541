import React from 'react';
import './modalComponent.scss';
import { Fade, Modal, Box, Grid, Dialog } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import StoryTable from '../storyTable/storyTable';
import { Scrollbars } from 'react-custom-scrollbars';
import ButtonRound from '../buttonRound/buttonRound';

const close = require('../../../assets/images/close_icon.svg');


export interface Props {
    currentUser: any;
    setOpen: boolean,
    backGroundColor: any,
    childInfo: any;
    titleFirst?: string,
    titleSecond?: string,
    logo?: string,
    callback: any,
    data: Array<any>,
    titleCol: Array<any>,
}

export interface State {
    setOpen: boolean
}

class ModalStoryList extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = { setOpen: false };
    }

    async componentWillMount() {
        this.setState({ setOpen: this.props.setOpen });
    }

    render() {
        const { titleFirst, logo, titleSecond, callback, data, childInfo, currentUser, titleCol  } = this.props;
        return (
            <div className={"modal-component"}>
                <Dialog
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.setOpen}
                    onClose={callback}
                    className={"modal-component"}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.setOpen}>
                        <Box className={"modal-box background-C"}>
                            <Grid container className={logo} style={{ padding: "30px", height: "100%" }}>
                                <Grid xs={12} style={{ display: "flex", height: "25%" }}>
                                    <Grid xs={8} style={{ position: "relative" }}>
                                        <Box className={"modal-title"}>
                                            {titleFirst ?
                                                <Box>
                                                    <span>{titleFirst}</span>
                                                </Box> : null}
                                            {titleSecond ?
                                                <Box>
                                                    <span>{titleSecond}</span>
                                                </Box> : null}
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box style={{ float: "right" }}>
                                            <ButtonRound className={"button-close"} callback={callback} icon={close}></ButtonRound>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} style={{ paddingTop: "20px", height: "75%" }}>
                                    <Scrollbars style={{ width: "100%", height: "100%" }}>
                                        <StoryTable data={data} buttonAdd={true} currentUser={currentUser} childInfo={childInfo} titleCol={titleCol} isUsedForStar={titleSecond && titleSecond.toLowerCase().includes('star')?true:undefined}></StoryTable>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Dialog>
            </div>
        );
    }
}

export default ModalStoryList;