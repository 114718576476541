import React, {Component} from 'react';
import {Box,Container, Link, Grid,Typography } from '@material-ui/core'
import './layout.scss'
import logo  from '../../../assets/images/logo.svg';
  
export interface Props {
   className:string 
}
 
export interface State {
    
}
class LayoutBlank  extends React.Component<Props, State> {


    render() { 
       return (
      <div className={this.props.className}>
       <Grid container style={{height:'100vh'}} >
          <Grid  className="left-panel-background" item style={{width:'680px'}}>
          </Grid>
           <Grid  container
            className="layout-blank-right-panel"
            >
          </Grid>
       </Grid>
      </div>
    );
  }
}
 
export default LayoutBlank ;