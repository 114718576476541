import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface DanaProps {
  
}

export interface DanaState {
    height:number
    width:number
}


export class Dana extends React.Component<DanaProps, DanaState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:520})
      
    }

    getDanaChar(){
       const kid=<svg id="Layer_1" data-name="Layer 1"  viewBox="0 0 500 520"  height={this.state.height}>
                    <defs>
                        <clipPath id="clip-path">
                            <path d="M336.24,436.12c19.39-10-2.29-51.32-7.49-95.68-3.51-29.85-40.11-28.64-40.11-28.64l2.63,49.37C291.2,361.23,306.47,451.45,336.24,436.12Z" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-4">
                            <path d="M165.13,436.12c-19.38-10,2.3-51.32,7.5-95.68,3.51-29.85,40.11-28.64,40.11-28.64l-2.64,49.37C210.24,361.23,194.84,451.45,165.13,436.12Z" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-8">
                            <path d="M151.09,381.49s35-3.1,50.91,16.61,5.88-86.23,5.88-86.23l-43.36,6.48Z" fill="none"/>
                        </clipPath>
                        <clipPath id="clip-path-9">
                            <path d="M311.6,379.8a269.08,269.08,0,0,0-7.5-43.42c-4.59-16.68-9.11-23.63-9.11-23.63L284.65,309,278,286.62H223.27L216.65,309l-10.33,3.71s-4.52,6.89-9.11,23.63a269.4,269.4,0,0,0-7.5,43.42c-1.21,12.09-1.89,26.2-1.89,42.34,0,0,.07,1.89.2,5.14l62.87,3.57,62.53-3.57c.07-3.18.2-5.14.2-5.14C313.49,406,312.68,391.89,311.6,379.8Z" fill="none"/>
                        </clipPath>
                        <linearGradient id="linear-gradient" x1="250.08" y1="580.15" x2="250.08" y2="593.41" gradientTransform="matrix(1, 0, 0, -1, 0, 826.43)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#656565" stop-opacity="0"/>
                            <stop offset="1" stop-color="#161616" stop-opacity="0.3"/>
                        </linearGradient>
                    </defs>
                    <title>
                        500x520
                    </title>
                    <g id="Dana">
                        <g id="Dana-2" data-name="Dana">
                            <path d="M373.92,215c-1.68-4.39-6.07-9.72-10.8-9.52a13.31,13.31,0,0,0-4.59,1c-6.21-40.24-52.13-85.28-107.84-85.28s-101.7,45-108,85.21a15.45,15.45,0,0,0-4.59-1c-4.73-.2-9,5.2-10.8,9.52-6,15.4.47,27.15,1.62,28.9,3.17,5.54,7.83,6.42,9.32,6.28a17.81,17.81,0,0,0,8.1-2.9c8.91,29.1,32,47.94,72.32,54.16l-2.23,7.49-8.3,3c-9.93.81-32.75,5.2-35.52,28.43-5.2,44.23-26.95,85.63-7.5,95.69,8.91,4.52,16.41-.27,22.69-9.32V427l62.73,3.57L313.08,427v-.68c6.28,9.18,14,14.18,22.89,9.59,19.38-10-2.29-51.32-7.49-95.69-2.77-23.43-26-27.68-35.79-28.49l-8.31-3-2.22-7.5c40.51-6.21,63.61-25,72.52-54.22a19.26,19.26,0,0,0,8.1,2.9c1.49.07,6.08-.81,9.32-6.28C373.32,242.12,379.93,230.37,373.92,215Z" fill="#eaa390"/>
                            <path d="M284,428.63l-33.29,1.62-33.63-1.62-29.24-1.42v1.21c.68,9.12,5.2,65.84,15.33,82.93a10.87,10.87,0,0,0,4,4.39,30.76,30.76,0,0,0,9.39,3.37,64.49,64.49,0,0,0,10.8.88h.27c9.8,0,19.72-1.82,18.51-6.48-2.3-8.51-3.52-39.64-3.18-44.84h.27c2.3.2,4.59.27,7.09.27h.27q3.34,0,6.49-.2l1-.07c.27,5.2-1,36.33-3.18,44.84-1.28,4.72,8.72,6.48,18.51,6.48h.27a55.26,55.26,0,0,0,10.6-.81,32.11,32.11,0,0,0,9.59-3.51,12.94,12.94,0,0,0,4.12-4.39c8.78-14.79,13.3-58.95,14.79-76.64.06-2.91.2-5.54.27-7.5Z" fill="#161616"/>
                            <path d="M197,336.32a270.85,270.85,0,0,0-7.49,43.35H311.39a268.28,268.28,0,0,0-7.49-43.35Z" fill="#161616"/>
                            <path d="M338.34,234.49c-2.43,1.48-3.58,1.62-5.34,1.41,2.77-.87,5.61-3.51,5.68-6.68.06-1.28-.21-1-.81-.27-1.63,1.89-4.19,1.62-5,1.48-1.62-15.53-13-21.13-21.14-21.94-9.11-.81-14.38,2.43-15.39,3.78a2.7,2.7,0,0,0-.07,2.77,21.7,21.7,0,0,1,29,31.4l-.07.2h4.39a17.52,17.52,0,0,0,2.7-5.33c4.32-1.08,6.08-3.92,6.62-5.81C339.28,234.15,339.28,233.88,338.34,234.49Z" fill="#d18779"/>
                            <path d="M308,211.8a21.69,21.69,0,0,0-17.15,35h34.37l.07-.2a21.72,21.72,0,0,0-4.19-30.39A21.34,21.34,0,0,0,308,211.8Z" fill="#fff"/>
                            <path d="M308.49,229.76a5,5,0,0,1-4.93-4.93h0a5,5,0,0,1,4.93-4.93h1.42a12.64,12.64,0,1,0,3.71,17.49,12.71,12.71,0,0,0,2-6.89v-.81l-7.16.07Z" fill="#010101"/>
                            <path d="M163,234.76c2.43,1.62,3.58,1.62,5.34,1.41-2.77-.87-5.61-3.51-5.68-6.68-.06-1.28.21-1,.81-.27,1.62,1.89,4.19,1.62,5,1.48,1.49-15.53,13-21.13,21.14-21.94,9.11-.81,14.38,2.43,15.39,3.78a2.72,2.72,0,0,1,.07,2.77,21.7,21.7,0,0,0-29,31.4l.07.2h-4.52a17.08,17.08,0,0,1-2.7-5.33c-4.33-1.08-6.08-3.92-6.62-5.81C162,234.49,162,234.08,163,234.76Z" fill="#d18779"/>
                            <path d="M193.29,211.93A21.73,21.73,0,0,1,215,233.68a22,22,0,0,1-4.52,13.3h-34.3l-.07-.2a21.76,21.76,0,0,1,17.15-34.85Z" fill="#fff"/>
                            <path d="M198.35,218.14a11.68,11.68,0,0,0-6.88,2.1h1.41a4.93,4.93,0,1,1,0,9.86h-7.09v.81a12.7,12.7,0,0,0,25.39,0,13,13,0,0,0-12.83-12.77Z" fill="#010101"/>
                            <path d="M326.93,256.5c-6.89,0-12.43,3.51-12.43,7.9s5.61,7.9,12.43,7.9,12.42-3.51,12.42-7.9S333.68,256.5,326.93,256.5Z" fill="#d18779" opacity="0.2" />
                            <path d="M174.38,256.5c-6.89,0-12.42,3.51-12.42,7.9s5.6,7.9,12.42,7.9,12.43-3.51,12.43-7.9S181.34,256.5,174.38,256.5Z" fill="#d18779" opacity="0.2" />
                            <path d="M240.15,245.36s4.39-7,10.54-7.23,9.32,7.23,9.32,7.23A36.8,36.8,0,0,1,240.15,245.36Z" fill="url(#linear-gradient)"/>
                            <path d="M373.92,215c-1.68-4.39-6.07-9.72-10.8-9.52a13.31,13.31,0,0,0-4.59,1,58.28,58.28,0,0,1,.67,9.12,109.42,109.42,0,0,1-4.32,31.8,19.26,19.26,0,0,0,8.11,2.91c1.48.06,6.07-.81,9.31-6.28A34,34,0,0,0,373.92,215Z" fill="#2b2c2b" opacity="0.12" />
                            <path d="M142.78,206.46a14.92,14.92,0,0,0-4.59-1c-4.73-.2-9,5.2-10.81,9.52-6,15.4.48,27.15,1.62,28.9,3.18,5.54,7.84,6.42,9.32,6.28a17.81,17.81,0,0,0,8.11-2.9A110.49,110.49,0,0,1,142,215.44,50.39,50.39,0,0,1,142.78,206.46Z" fill="#2b2c2b" opacity="0.12" />
                            <path d="M222.87,254.81s.2.07.67.2a7,7,0,0,1,.81.21,5.69,5.69,0,0,0,1.08.27c.41.06.88.2,1.42.27a12.54,12.54,0,0,1,1.62.2,17,17,0,0,0,1.89,0h1c.33,0,.67-.07,1.08-.07.67-.07,1.48-.2,2.22-.27s1.62-.27,2.44-.4,1.62-.41,2.43-.48,1.62-.27,2.49-.47,1.69-.27,2.5-.4a7.14,7.14,0,0,1,1.22-.07,7.43,7.43,0,0,1,1.28-.07c.81-.07,1.69-.07,2.5-.07h2.57a17.43,17.43,0,0,1,2.63.21c.88.06,1.69.2,2.63.27a17.41,17.41,0,0,1,2.64.47l2.63.61c1.69.4,3.51,1,5.13,1.28s3.18.67,4.73,1c.67.07,1.42.27,2.09.27a15.38,15.38,0,0,1,1.89.21c1.22.06,2.23.06,3.11.06h2.7l.07.48a4.17,4.17,0,0,0-.61.27c-.4.2-1,.47-1.82.87s-1.69.88-2.77,1.49c-.47.27-1.08.61-1.69,1a14,14,0,0,0-1.89,1.08c-1.42.68-2.77,1.62-4.39,2.5a27.93,27.93,0,0,1-2.5,1.28c-.88.41-1.69.88-2.63,1.29-.47.2-.88.4-1.42.6s-.88.41-1.42.61c-1,.27-2,.61-3,.81s-2,.48-3.11.61-2.09.2-3.1.2a24.61,24.61,0,0,1-3.11-.06,22,22,0,0,1-3.1-.48c-.48-.06-1-.2-1.49-.27a6.26,6.26,0,0,1-1.49-.47,19.12,19.12,0,0,1-2.9-1.08c-.47-.2-.88-.47-1.42-.68a4.11,4.11,0,0,1-1.28-.81,19,19,0,0,1-2.5-1.62c-.81-.6-1.48-1.08-2.29-1.62a22.87,22.87,0,0,1-2-1.62,9.26,9.26,0,0,1-.88-.81c-.27-.34-.61-.47-.88-.81a8.25,8.25,0,0,0-.81-.67,2.28,2.28,0,0,1-.67-.68c-.47-.47-.88-.88-1.28-1.21l-1.08-1.09a10.91,10.91,0,0,1-.82-.87,5.92,5.92,0,0,0-.6-.68,4.49,4.49,0,0,0-.48-.61Z" fill="#d18779"/>
                            <path d="M246.57,274.39s8.91,1.69,12.69-.4C255.55,278.31,246.57,274.39,246.57,274.39Z" fill="#ba8863"/>
                            <path d="M130.56,240.9s-4.59.47-4.12,5.2c.47,4.32,5.6,3.78,6.08,2.7l-.41-.67s-4,1.48-4.39-2.1a3,3,0,0,1,3.18-3.58Z" fill="#acabab"/>
                            <path d="M371.16,240.9s4.59.47,4.12,5.2c-.48,4.32-5.61,3.78-6.08,2.7l.4-.67s4,1.48,4.39-2.1a3,3,0,0,0-3.17-3.58Z" fill="#acabab"/>
                            <path d="M130,214.36s-2.09-2.7-4.59-.61a3,3,0,0,0,.81,5.14l.2-.81s-2.5-1.89-.47-3.38a2.05,2.05,0,0,1,3.31.61Z" fill="#acabab"/>
                            <circle cx="129.95" cy="236.38" r="1.62" fill="#ddd"/>
                            <path d="M129.48,236.71a1,1,0,0,1,1-1h.2a1,1,0,0,0-.88-.47,1,1,0,0,0-.2,2A.92.92,0,0,1,129.48,236.71Z" fill="#fff"/>
                            <circle cx="370.35" cy="236.38" r="1.62" fill="#ddd"/>
                            <path d="M370.82,236.71a1,1,0,0,0-1-1h-.21a1.07,1.07,0,0,1,.88-.47,1,1,0,0,1,1,1,1,1,0,0,1-.81,1A.87.87,0,0,0,370.82,236.71Z" fill="#fff"/>
                            <path d="M141.36,258.12s.47-1.28.41-5.33l-.61-.21a22.35,22.35,0,0,1-.27,5.13C140.69,257.78,141.36,258.12,141.36,258.12Z" fill="#8f8f8f"/>
                            <path d="M129.75,252.92c2,5.2,7.83,8.58,11.81.2.07-.2.21-.4.27-.6s.21-.41.27-.61c.21-.41.27-.81.48-1.29h0c.06-.27-.21-.4-.41-.2h0a14.73,14.73,0,0,1-4.39,3.38c-.06.07-.2.07-.27.07a2.84,2.84,0,0,1-.67.2h-.27a2.76,2.76,0,0,1-2.64-1.22,4.5,4.5,0,0,1-.67-1.28,4.82,4.82,0,0,1-.27-3.71c.81-1.89,3.51-2.91,7.43-3.58.47-.07.6-.61.06-.81C129.48,240.36,127.65,247.59,129.75,252.92Z" fill="#ddd"/>
                            <path d="M138,244.61c-3-.27-5.2.61-6.42,3.38-.88,2-.4,3.51.81,5.67.07.07-.07.27-.2.21-3.31-2.44-4.12-11.62,5.87-9.39,0,0,0,.13-.06.13Z" fill="#fff"/>
                            <path d="M133.8,254.27c3,4.19,7.09-2.23,7.63-1.89.2.07.2.41.2.68.07-.21.2-.41.27-.61s.2-.41.27-.61c.2-.41.27-.81.47-1.28h0c.07-.27-.2-.41-.4-.21h0a14.73,14.73,0,0,1-4.39,3.38.39.39,0,0,1-.27.07,2.44,2.44,0,0,1-.67.2h-.28a2.75,2.75,0,0,1-2.63-1.21,4.43,4.43,0,0,1-.67-1.29,4.82,4.82,0,0,1-.27-3.71C132.11,249.41,131.71,251.5,133.8,254.27Z" fill="#acabab"/>
                            <path d="M141.5,259.2s.06-1.62-.61-1.62-.61,1.42-.61,1.42h-.81s0-2.3,1.49-2.23c1.28.07,1.41,2.3,1.41,2.3Z" fill="#ddd"/>
                            <path d="M145,262.71a9.26,9.26,0,0,0-.88-1.82,3.68,3.68,0,0,0-3.31-2.09,3.88,3.88,0,0,0-3.58,1.82,3.93,3.93,0,0,1-4.72,1.69,3.78,3.78,0,0,1-2-2s-1.28,1.62.2,5c1.62,3.71,3.78,4.72,7.09,4.79a6.17,6.17,0,0,0,6.49-5.13A6.44,6.44,0,0,1,145,262.71Z" fill="#acabab"/>
                            <circle cx="142.51" cy="261.97" r="0.41" fill="#fff"/>
                            <path d="M141.29,263.59s-1.89,3.11-5.6.88c0,0,1.08,2.63,3.11,2.23,0,0-3.11,1.28-4.93-1.42,0,0,1.08,4.32,5.2,2.7C140.48,267.37,141.16,265.68,141.29,263.59Z" fill="#fff"/>
                            <path d="M143.46,259.27c-1.49-1.08-4.12-.47-4.8,0a3.31,3.31,0,0,1,2.09-.41,3.35,3.35,0,0,1,3.31,2.1,7.51,7.51,0,0,1,.88,1.82l.68-.27A7.31,7.31,0,0,0,143.46,259.27Z" fill="#ddd"/>
                            <path d="M132.45,262.31a4,4,0,0,0,4.39-1.22,4.74,4.74,0,0,1-3.51.88,3.21,3.21,0,0,1-2.43-2,3.24,3.24,0,0,0-.48.21A3.35,3.35,0,0,0,132.45,262.31Z" fill="#ddd"/>
                            <path d="M145,262.71a7.67,7.67,0,0,0-.68,2.23,6.17,6.17,0,0,1-5.67,5.13c4.32-.07,5.88-2.9,6.08-4.72a10.16,10.16,0,0,1,1-2.91Z" fill="#8f8f8f"/>
                            <path d="M142.31,261.7v-.2a.35.35,0,0,0-.27.4.44.44,0,0,0,.4.41c.2,0,.27-.07.41-.27h-.21C142.51,262.17,142.31,262,142.31,261.7Z" fill="#8f8f8f"/>
                            <path d="M139.61,254.68s-.27,2.22.87,3.1a2.2,2.2,0,0,0,.88.27s-.88-.07-1.28-4.12Z" fill="#ddd"/>
                            <path d="M362.65,258.86s.47-1.28.4-5.33l-.6-.2a22.36,22.36,0,0,1-.27,5.13C361.91,258.59,362.65,258.86,362.65,258.86Z" fill="#8f8f8f"/>
                            <path d="M371.83,252.92c-2,5.2-7.83,8.58-11.82.2-.06-.2-.2-.4-.27-.6s-.2-.41-.27-.61c-.2-.41-.27-.81-.47-1.29h0c-.07-.27.2-.4.41-.2h0a14.61,14.61,0,0,0,4.39,3.38c.06.07.2.07.27.07a2.84,2.84,0,0,0,.67.2H365a2.74,2.74,0,0,0,2.63-1.22,4.5,4.5,0,0,0,.68-1.28,4.82,4.82,0,0,0,.27-3.71c-.81-1.89-3.51-2.91-7.43-3.58-.47-.07-.61-.61-.07-.81C372.24,240.36,373.92,247.59,371.83,252.92Z" fill="#ddd"/>
                            <path d="M363.66,244.61c3-.27,5.2.61,6.42,3.38.87,2,.4,3.51-.81,5.67-.07.07.06.27.2.21,3.31-2.44,4.12-11.62-5.88-9.39-.06,0-.06.13.07.13Z" fill="#fff"/>
                            <path d="M367.85,254.27c-3,4.19-7.09-2.23-7.63-1.89-.21.07-.21.41-.21.68-.06-.21-.2-.41-.27-.61s-.2-.41-.27-.61c-.2-.41-.27-.81-.47-1.28h0c-.07-.27.2-.41.41-.21h0a14.61,14.61,0,0,0,4.39,3.38c.06.07.2.07.27.07a2.44,2.44,0,0,0,.67.2H365a2.75,2.75,0,0,0,2.63-1.21,4.44,4.44,0,0,0,.68-1.29,4.82,4.82,0,0,0,.27-3.71C369.6,249.41,369.94,251.5,367.85,254.27Z" fill="#acabab"/>
                            <path d="M362.72,260s.06-1.62-.61-1.62-.61,1.42-.61,1.42h-.81s0-2.3,1.49-2.23c1.28.07,1.41,2.3,1.41,2.3Z" fill="#ddd"/>
                            <path d="M366.23,263.52a10.09,10.09,0,0,0-.88-1.82,3.68,3.68,0,0,0-3.31-2.09,3.87,3.87,0,0,0-3.58,1.82,3.94,3.94,0,0,1-4.73,1.69,3.79,3.79,0,0,1-2-2s-1.28,1.62.2,5c1.62,3.71,3.78,4.73,7.09,4.79a6.16,6.16,0,0,0,6.48-5.13A8.62,8.62,0,0,1,366.23,263.52Z" fill="#acabab"/>
                            <circle cx="363.73" cy="262.78" r="0.41" fill="#fff"/>
                            <path d="M362.51,264.4s-1.89,3.11-5.6.88c0,0,1.08,2.63,3.1,2.23a4.14,4.14,0,0,1-4.93-1.42s1.09,4.32,5.2,2.7C361.7,268.18,362.45,266.49,362.51,264.4Z" fill="#fff"/>
                            <path d="M364.74,260.08c-1.48-1.08-4.12-.47-4.79,0a3.31,3.31,0,0,1,2.09-.41,3.36,3.36,0,0,1,3.31,2.1,8,8,0,0,1,.88,1.82l.67-.27A8.12,8.12,0,0,0,364.74,260.08Z" fill="#ddd"/>
                            <path d="M353.73,263.12a4,4,0,0,0,4.39-1.22,4.72,4.72,0,0,1-3.51.88,3.23,3.23,0,0,1-2.43-2,3.07,3.07,0,0,0-.47.21A3.17,3.17,0,0,0,353.73,263.12Z" fill="#ddd"/>
                            <path d="M366.23,263.52a7.32,7.32,0,0,0-.68,2.23,6.17,6.17,0,0,1-5.67,5.13c4.32-.06,5.87-2.9,6.08-4.72a10.16,10.16,0,0,1,1-2.91Z" fill="#8f8f8f"/>
                            <path d="M363.53,262.58v-.21a.37.37,0,0,0-.27.41.43.43,0,0,0,.4.4c.2,0,.27-.06.41-.27h-.21C363.73,263,363.53,262.78,363.53,262.58Z" fill="#8f8f8f"/>
                            <path d="M360.62,253.87s-.07,3.91,1.08,4.72a2.2,2.2,0,0,0,.88.27s-1.28-.67-1.42-5.2Z" fill="#ddd"/>
                            <path d="M327.33,137.92c-13.23,5-18.1,19-19.38,23.91,57.26.67,48.62,80,48.62,80,13-54,.88-88.06-7.83-106.7C340.3,135.22,332.4,135.9,327.33,137.92Z" fill="#aba6a5"/>
                            <path d="M145.08,243.6s-.88-55.91,43.35-78.46c0,0,21.94,14.51,61.45,14.51s61.51-17.08,84.74-31.33,40.66-20,40.66-20-15.33-2.9-28.43,0a161.72,161.72,0,0,1,42.54-17.89s-17.42-14.38-50.92-16a147.57,147.57,0,0,1,32.62-7.09S344.15,76.27,308,80.52a95.5,95.5,0,0,1,27.68-10s-30.38-6.42-68.47,7.9c0,0,7.5-6.41,29.71-14.32,0,0-35.92-.47-67.73,19,0,0,11.08-10,21.95-16.34,0,0-27.15,2.7-48.75,16.13a27.54,27.54,0,0,1,4.72-6.07s-8.24,4.18-17.69,14a32.3,32.3,0,0,1,2.3-6.28s-8.51,4.52-12.7,23.5c0,0-17.29,1.28-28.49,15.59a31,31,0,0,1,9.72-3.91C160,120,125.09,160.41,145.08,243.6Z" fill="#e5e5e5"/>
                            <path d="M387.23,108.89a120.91,120.91,0,0,0-37,3.3,219.6,219.6,0,0,0-38.69,14s9.32-9.18,12.69-14.52c0,0-35.11,17.43-60,22.63A103.33,103.33,0,0,0,281.48,120s-42.14,8.24-75.83,5.6c0,0,10.53-2.7,16.34-7.63a167.22,167.22,0,0,1-22.08,3.38c-10.2.81-16.41,2-19.52,7.23,0,0-8.37-2.91-14.52-.48-4,1.62-10.19,4.59-18.23,14.79C140,163,133.73,196.4,145.14,243.4c0,0-.87-55.85,43.36-78.33,0,0,22.14,15.6,61.65,15.6s62.39-17.36,85.62-31.54,39.57-21,39.57-21-15.33-2.9-28.43,0a161.5,161.5,0,0,1,42.55-17.89C389.46,110.51,388.65,109.9,387.23,108.89Z" fill="#aba6a5"/>
                            <path d="M279.79,90.32a111.48,111.48,0,0,1-18,11.41c-1.42.67-16.2,6.28-16.2,6.89,14.51-.68,29.3-9.39,37.61-21-1.08.81-2.09,1.69-3.18,2.5C279.86,90.32,279.79,90.32,279.79,90.32Z" fill="#aba6a5"/>
                            <path d="M329,98.08h-1.89a64.63,64.63,0,0,0-38.36,14.79A416.08,416.08,0,0,1,329,98.08Z" fill="#aba6a5"/>
                            <path d="M338,139.41h-1.89a64.57,64.57,0,0,0-38.36,14.79A419.29,419.29,0,0,1,338,139.41Z" fill="#845b9f"/>
                            <path d="M212.54,97.61c-6.08,4.52-12.63,8.37-18.84,12.83,10.73-2.64,21.13-10.13,27.61-18.84-3,1.82-5.87,3.91-8.77,6C211.86,98.22,212.13,97.88,212.54,97.61Z" fill="#aba6a5"/>
                            <path d="M229.42,152.91c-6.08,4.53-12.63,8.38-18.84,12.83,10.73-2.63,21.13-10.13,27.62-18.84-3,1.83-5.88,3.92-8.78,6C228.74,153.45,229.15,153.25,229.42,152.91Z" fill="#845b9f"/>
                            <path d="M247,163c10.94-1.42,31.74-8.78,39.1-16.81-3.17,1.48-16.14,6.08-19.24,7.9C266.08,154.6,253.59,159.33,247,163Z" fill="#845b9f"/>
                            <path d="M294.11,202.14c1.69-.27,3.17-.81,4.59-1.08,1.22-.27,2.3-.61,3.38-.88s2.22-.47,3.3-.61a44.32,44.32,0,0,1,6.42-.67,70.46,70.46,0,0,1,10.94.27c6.28.67,10.2,1.82,10.6.88.2-.41-.47-1.29-2.09-2.5a26.43,26.43,0,0,0-7.5-3.38,45.89,45.89,0,0,0-12.09-2,53.87,53.87,0,0,0-7.29.27,35.34,35.34,0,0,0-3.78.6c-1.28.27-2.63.61-3.78.88a40.06,40.06,0,0,1-3.92,1c-.2,0-.2.48-.2,1.22s0,1.62.07,2.5C293,200.59,293.37,202.28,294.11,202.14Z" fill="#aba6a5"/>
                            <path d="M210,202.34c-1.69-.27-3.18-.81-4.6-1.08-1.21-.27-2.29-.61-3.37-.88s-2.23-.47-3.31-.6a42.87,42.87,0,0,0-6.42-.68,70.33,70.33,0,0,0-10.93.27c-6.28.68-10.2,1.82-10.61.88-.2-.41.48-1.28,2.1-2.5a26.48,26.48,0,0,1,7.49-3.38,45.89,45.89,0,0,1,12.09-2,53.75,53.75,0,0,1,7.29.27,35,35,0,0,1,3.78.61c1.29.27,2.64.6,3.79.87,1.41.41,2.76.81,3.91,1,.2,0,.2.47.2,1.21s0,1.62-.06,2.5C211.12,200.79,210.71,202.48,210,202.34Z" fill="#aba6a5"/>
                            <path d="M291.61,249.48c.07-.07,23.3,4.18,33.43-1.08C315.38,259.47,291.61,249.48,291.61,249.48Z" fill="#d18779" opacity="0.2" />
                            <path d="M209.7,249.48c-.07-.07-23.3,4.18-33.43-1.08C185.93,259.47,209.7,249.48,209.7,249.48Z" fill="#d18779" opacity="0.2" />
                            <g clip-path="url(#clip-path)">
                                <path d="M350,381.49s-35-3.1-50.91,16.61-5.88-86.23-5.88-86.23l43.35,6.48Z" fill="#e85757"/>
                            </g>
                            <g clip-path="url(#clip-path)">
                                <path d="M329.22,374.4c1.69,5.34-6.21,5.67-6.48,5-.41-.88,1.08-1.22,1.49-1.28a2.57,2.57,0,0,0,.67-.27c-1.89.06-3.78.06-3.71-.61,0-.27.6-.61,1.28-.81-.88-.07-1.69-.07-2.63-.21s-2-.4-2-.81,1.08-.87,2.09-1c.68-.07,1.42-.2,2-.27a16.7,16.7,0,0,1-4.8-.47c-1.08-.27-3.17-.68-3.3-1.22s1.28-.6,2-.81,1.22-.2,1.82-.27c-2.43-.27-4.79-.6-7.22-.88-.47-.06-1.22-.4-1-.81.06-.2.27-.27.4-.4a5.53,5.53,0,0,1,2.1-.41c-1.63-.81-4.39-1.68-4.73-2.49s3.17.06,4.93.81c-.48-.27-1-.68-.81-.88s1.62.2,2.29.47a5.24,5.24,0,0,1,1.62.88c-.4-.47-.6-.88-.4-1,.4-.27,1.48.4,2.09.81a7.13,7.13,0,0,1,1.62,1.28c-.61-.61-1.08-1.22-.88-1.28s1.22.06,1.69.4a9,9,0,0,1,3,2.43c-.27-.47-.4-1-.2-1.21s1,0,1.82.67a22.81,22.81,0,0,1,2.1,2.23c-.47-.88-.81-1.82-.47-2C326.18,369.13,328.28,371.63,329.22,374.4Z" fill="#7cced0"/>
                                <path d="M324.43,375.75a4.55,4.55,0,0,1-1.89-.47c-.27-.07-.07-.47.06-.41a2,2,0,0,0,.68.21,6.08,6.08,0,0,0,1.22.2,19,19,0,0,0,2.7-.2c-5.54-3-9.93-4.93-9.93-5a.37.37,0,0,1,.27-.68c.07,0,2.9,1.22,6.82,3.31a1.32,1.32,0,0,0-.41-.81,4,4,0,0,0-.67-.81.19.19,0,1,1,.27-.27,5.77,5.77,0,0,1,.81,1,1.2,1.2,0,0,1,.27,1.08c1.49.81,3.11,1.62,4.73,2.63a3.45,3.45,0,0,1-.07.81c-.47-.27-1.08-.61-1.62-.88A16.33,16.33,0,0,1,324.43,375.75Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path)">
                                <path d="M340.63,343.27c-.06-.2-.2-.4-.27-.61v-.06a.06.06,0,0,1-.06-.07,9.4,9.4,0,0,0-2.43-3.78c1.82-.21,9-3.92,9.11-5s-1.48-4.19-7.7-4.19a9.84,9.84,0,0,0-5,2.23A15.32,15.32,0,0,0,334,320c-1,1.83-3,2.71-4.93,3.58a7.2,7.2,0,0,0-3.11,6.08c-1.21-1.08-.47-4.59-3.51-6-1.89-.88-5.54-.61-7-1.28,1.08.67.67,7.29,1.68,9a13.79,13.79,0,0,0,5.14,4c-1.69.07-5.88-.4-8.58,1.89-2,1.69-3.11,4.33-5,6.08,1.83-.81,3.92-.4,5.81.07a29.47,29.47,0,0,0,5.67,1.62c2,.2,3.38-1.28,4.53-3.11-.41,1.69-2.7,5.41-2.77,8.71-.07,5.14,3.11,7.23,3.78,6.62s3.58-3.1,4.39-5.6.47-6.42,1-8.24c.07,1.69,1.83,5.4,3.18,6.21a9.62,9.62,0,0,0,4.79,1.08,17.83,17.83,0,0,0,7.23-1.48A16.31,16.31,0,0,1,340.63,343.27Z" fill="#efd3e6"/>
                                <path d="M338.2,338.61a10.81,10.81,0,0,0-4.39-2.77,1.63,1.63,0,0,0,.27-.4c1.89-2.7,1.69-6.69,1.29-6.89s-3.18.88-4.73,2.43a8.75,8.75,0,0,0-1.28,1.69,5.57,5.57,0,0,0-.27-1.69c-.68-2.5-4.32-4.32-4.73-4a9.41,9.41,0,0,0-1.62,6.62,3.45,3.45,0,0,0-2.5,1c-2.7,2.5-4.52,2-4.52,2,.2,1.62,2.22,2.91,4.39,4a6.59,6.59,0,0,0,5.87.2,8.07,8.07,0,0,0,.61,4.59c1.08,2.43,4.93,3.51,5.2,3.18s1.82-4.53.4-7.23a5,5,0,0,0,1.08.07C336,341.65,338.41,339,338.2,338.61Z" fill="#e57b70"/>
                                <path d="M329.09,335.37c.06-.2,0-.61-.21-.61s-.4,0-.6.27,0,.61.2.61.4,0,.61-.27Z" fill="#231f20"/>
                                <path d="M329.83,336.72a.58.58,0,0,0,.4-.47.91.91,0,0,0,0-.61.67.67,0,0,0-1-.07.58.58,0,0,0-.4.48.88.88,0,0,0,0,.6c.2.27.6.27,1,.07Z" fill="#231f20"/>
                                <path d="M326.93,337.53c.2,0,.4-.27.4-.47a.45.45,0,0,0-.47-.41.51.51,0,0,0-.41.48c.07.33.14.4.48.4Z" fill="#231f20"/>
                                <path d="M328.28,337.94c.2-.48.2-.88-.27-1a.74.74,0,0,0-.81.81c-.07.41,0,.88.4.88S328.21,338.27,328.28,337.94Z" fill="#231f20"/>
                                <path d="M329.49,338.34h.27a1.13,1.13,0,0,0,.61-.2.44.44,0,0,0,.07-.61,1.21,1.21,0,0,0-.41-.4.84.84,0,0,0-.81-.07.74.74,0,0,0-.4.2l-.27.27v.41c.06.06.06.2.27.27a2.65,2.65,0,0,0,.67.13Z" fill="#231f20"/>
                                <path d="M327.53,336.65c.21,0,.27-.06.41-.27a1.76,1.76,0,0,0,.07-.6,1,1,0,0,0-.21-.61.73.73,0,0,0-.4-.2c-.2,0-.27.06-.41.27a2,2,0,0,0-.06.6,1,1,0,0,0,.2.61c.13.2.27.27.4.2Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-4)">
                                <path d="M151.09,381.49s35-3.1,50.91,16.61,5.88-86.23,5.88-86.23l-43.36,6.48Z" fill="#e85757"/>
                            </g>
                            <g clip-path="url(#clip-path-4)">
                                <path d="M193.7,328.82a3,3,0,0,0,1.82.68,6.8,6.8,0,0,1-2.7,2.29c.2.27.47.27.81.2a4,4,0,0,0,1.82-.67c-.47,1-.88,2-1.21,3,0,.07-.07.27.06.41h.27a3.6,3.6,0,0,0,1.62-1.42,4.07,4.07,0,0,0,1.22,4.52,8.25,8.25,0,0,1,1.21-3,7.09,7.09,0,0,1,1.09,2,9.5,9.5,0,0,1,1-3,.22.22,0,0,1,.2-.21c.07,0,.07,0,.2.07.48.41,1,.81,1.49,1.28,0-1-.07-2.09-.07-3.1.68.06,1.08.67,1.82.87h.21c.2-.06.06-.27.06-.47a9.28,9.28,0,0,0-.81-1.89,12.82,12.82,0,0,1-.67-7.29l-.07-.07c-1.82,1.22-3.71,2.43-5.54,3.58A19.76,19.76,0,0,1,193.7,328.82Z" fill="#7cced0"/>
                                <path d="M198.56,329.36a8.87,8.87,0,0,0-1.22,4.59,19.12,19.12,0,0,1,1.69-4.32c1.28-2.23,3-3.51,2.77-3.71A7.8,7.8,0,0,0,198.56,329.36Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-4)">
                                <path d="M189,350.83c-.41.41-.21.81-1.49,2.64-1.08,1.42-4,.88-4.32.07-.07-.21-.88,2.09-1.49,3a5.1,5.1,0,0,1-2.5,1.82c-.27-.07-.67-2.43.07-4.12.47-1,1.62-2.23,2.5-2.29a3.16,3.16,0,0,1-2-1.22,5.46,5.46,0,0,1-.47-4.32,3.85,3.85,0,0,1,3.38,1.42,4.67,4.67,0,0,1,.81,2.63A4.29,4.29,0,0,1,187,350C188.43,350.36,189.44,350.43,189,350.83Z" fill="#e57b70"/>
                                <path d="M174.52,353.33s1.28,0,2.5-2.09c1-1.82,4.32-1.62,4.79-.81.07.2.61-2.43,1.22-3.58a6.07,6.07,0,0,1,2.49-2.43c.27,0,1.22,2.5.48,4.59-.41,1.22-1.42,2.77-2.43,3a3.61,3.61,0,0,1,2.43,1c1.48,1.41,1.42,4.38,1.21,4.79s-2.77.27-4-1A4.69,4.69,0,0,1,182,354a4.64,4.64,0,0,1-3.92,1C176.54,354.75,175,354.28,174.52,353.33Z" fill="#efd3e6"/>
                                <path d="M181.41,351.24c-.07-.2-.27-.2-.48-.07a.61.61,0,0,0,0,.47c.07.21.27.21.48.07a.33.33,0,0,0,0-.47Z" fill="#231f20"/>
                                <path d="M182.49,351.24c0-.2-.07-.27-.07-.47s-.2-.21-.27-.21c-.27,0-.47.21-.47.61,0,.2.06.27.06.47s.21.21.27.21a.55.55,0,0,0,.48-.61Z" fill="#231f20"/>
                                <path d="M182,353.33c.06-.06,0-.4-.21-.4a.39.39,0,0,0-.4.07c-.07.06,0,.4.2.4s.41.14.41-.07Z" fill="#231f20"/>
                                <path d="M182.69,352.66c-.2-.27-.47-.41-.68-.2s-.06.6.21.81a.45.45,0,0,0,.67.06C183,353.06,182.82,352.86,182.69,352.66Z" fill="#231f20"/>
                                <path d="M183.43,352.05c0-.07.07-.07.07-.2s.07-.27.07-.41-.07-.27-.27-.27a1,1,0,0,0-.41.07c-.2.07-.27.27-.4.47V352a.43.43,0,0,0,.06.27c.07.07.07.07.27.07s.21,0,.27-.07.21-.06.34-.2Z" fill="#231f20"/>
                                <path d="M181.61,352.66v-.41l-.27-.27a.83.83,0,0,0-.41-.06.36.36,0,0,0-.27.2v.4l.27.27c.07.07.27.07.41.07s.27-.13.27-.2Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-4)">
                                <g clip-path="url(#clip-path-8)">
                                    <path d="M169.52,368.93c-.07.07-.27.07-.4.2h-.07v.07a7.49,7.49,0,0,0-2.43,1.42c-.07-1.08-2.23-5.54-2.91-5.6s-2.5.87-2.7,4.59a6.49,6.49,0,0,0,1.22,3.1,9,9,0,0,0-7.23,0,5.94,5.94,0,0,1,2.1,3,4.52,4.52,0,0,0,3.71,2c-.68.68-2.77.2-3.71,2-.48,1.21-.48,3.3-.88,4.18.47-.6,4.39-.27,5.53-.88a8.1,8.1,0,0,0,2.5-3c.07,1-.27,3.58,1,5.34,1,1.28,2.49,1.89,3.58,3.1a5.19,5.19,0,0,1,.2-3.51,16,16,0,0,0,1-3.37,3.26,3.26,0,0,0-1.82-2.77c1,.27,3.17,1.69,5.2,1.82,3.1.07,4.39-1.69,4.12-2.23a8.45,8.45,0,0,0-3.31-2.7c-1.49-.47-3.92-.4-5-.67,1,0,3.31-1,3.78-1.83a6.13,6.13,0,0,0,.68-2.9,11.13,11.13,0,0,0-.81-4.39A7.73,7.73,0,0,1,169.52,368.93Z" fill="#efd3e6"/>
                                    <path d="M166.68,370.35a7,7,0,0,0-1.82,2.63l-.2-.2c-1.62-1.21-4-1.21-4.12-.88a5.23,5.23,0,0,0,1.42,2.91,7.83,7.83,0,0,0,1,.81,6.11,6.11,0,0,0-1,.06c-1.49.41-2.7,2.5-2.5,2.77a6,6,0,0,0,4,1.08,3.47,3.47,0,0,0,.48,1.62c1.48,1.69,1.08,2.77,1.08,2.77,1-.06,1.82-1.28,2.49-2.63a4.1,4.1,0,0,0,.21-3.58,5.55,5.55,0,0,0,2.77-.27,3.85,3.85,0,0,0,2-3.11,6.25,6.25,0,0,0-4.39-.4c0-.2.07-.41.07-.61A3.06,3.06,0,0,0,166.68,370.35Z" fill="#e57b70"/>
                                    <path d="M164.52,375.89c-.06-.07-.27,0-.4.06s0,.27.2.27.27,0,.41-.06,0-.21-.21-.27Z" fill="#231f20"/>
                                    <path d="M165.4,375.48l-.27-.27a.2.2,0,0,0-.27,0c-.2.07-.2.41-.07.61l.27.27a.2.2,0,0,0,.27,0,.42.42,0,0,0,.07-.61Z" fill="#231f20"/>
                                    <path d="M165.81,377.24c0-.07-.21-.27-.27-.2s-.27.06-.21.27.21.27.27.2.21-.14.21-.27Z" fill="#231f20"/>
                                    <path d="M166.08,376.36a.42.42,0,0,0-.61.07c-.07.27.2.47.47.47a.55.55,0,0,0,.61-.2C166.48,376.56,166.28,376.49,166.08,376.36Z" fill="#231f20"/>
                                    <path d="M166.41,375.68v-.2a.93.93,0,0,0-.06-.4c-.07-.14-.21-.07-.27-.07a.35.35,0,0,0-.27.2,1,1,0,0,0-.07.47c0,.07,0,.21.07.21s.06.06.2.2h.2c.07,0,.07-.07.2-.2-.13.06,0-.14,0-.21Z" fill="#231f20"/>
                                    <path d="M165.33,376.77a.21.21,0,0,0-.2-.21c-.07-.07-.2-.07-.4-.07a1.43,1.43,0,0,0-.27.07.41.41,0,0,0-.07.27.22.22,0,0,0,.2.21c.07.06.2.06.41.06a1.91,1.91,0,0,0,.27-.06c.06-.07.06-.14.06-.27Z" fill="#231f20"/>
                                </g>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M250.69,317.61h0a1.82,1.82,0,0,0,.07.61,62.65,62.65,0,0,1,1.89,15c0,8.58-2.1,12.83-2.1,12.83h0s-2.09-4.19-2.09-12.83a62.13,62.13,0,0,1,1.89-15c0-.2.07-.41.07-.61a161.33,161.33,0,0,1-69.15-15.53V432.34H320V302.22A162.31,162.31,0,0,1,250.69,317.61Z" fill="#e85757"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M302.62,406.27c-7.09,2.1-7.5-8.5-6.49-8.91,1.22-.47,1.49,1.62,1.62,2a5.19,5.19,0,0,0,.41,1c0-2.63,0-5.2.88-5,.4.07.67.81,1,1.69.07-1.21.27-2.3.41-3.51s.6-2.7,1.21-2.7,1.08,1.48,1.28,2.77c.07,1,.27,1.82.27,2.7a25.39,25.39,0,0,1,.81-6.42c.41-1.48,1-4.32,1.69-4.52s.81,1.82,1,2.77.27,1.62.4,2.43c.47-3.18,1-6.42,1.49-9.59.06-.68.47-1.62,1.21-1.28a.74.74,0,0,1,.47.6,5.59,5.59,0,0,1,.41,2.77c1.22-2.09,2.43-5.87,3.58-6.21s-.2,4.32-1.28,6.62c.47-.68,1-1.29,1.21-1s-.27,2.1-.67,3.11a8,8,0,0,1-1.22,2.23c.61-.47,1.22-.68,1.42-.47.4.47-.61,2-1.08,2.9a10.67,10.67,0,0,1-1.83,2.23c.88-.81,1.69-1.42,1.83-1.22.27.41-.2,1.62-.47,2.3a11.52,11.52,0,0,1-3.31,3.92,1.58,1.58,0,0,1,1.68-.21c.27.21-.06,1.42-1,2.43a29.6,29.6,0,0,1-3,2.71c1.22-.68,2.5-1.09,2.77-.61C309.57,402.49,306.26,405.26,302.62,406.27Z" fill="#7cced0"/>
                                <path d="M300.86,399.86a6.5,6.5,0,0,1,.68-2.63c.2-.27.67-.07.6.2s-.2.61-.27.88a4.11,4.11,0,0,0-.27,1.62,21.14,21.14,0,0,0,.21,3.58,125.23,125.23,0,0,0,7-13.24c.07-.2.4-.27.61-.2s.27.4.2.61-1.82,3.78-4.73,9a1.92,1.92,0,0,0,1.08-.48,3.14,3.14,0,0,0,1.08-.81c.27-.27.68.21.41.48a4.29,4.29,0,0,1-1.42,1,2.05,2.05,0,0,1-1.42.4c-1.08,1.9-2.29,4.12-3.71,6.22a5.55,5.55,0,0,1-1.08-.07c.4-.68.88-1.42,1.28-2.09A32.1,32.1,0,0,1,300.86,399.86Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M198.62,342.66a4.22,4.22,0,0,0-.67,1.08c-.07-.06-.07-.27-.2-.4s-.07-.27-.21-.41-.2-.2-.2-.27-.07-.2-.2-.27a.22.22,0,0,0-.2-.2h0a.22.22,0,0,0-.21.2l-.27.68a1.7,1.7,0,0,1-.4.67,5.4,5.4,0,0,0-1-1.21,9.26,9.26,0,0,1-.81-.88h0a4.54,4.54,0,0,0-.48,1.62,9.55,9.55,0,0,0-.27,3.38,10.46,10.46,0,0,0,.81,2.5h-1.14c-.07,0-.07.06,0,.2a2.48,2.48,0,0,0,.6,1.28,4.7,4.7,0,0,0,1.22,1.42,3.74,3.74,0,0,1-.88.07H194a5.58,5.58,0,0,0,3.11,2.7c.07,0,.2.07.27.07h0a6.27,6.27,0,0,0-1.42.4h-.07a4.9,4.9,0,0,0,2.23,1.22,5.8,5.8,0,0,0,1.83.27h0c-.21.06-.27.27-.41.4a2.42,2.42,0,0,1-.2.61c0,.2-.07.27-.07.47h0a18,18,0,0,0,6.62-.67s.2-.48.27-.68a9.58,9.58,0,0,0,.2-4.32,14.21,14.21,0,0,0-2.5-6.68h0a9.71,9.71,0,0,0-1.08,2c-.4-1-.67-2.09-1.08-3.11v0a6.13,6.13,0,0,0-.88,2.23,6,6,0,0,1-.47,1.49v-.74a13.85,13.85,0,0,0-1.22-5.14c-.06-.06-.06-.2-.2-.2h-.07A.49.49,0,0,0,198.62,342.66Z" fill="#7cced0"/>
                                <path d="M199.1,351.78c2.43,2.77,5.33,3.92,5.53,3.58s-2.49-1.62-4.92-4.19-3.52-5.33-3.72-5.2A15,15,0,0,0,199.1,351.78Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M291,399a3.25,3.25,0,0,0-.67,2.09,10.19,10.19,0,0,1-2.7-3,.88.88,0,0,0-.21.88,4.85,4.85,0,0,0,.81,2,12.87,12.87,0,0,0-3.37-1.29c-.21,0-.41-.06-.41.07s0,.2,0,.27a3.57,3.57,0,0,0,1.62,1.69,4.48,4.48,0,0,0-5,1.49,10,10,0,0,1,3.37,1.21,7.87,7.87,0,0,1-2.23,1.28,12,12,0,0,1,3.31,1c.07,0,.27.06.27.2s0,.2-.07.2a8.23,8.23,0,0,1-1.41,1.69c1.08-.07,2.29-.2,3.37-.2-.06.67-.81,1.28-.87,2v.21c.06.2.27.06.47.06l2.09-.87a14.51,14.51,0,0,1,8.1-1l.07-.06c-1.42-2-2.77-4-4.18-6A24.1,24.1,0,0,1,291,399Z" fill="#7cced0"/>
                                <path d="M290.6,404.32a11.22,11.22,0,0,0-5.2-1.22,18,18,0,0,1,4.79,1.82c2.36,1.42,4,3.31,4.19,3.11S293.16,405.6,290.6,404.32Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M214.83,406.21c1.69,5.33-6.21,5.67-6.48,5-.41-.87,1.08-1.21,1.48-1.28a2.34,2.34,0,0,0,.68-.27c-1.89.07-3.78.07-3.71-.61,0-.27.6-.6,1.28-.81-.88-.06-1.69-.06-2.64-.2s-2-.41-2-.81,1.08-.88,2.09-1c.68-.07,1.42-.21,2-.27a16.67,16.67,0,0,1-4.8-.48c-1.08-.27-3.17-.67-3.3-1.21s1.28-.61,2-.81,1.22-.2,1.82-.27c-2.43-.27-4.79-.61-7.22-.88-.47-.07-1.22-.41-1-.81.06-.2.27-.27.4-.41a5.51,5.51,0,0,1,2.09-.4c-1.62-.81-4.38-1.69-4.72-2.5s3.17.07,4.93.81c-.48-.27-1-.67-.81-.88s1.62.21,2.29.48a5.42,5.42,0,0,1,1.62.87c-.4-.47-.6-.87-.4-1,.4-.27,1.48.41,2.09.81a7.13,7.13,0,0,1,1.62,1.28c-.61-.6-1.08-1.21-.88-1.28s1.22.07,1.69.41a8.88,8.88,0,0,1,3,2.43c-.27-.48-.4-1-.2-1.22s1,0,1.82.68a22.81,22.81,0,0,1,2.1,2.23c-.48-.88-.81-1.83-.48-2C211.93,401,214,403.51,214.83,406.21Z" fill="#7cced0"/>
                                <path d="M210.1,407.56a4.64,4.64,0,0,1-1.89-.48c-.27-.06-.06-.47.07-.4a1.94,1.94,0,0,0,.68.2,5.44,5.44,0,0,0,1.21.2,17.43,17.43,0,0,0,2.7-.2c-5.53-3-9.92-4.93-9.92-5a.36.36,0,0,1-.21-.48.35.35,0,0,1,.48-.2c.06,0,2.9,1.22,6.82,3.31a1.35,1.35,0,0,0-.41-.81,3.76,3.76,0,0,0-.67-.81c-.21-.2.06-.47.27-.27a6.18,6.18,0,0,1,.81,1,1.24,1.24,0,0,1,.27,1.08c1.48.81,3.1,1.62,4.72,2.63a4.11,4.11,0,0,1-.06.82c-.48-.28-1.08-.61-1.62-.88A16.9,16.9,0,0,1,210.1,407.56Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M209.63,414.92a3,3,0,0,0,1.49.47,6,6,0,0,1-2.23,2,.51.51,0,0,0,.67.06,3.43,3.43,0,0,0,1.49-.6,10.57,10.57,0,0,0-.88,2.63c0,.07,0,.27.07.27h.2a2.72,2.72,0,0,0,1.22-1.22,3.43,3.43,0,0,0,1.21,3.72,6.92,6.92,0,0,1,.81-2.5,9.14,9.14,0,0,1,1,1.62,10.15,10.15,0,0,1,.67-2.5.21.21,0,0,1,.2-.2c.07,0,.07,0,.21.07a9.64,9.64,0,0,1,1.28,1c-.07-.81-.2-1.69-.27-2.5.47,0,1,.61,1.49.68h.2a.42.42,0,0,0,0-.41c-.2-.47-.48-1-.68-1.62a10.18,10.18,0,0,1-.88-6l-.06-.07L212.47,413A10.66,10.66,0,0,1,209.63,414.92Z" fill="#7cced0"/>
                                <path d="M213.62,415.12a10,10,0,0,0-.81,3.92,12,12,0,0,1,1.21-3.58c1-1.76,2.3-3,2.23-3.18S214.56,413.16,213.62,415.12Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M197.75,363.93a3.33,3.33,0,0,0,1.62.82,7.1,7.1,0,0,1-2.7,1.89c.06.27.47.27.81.27a4.5,4.5,0,0,0,1.68-.48,15.12,15.12,0,0,0-1.41,2.7c0,.07-.07.27,0,.41s.2,0,.27,0a2.43,2.43,0,0,0,1.48-1.22,4,4,0,0,0,.81,4.33,7.71,7.71,0,0,1,1.29-2.71,12.33,12.33,0,0,1,.87,1.9,23.51,23.51,0,0,1,1.08-2.71.22.22,0,0,1,.21-.2c.06,0,.06.07.2.07a10.17,10.17,0,0,1,1.21,1.28c0-1,.07-1.89.07-2.9.61.07,1,.81,1.62,1h.21c.2-.07.06-.27.06-.4a9.32,9.32,0,0,0-.6-1.83,12.26,12.26,0,0,1-.07-6.82l-.07-.06c-1.82.87-3.58,1.89-5.4,2.9A8.3,8.3,0,0,1,197.75,363.93Z" fill="#7cced0"/>
                                <path d="M202.14,364.75a8.72,8.72,0,0,0-1.42,4.18,13.81,13.81,0,0,1,1.89-3.78c1.42-1.89,3.11-3,2.9-3.17A7.06,7.06,0,0,0,202.14,364.75Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M214.76,343.07a10.36,10.36,0,0,0-.81,2.77,7,7,0,0,1-1.48-1.29c-.27,1.08-.61,2.23-.88,3.18a12.11,12.11,0,0,1-1.42-1,20.1,20.1,0,0,1-.67,3.37,3,3,0,0,1-1.49-.67,6.19,6.19,0,0,0-.27,3.31c.07,1.68.2,3.51.2,5.19a19.5,19.5,0,0,0,2.3-1,25.83,25.83,0,0,0,6.48-5.33,8.65,8.65,0,0,0-1.42-.21c.88-1.21,1.83-2.49,2.64-3.71-.48.07-1,.2-1.49.27a5.51,5.51,0,0,0-.2-5.13.88.88,0,0,0-.61-.47A1.24,1.24,0,0,0,214.76,343.07Z" fill="#7cced0"/>
                                <path d="M212.27,351.58c-1.22,2.29-2.71,3.78-2.64,4a8.86,8.86,0,0,0,3-3.71,11.3,11.3,0,0,0,1.22-4.59A31.16,31.16,0,0,1,212.27,351.58Z" fill="#01908f"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M299.78,365.29c-1.22-.88-2.43-.07-7.83-3.18-4.39-2.63-4.12-10.8-2.1-11.88.61-.27-6.07-1.49-9-3.11-3.1-1.62-6.21-5.54-6.21-6.28s6.28-3,11.61-1.28c2.91.88,6.89,3.58,7.64,6a8.69,8.69,0,0,1,2.63-6.08c3.51-3.78,11.07-3.51,11.88-3.17s.81,7.09-2.43,10a13.74,13.74,0,0,1-7.09,3.31s3.51,3.38,2.64,9.72C300.86,363.39,301.06,366.16,299.78,365.29Z" fill="#d0b7d9"/>
                                <path d="M287.09,325.65s.4,3.51,7,6.07c5.33,2.23,6.21,11.42,4.18,13-.6.4,7,.81,10.54,2,3.71,1.29,7.83,5.2,7.9,6.08s-6.62,4.39-12.7,3.31c-3.37-.61-8.3-2.9-9.38-5.61a10.32,10.32,0,0,1-1.89,7.23c-3.38,4.79-11.89,5.67-12.83,5.4s-2-7.83,1.21-11.61a15.17,15.17,0,0,1,7.43-4.8s-4.39-3.31-4.39-10.4C283.91,331.72,284.45,327.27,287.09,325.65Z" fill="#7d81af"/>
                                <path d="M295.66,344a1,1,0,0,0,.2-1.29,1,1,0,0,0-1.28.07,1,1,0,0,0-.2,1.28.83.83,0,0,0,1.28-.06Z" fill="#231f20"/>
                                <path d="M296.2,347.05a2.3,2.3,0,0,0,1.22-.6,1.23,1.23,0,0,0,.4-1,1.43,1.43,0,0,0-1.82-1,2.32,2.32,0,0,0-1.22.61,1.21,1.21,0,0,0-.4,1c0,.74.81,1.22,1.82,1Z" fill="#231f20"/>
                                <path d="M290,346.38a1,1,0,0,0,1.08-.68c.07-.47-.07-1-.61-1.08a1,1,0,0,0-1.08.68.87.87,0,0,0,.61,1.08Z" fill="#231f20"/>
                                <path d="M292.35,348.27c.81-.68,1.08-1.49.2-2.09-.67-.48-1.68,0-2.22.81s-.68,1.62,0,2A1.88,1.88,0,0,0,292.35,348.27Z" fill="#231f20"/>
                                <path d="M294.31,349.82c.2,0,.27.07.47.2a1.66,1.66,0,0,0,1.29.07.81.81,0,0,0,.67-.88,1.33,1.33,0,0,0-.47-1.08,1.94,1.94,0,0,0-1.42-.81H294a1.2,1.2,0,0,0-.81.27.85.85,0,0,0-.2.81,1,1,0,0,0,.27.68,1.88,1.88,0,0,0,1.08.74Z" fill="#231f20"/>
                                <path d="M291.88,345.23a1.22,1.22,0,0,0,1-.27,1.78,1.78,0,0,0,.68-1.08,3.56,3.56,0,0,0,0-1.22,1.13,1.13,0,0,0-.68-.67,1.22,1.22,0,0,0-1,.27,1.78,1.78,0,0,0-.68,1.08,4.08,4.08,0,0,0,0,1.21.87.87,0,0,0,.68.68Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M242.45,454c-1.22-.88-2.43-.07-7.83-3.18-4.39-2.63-4.12-10.8-2.1-11.88.61-.27-6.07-1.49-9-3.11-3.1-1.62-6.21-5.54-6.21-6.28s6.28-3,11.61-1.28c2.91.88,6.89,3.58,7.63,6a8.73,8.73,0,0,1,2.64-6.08c3.51-3.78,11.07-3.51,11.88-3.17s.81,7.09-2.43,10a13.74,13.74,0,0,1-7.09,3.31s3.51,3.38,2.64,9.72C243.46,452.13,243.67,455,242.45,454Z" fill="#d0b7d9"/>
                                <path d="M229.62,414.38s.4,3.51,7,6.07c5.34,2.23,6.21,11.42,4.19,13-.61.4,7,.81,10.53,2,3.72,1.29,7.84,5.2,7.9,6.08s-6.61,4.39-12.69,3.31c-3.38-.61-8.31-2.9-9.39-5.6a10.24,10.24,0,0,1-1.89,7.22c-3.37,4.79-11.88,5.67-12.83,5.4s-2-7.83,1.22-11.61a15.13,15.13,0,0,1,7.43-4.8s-4.39-3.31-4.39-10.4C226.45,420.52,227.05,416.07,229.62,414.38Z" fill="#7d81af"/>
                                <path d="M238.26,432.81a1,1,0,0,0,.21-1.28,1,1,0,0,0-1.29.07,1,1,0,0,0-.2,1.28.87.87,0,0,0,1.28-.07Z" fill="#231f20"/>
                                <path d="M238.74,435.92a2.33,2.33,0,0,0,1.21-.61,1.25,1.25,0,0,0,.41-1,1.42,1.42,0,0,0-1.83-1,2.31,2.31,0,0,0-1.21.6,1.24,1.24,0,0,0-.41,1c.07.67.81,1.21,1.83,1Z" fill="#231f20"/>
                                <path d="M232.52,435.18a1,1,0,0,0,1.08-.68c.07-.47-.06-1-.6-1.08a1,1,0,0,0-1.08.68c-.07.54.13,1,.6,1.08Z" fill="#231f20"/>
                                <path d="M235,437.13c.81-.67,1.08-1.48.21-2.09-.68-.47-1.69,0-2.23.81s-.68,1.62,0,2C233.87,438.21,234.41,437.54,235,437.13Z" fill="#231f20"/>
                                <path d="M236.84,438.69a.7.7,0,0,1,.48.2,1.72,1.72,0,0,0,1.28.07.83.83,0,0,0,.68-.88,1.29,1.29,0,0,0-.48-1.08,1.89,1.89,0,0,0-1.41-.81h-.88a1.19,1.19,0,0,0-.81.27.84.84,0,0,0-.21.81,1,1,0,0,0,.27.67,2.16,2.16,0,0,0,1.08.75Z" fill="#231f20"/>
                                <path d="M234.41,434a1.24,1.24,0,0,0,1-.27,1.77,1.77,0,0,0,.67-1.08,3.56,3.56,0,0,0,0-1.22,1.09,1.09,0,0,0-.67-.67,1.24,1.24,0,0,0-1,.27,1.75,1.75,0,0,0-.67,1.08,3.51,3.51,0,0,0,0,1.21c.13.34.4.68.67.68Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M270.2,394.25c-.6-.6-1.41-.2-4.32-2.43-2.43-1.82-1.62-6.41-.4-6.88.27-.07-3.38-1.29-4.93-2.3s-3.18-3.58-3.11-4,3.78-1.29,6.69,0c1.62.67,3.71,2.49,3.91,4a4.91,4.91,0,0,1,1.89-3.31c2.3-1.89,6.62-1.28,7-1s0,4.12-2.1,5.6a7.63,7.63,0,0,1-4.32,1.42,6.93,6.93,0,0,1,.81,5.67C270.88,393.31,270.74,395,270.2,394.25Z" fill="#e57b70"/>
                                <path d="M265.54,370.89s0,2,3.58,4c2.91,1.62,2.77,6.89,1.49,7.7-.41.2,4,.88,5.87,1.83s4.12,3.51,4.12,4-4.12,2.09-7.49,1c-1.89-.61-4.53-2.23-5-3.78a5.54,5.54,0,0,1-1.62,4c-2.23,2.49-7.23,2.43-7.7,2.22s-.61-4.59,1.42-6.48a9.17,9.17,0,0,1,4.52-2.23,7.69,7.69,0,0,1-1.82-6.21C263.25,374.2,264,371.63,265.54,370.89Z" fill="#efd3e6"/>
                                <path d="M269.19,381.9c.2-.21.41-.48.2-.68a.45.45,0,0,0-.67,0c-.2.2-.41.47-.2.68a.45.45,0,0,0,.67,0Z" fill="#231f20"/>
                                <path d="M269.26,383.65a.83.83,0,0,0,.67-.27c.21-.06.27-.4.27-.61,0-.4-.4-.67-1-.67a.86.86,0,0,0-.67.27c-.21.07-.27.4-.27.61,0,.47.4.81,1,.67Z" fill="#231f20"/>
                                <path d="M265.75,382.84a.64.64,0,0,0,.67-.27c.07-.27,0-.61-.27-.67s-.61.06-.67.27a.45.45,0,0,0,.27.67Z" fill="#231f20"/>
                                <path d="M267.1,384.19a.75.75,0,0,0,.27-1.21c-.41-.41-1-.07-1.29.27s-.47.88-.06,1.08S266.69,384.4,267.1,384.19Z" fill="#231f20"/>
                                <path d="M268,385.21c.07,0,.21.06.28.06s.47.21.67.07.41-.2.47-.47a1,1,0,0,0-.2-.68,2.45,2.45,0,0,0-.81-.6c-.2,0-.27-.07-.47-.07a1.15,1.15,0,0,0-.48.07.58.58,0,0,0-.2.4c0,.07.07.27.07.41a2.28,2.28,0,0,0,.67.81Z" fill="#231f20"/>
                                <path d="M267,382.37a.93.93,0,0,0,.61-.07.78.78,0,0,0,.47-.61,2.19,2.19,0,0,0,.07-.67c-.07-.2-.2-.41-.27-.41a1,1,0,0,0-.61.07.78.78,0,0,0-.47.61,2.19,2.19,0,0,0-.07.67.37.37,0,0,0,.27.41Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M253.39,409.79c.61-.41.27-1.08,2.3-3.11,1.68-1.69,5.33-.61,5.6.47.07.27,1.42-2.5,2.43-3.71a7.08,7.08,0,0,1,3.51-2c.27.07.61,3.18-.67,5.34-.81,1.21-2.43,2.7-3.58,2.7a4.06,4.06,0,0,1,2.43,1.89c1.28,2,.27,5.4,0,5.67s-3.31-.47-4.19-2.23a6.91,6.91,0,0,1-.67-3.51,5,5,0,0,1-4.59,0C254.06,410.46,252.78,410.26,253.39,409.79Z" fill="#d0b7d9"/>
                                <path d="M272.57,408.71s-1.62-.21-3.58,2.43c-1.62,2.09-5.81,1.48-6.28.4-.07-.27-1.22,3.11-2.1,4.53s-3.17,2.9-3.57,2.76-1.22-3.51,0-6.07c.67-1.49,2.29-3.38,3.57-3.58a4.47,4.47,0,0,1-3-1.69c-1.68-2-1.21-6-.87-6.28s3.71,0,5,1.82a7.54,7.54,0,0,1,1.29,3.92,6.18,6.18,0,0,1,5.2-.81C270.2,406.61,272.09,407.29,272.57,408.71Z" fill="#7d81af"/>
                                <path d="M263.38,410.39c.07.21.41.27.61.21s.2-.41.07-.61a.55.55,0,0,0-.61-.2.37.37,0,0,0-.07.6Z" fill="#231f20"/>
                                <path d="M261.9,410.26a1.85,1.85,0,0,0,.07.61.42.42,0,0,0,.4.27.79.79,0,0,0,.68-.68,1.85,1.85,0,0,0-.07-.61.43.43,0,0,0-.41-.27c-.33-.06-.6.21-.67.68Z" fill="#231f20"/>
                                <path d="M263,407.56a.55.55,0,0,0,.2.61.52.52,0,0,0,.61-.07c.13-.14,0-.48-.2-.61s-.54-.07-.61.07Z" fill="#231f20"/>
                                <path d="M261.76,408.37c.21.4.48.61.88.4a.83.83,0,0,0-.07-1.08c-.27-.27-.6-.47-.88-.2S261.69,408.17,261.76,408.37Z" fill="#231f20"/>
                                <path d="M260.88,409.11c0,.07-.06.07-.06.2a2.21,2.21,0,0,0-.21.61c0,.2.07.41.27.41a1.15,1.15,0,0,0,.48-.07.67.67,0,0,0,.47-.61c0-.07.07-.27.07-.4a.72.72,0,0,0-.07-.41.37.37,0,0,0-.27-.2.39.39,0,0,0-.27.07.76.76,0,0,0-.41.4Z" fill="#231f20"/>
                                <path d="M263.18,408.57a.53.53,0,0,0,0,.47c.07.21.2.27.41.41s.4.2.47.07a.46.46,0,0,0,.4-.21c.14-.13.07-.27,0-.47s-.2-.27-.4-.4-.41-.21-.47-.07-.34,0-.41.2Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M249.54,376.36c-.07-.2-.2-.41-.27-.61v-.07s-.07,0-.07-.06a9.3,9.3,0,0,0-2.43-3.78c1.82-.21,9-3.92,9.12-5s-1.49-4.19-7.7-4.19a9.9,9.9,0,0,0-5,2.23,15.23,15.23,0,0,0-.27-11.82c-1,1.83-3,2.7-4.93,3.58a7.2,7.2,0,0,0-3.1,6.08c-1.22-1.08-.48-4.59-3.51-6-1.9-.88-5.54-.61-7-1.28,1.08.67.68,7.29,1.69,9a13.77,13.77,0,0,0,5.13,4c-1.69.07-5.87-.4-8.57,1.89-2,1.69-3.11,4.32-5,6.08,1.82-.81,3.92-.41,5.81.07a29.47,29.47,0,0,0,5.67,1.62c2,.2,3.38-1.29,4.52-3.11-.4,1.69-2.7,5.4-2.76,8.71-.07,5.13,3.1,7.23,3.78,6.62s3.58-3.11,4.39-5.6.47-6.42,1-8.24c.07,1.69,1.82,5.4,3.17,6.21a9.83,9.83,0,0,0,4.8,1.08,17.59,17.59,0,0,0,7.22-1.49A16.27,16.27,0,0,1,249.54,376.36Z" fill="#efd3e6"/>
                                <path d="M247.18,371.77a10.86,10.86,0,0,0-4.39-2.77,2.64,2.64,0,0,0,.27-.41c1.89-2.7,1.69-6.68,1.28-6.88s-3.17.87-4.73,2.43a9.27,9.27,0,0,0-1.28,1.69,5.52,5.52,0,0,0-.27-1.69c-.67-2.5-4.32-4.32-4.73-4a9.64,9.64,0,0,0-1.62,6.62,3.51,3.51,0,0,0-2.5,1c-2.7,2.5-4.52,2-4.52,2,.2,1.62,2.23,2.9,4.39,4A6.61,6.61,0,0,0,235,374a8.07,8.07,0,0,0,.61,4.59c1.08,2.43,4.93,3.51,5.2,3.17s1.82-4.52.41-7.22a5.72,5.72,0,0,0,1.08.06C245,374.74,247.45,372.17,247.18,371.77Z" fill="#e57b70"/>
                                <path d="M238.06,368.46c.07-.2,0-.61-.2-.61s-.41,0-.61.27,0,.61.2.61.48.07.61-.27Z" fill="#231f20"/>
                                <path d="M238.74,369.88a.58.58,0,0,0,.4-.48.88.88,0,0,0,0-.6.66.66,0,0,0-1-.07.59.59,0,0,0-.41.47.61.61,0,0,0,1,.68Z" fill="#231f20"/>
                                <path d="M235.83,370.69a.51.51,0,0,0,.41-.48.46.46,0,0,0-.48-.4c-.2,0-.4.27-.4.47a.5.5,0,0,0,.47.41Z" fill="#231f20"/>
                                <path d="M237.32,371.09c.2-.47.2-.88-.27-1a.74.74,0,0,0-.81.81c-.07.41,0,.88.4.88S237.12,371.36,237.32,371.09Z" fill="#231f20"/>
                                <path d="M238.47,371.5h.27a1.1,1.1,0,0,0,.6-.2.42.42,0,0,0,.07-.61,1.12,1.12,0,0,0-.4-.41c-.21-.07-.61-.2-.81-.07a.75.75,0,0,0-.41.21l-.27.27v.4c.07.07.07.21.27.27s.47.14.68.14Z" fill="#231f20"/>
                                <path d="M236.57,369.74c.21,0,.28-.07.41-.27a1.79,1.79,0,0,0,.07-.61,1,1,0,0,0-.21-.6.74.74,0,0,0-.4-.21c-.2,0-.27.07-.41.27a2.14,2.14,0,0,0-.06.61,1.05,1.05,0,0,0,.2.61c.07.2.2.2.4.2Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M314.23,427.41c-.07-.07-.07-.2-.2-.27v-.07H314a5.33,5.33,0,0,0-1.62-1.62c.81-.27,3.92-2.9,3.78-3.51s-1.21-1.82-4.18-1.08a5,5,0,0,0-2.1,1.62,7.67,7.67,0,0,0-1.48-5.67c-.21,1-1.22,1.62-1.89,2.29a3.71,3.71,0,0,0-.81,3.31c-.68-.4-.82-2.23-2.44-2.5-1-.2-2.7.41-3.51.21.61.2,1.22,3.37,1.89,4.12a5.83,5.83,0,0,0,2.91,1.28c-.81.27-2.91.47-3.92,1.89-.81,1.08-1,2.43-1.69,3.51a4.06,4.06,0,0,1,2.77-.61,13.52,13.52,0,0,0,2.9.07c1-.2,1.49-1.08,1.83-2,0,.87-.68,2.9-.27,4.52.61,2.5,2.29,3.11,2.63,2.7a7.41,7.41,0,0,0,1.42-3.17c.07-1.29-.47-3.11-.47-4.12.27.81,1.48,2.43,2.29,2.63a5.86,5.86,0,0,0,2.43-.07,8,8,0,0,0,3.31-1.62A5.25,5.25,0,0,1,314.23,427.41Z" fill="#efd3e6"/>
                                <path d="M312.48,425.52a7.12,7.12,0,0,0-2.44-.81c0-.07.07-.07.07-.2a4.48,4.48,0,0,0-.2-3.51,4.76,4.76,0,0,0-2,1.68c-.06.27-.27.61-.4,1a6.35,6.35,0,0,1-.27-.81,3.22,3.22,0,0,0-2.7-1.29,5.43,5.43,0,0,0,0,3.38,1.6,1.6,0,0,0-1.08.81c-1,1.49-1.89,1.49-1.89,1.49.27.81,1.41,1.08,2.63,1.41a3.3,3.3,0,0,0,2.9-.6,4.21,4.21,0,0,0,.81,2.22,3.18,3.18,0,0,0,2.91.88,4.72,4.72,0,0,0-.68-3.51,1.1,1.1,0,0,0,.47-.07C311.8,427.21,312.68,425.59,312.48,425.52Z" fill="#e57b70"/>
                                <path d="M307.68,425c0-.07,0-.27-.2-.27a.21.21,0,0,0-.2.2c0,.14,0,.27.2.27a.18.18,0,0,0,.2-.2Z" fill="#231f20"/>
                                <path d="M308.29,425.52c.07-.07.07-.2.2-.27a.39.39,0,0,0-.07-.27c-.06-.07-.27-.07-.47.07s-.07.2-.2.27a.41.41,0,0,0,.07.27c.06.13.27.13.47-.07Z" fill="#231f20"/>
                                <path d="M306.87,426.33c.07,0,.2-.2.07-.27s-.2-.2-.27-.07-.2.2-.07.27.2.07.27.07Z" fill="#231f20"/>
                                <path d="M307.68,426.33c.07-.27,0-.41-.27-.47s-.27.2-.27.47.07.4.27.4S307.68,426.4,307.68,426.33Z" fill="#231f20"/>
                                <path d="M308.29,426.33h0a.39.39,0,0,0,.34-.2.22.22,0,0,0,0-.27c-.07-.07-.07-.07-.21-.07H308c-.14,0-.07.07-.2.07s-.07.06-.07.2,0,.07.07.2a.27.27,0,0,0,.2.07c0,.07.2.07.27,0Z" fill="#231f20"/>
                                <path d="M307.21,425.79c.07,0,.07-.07.2-.2v-.27a.22.22,0,0,0-.2-.21c-.14,0-.2-.06-.2,0s-.07.07-.21.21v.27a.22.22,0,0,0,.21.2Z" fill="#231f20"/>
                            </g>
                            <g clip-path="url(#clip-path-9)">
                                <path d="M219.56,326.05l-.2-.2h0a3.28,3.28,0,0,0-1.08-1.08c.6-.2,2.7-2,2.63-2.43s-.88-1.28-2.91-.81a3.25,3.25,0,0,0-1.48,1.08,5.16,5.16,0,0,0-1.08-3.92c-.2.68-.81,1.08-1.28,1.62a2.36,2.36,0,0,0-.48,2.3c-.47-.27-.47-1.49-1.68-1.69-.68-.07-1.9.2-2.44.07.41.2.81,2.43,1.29,2.9a4.06,4.06,0,0,0,2,.88c-.47.2-2,.27-2.7,1.28-.47.68-.67,1.69-1.21,2.5a2.84,2.84,0,0,1,2-.4,10,10,0,0,0,2,.06c.67-.06,1-.67,1.21-1.41a10.63,10.63,0,0,0-.2,3.1c.41,1.69,1.62,2.09,1.82,1.89a5.2,5.2,0,0,0,1-2.23c.06-.87-.41-2.22-.27-2.76a4.16,4.16,0,0,0,1.62,1.82,3.58,3.58,0,0,0,1.69,0,7.09,7.09,0,0,0,2.29-1.08A5.23,5.23,0,0,1,219.56,326.05Z" fill="#efd3e6"/>
                                <path d="M218.41,324.63a5.41,5.41,0,0,0-1.69-.6.07.07,0,0,1,.07-.07,3.62,3.62,0,0,0-.07-2.43,4.63,4.63,0,0,0-1.42,1.21c-.06.21-.2.41-.27.68-.06-.2-.06-.41-.2-.47-.4-.81-1.82-1.08-1.89-.88a3.6,3.6,0,0,0,0,2.29,1.92,1.92,0,0,0-.81.48c-.68,1.08-1.28,1-1.28,1,.2.47,1,.81,1.82,1a2.39,2.39,0,0,0,2-.4,2.43,2.43,0,0,0,.6,1.48c.61.68,1.89.81,2,.61a3.33,3.33,0,0,0-.47-2.43,1.14,1.14,0,0,0,.4-.07C217.94,325.85,218.55,324.84,218.41,324.63Z" fill="#e57b70"/>
                                <path d="M215,324.36c0-.06,0-.2-.06-.2s-.07.07-.21.07,0,.2.07.2.2,0,.2-.07Z" fill="#231f20"/>
                                <path d="M215.44,324.77c.07-.07.07-.07.07-.2v-.21c-.07-.06-.21-.06-.41,0s-.07.07-.07.21v.2a.42.42,0,0,0,.41,0Z" fill="#231f20"/>
                                <path d="M214.56,325.24c.07,0,.07-.07.07-.2s-.07-.07-.2-.07-.07.07-.07.2.07.07.2.07Z" fill="#231f20"/>
                                <path d="M215,325.24c0-.2,0-.27-.2-.27s-.2.2-.2.27.07.27.2.27S215,325.31,215,325.24Z" fill="#231f20"/>
                                <path d="M215.44,325.31h0c.2,0,.2-.07.27-.07V325c0-.14-.07-.07-.2-.07h-.27l-.07.07-.07.07v.06a.07.07,0,0,1,.07.07.64.64,0,0,0,.27.07Z" fill="#231f20"/>
                                <path d="M214.63,324.9l.07-.06v-.21c0-.13-.07-.06-.07-.2h-.2l-.07.07v.2c0,.14.07.07.07.2Z" fill="#231f20"/>
                            </g>
                        </g>
                    </g>
                </svg>
        return kid;     
    }
   
   

    render() {
     
        return (
            // <>
            // {
                this.getDanaChar()
        //     }
        // </>
        )
    }
}
