import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface EchidnaProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface EchidnaState {
    height:string
}


export class Echidna extends React.Component<EchidnaProps, EchidnaState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <g>
                                <path fill="#302A2A" d="M120.1,313.2c-1.1-2-2.2-4.2-3.2-6.5C113.7,312,115.4,314.2,120.1,313.2z"/>
                                <path fill="#302A2A" d="M382.1,367.6c0,0,13.9,7.7,11-1.2c-3-8.9-14.2-27.1-14.2-27.1s11.8,5,11.8-1.3c0-3.5-7.2-12.8-13.5-20.2
                                    c-4.9,7.9-9.5,13-11.4,14.9c-0.9,1.2-1.8,2.3-2.7,3.5c2,10.1,3.1,20.7,3.1,32c0,51.2-22.4,96-56,120.8c0,0.2,0,0.4-0.1,0.7h6.7
                                    c-1.6,15.2,15.1,19.2,31.7-15.2c-3.9,22.5,9.4,12.8,13.3-16.5c0,0,8.2,9.4,13.2,4.8c5-4.6-0.8-26.1-0.8-26.1
                                    c24.9,7.5,19.6-14.5,6-34.7c0,0,17,8.4,19,0C401,393.5,382.1,367.6,382.1,367.6z"/>
                                <path fill="#302A2A" d="M384.7,310.8c0-1.1-0.6-2.6-1.6-4.2c-1.1,2.3-2.1,4.5-3.3,6.6C382.6,313.9,384.7,313.5,384.7,310.8z"/>
                                <path fill="#302A2A" d="M133.8,368.2c0-11.3,1.1-21.9,3.1-32c-0.9-1.1-1.8-2.3-2.7-3.5c-2-2-7.2-7.6-12.4-16.4
                                    c-15.3,15.2-21.2,31.6-0.8,23c-20.7,15.8-23.8,39.7-3.2,28.3c0,0-18.9,25.9-16.9,34.3c2,8.4,19,0,19,0s-7.5,23.2-7.5,30.1
                                    c0,6.9,13,0,13,0s-5.4,26.1-0.4,30.7c5,4.6,13.2-4.8,13.2-4.8c3.8,29.3,17.2,39,13.3,16.5c16.5,34.4,33.3,30.4,31.7,15.2h6.6
                                    c0-0.2,0-0.5-0.1-0.7C156.2,464.1,133.8,419.3,133.8,368.2z"/>
                                <path fill="#231E1E" d="M189.7,488.9c-1.1-12.3-1.1-31.5-1.1-31.5c0-4.7,0-9.2,0.1-13.6c0,0.2,0,0.4,0.1,0.6c0.1-2,0.1-4,0.2-6
                                    c-5.4,4.6-12.2,6-20.6,1.1c-26.1-15.6,17-60.8,25.9-69.7c-25.7-6.4-44.5-17.7-57.3-33.5c-2,10.1-3.1,20.7-3.1,32
                                    C133.8,419.3,156.2,464.1,189.7,488.9z"/>
                                <path fill="#231E1E" d="M305.7,369.7c8.9,8.9,51.9,54.1,25.9,69.7c-8.3,5-15.1,3.5-20.6-1.1c0.3,6.1,0.4,12.4,0.3,19
                                    c0,0,0,19.3-1.2,31.6c33.6-24.7,56-69.6,56-120.8c0-11.3-1.1-21.9-3.1-32C350.3,351.9,331.4,363.3,305.7,369.7z"/>
                                <path fill="#231E1E" d="M273.3,489.1c-11.5,1.6-23.3,1.8-23.3,1.8s-11.8-0.2-23.3-1.8l-0.2,0.4c-0.3,5.3-1.1,11.1-2.6,16.3
                                    c8.4,2.3,17.1,3.6,26.1,3.6c8.9,0,17.6-1.2,25.9-3.5C274.4,500.5,273.6,494.5,273.3,489.1z"/>
                                <polygon fill="#231E1E" points="200.2,371 200.2,371 200.2,371 		"/>
                                <path fill={backGroundColor} d="M311.4,457.3c0.1-6.6-0.1-12.9-0.3-19c5.4,4.6,12.2,6,20.6,1.1c26.1-15.6-17-60.8-25.9-69.7
                                    c25.7-6.4,44.5-17.7,57.3-33.5c0.9-1.1,1.8-2.3,2.7-3.5c1.9-1.9,6.6-6.9,11.4-14.9c0.9-1.4,1.8-3,2.7-4.6c1.1-2,2.2-4.2,3.3-6.6
                                    c3.3-7.2,6.1-15.8,7.7-25.6c0,0-4.4,1.6-7.6,6.2c0,0,9.6-16.5,9-35.8c0,0-3.9-0.3-6.9,4.3c0,0,4.7-10.3,1.9-30.3
                                    c0,0-5.6,1.2-7.6,6.2c0,0,3.6-14.3-1.1-28.4c0,0-3.5,2.1-5.3,6.5c0,0,0.7-10.3-4.2-22.1c0,0-2.7,2.3-4.1,6.4
                                    c0,0-2.5-20.2-14.6-30.1c0,0-1.3,15.9,0.4,25.6c0,0-5.1-24-19.4-32.3c0,0-0.5,7.2,0.4,20.4C309.1,161,280.7,150,250,150
                                    c-30.7,0-59.1,11.1-81.7,27.7c0.9-13.2,0.4-20.4,0.4-20.4c-14.3,8.3-19.4,32.3-19.4,32.3c1.7-9.8,0.4-25.6,0.4-25.6
                                    c-12.1,9.9-14.6,30.1-14.6,30.1c-1.4-4-4.1-6.4-4.1-6.4c-4.9,11.9-4.2,22.1-4.2,22.1c-1.8-4.4-5.3-6.5-5.3-6.5
                                    c-4.6,14.1-1.1,28.4-1.1,28.4c-2-5-7.6-6.2-7.6-6.2c-2.8,20,1.9,30.3,1.9,30.3c-2.9-4.7-6.9-4.3-6.9-4.3c-0.6,19.2,9,35.8,9,35.8
                                    c-3.1-4.5-7.6-6.2-7.6-6.2c1.6,9.9,4.4,18.5,7.7,25.7c1,2.3,2.1,4.5,3.2,6.5c0.6,1.1,1.2,2.1,1.8,3.1c5.2,8.8,10.4,14.4,12.4,16.4
                                    c0.9,1.2,1.8,2.3,2.7,3.5c12.8,15.8,31.7,27.1,57.3,33.5c-8.9,8.9-51.9,54.1-25.9,69.7c8.3,5,15.1,3.5,20.6-1.1
                                    c-0.1,2-0.2,4-0.2,6c0-0.2-0.1-0.4-0.1-0.6c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0,19.3,1.1,31.5c0,0.3,0,0.5,0.1,0.7
                                    c0.1,0.7,0.1,1.4,0.2,2.1c0.2,1.8,0.5,3.7,0.9,5.5c0,0,0,0,0,0c-0.8,0-1.3,0.1-1.6,0.4c-5.2,4.8-6.5,19.8-6.5,19.8
                                    c15.8,5.9,34.9-0.2,34.9-0.2c0,0,0,0,0,0c0,0,0,0,0,0c2.9-2.6,4.8-6.7,6.2-11.4c1.5-5.1,2.2-11,2.6-16.3l0.2-0.4
                                    c11.5,1.6,23.3,1.8,23.3,1.8s11.8-0.2,23.3-1.8c0.3,5.4,1.1,11.4,2.6,16.7c1.3,4.7,3.3,8.8,6.1,11.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0
                                    c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15.1-6.5-19.8c-0.3-0.2-0.8-0.4-1.6-0.4c0-0.1,0-0.3,0.1-0.4c0.4-1.6,0.7-3.3,0.9-5.1
                                    c0.1-0.7,0.1-1.3,0.2-2.1c0-0.2,0-0.4,0.1-0.7C311.3,476.7,311.4,457.3,311.4,457.3z M200.2,371C200.2,371,200.2,371,200.2,371
                                    L200.2,371C200.2,371,200.2,371,200.2,371z"/>
                            </g>
                            <path opacity="0.2" fill="#FFFFFF" d="M250,469.1c61.8,0,58.3-59.9,49.8-98.1c-5.2,1.1-10.6,2-16.3,2.7c-3.1,0.4-6.2,0.7-9.4,1
                                c-7.6,0.6-15.7,1-24.1,1c-8.5,0-16.5-0.3-24.1-1c-3.1-0.3-6.1-0.6-9.1-1c-5.8-0.7-11.3-1.6-16.6-2.7
                                C191.7,409.2,188.2,469.1,250,469.1z"/>
                            <path opacity="0.2" fill="#161616" d="M306,369.6c27.4-6.9,47-19.4,59.7-36.9c4-4,20.4-22,25.1-51.7c0,0-4.4,1.6-7.6,6.2
                                c0,0,9.6-16.5,9-35.8c0,0-3.9-0.3-6.9,4.3c0,0,4.7-10.3,1.9-30.3c0,0-5.6,1.2-7.6,6.2c0,0,3.6-14.3-1.1-28.4c0,0-3.5,2.1-5.3,6.5
                                c0,0,0.7-10.3-4.2-22.1c0,0-2.7,2.3-4.1,6.4c0,0-2.5-20.2-14.6-30.1c0,0-1.3,15.9,0.4,25.6c0,0-5.1-24-19.4-32.3
                                c0,0-0.5,7.2,0.4,20.4c-3-2.2-6.1-4.3-9.3-6.3c-3.6,3.8-8.4,9.2-14,16.1c5.8-3,10.5-0.2,10.5-0.2c-17.3,7.9-32.4,30.3-33.2,31.5
                                c0,0,0,0,0,0.1c3.3-2.2,6.9-2,6.9-2c-12.4,9.7-22.6,32-22.7,32c0,0,0,0,0,0c2.2-2.3,5.2-2.8,5.2-2.8c-9,9.2-13.9,33.7-14.2,35.4
                                c0,0,0,0.1,0,0.1c2.1-2.6,4.5-1.9,4.5-1.9c-4.9,11.3-5.4,23-4.4,31.5C265.4,341.2,282.8,363.9,306,369.6z"/>
                            <path opacity="0.2" fill="#161616" d="M168.7,157.3c-14.3,8.3-19.4,32.3-19.4,32.3c1.7-9.8,0.4-25.6,0.4-25.6
                                c-12.1,9.9-14.6,30.1-14.6,30.1c-1.4-4-4.1-6.4-4.1-6.4c-4.9,11.9-4.2,22.1-4.2,22.1c-1.8-4.4-5.3-6.5-5.3-6.5
                                c-4.6,14.1-1.1,28.4-1.1,28.4c-2-5-7.6-6.2-7.6-6.2c-2.8,20,1.9,30.3,1.9,30.3c-2.9-4.7-6.9-4.3-6.9-4.3c-0.6,19.2,9,35.8,9,35.8
                                c-3.1-4.5-7.6-6.2-7.6-6.2c4.7,29.7,21.1,47.7,25.1,51.7c12.8,17.5,32.4,30,59.7,36.9c23.2-5.7,40.6-28.4,44.9-58.6
                                c1-8.4,0.6-20.2-4.4-31.5c0,0,2.3-0.7,4.5,1.9c0,0,0-0.1,0-0.1c-0.3-1.7-5.2-26.2-14.2-35.4c0,0,2.9,0.4,5.2,2.8c0,0,0,0,0,0
                                c0,0-10.3-22.4-22.7-32c0,0,3.6-0.1,6.9,2c0,0,0,0,0-0.1c-0.8-1.2-15.8-23.6-33.2-31.5c0,0,4.7-2.8,10.5,0.2
                                c-5.6-6.9-10.4-12.2-14-16.1c-3.2,2-6.3,4.1-9.3,6.3C169.2,164.5,168.7,157.3,168.7,157.3z"/>
                            <g>
                                <g>
                                    <g>
                                        <path fill="#FFFFFF" d="M334.6,330.2c2.5-2.2,4.7-4.8,6.4-7.9c7.6-13.8,2.6-31.1-11.1-38.7c-13.8-7.6-31.1-2.6-38.7,11.1
                                            c-7.6,13.8-2.6,31.1,11.1,38.7c0.1,0,0.2,0.1,0.3,0.1C312.3,328.4,323.7,327.1,334.6,330.2z"/>
                                        <path fill="#21323F" d="M317,309.7c-3.2,0-5.8-2.6-5.8-5.8v0c0-3.1,2.5-5.6,5.5-5.8c-2.6-2.1-5.8-3.3-9.3-3.3
                                            c-8.2,0-14.9,6.7-14.9,14.9c0,8.2,6.7,14.9,14.9,14.9c8.2,0,14.9-6.7,14.9-14.9H317z"/>
                                        <g>
                                            <path fill="#F9B7A8" d="M315.3,337c-1.7,3.1-0.6,7,2.5,8.8c3.1,1.7,7,0.6,8.8-2.5c1.7-3.1,0.6-7-2.5-8.8
                                                C321,332.7,317,333.9,315.3,337z"/>
                                            <circle fill="#F9B7A8" cx="317.3" cy="353.4" r="3.2"/>
                                            <circle fill="#F9B7A8" cx="329.7" cy="349.8" r="3.2"/>
                                        </g>
                                    </g>
                                    <g>
                                        <path fill="#FFFFFF" d="M165.4,330.2c-2.5-2.2-4.7-4.8-6.4-7.9c-7.6-13.8-2.6-31.1,11.1-38.7c13.8-7.6,31.1-2.6,38.7,11.1
                                            c7.6,13.8,2.6,31.1-11.1,38.7c-0.1,0-0.2,0.1-0.3,0.1C187.7,328.4,176.3,327.1,165.4,330.2z"/>
                                        <path fill="#21323F" d="M183,309.7c3.2,0,5.8-2.6,5.8-5.8v0c0-3.1-2.5-5.6-5.5-5.8c2.6-2.1,5.8-3.3,9.3-3.3
                                            c8.2,0,14.9,6.7,14.9,14.9c0,8.2-6.7,14.9-14.9,14.9c-8.2,0-14.9-6.7-14.9-14.9H183z"/>
                                        <g>
                                            <path fill="#F9B7A8" d="M184.7,337c1.7,3.1,0.6,7-2.5,8.8c-3.1,1.7-7,0.6-8.8-2.5c-1.7-3.1-0.6-7,2.5-8.8
                                                C179,332.7,183,333.9,184.7,337z"/>
                                            <circle fill="#F9B7A8" cx="182.7" cy="353.4" r="3.2"/>
                                            <circle fill="#F9B7A8" cx="170.3" cy="349.8" r="3.2"/>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <path fill="#999688" d="M297.1,338.6c-0.9-2.9-4.2-5.5-7.6-4.9c0,0-0.1,0-0.1,0c-3.8,0.9-8.6,2.8-13.3,2.6c0,0-0.1,0-0.1,0
                                        c-0.1,0-0.2,0-0.3,0c-0.6,0-1-0.1-1.3-0.1c-0.2,0-0.5-0.1-0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1c-5.8-1.5-5.1-10.8-9.1-13.8
                                        c-3.8-1.1-7.2-1.2-11.6-1.3c-1.3,0-2.8,0-5.1,0c-2.9,0.1-7.8,0.1-11.6,1.3c-4.2,2.9-2.7,11.9-9.1,13.8c-0.2,0-0.2,0-0.3,0.1
                                        c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7,0.1-1.3,0.1c-0.1,0-0.1,0-0.3,0c0,0-0.1,0-0.1,0c-4,0.2-8.7-1.2-13.3-2.6c0,0-0.1,0-0.1,0
                                        c-8.6-0.7-9.1,9.4-6.7,12.4c7.9,12.8,31.5,15.1,46.2,15.8C267.3,361.8,301.5,355.1,297.1,338.6z"/>
                                    <path fill="#A8A69E" d="M289.5,333.6c0,0-0.1,0-0.1,0c-3.8,0.9-8.6,2.8-13.3,2.6c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
                                        c-0.6,0-1-0.1-1.3-0.1c-0.2,0-0.5-0.1-0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1c-5.8-1.5-5.1-10.8-9.1-13.8c-0.3-0.1-0.6-0.2-0.8-0.2
                                        c-0.2,4.7-1.2,13.1-5.3,19.5c-6.1,9.4,13.3,7.1,18.5,5.3c4.1-1.4,12.7-5.6,17.2-12.2C292.4,333.7,290.9,333.4,289.5,333.6z"/>
                                    <path fill="#A8A69E" d="M223.6,346.6c5.3,1.8,24.6,4.1,18.5-5.3c-4.1-6.4-5.1-14.8-5.3-19.5c-0.3,0.1-0.6,0.2-0.9,0.2
                                        c-4.2,2.9-2.7,11.9-9.1,13.8c-0.2,0-0.2,0-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7,0.1-1.3,0.1c-0.1,0-0.1,0-0.3,0
                                        c0,0-0.1,0-0.1,0c-4,0.2-8.7-1.2-13.3-2.6c0,0-0.1,0-0.1,0c-1.7-0.1-3,0.1-4.1,0.7C210.7,340.9,219.4,345.2,223.6,346.6z"/>
                                    <path fill="#302A2A" d="M259.8,338.8c-6.5,0-9.4,2.3-9.8,3.1c-0.4-0.9-3.4-3.1-9.8-3.1c-5.8,0-3.9,10,0.7,13.9
                                        c4.4,3.7,8.5,4.2,9.1,4.3v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.6-0.1,4.7-0.6,9.1-4.3C263.8,348.8,265.6,338.8,259.8,338.8z"/>
                                    <g>
                                        <path fill="#231E1E" d="M241.3,342.2c-0.2,3.7,1.9,6.2,3.9,5.6C250.6,342.8,243.7,341.4,241.3,342.2z"/>
                                        <path fill="#231E1E" d="M254.8,347.9c2.1,0.6,4.1-2,3.9-5.6C256.3,341.4,249.4,342.8,254.8,347.9z"/>
                                    </g>
                                    <g>
                                        <path fill="#FFFFFF" d="M260.7,326.3c-0.3,0.8-4.9-0.5-10.7-0.4c-5.8-0.1-10.4,1.1-10.6,0.4c-0.1-0.3,0.9-1.1,2.8-1.8
                                            c1.9-0.7,4.7-1.3,7.8-1.3c3.1,0,5.9,0.6,7.8,1.3C259.7,325.2,260.8,326,260.7,326.3z"/>
                                    </g>
                                </g>
                            </g>
                            <g>
                                
                                    <linearGradient id="SVGID_69_" gradientUnits="userSpaceOnUse" x1="3780.116" y1="574.9597" x2="3778.855" y2="551.0016" gradientTransform="matrix(-0.9995 -3.020000e-02 -3.020000e-02 0.9995 4094.8784 60.2872)">
                                    <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                    <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                    <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                    <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                    <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                </linearGradient>
                                <path opacity="0.5" fill="url(#SVGID_69_)" d="M317,517.4c0,0-1.3-15.1-6.5-19.8c-1.2-1.1-8.9,0.3-20.2,2.8c0,0-6.8,5.6-8.2,16.9
                                    C282.1,517.3,301.2,523.3,317,517.4z"/>
                                
                                    <linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse" x1="9796.5137" y1="756.7458" x2="9795.252" y2="732.7877" gradientTransform="matrix(0.9995 -3.020000e-02 3.020000e-02 0.9995 -9613.9277 60.2872)">
                                    <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                    <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                    <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                    <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                    <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                </linearGradient>
                                <path opacity="0.5" fill="url(#SVGID_70_)" d="M182.8,517.4c0,0,1.3-15.1,6.5-19.8c1.2-1.1,8.9,0.3,20.2,2.8c0,0,6.8,5.6,8.2,16.9
                                    C217.7,517.3,198.6,523.3,182.8,517.4z"/>
                                <g>
                                    <path fill="#231E1E" d="M316.9,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                        C313.8,515.7,315.2,517.6,316.9,517.5z"/>
                                    <path fill="#302A2A" d="M311.1,520l4.8,0c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.2-1.1-2.9-1-3.4,0.3l-1.1,2.8
                                        C308,518.1,309.4,520,311.1,520z"/>
                                </g>
                                <g>
                                    <path fill="#231E1E" d="M183.1,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                        C186.2,515.7,184.8,517.6,183.1,517.5z"/>
                                    <path fill="#302A2A" d="M188.9,520l-4.8,0c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.9-1,3.4,0.3l1.1,2.8
                                        C192,518.1,190.6,520,188.9,520z"/>
                                </g>
                            </g>
                            <path opacity="0.2" fill="#FFFFFF" d="M250,469.1c61.8,0,58.3-59.9,49.8-98.1c-5.2,1.1-10.6,2-16.3,2.7c-3.1,0.4-6.2,0.7-9.4,1
                                c-7.6,0.6-15.7,1-24.1,1c-8.5,0-16.5-0.3-24.1-1c-3.1-0.3-6.1-0.6-9.1-1c-5.8-0.7-11.3-1.6-16.6-2.7
                                C191.7,409.2,188.2,469.1,250,469.1z"/>
                        </svg>
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                    <path fill="#302A2A" d="M120.1,313.2c-1.1-2-2.2-4.2-3.2-6.5C113.7,312,115.4,314.2,120.1,313.2z"/>
                    <path fill="#302A2A" d="M384.7,310.8c0-1.1-0.6-2.6-1.6-4.2c-1.1,2.3-2.1,4.5-3.3,6.6C382.6,313.9,384.7,313.5,384.7,310.8z"/>
                    <path fill="#302A2A" d="M142.1,420.6c-2-1.8-3.8-4.2-5.2-7.5c-2.2-5-2-9.4-0.1-13.4c-1.9-10.1-2.9-20.7-2.9-31.5
                        c0-11.2,1.1-21.9,3.1-32c-0.9-1.1-1.8-2.3-2.7-3.5c-2-2-7.2-7.6-12.4-16.4c-15.3,15.2-21.2,31.6-0.8,23
                        c-20.7,15.8-23.8,39.7-3.2,28.3c0,0-18.9,25.9-16.9,34.3c2,8.4,19,0,19,0s-7.5,23.2-7.5,30.1c0,6.9,13,0,13,0s-5.4,26.1-0.4,30.7
                        s13.2-4.8,13.2-4.8c3.8,29.3,17.2,39,13.3,16.5c16.5,34.4,33.3,30.4,31.7,15.2h6.6c0-0.2,0-0.5-0.1-0.7
                        C168.3,473.1,151.5,449.1,142.1,420.6z"/>
                    <path fill="#302A2A" d="M382.1,367.6c0,0,13.9,7.7,11-1.2c-3-8.9-14.2-27.1-14.2-27.1s11.8,5,11.8-1.3c0-3.5-7.2-12.8-13.5-20.2
                        c-4.9,7.9-9.5,13-11.4,14.9c-0.9,1.2-1.8,2.3-2.7,3.5c2,10.1,3.1,20.7,3.1,32c0,51.2-22.4,96-56,120.8c0,0.2,0,0.4-0.1,0.7h6.7
                        c-1.6,15.2,15.1,19.2,31.7-15.2c-3.9,22.5,9.4,12.8,13.3-16.5c0,0,8.2,9.4,13.2,4.8c5-4.6-0.8-26.1-0.8-26.1
                        c24.9,7.5,19.6-14.5,6-34.7c0,0,17,8.4,19,0C401,393.5,382.1,367.6,382.1,367.6z"/>
                    <polygon fill="#231E1E" points="200.2,371 200.2,371 200.2,371 	"/>
                    <path fill="#231E1E" d="M136.7,399.7c8.4-18.3,51.7-26.4,61.8-28.1c0.1-0.3,0.2-0.6,0.2-0.9c-27.9-6.1-48.3-17.9-61.8-34.5
                        c-2,10.1-3.1,20.7-3.1,32C133.8,379,134.8,389.6,136.7,399.7z"/>
                    <path fill="#231E1E" d="M273.3,489.1c-11.5,1.6-23.3,1.8-23.3,1.8s-11.8-0.2-23.3-1.8l-0.2,0.4c-0.3,5.3-1.1,11.1-2.6,16.3
                        c8.4,2.3,17.1,3.6,26.1,3.6c8.9,0,17.6-1.2,25.9-3.5C274.4,500.5,273.6,494.5,273.3,489.1z"/>
                    <path fill="#231E1E" d="M305.7,369.7c8.9,8.9,51.9,54.1,25.9,69.7c-8.3,5-15.1,3.5-20.6-1c0.3,6.1,0.4,12.4,0.3,19
                        c0,0,0,19.3-1.2,31.6c33.6-24.7,56-69.6,56-120.8c0-11.3-1.1-21.9-3.1-32C350.3,351.9,331.4,363.3,305.7,369.7z"/>
                    <path fill="#231E1E" d="M189.7,488.9c-1.1-12.3-1.1-31.5-1.1-31.5c0-4.7,0-9.2,0.1-13.6c0,0.2,0,0.4,0.1,0.6
                        c0.4-15.5,1.8-29.5,3.6-41.8c-3.4,3.2-34.5,31.8-50.3,18.1C151.5,449.1,168.3,473.1,189.7,488.9z"/>
                    <path fill={backGroundColor} d="M311.4,457.3c0.1-6.6-0.1-12.9-0.3-19c5.4,4.6,12.2,6,20.6,1c26.1-15.6-17-60.8-25.9-69.7
                        c25.7-6.4,44.5-17.7,57.3-33.5c0.9-1.1,1.8-2.3,2.7-3.5c1.9-1.9,6.6-6.9,11.4-14.9c0.9-1.4,1.8-3,2.7-4.6c1.1-2,2.2-4.2,3.3-6.6
                        c3.3-7.2,6.1-15.8,7.7-25.6c0,0-4.4,1.6-7.6,6.2c0,0,9.6-16.5,9-35.8c0,0-3.9-0.3-6.9,4.3c0,0,4.7-10.3,1.9-30.3
                        c0,0-5.6,1.2-7.6,6.2c0,0,3.6-14.3-1.1-28.4c0,0-3.5,2.1-5.3,6.5c0,0,0.7-10.3-4.2-22.1c0,0-2.7,2.3-4.1,6.4
                        c0,0-2.5-20.2-14.6-30.1c0,0-1.3,15.9,0.4,25.6c0,0-5.1-24-19.4-32.3c0,0-0.5,7.2,0.4,20.4C309.1,161,280.7,150,250,150
                        c-30.7,0-59.1,11.1-81.7,27.7c0.9-13.2,0.4-20.4,0.4-20.4c-14.3,8.3-19.4,32.3-19.4,32.3c1.7-9.8,0.4-25.6,0.4-25.6
                        c-12.1,9.9-14.6,30.1-14.6,30.1c-1.4-4-4.1-6.4-4.1-6.4c-4.9,11.9-4.2,22.1-4.2,22.1c-1.8-4.4-5.3-6.5-5.3-6.5
                        c-4.6,14.1-1.1,28.4-1.1,28.4c-2-5-7.6-6.2-7.6-6.2c-2.8,20,1.9,30.3,1.9,30.3c-2.9-4.7-6.9-4.3-6.9-4.3c-0.6,19.2,9,35.8,9,35.8
                        c-3.1-4.5-7.6-6.2-7.6-6.2c1.6,9.9,4.4,18.5,7.7,25.7c1,2.3,2.1,4.5,3.2,6.5c0.6,1.1,1.2,2.1,1.8,3.1c5.2,8.8,10.4,14.4,12.4,16.4
                        c0.9,1.2,1.7,2.3,2.7,3.5c13.5,16.7,33.8,28.4,61.8,34.5c-0.1,0.3-0.2,0.6-0.2,0.9c-10.1,1.7-53.4,9.8-61.8,28.1
                        c-1.8,4-2,8.4,0.1,13.4c1.4,3.3,3.2,5.7,5.2,7.5c15.8,13.7,46.8-14.8,50.3-18.1c-1.8,12.2-3.2,26.2-3.6,41.8c0-0.2-0.1-0.4-0.1-0.6
                        c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0,19.3,1.1,31.5c0,0.3,0,0.5,0.1,0.7c0.1,0.7,0.1,1.4,0.2,2.1c0.2,1.8,0.5,3.7,0.9,5.5c0,0,0,0,0,0
                        c-0.8,0-1.3,0.1-1.6,0.4c-5.2,4.8-6.5,19.8-6.5,19.8c15.8,5.9,34.9-0.2,34.9-0.2c0,0,0,0,0,0c0,0,0,0,0,0c2.9-2.6,4.8-6.7,6.2-11.4
                        c1.5-5.1,2.2-11,2.6-16.3l0.2-0.4c11.5,1.6,23.3,1.8,23.3,1.8s11.8-0.2,23.3-1.8c0.3,5.4,1.1,11.4,2.6,16.7
                        c1.3,4.7,3.3,8.8,6.1,11.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15.1-6.5-19.8c-0.3-0.2-0.8-0.4-1.6-0.4
                        c0-0.1,0-0.3,0.1-0.4c0.4-1.6,0.7-3.3,0.9-5.1c0.1-0.7,0.1-1.3,0.2-2.1c0-0.2,0-0.4,0.1-0.7C311.3,476.7,311.4,457.3,311.4,457.3z
                        M200.2,371C200.2,371,200.2,371,200.2,371L200.2,371C200.2,371,200.2,371,200.2,371z"/>
                    <path opacity="0.2" fill="#161616" d="M306,369.6c27.4-6.9,47-19.4,59.7-36.9c4-4,20.4-22,25.1-51.7c0,0-4.4,1.6-7.6,6.2
                        c0,0,9.6-16.5,9-35.8c0,0-3.9-0.3-6.9,4.3c0,0,4.7-10.3,1.9-30.3c0,0-5.6,1.2-7.6,6.2c0,0,3.6-14.3-1.1-28.4c0,0-3.5,2.1-5.3,6.5
                        c0,0,0.7-10.3-4.2-22.1c0,0-2.7,2.3-4.1,6.4c0,0-2.5-20.2-14.6-30.1c0,0-1.3,15.9,0.4,25.6c0,0-5.1-24-19.4-32.3
                        c0,0-0.5,7.2,0.4,20.4c-3-2.2-6.1-4.3-9.3-6.3c-3.6,3.8-8.4,9.2-14,16.1c5.8-3,10.5-0.2,10.5-0.2c-17.3,7.9-32.4,30.3-33.2,31.5
                        c0,0,0,0,0,0.1c3.3-2.2,6.9-2,6.9-2c-12.4,9.7-22.6,32-22.7,32c0,0,0,0,0,0c2.2-2.3,5.2-2.8,5.2-2.8c-9,9.2-13.9,33.7-14.2,35.4
                        c0,0,0,0.1,0,0.1c2.1-2.6,4.5-1.9,4.5-1.9c-4.9,11.3-5.4,23-4.4,31.5C265.4,341.2,282.8,363.9,306,369.6z"/>
                    <path opacity="0.2" fill="#161616" d="M168.7,157.3c-14.3,8.3-19.4,32.3-19.4,32.3c1.7-9.8,0.4-25.6,0.4-25.6
                        c-12.1,9.9-14.6,30.1-14.6,30.1c-1.4-4-4.1-6.4-4.1-6.4c-4.9,11.9-4.2,22.1-4.2,22.1c-1.8-4.4-5.3-6.5-5.3-6.5
                        c-4.6,14.1-1.1,28.4-1.1,28.4c-2-5-7.6-6.2-7.6-6.2c-2.8,20,1.9,30.3,1.9,30.3c-2.9-4.7-6.9-4.3-6.9-4.3c-0.6,19.2,9,35.8,9,35.8
                        c-3.1-4.5-7.6-6.2-7.6-6.2c4.7,29.7,21.1,47.7,25.1,51.7c12.8,17.5,32.4,30,59.7,36.9c23.2-5.7,40.6-28.4,44.9-58.6
                        c1-8.4,0.6-20.2-4.4-31.5c0,0,2.3-0.7,4.5,1.9c0,0,0-0.1,0-0.1c-0.3-1.7-5.2-26.2-14.2-35.4c0,0,2.9,0.4,5.2,2.8c0,0,0,0,0,0
                        c0,0-10.3-22.4-22.7-32c0,0,3.6-0.1,6.9,2c0,0,0,0,0-0.1c-0.8-1.2-15.8-23.6-33.2-31.5c0,0,4.7-2.8,10.5,0.2
                        c-5.6-6.9-10.4-12.2-14-16.1c-3.2,2-6.3,4.1-9.3,6.3C169.2,164.5,168.7,157.3,168.7,157.3z"/>
                    <g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" d="M334.6,330.2c2.5-2.2,4.7-4.8,6.4-7.9c7.6-13.8,2.6-31.1-11.1-38.7c-13.8-7.6-31.1-2.6-38.7,11.1
                                    c-7.6,13.8-2.6,31.1,11.1,38.7c0.1,0,0.2,0.1,0.3,0.1C312.3,328.4,323.7,327.1,334.6,330.2z"/>
                                <path fill="#21323F" d="M317,309.7c-3.2,0-5.8-2.6-5.8-5.8v0c0-3.1,2.5-5.6,5.5-5.8c-2.6-2.1-5.8-3.3-9.3-3.3
                                    c-8.2,0-14.9,6.7-14.9,14.9c0,8.2,6.7,14.9,14.9,14.9c8.2,0,14.9-6.7,14.9-14.9H317z"/>
                                <g>
                                    <path fill="#F9B7A8" d="M315.3,337c-1.7,3.1-0.6,7,2.5,8.8c3.1,1.7,7,0.6,8.8-2.5c1.7-3.1,0.6-7-2.5-8.8
                                        C321,332.7,317,333.9,315.3,337z"/>
                                    <circle fill="#F9B7A8" cx="317.3" cy="353.4" r="3.2"/>
                                    <circle fill="#F9B7A8" cx="329.7" cy="349.8" r="3.2"/>
                                </g>
                            </g>
                            <g>
                                <path fill="#FFFFFF" d="M165.4,330.2c-2.5-2.2-4.7-4.8-6.4-7.9c-7.6-13.8-2.6-31.1,11.1-38.7c13.8-7.6,31.1-2.6,38.7,11.1
                                    c7.6,13.8,2.6,31.1-11.1,38.7c-0.1,0-0.2,0.1-0.3,0.1C187.7,328.4,176.3,327.1,165.4,330.2z"/>
                                <path fill="#21323F" d="M183,309.7c3.2,0,5.8-2.6,5.8-5.8v0c0-3.1-2.5-5.6-5.5-5.8c2.6-2.1,5.8-3.3,9.3-3.3
                                    c8.2,0,14.9,6.7,14.9,14.9c0,8.2-6.7,14.9-14.9,14.9c-8.2,0-14.9-6.7-14.9-14.9H183z"/>
                                <g>
                                    <path fill="#F9B7A8" d="M184.7,337c1.7,3.1,0.6,7-2.5,8.8c-3.1,1.7-7,0.6-8.8-2.5c-1.7-3.1-0.6-7,2.5-8.8
                                        C179,332.7,183,333.9,184.7,337z"/>
                                    <circle fill="#F9B7A8" cx="182.7" cy="353.4" r="3.2"/>
                                    <circle fill="#F9B7A8" cx="170.3" cy="349.8" r="3.2"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path fill="#999688" d="M297.1,338.6c-0.9-2.9-4.2-5.5-7.6-4.9c0,0-0.1,0-0.1,0c-3.8,0.9-8.6,2.8-13.3,2.6c0,0-0.1,0-0.1,0
                                c-0.1,0-0.2,0-0.3,0c-0.6,0-1-0.1-1.3-0.1c-0.2,0-0.5-0.1-0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1c-5.8-1.5-5.1-10.8-9.1-13.8
                                c-3.8-1.1-7.2-1.2-11.6-1.3c-1.3,0-2.8,0-5.1,0c-2.9,0.1-7.8,0.1-11.6,1.3c-4.2,2.9-2.7,11.9-9.1,13.8c-0.2,0-0.2,0-0.3,0.1
                                c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7,0.1-1.3,0.1c-0.1,0-0.1,0-0.3,0c0,0-0.1,0-0.1,0c-4,0.2-8.7-1.2-13.3-2.6c0,0-0.1,0-0.1,0
                                c-8.6-0.7-9.1,9.4-6.7,12.4c7.9,12.8,31.5,15.1,46.2,15.8C267.3,361.8,301.5,355.1,297.1,338.6z"/>
                            <path fill="#A8A69E" d="M289.5,333.6c0,0-0.1,0-0.1,0c-3.8,0.9-8.6,2.8-13.3,2.6c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
                                c-0.6,0-1-0.1-1.3-0.1c-0.2,0-0.5-0.1-0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1c-5.8-1.5-5.1-10.8-9.1-13.8c-0.3-0.1-0.6-0.2-0.8-0.2
                                c-0.2,4.7-1.2,13.1-5.3,19.5c-6.1,9.4,13.3,7.1,18.5,5.3c4.1-1.4,12.7-5.6,17.2-12.2C292.4,333.7,290.9,333.4,289.5,333.6z"/>
                            <path fill="#A8A69E" d="M223.6,346.6c5.3,1.8,24.6,4.1,18.5-5.3c-4.1-6.4-5.1-14.8-5.3-19.5c-0.3,0.1-0.6,0.2-0.9,0.2
                                c-4.2,2.9-2.7,11.9-9.1,13.8c-0.2,0-0.2,0-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7,0.1-1.3,0.1c-0.1,0-0.1,0-0.3,0
                                c0,0-0.1,0-0.1,0c-4,0.2-8.7-1.2-13.3-2.6c0,0-0.1,0-0.1,0c-1.7-0.1-3,0.1-4.1,0.7C210.7,340.9,219.4,345.2,223.6,346.6z"/>
                            <path fill="#302A2A" d="M259.8,338.8c-6.5,0-9.4,2.3-9.8,3.1c-0.4-0.9-3.4-3.1-9.8-3.1c-5.8,0-3.9,10,0.7,13.9
                                c4.4,3.7,8.5,4.2,9.1,4.3v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.6-0.1,4.7-0.6,9.1-4.3C263.8,348.8,265.6,338.8,259.8,338.8z"/>
                            <g>
                                <path fill="#231E1E" d="M241.3,342.2c-0.2,3.7,1.9,6.2,3.9,5.6C250.6,342.8,243.7,341.4,241.3,342.2z"/>
                                <path fill="#231E1E" d="M254.8,347.9c2.1,0.6,4.1-2,3.9-5.6C256.3,341.4,249.4,342.8,254.8,347.9z"/>
                            </g>
                            <g>
                                <path fill="#FFFFFF" d="M260.7,326.3c-0.3,0.8-4.9-0.5-10.7-0.4c-5.8-0.1-10.4,1.1-10.6,0.4c-0.1-0.3,0.9-1.1,2.8-1.8
                                    c1.9-0.7,4.7-1.3,7.8-1.3c3.1,0,5.9,0.6,7.8,1.3C259.7,325.2,260.8,326,260.7,326.3z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        
                            <linearGradient id="SVGID_67_" gradientUnits="userSpaceOnUse" x1="3780.116" y1="574.9597" x2="3778.855" y2="551.0016" gradientTransform="matrix(-0.9995 -3.020000e-02 -3.020000e-02 0.9995 4094.8784 60.2872)">
                            <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                            <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                            <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                            <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                            <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                        </linearGradient>
                        <path opacity="0.5" fill="url(#SVGID_67_)" d="M317,517.4c0,0-1.3-15.1-6.5-19.8c-1.2-1.1-8.9,0.3-20.2,2.8c0,0-6.8,5.6-8.2,16.9
                            C282.1,517.3,301.2,523.3,317,517.4z"/>
                        
                            <linearGradient id="SVGID_68_" gradientUnits="userSpaceOnUse" x1="9796.5137" y1="756.7458" x2="9795.252" y2="732.7877" gradientTransform="matrix(0.9995 -3.020000e-02 3.020000e-02 0.9995 -9613.9277 60.2872)">
                            <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                            <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                            <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                            <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                            <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                        </linearGradient>
                        <path opacity="0.5" fill="url(#SVGID_68_)" d="M182.8,517.4c0,0,1.3-15.1,6.5-19.8c1.2-1.1,8.9,0.3,20.2,2.8c0,0,6.8,5.6,8.2,16.9
                            C217.7,517.3,198.6,523.3,182.8,517.4z"/>
                        <g>
                            <path fill="#231E1E" d="M316.9,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                C313.8,515.7,315.2,517.6,316.9,517.5z"/>
                            <path fill="#302A2A" d="M311.1,520l4.8,0c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.2-1.1-2.9-1-3.4,0.3l-1.1,2.8
                                C308,518.1,309.4,520,311.1,520z"/>
                        </g>
                        <g>
                            <path fill="#231E1E" d="M183.1,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                C186.2,515.7,184.8,517.6,183.1,517.5z"/>
                            <path fill="#302A2A" d="M188.9,520l-4.8,0c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.9-1,3.4,0.3l1.1,2.8
                                C192,518.1,190.6,520,188.9,520z"/>
                        </g>
                    </g>
                    <path opacity="0.2" fill="#FFFFFF" d="M250,469.1c61.8,0,58.3-59.9,49.8-98.1c-5.2,1.1-10.6,2-16.3,2.7c-3.1,0.4-6.2,0.7-9.4,1
                        c-7.6,0.6-15.7,1-24.1,1c-8.5,0-16.5-0.3-24.1-1c-3.1-0.3-6.1-0.6-9.1-1c-5.8-0.7-11.3-1.6-16.6-2.7
                        C191.7,409.2,188.3,469.1,250,469.1z"/>
                </svg>
 
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
            const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                            <path fill="none" d="M297.7,176.6C297.7,176.6,297.7,176.6,297.7,176.6C297.7,176.6,297.7,176.6,297.7,176.6
                                    C297.7,176.6,297.7,176.6,297.7,176.6z"/>
                                <g>
                                    <path fill="#302A2A" d="M136.5,353.3c0-32.6,8-60.8,21.4-82.9c-1.8-2.3-3.4-4.6-4.8-7.1c-2.1-2.1-7.3-7.9-12.3-17.1
                                        c-6.2,4.2-11.2,8.4-11.2,11.1c0,7.2,14.3,6,14.3,6c-35.9,29.9-29.2,40-9.2,25.9c-21.5,17.4-34.5,44.5-10.6,33.6
                                        c-20.2,16.7-23.2,42-3.1,30c0,0-18.5,27.4-16.5,36.3c1.9,8.9,18.6,0,18.6,0s-7.3,24.6-7.3,31.9c0,7.3,12.7,0,12.7,0
                                        s-5.3,27.6-0.4,32.5c4.9,4.9,12.9-5.1,12.9-5.1c3.8,30.9,16.8,41.3,12.9,17.4c16.1,36.4,32.5,32.1,30.9,16.1h5.4
                                        c0-0.5-0.1-1.1-0.1-1.7C157.9,453.8,136.5,406.8,136.5,353.3z"/>
                                    <path fill="#302A2A" d="M379,352.7c0,0,13.6,8.1,10.7-1.3c-2.9-9.4-13.8-28.6-13.8-28.6s11.5,5.2,11.5-1.4
                                        c0-6.6-22.2-32.2-22.2-32.2s16.3,11.5,16.3,3.5c0-8-25.5-29.4-25.5-29.4c8.5,0.8,20.9-7.2,4.2-18.6c-5.2,10-10.9,16.4-13.1,18.6
                                        c-1.5,2.5-3.1,5-5,7.3c13.4,22.1,21.3,50.2,21.3,82.8c0,53.5-21.4,100.4-53.5,126.8c0,0.6-0.1,1.1-0.1,1.7h5.4
                                        c-1.6,16.1,14.8,20.3,30.9-16.1c-3.8,23.8,9.2,13.5,12.9-17.4c0,0,8,9.9,12.9,5.1c4.9-4.9-0.8-27.6-0.8-27.6
                                        c24.3,7.9,19.2-15.3,5.8-36.7c0,0,16.6,8.9,18.6,0C397.5,380.1,379,352.7,379,352.7z"/>
                                    <path fill="#231E1E" d="M281.8,301.2l2.2,7.5l8,2.9c9.5,0.7,33.2,4.8,36,28.4c5.2,44.2,26.9,85.4,7.5,95.4
                                        c-9,4.6-16.6-0.4-22.9-9.7c-0.4,10.4-1.6,37.4-2.6,54.4c32.1-26.4,53.5-73.3,53.5-126.8c0-32.5-7.9-60.6-21.3-82.8
                                        C329.8,286.4,310,296.9,281.8,301.2z"/>
                                    <path fill="#231E1E" d="M250,488.7c-0.9,0-7.7,0.3-18.8-2.1c0,0-1,5.9-3.8,13c7.3,1.9,14.8,3,22.6,3c7.7,0,15.3-1,22.6-3
                                        c-2.8-7.1-3.8-13.1-3.8-13.1C257.7,489,250.9,488.8,250,488.7z"/>
                                    <path fill="#231E1E" d="M190,480.1c-1.1-17.1-2.2-44.5-2.6-54.6c-6.3,9.4-14,14.5-23.1,9.9c-19.4-10,2.3-51.2,7.5-95.4
                                        c2.8-23.8,26.8-27.8,36.2-28.4l7.9-2.9l2.2-7.5c-28.2-4.3-48-14.9-60.3-30.8c-13.5,22.1-21.4,50.3-21.4,82.9
                                        C136.5,406.8,157.9,453.8,190,480.1z"/>
                                    <path fill={backGroundColor} d="M312.6,425.7c6.3,9.2,14,14.3,22.9,9.7c19.4-10-2.3-51.2-7.5-95.4c-2.8-23.7-26.5-27.7-36-28.4l-8-2.9
                                        l-2.2-7.5c28.2-4.3,48-14.8,60.4-30.7c1.8-2.3,3.5-4.7,5-7.3c2.2-2.3,7.9-8.6,13.1-18.6c3.6-7,7-15.9,8.6-26.5
                                        c0,0-3.9,1.4-6.6,5.4c0,0,8.4-14.5,7.9-31.3c0,0-3.4-0.3-6,3.8c0,0,4.1-9,1.6-26.5c0,0-4.9,1.1-6.7,5.4c0,0,3.1-12.5-0.9-24.8
                                        c0,0-3.1,1.8-4.6,5.7c0,0,0.6-9-3.7-19.4c0,0-2.4,2-3.6,5.6c0,0-2.2-17.7-12.8-26.4c0,0-1.1,13.9,0.4,22.5c0,0-4.5-21-17-28.3
                                        c0,0-0.9,13.1,2.3,36.2c-18.7-14.7-42.7-24.6-68.9-24.6c-26.2,0-50.2,9.9-68.9,24.6c3.2-23,2.3-36.2,2.3-36.2
                                        c-12.6,7.2-17,28.3-17,28.3c1.5-8.6,0.4-22.5,0.4-22.5c-10.6,8.7-12.8,26.4-12.8,26.4c-1.3-3.5-3.6-5.6-3.6-5.6
                                        c-4.3,10.4-3.7,19.4-3.7,19.4c-1.6-3.9-4.6-5.7-4.6-5.7c-4.1,12.3-0.9,24.8-0.9,24.8c-1.8-4.4-6.7-5.4-6.7-5.4
                                        c-2.4,17.5,1.6,26.5,1.6,26.5c-2.6-4.1-6-3.8-6-3.8c-0.5,16.8,7.9,31.3,7.9,31.3c-2.8-4-6.6-5.4-6.6-5.4c1.8,11.4,5.5,20.8,9.5,28
                                        c4.9,9.1,10.2,14.9,12.3,17.1c1.5,2.5,3.1,4.8,4.8,7.1c12.4,15.9,32.1,26.5,60.3,30.8l-2.2,7.5l-7.9,2.9
                                        c-9.4,0.6-33.4,4.6-36.2,28.4c-5.2,44.2-26.9,85.4-7.5,95.4c9,4.6,16.7-0.5,23.1-9.9c0.4,10.2,1.6,37.5,2.6,54.6
                                        c0,0.6,0.1,1.1,0.1,1.7c0.1,2,0.3,3.9,0.4,5.6c0.3,3.6,0.6,7,1.1,10c-1.8-0.1-2.9-0.1-3.3,0.3c-5.2,4.8-6.5,19.8-6.5,19.8
                                        c15.6,5.8,34.3,0,34.8-0.2l0,0c2.4-1.9,4.5-4.8,6.2-8.3c1.9-3.1,3.4-6.3,4.6-9.3c2.8-7.1,3.8-13,3.8-13c11,2.3,17.9,2.1,18.8,2.1
                                        c0.9,0,7.7,0.3,18.8-2.1c0,0,1,5.9,3.8,13.1c1.2,3.1,2.8,6.4,4.8,9.5l0,0c1.6,3.3,3.6,6.1,5.9,7.9c0,0,0,0.1,0,0.1
                                        c0,0,19,6,34.8,0.2c0,0-1.3-15-6.5-19.8c-0.4-0.4-1.5-0.5-3.2-0.3c0.5-3,0.8-6.3,1.1-10c0.1-1.6,0.3-3.5,0.4-5.6
                                        c0-0.5,0.1-1.1,0.1-1.7C311,463.1,312.1,436,312.6,425.7z"/>
                                </g>
                                <polygon fill="none" points="279.5,197.8 279.5,197.8 279.5,197.8 	"/>
                                <path fill="none" d="M266.3,222.5C266.3,222.5,266.3,222.4,266.3,222.5C266.3,222.5,266.3,222.5,266.3,222.5
                                    C266.3,222.5,266.3,222.5,266.3,222.5z"/>
                                <path opacity="0.2" fill="#161616" d="M347.1,263.3c3.8-3.8,17.7-19.5,21.8-45.1c0,0-3.9,1.4-6.6,5.4c0,0,8.4-14.5,7.9-31.3
                                    c0,0-3.4-0.3-6,3.8c0,0,4.1-9,1.6-26.5c0,0-4.9,1.1-6.7,5.4c0,0,3.1-12.5-0.9-24.8c0,0-3.1,1.8-4.6,5.7c0,0,0.6-9-3.7-19.4
                                    c0,0-2.4,2-3.6,5.6c0,0-2.2-17.7-12.8-26.4c0,0-1.1,13.9,0.4,22.5c0,0-4.5-21-17-28.3c0,0-0.9,13.1,2.3,36.2
                                    c-1.1-0.9-2.3-1.8-3.5-2.6c-2.9,2.9-8.2,8.5-14.9,16.8c4.8-2.5,8.7-0.2,8.7-0.2c-14.3,6.5-26.7,25-27.3,25.9c0,0,0,0,0,0
                                    c2.7-1.8,5.7-1.7,5.7-1.7c-10.2,8-18.7,26.4-18.7,26.4c0,0,0,0,0,0c1.8-1.9,4.3-2.3,4.3-2.3c-7.4,7.5-11.4,27.8-11.7,29.2
                                    c0,0,0,0,0,0.1c1.7-2.1,3.7-1.6,3.7-1.6c-4.1,9.3-4.4,19-3.6,25.9c2.4,16.7,9.6,30.5,19.8,39.3C314,296.4,335.3,283.3,347.1,263.3z
                                    "/>
                                <path opacity="0.2" fill="#161616" d="M235.4,236c0,0,1.9-0.5,3.7,1.6c0,0,0,0,0-0.1c-0.3-1.4-4.3-21.6-11.7-29.2
                                    c0,0,2.4,0.4,4.3,2.3c0,0,0,0,0,0c0,0-8.5-18.4-18.7-26.4c0,0,3-0.1,5.7,1.7c0,0,0,0,0,0c-0.7-1-13.1-19.4-27.3-25.9
                                    c0,0,3.9-2.3,8.7,0.2c-6.8-8.4-12.2-14.1-15.1-16.9c-1.2,0.9-2.5,1.8-3.7,2.8c3.2-23,2.3-36.2,2.3-36.2c-12.6,7.2-17,28.3-17,28.3
                                    c1.5-8.6,0.4-22.5,0.4-22.5c-10.6,8.7-12.8,26.4-12.8,26.4c-1.3-3.5-3.6-5.6-3.6-5.6c-4.3,10.4-3.7,19.4-3.7,19.4
                                    c-1.6-3.9-4.6-5.7-4.6-5.7c-4.1,12.3-0.9,24.8-0.9,24.8c-1.8-4.4-6.7-5.4-6.7-5.4c-2.4,17.5,1.6,26.5,1.6,26.5
                                    c-2.6-4.1-6-3.8-6-3.8c-0.5,16.8,7.9,31.3,7.9,31.3c-2.8-4-6.6-5.4-6.6-5.4c4,25.6,18,41.3,21.8,45.1c11.8,20.1,33.3,33.2,66,38
                                    c10.3-8.8,17.5-22.6,19.9-39.3C239.8,255,239.4,245.3,235.4,236z"/>
                                <path fill="#FFFFFF" d="M330,265.1c2.4-2,4.4-4.5,6-7.4c7.2-13,2.5-29.3-10.5-36.5c-13-7.2-29.4-2.5-36.5,10.5
                                    c-7.2,13-2.5,29.4,10.5,36.5c0.1,0,0.2,0.1,0.2,0.1C308.9,263.4,319.8,262.1,330,265.1z"/>
                                <path fill="#21323F" d="M313.4,245.7c-3,0-5.5-2.5-5.5-5.5v0c0-2.9,2.3-5.3,5.2-5.4c-2.4-2-5.5-3.1-8.8-3.1c-7.7,0-14,6.3-14,14
                                    c0,7.8,6.3,14,14,14c7.8,0,14-6.3,14-14H313.4z"/>
                                <g>
                                    <g>
                                        <path fill="#F9B7A8" d="M312.8,270.1c-1.5,2.7-0.5,6,2.2,7.5c2.7,1.5,6,0.5,7.5-2.2c1.5-2.7,0.5-6-2.2-7.5
                                            C317.6,266.5,314.3,267.5,312.8,270.1z"/>
                                        <circle fill="#F9B7A8" cx="314.5" cy="284.2" r="2.8"/>
                                        <circle fill="#F9B7A8" cx="325.1" cy="281.1" r="2.8"/>
                                    </g>
                                    <g>
                                        <path fill="#F9B7A8" d="M187.4,270.1c1.5,2.7,0.5,6-2.2,7.5c-2.7,1.5-6,0.5-7.5-2.2c-1.5-2.7-0.5-6,2.2-7.5
                                            C182.6,266.5,186,267.5,187.4,270.1z"/>
                                        <circle fill="#F9B7A8" cx="185.7" cy="284.2" r="2.8"/>
                                        <circle fill="#F9B7A8" cx="175.1" cy="281.1" r="2.8"/>
                                    </g>
                                </g>
                                <path fill="#FFFFFF" d="M170.2,265.1c-2.4-2-4.4-4.5-6-7.4c-7.2-13-2.5-29.3,10.5-36.5c13-7.2,29.4-2.5,36.5,10.5
                                    c7.2,13,2.5,29.4-10.5,36.5c-0.1,0-0.2,0.1-0.2,0.1C191.3,263.4,180.4,262.1,170.2,265.1z"/>
                                <path fill="#21323F" d="M186.9,245.7c3,0,5.5-2.5,5.5-5.5v0c0-2.9-2.3-5.3-5.2-5.4c2.4-2,5.5-3.1,8.8-3.1c7.7,0,14,6.3,14,14
                                    c0,7.8-6.3,14-14,14c-7.8,0-14-6.3-14-14H186.9z"/>
                                <g>
                                    <path fill="#999688" d="M289.2,274.2c-0.7-2.4-3.5-4.5-6.3-4.1c0,0-0.1,0-0.1,0c-3.2,0.8-7.1,2.3-11.1,2.1c0,0-0.1,0-0.1,0
                                        c-0.1,0-0.2,0-0.2,0c-0.5,0-0.8-0.1-1.1-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.1,0-0.3-0.1c-4.8-1.2-4.2-9-7.5-11.5
                                        c-3.1-1-6-1-9.6-1.1c-1.1,0-2.4,0-4.2,0c-2.4,0.1-6.5,0.1-9.6,1.1c-3.5,2.4-2.2,9.8-7.5,11.5c-0.1,0-0.2,0-0.3,0.1
                                        c-0.3,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.6,0.1-1.1,0.1c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-3.3,0.2-7.2-1-11.1-2.1c0,0-0.1,0-0.1,0
                                        c-7.2-0.6-7.5,7.8-5.6,10.3c6.6,10.6,26.1,12.5,38.3,13.1C264.5,293.5,292.8,287.9,289.2,274.2z"/>
                                    <path fill="#A8A69E" d="M282.9,270.1C282.8,270.1,282.8,270.1,282.9,270.1c-3.2,0.8-7.2,2.3-11.1,2.1c0,0-0.1,0-0.1,0
                                        c-0.1,0-0.2,0-0.2,0c-0.5,0-0.8-0.1-1.1-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.1,0-0.3-0.1c-4.8-1.2-4.2-9-7.5-11.5
                                        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,3.9-1,10.8-4.4,16.1c-5,7.8,11,5.9,15.4,4.4c3.4-1.2,10.5-4.7,14.2-10.1
                                        C285.3,270.2,284.1,269.9,282.9,270.1z"/>
                                    <path fill="#A8A69E" d="M228.2,280.8c4.4,1.5,20.4,3.4,15.4-4.4c-3.4-5.3-4.3-12.3-4.4-16.2c-0.3,0.1-0.5,0.1-0.8,0.2
                                        c-3.5,2.4-2.2,9.8-7.5,11.5c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.6,0.1-1.1,0.1c0,0-0.1,0-0.2,0
                                        c0,0-0.1,0-0.1,0c-3.3,0.2-7.2-1-11.1-2.1c0,0-0.1,0-0.1,0c-1.4-0.1-2.5,0.1-3.4,0.6C217.6,276.1,224.8,279.7,228.2,280.8z"/>
                                    <path fill="#302A2A" d="M258.3,274.4c-5.3,0-7.8,1.9-8.1,2.6c-0.3-0.7-2.8-2.6-8.1-2.6c-4.8,0-3.3,8.3,0.6,11.5
                                        c3.6,3.1,7,3.5,7.6,3.6v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.5,0,3.9-0.5,7.6-3.6C261.5,282.7,263.1,274.4,258.3,274.4z"/>
                                    <g>
                                        <path fill="#231E1E" d="M242.9,277.2c-0.2,3,1.6,5.1,3.3,4.7C250.6,277.7,244.9,276.5,242.9,277.2z"/>
                                        <path fill="#231E1E" d="M254.1,281.9c1.7,0.5,3.4-1.6,3.3-4.7C255.3,276.5,249.6,277.7,254.1,281.9z"/>
                                    </g>
                                    <g>
                                        <path fill="#FFFFFF" d="M259,264c-0.2,0.6-4.1-0.4-8.8-0.4c-4.8-0.1-8.6,0.9-8.8,0.4c-0.1-0.3,0.7-1,2.3-1.5
                                            c1.6-0.6,3.9-1.1,6.5-1.1c2.6,0,4.9,0.5,6.5,1.1C258.2,263.1,259.1,263.7,259,264z"/>
                                    </g>
                                </g>
                                <path fill="#848484" d="M275.4,318.4"/>
                                <path opacity="0.2" fill="#FFFFFF" d="M303.8,357.5c-6.4-8-7.8-20.4-7.8-20.4c1.8,1.6,4,1.5,4,1.5c-1.5-1.1-3.7-3.9-5.8-6.8
                                    c-1.1-2.6-2.6-5-4.6-7c-0.3-0.5-0.5-0.8-0.5-0.8s0,0.1,0,0.3c-2.1-1.9-4.5-3.5-7.3-4.5v0c-10.3-3.7-21-5.6-31.8-5.6
                                    c-10.8,0-21.5,1.9-31.8,5.6l0,0c-2.8,1-5.2,2.5-7.3,4.5c0-0.2,0-0.3,0-0.3s-0.2,0.3-0.5,0.8c-2,2-3.6,4.4-4.6,7
                                    c-2,2.9-4.2,5.7-5.8,6.8c0,0,2.1,0.1,4-1.5c0,0-1.4,12.4-7.8,20.4c0,0,4.2,0.9,7-2.4c0,0-1.4,20.9-5.8,30.7c0,0,3.5,0.4,6.2-3.8
                                    c0,0,2.4,26.7,1.3,33.9c0,0,2.5-2.3,3.9-6.1c6.1,20.7,18.1,37,41.2,37c23.1,0,35.2-16.3,41.2-37c1.4,3.9,3.9,6.1,3.9,6.1
                                    c-1.1-7.2,1.3-33.9,1.3-33.9c2.7,4.2,6.2,3.8,6.2,3.8c-4.5-9.7-5.8-30.7-5.8-30.7C299.6,358.4,303.8,357.5,303.8,357.5z"/>
                                <g opacity="0.5">
                                    <linearGradient id="SVGID_73_" gradientUnits="userSpaceOnUse" x1="199.3499" y1="519.9918" x2="198.1307" y2="496.8258">
                                        <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                        <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                        <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                        <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                        <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_73_)" d="M216.5,516.7C216.5,516.7,216.5,516.7,216.5,516.7c-1.6-10.9-8.1-16.3-8.1-16.3
                                        c-7.3-1.6-13.1-2.8-16.6-3.1c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.3,0,34.8-0.2
                                        C216.6,517.1,216.6,516.9,216.5,516.7z"/>
                                    
                                        <linearGradient id="SVGID_74_" gradientUnits="userSpaceOnUse" x1="3779.0811" y1="574.925" x2="3777.824" y2="551.0388" gradientTransform="matrix(-0.9995 -3.020000e-02 -3.020000e-02 0.9995 4094.8784 60.2872)">
                                        <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                        <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                        <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                        <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                        <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_74_)" d="M318,517.4c0,0-1.3-15-6.5-19.8c-1.2-1.1-8.9,0.3-20.1,2.8c0,0-6.8,5.6-8.2,16.8
                                        C283.2,517.3,302.2,523.3,318,517.4z"/>
                                </g>
                                <path fill="#231E1E" d="M317.9,517.6l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                    C314.7,515.7,316.2,517.6,317.9,517.6z"/>
                                <path fill="#302A2A" d="M312.1,520l4.8,0c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.1-1.1-2.8-1-3.3,0.3l-1.1,2.8
                                    C309,518.1,310.4,520,312.1,520z"/>
                                <path fill="#231E1E" d="M182.1,517.6l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                    C185.3,515.7,183.8,517.6,182.1,517.6z"/>
                                <path fill="#302A2A" d="M187.9,520l-4.8,0c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.1-1.1,2.8-1,3.3,0.3l1.1,2.8
                                    C191,518.1,189.6,520,187.9,520z"/>
                            </svg>
   
   
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <path fill="#302A2A" d="M136.9,341.5c1.7-27.7,9.2-51.7,21.1-71.2c-1.8-2.3-3.4-4.6-4.8-7.1c-2.1-2.1-7.3-7.9-12.3-17.1
                                    c-6.2,4.2-11.2,8.4-11.2,11.1c0,7.2,14.3,6,14.3,6c-35.9,29.9-29.2,40-9.2,25.9c-21.5,17.4-34.5,44.5-10.6,33.6
                                    c-19,15.8-22.8,39.1-6.4,31.7C121.4,349.6,128.2,345.5,136.9,341.5z"/>
                                <path fill="#302A2A" d="M379,352.7c0,0,13.6,8.1,10.7-1.3c-2.9-9.4-13.8-28.6-13.8-28.6s11.5,5.2,11.5-1.4
                                    c0-6.6-22.2-32.2-22.2-32.2s16.3,11.5,16.3,3.5c0-8-25.5-29.4-25.5-29.4c8.5,0.8,20.9-7.2,4.2-18.6c-5.2,10-10.9,16.4-13.1,18.6
                                    c-1.5,2.5-3.1,5-5,7.3c13.4,22.1,21.3,50.2,21.3,82.8c0,53.5-21.4,100.4-53.5,126.8c0,0.6-0.1,1.1-0.1,1.7h5.4
                                    c-1.6,16.1,14.8,20.3,30.9-16.1c-3.8,23.8,9.2,13.5,12.9-17.4c0,0,8,9.9,12.9,5.1c4.9-4.9-0.8-27.6-0.8-27.6
                                    c24.3,7.9,19.2-15.3,5.8-36.7c0,0,16.6,8.9,18.6,0C397.5,380.1,379,352.7,379,352.7z"/>
                                <path fill="#302A2A" d="M138,377.8c-12.1,0.8-21.5-2.2-23.2-12.7c-0.1-0.8-0.2-1.5-0.2-2.2c-5.1,8.8-11.4,20.8-10.2,26.2
                                    c1.9,8.9,18.6,0,18.6,0s-7.3,24.6-7.3,31.9s12.7,0,12.7,0s-5.3,27.6-0.4,32.5c4.9,4.9,12.9-5.1,12.9-5.1
                                    c3.8,30.9,16.8,41.3,12.9,17.4c16.1,36.4,32.5,32.1,30.9,16.1h5.4c0-0.5-0.1-1.1-0.1-1.7C162.9,457.9,143.4,421,138,377.8z"/>
                                <path fill="#231E1E" d="M197.6,332.5l0,0.4c0-0.2,0.1-0.3,0.1-0.5L197.6,332.5z"/>
                                <path fill="#231E1E" d="M190,480.1c-1.3-21.4-2.8-58.7-2.8-58.7c0-24.4,1.7-44,4-59.5c-12.9,5.7-35.8,14.6-53.2,15.8
                                    C143.4,421,162.9,457.9,190,480.1z"/>
                                <path fill="#231E1E" d="M196,315.2c4.6-2.6,9-3.8,13.1-4l6.9-2.5l2.2-7.5c-28.2-4.3-48-14.9-60.3-30.8
                                    c-11.8,19.4-19.4,43.5-21.1,71.2C153,334,175.5,326.7,196,315.2z"/>
                                <path fill="#231E1E" d="M281.8,301.2l2.2,7.5l8.1,2.9c9.6,0.7,33.1,4.8,35.9,28.4c5.2,44.1,26.9,85.4,7.5,95.3
                                    c-9,4.6-16.6-0.4-22.9-9.7c-0.4,10.4-1.6,37.4-2.6,54.4c32.1-26.4,53.5-73.3,53.5-126.8c0-32.5-7.9-60.6-21.3-82.8
                                    C329.8,286.4,310,296.9,281.8,301.2z"/>
                                <path fill="#231E1E" d="M250,488.7c-0.9,0-7.7,0.3-18.8-2.1c0,0-1,5.9-3.8,13c7.3,1.9,14.8,3,22.6,3c7.7,0,15.3-1,22.6-3
                                    c-2.8-7.1-3.8-13.1-3.8-13.1C257.7,489,250.9,488.8,250,488.7z"/>
                                <path fill={backGroundColor} d="M312.6,425.7c6.3,9.2,14,14.3,22.9,9.7c19.4-10-2.3-51.2-7.5-95.3c-2.8-23.6-26.3-27.7-35.9-28.4l-8.1-2.9
                                    l-2.2-7.5c28.2-4.3,48-14.8,60.4-30.7c1.8-2.3,3.5-4.7,5-7.3c2.2-2.3,7.9-8.6,13.1-18.6c3.6-7,7-15.9,8.6-26.5c0,0-3.9,1.4-6.6,5.4
                                    c0,0,8.4-14.5,7.9-31.3c0,0-3.4-0.3-6,3.8c0,0,4.1-9,1.6-26.5c0,0-4.9,1.1-6.7,5.4c0,0,3.1-12.5-0.9-24.8c0,0-3.1,1.8-4.6,5.7
                                    c0,0,0.6-9-3.7-19.4c0,0-2.4,2-3.6,5.6c0,0-2.2-17.7-12.8-26.4c0,0-1.1,13.9,0.4,22.5c0,0-4.5-21-17-28.3c0,0-0.9,13.1,2.3,36.2
                                    c-18.7-14.7-42.7-24.6-68.9-24.6c-26.2,0-50.2,9.9-68.9,24.6c3.2-23,2.3-36.2,2.3-36.2c-12.6,7.2-17,28.3-17,28.3
                                    c1.5-8.6,0.4-22.5,0.4-22.5c-10.6,8.7-12.8,26.4-12.8,26.4c-1.3-3.5-3.6-5.6-3.6-5.6c-4.3,10.4-3.7,19.4-3.7,19.4
                                    c-1.6-3.9-4.6-5.7-4.6-5.7c-4.1,12.3-0.9,24.8-0.9,24.8c-1.8-4.4-6.7-5.4-6.7-5.4c-2.4,17.5,1.6,26.5,1.6,26.5
                                    c-2.6-4.1-6-3.8-6-3.8c-0.5,16.8,7.9,31.3,7.9,31.3c-2.8-4-6.6-5.4-6.6-5.4c1.8,11.4,5.5,20.8,9.5,28c4.9,9.1,10.2,14.9,12.3,17.1
                                    c1.5,2.5,3.1,4.8,4.8,7.1c12.4,15.9,32.1,26.5,60.3,30.8l-2.2,7.5l-6.9,2.5c-4.1,0.2-8.5,1.4-13.1,4c-20.5,11.5-43,18.8-59.1,26.4
                                    c-8.6,4-15.4,8.1-19.1,12.9c-2,2.6-3.1,5.3-3.1,8.4c0,0.7,0,1.5,0.2,2.2c1.6,10.5,11.1,13.5,23.2,12.7
                                    c17.4-1.1,40.3-10.1,53.2-15.8c-2.3,15.5-4,35.1-4,59.5c0,0,1.5,37.2,2.8,58.7c0,0.6,0.1,1.1,0.1,1.7c0.1,2,0.3,3.9,0.4,5.6
                                    c0.3,3.6,0.6,7,1.1,10c-1.8-0.1-2.9-0.1-3.3,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.3,0,34.8-0.2l0,0c2.4-1.9,4.5-4.8,6.2-8.3
                                    c1.9-3.1,3.4-6.3,4.6-9.3c2.8-7.1,3.8-13,3.8-13c11,2.3,17.9,2.1,18.8,2.1c0.9,0,7.7,0.3,18.8-2.1c0,0,1,5.9,3.8,13.1
                                    c1.2,3.1,2.8,6.4,4.8,9.5l0,0c1.6,3.3,3.6,6.1,5.9,7.9c0,0,0,0.1,0,0.1c0,0,19,6,34.8,0.2c0,0-1.3-15-6.5-19.8
                                    c-0.4-0.4-1.5-0.5-3.2-0.3c0.5-3,0.8-6.3,1.1-10c0.1-1.6,0.3-3.5,0.4-5.6c0-0.5,0.1-1.1,0.1-1.7C311,463.1,312.1,436.1,312.6,425.7
                                    z M197.6,332.5l0.1-0.1c0,0.2-0.1,0.3-0.1,0.5L197.6,332.5z"/>
                                <path fill="none" d="M297.7,176.6C297.7,176.6,297.7,176.6,297.7,176.6C297.7,176.6,297.7,176.6,297.7,176.6
                                    C297.7,176.6,297.7,176.6,297.7,176.6z"/>
                                <polygon fill="none" points="279.5,197.8 279.5,197.8 279.5,197.8 	"/>
                                <path fill="none" d="M266.3,222.5C266.3,222.5,266.3,222.4,266.3,222.5C266.3,222.5,266.3,222.5,266.3,222.5
                                    C266.3,222.5,266.3,222.5,266.3,222.5z"/>
                                <path opacity="0.2" fill="#161616" d="M347.1,263.3c3.8-3.8,17.7-19.5,21.8-45.1c0,0-3.9,1.4-6.6,5.4c0,0,8.4-14.5,7.9-31.3
                                    c0,0-3.4-0.3-6,3.8c0,0,4.1-9,1.6-26.5c0,0-4.9,1.1-6.7,5.4c0,0,3.1-12.5-0.9-24.8c0,0-3.1,1.8-4.6,5.7c0,0,0.6-9-3.7-19.4
                                    c0,0-2.4,2-3.6,5.6c0,0-2.2-17.7-12.8-26.4c0,0-1.1,13.9,0.4,22.5c0,0-4.5-21-17-28.3c0,0-0.9,13.1,2.3,36.2
                                    c-1.1-0.9-2.3-1.8-3.5-2.6c-2.9,2.9-8.2,8.5-14.9,16.8c4.8-2.5,8.7-0.2,8.7-0.2c-14.3,6.5-26.7,25-27.3,25.9c0,0,0,0,0,0
                                    c2.7-1.8,5.7-1.7,5.7-1.7c-10.2,8-18.7,26.4-18.7,26.4c0,0,0,0,0,0c1.8-1.9,4.3-2.3,4.3-2.3c-7.4,7.5-11.4,27.8-11.7,29.2
                                    c0,0,0,0,0,0.1c1.7-2.1,3.7-1.6,3.7-1.6c-4.1,9.3-4.4,19-3.6,25.9c2.4,16.7,9.6,30.5,19.8,39.3C314,296.4,335.3,283.3,347.1,263.3z
                                    "/>
                                <path opacity="0.2" fill="#161616" d="M235.4,236c0,0,1.9-0.5,3.7,1.6c0,0,0,0,0-0.1c-0.3-1.4-4.3-21.6-11.7-29.2
                                    c0,0,2.4,0.4,4.3,2.3c0,0,0,0,0,0c0,0-8.5-18.4-18.7-26.4c0,0,3-0.1,5.7,1.7c0,0,0,0,0,0c-0.7-1-13.1-19.4-27.3-25.9
                                    c0,0,3.9-2.3,8.7,0.2c-6.8-8.4-12.2-14.1-15.1-16.9c-1.2,0.9-2.5,1.8-3.7,2.8c3.2-23,2.3-36.2,2.3-36.2c-12.6,7.2-17,28.3-17,28.3
                                    c1.5-8.6,0.4-22.5,0.4-22.5c-10.6,8.7-12.8,26.4-12.8,26.4c-1.3-3.5-3.6-5.6-3.6-5.6c-4.3,10.4-3.7,19.4-3.7,19.4
                                    c-1.6-3.9-4.6-5.7-4.6-5.7c-4.1,12.3-0.9,24.8-0.9,24.8c-1.8-4.4-6.7-5.4-6.7-5.4c-2.4,17.5,1.6,26.5,1.6,26.5
                                    c-2.6-4.1-6-3.8-6-3.8c-0.5,16.8,7.9,31.3,7.9,31.3c-2.8-4-6.6-5.4-6.6-5.4c4,25.6,18,41.3,21.8,45.1c11.8,20.1,33.3,33.2,66,38
                                    c10.3-8.8,17.5-22.6,19.9-39.3C239.8,255,239.4,245.3,235.4,236z"/>
                                <path fill="#FFFFFF" d="M330,265.1c2.4-2,4.4-4.5,6-7.4c7.2-13,2.5-29.3-10.5-36.5c-13-7.2-29.4-2.5-36.5,10.5
                                    c-7.2,13-2.5,29.4,10.5,36.5c0.1,0,0.2,0.1,0.2,0.1C308.9,263.4,319.8,262.1,330,265.1z"/>
                                <path fill="#21323F" d="M313.4,245.7c-3,0-5.5-2.5-5.5-5.5v0c0-2.9,2.3-5.3,5.2-5.4c-2.4-2-5.5-3.1-8.8-3.1c-7.7,0-14,6.3-14,14
                                    c0,7.8,6.3,14,14,14c7.8,0,14-6.3,14-14L313.4,245.7z"/>
                                <g>
                                    <g>
                                        <path fill="#F9B7A8" d="M312.8,270.1c-1.5,2.7-0.5,6,2.2,7.5c2.7,1.5,6,0.5,7.5-2.2c1.5-2.7,0.5-6-2.2-7.5
                                            C317.6,266.5,314.3,267.5,312.8,270.1z"/>
                                        <circle fill="#F9B7A8" cx="314.5" cy="284.2" r="2.8"/>
                                        <circle fill="#F9B7A8" cx="325.1" cy="281.1" r="2.8"/>
                                    </g>
                                    <g>
                                        <path fill="#F9B7A8" d="M187.4,270.1c1.5,2.7,0.5,6-2.2,7.5c-2.7,1.5-6,0.5-7.5-2.2c-1.5-2.7-0.5-6,2.2-7.5
                                            C182.6,266.5,186,267.5,187.4,270.1z"/>
                                        <circle fill="#F9B7A8" cx="185.7" cy="284.2" r="2.8"/>
                                        <circle fill="#F9B7A8" cx="175.1" cy="281.1" r="2.8"/>
                                    </g>
                                </g>
                                <path fill="#FFFFFF" d="M170.2,265.1c-2.4-2-4.4-4.5-6-7.4c-7.2-13-2.5-29.3,10.5-36.5c13-7.2,29.4-2.5,36.5,10.5
                                    c7.2,13,2.5,29.4-10.5,36.5c-0.1,0-0.2,0.1-0.2,0.1C191.3,263.4,180.4,262.1,170.2,265.1z"/>
                                <path fill="#21323F" d="M186.9,245.7c3,0,5.5-2.5,5.5-5.5v0c0-2.9-2.3-5.3-5.2-5.4c2.4-2,5.5-3.1,8.8-3.1c7.7,0,14,6.3,14,14
                                    c0,7.8-6.3,14-14,14c-7.8,0-14-6.3-14-14L186.9,245.7z"/>
                                <g>
                                    <path fill="#999688" d="M289.2,274.2c-0.7-2.4-3.5-4.5-6.3-4.1c0,0-0.1,0-0.1,0c-3.2,0.8-7.1,2.3-11.1,2.1c0,0-0.1,0-0.1,0
                                        c-0.1,0-0.2,0-0.2,0c-0.5,0-0.8-0.1-1.1-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.1,0-0.3-0.1c-4.8-1.2-4.2-9-7.5-11.5
                                        c-3.1-1-6-1-9.6-1.1c-1.1,0-2.4,0-4.2,0c-2.4,0.1-6.5,0.1-9.6,1.1c-3.5,2.4-2.2,9.8-7.5,11.5c-0.1,0-0.2,0-0.3,0.1
                                        c-0.3,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.6,0.1-1.1,0.1c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-3.3,0.2-7.2-1-11.1-2.1c0,0-0.1,0-0.1,0
                                        c-7.2-0.6-7.5,7.8-5.6,10.3c6.6,10.6,26.1,12.5,38.3,13.1C264.5,293.5,292.8,287.9,289.2,274.2z"/>
                                    <path fill="#A8A69E" d="M282.9,270.1C282.8,270.1,282.8,270.1,282.9,270.1c-3.2,0.8-7.2,2.3-11.1,2.1c0,0-0.1,0-0.1,0
                                        c-0.1,0-0.2,0-0.2,0c-0.5,0-0.8-0.1-1.1-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.1,0-0.3-0.1c-4.8-1.2-4.2-9-7.5-11.5
                                        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,3.9-1,10.8-4.4,16.1c-5,7.8,11,5.9,15.4,4.4c3.4-1.2,10.5-4.7,14.2-10.1
                                        C285.3,270.2,284.1,269.9,282.9,270.1z"/>
                                    <path fill="#A8A69E" d="M228.2,280.8c4.4,1.5,20.4,3.4,15.4-4.4c-3.4-5.3-4.3-12.3-4.4-16.2c-0.3,0.1-0.5,0.1-0.8,0.2
                                        c-3.5,2.4-2.2,9.8-7.5,11.5c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.6,0.1-1.1,0.1c0,0-0.1,0-0.2,0
                                        c0,0-0.1,0-0.1,0c-3.3,0.2-7.2-1-11.1-2.1c0,0-0.1,0-0.1,0c-1.4-0.1-2.5,0.1-3.4,0.6C217.6,276.1,224.8,279.7,228.2,280.8z"/>
                                    <path fill="#302A2A" d="M258.3,274.4c-5.4,0-7.8,1.9-8.1,2.6c-0.3-0.7-2.8-2.6-8.1-2.6c-4.8,0-3.3,8.3,0.6,11.5
                                        c3.6,3.1,7,3.5,7.6,3.6v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.5,0,3.9-0.5,7.6-3.6C261.5,282.7,263.1,274.4,258.3,274.4z"/>
                                    <g>
                                        <path fill="#231E1E" d="M242.9,277.2c-0.2,3,1.6,5.1,3.3,4.7C250.6,277.7,244.9,276.5,242.9,277.2z"/>
                                        <path fill="#231E1E" d="M254.1,281.9c1.7,0.5,3.4-1.6,3.3-4.7C255.3,276.5,249.6,277.7,254.1,281.9z"/>
                                    </g>
                                    <g>
                                        <path fill="#FFFFFF" d="M259,264c-0.2,0.6-4.1-0.4-8.8-0.4c-4.8-0.1-8.6,0.9-8.8,0.4c-0.1-0.3,0.7-1,2.3-1.5
                                            c1.6-0.6,3.9-1.1,6.5-1.1c2.6,0,4.9,0.5,6.5,1.1C258.2,263.1,259.1,263.7,259,264z"/>
                                    </g>
                                </g>
                                <path fill="#848484" d="M275.4,318.4"/>
                                <path opacity="0.2" fill="#FFFFFF" d="M303.8,357.5c-6.4-8-7.8-20.4-7.8-20.4c1.8,1.6,4,1.5,4,1.5c-1.5-1.1-3.7-3.9-5.8-6.8
                                    c-1.1-2.6-2.6-5-4.6-7c-0.3-0.5-0.5-0.8-0.5-0.8s0,0.1,0,0.3c-2.1-1.9-4.5-3.5-7.3-4.5v0c-10.3-3.7-21-5.6-31.8-5.6
                                    c-10.8,0-21.5,1.9-31.8,5.6l0,0c-2.8,1-5.2,2.5-7.3,4.5c0-0.2,0-0.3,0-0.3s-0.2,0.3-0.5,0.8c-2,2-3.6,4.4-4.6,7
                                    c-2,2.9-4.2,5.7-5.8,6.8c0,0,2.1,0.1,4-1.5c0,0-1.4,12.4-7.8,20.4c0,0,4.2,0.9,7-2.4c0,0-1.4,20.9-5.8,30.7c0,0,3.5,0.4,6.2-3.8
                                    c0,0,2.4,26.7,1.3,33.9c0,0,2.5-2.3,3.9-6.1c6.1,20.7,18.1,37,41.2,37c23.1,0,35.2-16.3,41.2-37c1.4,3.9,3.9,6.1,3.9,6.1
                                    c-1.1-7.2,1.3-33.9,1.3-33.9c2.7,4.2,6.2,3.8,6.2,3.8c-4.5-9.7-5.8-30.7-5.8-30.7C299.6,358.4,303.8,357.5,303.8,357.5z"/>
                                <g opacity="0.5">
                                    <linearGradient id="SVGID_71_" gradientUnits="userSpaceOnUse" x1="199.35" y1="519.9918" x2="198.1308" y2="496.8258">
                                        <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                        <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                        <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                        <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                        <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_71_)" d="M216.5,516.7C216.5,516.7,216.5,516.7,216.5,516.7c-1.6-10.9-8.1-16.3-8.1-16.3
                                        c-7.3-1.6-13.1-2.8-16.6-3.1c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.3,0,34.8-0.2
                                        C216.6,517.1,216.6,516.9,216.5,516.7z"/>
                                    
                                        <linearGradient id="SVGID_72_" gradientUnits="userSpaceOnUse" x1="3779.0813" y1="574.925" x2="3777.824" y2="551.0389" gradientTransform="matrix(-0.9995 -3.020000e-02 -3.020000e-02 0.9995 4094.8784 60.2872)">
                                        <stop  offset="1.012280e-07" stop-color="#686868" stop-opacity="0"/>
                                        <stop  offset="0.1242" stop-color="#727272" stop-opacity="0.1145"/>
                                        <stop  offset="0.3378" stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                        <stop  offset="0.613" stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                        <stop  offset="0.8676" stop-color="#EAEAEA" stop-opacity="0.8"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_72_)" d="M318,517.4c0,0-1.3-15-6.5-19.8c-1.2-1.1-8.9,0.3-20.1,2.8c0,0-6.8,5.6-8.2,16.8
                                        C283.2,517.3,302.2,523.3,318,517.4z"/>
                                </g>
                                <path fill="#231E1E" d="M317.9,517.6l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                    C314.7,515.7,316.2,517.6,317.9,517.6z"/>
                                <path fill="#302A2A" d="M312.1,520l4.8,0c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.1-1.1-2.8-1-3.3,0.3l-1.1,2.8
                                    C309,518.1,310.4,520,312.1,520z"/>
                                <path fill="#231E1E" d="M182.1,517.6l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                    C185.3,515.7,183.8,517.6,182.1,517.6z"/>
                                <path fill="#302A2A" d="M187.9,520l-4.8,0c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.1-1.1,2.8-1,3.3,0.3l1.1,2.8
                                    C191,518.1,189.6,520,187.9,520z"/>
                            </svg>
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
