import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box
} from "@material-ui/core";
import Wrapper from '../../../../baseComponents/wrapper';
import { Formik, Field, Form } from 'formik';
import {
  LinearProgress,
  MenuItem,
  Theme,
  createStyles,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase';
import * as yup from 'yup';
import { userService, categoryService } from '../../../../../services';
import { officeService } from '../../../../../services'
import { FormatDate } from '../../../../../common/formatDate';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Notifier, notifierProps } from '../../../../components/notifier/notifier'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Scrollbars } from 'react-custom-scrollbars';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { parsePhoneNumber } from 'libphonenumber-js'
import { userRole } from "../../../../../enums";
import ButtonRound from "../../../../components/buttonRound/buttonRound";

const closeIcon = require('../../../../../assets/images/close_icon.png');

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  userData: any;
  isChildPageCall: boolean

  // description: string;
}

interface EditDetailsDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}
const snackBarStatusInitialState: any = "";
export const EditDetailsDialog: React.FC<EditDetailsDialogProps> = ({
  open,
  // description,
  userData,
  isChildPageCall,
  onSubmit,
  onClose
}) => {
  const [snackBarStatus, setsnackBarStatus] = React.useState(snackBarStatusInitialState);
  const [categoryList, setcategoryList] = useState([] as any);
  const [locationsList, setLocationsList] = useState([] as any);
  const [categoryValue, setCategoryValue] = React.useState({ value: 0, text: "" });
  const [textValue, setTextValue] = React.useState(userData ? userData.jobTitle : "");
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedDobDate, setSelectedDobDate] = useState<Date>(userData ? userData.dateOfBirth : new Date());
  const [counterDobDate, setcounterDobDate] = React.useState(0);
  const [counterStartDate, setcounterStartDate] = React.useState(0);
  const close = require('../../../../../assets/images/close_icon.svg');


  const handleStartDateChange = date => {
    if (date) {
      setSelectedStartDate(date._d);
      setcounterStartDate(1);
    }
  };

  const handleDoBDateChange = date => {
    if (date) {
      setSelectedDobDate(date._d);
      setcounterDobDate(1);
    }
  };

  const schema = yup.object().shape({
    uniqueId: yup.string().required(),
    // userName:yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string(),
    dateOfBirth: yup.string(),
    email: yup
      .string()
      .email()
      .required(),
    streetAddress: yup.string().required(),
    suburb: yup.string().required(),
    state: yup.string().required(),
    postCode: yup.number().required(),
    //  phoneNumber: this.props.location.search!=='?child'?yup.string().required():yup.string(),
    phoneNumber: userData.roleId === 2 || userData.roleId === 3 ? yup.string()
      .required()
      .test('is-valid-phone', "Phone Number invalid", value => {
        if (!value) return false;
        let number = parsePhoneNumber(value, "AU");
        return number.isValid();
      }) : yup.string(),
    mobileNumber: yup.string().required()
      .test('is-valid-mobile', "Mobile Number invalid", value => {
        if (!value) return false;
        let number = parsePhoneNumber(value, "AU");
        return number.isValid();
      }),
    startDate: yup.string().required(),
    aboutme: yup.string(),
    location: yup.number().positive()
  });

  interface selectData {
    value: number,
    text: string
  }

  useEffect(
    () => {
      let locations: officeData[] = [];
      officeService.getAll().then((offices: any) => {
        if (offices.success) {
          offices.data.forEach(element => {
            let data: any = {
              value: element.id,
              label: element.officeName
            }
            locations.push(data);
          });
          setLocationsList({ locationsList: locations });
        }
      });

      let categoryData: selectData[] = [];
      categoryService.getAll().then((categories: any) => {
        if (categories.success) {
          categories.data.map(category => {
            let data: any = {
              value: category.id,
              text: category.categoryName
            }
            categoryData.push(data);
          }
          )
        }
      })
      setcategoryList({ categoryList: categoryData });

    }, []



  );
  interface Values {
    id: number,
    uniqueId: string,
    // userName:string,
    firstName: string,
    lastName: string,
    dateOfBirth: String,
    email: string,
    streetAddress: string,
    suburb: string,
    state: string,
    postCode: number,
    phoneNumber: string,
    mobileNumber: string,
    startDate: String,
    aboutMe: string,
    gender: string,
    officeId: number,
    jobTitle:string,
    // roleId:number
  }

  const states = [
    {
      value: "Canberra",
      label: "Canberra"

    },
    {
      value: 'NSW',
      label: 'New South Wales',
    },
    {
      value: "NT",
      label: "Northern Territory"

    },
    {
      value: 'Queensland',
      label: 'Queensland',
    },
    {
      value: 'SA',
      label: 'South Australia',
    },
    {
      value: 'Tasmania',
      label: 'Tasmania',
    },
    {
      value: 'Victoria',
      label: 'Victoria',
    },
    {
      value: 'WA',
      label: 'Western Australia',
    }
  ];

  const gender = [
    {
      value: 'm',
      label: 'Male',
    },
    {
      value: 'f',
      label: 'Female',
    },
  ]

  interface officeData {
    value: number,
    label: string
  }
  const officeId = userData.Office && userData.Office.id;
  let _dateOfBirth = userData && new Date(userData.dateOfBirth);
  let dateOfBirth = _dateOfBirth && FormatDate.trasformDate(_dateOfBirth);
  let _startDate = userData && new Date(userData.startDate);
  let startDate = _startDate && FormatDate.trasformDate(_startDate);
  

  return (

    <Dialog open={open} className={isChildPageCall ? "popup-edit-details child-background" : "popup-edit-details admin-background"}>
      <Grid container style={{ padding: "20px", height: "100%" }}>
      <DialogContent className={isChildPageCall ? "edit-child-shells" : "edit-teamMember-platypus"}>
          <Formik<Values>
            initialValues={{
              id: userData.id,
              uniqueId: userData.uniqueId,
              // userName:userData.userName,
              firstName: userData.firstName,
              lastName: userData.lastName,
              // dateOfBirth:_dateOfBirth,
              dateOfBirth: dateOfBirth,
              email: userData.email,
              streetAddress: userData.streetAddress,
              suburb: userData.suburb,
              state: userData.state,
              postCode: userData.postCode,
              phoneNumber: userData.phoneNumber,
              mobileNumber: userData.mobileNumber,
              // startDate:_startDate,
              startDate: startDate,
              aboutMe: userData.aboutMe,
              officeId: officeId,
              gender: userData.gender,
              jobTitle: userData.jobTitle,
              // roleId:1,
            }}

            validationSchema={schema}

            onSubmit={(values, { setSubmitting }) => {
              values.dateOfBirth = counterDobDate === 0 ? values.dateOfBirth : FormatDate.trasformDate(selectedDobDate);
              values.startDate = counterStartDate === 0 ? values.startDate : FormatDate.trasformDate(selectedStartDate);
              values.jobTitle = isChildPageCall ? "" : textValue;
              setTimeout(() => {
                setSubmitting(false);

                userService.updateUser(values, isChildPageCall).then((data: any) => {
                  if (data.success) {
                    const stateObject = {
                      msg: data.message,
                      status: true,
                      date: new Date(),
                      variant: 'success'
                    }
                    setsnackBarStatus(stateObject);
                  }
                  else {
                    const stateObject = {
                      msg: data.message,
                      status: true,
                      date: new Date(),
                      variant: 'error'
                    }
                    setsnackBarStatus(stateObject);
                  }
                })
              }, 400);
            }}
            render={({ setFieldTouched, handleChange, submitForm, isSubmitting, values, errors }) => (
              <>
                <Grid xs={12} style={{ display: "flex", height: "25%" }}>
                  <Grid xs={8} style={{ position: "relative" }}>
                    <Box className={"modal-title"}>
                      <Box>
                        <span>{userData.fullName}</span>
                      </Box>
                      <Box>
                        <span className={""}>{"Edit Details"}</span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={4}>
                    <Box style={{ float: "right" }}>
                      <ButtonRound className={"button-close"} backgroundColor={'trasparent'} callback={onClose} icon={close}></ButtonRound>
                    </Box>
                  </Grid>
                </Grid>
                {/* <h2>
                  <img src={closeIcon} onClick={onClose} />
                </h2>
                <h2 className={"popup-heading margin-top"} > {userData.fullName} </h2>
                <h2 className={"popup-heading"}> {"Edit Details"} </h2> */}
                <Grid xs={12} style={{ paddingTop: "20px", height: "75%" }}>
                  <Scrollbars style={{ width: "100%", height: "100%" }}>
                  <Form className="edit-detail-form">
                  <Grid container spacing={3} xs={9} item className={"add-user"} >

                    <Grid item xs={12} className={"row"}>
                      <Typography>
                        Details
                                  </Typography>
                    </Grid>
                    <Grid item xs={12} className={"row"}  style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Unique ID *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.uniqueId ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="text"
                        name="uniqueId"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      // onChange={e => {
                      //     setFieldTouched('uniqueId');
                      //     handleChange(e);
                      // }}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="User Name*"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.uniqueId ? "label Mui-error-label" : "label"}
                      />
                      <InputBase
                        defaultValue={userData.userName}
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label "}
                        style={{width:"100%"}}
                      />

                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="First Name *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.firstName ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="text"
                        name="firstName"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      />

                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Last Name"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label"}
                      />
                      <Field
                        type="text"
                        name="lastName"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row tow-column"}>
                      <Grid item xs={6} className={"DOB-gender"} style={{display:"flex", paddingRight:20}}>
                        <InputBase
                          defaultValue="Date of Birth *"
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.dateOfBirth ? "label Mui-error-label" : "label"}
                        />
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                                          <MuiPickersUtilsProvider utils={moment}>
                                                  <DatePicker value={selectedDate} onChange={handleDateChange} />
                                          </MuiPickersUtilsProvider> 
                                         </MuiPickersUtilsProvider>  */}
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            name="dateOfBirth"
                            format="DD/MM/YYYY"
                            style={{width:"50%"}}
                            value={counterDobDate === 0 ? _dateOfBirth : selectedDobDate}
                            onChange={handleDoBDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} className={"second-column"} style={{display:"flex"}}>
                        <InputBase
                          defaultValue="Gender *"
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.gender ? "label Mui-error-label" : "label"}
                        />
                        <Field
                          type="text"
                          name="gender"
                          defaultValue={userData.gender}
                          select
                          variant="filled"
                          style={{width:"100%"}}
                          SelectProps={{
                            MenuProps: {
                              className: "classes.menu",
                            },
                          }}
                          // helperText="Please select gender"
                          component={TextField}
                        >
                          {gender.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Email *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.email ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="email"
                        name="email"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Street Address *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.streetAddress ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="text"
                        name="streetAddress"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      />
                    </Grid>
                    <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Suburb *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.suburb ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="text"
                        name="suburb"
                        component={TextField}
                        variant="filled"
                        style={{width:"100%"}}
                      />
                    </Grid>

                    <Grid item xs={12} className={"row tow-column"}>
                      <Grid item xs={7} className={"DOB-gender"} style={{display:"flex", paddingRight:20}}>
                        <InputBase
                          defaultValue="State *"
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.state ? "label Mui-error-label" : "label"}
                        />
                        <Field
                          type="text"
                          name="state"
                          select
                          variant="filled"
                          style={{width:"100%"}}
                          SelectProps={{
                            MenuProps: {
                              className: "classes.menu",
                            },
                          }}
                          // helperText="Please select Range"
                          // margin="normal"
                          component={TextField}
                        >
                          {states.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={5} className={"second-column"} style={{display:"flex"}}>
                          <InputBase
                            defaultValue="Post Code *"
                            inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                            className={errors.postCode ? "label Mui-error-label" : "label"}
                          />
                          <Field
                            type="text"
                            name="postCode"
                            component={TextField}
                            variant="filled"
                            style={{width:"100%"}}
                          />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={"row tow-column"}>
                      <Grid item xs={6} className={"DOB-gender"} style={{display:"flex", paddingRight:20}}>
                        <InputBase
                          defaultValue={isChildPageCall? "Phone Number" : "Phone Number *"}
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.phoneNumber ? "label Mui-error-label" : "label"}
                        />
                        <Field
                          type="tel"
                          name="phoneNumber"
                          component={TextField}
                          variant="filled"
                          style={{width:"50%"}}
                        />
                      </Grid>
                      <Grid item xs={6} className={"second-column"} style={{display:"flex"}}>
                        <InputBase
                          defaultValue="Mobile Number *"
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.mobileNumber ? "label Mui-error-label" : "label"}
                        />
                        <Field
                          type="tel"
                          name="mobileNumber"
                          component={TextField}
                          variant="filled"
                          style={{width:"50%"}}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={"row tow-column"}>
                      <Grid item xs={12} className={"DOB-gender"}>
                        <InputBase
                          defaultValue="Start Date*"
                          inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                          className={errors.startDate ? "label Mui-error-label" : "label"}
                        />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            name="startDate"
                            format="DD/MM/YYYY"
                            value={counterStartDate === 0 ? _startDate : selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    {!isChildPageCall ? 
                                <Grid item xs={12} className={"row"} style={{display:"flex"}}>
                                <InputBase
                                    defaultValue="Type"
                                    inputProps={{ 'aria-label': 'naked' , 'disabled':'true'}}
                                    className={"label"}
                                /> 
                                    <Autocomplete
                                    id="categoryId"
                                    freeSolo
                                    className={"auto-complete"}
                                    value={values.jobTitle}
                                    options={categoryList.categoryList}
                                    getOptionLabel={(option => typeof option === 'string' ? option : option.text)}
                                    onInputChange={(event, newInputValue) => {
                                        setTextValue(newInputValue);
                                      }}
                                    renderInput={params => (
                                        <Field {...params} 
                                            type="text"
                                            name="jobTitle"
                                            margin="normal"
                                            variant="filled"
                                            component={TextField}
                                            style={{width:"100%"}}
                                        />
                                    )}
                                  />
                            </Grid>
                            : null}
                    <Grid item xs={12} className={"row" } style={{display:"flex"}}>
                      <InputBase
                        defaultValue="Location *"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={errors.officeId ? "label Mui-error-label" : "label"}
                      />
                      <Field
                        type="text"
                        name="officeId"
                        defaultValue={officeId}
                        select
                        variant="filled"
                        style={{width:"100%"}}
                        SelectProps={{
                          MenuProps: {
                            className: "classes.menu-1",
                          },
                        }}
                        // helperText="Please select location"
                        component={TextField}
                      >
                        {locationsList.locationsList.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} className={"row"} style={{ display: "flex" }}>
                      <InputBase
                        defaultValue="About Me"
                        inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                        className={"label about-me-label"}
                      />
                      <Field
                        type="text"
                        name="aboutMe"
                        variant="filled"
                        style={{width:"100%"}}
                        multiline={true}
                        rows={4}
                        rowsMax={4}
                        component={TextField}
                      />
                    </Grid>
                    {/* <Grid item xs={12} className={"auto-complete-row"}>
                                    <InputBase
                                        defaultValue="Type"
                                        inputProps={{ 'aria-label': 'naked', 'disabled':'true' }}
                                        className={"label"}
                                    /> 
                                        <Autocomplete
                                        id="categoryId"
                                        freeSolo
                                        className={"auto-complete"}
                                        options={categoryList.categoryList}
                                        getOptionLabel={(option: selectData) => option.text}
                                        onChange={onCategoryChange}
                                        renderInput={params => (
                                          <TextField {...params}   
                                          placeholder="Enter the Team Member Type" 
                                          margin="normal"
                                          variant="outlined"
                                          // onChange={handleTextChange} 
                                         />
                                        )}
                                      />
                                </Grid> */}
                    <Grid item className={"margin-top button-container"} xs={12}>
                      {/* {isSubmitting && <LinearProgress />} */}
                      <Grid item>
                        <Button
                          variant="contained"
                          className={isChildPageCall ? "orange-button" : "green-button"}
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Save
                                    </Button>

                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
                  </Scrollbars>
                </Grid>

              </>
            )}
          />
          {snackBarStatus.status ?
            <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
      </DialogContent>
      </Grid>
    </Dialog>
  );
};
