import React from 'react';
import './buttonRound.scss';
import { IconButton } from '@material-ui/core';

export interface Props {
    callback: any,
    className: any,
    backgroundColor?: any,
    title?: any,
    icon?: any
}

export interface State {
}

class ButtonRound extends React.Component<Props, State>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
    }

    createImgName(icon, title){
        if(icon && title){
            return(
                <>
                <img src={icon}></img><span>{title}</span></>
            )
        }
        if(!icon && title){
            return(
                <>
                <span>{title}</span></>
            )
        }
        if(icon && !title){
            return(
                <img src={icon}></img>
            )
        }
        if(!icon && !title){
            return(
                <span>Button</span>
            )
        }

    }

    render() {
        const {callback, className, backgroundColor, icon, title} = this.props;
        return (
            <div className={className}>
                <IconButton disabled={callback === null} style={backgroundColor ? {backgroundColor: backgroundColor, height:"100%", width: "100%"} : {backgroundColor: "white", height:"100%", width: "100%"}} onClick={callback}>
                    {this.createImgName(icon,title)}
                </IconButton>
            </div>
        );
    }
}

export default ButtonRound;