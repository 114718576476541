
import React from 'react'
import { Typography, Grid, Button, Tooltip } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import ButtonComponent from './buttonComponent';
import CustomToolbar from "../components/customToolbar";
import { BhaviouralPlansDialog, behaviouralPlansDialogProps } from "./popups/behaviouralPlans/behaviouralPlansDialog"
import { authenticationService, behavoursService, childrenService, adminMemberService } from '../../../services';
import { FormatDate } from '../../../common/formatDate';
import DescriptionIcon from '@material-ui/icons/Description';



export interface Props {
    behaviouralPlansData: any;
    behaviourId?: number;
    fromDreamCircle?: boolean

}

export interface State {
    isLoading: boolean;
    behaviourPlansForMe: any;
    newBhaviouralPlansDialogData: any
    childId: number;
    behaviourPlanId: number
}


let currentUser;
let formateDate = new FormatDate();


class BehaviouralPlansComponent extends React.Component<Props, State> {
    constructor(props) {
        super(props);

    }

    onClick() {
        console.log('this is clicked ')
    }
    componentWillMount() {
        currentUser = authenticationService.currentUserValue;
        let behaviourPlans: any[] = [];
        console.log(this.props.behaviouralPlansData)
        const behaviourPlansData = this.props.behaviouralPlansData.behaviourPlansForMe ? this.props.behaviouralPlansData.behaviourPlansForMe : this.props.behaviouralPlansData.behaviourPlans ? this.props.behaviouralPlansData.behaviourPlans : this.props.behaviouralPlansData;
        behaviourPlansData.forEach(item => {
            const createdDate = item.creationDate ? item.creationDate : item.date;
            //const date = formateDate.trasformDate(createdDate);
            const plan = {
                id: item.id,
                isPublished: item.isPublished ? "Yes" : "No",
                behaviourName: item.behaviours ? item.behaviours.behaviourName : item.behaviourName,
                uploadedBy: item.teamMember ? item.teamMember.firstName + " " + item.teamMember.lastName : item.uploadedBy,
                date: createdDate,
                file: item.behaviourPlanFileURL ? item.behaviourPlanFileURL : item.fileName
            }
            behaviourPlans.push(plan);
        })

        const stateObject = {
            openAction: false,
            behaviouralPlansData: this.props.behaviouralPlansData,

        }
        this.setState({ newBhaviouralPlansDialogData: stateObject });
        this.setState({ behaviourPlansForMe: behaviourPlans });
        this.setState({ childId: this.props.behaviouralPlansData.id });
        this.setState({ behaviourPlanId: 0 });

    }

    onViewBehaviourClick = (data: any) => {
        this.setBehaviourPlanDialog(true, data);
    }

    async setBehaviourPlanDialog(isOpen: boolean, data: any) {
        const stateObject = {
            openAction: isOpen,
            data: this.props.behaviouralPlansData
        }
        this.setState({ newBhaviouralPlansDialogData: stateObject });
    }

    closeBehaviourDialog = async (updateBehaviourPlanlist: boolean) => {
        if (updateBehaviourPlanlist) {
            let behaviourPlansData: Array<any> = [];
            if (this.props.behaviourId) {
                await behavoursService.getBehaviourPlansByBehaviour(this.state.childId, this.props.behaviourId).then((data: any) => {
                    if (data.success) {
                        behaviourPlansData = data.data;
                    } else {
                        //ERROR
                    }

                }, error => {
                    //ERROR
                    console.log(error.message);

                });
            } else {
                await behavoursService.getBehaviourPlansByChild(this.state.childId).then((data: any) => {
                    if (data.success) {
                        behaviourPlansData = data.data;
                    } else {
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);

                })
            }
            if (behaviourPlansData.length > 0) {
                let behaviourPlans: Array<any> = [];
                behaviourPlansData.forEach(item => {
                    const plan = {
                        id: item.id,
                        isPublished: item.isPublished ? "Yes" : "No",
                        behaviourName: item.behaviourName,
                        uploadedBy: item.uploadedBy,
                        date: item.date,
                        file: item.behaviourPlanFileURL
                    }
                    behaviourPlans.push(plan);
                });
                this.setState({ behaviourPlansForMe: behaviourPlans });
            }
        }
        this.setBehaviourPlanDialog(false, null)
    }

    openFile = (url: string) => {
        window.open(url);
    }

    openRow = (rowData: any) => {
        this.setState({ behaviourPlanId: rowData[0] });
        this.setBehaviourPlanDialog(true, "");
    }

    render() {
        const { fromDreamCircle } = this.props;
        const columns = [
            {
                name: "id",
                label: "id",
                options: {
                    display: false
                }
            }, {
                name: "date",
                label: "Date",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value) {
                            return (
                                <div>{FormatDate.trasformDate(new Date(value))}</div>
                            );
                        }

                    }
                }
            }, {
                name: "uploadedBy",
                label: "Uploaded By",
                options: {
                    sort: true
                }
            },
            {
                name: "behaviourName",
                label: "Behaviour",
                options: {
                    sort: true,
                }
            },
            // {
            //     name: "isPublished",
            //     label: "Published",
            //     options: {
            //         sort: true,
            //     }
            // },
            {
                name: "file",
                label: "File",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value) {
                            return (
                                <DescriptionIcon onClick={() => this.openFile(value)} />
                            );
                        }

                    }
                }
            }

        ];
        const options = {
            print: false,
            download: false,
            filter: false,
            viewColumns: false,
            // selectableRowsOnClick:true,
            selectableRowsHeader: false,
            selectableRows: 'none',
            rowsPerPage: 5,
            // onRowClick: (rowData) =>{
            //     this.openRow(rowData);
            // },
            customToolbar: () => {
                return (
                    !fromDreamCircle ? <CustomToolbar text={"Upload New Plan"} clickHandler={(rowData) => this.onViewBehaviourClick(rowData)} cssClasses={"blue-button"} /> : null
                );
            },
        };
        return (
            <>
                <Grid container >
                    {/* <Grid  container  className={"user-stroies-header"}>
                            <Grid item style={{width:'250px'}} className="detail-header">
                                <Typography variant="h4">
                                        Behavioural Plans
                                </Typography>
                            </Grid>
                            <Grid>
                                <ButtonComponent toolTip="Upload New Plan" text="Upload New Plan" onClick={()=>this.onClick()} cssClasses={"blue-button"}></ButtonComponent> 
                            </Grid>
                        </Grid> */}
                        <MUIDataTable
                            title={fromDreamCircle ? null : "Behavioural Plans"}
                            data={this.state.behaviourPlansForMe}
                            options={options}
                            columns={columns}
                            className={"trasnparent-muipaper-root"}
                        />
                </Grid>
                {this.state.newBhaviouralPlansDialogData.openAction ?
                    <BhaviouralPlansDialog onClose={this.closeBehaviourDialog} openAction={this.state.newBhaviouralPlansDialogData.openAction} isBehaviourSelected={false} behaviouralPlanData={this.state.newBhaviouralPlansDialogData.data} behaviourPlanId={this.state.behaviourPlanId} currentUser={currentUser.user} behaviourIdSelected={this.props.behaviourId} /> : null}
            </>
        )
    };
}
export default BehaviouralPlansComponent;