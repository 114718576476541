import React from 'react';
import { Router, Route, NavLink, useLocation, useParams } from 'react-router-dom'
// import {PrivateRoute} from '../../../componentHelper/privateRoute'
import { authenticationService } from '../../../services';
import {Typography} from '@material-ui/core'
import { history, Role } from '../../../helper';
import {Home} from '../../home/'
import ChildrenList from '../childrenList'
import TeamMemberList from '../teamMemberList'
import AdminDetails from '../adminDetails'
export interface  Props {
    pageName:string
}
 
export interface  State {
        currentUser: any,
        isAdmin: boolean
      
        
}
class AdminMenu extends React.Component<Props,  State>{
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
         
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x,
            isAdmin: x && x.role === Role.Admin
        }));
    }

  
    render(){
        const { currentUser, isAdmin } = this.state;
        return (
            <Router history={history}>
                {/* <div> */}
                {/* <Typography> */}
                        <NavLink to="/childrenlist" className={this.props.pageName==="child" ? "admin-left-manu active" : "admin-left-manu"}  >
                            Children
                        </NavLink>
                        <NavLink to="/teammember" className={this.props.pageName==="team" ? "admin-left-manu active" : "admin-left-manu"}>
                            Team Member
                        </NavLink>
                        {/* <NavLink to="administration" className={this.props.pageName==="admin" ? "admin-left-manu active" : "admin-left-manu"}>
                            Administration
                        </NavLink> */}
                        <NavLink to="/" className="admin-left-manu" exact >
                            Back to Web App
                        </NavLink>
                    {/* </Typography> */}
                {/* </div> */}
                {/* <PrivateRoute exact path="/childlist" roles={[Role.User]} component={ChildrenList} />
                <PrivateRoute exact path="/teammember" roles={[Role.User]} component={TeamMemberList} />
                <PrivateRoute exact path="/administration" roles={[Role.User]} component={AdminDetails} />
                <PrivateRoute exact path="/" roles={[Role.User]} component={Home} /> */}
                 {/* <PrivateRoute path="/children" roles={[Role.Admin]} component={AdminPage} /> */}
             
            </Router>
            
        );
    }
}

export default AdminMenu;