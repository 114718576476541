import React, { useEffect, SyntheticEvent, FunctionComponent  } from "react";

import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import './style.scss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface notifierProps {
  className?: string;
  message?: string;
  variant: keyof typeof variantIcon;
  status?:string

}


export const Notifier: FunctionComponent<notifierProps> = ({ ...props }) =>{
  
  const [open, setOpen] = React.useState(true);
  const classes = useStyles1();
  const { className, message,  variant, status, ...other } = props;
  const Icon = variantIcon[variant];

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
console.log(reason)
    setOpen(false);
  };

  useEffect(
    () => {
      setOpen(true);
     },
    [status]
  );

return (
  <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <SnackbarContent
      // status="true"
        // status={status}
        // contentprops={{
        //   "aria-describedby": "message-id"
        // }}
        // prettier-ignore
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit"  onClick={handleClose} >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
     </Snackbar>
);
};