import * as React from 'react';
import { Box, Grid, Typography, Link } from '@material-ui/core'
import './newsFeedLoader.scss';
import ButtonReaction from '../buttonReaction/buttonReaction';
import { object } from 'prop-types';
import { storyService, authenticationService } from '../../../services';
import { Notifier } from '../notifier/notifier';
import { Common } from '../../../common';
const loaderImage = require('../../../assets/images/loader.png')
const avatar = Common.getAvadarDefaut();
const limit = 100;

export interface Props {

}

export interface State {

}

let childUser;

class NewsFeedLoader extends React.Component<Props, State>{
    render() {


        return (
            <div style={{ padding: "8px" }}>
                <Grid container>
                    <Grid item xs={3} style={{}}>
                        <img src={avatar} style={{ maxWidth: "70%", margin: "auto" }}></img>
                    </Grid>
                    <Grid item xs={9} className="information-story">
                        <Box>
                            <img src={loaderImage} style={{ width: "100%" }} />
                        </Box>
                        <Box>
                            <img src={loaderImage} style={{ width: "100%" }} />
                        </Box>
                        <Box style={{ display: "flex" }}>
                            <Grid item xs={7}>
                                <Box>

                                </Box>
                            </Grid>
                            <Grid item xs={5}>

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default NewsFeedLoader;