import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface TurtleProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface TurtleState {
    height:string
}


export class Turtle extends React.Component<TurtleProps, TurtleState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill="#314C2C" d="M356,392.7c0-0.3-0.3-0.5-0.5-0.6l-5.1-1.4c-0.6-0.2-0.7-0.9-0.2-1.2l4.6-2.9c0.3-0.2,0.4-0.4,0.4-0.7
                                c-1.3-7.9-3.5-15.5-6.5-22.5c-0.1-0.3-0.4-0.4-0.7-0.4l-4.7,0.1c-0.6,0-1-0.7-0.6-1.1l3.1-3.6c0.2-0.2,0.2-0.5,0.1-0.7
                                c-4.7-8.8-10.9-16.8-18.7-23.6c-0.2-0.2-0.5-0.3-0.8-0.1l-2.5,1c-0.5,0.2-1.1-0.2-1.1-0.7l0.4-2.8c0-0.3-0.1-0.5-0.3-0.6
                                c-16.8-12.6-40.3-20.5-71.8-21.5l0,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0l0,0c-0.2,0-0.3,0-0.4,0
                                c-0.2,0-0.3,0-0.4,0l0,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0l0,0c-31.5,1-55,8.8-71.8,21.5
                                c-0.2,0.2-0.3,0.4-0.3,0.6l0.4,2.8c0.1,0.5-0.5,1-1.1,0.7l-2.5-1c-0.3-0.1-0.6-0.1-0.8,0.1c-7.9,6.8-14.1,14.8-18.7,23.6
                                c-0.1,0.3-0.1,0.5,0.1,0.7l3.1,3.6c0.4,0.4,0,1.1-0.6,1.1l-4.7-0.1c-0.4,0-0.6,0.2-0.7,0.4c-3,7.1-5.2,14.6-6.5,22.5
                                c-0.1,0.3,0.1,0.5,0.4,0.7l4.6,2.9c0.5,0.4,0.4,1.1-0.2,1.2l-5.1,1.4c-0.3,0.1-0.5,0.4-0.5,0.6c-0.5,4.7-0.7,9.4-0.7,14.2
                                c0,0.7,0,1.4,0,2.1c0,0.3,0.2,0.5,0.4,0.6l2.5,1c0.5,0.2,0.6,0.8,0.2,1.1l-2.6,2.2c-0.2,0.2-0.3,0.4-0.3,0.6
                                c0.6,7.6,2.3,14.9,4.7,21.6c0.1,0.4,0.5,0.5,0.9,0.4l5-0.9c0.6-0.1,1.1,0.5,0.8,1.1l-3.7,5.6c-0.1,0.2-0.2,0.4-0.1,0.6
                                c16.8,36.3,57.8,57.5,97.9,59.7c0,0,2,0.1,2.1,0.1c0.3,0,1-0.1,1-0.1c40.1-2.1,81.1-23.4,97.9-59.7c0.1-0.2,0.1-0.4-0.1-0.6
                                l-3.7-5.6c-0.4-0.5,0.2-1.1,0.8-1.1l5,0.9c0.4,0.1,0.7-0.1,0.9-0.4c2.4-6.7,4.1-14,4.7-21.6c0-0.2-0.1-0.4-0.3-0.6l-2.6-2.2
                                c-0.4-0.4-0.3-1,0.2-1.1l2.5-1c0.3-0.1,0.4-0.4,0.4-0.6c0-0.7,0-1.4,0-2.1C356.8,402.1,356.6,397.4,356,392.7z"/>
                            <path fill="#AED76B" d="M301.3,385.5c-0.3-0.4-7.5-7-17.6-8.9c-6.8-1.3-13.9,0.6-21,5.7c-4.3,3.1-8.7,5.2-13,5.2
                                c-4.3,0-8.7-2.1-13-5.2c-7.2-5.1-14.2-7-21-5.7c-10.2,1.9-17.4,8.6-17.6,8.9c-1.1,1.7-0.6,4,1.1,5c1.7,1.1,4,0.6,5-1.1
                                c0-0.1,5.8-4.3,12.9-5.7c4.8-0.9,10,0.6,15.5,4.5c6.3,4.4,12.1,6.3,17.2,6.5c0,0,0.1,0,0.2,0c5-0.4,11-2.1,17.2-6.5
                                c5.5-3.9,10.7-5.4,15.5-4.5c7,1.3,12.8,5.7,12.9,5.7c1.1,1.7,3.4,2.2,5,1.1C301.8,389.5,302.4,387.2,301.3,385.5z"/>
                            <path fill="#67A149" d="M255.9,432.1c9.3-0.5,24.8-2.6,33.4-5.6c0.4-0.1,0.4-0.4,0.4-0.8c-0.1-0.4-0.4-0.4-0.8-0.4
                                c-11.9,4.2-30.2,5.6-38.7,5.7V412c1.8,0,3.4-0.2,5.2-0.3c7.6-0.4,21.5-2.7,28.2-5.7c0.3-0.2,0.4-0.5,0.3-0.8
                                c-0.2-0.3-0.5-0.4-0.8-0.3c-9.5,4.3-25.7,5.8-32.9,5.9v-13.1c0-0.4-0.4-0.7-0.7-0.7l0,0l0,0c-0.4,0-0.7,0.4-0.7,0.7v13.1
                                c-7.2-0.1-23.5-1.6-32.9-5.9c-0.3-0.2-0.6,0-0.8,0.3c-0.2,0.3,0,0.6,0.3,0.8c6.7,3.1,20.5,5.3,28.2,5.7c1.8,0.1,3.4,0.3,5.2,0.3
                                v19.1c-8.6-0.2-26.8-1.6-38.7-5.7c-0.4-0.1-0.6,0.1-0.8,0.4c-0.1,0.4,0.1,0.6,0.4,0.8c8.7,3,24.2,5,33.4,5.6
                                c1.9,0.1,3.8,0.3,5.7,0.3v20.3c-9-0.1-27.3-1.8-38.4-7c-0.3-0.2-0.6,0-0.8,0.3c-0.2,0.3,0,0.6,0.3,0.8c7.8,3.6,22.9,6.3,32.1,6.7
                                c2.3,0.1,4.5,0.4,6.8,0.4v16.4c0,0.4,0.4,0.7,0.7,0.7l0,0l0,0c0.4,0,0.7-0.4,0.7-0.7v-16.4c2.3,0,4.6-0.3,6.8-0.4
                                c9.1-0.4,24.3-3.1,32.1-6.7c0.3-0.2,0.4-0.5,0.3-0.8c-0.2-0.3-0.5-0.4-0.8-0.3c-11.1,5.2-29.4,6.9-38.4,7v-20.3
                                C252.1,432.3,254,432.2,255.9,432.1z"/>
                            <path fill="#AED76B" d="M188.5,435.2c-0.3,1.9-0.4,3.8-0.4,5.8c0,0.9,0.1,1.9,0.2,2.7c0.1-2.7,0.2-5.4,0.4-8
                                C188.6,435.5,188.5,435.3,188.5,435.2z"/>
                            <path fill="#AED76B" d="M310.9,441c0-1.9-0.1-3.9-0.4-5.8c0,0.2-0.1,0.3-0.1,0.4c0.1,2.7,0.3,5.3,0.4,8
                                C310.8,442.9,310.9,441.9,310.9,441z"/>
                            <path fill="#8CC63F" d="M317,517.4c0,0-1.3-15.1-6.5-19.8c-1.2-1.1-8.9,0.4-20.2,2.8c0,0-6.8,5.7-8.2,16.9
                                C282.1,517.2,301.2,523.3,317,517.4z"/>
                            <path fill="#2D4728" d="M316.5,517.5l4.9-0.2c1.4,0,1.8-1.8,0.6-2.8l-3.7-3.4c-1.1-1.1-2.9-0.9-3.4,0.4l-1,2.8
                                C313.4,515.7,314.8,517.6,316.5,517.5z"/>
                            <path fill="#395E32" d="M310.7,520h4.9c1.4,0,1.9-1.8,0.7-2.8l-3.6-3.5c-1.1-1.1-2.8-1-3.4,0.3l-1.1,2.8
                                C307.7,518.1,309,520,310.7,520z"/>
                            <path fill={backGroundColor} d="M383.6,258.9c-0.8-7.8-2.9-15.7-6.1-23.7c-2.7-6.5-6-13-10.1-19.4l-0.3-31.7c0,0-18.7-6-35.5-9.4
                                c-1.7-0.4-3.4-0.5-5-0.5c-21.8-14.8-48.3-24.3-76.9-24.3s-55.1,9.6-76.9,24.3c-1.7,0-3.3,0.2-5,0.5c-16.8,3.4-35.5,9.4-35.5,9.4
                                l-0.3,31.7c-4,6.2-7.3,12.6-9.9,19c-4.3,10.6-6.6,21.4-6.6,31.7c0,6.6,0.4,13.1,1.1,19.2c0.4,2.9,0.8,5.7,1.3,8.6
                                c2.1,10.9,5.6,20.8,10.5,29.6c12.2,22.1,33.5,37.7,65.2,45.6l0,0c-9.9,10-50.8,53.9-25.3,69.1c8,4.8,14.7,3.5,20.1-0.6
                                c-0.1,1-0.1,2-0.1,3c0,0.7,0,1.4,0.1,2.1c0,0.2,0,0.4,0.1,0.5c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0.1,22.5,1.4,34.3
                                c0.2,1.9,0.5,3.7,0.9,5.5l0,0c-0.2,0-0.4,0-0.5,0h-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1
                                c-0.1,0.1-0.2,0.1-0.3,0.2c-5.2,4.8-6.5,19.8-6.5,19.8c15.8,5.9,34.9-0.2,34.9-0.2l0,0l0,0c8.4-7.5,9.2-28.5,9-38.4
                                c0.4,0.1,0.9,0.3,1.2,0.4c0.3,0.1,0.5,0.2,0.8,0.2c0.5,0.2,1.1,0.3,1.7,0.4c0.1,0,0.3,0.1,0.4,0.1c1.4,0.4,2.8,0.6,4.3,0.9l0,0l0,0
                                c1,0.2,1.9,0.4,2.8,0.5l0,0l0,0c0.9,0.2,1.7,0.3,2.6,0.4l0,0c0,0,0,0,0.1,0c2.9,0.4,5.9,0.6,8.9,0.8h0.6c1.1,0,2.1-0.1,3.2-0.2
                                c0.1,0,0.2,0,0.3,0l0,0c1.8-0.1,3.4-0.3,5.2-0.5l0,0c0.1,0,0.2,0,0.4-0.1l0,0c0.9-0.1,1.7-0.3,2.6-0.4l0,0h0.1l0,0
                                c0.9-0.2,1.9-0.3,2.7-0.4l0,0l0,0c1.4-0.3,2.8-0.6,4.3-0.9c0.1,0,0.3-0.1,0.4-0.1c0.5-0.2,1.1-0.3,1.7-0.4c0.3-0.1,0.5-0.2,0.8-0.2
                                c0.4-0.1,0.9-0.3,1.3-0.4c-0.1,9.8,0.6,30.7,9,38.3c0,0,0.1,0,0.1,0.1l0,0c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15.1-6.5-19.8
                                c-0.3-0.3-0.8-0.4-1.6-0.4c0.4-1.9,0.7-3.7,0.9-5.5c1.4-11.9,1.4-34.3,1.4-34.3c0-4.7,0-9.2-0.1-13.6l0,0c0-0.2,0-0.4,0.1-0.5
                                c0.1-0.7,0.1-1.4,0.1-2.1c0-1.1-0.1-2.3-0.2-3.4c5.4,4.5,12.2,5.9,20.5,1c25.6-15.3-15.4-59.1-25.3-69.1l0,0
                                c31.7-8,53-23.5,65.2-45.6c4.9-8.8,8.4-18.7,10.4-29.6c0.5-2.7,0.9-5.3,1.2-8l0,0c0.4-2.6,0.6-5.2,0.8-7.9
                                c0.3-3.9,0.4-7.8,0.4-11.8C384.1,264.1,383.9,261.5,383.6,258.9z"/>
                            <path fill="#2D4728" d="M182.7,517.5l-4.9-0.2c-1.4,0-1.8-1.8-0.6-2.8l3.7-3.4c1.1-1.1,2.9-0.9,3.4,0.4l1,2.8
                                C185.9,515.7,184.4,517.6,182.7,517.5z"/>
                            <path fill="#395E32" d="M188.5,520h-4.9c-1.4,0-1.9-1.8-0.7-2.8l3.6-3.5c1.1-1.1,2.8-1,3.4,0.3l1.1,2.8
                                C191.6,518.1,190.2,520,188.5,520z"/>
                            <path fill="#395E32" d="M367.2,184.2c0,0,9.1-10.5-8.4-19.3c-5.7-2.8-17.4-7.2-30.1-5c-16.5,2.9-34.1,19.7-34.1,19.7
                                c19.7-3.5,27.6-6.7,37-4.9C348.5,178.2,367.2,184.2,367.2,184.2z"/>
                            
                                <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="331.8595" y1="224.1302" x2="331.0661" y2="270.9406" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0"  stop-color="#EAEAEA" stop-opacity="0"/>
                                <stop  offset="0.1515"  stop-color="#D9D9D9" stop-opacity="0.1515"/>
                                <stop  offset="0.4526"  stop-color="#ACACAC" stop-opacity="0.4526"/>
                                <stop  offset="0.8711"  stop-color="#646464" stop-opacity="0.8711"/>
                                <stop  offset="1"  stop-color="#4D4D4D"/>
                            </linearGradient>
                            <path opacity="0.35" fill="url(#SVGID_23_)" enable-background="new    " d="M331.6,174.8c-9.5-1.9-17.3,1.3-37,4.9
                                c0,0,5,37.6,8.3,75.5c21.1-10.7,64.8-8.6,64.8-8.6l-0.6-62.4C367.2,184.2,348.5,178.2,331.6,174.8z"/>
                            <path fill="#395E32" d="M132.3,184.2c0,0-9.1-10.5,8.4-19.3c5.7-2.8,17.4-7.2,30.1-5c16.5,2.9,34.1,19.7,34.1,19.7
                                c-19.7-3.5-27.6-6.7-37-4.9C151,178.2,132.3,184.2,132.3,184.2z"/>
                            
                                <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="168.6765" y1="223.3333" x2="167.8754" y2="270.5959" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0"  stop-color="#EAEAEA" stop-opacity="0"/>
                                <stop  offset="0.1515"  stop-color="#D9D9D9" stop-opacity="0.1515"/>
                                <stop  offset="0.4526"  stop-color="#ACACAC" stop-opacity="0.4526"/>
                                <stop  offset="0.8711"  stop-color="#646464" stop-opacity="0.8711"/>
                                <stop  offset="1"  stop-color="#4D4D4D"/>
                            </linearGradient>
                            <path opacity="0.35" fill="url(#SVGID_24_)" enable-background="new    " d="M167.8,174.8c-16.8,3.4-35.5,9.4-35.5,9.4l-0.6,62.4
                                c0,0,43.6-2,64.8,8.6c3.3-37.9,8.3-75.5,8.3-75.5C185.2,176.2,177.3,173,167.8,174.8z"/>
                            
                                <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="3066.7676" y1="-102.298" x2="3065.5063" y2="-78.3395" gradientTransform="matrix(-0.9995 -3.020000e-02 3.020000e-02 -0.9995 3367.6272 511.1578)">
                                <stop  offset="1.012280e-07"  stop-color="#686868" stop-opacity="0"/>
                                <stop  offset="0.1242"  stop-color="#727272" stop-opacity="0.1145"/>
                                <stop  offset="0.3378"  stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                <stop  offset="0.613"  stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                <stop  offset="0.8676"  stop-color="#EAEAEA" stop-opacity="0.8"/>
                            </linearGradient>
                            <path opacity="0.5" fill="url(#SVGID_25_)" enable-background="new    " d="M317,517.4c0,0-1.3-15.1-6.5-19.8
                                c-1.2-1.1-8.9,0.4-20.2,2.8c0,0-6.8,5.7-8.2,16.9C282.1,517.2,301.2,523.3,317,517.4z"/>
                            <path fill="#2D4728" d="M316.5,517.5l4.9-0.2c1.4,0,1.8-1.8,0.6-2.8l-3.7-3.4c-1.1-1.1-2.9-0.9-3.4,0.4l-1,2.8
                                C313.4,515.7,314.8,517.6,316.5,517.5z"/>
                            <path fill="#395E32" d="M310.7,520h4.9c1.4,0,1.9-1.8,0.7-2.8l-3.6-3.5c-1.1-1.1-2.8-1-3.4,0.3l-1.1,2.8
                                C307.7,518.1,309,520,310.7,520z"/>
                            
                                <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="9792.8672" y1="-305.4741" x2="9791.6064" y2="-281.5155" gradientTransform="matrix(0.9995 -3.020000e-02 -3.020000e-02 -0.9995 -9596.8291 511.1578)">
                                <stop  offset="1.012280e-07"  stop-color="#686868" stop-opacity="0"/>
                                <stop  offset="0.1242"  stop-color="#727272" stop-opacity="0.1145"/>
                                <stop  offset="0.3378"  stop-color="#8E8E8E" stop-opacity="0.3115"/>
                                <stop  offset="0.613"  stop-color="#BBBBBB" stop-opacity="0.5653"/>
                                <stop  offset="0.8676"  stop-color="#EAEAEA" stop-opacity="0.8"/>
                            </linearGradient>
                            <path opacity="0.5" fill="url(#SVGID_26_)" enable-background="new    " d="M182.7,517.4c0,0,1.3-15.1,6.5-19.8
                                c1.2-1.1,8.9,0.4,20.2,2.8c0,0,6.8,5.7,8.2,16.9C217.5,517.2,198.5,523.3,182.7,517.4z"/>
                            <path opacity="0.35" fill="#4F4F4F" d="M226.6,478.9c-20.1-5.7-36.4-18-38.1-35.1c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0.1,22.5,1.4,34.3
                                c0.2,1.9,0.5,3.7,0.9,5.5l0,0c3-0.1,9.6,1.2,18.6,3.2c0,0,6.8,5.7,8.2,16.9l0,0C226,509.6,226.8,488.7,226.6,478.9z"/>
                            <path opacity="0.35" fill="#4F4F4F" d="M290.4,500.3c8.9-1.9,15.6-3.3,18.6-3.2c0.4-1.9,0.7-3.7,0.9-5.5
                                c1.4-11.9,1.4-34.3,1.4-34.3c0-4.7,0-9.2-0.1-13.6c-1.6,17.2-18,29.4-38.1,35.1c-0.1,9.8,0.6,30.7,9,38.3c0,0,0.1,0,0.1,0.1
                                C283.6,506,290.4,500.3,290.4,500.3z"/>
                            <path opacity="0.35" fill="#4F4F4F" enable-background="new    " d="M301.4,370.9L301.4,370.9c4,15.6,8.2,37.8,9.6,65.4
                                c0,0.4,0,0.8,0,1.2c5.5,4.7,12.4,6.3,20.8,1.2c25.6-15.3-15.4-59.1-25.3-69.1C304.7,370.1,303.1,370.5,301.4,370.9z"/>
                            <path opacity="0.35" fill="#4F4F4F" enable-background="new    " d="M188.6,436.1c1.3-27.7,5.7-50,9.6-65.5
                                c-1.6-0.4-3.2-0.7-4.7-1.1c-9.9,10-50.8,53.9-25.3,69.1c8.2,5,15,3.5,20.4-1C188.5,437.2,188.5,436.7,188.6,436.1z"/>
                            <path opacity="0.2" fill="#E2E2E2" enable-background="new    " d="M301.3,385.5c-0.3-0.4-7.5-7-17.6-8.9c-6.8-1.3-13.9,0.6-21,5.7
                                c-4.3,3.1-8.7,5.2-13,5.2c-4.3,0-8.7-2.1-13-5.2c-7.2-5.1-14.2-7-21-5.7c-10.2,1.9-17.4,8.6-17.6,8.9c-1.1,1.7-0.6,4,1.1,5
                                c1.7,1.1,4,0.6,5-1.1c0-0.1,5.8-4.3,12.9-5.7c4.8-0.9,10,0.6,15.5,4.5c6.3,4.4,12.1,6.3,17.2,6.5c0,0,0.1,0,0.2,0
                                c5-0.4,11-2.1,17.2-6.5c5.5-3.9,10.7-5.4,15.5-4.5c7,1.3,12.8,5.7,12.9,5.7c1.1,1.7,3.4,2.2,5,1.1
                                C301.8,389.5,302.4,387.2,301.3,385.5z"/>
                            <path opacity="0.2" fill="#E2E2E2" enable-background="new    " d="M255.6,482.1c0.2,0,0.4,0,0.5-0.1c0.8-0.1,1.6-0.2,2.4-0.3
                                c0.1,0,0.2,0,0.4-0.1c0.9-0.1,1.7-0.3,2.6-0.4h0.1c0.9-0.2,1.9-0.3,2.7-0.4l0,0c24.8-4.7,46.5-18.7,46.6-39.9
                                c0-1.9-0.1-3.9-0.4-5.8c-3.6,22.4-32.5,35.7-60.7,36.9h-0.6c-28.1-1.1-57.1-14.5-60.7-36.9c-0.3,1.9-0.4,3.8-0.4,5.8
                                c0.1,21.1,21.8,35.2,46.6,39.9l0,0c0.9,0.2,1.9,0.4,2.7,0.4h0.1c0.9,0.2,1.7,0.3,2.6,0.4c0.1,0,0.2,0,0.4,0.1
                                c0.8,0.1,1.6,0.2,2.4,0.3c0.2,0,0.4,0,0.5,0.1c0.8,0.1,1.5,0.2,2.3,0.2c0.2,0,0.4,0,0.6,0.1c1,0.1,1.9,0.1,2.8,0.2h0.6
                                c1,0,1.9-0.1,2.8-0.2c0.2,0,0.4,0,0.6-0.1C254.1,482.2,254.9,482.2,255.6,482.1z"/>
                            <path opacity="0.2" fill="#3A3A3A" enable-background="new    " d="M255.9,432.1c9.3-0.5,24.8-2.6,33.4-5.6
                                c0.4-0.1,0.4-0.4,0.4-0.8c-0.1-0.4-0.4-0.4-0.8-0.4c-11.9,4.2-30.2,5.6-38.7,5.7V412c1.8,0,3.4-0.2,5.2-0.3
                                c7.6-0.4,21.5-2.7,28.2-5.7c0.3-0.2,0.4-0.5,0.3-0.8c-0.2-0.3-0.5-0.4-0.8-0.3c-9.5,4.3-25.7,5.8-32.9,5.9v-13.1
                                c0-0.4-0.4-0.7-0.7-0.7l0,0l0,0c-0.4,0-0.7,0.4-0.7,0.7v13.1c-7.2-0.1-23.5-1.6-32.9-5.9c-0.3-0.2-0.6,0-0.8,0.3
                                c-0.2,0.3,0,0.6,0.3,0.8c6.7,3.1,20.5,5.3,28.2,5.7c1.8,0.1,3.4,0.3,5.2,0.3v19.1c-8.6-0.2-26.8-1.6-38.7-5.7
                                c-0.4-0.1-0.6,0.1-0.8,0.4c-0.1,0.4,0.1,0.6,0.4,0.8c8.7,3,24.2,5,33.4,5.6c1.9,0.1,3.8,0.3,5.7,0.3v20.3c-9-0.1-27.3-1.8-38.4-7
                                c-0.3-0.2-0.6,0-0.8,0.3c-0.2,0.3,0,0.6,0.3,0.8c7.8,3.6,22.9,6.3,32.1,6.7c2.3,0.1,4.5,0.4,6.8,0.4v16.4c0,0.4,0.4,0.7,0.7,0.7
                                l0,0l0,0c0.4,0,0.7-0.4,0.7-0.7v-16.4c2.3,0,4.6-0.3,6.8-0.4c9.1-0.4,24.3-3.1,32.1-6.7c0.3-0.2,0.4-0.5,0.3-0.8
                                c-0.2-0.3-0.5-0.4-0.8-0.3c-11.1,5.2-29.4,6.9-38.4,7v-20.3C252.1,432.3,254,432.2,255.9,432.1z"/>
                            <g>
                                <path fill="#56002F" d="M196.5,285.3c1.5,17.9,31,38,62.2,34.8c41.2-4.2,50.7-64,50.7-64s-21.6,13.9-56.6,13.4
                                    C217.7,268.9,194,256.1,196.5,285.3z"/>
                                <path fill="#FFFFFF" d="M296.2,262.3c-9.8,3.6-24.7,7.5-43.4,7.2c-34.9-0.6-58.8-13.4-56.3,15.8c0.1,1.4,0.4,2.8,0.9,4.2
                                    c11.9,2.1,45.2,4.2,55.5,3.5c12.3-0.8,37.4-7.8,39.8-10.3C297,278.5,296.9,268.7,296.2,262.3z"/>
                                <path fill="#FFFFFF" d="M295.7,293.6c-0.4-2.5-3.2-5.6-14.9-4.2c-18.5,2.1-61.6,0-61.6,0s-12.2,1.9-20.2,4.2
                                    c8,15.2,33.2,29.4,59.7,26.6C276.2,318.4,287.9,306.5,295.7,293.6z"/>
                                <path fill="#E0DEDF" d="M296.2,262.3c-9.8,3.6-24.7,7.5-43.4,7.2c-34.9-0.6-58.8-13.4-56.3,15.8c0.1,1.4,0.4,2.8,0.9,4.2
                                    c3.1,0.5,7.6,1.1,12.7,1.6c-0.7-2.9-2.5-13,8.3-13.4c38.7-1.1,75.5-9.6,78.1-12.3C296.4,264.3,296.3,263.3,296.2,262.3z"/>
                                <path fill="#E0DEDF" d="M214.7,298.6c-0.8-4.1,0.4-6,0.6-7.5c-4.2,0.8-11.5,1.1-16.3,2.4c4.1,7.8,12.6,15.2,23.3,20.3
                                    C218.3,309,215.4,302.5,214.7,298.6z"/>
                                <polygon fill="#56002F" points="258,289.7 255.9,281.6 254.1,290.2 		"/>
                            </g>
                            <path fill="#395E32" d="M332.8,266.2c0,0,19.5-6.4,35.6,0.3C352.7,270.8,332.8,266.2,332.8,266.2z"/>
                            <path fill="#395E32" d="M367.2,184.2c0,0,9.1-10.5-8.4-19.3c-5.7-2.8-17.4-7.2-30.1-5c-16.5,2.9-34.1,19.7-34.1,19.7
                                c19.7-3.5,27.6-6.7,37-4.9C348.5,178.2,367.2,184.2,367.2,184.2z"/>
                            <path fill="#395E32" d="M166.7,266.2c0,0-19.5-6.4-35.6,0.3C146.8,270.8,166.7,266.2,166.7,266.2z"/>
                            <path fill="#395E32" d="M258.8,222.6c0.2-2.5-1.9-4.2-4.1-3.9C249.1,222.2,256.2,223.2,258.8,222.6z"/>
                            <path fill="#395E32" d="M244.8,218.8c-2.1-0.4-4.2,1.3-4.1,3.9C243.3,223.2,250.4,222.2,244.8,218.8z"/>
                            <path fill="#395E32" d="M132.3,184.2c0,0-9.1-10.5,8.4-19.3c5.7-2.8,17.4-7.2,30.1-5c16.5,2.9,34.1,19.7,34.1,19.7
                                c-19.7-3.5-27.6-6.7-37-4.9C151,178.2,132.3,184.2,132.3,184.2z"/>
                            <path fill="#FFFFFF" d="M165.9,200.2c-16.2,0-29.3,13.1-29.3,29.3s13.1,29.3,29.3,29.3s29.3-13.1,29.3-29.3
                                C195.2,213.3,182.1,200.2,165.9,200.2z"/>
                            <path fill="#21323F" d="M175.8,216.2c-3.6,0-7,1.2-9.6,3.4c3.1,0.2,5.7,2.7,5.7,5.9l0,0c0,3.3-2.7,5.9-5.9,5.9h-5.4
                                c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3C191,223.1,184.2,216.2,175.8,216.2z"/>
                            <path fill="#FFFFFF" d="M333.6,200.2c-16.2,0-29.3,13.1-29.3,29.3s13.1,29.3,29.3,29.3s29.3-13.1,29.3-29.3
                                C362.7,213.3,349.7,200.2,333.6,200.2z"/>
                            <path fill="#21323F" d="M327.6,225.6L327.6,225.6c0-3.2,2.5-5.7,5.7-5.9c-2.7-2.1-5.9-3.4-9.6-3.4c-8.4,0-15.3,6.8-15.3,15.3
                                c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3h-5.4C330.3,231.5,327.6,228.9,327.6,225.6z"/>
                            <path fill="#2D4728" d="M182.7,517.5l-4.9-0.2c-1.4,0-1.8-1.8-0.6-2.8l3.7-3.4c1.1-1.1,2.9-0.9,3.4,0.4l1,2.8
                                C185.9,515.7,184.4,517.6,182.7,517.5z"/>
                            <path fill="#395E32" d="M188.5,520h-4.9c-1.4,0-1.9-1.8-0.7-2.8l3.6-3.5c1.1-1.1,2.8-1,3.4,0.3l1.1,2.8
                                C191.6,518.1,190.2,520,188.5,520z"/>
                        </svg>
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=     <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill="#314C2C" d="M356.4,392.8c0-0.3-0.3-0.5-0.5-0.6l-5.1-1.4c-0.4-0.1-0.6-0.4-0.5-0.8c0-0.2,0.2-0.4,0.3-0.5l4.6-2.9
                                c0.2-0.1,0.4-0.4,0.3-0.7c-1.3-7.7-3.5-15.3-6.6-22.5c-0.1-0.3-0.4-0.5-0.7-0.4l-4.7,0.1c-0.4,0-0.7-0.3-0.7-0.7
                                c0-0.2,0-0.3,0.1-0.4l3.1-3.6c0.2-0.2,0.2-0.5,0.1-0.8c-4.7-9-11.1-17-18.7-23.6c-0.2-0.2-0.5-0.2-0.8-0.1l-2.5,1
                                c-0.4,0.2-0.8,0-1-0.4c-0.1-0.1-0.1-0.2-0.1-0.4l0.4-2.9c0-0.2-0.1-0.5-0.3-0.6c-16.8-12.6-40.3-20.5-71.8-21.5l0,0h-1.1l0,0h-0.4
                                h-0.4l0,0h-1.1l0,0c-31.5,1-55,8.8-71.8,21.5c-0.2,0.1-0.3,0.4-0.3,0.6l0.4,2.9c0,0.4-0.3,0.8-0.7,0.8c-0.1,0-0.3,0-0.4-0.1l-2.5-1
                                c-0.3-0.1-0.6-0.1-0.8,0.1c-7.7,6.6-14,14.6-18.7,23.6c-0.1,0.2-0.1,0.5,0.1,0.8l3.1,3.6c0.2,0.3,0.2,0.7-0.1,1
                                c-0.1,0.1-0.3,0.2-0.5,0.2l-4.7-0.1c-0.3,0-0.6,0.2-0.7,0.4c-3.1,7.2-5.3,14.8-6.5,22.5c0,0.3,0.1,0.5,0.3,0.7l4.6,2.9
                                c0.3,0.2,0.4,0.6,0.2,1c-0.1,0.2-0.3,0.3-0.4,0.3l-5.1,1.4c-0.3,0.1-0.5,0.3-0.5,0.6c-0.5,4.7-0.7,9.5-0.7,14.2c0,0.7,0,1.4,0,2.1
                                c0,0.3,0.2,0.5,0.5,0.6l2.4,1c0.4,0.1,0.5,0.5,0.4,0.9c0,0.1-0.1,0.2-0.2,0.3l-2.5,2.2c-0.2,0.1-0.3,0.4-0.2,0.6
                                c0.6,7.4,2.2,14.6,4.7,21.6c0.1,0.4,0.5,0.6,0.9,0.5l4.9-0.9c0.4-0.1,0.8,0.1,0.9,0.5c0,0.2,0,0.4-0.1,0.5l-3.7,5.6
                                c-0.1,0.2-0.1,0.4,0,0.6c16.8,36.3,57.8,57.5,97.9,59.7l2.1,0l0.9,0c40.1-2.2,81.1-23.4,97.9-59.7c0.1-0.2,0.1-0.5-0.1-0.6
                                l-3.7-5.6c-0.2-0.3-0.1-0.8,0.3-1c0.2-0.1,0.4-0.1,0.5-0.1l4.9,0.9c0.4,0.1,0.7-0.1,0.9-0.5c2.5-7,4.1-14.2,4.7-21.6
                                c0-0.2-0.1-0.4-0.2-0.6l-2.6-2.2c-0.3-0.2-0.3-0.7-0.1-1c0.1-0.1,0.2-0.2,0.3-0.2l2.4-1c0.3-0.1,0.4-0.3,0.5-0.6c0-0.7,0-1.4,0-2.1
                                C357.2,402.2,356.9,397.5,356.4,392.8z"/>
                            <path fill={backGroundColor} d="M384,258.9c-0.9-8.1-3-16.1-6.1-23.7c-2.7-6.8-6.1-13.2-10-19.4l-0.3-31.6c0,0-18.7-6-35.6-9.4
                                c-1.6-0.3-3.3-0.5-5-0.5c-21.8-14.8-48.3-24.3-76.9-24.3s-55.1,9.6-76.9,24.3c-1.7,0-3.3,0.2-5,0.5c-16.8,3.3-35.6,9.4-35.6,9.4
                                l-0.3,31.6c-3.9,6-7.2,12.4-9.9,19c-4.3,10.6-6.6,21.4-6.6,31.6c0,6.4,0.4,12.8,1.1,19.2c0.4,2.9,0.8,5.8,1.3,8.5
                                c1.9,10.4,5.4,20.4,10.5,29.6c12.8,23.2,35.6,39.2,69.9,46.7c-0.2,0.9-0.5,1.8-0.7,2.7c0.2-0.9,0.5-1.8,0.7-2.7l-0.9-0.2
                                c-20.3,2.7-65.9,11.5-57.1,36.3s39.3,5.8,51.4-3.1l0,0c-1.4,9.6-2.5,20.3-3.1,32c0-0.2-0.1-0.3-0.1-0.5c-0.2,1.9-0.4,3.9-0.4,5.8
                                c0,0.7,0,1.5,0.1,2.2c0,0.2,0,0.4,0.1,0.6c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0.1,22.4,1.4,34.3c0.2,1.8,0.5,3.7,0.9,5.5l0,0
                                c-0.6,0-1.1,0.1-1.6,0.4c-5.2,4.8-6.5,19.8-6.5,19.8c15.8,5.9,34.9-0.2,34.9-0.2l0,0l0,0c8.4-7.5,9.2-28.5,9-38.3l1.3,0.4l0.8,0.2
                                c0.6,0.2,1.1,0.3,1.7,0.4l0.3,0.1c1.4,0.3,2.9,0.6,4.3,0.9l0,0c0.9,0.2,1.9,0.3,2.8,0.5l0,0c0.9,0.1,1.7,0.3,2.6,0.4h0.1
                                c3,0.4,5.9,0.7,8.9,0.8h0.6c1.1,0,2.1-0.1,3.2-0.2h0.3l0,0c1.7-0.1,3.5-0.3,5.2-0.5l0,0l0.3,0l0,0l2.6-0.4l0,0h0.1l0,0
                                c0.9-0.1,1.8-0.3,2.7-0.5l0,0c1.5-0.3,2.9-0.6,4.3-0.9l0.4-0.1l1.7-0.4l0.8-0.2l1.3-0.4c-0.1,9.8,0.6,30.7,9,38.3h0.1l0,0
                                c0,0,19.1,6.1,34.9,0.2c0,0-1.3-15-6.5-19.8c-0.5-0.3-1-0.4-1.6-0.4c0.4-1.8,0.7-3.7,0.9-5.5c1.4-11.9,1.4-34.3,1.4-34.3
                                c0-4.7,0-9.2-0.1-13.6l0,0l0.1-0.6c0.1-0.7,0.1-1.4,0.1-2.2c0-1.1-0.1-2.3-0.1-3.4c5.4,4.5,12.2,6,20.5,1
                                c25.6-15.3-15.4-59.1-25.3-69.1c31.7-8,52.9-23.5,65.2-45.7c5-9.2,8.6-19.2,10.5-29.6c0.5-2.6,0.9-5.3,1.3-8l0,0
                                c0.3-2.6,0.6-5.2,0.8-7.9c0.3-3.9,0.4-7.8,0.4-11.9C384.3,264,384.2,261.4,384,258.9z M276.5,477.8l0.4-0.1L276.5,477.8
                                L276.5,477.8z M278.6,477.1l0.2-0.1l-0.4,0.2L278.6,477.1z M283.7,475.1l0.2-0.1l-0.4,0.2L283.7,475.1z M288.9,472.5L288.9,472.5
                                l-0.2,0.2L288.9,472.5z M293.4,469.8l-0.2,0.1L293.4,469.8z M294.7,469l0.3-0.2L294.7,469L294.7,469z M309.3,451.4l-0.1,0.1
                                L309.3,451.4z M308.7,452.8c0,0.1,0,0.1-0.1,0.2C308.7,452.9,308.7,452.9,308.7,452.8L308.7,452.8z M303.7,460.8
                                c-0.1,0.1-0.1,0.1-0.2,0.2C303.5,461,303.6,460.9,303.7,460.8L303.7,460.8z M302.6,462.1l-0.1,0.2L302.6,462.1z M197.8,373.8
                                c-0.2,0.8-0.4,1.7-0.6,2.6C197.4,375.5,197.6,374.7,197.8,373.8z M192.5,400.4c-0.1,0.4-0.1,0.8-0.2,1.1
                                C192.4,401.2,192.5,400.8,192.5,400.4L192.5,400.4z M197,377.2c-0.2,0.8-0.4,1.7-0.6,2.6C196.6,378.9,196.8,378,197,377.2z
                                M196.2,380.7c-0.2,0.7-0.3,1.3-0.4,2C195.9,382,196,381.3,196.2,380.7L196.2,380.7z M195.4,384.3l-0.4,1.8L195.4,384.3z
                                M194.8,387.7c-0.2,0.8-0.3,1.5-0.4,2.3C194.4,389.2,194.6,388.4,194.8,387.7L194.8,387.7z M194,391.8c-0.1,0.4-0.2,0.8-0.2,1.2
                                C193.8,392.6,193.9,392.2,194,391.8L194,391.8z M193.2,396c-0.1,0.4-0.1,0.7-0.2,1.1C193.1,396.7,193.2,396.4,193.2,396L193.2,396z
                                M223.3,477.8l-0.4-0.1L223.3,477.8L223.3,477.8z M221.3,477.2l-0.4-0.2l0.2,0.1L221.3,477.2z M216.2,475.1l-0.4-0.2l0.2,0.1
                                L216.2,475.1z M211,472.6l-0.3-0.2h0.1L211,472.6z M206.5,470l-0.2-0.1L206.5,470z M205.1,469l-0.3-0.2L205.1,469L205.1,469z
                                M190.5,451.5l-0.1-0.1L190.5,451.5z M191.1,453l-0.1-0.2L191.1,453z M196.2,461.1l-0.2-0.2L196.2,461.1z M197.3,462.3l-0.1-0.2
                                L197.3,462.3z M197.7,498.1l0.2,0L197.7,498.1z M193.7,497.4L193.7,497.4z M207.6,500.1l1.9,0.4l0.1,0.1l-0.1-0.1L207.6,500.1z
                                M199,498.3l0.4,0.1L199,498.3z M200.3,498.5l0.5,0.1L200.3,498.5z M202.5,499l0.8,0.2L202.5,499z M204.1,499.3l0.9,0.2
                                L204.1,499.3z M205.8,499.7l1.1,0.2L205.8,499.7z M290.2,500.6L290.2,500.6l1.9-0.5L290.2,500.6L290.2,500.6z M300.8,498.3
                                l-0.4,0.1L300.8,498.3z M299.5,498.6l-0.5,0.1L299.5,498.6z M297.3,499l-0.7,0.2L297.3,499z M295.7,499.3l-0.9,0.2L295.7,499.3z
                                M294,499.7l-1.1,0.2L294,499.7z M301.8,498.1l0.2,0L301.8,498.1z M306,497.5L306,497.5z M301.2,370.9L301.2,370.9L301.2,370.9
                                L301.2,370.9z"/>
                            <path fill="#395E32" d="M367.5,184.2c0,0,9.1-10.5-8.4-19.2c-5.6-2.8-17.4-7.2-30.1-5c-16.5,2.9-34.1,19.7-34.1,19.7
                                c19.7-3.5,27.6-6.7,37-4.8C348.8,178.2,367.5,184.2,367.5,184.2z"/>
                            <path fill="#2D4728" d="M316.8,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                c-0.4,1.4,0.5,2.9,1.9,3.2C316.4,517.5,316.6,517.5,316.8,517.5z"/>
                            <path fill="#395E32" d="M311,520h4.8c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.2-1.1-2.9-1-3.4,0.3l-1.1,2.8c-0.4,1.4,0.4,2.9,1.8,3.3
                                C310.6,520,310.8,520,311,520z"/>
                            <path fill="#395E32" d="M132.6,184.2c0,0-9.1-10.5,8.4-19.2c5.6-2.8,17.4-7.2,30.1-5c16.5,2.9,34.1,19.7,34.1,19.7
                                c-19.7-3.5-27.6-6.7-37-4.8C151.3,178.2,132.6,184.2,132.6,184.2z"/>
                            <path fill="#2D4728" d="M183.1,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                c0.4,1.4-0.5,2.9-1.9,3.2C183.5,517.5,183.3,517.5,183.1,517.5z"/>
                            <path fill="#395E32" d="M188.9,520H184c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.9-1,3.4,0.3l1.1,2.8c0.4,1.4-0.4,2.9-1.8,3.3
                                C189.3,520,189.1,520,188.9,520z"/>
                            <path fill="#395E32" d="M367.5,184.2c0,0,9.1-10.5-8.4-19.2c-5.6-2.8-17.4-7.2-30.1-5c-16.5,2.9-34.1,19.7-34.1,19.7
                                c19.7-3.5,27.6-6.7,37-4.8C348.8,178.2,367.5,184.2,367.5,184.2z"/>
                            <path fill="#395E32" d="M132.6,184.2c0,0-9.1-10.5,8.4-19.2c5.6-2.8,17.4-7.2,30.1-5c16.5,2.9,34.1,19.7,34.1,19.7
                                c-19.7-3.5-27.6-6.7-37-4.8C151.3,178.2,132.6,184.2,132.6,184.2z"/>
                            <path opacity="0.35" fill="#4F4F4F" enable-background="new    " d="M198.6,370.7l-0.9-0.2c-20.3,2.7-65.9,11.5-57.1,36.3
                                c8.8,24.8,39.3,5.8,51.4-3.1C193.6,392.6,195.8,381.6,198.6,370.7z"/>
                            
                                <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="332.2074" y1="224.1075" x2="331.4115" y2="270.9126" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#EAEAEA" stop-opacity="0"/>
                                <stop  offset="0.28"  stop-color="#C7C7C7" stop-opacity="0.22"/>
                                <stop  offset="0.83"  stop-color="#6C6C6C" stop-opacity="0.8"/>
                                <stop  offset="1"  stop-color="#4D4D4D"/>
                            </linearGradient>
                            <path opacity="0.35" fill="url(#SVGID_19_)" enable-background="new    " d="M332,174.9c-9.4-1.9-17.3,1.3-37,4.8
                                c0,0,5.1,37.6,8.4,75.5c21.2-10.7,64.8-8.6,64.8-8.6l-0.6-62.4C367.5,184.2,348.8,178.2,332,174.9z"/>
                            
                                <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="169.0317" y1="223.3117" x2="168.227" y2="270.5677" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0"  stop-color="#EAEAEA" stop-opacity="0"/>
                                <stop  offset="0.28"  stop-color="#C7C7C7" stop-opacity="0.22"/>
                                <stop  offset="0.83"  stop-color="#6C6C6C" stop-opacity="0.8"/>
                                <stop  offset="1"  stop-color="#4D4D4D"/>
                            </linearGradient>
                            <path opacity="0.35" fill="url(#SVGID_20_)" enable-background="new    " d="M168.2,174.9c-16.8,3.3-35.6,9.4-35.6,9.4l-0.6,62.4
                                c0,0,43.6-2.1,64.8,8.6c3.3-37.9,8.4-75.5,8.4-75.5C185.5,176.2,177.6,173,168.2,174.9z"/>
                            <path opacity="0.35" fill="#4F4F4F" enable-background="new    " d="M301.2,370.7L301.2,370.7c5.4,21.5,8.6,43.4,9.6,65.4
                                c0,0.4,0,0.8,0,1.2c5.5,4.7,12.4,6.3,20.8,1.3c25.6-15.3-15.4-59.1-25.3-69.1C304.6,370,303,370.4,301.2,370.7z"/>
                            <path opacity="0.2" fill="#E2E2E2" enable-background="new    " d="M301.6,385.5c-5-4.5-11.1-7.6-17.6-9
                                c-6.8-1.3-13.9,0.6-21.1,5.7c-4.4,3.1-8.7,5.2-13,5.2c-4.3,0-8.7-2.1-13-5.2c-7.2-5.1-14.3-7-21.1-5.7c-6.6,1.4-12.7,4.5-17.6,9
                                c-1.1,1.7-0.6,4,1.1,5.1s4,0.6,5.1-1.1c3.9-2.8,8.2-4.7,12.9-5.7c4.8-0.9,10,0.6,15.5,4.5c6.3,4.5,12.1,6.2,17.2,6.6h0.1
                                c5.1-0.3,10.9-2.1,17.2-6.6c5.5-3.9,10.7-5.4,15.5-4.5c4.6,1,9,3,12.9,5.7c1.1,1.7,3.4,2.2,5.1,1.1S302.8,387.2,301.6,385.5
                                L301.6,385.5z"/>
                            <path opacity="0.2" fill="#E2E2E2" enable-background="new    " d="M255.9,482.1l0.5-0.1c0.8-0.1,1.6-0.2,2.4-0.3l0.3,0l2.6-0.4
                                h0.1c0.9-0.1,1.8-0.3,2.7-0.5l0,0c24.8-4.7,46.6-18.8,46.6-39.8c0-1.9-0.1-3.9-0.4-5.8c-3.6,22.4-32.5,35.8-60.7,36.9h-0.6
                                c-28.1-1.1-57.1-14.5-60.7-36.9c-0.2,1.9-0.4,3.9-0.4,5.8c0.1,21.1,21.9,35.2,46.6,39.8l0,0c0.9,0.2,1.8,0.3,2.8,0.5h0.1
                                c0.9,0.1,1.7,0.3,2.6,0.4l0.3,0c0.8,0.1,1.6,0.2,2.4,0.3l0.5,0.1l2.3,0.2h0.6c1,0.1,1.9,0.1,2.9,0.2h0.6c1,0,1.9-0.1,2.9-0.2h0.6
                                L255.9,482.1z"/>
                            <path opacity="0.2" fill="#3A3A3A" enable-background="new    " d="M256.3,432.1c9.2-0.5,24.8-2.5,33.4-5.5
                                c0.3-0.1,0.5-0.4,0.4-0.7c-0.1-0.3-0.4-0.5-0.7-0.4c0,0-0.1,0-0.1,0c-11.9,4.1-30.1,5.6-38.7,5.7V412c1.8,0,3.5-0.2,5.2-0.3
                                c7.6-0.4,21.5-2.7,28.2-5.8c0.3-0.2,0.4-0.5,0.2-0.8c-0.1-0.3-0.5-0.4-0.7-0.3c-9.4,4.4-25.7,5.8-32.9,5.9v-13.1
                                c0-0.4-0.3-0.7-0.7-0.7l0,0c-0.4,0-0.7,0.3-0.7,0.7v13.1c-7.2-0.1-23.5-1.6-32.9-5.9c-0.3-0.2-0.7-0.1-0.8,0.2
                                c-0.2,0.3-0.1,0.7,0.2,0.8c0,0,0.1,0,0.1,0c6.7,3.1,20.6,5.3,28.2,5.8c1.8,0.1,3.5,0.3,5.2,0.3v19.1c-8.6-0.2-26.8-1.6-38.7-5.7
                                c-0.3-0.1-0.7,0-0.8,0.3s0,0.7,0.3,0.8c0,0,0.1,0,0.1,0c8.7,3,24.2,5,33.4,5.5c1.9,0.1,3.8,0.2,5.7,0.3v20.2
                                c-9-0.1-27.4-1.8-38.4-6.9c-0.3-0.2-0.7-0.1-0.8,0.2c-0.2,0.3-0.1,0.7,0.2,0.8c0,0,0.1,0,0.1,0c7.8,3.6,22.9,6.3,32.1,6.7
                                c2.3,0.1,4.5,0.3,6.8,0.4v16.4c0,0.4,0.3,0.7,0.7,0.7l0,0c0.4,0,0.7-0.3,0.7-0.7v-16.4c2.3,0,4.6-0.2,6.8-0.4
                                c9.2-0.4,24.3-3.1,32.1-6.7c0.3-0.1,0.5-0.5,0.4-0.8c-0.1-0.3-0.5-0.5-0.8-0.4c0,0-0.1,0-0.1,0c-11.1,5.2-29.4,6.9-38.4,6.9v-20.3
                                C252.5,432.3,254.3,432.2,256.3,432.1z"/>
                            <path fill="#56002F" d="M196.9,285.3c1.5,17.8,30.9,38,62.2,34.8c41.2-4.3,50.7-64,50.7-64s-21.6,13.9-56.6,13.3
                                C218.2,268.9,194.4,256.2,196.9,285.3z"/>
                            <path fill="#FFFFFF" d="M296.6,262.3c-13.9,5.1-28.6,7.5-43.4,7.2c-35-0.6-58.8-13.3-56.3,15.8c0.1,1.4,0.4,2.8,0.9,4.2
                                c11.9,2.1,45.2,4.2,55.5,3.5c12.3-0.8,37.4-7.8,39.8-10.2C297.3,278.5,297.2,268.7,296.6,262.3z"/>
                            <path fill="#FFFFFF" d="M296.1,293.6c-0.5-2.5-3.2-5.6-14.9-4.2c-18.5,2.2-61.6,0-61.6,0s-12.2,1.9-20.2,4.1
                                c8,15.2,33.1,29.4,59.7,26.7C276.5,318.3,288.3,306.6,296.1,293.6z"/>
                            <path fill="#E0DEDF" d="M296.6,262.3c-13.9,5.1-28.6,7.5-43.4,7.2c-35-0.6-58.8-13.3-56.3,15.8c0.1,1.4,0.4,2.8,0.9,4.2
                                c3.1,0.5,7.6,1.1,12.7,1.6c-0.7-3-2.5-13,8.3-13.3c38.7-1.1,75.5-9.6,78-12.3C296.8,264.3,296.7,263.3,296.6,262.3z"/>
                            <path fill="#E0DEDF" d="M215,298.6c-0.8-4.1,0.3-6,0.6-7.5c-4.3,0.8-11.5,1.1-16.2,2.4c4,7.7,12.5,15.2,23.4,20.3
                                C219,309.4,216.4,304.2,215,298.6z"/>
                            <polygon fill="#56002F" points="258.3,289.7 256.2,281.5 254.4,290.2 	"/>
                            <path fill="#395E32" d="M333.1,266.2c0,0,19.5-6.3,35.6,0.2C353,270.8,333.1,266.2,333.1,266.2z"/>
                            <path fill="#395E32" d="M367.5,184.2c0,0,9.1-10.5-8.4-19.2c-5.6-2.8-17.4-7.2-30.1-5c-16.5,2.9-34.1,19.7-34.1,19.7
                                c19.7-3.5,27.6-6.7,37-4.8C348.8,178.2,367.5,184.2,367.5,184.2z"/>
                            <path fill="#395E32" d="M167.1,266.2c0,0-19.5-6.3-35.6,0.2C147.2,270.8,167.1,266.2,167.1,266.2z"/>
                            <path fill="#395E32" d="M132.6,184.2c0,0-9.1-10.5,8.4-19.2c5.6-2.8,17.4-7.2,30.1-5c16.5,2.9,34.1,19.7,34.1,19.7
                                c-19.7-3.5-27.6-6.7-37-4.8C151.3,178.2,132.6,184.2,132.6,184.2z"/>
                            <path fill="#FFFFFF" d="M166.3,200.2c-16.2,0-29.2,13.1-29.2,29.2s13.1,29.2,29.2,29.2s29.2-13.1,29.2-29.2l0,0
                                C195.5,213.3,182.4,200.2,166.3,200.2z"/>
                            <path fill="#21323F" d="M176.1,216.3c-3.5,0-6.9,1.2-9.6,3.4c3.2,0.2,5.6,2.8,5.6,5.9l0,0c0,3.3-2.7,5.9-5.9,6h-5.4
                                c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3S184.6,216.3,176.1,216.3C176.1,216.3,176.1,216.3,176.1,216.3L176.1,216.3z"/>
                            <path fill="#FFFFFF" d="M333.9,200.2c-16.2,0-29.2,13.1-29.2,29.2s13.1,29.2,29.2,29.2s29.2-13.1,29.2-29.2
                                C363.1,213.3,350,200.2,333.9,200.2z"/>
                            <path fill="#21323F" d="M328,225.6L328,225.6c0-3.2,2.5-5.8,5.6-5.9c-6.6-5.3-16.2-4.2-21.4,2.3c-5.3,6.6-4.2,16.2,2.3,21.4
                                s16.2,4.2,21.4-2.3c2.2-2.7,3.4-6.1,3.4-9.6h-5.4C330.6,231.5,328,228.9,328,225.6z"/>
                            <path fill="#395E32" d="M259.1,222.6c0.2-1.9-1.2-3.7-3.2-3.9c-0.3,0-0.6,0-0.9,0C249.4,222.2,256.6,223.2,259.1,222.6z"/>
                            <path fill="#395E32" d="M245.1,218.8c-1.9-0.3-3.7,1-4,3c0,0.3-0.1,0.6,0,0.9C243.6,223.2,250.7,222.2,245.1,218.8z"/>
                            
                                <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="3065.3474" y1="-102.3088" x2="3064.0828" y2="-78.3536" gradientTransform="matrix(-1 -3.000000e-02 3.000000e-02 -1 3367.5618 511.53)">
                                <stop  offset="0"  stop-color="#686868" stop-opacity="0"/>
                                <stop  offset="9.000000e-02"  stop-color="#6F6F6F" stop-opacity="4.000000e-02"/>
                                <stop  offset="0.26"  stop-color="#828282" stop-opacity="0.16"/>
                                <stop  offset="0.47"  stop-color="#A2A2A2" stop-opacity="0.36"/>
                                <stop  offset="0.71"  stop-color="#CDCDCD" stop-opacity="0.62"/>
                                <stop  offset="0.87"  stop-color="#EAEAEA" stop-opacity="0.8"/>
                            </linearGradient>
                            <path opacity="0.5" fill="url(#SVGID_21_)" enable-background="new    " d="M317,517.4c0,0-1.3-15-6.5-19.8
                                c-1.2-1.1-8.9,0.3-20.2,2.8c0,0-6.8,5.6-8.3,16.9C282.1,517.3,301.1,523.3,317,517.4z"/>
                            
                                <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="9792.5537" y1="-305.5362" x2="9791.2979" y2="-281.5811" gradientTransform="matrix(0.9995 -3.018961e-02 -3.018961e-02 -0.9995 -9596.8154 511.1917)">
                                <stop  offset="0"  stop-color="#686868" stop-opacity="0"/>
                                <stop  offset="9.000000e-02"  stop-color="#6F6F6F" stop-opacity="4.000000e-02"/>
                                <stop  offset="0.26"  stop-color="#828282" stop-opacity="0.16"/>
                                <stop  offset="0.47"  stop-color="#A2A2A2" stop-opacity="0.36"/>
                                <stop  offset="0.71"  stop-color="#CDCDCD" stop-opacity="0.62"/>
                                <stop  offset="0.87"  stop-color="#EAEAEA" stop-opacity="0.8"/>
                            </linearGradient>
                            <path opacity="0.5" fill="url(#SVGID_22_)" enable-background="new    " d="M182.7,517.4c0,0,1.3-15,6.5-19.8
                                c1.2-1.1,8.9,0.3,20.2,2.8c0,0,6.8,5.6,8.3,16.9C217.7,517.3,198.6,523.3,182.7,517.4z"/>
                            <path opacity="0.35" fill="#4F4F4F" d="M226.7,478.9c-20-5.7-36.5-17.9-38.1-35.1c-0.1,4.4-0.2,8.9-0.1,13.6c0,0,0.1,22.4,1.4,34.3
                                c0.2,1.8,0.5,3.7,0.9,5.5l0,0c3-0.1,9.6,1.2,18.6,3.2c0,0,6.8,5.6,8.3,16.8l0,0C226.1,509.7,226.9,488.7,226.7,478.9z"/>
                            <path opacity="0.35" fill="#4F4F4F" d="M290.3,500.4c8.9-2,15.6-3.3,18.6-3.2c0.4-1.8,0.7-3.7,0.9-5.5c1.4-11.9,1.4-34.3,1.4-34.3
                                c0-4.7,0-9.2-0.1-13.6c-1.6,17.2-18.1,29.4-38.1,35.1c-0.1,9.8,0.6,30.7,9,38.3h0.1C283.5,506,290.3,500.4,290.3,500.4z"/>
                            <path fill="#2D4728" d="M316.8,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                c-0.4,1.4,0.5,2.9,1.9,3.2C316.4,517.5,316.6,517.5,316.8,517.5z"/>
                            <path fill="#395E32" d="M311,520h4.8c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.2-1.1-2.9-1-3.4,0.3l-1.1,2.8c-0.4,1.4,0.4,2.9,1.8,3.3
                                C310.6,520,310.8,520,311,520z"/>
                            <path fill="#2D4728" d="M183.1,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                c0.4,1.4-0.5,2.9-1.9,3.2C183.5,517.5,183.3,517.5,183.1,517.5z"/>
                            <path fill="#395E32" d="M188.9,520H184c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.9-1,3.4,0.3l1.1,2.8c0.4,1.4-0.4,2.9-1.8,3.3
                                C189.3,520,189.1,520,188.9,520z"/>
                        </svg>
 
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                        <path fill="#314C2C" d="M355.5,373.7c0-0.3-0.2-0.6-0.5-0.6l-5.1-1.5c-0.4-0.1-0.6-0.5-0.5-0.9c0-0.2,0.2-0.3,0.3-0.4l4.6-3.1
                                c0.2-0.2,0.4-0.4,0.3-0.7c-1.3-8.2-3.4-16.2-6.5-23.9c-0.1-0.3-0.4-0.5-0.7-0.5l-4.6,0.1c-0.4,0-0.7-0.3-0.8-0.7
                                c0-0.2,0.1-0.3,0.2-0.5l3.1-3.8c0.2-0.2,0.2-0.5,0.1-0.8c-4.6-9.4-10.9-18-18.6-25.1c-0.2-0.2-0.5-0.3-0.8-0.1l-2.5,1.1
                                c-0.4,0.2-0.8,0-1-0.4c-0.1-0.1-0.1-0.3-0.1-0.4l0.4-3c0-0.3-0.1-0.5-0.3-0.7c-16.6-13.4-39.9-21.8-71.1-22.8v0h-1.1v0H250h-0.4v0
                                h-1.1v0c-31.2,1-54.5,9.4-71.1,22.8c-0.2,0.2-0.3,0.4-0.3,0.7l0.4,3c0.1,0.4-0.2,0.8-0.6,0.8c-0.1,0-0.3,0-0.4-0.1l-2.5-1
                                c-0.3-0.1-0.6-0.1-0.8,0.1c-7.7,7.1-14,15.6-18.6,25.1c-0.1,0.3-0.1,0.6,0.1,0.8l3.1,3.8c0.3,0.3,0.2,0.8-0.1,1
                                c-0.1,0.1-0.3,0.2-0.5,0.2l-4.6-0.1c-0.3,0-0.6,0.2-0.7,0.5c-3.1,7.7-5.2,15.7-6.5,23.9c0,0.3,0.1,0.6,0.3,0.7l4.6,3.1
                                c0.3,0.2,0.4,0.7,0.2,1c-0.1,0.1-0.2,0.3-0.4,0.3l-5.1,1.5c-0.3,0.1-0.5,0.3-0.5,0.6c-0.5,5-0.7,10.1-0.7,15.1c0,0.8,0,1.5,0,2.2
                                c0,0.3,0.2,0.5,0.4,0.7l2.4,1.1c0.4,0.2,0.5,0.6,0.4,1c0,0.1-0.1,0.2-0.2,0.3l-2.5,2.3c-0.2,0.2-0.3,0.4-0.2,0.6
                                c0.6,7.8,2.2,15.5,4.6,22.9c0.1,0.4,0.5,0.6,0.9,0.5l4.9-1c0.4-0.1,0.8,0.2,0.9,0.6c0,0.2,0,0.4-0.1,0.5l-3.7,5.9
                                c-0.1,0.2-0.1,0.5-0.1,0.7c16.6,38.4,57.3,61,96.9,63.3l2.1,0.1l0.9-0.1c39.7-2.3,80.3-24.9,96.9-63.3c0.1-0.2,0.1-0.5,0-0.7
                                l-3.7-6c-0.2-0.3-0.1-0.8,0.2-1c0.2-0.1,0.4-0.1,0.5-0.1l4.9,1c0.4,0.1,0.8-0.1,0.9-0.5c2.5-7.4,4-15.1,4.6-22.9
                                c0-0.2-0.1-0.5-0.2-0.6l-2.6-2.3c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.2-0.1,0.2-0.2l2.4-1.1c0.3-0.1,0.4-0.4,0.4-0.7
                                c0-0.7,0-1.5,0-2.2C356.3,383.8,356,378.8,355.5,373.7z"/>
                            <path fill={backGroundColor} d="M358.1,209.2c-0.8-6.6-2.4-13-4.9-19.1c-3-7.4-7-14.4-11.7-20.8l-0.1-9.6c0,0,6.6-7.7-6.1-14
                                c-4.1-2-12.5-5.2-21.7-3.6c-17.9-12.5-39.8-20.7-63.6-20.7c-23.8,0-45.7,8.2-63.6,20.7c-9.1-1.5-17.6,1.6-21.7,3.6
                                c-12.7,6.3-6.1,14-6.1,14l-0.1,9.6c-4.7,6.4-8.6,13.3-11.6,20.6c-3.4,8.1-5.2,16.7-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5
                                c0.3,2.3,0.6,4.6,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9c11.6,20.9,33.1,34.5,66.4,39.6l0,0l-1.6,5.5c-0.3,0-0.6,0.1-0.9,0.2
                                c-4.7,0.9-7.5,2.9-9.2,4.9c-10.4,1-31.8,5.9-34.4,28.3c-5.2,44.2-26.9,85.4-7.5,95.4c9,4.6,16.6-0.4,22.9-9.6
                                c0.5,12,1.9,46.2,3.1,61.4c0.3,3.7,0.6,7,1.1,10c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.8-0.2,34.8-0.1
                                c9.5-7.4,12.9-30.2,14.1-42.7c6.1,2.2,12.5,3.6,19,4h0.6c6.5-0.4,12.9-1.8,19-4c1.2,12.4,4.6,34.9,13.9,42.5c0,0,0,0.1,0,0.1
                                c0,0,19,6,34.8,0.2c0,0-1.3-15-6.5-19.8c-0.4-0.4-1.6-0.5-3.4-0.3c0.5-3,0.8-6.4,1.1-10c1.2-15.3,2.6-50,3.1-61.7
                                c6.3,9.4,14,14.5,23.1,9.9c19.4-10-2.3-51.2-7.5-95.4c-2.6-22.1-23.4-27.1-34-28.2l0,0c-2.8-2.6-6.2-4.3-9.9-4.9
                                c-0.3-0.1-0.6-0.1-0.9-0.2l-1.6-5.6c33.3-5,54.9-18.7,66.5-39.6c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.8-4.3,1-6.5
                                c0.3-2.1,0.5-4.2,0.6-6.3c0.2-3.1,0.3-6.3,0.3-9.6C358.4,213.3,358.3,211.2,358.1,209.2z"/>
                            <path opacity="0.4" fill="#EDEDED" enable-background="new    " d="M297.2,316.2c-0.2-0.4-3-7.4-13.2-9.3c-6.8-1.3-13.9,0.6-21,5.7
                                c-3.8,2.9-8.3,4.7-13,5.2c-4.7-0.5-9.2-2.3-13-5.2c-7.2-5.1-14.2-7-21-5.7c-10.1,2-11.6,9.1-11.9,9.5c-1,1.7-0.5,4,1.3,5
                                c1.7,1,3.8,0.5,4.9-1.1c0-0.1,0.1-4.9,7.1-6.3c4.8-0.9,10,0.6,15.4,4.5c6.3,4.5,12.1,6.3,17.2,6.5l0,0c5.1-0.3,10.9-2.1,17.2-6.5
                                c5.4-3.9,10.6-5.4,15.4-4.5c7,1.4,8.4,6,8.4,6.1c1.1,1.7,3.3,2.2,5,1.1S298.3,317.9,297.2,316.2L297.2,316.2L297.2,316.2z"/>
                            <path opacity="0.4" fill="#EDEDED" enable-background="new    " d="M250.3,462.3h-0.6c-28.7-1.8-58.2-22.8-61.8-57.9
                                c-0.3,3-0.4,6-0.4,9.1c0.1,39.4,31.6,63.2,62.2,65.1h0.6c30.6-1.9,62.1-25.7,62.2-65.1c0-3.1-0.1-6.1-0.4-9.1
                                C308.5,439.5,279,460.5,250.3,462.3z"/>
                            <path opacity="0.4" fill="#515151" enable-background="new    " d="M260.7,388.1c14.4-1.2,28.6-4.3,42.3-9.1
                                c0.5-0.2,0.8-0.7,0.6-1.3s-0.7-0.8-1.3-0.6l0,0c-16.4,5.8-33.6,9-51,9.4v-31.5c2.9,0,5.7-0.2,8.6-0.5c11.7-1.3,23.1-4.5,33.7-9.5
                                c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5c-13,6.1-27.1,9.5-41.4,9.8v-21.5c0.1-0.7-0.4-1.2-1.1-1.3s-1.2,0.4-1.3,1.1
                                c0,0.1,0,0.1,0,0.2v21.5c-5.2-0.1-10.4-0.5-15.6-1.5c-14.7-2.7-23.7-8.2-23.8-8.2c-0.5-0.3-1.1-0.1-1.3,0.3c0,0,0,0,0,0
                                c-0.3,0.5-0.1,1.1,0.3,1.4c0.4,0.2,9.4,5.7,24.4,8.5c5.3,1,10.6,1.5,16,1.5v31.5c-6.8,0-13.5-0.5-20.2-1.4
                                c-17.3-2.6-27.2-7.9-27.3-7.9c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.2,1.1,0.2,1.4c0.1,0.1,0.2,0.1,0.3,0.1
                                c0.4,0.2,10.2,5.5,27.8,8.1c6.7,1,13.5,1.5,20.3,1.5h0.3v33.2c-6.6-0.1-13.2-0.6-19.8-1.7c-19-3.2-31.4-9.6-31.5-9.6
                                c-0.5-0.2-1.1,0-1.3,0.4c-0.2,0.5,0,1.1,0.4,1.3c0.1,0.1,12.7,6.6,32,9.9c6.7,1.1,13.4,1.7,20.2,1.8v27c0.1,0.7,0.6,1.1,1.3,1.1
                                c0.6,0,1-0.5,1.1-1.1v-27c3.8,0,7.5-0.2,11.2-0.6c13.9-1.4,27.5-5.1,40.1-11c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5
                                c-15.8,7.4-33,11.3-50.5,11.4v-33.2C254.5,388.5,257.6,388.3,260.7,388.1z"/>
                            <path opacity="0.3" fill="#161616" enable-background="new    " d="M291.5,500.4c7.4-1.6,13.3-2.8,16.8-3.1c0.5-3,0.8-6.4,1.1-10
                                c1.4-18.6,3.3-65.6,3.3-65.6c0-1.6,0-3.6-0.1-5.7c-1.1,29.9-20.4,50.4-43.2,58.7c1.2,12.4,4.6,34.9,13.9,42.5
                                C284.7,506,291.5,500.4,291.5,500.4z"/>
                            <polygon fill="#161616" points="216.7,517.2 216.7,517.2 216.7,517.2 	"/>
                            <path opacity="0.3" fill="#161616" enable-background="new    " d="M187.6,415.9c0,2.1-0.1,4.1-0.1,5.7c0,0,1.8,47,3.2,65.6
                                c0.3,3.7,0.6,7,1.1,10c5.6,0.8,11.1,1.8,16.6,3.1c0,0,6.6,5.4,8.1,16.3l0,0c0,0.2,0.1,0.3,0.1,0.5c9.5-7.4,12.9-30.2,14.1-42.6
                                C208,466.3,188.6,445.8,187.6,415.9z"/>
                            
                                <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="199.3907" y1="-58.1129" x2="198.1723" y2="-34.9455" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0" stop-color="#C8C8C8" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#C8C8C8" stop-opacity="0.2"/>
                            </linearGradient>
                            <path fill="url(#SVGID_31_)" d="M216.6,516.7L216.6,516.7c-1.6-10.8-8.1-16.3-8.1-16.3c-5.5-1.3-11-2.4-16.6-3.1
                                c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.3,0,34.8-0.2C216.6,517.1,216.6,516.9,216.6,516.7z"/>
                            
                                <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="3064.0046" y1="-102.2621" x2="3062.7505" y2="-78.3785" gradientTransform="matrix(-1 -3.000000e-02 3.000000e-02 -1 3367.3318 511.52)">
                                <stop  offset="0" stop-color="#C8C8C8" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#C8C8C8" stop-opacity="0.2"/>
                            </linearGradient>
                            <path fill="url(#SVGID_32_)" d="M318,517.4c0,0-1.3-15-6.5-19.8c-1.2-1.1-8.9,0.3-20.1,2.8c0,0-6.8,5.6-8.2,16.8
                                C283.2,517.2,302.3,523.3,318,517.4z"/>
                            <path fill="#2D4728" d="M182.2,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                c0.3,1.4-0.6,2.9-2,3.2C182.6,517.5,182.4,517.5,182.2,517.5z"/>
                            <path fill="#395E32" d="M188,520h-4.8c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.8-1,3.3,0.3l1.1,2.8c0.4,1.4-0.5,2.9-1.9,3.3
                                C188.3,519.9,188.1,520,188,520z"/>
                            <path fill="#2D4728" d="M317.9,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                c-0.3,1.4,0.6,2.9,2,3.2C317.5,517.5,317.7,517.5,317.9,517.5z"/>
                            <path fill="#395E32" d="M312.1,520h4.8c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.1-1.1-2.8-1-3.3,0.3l-1.1,2.8c-0.4,1.4,0.5,2.9,1.9,3.3
                                C311.7,519.9,311.9,520,312.1,520z"/>
                            <path opacity="0.3" fill="#161616" enable-background="new    " d="M187.7,425.9c-0.1-2.7-0.2-4.3-0.2-4.3c0-2.2,0-4.4,0-6.6
                                c0-0.5,0-1,0-1.6c0-2.2,0.1-4.3,0.2-6.4c1.7-57.2,12.9-84,16.8-91.7c0.5-1.3,1.3-2.6,2.3-3.7c-10.4,1-31.8,5.9-34.4,28.3
                                c-5.2,44.2-26.9,85.4-7.5,95.4C173.8,440,181.4,435.1,187.7,425.9z"/>
                            <path opacity="0.3" fill="#161616" enable-background="new    " d="M327.9,340.1c-2.6-22.1-23.4-27.1-34-28.2
                                c1.3,1.3,2.4,2.8,3.2,4.4c0.6,1,0.8,2.3,0.3,3.4c4.8,11.3,13.4,38,14.8,87.5c0.1,2.1,0.2,4.2,0.2,6.4c0,0.5,0,1,0,1.6
                                c0,2.2,0,4.3,0,6.6c0,0-0.1,1.5-0.2,4c6.3,9.4,14,14.5,23.1,9.9C354.8,425.5,333.1,384.2,327.9,340.1z"/>
                            <path fill="#395E32" d="M181,232.7c0,0-11.2-3.6-20.3,0.1C169.7,235.3,181,232.7,181,232.7z"/>
                            <path fill="#56002F" d="M211.9,231.9c1,12.1,20.9,25.7,42.1,23.5c27.8-2.9,34.3-43.2,34.3-43.2s-14.6,9.4-38.2,9
                                C226.4,220.7,210.3,212.2,211.9,231.9z"/>
                            <path fill="#FFFFFF" d="M262,235.1c7.1-1.6,13.9-3.8,14.9-4.9c2.9-2.9,2.8-9.5,2.4-13.9c-9.4,3.4-19.3,5.1-29.3,4.8
                                c-23.6-0.4-39.7-9-38.1,10.7c0.1,1,0.3,1.9,0.6,2.8c2,0.4,4.9,0.7,8.3,1c3.5-0.7,6.5-1.2,6.5-1.2S248,235.7,262,235.1z"/>
                            <path fill="#FFFFFF" d="M269,234.6c-2,0.2-4.3,0.4-6.9,0.5c-14,0.6-34.7-0.5-34.7-0.5s-2.9,0.5-6.5,1.2c-2.4,0.5-5,1-7.2,1.6
                                c5.4,10.3,22.4,19.9,40.3,18c11.8-1.2,19.7-9.2,25-17.9C278.7,235.8,276.8,233.7,269,234.6z"/>
                            <path fill="#E0DEDF" d="M279.4,216.4c-9.4,3.4-19.3,5.1-29.3,4.8c-23.6-0.4-39.7-9-38.1,10.7c0.1,1,0.3,1.9,0.6,2.8
                                c2.1,0.4,5.1,0.7,8.6,1.1c-0.5-2-1.7-8.8,5.6-9c26.2-0.7,51.1-6.5,52.8-8.3C279.5,217.7,279.4,217,279.4,216.4z"/>
                            <path fill="#E0DEDF" d="M224.2,240.9c-0.3-1.7-0.2-3.5,0.4-5.1c-2.9,0.5-7.7,0.7-11,1.6c2.7,5.2,8.5,10.3,15.8,13.7
                                C226.9,248.2,225.1,244.7,224.2,240.9z"/>
                            <polygon fill="#56002F" points="253.5,234.9 252.1,229.4 250.8,235.2 	"/>
                            <path fill="#395E32" d="M259,189.7c0.2-1.9-1.2-3.7-3.1-3.9c-0.3,0-0.6,0-0.9,0C249.4,189.3,256.5,190.3,259,189.7z"/>
                            <path fill="#395E32" d="M245.1,185.9c-1.9-0.3-3.7,1-4,2.9c0,0.3-0.1,0.6,0,0.9C243.5,190.3,250.7,189.3,245.1,185.9z"/>
                            
                                <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="183.7935" y1="259.4072" x2="185.4239" y2="319.2775" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0.46" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_33_)" d="M184.5,152.8c-12.2,2.4-25.8,6.8-25.8,6.8l-0.4,45.3c0,0,31.7-1.5,47,6.3
                                c2.4-27.5,6.1-54.8,6.1-54.8C197,153.7,191.3,151.4,184.5,152.8z"/>
                            <path fill="#395E32" d="M186.6,142c-9.1-1.6-17.7,1.6-21.8,3.6c-12.7,6.3-6.1,14-6.1,14s13.6-4.4,25.8-6.8c6.9-1.4,12.5,1,26.9,3.5
                                C211.3,156.3,198.6,144.1,186.6,142z"/>
                            
                                <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="316.4182" y1="262.0838" x2="314.51" y2="317.878" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                <stop  offset="0.46" stop-color="#464646" stop-opacity="0"/>
                                <stop  offset="1" stop-color="#323232" stop-opacity="0.3"/>
                            </linearGradient>
                            <path fill="url(#SVGID_34_)" d="M315.5,152.8c-6.9-1.4-12.5,1-26.9,3.5c0,0,3.7,27.3,6.1,54.8c15.3-7.7,47-6.3,47-6.3l-0.4-45.3
                                C341.3,159.5,327.8,155.2,315.5,152.8z"/>
                            <path fill="#395E32" d="M335.3,145.6c-4.1-2-12.6-5.2-21.8-3.6c-12,2.1-24.7,14.3-24.7,14.3c14.3-2.6,20-4.9,26.9-3.5
                                c12.2,2.4,25.8,6.8,25.8,6.8S348,151.9,335.3,145.6z"/>
                            <path fill="#FFFFFF" d="M180.4,179.6c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6s23.6-10.6,23.6-23.6l0,0
                                C204,190.1,193.4,179.6,180.4,179.6z"/>
                            <path fill="#FFFFFF" d="M319.7,179.6c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6c13,0,23.6-10.6,23.6-23.6
                                C343.3,190.1,332.7,179.6,319.7,179.6C319.7,179.6,319.7,179.6,319.7,179.6z"/>
                            <path fill="#395E32" d="M319,232.7c0,0,11.2-3.6,20.3,0.1C330.4,235.3,319,232.7,319,232.7z"/>
                            <path fill="#21323F" d="M319.7,204.8c-2.6,0-4.8-2.2-4.8-4.8l0,0c0-2.6,2-4.6,4.6-4.8c-5.3-4.3-13-3.5-17.3,1.8
                                c-4.3,5.3-3.5,13,1.8,17.3c5.3,4.3,13,3.5,17.3-1.8c1.8-2.2,2.7-4.9,2.7-7.7H319.7z"/>
                            <path fill="#21323F" d="M188.3,192.5c-2.8,0-5.6,1-7.7,2.7c2.5,0.1,4.5,2.2,4.6,4.8l0,0c0,2.6-2.1,4.8-4.8,4.8H176
                                c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3C200.7,198,195.1,192.5,188.3,192.5C188.3,192.5,188.3,192.5,188.3,192.5z"/>
                        </svg>
   
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520"  height={this.state.height}>
                                <path fill="#314C2C" d="M355.5,373.7c0-0.3-0.2-0.6-0.5-0.6l-5.1-1.5c-0.4-0.1-0.6-0.5-0.5-0.9c0-0.2,0.2-0.3,0.3-0.4l4.6-3.1
                                        c0.2-0.2,0.4-0.4,0.3-0.7c-1.3-8.2-3.4-16.2-6.5-23.9c-0.1-0.3-0.4-0.5-0.7-0.5l-4.6,0.1c-0.4,0-0.7-0.3-0.8-0.7
                                        c0-0.2,0.1-0.3,0.2-0.5l3.1-3.8c0.2-0.2,0.2-0.5,0.1-0.8c-4.6-9.4-10.9-18-18.6-25.1c-0.2-0.2-0.5-0.3-0.8-0.1l-2.5,1.1
                                        c-0.4,0.2-0.8,0-1-0.4c-0.1-0.1-0.1-0.3-0.1-0.4l0.4-3c0-0.3-0.1-0.5-0.3-0.7c-16.6-13.4-39.9-21.8-71.1-22.8v0h-1.1v0H250h-0.4v0
                                        h-1.1v0c-31.2,1-54.5,9.4-71.1,22.8c-0.2,0.2-0.3,0.4-0.3,0.7l0.4,3c0.1,0.4-0.2,0.8-0.6,0.8c-0.1,0-0.3,0-0.4-0.1l-2.5-1
                                        c-0.3-0.1-0.6-0.1-0.8,0.1c-7.7,7.1-14,15.6-18.6,25.1c-0.1,0.3-0.1,0.6,0.1,0.8l3.1,3.8c0.3,0.3,0.2,0.8-0.1,1
                                        c-0.1,0.1-0.3,0.2-0.5,0.2l-4.6-0.1c-0.3,0-0.6,0.2-0.7,0.5c-3.1,7.7-5.2,15.7-6.5,23.9c0,0.3,0.1,0.6,0.3,0.7l4.6,3.1
                                        c0.3,0.2,0.4,0.7,0.2,1c-0.1,0.1-0.2,0.3-0.4,0.3l-5.1,1.5c-0.3,0.1-0.5,0.3-0.5,0.6c-0.5,5-0.7,10.1-0.7,15.1c0,0.8,0,1.5,0,2.2
                                        c0,0.3,0.2,0.5,0.4,0.7l2.4,1.1c0.4,0.2,0.5,0.6,0.4,1c0,0.1-0.1,0.2-0.2,0.3l-2.5,2.3c-0.2,0.2-0.3,0.4-0.2,0.6
                                        c0.6,7.8,2.2,15.5,4.6,22.9c0.1,0.4,0.5,0.6,0.9,0.5l4.9-1c0.4-0.1,0.8,0.2,0.9,0.6c0,0.2,0,0.4-0.1,0.5l-3.7,5.9
                                        c-0.1,0.2-0.1,0.5-0.1,0.7c16.6,38.4,57.3,61,96.9,63.3l2.1,0.1l0.9-0.1c39.7-2.3,80.3-24.9,96.9-63.3c0.1-0.2,0.1-0.5,0-0.7
                                        l-3.7-6c-0.2-0.3-0.1-0.8,0.2-1c0.2-0.1,0.4-0.1,0.5-0.1l4.9,1c0.4,0.1,0.8-0.1,0.9-0.5c2.5-7.4,4-15.1,4.6-22.9
                                        c0-0.2-0.1-0.5-0.2-0.6l-2.6-2.3c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.2-0.1,0.2-0.2l2.4-1.1c0.3-0.1,0.4-0.4,0.4-0.7
                                        c0-0.7,0-1.5,0-2.2C356.3,383.8,356,378.8,355.5,373.7z"/>
                                    <path fill={backGroundColor} d="M358.1,209.2c-0.8-6.6-2.4-13-4.9-19.1c-3-7.4-7-14.4-11.7-20.8l-0.1-9.6c0,0,6.6-7.7-6.1-14
                                        c-4.1-2-12.5-5.2-21.7-3.6c-17.9-12.5-39.8-20.7-63.6-20.7c-23.8,0-45.7,8.2-63.6,20.7c-9.1-1.5-17.6,1.6-21.7,3.6
                                        c-12.7,6.3-6.1,14-6.1,14l-0.1,9.6c-4.7,6.4-8.6,13.3-11.6,20.6c-3.4,8.1-5.2,16.7-5.4,25.5c0,5.2,0.3,10.3,0.9,15.5
                                        c0.3,2.3,0.6,4.6,1.1,6.9c1.5,8.4,4.4,16.4,8.5,23.9c11.6,20.9,33.1,34.5,66.4,39.6l-1.6,5.5c-0.3,0-0.6,0.1-0.9,0.2
                                        c-3.4,0.5-6.5,2.1-8.9,4.5c-4,0.5-7.8,1.8-11.3,3.8c-38.5,21.7-84.5,28.5-81.1,50c4,25.8,55.2,6.3,76.9-3.3
                                        c-2.2,15-3.5,30.1-3.9,45.2c-0.1,2.1-0.2,4.2-0.2,6.4c0,0.5,0,1,0,1.6c0,2.2,0,4.3,0,6.6c0,0,1.8,47,3.2,65.6c0.3,3.7,0.6,7,1.1,10
                                        c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.8-0.2,34.8-0.1c9.5-7.4,12.9-30.2,14.1-42.7
                                        c6.1,2.2,12.5,3.6,19,4h0.6c6.5-0.4,12.9-1.8,19-4c1.2,12.4,4.6,34.9,13.9,42.5c0,0,0,0.1,0,0.1c0,0,19,6,34.8,0.2
                                        c0,0-1.3-15-6.5-19.8c-0.4-0.4-1.6-0.5-3.4-0.3c0.5-3,0.8-6.4,1.1-10c1.2-15.3,2.6-50,3.1-61.7c6.3,9.4,14,14.5,23.1,9.9
                                        c19.4-10-2.3-51.2-7.5-95.4c-2.6-22.1-23.4-27.1-34-28.2c-2.8-2.6-6.2-4.3-9.9-4.9c-0.3-0.1-0.6-0.1-0.9-0.2l-1.7-5.6
                                        c33.3-5,54.9-18.7,66.5-39.6c4.1-7.5,6.9-15.5,8.4-23.9c0.4-2.1,0.8-4.3,1-6.5c0.3-2.1,0.5-4.2,0.6-6.3c0.2-3.1,0.3-6.3,0.3-9.6
                                        C358.4,213.3,358.3,211.2,358.1,209.2z"/>
                                    <path opacity="0.4" fill="#EDEDED" enable-background="new    " d="M297.2,316.2c-0.2-0.4-3-7.4-13.2-9.3c-6.8-1.3-13.9,0.6-21,5.7
                                        c-3.8,2.9-8.3,4.7-13,5.2c-4.7-0.5-9.2-2.3-13-5.2c-7.2-5.1-14.2-7-21-5.7c-10.1,2-11.6,9.1-11.9,9.5c-1,1.7-0.5,4,1.3,5
                                        c1.7,1,3.8,0.5,4.9-1.1c0-0.1,0.1-4.9,7.1-6.3c4.8-0.9,10,0.6,15.4,4.5c6.3,4.5,12.1,6.3,17.2,6.5l0,0c5.1-0.3,10.9-2.1,17.2-6.5
                                        c5.4-3.9,10.6-5.4,15.4-4.5c7,1.4,8.4,6,8.4,6.1c1.1,1.7,3.3,2.2,5,1.1S298.3,317.9,297.2,316.2L297.2,316.2L297.2,316.2z"/>
                                    <path opacity="0.4" fill="#EDEDED" enable-background="new    " d="M250.3,462.3h-0.6c-28.7-1.8-58.2-22.8-61.8-57.9
                                        c-0.3,3-0.4,6-0.4,9.1c0.1,39.4,31.6,63.2,62.2,65.1h0.6c30.6-1.9,62.1-25.7,62.2-65.1c0-3.1-0.1-6.1-0.4-9.1
                                        C308.5,439.5,279,460.5,250.3,462.3z"/>
                                    <path opacity="0.4" fill="#515151" enable-background="new    " d="M260.7,388.1c14.4-1.2,28.6-4.3,42.3-9.1
                                        c0.5-0.2,0.8-0.7,0.6-1.3s-0.7-0.8-1.3-0.6l0,0c-16.4,5.8-33.6,9-51,9.4v-31.5c2.9,0,5.7-0.2,8.6-0.5c11.7-1.3,23.1-4.5,33.7-9.5
                                        c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5c-13,6.1-27.1,9.5-41.4,9.8v-21.5c0.1-0.7-0.4-1.2-1.1-1.3s-1.2,0.4-1.3,1.1
                                        c0,0.1,0,0.1,0,0.2v21.5c-5.2-0.1-10.4-0.5-15.6-1.5c-14.7-2.7-23.7-8.2-23.8-8.2c-0.5-0.3-1.1-0.1-1.3,0.3c0,0,0,0,0,0
                                        c-0.3,0.5-0.1,1.1,0.3,1.4c0.4,0.2,9.4,5.7,24.4,8.5c5.3,1,10.6,1.5,16,1.5v31.5c-6.8,0-13.5-0.5-20.2-1.4
                                        c-17.3-2.6-27.2-7.9-27.3-7.9c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.2,1.1,0.2,1.4c0.1,0.1,0.2,0.1,0.3,0.1
                                        c0.4,0.2,10.2,5.5,27.8,8.1c6.7,1,13.5,1.5,20.3,1.5h0.3v33.2c-6.6-0.1-13.2-0.6-19.8-1.7c-19-3.2-31.4-9.6-31.5-9.6
                                        c-0.5-0.2-1.1,0-1.3,0.4c-0.2,0.5,0,1.1,0.4,1.3c0.1,0.1,12.7,6.6,32,9.9c6.7,1.1,13.4,1.7,20.2,1.8v27c0.1,0.7,0.6,1.1,1.3,1.1
                                        c0.6,0,1-0.5,1.1-1.1v-27c3.8,0,7.5-0.2,11.2-0.6c13.9-1.4,27.5-5.1,40.1-11c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5
                                        c-15.8,7.4-33,11.3-50.5,11.4v-33.2C254.5,388.5,257.6,388.3,260.7,388.1z"/>
                                    <path opacity="0.3" fill="#161616" enable-background="new    " d="M291.5,500.4c7.4-1.6,13.3-2.8,16.8-3.1c0.5-3,0.8-6.4,1.1-10
                                        c1.4-18.6,3.3-65.6,3.3-65.6c0-1.6,0-3.6-0.1-5.7c-1.1,29.9-20.4,50.4-43.2,58.7c1.2,12.4,4.6,34.9,13.9,42.5
                                        C284.7,506,291.5,500.4,291.5,500.4z"/>
                                    <polygon fill="#161616" points="216.7,517.2 216.7,517.2 216.7,517.2 	"/>
                                    <path opacity="0.3" fill="#161616" enable-background="new    " d="M187.6,415.9c0,2.1-0.1,4.1-0.1,5.7c0,0,1.8,47,3.2,65.6
                                        c0.3,3.7,0.6,7,1.1,10c5.6,0.8,11.1,1.8,16.6,3.1c0,0,6.6,5.4,8.1,16.3l0,0c0,0.2,0.1,0.3,0.1,0.5c9.5-7.4,12.9-30.2,14.1-42.6
                                        C208,466.3,188.6,445.8,187.6,415.9z"/>
                                    
                                        <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="199.3907" y1="-58.1129" x2="198.1723" y2="-34.9455" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                        <stop  offset="0"   stop-color="#C8C8C8" stop-opacity="0"/>
                                        <stop  offset="1"   stop-color="#C8C8C8" stop-opacity="0.2"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_27_)" d="M216.6,516.7L216.6,516.7c-1.6-10.8-8.1-16.3-8.1-16.3c-5.5-1.3-11-2.4-16.6-3.1
                                        c-1.9-0.2-3.1-0.1-3.5,0.3c-5.2,4.8-6.5,19.8-6.5,19.8c15.6,5.8,34.3,0,34.8-0.2C216.6,517.1,216.6,516.9,216.6,516.7z"/>
                                    
                                        <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="3064.0046" y1="-102.2621" x2="3062.7505" y2="-78.3785" gradientTransform="matrix(-1 -3.000000e-02 3.000000e-02 -1 3367.3318 511.52)">
                                        <stop  offset="0"   stop-color="#C8C8C8" stop-opacity="0"/>
                                        <stop  offset="1"   stop-color="#C8C8C8" stop-opacity="0.2"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_28_)" d="M318,517.4c0,0-1.3-15-6.5-19.8c-1.2-1.1-8.9,0.3-20.1,2.8c0,0-6.8,5.6-8.2,16.8
                                        C283.2,517.2,302.3,523.3,318,517.4z"/>
                                    <path fill="#2D4728" d="M182.2,517.5l-4.8-0.1c-1.4,0-1.8-1.8-0.6-2.9l3.7-3.5c1.2-1.1,2.9-0.9,3.3,0.4l1,2.8
                                        c0.3,1.4-0.6,2.9-2,3.2C182.6,517.5,182.4,517.5,182.2,517.5z"/>
                                    <path fill="#395E32" d="M188,520h-4.8c-1.4,0-1.8-1.7-0.7-2.8l3.6-3.6c1.2-1.1,2.8-1,3.3,0.3l1.1,2.8c0.4,1.4-0.5,2.9-1.9,3.3
                                        C188.3,519.9,188.1,520,188,520z"/>
                                    <path fill="#2D4728" d="M317.9,517.5l4.8-0.1c1.4,0,1.8-1.8,0.6-2.9l-3.7-3.5c-1.2-1.1-2.9-0.9-3.3,0.4l-1,2.8
                                        c-0.3,1.4,0.6,2.9,2,3.2C317.5,517.5,317.7,517.5,317.9,517.5z"/>
                                    <path fill="#395E32" d="M312.1,520h4.8c1.4,0,1.8-1.7,0.7-2.8l-3.6-3.6c-1.1-1.1-2.8-1-3.3,0.3l-1.1,2.8c-0.4,1.4,0.5,2.9,1.9,3.3
                                        C311.7,519.9,311.9,520,312.1,520z"/>
                                    <path opacity="0.3" fill="#161616" enable-background="new    " d="M327.9,340.1c-2.6-22.1-23.4-27.1-34-28.2
                                        c1.3,1.3,2.4,2.8,3.2,4.4c0.6,1,0.8,2.3,0.3,3.4c4.8,11.3,13.4,38,14.8,87.5c0.1,2.1,0.2,4.2,0.2,6.4c0,0.5,0,1,0,1.6
                                        c0,2.2,0,4.3,0,6.6c0,0-0.1,1.5-0.2,4c6.3,9.4,14,14.5,23.1,9.9C354.8,425.5,333.1,384.2,327.9,340.1z"/>
                                    <path opacity="0.3" fill="#161616" enable-background="new    " d="M204.5,315.4c0.6-1.5,1.5-2.9,2.6-4c-4,0.5-7.8,1.8-11.3,3.8
                                        c-38.6,21.8-84.6,28.5-81.2,50c4,25.8,55.2,6.3,76.9-3.3C195.7,335,201.8,320.7,204.5,315.4z"/>
                                    <path fill="#395E32" d="M181,232.7c0,0-11.2-3.6-20.3,0.1C169.7,235.3,181,232.7,181,232.7z"/>
                                    <path fill="#395E32" d="M259,189.7c0.2-1.9-1.2-3.7-3.1-3.9c-0.3,0-0.6,0-0.9,0C249.4,189.3,256.5,190.3,259,189.7z"/>
                                    <path fill="#395E32" d="M245.1,185.9c-1.9-0.3-3.7,1-4,2.9c0,0.3-0.1,0.6,0,0.9C243.5,190.3,250.7,189.3,245.1,185.9z"/>
                                    
                                        <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="183.7935" y1="259.4072" x2="185.4239" y2="319.2775" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                        <stop  offset="0.46"   stop-color="#464646" stop-opacity="0"/>
                                        <stop  offset="1"   stop-color="#323232" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_29_)" d="M184.5,152.8c-12.2,2.4-25.8,6.8-25.8,6.8l-0.4,45.3c0,0,31.7-1.5,47,6.3
                                        c2.4-27.5,6.1-54.8,6.1-54.8C197,153.7,191.3,151.4,184.5,152.8z"/>
                                    <path fill="#395E32" d="M186.6,142c-9.1-1.6-17.7,1.6-21.8,3.6c-12.7,6.3-6.1,14-6.1,14s13.6-4.4,25.8-6.8c6.9-1.4,12.5,1,26.9,3.5
                                        C211.3,156.3,198.6,144.1,186.6,142z"/>
                                    
                                        <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="316.4182" y1="262.0838" x2="314.51" y2="317.878" gradientTransform="matrix(1 0 0 -1 0 461.88)">
                                        <stop  offset="0.46"   stop-color="#464646" stop-opacity="0"/>
                                        <stop  offset="1"   stop-color="#323232" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <path fill="url(#SVGID_30_)" d="M315.5,152.8c-6.9-1.4-12.5,1-26.9,3.5c0,0,3.7,27.3,6.1,54.8c15.3-7.7,47-6.3,47-6.3l-0.4-45.3
                                        C341.3,159.5,327.8,155.2,315.5,152.8z"/>
                                    <path fill="#395E32" d="M335.3,145.6c-4.1-2-12.6-5.2-21.8-3.6c-12,2.1-24.7,14.3-24.7,14.3c14.3-2.6,20-4.9,26.9-3.5
                                        c12.2,2.4,25.8,6.8,25.8,6.8S348,151.9,335.3,145.6z"/>
                                    <path fill="#395E32" d="M319,232.7c0,0,11.2-3.6,20.3,0.1C330.4,235.3,319,232.7,319,232.7z"/>
                                    <path fill="#56002F" d="M211.9,231.9c1,12.1,20.9,25.7,42.1,23.5c27.8-2.9,34.3-43.2,34.3-43.2s-14.6,9.4-38.2,9
                                        C226.4,220.7,210.3,212.2,211.9,231.9z"/>
                                    <path fill="#FFFFFF" d="M262,235.1c7.1-1.6,13.9-3.8,14.9-4.9c2.9-2.9,2.8-9.5,2.4-13.9c-9.4,3.4-19.3,5.1-29.3,4.8
                                        c-23.6-0.4-39.7-9-38.1,10.7c0.1,1,0.3,1.9,0.6,2.8c2,0.4,4.9,0.7,8.3,1c3.5-0.7,6.5-1.2,6.5-1.2S248,235.7,262,235.1z"/>
                                    <path fill="#FFFFFF" d="M269,234.6c-2,0.2-4.3,0.4-6.9,0.5c-14,0.6-34.7-0.5-34.7-0.5s-2.9,0.5-6.5,1.2c-2.4,0.5-5,1-7.2,1.6
                                        c5.4,10.3,22.4,19.9,40.3,18c11.8-1.2,19.7-9.2,25-17.9C278.7,235.8,276.8,233.7,269,234.6z"/>
                                    <path fill="#E0DEDF" d="M279.4,216.4c-9.4,3.4-19.3,5.1-29.3,4.8c-23.6-0.4-39.7-9-38.1,10.7c0.1,1,0.3,1.9,0.6,2.8
                                        c2.1,0.4,5.1,0.7,8.6,1.1c-0.5-2-1.7-8.8,5.6-9c26.2-0.7,51.1-6.5,52.8-8.3C279.5,217.7,279.4,217,279.4,216.4z"/>
                                    <path fill="#E0DEDF" d="M224.2,240.9c-0.3-1.7-0.2-3.5,0.4-5.1c-2.9,0.5-7.7,0.7-11,1.6c2.7,5.2,8.5,10.3,15.8,13.7
                                        C226.9,248.2,225.1,244.7,224.2,240.9z"/>
                                    <polygon fill="#56002F" points="253.5,234.9 252.1,229.4 250.8,235.2 	"/>
                                    <path fill="#FFFFFF" d="M180.4,179.6c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6s23.6-10.6,23.6-23.6l0,0
                                        C204,190.1,193.4,179.6,180.4,179.6z"/>
                                    <path fill="#FFFFFF" d="M319.7,179.6c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6c13,0,23.6-10.6,23.6-23.6
                                        C343.3,190.1,332.7,179.6,319.7,179.6C319.7,179.6,319.7,179.6,319.7,179.6z"/>
                                    <path fill="#21323F" d="M319.7,204.8c-2.6,0-4.8-2.2-4.8-4.8l0,0c0-2.6,2-4.6,4.6-4.8c-5.3-4.3-13-3.5-17.3,1.8
                                        c-4.3,5.3-3.5,13,1.8,17.3c5.3,4.3,13,3.5,17.3-1.8c1.8-2.2,2.7-4.9,2.7-7.7H319.7z"/>
                                    <path fill="#21323F" d="M188.3,192.5c-2.8,0-5.6,1-7.7,2.7c2.5,0.1,4.5,2.2,4.6,4.8l0,0c0,2.6-2.1,4.8-4.8,4.8H176
                                        c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3C200.7,198,195.1,192.5,188.3,192.5C188.3,192.5,188.3,192.5,188.3,192.5z"/>
                                </svg>
       
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
