import React from "react";
import "./avatarComponent.scss";
import { Box } from "@material-ui/core";
import ButtonRound from "../buttonRound/buttonRound";
import ColorPicker from "../colorPicker/colorPicker";
import { AvatarInfo, AvatarAccessory } from "../../../enums/AvatarInfo";
import { characterName, backGroundName, menuTypes } from "../../../enums/index";
import ResizableContantComponent from './resizableContantComponent'
import path from 'path';

const close = require("../../../assets/images/close_icon.svg");

export interface Props {
  avatarDetails: AvatarInfo;
  acessory: AvatarAccessory;
  OnDrag: (deltaX: number, deltaY: number) => void;
  OnResize: (style: any, isShiftKey: any, type: any) => void;
  menu:any;
}

export interface State {
  avatarDetailState: AvatarInfo;
}

class AvatarAccessoriesComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }


  async componentWillMount() {
    this.setState({ avatarDetailState: this.props.avatarDetails });
  }

  render() {
    const rotateAngle: number = 0;
    const { width, height, top, left, name } = this.props.acessory
    const { OnDrag, OnResize, menu } = this.props;
    console.log(path.resolve(__dirname, 'src/assets/images/avatar'+ `/${menu}/${name}.png`), __dirname, path.resolve('../../../assets/images/avatar'))
    return (

      <>{name ?
        <ResizableContantComponent
          avatarDetails={this.props.avatarDetails}
          accessory={this.props.acessory}
          // aspectRatio={false}
          // minWidth={10}
          // minHeight={10}


          // onRotateStart={this.handleRotateStart}
          // onRotate={this.handleRotate}
          // onRotateEnd={this.handleRotateEnd}
          // onResizeStart={this.handleResizeStart}
          OnResize={OnResize}
          // onResizeEnd={this.handleUp}
          // onDragStart={this.handleDragStart}
          OnDrag={OnDrag}
        // onDragEnd={this.handleDragEnd}

        >

          <img
            style={{ width: "100%", height:"100%"}} 
            // src={require( path.resolve(__dirname, 'src/assets/images/avatar'+ `/${menu}/${name}.png`))}
            src={require( '../../../assets/images/avatar'+ `/${menu}/${name}.png`)}
          ></img>

        </ResizableContantComponent> : null}
      </>
    );
  }
}

export default AvatarAccessoriesComponent;
