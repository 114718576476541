import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const adminMemberService = {
    getAll,
    getById,
    getAdminNewsFeed,
    getNiecesNephewsAchievements,
    getChildrenAdminMember,
    getMyNiecesNephews,
    makeTeamMemberAnAdmin,
};


function getAll() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = { method: 'GET' , headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getAdminMemberNewsFeed`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
   
}
function getById(id) {
    const requestOptions = { method: 'GET' , headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getAdminMemberDetails/${id}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
  
}

function getAdminNewsFeed(adminId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(adminId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getAdminMemberNewsFeed/${adminId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getNiecesNephewsAchievements(adminId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(adminId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getNiecesNephewsAchievementsAdminMember/${adminId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getChildrenAdminMember(adminId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(adminId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getChildrenAdminMember/${adminId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}

function getMyNiecesNephews(adminId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(adminId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/getMyNiecesNephews/${adminId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        })
    })
}

function makeTeamMemberAnAdmin(value:any, userId:number){
    const requestOptions = { 
        method: 'PATCH', 
        headers:{ Authorization:authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(value) };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/adminMembers/makeTeamMemberAnAdmin/${userId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}