import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const reactionsService = {
    getReactionList,
    addReaction,
    removeReaction
};

function getReactionList()
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/reactions/getReactionList/`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function addReaction(type:string, typeId:number, reactionById: number, reactionToId:number, reactionId:number)
{
    const requestOptions = { method: 'POST', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/reactions/addReaction/${type}/${typeId}/${reactionById}/${reactionToId}/${reactionId}`,requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function removeReaction(type:string, typeId:number, reactionById: number, reactionToId:number, reactionId:number)
{
    const requestOptions = { method: 'DELETE', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/reactions/removeReaction/${type}/${typeId}/${reactionById}/${reactionToId}/${reactionId}`,requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}