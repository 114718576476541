
import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import ButtonComponent from './buttonComponent';
import CustomToolbar from "../components/customToolbar";
import { TeamMemberDialog, teamMemberDialogProps} from "./popups/teamMember/addTeamMemberDialog"
import { childrenService } from '../../../services';
import { Notifier, notifierProps } from '../../components/notifier/notifier';


export interface  Props {
    // className:string;
    teamMemberListData:any;
    history:any;
    userId:number;
    refresh:() => void;
    // isChildrenStory:boolean;
}
 
export interface  State {
    isLoading:boolean;
    teamMemberData:any;
    addTeammemberDailogData:any;
    snackBarStatus:any;
}
class TeamMemberListComponent  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);

        const stateObject = {
            msg: "message",
            status: false,
            date: new Date(),
            variant: "error"
        }
        this.state={
            isLoading:false,
            snackBarStatus: stateObject ,
            addTeammemberDailogData:"",
            teamMemberData:null,
        };
        
    }
    componentWillMount() {
        this.setAddTeamMemberDialog(false);
        let teamMembers=  this.props.teamMemberListData.teamMembers
         this.setState({teamMemberData:teamMembers})
    }


 
    setNotification(status:boolean, message:string, varient:string){
            const stateObject = {
                msg: message,
                status: status,
                date: new Date(),
                variant: varient
            }
            this.setState({ snackBarStatus: stateObject });
        }
    async setAddTeamMemberDialog(isOpen:boolean)
    {
          const stateObject={
              openAction:isOpen,
              childData: this.props.teamMemberListData,
              
          }
          this.setState({addTeammemberDailogData:stateObject} );
        
    }

    openAddTeamMemberDialog(){
        this.setAddTeamMemberDialog(true)
    }

    closeAddTeamMemberDialog=()=>{
        console.log("TEST ----");
        
        this.setAddTeamMemberDialog(false)
        this.props.refresh();
    }

    refreshTeamMemberList(userId){
         childrenService.getMyPeople(userId).then((data:any) =>{
             if(data.success){
                 let teamMembers=  data.data;
                 this.setState({teamMemberData: data.data})
             }else{
                 this.setNotification(true,"There is something wrong, Please try again after some time","error")
             }
         }, error => {
             this.setNotification(true,"There is something wrong, Please try again after some time","error")
             //ERROR
             console.log(error.message);
         });
    }

    onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }){
      
        console.log("----RowClick");
        console.log("rowData: ", rowData);
        console.log("rowMeta: ", rowMeta);
    }

    render(){
        console.log("TEST PROPS",this.props);
        const columns = [
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },  {
                name:"uniqueId",
                label:"Unique ID",
                options:{
                    sort:true
                }
            },{
                name:"fullName",
                label: "Full Name",
                options:{
                    sort:true
                }
            },
            {
                name:"mobileNumber",
                label:"Mobile Number",
                options:{
                    sort:true,
                }
            },{
                name:"categoryName",
                label:"Type",
                options:{
                    sort:true,
                }
            }
           
        ];
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            selectableRowsHeader:false, 
            selectableRows:'none',
            onRowClick: (rowData) => {
                console.log("rowData",rowData);
                this.props.history.push({pathname:'/teammemberdetails/'+rowData[0], data:rowData}
                )},
            rowsPerPage:5,
            customToolbar: () => {
                return (
                    <CustomToolbar text={"Add Team Member"} clickHandler={()=>this.openAddTeamMemberDialog() } cssClasses={"blue-button"}/>
                );
              }
          };
       return( 
           <>
        <Grid  container className={"user-stroies-header"}>
                {/* <Grid  container className={"user-stroies-header"}>
                    <Grid item style={{width:'172px'}} className="detail-header">
                        <Typography variant="h4">
                              
                        </Typography>
                    </Grid>
                    <Grid>
                          <ButtonComponent toolTip="Add Team Member" text="Add Team Member" onClick={()=>this.onClick()} cssClasses={"blue-button"}></ButtonComponent> 
                    </Grid>
                </Grid> */}
                <MUIDataTable
                title={"Team Member"}
                data={this.state.teamMemberData}
                options={options}
                columns={columns}
                className={"trasnparent-muipaper-root"}
              
                />
                  {this.state.addTeammemberDailogData && this.state.addTeammemberDailogData.openAction   ? 
            <TeamMemberDialog onClose={this.closeAddTeamMemberDialog} childData={this.state.addTeammemberDailogData.childData}  openAction ={this.state.addTeammemberDailogData.openAction}  /> : null}
        </Grid>
        {this.state.snackBarStatus && this.state.snackBarStatus.status ?
            <Notifier variant={this.state.snackBarStatus.variant} key={this.state.snackBarStatus.date} status={this.state.snackBarStatus.status} message={this.state.snackBarStatus.msg} /> : null}
     
        </>
           
       )
    };
}
export default TeamMemberListComponent;