import React from 'react';
import './wayDreamCircle.scss';
import { Box, ButtonBase } from '@material-ui/core';

const serpent1 = require('../../../assets/images/serpents/Rainbow-Serpent-1-Horizontal.gif');
const serpent2 = require('../../../assets/images/serpents/Rainbow-Serpent-2-Horizontal.gif');
const serpent3 = require('../../../assets/images/serpents/Rainbow-Serpent-3-Horizontal.gif');
const serpent4 = require('../../../assets/images/serpents/Rainbow-Serpent-4-Horizontal.gif');
const serpent5 = require('../../../assets/images/serpents/Rainbow-Serpent-5-Horizontal.gif');
const serpent6 = require('../../../assets/images/serpents/Rainbow-Serpent-6-Horizontal.gif');
const star = require('../../../assets/images/star-circle.svg');

export interface Props {
    data: any
}

export interface State {
}

let starListTest: Array<any> = [];

class WayDreamCircle extends React.Component<Props, State>{
    constructor(props) {
        super(props);
    }

    selectStar(starList: Array<any>, star: any, data: any) {
        data.getStoriesByStar(data.childId, star);
    }

    createStarList(starList: Array<any>, starId?: number): Array<any> {
        let newStarList: Array<any> = [];
        for (var i = 0; i < starList.length; i++) {
            let object: any = {
                id: starList[i].id,
                creationOn: starList[i].creationOn,
                starName: starList[i].starName,
                isSelect: starList[i].isSelect ? starList[i].isSelect : false,
                top: '',
                bottom: '',
                left: '',
                right: '',
            }
            switch (i) {
                case 0:
                    object.top = 0;
                    object.right = 0;
                    newStarList.push(object);
                    break;
                case 1:
                    object.bottom = 0;
                    object.right = 0;
                    newStarList.push(object);
                    break;
                case 2:
                    object.top = 34;
                    object.right = 100;
                    newStarList.push(object);
                    break;
                case 3:
                    object.bottom = 34;
                    object.right = 100;
                    newStarList.push(object);
                    break;
                case 4:
                    object.bottom = 0;
                    object.left = 0;
                    newStarList.push(object);
                    break;
                // case 5:
                //    object.top = 0;
                //     object.left = 0;
                //     newStarList.push(object);
                //     break;
            }
        }
        starListTest = newStarList;
        return newStarList;
    }

    render() {
        const { data } = this.props;
        this.createStarList(data.starList);
        return (
            <div className={"way-serpent"}>
                <Box style={{ zIndex: 13, width: "360px", height: "190px", position: "absolute", top: data.positionTop + "px", left: data.positionLeft + "px", transform: "rotate(" + data.positionRotate + "deg)" }}>
                    <Box className={data.className} style={{ width: "290px", height: "100%", position: "absolute", display: "flex" }}>
                        {
                            data.data.progress > 0 ?
                                <img src={
                                    data.data.progress >= 1 && data.data.progress <= 10 ? serpent1 :
                                        data.data.progress >= 11 && data.data.progress <= 25 ? serpent2 :
                                            data.data.progress >= 26 && data.data.progress <= 50 ? serpent3 :
                                                data.data.progress >= 51 && data.data.progress <= 75 ? serpent4 :
                                                    data.data.progress >= 76 && data.data.progress <= 99 ? serpent5 :
                                                        data.data.progress >= 100 ? serpent6 : null
                                } style={{ height: "96px", marginBottom: "auto", marginTop: "auto" }}></img> : null
                        }
                    </Box>
                    <Box  zIndex="2" style={{ height: "100%", width: "70px", position: "absolute", right: "0", display: "flex",  transform: "rotate(" + (-data.positionRotate) + "deg)" }}>
                        <Box style={{ display: "grid", margin: "auto" }}>
                            <ButtonBase>
                                <Box style={{ display: "flex" }} onClick={() => data.getStarsByBehaviour(data.childId, data.data.id, data.isSelect)}>
                                    <img src={data.isSelect ? data.imgCircleRed : data.imgCircleYlw} style={{ width: "100%" }}></img>
                                    <span className="count-star">{data.data.stars}</span>
                                </Box>
                            </ButtonBase>
                        </Box>
                        <Box style={{ position: "absolute", width: "300px", bottom: "25px", left: "-160%" }}>
                            <Box className={data.isSelect ? "circle-title circle-select" : "circle-title circle-no-select"} onClick={() => data.getStarsByBehaviour(data.childId, data.data.id)}>
                                <span style={{ fontSize: "14px" }}>{data.data.circleName}</span>
                            </Box>
                        </Box>
                    </Box>
                    {starListTest && starListTest.length > 0 ?
                        <Box style={{ height: "300px", width: "250px", position: "absolute", right: "0", top: "-60px", display: "flex",cursor: "pointer" }}>
                            {
                                starListTest.map((item, index) => (
                                    <Box style={{ position: "absolute", top: item.top, left: item.left, bottom: item.bottom, right: item.right, width: "72px", transform: "rotate(" + (-data.positionRotate) + "deg)" }} onClick={() => this.selectStar(starListTest, item, data)}>
                                        <img src={star} className={item.isSelect ? "star-select" : ""}></img>
                                        {item.isSelect ?
                                            <span className={"star-name"}>{item.starName}</span> : null
                                        }
                                    </Box>
                                ))
                            }
                        </Box> : null}
                </Box>
            </div>
        )
    }
}

export default WayDreamCircle;
