import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const notificationService = {
    getNotificationsList,
    markNotificationAllAsRead,
    markNotificationAsRead,
    updateNotificationBadgeRead
};

function getNotificationsList(userId:number)
{
    const requestOptions = { method: 'GET',   headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/notifications/notificationsList/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function markNotificationAsRead(notificationId:number)
{
    const requestOptions = { method: 'PATCH',   headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/notifications/markAsRead/${notificationId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function markNotificationAllAsRead(userId:number)
{
    const requestOptions = { method: 'PATCH',   headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/notifications/markAllAsRead/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function updateNotificationBadgeRead(userId:number)
{
    const requestOptions = { method: 'PATCH',   headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/notifications/updateNotificationBadgeRead/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

