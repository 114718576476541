import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const storyService = {
    getNewStoriesByUserID,
    getNewStoriesByUserIDBehavoiurID,
    getNewStoriesByUserAndBehaviour,
    getById,
    getUserStories,
    addStory,
    getUserStoriesIWrote,
    updateStory,
    getIncompleteStoriesByChild,
    getTeamMemberStories
};

function getNewStoriesByUserAndBehaviour(userID:number, behavourId?:number)
{
    return behavourId? getNewStoriesByUserIDBehavoiurID(userID,behavourId):getNewStoriesByUserID(userID);
}
function getNewStoriesByUserID(userId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getChildNewStories/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}



function getNewStoriesByUserIDBehavoiurID(userId:number, behavourId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getChildNewStoriesByBehaviour/${userId}/${behavourId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function getById(storyId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getStory/${storyId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function getUserStories(userId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getUserStories/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}
function getTeamMemberStories(userId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getTeamMemberStories/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function getUserStoriesIWrote(userId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getUserStoriesIWrote/${userId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function addStory(values:any)
{
    const requestOptions = { 
        method: 'POST', 
        headers:{ Authorization:authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(values)};
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/addStory/`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function updateStory(storyId:number, values:any)
{
    const requestOptions = { 
        method: 'PATCH', 
        headers:{ Authorization:authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(values)};
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/updateStory/${storyId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function getIncompleteStoriesByChild(childId:number){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()} };
    // const requestOptions = { method: 'GET', headers: authHeader() };
    console.log(childId);
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/stories/getIncompleteStoriesByChild/${childId}`, requestOptions)
        .then(
            response=>{response.json()
            .then(data=>{
                resolve(data)
            })
            .catch(error=>{
                reject(error);
            })
        }
        )
    })
}