import React from 'react';
// import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface DolphinProps {
   isKid:boolean;
   withTool:boolean;
   backGroundColor:string;
}

export interface DolphinState {
    height:string
}


export class Dolphin extends React.Component<DolphinProps, DolphinState>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.setState({height:"520"})
    }

    getKidWithOutTool(backGroundColor:string){
       const kid=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                        <path fill={backGroundColor} d="M288.9,156.3c-6.5-21.7-21.3-63.6-38.9-63.8c-17.5-0.2-32.2,41.5-38.8,63.4c-50.6,15.7-89.1,59.8-95.1,100
                                c0,0,0,0,0,0c-4.5,47.7,12.1,87.3,55,106.6c-9.2,13.4-25.2,20.7-24.3,47.8c1.1,35.4-2.7,18.6-40.8,30.8
                                c-18.5,5.9-28.6,31-63.4,28.1c-39.1-3.2,26.4,37.1,70.6,34.6c29-1.6,41.2-12.2,65.1-13.7c6-0.4,8.9,0.9,11.3,5.6
                                c4.3,8.1,10.4,14.7,18.1,18.4c7.6,3.7,15.8,5,24.6,5.5c5.5,0.3,10.5,0.4,14.6,0.2c0.9,0,1.9,0.1,2.9,0.1c0,0,0,0,0,0h0c0,0,0,0,0,0
                                c56.8,0,60.8-66,60.8-66.7c0,0,0,0,0,0c0.2-4.4,0.4-9.3,0.2-15.7c5.4,4.5,12.2,5.9,20.5,1c25.1-15-14-57.5-24.8-68.5
                                c39.5-10,62.7-31.9,72.5-63.6C400.7,242.6,357.6,177.6,288.9,156.3z"/>
                            <path fill="#FFFFFF" d="M333.6,199.9c16.1,0,29.1,13,29.1,29.1c0,16.1-13,29.1-29.1,29.1c-16.1,0-29.1-13-29.1-29.1
                                C304.5,212.9,317.5,199.9,333.6,199.9"/>
                            <path fill="#301E1E" d="M327.8,225.1c0-3.2,2.5-5.7,5.6-5.9c-2.6-2.1-5.9-3.4-9.5-3.4c-8.4,0-15.2,6.8-15.2,15.2
                                c0,8.4,6.8,15.2,15.2,15.2c8.4,0,15.2-6.8,15.2-15.2h-5.4C330.4,231,327.8,228.4,327.8,225.1z"/>
                            <path fill="#FFFFFF" d="M166.6,199.9c-16.1,0-29.1,13-29.1,29.1c0,16.1,13,29.1,29.1,29.1c16.1,0,29.1-13,29.1-29.1
                                C195.8,212.9,182.7,199.9,166.6,199.9"/>
                            <path fill="#301E1E" d="M176.4,215.8c-3.6,0-6.9,1.3-9.5,3.4c3.1,0.2,5.6,2.8,5.6,5.9c0,3.3-2.7,5.9-5.9,5.9h-5.4
                                c0,8.4,6.8,15.2,15.2,15.2c8.4,0,15.2-6.8,15.2-15.2C191.6,222.6,184.8,215.8,176.4,215.8z"/>
                            <path opacity="0.18" fill="#FFFFFF" d="M303.2,178.9c0.1-2.9-1.4-3.9-1.4-3.9s-6.6-12.1-31.5-17.3c-6.4-1.5-13.6-2.4-21.7-2.3
                                c-8.1-0.1-15.4,0.8-21.7,2.3c-24.9,5.2-31.5,17.3-31.5,17.3s-0.8,0.9-1.4,3.9c-3,9.7,17.7-2.4,32.2-6c3.1-0.8,7.9-1.9,12.5-2.9
                                c3.3-0.5,6.6-0.8,9.9-0.9c3.3,0,6.6,0.4,9.9,0.9c4.1,0.8,9.1,2,12.5,2.9C287.8,177.5,305.9,188.2,303.2,178.9z"/>
                            <path fill="#FFFFFF" d="M290.2,166.5c-0.6-4-23.5-11.9-35.4-6.9C266.3,159.5,291.6,174,290.2,166.5z"/>
                            <path fill="#FFFFFF" d="M299.2,172.7c-1.5-5.8-14-0.5-2,2.7C297.1,175.4,300,176.2,299.2,172.7z"/>
                            <path fill="#FFFFFF" d="M301.1,178.3c-1.1-1.2-2.5,1,0.1,0.8C301.5,179,301.5,178.8,301.1,178.3z"/>
                            <path opacity="0.18" fill="#FFFFFF" d="M250,227.2c-30.2,0.9-41.4,28.7-41.4,28.7s2.5-4.4,7.6-9.3c-0.3-0.2-0.4-0.6-0.1-0.9
                                c2.2-2.2,4.4-4.5,7.3-6.2c2.8-1.7,5.7-3.7,8.6-5.1c4.2-2.1,15.1-6.7,18.7-2.2c0,0,0.1,0.1,0.1,0.1c2.3,0.1,4.6,0.3,6.7,0.7
                                c0.6,0,1.2,0.2,1.7,0.4c2.2,0.5,4.3,1.3,6.3,2.1c0.2-0.5,0.7-0.9,1.6-1c1.8-0.2,2.5,1.5,1.6,2.4c12.2,6.4,19.3,16.9,19.3,16.9
                                S280.3,226.3,250,227.2z"/>
                            <path fill="#FFFFFF" d="M250.8,232c-3.7-4.5-14.5,0.1-18.7,2.2c-3,1.5-5.8,3.4-8.6,5.1c-2.9,1.7-5.1,4-7.3,6.2
                                c-0.6,0.6,0.4,1.4,1.1,0.9c2.7-1.9,5.8-3.4,8.8-5c3.3-1.8,6.9-2.3,10.6-3.1C239.5,237.9,254.8,237,250.8,232z"/>
                            <path fill="#FFFFFF" d="M259.5,233.4c-0.8-0.3-1.6-0.6-2.5-0.4c-0.4,0.1-0.7,0.2-0.8,0.6c-0.1,0.3,0,0.6,0.3,0.8
                                c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0.5,0.1,0.9,0.1,1.4,0c0.4,0,0.8-0.3,0.8-0.6C260,233.8,259.8,233.6,259.5,233.4
                                z"/>
                            <path fill="#FFFFFF" d="M267.1,234.5c-2.4,0.2-2.2,3.3,0.3,3C269.8,237.3,269.5,234.2,267.1,234.5z"/>
                            <path fill="#DDD9C7" d="M344.4,286.9c-10.5,6.5-19.6,21.5-33.9,17.5c-28.6-9.1-27.7-51.6-60.6-52.2c-32.8,0.6-32.1,43.2-60.6,52.2
                                c-14.1,4.1-23.4-11-33.9-17.5c-8.7-1.5,1.5,10.1,4.2,12.3c19.7,19.1,35,12.7,52.1,1.1c14.1-12.9,18.5-38.5,38.2-39.1
                                c21.1,1.5,24.3,27.2,38.2,39.1c8.9,5.9,20.2,12.9,32.1,10.6C331.2,310.6,357,285.2,344.4,286.9z"/>
                            <path opacity="0.15" fill="#4C4C4C" d="M189.1,453.3c-0.3-5.4-0.4-10.3-0.2-15.7c-9.7,11.3-61.4-0.2,4.3-67.6c-8-2-15.3-4.5-22-7.6
                                c-9.2,13.4-25.2,20.7-24.3,47.8c1.1,35.4-2.7,18.6-40.8,30.8c-18.5,5.9-28.6,31-63.4,28.1c-39.1-3.2,26.4,37.1,70.6,34.6
                                c29-1.6,41.2-12.2,65.1-13.7c6-0.4,8.9,0.9,11.3,5.6c4.3,8.1,10.4,14.7,18.1,18.4c7.6,3.7,15.8,5,24.6,5.5
                                c5.5,0.3,10.5,0.4,14.6,0.2C192.7,517.7,189.1,453.3,189.1,453.3z"/>
                            <g>
                                <path opacity="0.22" fill="#FFFFFF" d="M189.6,362.1c2.8-3,5.5-9.1,7.5-15.2c-1.9,0.4-3.7,0.9-5.6,1.3
                                    c-10.5,20.4-25.4,30-33.8,46.3c-9.6,18.4-1.1,31.1-1.1,31.1s-0.8-1.4,1.1-11.1C159.6,404.8,162.3,390.8,189.6,362.1z"/>
                            </g>
                            <path opacity="0.22" fill="#FFFFFF" d="M35.7,472.1c0,0,18.5,11,46.9-9.8c15.6-11.4,25.3-18.7,41.8-20c14.1-1.1,23,3.4,23,3.4
                                s-24.7-0.9-45.4,12.6c-24.6,16.1-36.8,25.8-50.2,22.3C34.7,476.2,35.7,472.1,35.7,472.1z"/>
                            <path fill="#FFFFFF" d="M122,447.8c-4.4-0.9-42.5,22-45,25.2c-1.2,1.6-0.6,3.8,2.1,2.1c22.9-14.7,42.7-23.4,43.7-25
                                C123.3,449.3,123,448,122,447.8z"/>
                            <path fill="#FFFFFF" d="M177.3,375.1c-2.5,1.7-10.5,13.3-12.8,16.2c-4.6,6-6.1,12.3-7.5,19.5c-0.4,1.9,3,3.7,3.5,1.4
                                c2.9-14.8,17.7-31.6,19.3-34.6C180.6,376.1,178.9,374,177.3,375.1z"/>
                            <path fill="#F4F0DC" d="M359.3,287.8c-4.4-43.9-26.4,17-53.5-5.5c-7.2-5.8-12.9-15.6-21.6-23.9c-9.1-8.9-22.9-15.9-40.6-13.9
                                c-34.6,4.1-39.5,36.2-57.7,41.8c-26.5,5.9-29.7-22.6-42.5-12.7c-14.7,23.2,10.3,73.7,12.8,78.9c0,0,0,0,0.1,0l-0.1,0l0.6,2.1l0,0.1
                                c10.2,6.7,22.3,11.9,36.5,15.4c-65.7,67.3-14.1,78.8-4.3,67.6c-0.2,5.4-0.1,10.3,0.2,15.7c0,0,3.7,66.7,60.8,66.7c0,0,0,0,0,0h0
                                c0,0,0,0,0,0c56.8,0,60.8-66,60.8-66.7c0,0,0,0,0,0c0.2-4.4,0.4-9.3,0.2-15.7c5.4,4.5,12.2,5.9,20.5,1c25.1-15-14-57.5-24.8-68.5
                                c14.2-3.6,26.3-8.8,36.4-15.4l0-0.2l0.6-2.1l0,0c0,0,0,0,0,0C349,343.1,362.7,308.2,359.3,287.8z M359.4,301.1c0,0,0,0,0-0.1
                                C359.4,301,359.4,301,359.4,301.1z"/>
                            <path fill="#DDD9C7" d="M344.4,286.9c-10.5,6.5-19.6,21.5-33.9,17.5c-28.6-9.1-27.7-51.6-60.6-52.2c-32.8,0.6-32.1,43.2-60.6,52.2
                                c-14.1,4.1-23.4-11-33.9-17.5c-8.7-1.5,1.5,10.1,4.2,12.3c19.7,19.1,35,12.7,52.1,1.1c14.1-12.9,18.5-38.5,38.2-39.1
                                c21.1,1.5,24.3,27.2,38.2,39.1c8.9,5.9,20.2,12.9,32.1,10.6C331.2,310.6,357,285.2,344.4,286.9z"/>
                            <path opacity="0.42" fill="#DDD9C7" d="M232.6,303.1c0,0,23-8.7,35.5,0s29.3,28.4,24,31.7s-75.4,10.9-77.8-5.5
                                C212,313,232.6,303.1,232.6,303.1z"/>
                        </svg>
  
  
        return kid;     
    }
    getkidWithTool(backGroundColor:string){
      const kid=    <svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                    <path fill={backGroundColor} d="M288.9,156.2c-6.5-21.7-21.3-63.5-38.8-63.7c-17.5-0.2-32.2,41.3-38.8,63.3
                            c-50.7,15.7-89.2,59.8-95.1,100.1c0,0,0,0,0,0c-4,42.2,8.5,77.7,40.6,98.8c4.7,3.1,9.9,5.9,15.5,8.3c-0.4-0.1-0.7-0.3-1.1-0.4
                            c-4.7,6.9-11.1,12.1-16.2,19.2c-11.1,5.6-18.5,13.6-14.5,25c1.6,4.4,3.8,7.4,6.5,9.4c0.4,27.7-5.1,13.5-40.9,25
                            c-18.5,5.9-28.6,31-63.4,28.1c-39.1-3.2,26.4,37.1,70.6,34.6c29-1.6,41.2-12.2,65.1-13.7c6-0.4,8.9,0.9,11.3,5.6
                            c4.3,8.1,10.4,14.7,18.1,18.4c7.6,3.7,15.8,5,24.6,5.5c5.5,0.3,10.5,0.4,14.6,0.2c0,0,0,0,0,0c0.9,0,1.9,0.1,2.9,0.1c0,0,0,0,0,0h0
                            c0,0,0,0,0,0c56.8,0,60.8-66,60.8-66.7c0,0,0,0,0,0c0,0,0,0,0,0c0.2-4.4,0.4-9.3,0.2-15.6c0,0,0,0,0,0c0,0,0,0,0-0.1
                            c5.4,4.5,12.2,5.9,20.4,1c25.1-15-14-57.5-24.8-68.5c0,0,0,0,0.1,0c0,0,0,0,0,0c6.1-1.5,11.8-3.4,17.1-5.5c0.1-0.1,0.3-0.1,0.4-0.2
                            c2.8-1.1,5.5-2.3,8.1-3.6c0.5-0.3,1.1-0.5,1.6-0.8c0.6-0.3,1.2-0.6,1.9-1c0.9-0.5,1.9-1,2.8-1.5c0.5-0.3,0.9-0.5,1.3-0.8
                            c0.9-0.5,1.7-1,2.6-1.6c0.2-0.1,0.4-0.2,0.6-0.4c18.1-11.7,29.9-28,36.1-48.2C400.7,242.5,357.6,177.4,288.9,156.2z M359.4,300.9
                            c0,0,0,0.1,0,0.1C359.4,301.1,359.4,301,359.4,300.9z"/>
                        <path fill="#FFFFFF" d="M177.3,375.1c-2.5,1.7-10.5,13.3-12.8,16.2c-4.6,6-6.1,12.3-7.5,19.5c-0.4,1.9,3,3.7,3.5,1.4
                            c2.9-14.8,17.7-31.6,19.3-34.6C180.6,376.1,178.9,374,177.3,375.1z"/>
                        <path opacity="0.15" fill="#4C4C4C" d="M218.2,510.9C200,498.5,190,473.5,189,449.8c0,0,0,0,0,0c-0.2-4-0.2-8-0.1-12.1c0,0,0,0,0,0
                            c0.3-11.8,1.6-23.8,3.3-34.3c-9.8,7.2-32.6,21.9-45.3,12.8c0.4,27.3-5,14-39.7,24.6c-21.6,7.1-30.8,32.7-67.6,28.4
                            c-4.5-0.1-6.8,0.4-8.2,1.5c-4.4,3.8,9,13.8,28.1,21.9c65.5,27.9,88.5-4.2,123.8-2.1c2.6,0.6,4.1,1.9,5.8,4.5
                            c11.9,24.4,36.5,25.5,57.9,25c0,0,0,0,0,0C235.2,519.4,225.8,516,218.2,510.9z"/>
                        <path fill="#FFFFFF" d="M333.6,199.9c16.1,0,29.1,13,29.1,29.1c0,16.1-13,29.1-29.1,29.1c-16.1,0-29.1-13-29.1-29.1
                            C304.5,212.9,317.5,199.9,333.6,199.9"/>
                        <path fill="#301E1E" d="M327.8,225.1c0-3.2,2.5-5.7,5.6-5.9c-2.6-2.1-5.9-3.4-9.5-3.4c-8.4,0-15.2,6.8-15.2,15.2
                            c0,8.4,6.8,15.2,15.2,15.2c8.4,0,15.2-6.8,15.2-15.2h-5.4C330.4,231,327.8,228.4,327.8,225.1z"/>
                        <path fill="#FFFFFF" d="M166.6,199.9c-16.1,0-29.1,13-29.1,29.1c0,16.1,13,29.1,29.1,29.1c16.1,0,29.1-13,29.1-29.1
                            C195.8,212.9,182.7,199.9,166.6,199.9"/>
                        <path fill="#301E1E" d="M176.4,215.8c-3.6,0-6.9,1.3-9.5,3.4c3.1,0.2,5.6,2.8,5.6,5.9c0,3.3-2.7,5.9-5.9,5.9h-5.4
                            c0,8.4,6.8,15.2,15.2,15.2c8.4,0,15.2-6.8,15.2-15.2C191.6,222.6,184.8,215.8,176.4,215.8z"/>
                        <path opacity="0.18" fill="#FFFFFF" d="M303.2,178.9c0.1-2.9-1.4-3.9-1.4-3.9s-6.6-12.1-31.5-17.3c-6.4-1.5-13.6-2.4-21.7-2.3
                            c-8.1-0.1-15.4,0.8-21.7,2.3c-24.9,5.2-31.5,17.3-31.5,17.3s-0.8,0.9-1.4,3.9c-3,9.7,17.7-2.4,32.2-6c3.1-0.8,7.9-1.9,12.5-2.9
                            c3.3-0.5,6.6-0.8,9.9-0.9c3.3,0,6.6,0.4,9.9,0.9c4.1,0.8,9.1,2,12.5,2.9C287.8,177.5,305.9,188.2,303.2,178.9z"/>
                        <path fill="#FFFFFF" d="M290.2,166.5c-0.6-4-23.5-11.9-35.4-6.9C266.3,159.5,291.6,174,290.2,166.5z"/>
                        <path fill="#FFFFFF" d="M299.2,172.7c-1.5-5.8-14-0.5-2,2.7C297.1,175.4,300,176.2,299.2,172.7z"/>
                        <path fill="#FFFFFF" d="M301.1,178.3c-1.1-1.2-2.5,1,0.1,0.8C301.5,179,301.5,178.8,301.1,178.3z"/>
                        <path opacity="0.18" fill="#FFFFFF" d="M250,227.2c-30.2,0.9-41.4,28.7-41.4,28.7s2.5-4.4,7.6-9.3c-0.3-0.2-0.4-0.6-0.1-0.9
                            c2.2-2.2,4.4-4.5,7.3-6.2c2.8-1.7,5.7-3.7,8.6-5.1c4.2-2.1,15.1-6.7,18.7-2.2c0,0,0.1,0.1,0.1,0.1c2.3,0.1,4.6,0.3,6.7,0.7
                            c0.6,0,1.2,0.2,1.7,0.4c2.2,0.5,4.3,1.3,6.3,2.1c0.2-0.5,0.7-0.9,1.6-1c1.8-0.2,2.5,1.5,1.6,2.4c12.2,6.4,19.3,16.9,19.3,16.9
                            S280.3,226.3,250,227.2z"/>
                        <path fill="#FFFFFF" d="M250.8,232c-3.7-4.5-14.5,0.1-18.7,2.2c-3,1.5-5.8,3.4-8.6,5.1c-2.9,1.7-5.1,4-7.3,6.2
                            c-0.6,0.6,0.4,1.4,1.1,0.9c2.7-1.9,5.8-3.4,8.8-5c3.3-1.8,6.9-2.3,10.6-3.1C239.5,237.9,254.8,237,250.8,232z"/>
                        <path fill="#FFFFFF" d="M259.5,233.4c-0.8-0.3-1.6-0.6-2.5-0.4c-0.4,0.1-0.7,0.2-0.8,0.6c-0.1,0.3,0,0.6,0.3,0.8
                            c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0.5,0.1,0.9,0.1,1.4,0c0.4,0,0.8-0.3,0.8-0.6C260,233.8,259.8,233.6,259.5,233.4
                            z"/>
                        <path fill="#FFFFFF" d="M267.1,234.5c-2.4,0.2-2.2,3.3,0.3,3C269.8,237.3,269.5,234.2,267.1,234.5z"/>
                        <path opacity="0.22" fill="#FFFFFF" d="M35.7,472.1c0,0,18.5,11,46.9-9.8c15.6-11.4,25.3-18.7,41.8-20c14.1-1.1,23,3.4,23,3.4
                            s-24.7-0.9-45.4,12.6c-24.6,16.1-36.8,25.8-50.2,22.3C34.7,476.2,35.7,472.1,35.7,472.1z"/>
                        <path fill="#FFFFFF" d="M122,447.8c-4.4-0.9-42.5,22-45,25.2c-1.2,1.6-0.6,3.8,2.1,2.1c22.9-14.7,42.7-23.4,43.7-25
                            C123.3,449.3,123,448,122,447.8z"/>
                        <path opacity="0.15" fill="#4C4C4C" d="M172.3,363c-0.4-0.1-0.7-0.3-1.1-0.4c-4.7,6.9-11.1,12.1-16.2,19.2
                            c12.5-6.3,29.6-9.5,40.8-11.1C187.2,368.7,179.4,366.1,172.3,363z"/>
                        <g>
                            <path opacity="0.22" fill="#FFFFFF" d="M189.6,362.1c2.8-3,5.5-9.1,7.5-15.2c-1.9,0.4-3.7,0.9-5.6,1.3
                                c-10.5,20.4-25.4,30-33.8,46.3c-9.6,18.4-1.1,31.1-1.1,31.1s-0.8-1.4,1.1-11.1C159.6,404.8,162.3,390.8,189.6,362.1z"/>
                        </g>
                        <path fill="#F4F0DC" d="M359.3,287.8c-4.4-43.9-26.4,17-53.5-5.5c-7.2-5.8-12.9-15.6-21.6-23.9c-9.1-8.9-22.9-15.9-40.6-13.9
                            c-34.6,4.1-39.5,36.2-57.7,41.8c-26.5,5.9-29.7-22.6-42.5-12.7c-14.7,23.2,10.3,73.7,12.8,78.9c0,0,0,0,0.1,0l-0.1,0l0.6,2.1l0,0.1
                            c10.8,7.1,23.7,12.5,39,16c-21.1,3-63.8,12-55.2,36.1c8.9,25.1,39.9,5.4,51.8-3.4c-2.2,13.2-3.7,29-3.4,43.5c0,0.4,0,0.7,0,1.1
                            c0,0.6,0,1.2,0.1,1.9c0,1,0.1,2,0.1,3c0,0.2,0,0.3,0,0.5c0,0,3.7,66.7,60.8,66.7c0,0,0,0,0,0h0c0,0,0,0,0,0
                            c56.8,0,60.8-66,60.8-66.7c0,0,0,0,0,0c0,0,0,0,0,0c0.2-4.4,0.4-9.3,0.2-15.6c0,0,0,0,0,0c0,0,0,0,0-0.1c5.4,4.5,12.2,5.9,20.4,1
                            c25.1-15-14-57.5-24.8-68.5c0,0,0,0,0.1,0c0,0,0,0,0,0c6.1-1.5,11.8-3.4,17.1-5.5c0.1-0.1,0.3-0.1,0.4-0.2c2.8-1.1,5.5-2.3,8.1-3.6
                            c0.5-0.3,1.1-0.5,1.6-0.8c0.6-0.3,1.2-0.6,1.9-1c0.9-0.5,1.9-1,2.8-1.5c0.5-0.3,0.9-0.5,1.3-0.8c0.9-0.5,1.7-1,2.6-1.6
                            c0.2-0.1,0.4-0.2,0.6-0.4l0-0.1l0.6-2.1l0,0c0,0,0,0,0,0C349,343.1,362.7,308.2,359.3,287.8z M359.4,301.1c0,0,0,0,0-0.1
                            C359.4,301,359.4,301,359.4,301.1z"/>
                        <path fill="#DDD9C7" d="M344.4,286.9c-10.5,6.5-19.6,21.5-33.9,17.5c-28.6-9.1-27.7-51.6-60.6-52.2c-32.8,0.6-32.1,43.2-60.6,52.2
                            c-14.1,4.1-23.4-11-33.9-17.5c-8.7-1.5,1.5,10.1,4.2,12.3c19.7,19.1,35,12.7,52.1,1.1c14.1-12.9,18.5-38.5,38.2-39.1
                            c21.1,1.5,24.3,27.2,38.2,39.1c8.9,5.9,20.2,12.9,32.1,10.6C331.2,310.6,357,285.2,344.4,286.9z"/>
                        <path opacity="0.42" fill="#DDD9C7" d="M232.6,303.1c0,0,23-8.7,35.5,0s29.3,28.4,24,31.7s-75.4,10.9-77.8-5.5
                            C212,313,232.6,303.1,232.6,303.1z"/>
                    </svg>
 
        return kid;

    }
    getTeenWithOutTool(backGroundColor:string){
        const teen =<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height}>
                            <g>
                                <polygon fill="#4FB1C1" points="338.6,433.8 338.6,433.8 338.6,433.8 		"/>
                                <path fill={backGroundColor} d="M343.3,269.4c4.8-6.5,8.4-13.9,10.9-22c3-10.3,4.5-21,4.4-31.8c0-3-0.3-6.1-0.7-9.1
                                    c-6.2-40.2-52.1-85.2-107.8-85.2s-101.6,45-107.9,85.2c-0.5,3-0.7,6-0.7,9.1c-0.1,10.8,1.3,21.5,4.4,31.8c0,0,0,0,0,0
                                    c2.4,7.8,5.9,14.9,10.4,21.3c-0.1-0.5-0.1-1-0.2-1.4c0,0.3,0.1,0.7,0.2,1.4c0,0,0,0,0,0c4.5,6.3,10.4,12.1,17.4,16.8c0,0,0,0,0,0
                                    c11.7,7.9,24.8,12.4,39.7,15.2c0,0,0,0,0,0c1.6,0.3,3.2,0.6,4.8,0.8l-2.2,7.5l-7.1,2.6c-2.2,0.1-4.3,0.5-6.4,1.1
                                    c-34.5,5.9-29.5,31-34.7,53.8c-3.6,3.8-6.2,7.1-6.2,7.1c-8,10.4-16.8,19.3-18.8,33.8c-2.2,16.2,6,25.6-0.2,31.9
                                    c-6.4,6.1-15.7-2.4-31.9-0.5c-25.2,2.9-34.1,26.9-62.7,30.8c-11.9,1.6-17.9-1.6-19.2,0.8c-1.9,3.3,8.5,13.4,25.6,21.7
                                    c1.2,0.6,2.5,1.2,3.7,1.8c3.7,1.8,7.6,3.5,11.5,4.9c1.3,0.5,2.6,0.9,4,1.4c8,2.3,16.5,3.9,25.2,4.5c1.8,0.1,3.5,0.2,5.3,0.2
                                    c19,0.4,38.6-7.4,58.1-12.2c0.7-0.2,1.4-0.3,2-0.5c1.8-0.5,3.7-0.8,5.5-1.1c3.7-0.4,7.3-0.3,10.8,0.7c1.3,0.5,2.5,1.1,3.5,1.9
                                    c4.2,3.1,6.8,8.5,9.5,13c0.8,1.3,1.6,2.6,2.1,4c3.5,9.5,20.6,9.4,23.5,9.3l4.7,0l0-0.1c-1.1,0-2.2-0.1-3.3-0.2
                                    c-1.5-0.1-3-0.3-4.5-0.6c11.5,1,68.7,3.6,77.2-3.4c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                                    l0,0l0,0c0-0.1,0-0.2,0-0.7c6.9,10.5,16.8,15.2,26.1,7.3c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c13.6-14.3-6-52.1-10.7-93.3
                                    c-2.7-23.3-26-27.8-35.8-28.5l-8.3-3c0,0-2.1-7.2-2.2-7.5c22.5-3.4,40.2-11,52.5-22.1C337.2,276.6,340,273.8,343.3,269.4z"/>
                            </g>
                            <path fill={backGroundColor} d="M284.2,127.8c-1.4-4.1-15.9-45.2-34.2-45.4c-18.2-0.2-32.8,41.3-34.2,45.4c10.7-3.6,22.2-5.7,34.2-5.7
                                C261.9,122.1,273.4,124.2,284.2,127.8z"/>
                            <path fill="#FFFFFF" d="M294.5,158.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.7,0.1-0.8,0.4c-0.1,0.5,0.1,1.1,0.7,1.2
                                c0.2,0,0.5,0,0.6-0.3c0.1-0.1,0.1-0.2,0.1-0.4C294.7,159.2,294.7,158.9,294.5,158.7z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M298.1,157.3c-1.7-22.5-48.2-42-80.6-21.5c-17.1,10.3-20.7,28.8-12.3,25.8
                                C268.4,119.5,299.2,179.3,298.1,157.3z"/>
                            <path fill="#FFFFFF" d="M286.6,141.4c-3-5.4-24.1-13.3-31.4-8.9C268,131.6,287.7,150.9,286.6,141.4z"/>
                            <path fill="#FFFFFF" d="M294.1,151.3c-0.3-0.7-0.7-1.4-1.1-2c-0.5-0.8-0.9-1.7-1.8-2.1c-1.2-0.6-2.5-0.1-3,1.2
                                c-0.4,1.1,0.1,2.3,0.6,3.3c0.4,0.9,1,1.7,1.7,2.4c0.4,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7,0,1.1-0.2c0.5-0.3,1-1,0.9-1.6
                                C294.5,152.4,294.4,151.8,294.1,151.3z"/>
                            <path fill="#FFFFFF" d="M253,188.8c-14.8-10.2-42.1,19.9-31.2,14.8c10-8.2,19.7-10.7,28.6-9.8C253.2,194,255.7,191.4,253,188.8z"/>
                            <path fill="#FFFFFF" d="M261.5,190.5c-0.7-0.4-1.5-0.8-2.4-0.6c-0.4,0.1-0.7,0.2-0.8,0.6c-0.1,0.3,0,0.8,0.3,1
                                c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.7-0.3,0.8-0.7
                                C262,191.1,261.8,190.7,261.5,190.5z"/>
                            <path fill="#FFFFFF" d="M268.5,192.5c-2.3,0.2-2.1,3.8,0.2,3.6C271.1,195.9,270.8,192.3,268.5,192.5z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M252.4,183c-28.8-0.5-39.8,32.1-39.8,32.1s2.5-5.2,7.5-10.9c-5.4,0.5,19.2-24.9,32.9-15.4
                                c0.1,0.1,0.1,0.1,0.2,0.2c1.9,0.2,3.8,0.5,5.6,1c0.1,0,0.2-0.1,0.3-0.1c0.8-0.1,1.7,0.2,2.4,0.6c0.2,0.1,0.3,0.3,0.4,0.5
                                c1.8,0.7,3.5,1.6,5.1,2.5c0.2-0.6,0.7-1,1.5-1.1c1.8-0.1,2.3,1.9,1.5,3c11.6,8.2,18.2,21,18.2,21S281.2,183.4,252.4,183z"/>
                            <path fill="#FFFFFF" d="M320.4,176.1c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5
                                C345.9,187.5,334.5,176.1,320.4,176.1z"/>
                            <path fill="#FFFFFF" d="M179.6,176.1c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5
                                C205,187.5,193.6,176.1,179.6,176.1z"/>
                            <path fill="#301E1E" d="M315.3,198.2c0-2.8,2.2-5,4.9-5.2c-2.3-1.8-5.2-2.9-8.3-2.9c-7.3,0-13.3,5.9-13.3,13.3
                                c0,7.3,6,13.3,13.3,13.3c7.3,0,13.3-5.9,13.3-13.3h-4.7C317.6,203.3,315.3,201,315.3,198.2z"/>
                            <path fill="#301E1E" d="M188.1,190c-3.2,0-6.1,1.1-8.3,2.9c2.7,0.1,4.9,2.4,4.9,5.2c0,2.8-2.3,5.2-5.2,5.2h-4.7
                                c0,7.3,5.9,13.3,13.3,13.3c7.3,0,13.3-5.9,13.3-13.3C201.4,196,195.5,190,188.1,190z"/>
                            <path opacity="0.15" fill="#4F4F4F" d="M220.5,519.7c-1.5-0.1-3-0.3-4.5-0.6c-3.3-0.7-6.5-1.8-9.4-3.4c-1.7-1.1-3.1-2.6-4-4.4
                                c-10.2-17.1-14.7-73.8-15.3-82.9c0-0.4,0-0.9,0-1.2c0-0.1,0-0.3,0-0.4c-12.8,19.5-30.6,9.7-30.5-4.6c-0.9-11.9,4.6-30.2,9.4-50.1
                                c0.6-1.9,1.1-3.7,1.6-5.6c-3.6,3.8-6.2,7.1-6.2,7.1c-8,10.4-16.8,19.3-18.8,33.8c-2.2,16.2,6,25.6-0.2,31.9
                                c-6.4,6.1-15.7-2.4-31.9-0.5c-25.2,2.9-34.1,26.9-62.7,30.8c-11.9,1.6-17.9-1.6-19.2,0.8c-1.9,3.3,8.5,13.4,25.6,21.7
                                c1.2,0.6,2.5,1.2,3.7,1.8c3.7,1.8,7.6,3.5,11.5,4.9c1.3,0.5,2.6,0.9,4,1.4c8,2.3,16.5,3.9,25.2,4.5c1.8,0.1,3.5,0.2,5.3,0.2
                                c19,0.4,38.6-7.4,58.1-12.2c0.7-0.2,1.4-0.3,2-0.5c1.8-0.5,3.7-0.8,5.5-1.1c3.7-0.4,7.3-0.3,10.8,0.7c1.3,0.5,2.5,1.1,3.5,1.9
                                c4.2,3.1,6.8,8.5,9.5,13c0.8,1.3,1.6,2.6,2.1,4c3.5,9.5,20.6,9.4,23.5,9.3l4.7,0l0-0.1C222.7,519.9,221.6,519.8,220.5,519.7z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M143.1,453.9c0,0-6.5-9.3-23-10.1c-16.5-0.9-18.7,5-46.7,21.6c-26.2,15.4-41.7,5.7-41.7,5.7
                                s1,6.2,18,10.9c7.2,2,13,1.1,19.3-1.9c-0.4-0.6-0.3-1.6,0.3-2.4c2.4-3.3,24-20.6,45-25.2c0.5-0.1,0.8,0.2,0.9,0.7
                                C130.3,449.4,143.1,453.9,143.1,453.9z"/>
                            <path fill="#FFFFFF" d="M115.2,453c-0.2-0.5-0.5-0.8-0.9-0.7c-20.9,4.6-42.5,22-45,25.2c-0.6,0.8-0.7,1.8-0.3,2.4
                                c0.4,0.5,1.2,0.6,2.4-0.3c21.9-17,42.7-23.4,43.7-25C115.3,454.2,115.3,453.5,115.2,453z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M174.7,360.5c-8.3,17.3-19.8,27.9-25.7,43.1c-7.5,19.3,2.4,31,2.4,31s-0.9-1.3-0.1-11.2
                                c0-0.6,0.1-1.2,0.2-1.8c-0.7-0.4-1.2-1.1-1.2-1.9c0.6-7.4,1.4-13.8,5.3-20.2c1.9-3.1,8.6-15.5,10.9-17.5c0.3-0.3,0.6-0.4,0.9-0.4
                                c1-1.5,2.1-3.1,3.2-4.7C171.9,371.8,173.3,366.3,174.7,360.5z"/>
                            <path fill="#FFFFFF" d="M167.4,381.8c-0.3,0-0.6,0.1-0.9,0.4c-2.3,2-9,14.4-10.9,17.5c-3.9,6.4-4.7,12.9-5.3,20.2
                                c-0.1,0.8,0.5,1.5,1.2,1.9c1,0.6,2.3,0.5,2.4-0.8c1.3-15.1,14.1-33.4,15.4-36.5C169.8,383.2,168.6,381.7,167.4,381.8z"/>
                            <path fill="#F4F0DC" d="M334.1,279.4c3.1-2.8,5.8-5.6,9.1-10.1c0-0.1,0-0.1,0-0.1c0,0,0.8-3.4,0.8-3.4c4.1-25.3-1.1-44.5-15.4-27.2
                                c-22.4,27.3-33.9,2.3-46.1-16.5c-8-12.1-22.6-23.3-40.9-18.6c-23.1,5.2-29.3,32.9-42.8,44.1c-9,7.1-25.7-7.6-29.4-11.3
                                c-14-12.9-16.1,6.4-13.6,29.6c0,0,0,0,0,0c0,0.9,0,1.1,0.3,2.8c0,0,0,0,0,0c4.5,6.3,10.4,12.1,17.4,16.8c0,0,0,0,0,0
                                c11.7,7.9,24.8,12.4,39.7,15.2c0,0,0,0,0,0c1.6,0.3,3.2,0.6,4.8,0.8l-2.2,7.5l-7.1,2.6c-2.2,0.1-4.3,0.5-6.4,1.1
                                c-37.4,6.4-28.4,35.3-36.3,59.4c-4.8,19.8-10.3,38.2-9.4,50.1c0,14.3,17.7,24.1,30.5,4.6c0,0.1,0,0.3,0,0.4c0,0.4,0,0.8,0,1.2
                                c0.7,9.1,5.2,65.8,15.3,82.9c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c11.5,1,68.7,3.6,77.2-3.4c1.7-1.1,3.1-2.6,4.1-4.4
                                c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5l0,0l0,0c0-0.1,0-0.2,0-0.7c6.9,10.5,16.9,15.2,26.1,7.3c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c13.6-14.3-6-52.1-10.7-93.3c-2.7-23.3-26-27.8-35.8-28.5l-8.3-3c0,0-2.1-7.2-2.2-7.5
                                C304.2,298.1,321.8,290.5,334.1,279.4z"/>
                            <path opacity="0.42" fill="#DDD9C7" d="M231.7,258.7c0,0,20-7.6,30.9,0c10.8,7.6,25.5,24.7,20.9,27.5s-65.5,9.5-67.6-4.7
                                C213.8,267.3,231.7,258.7,231.7,258.7z"/>
                            <path fill="#DDD9C7" d="M332.2,244.4c-6.7,4.3-11.5,11-19.5,14.6c-18.5,6.2-28.9-15.9-37.1-28.2c-11-18.9-32-22.2-45.7-7
                                c-9,9.9-12.8,23.3-24.2,31.6c-13.2,10-22.1,2.5-30.8-5.5c-5.3-5.8-14.4-9.2-7.2,1.1c17.2,20.4,34.3,17.3,50.8,3.3
                                c11.4-11.4,14.4-32.1,32.6-32.3c15.1,1.9,18.7,17.9,26.6,27.6c7.1,10.2,21.7,17.1,32.4,15.8C319.7,265.9,343.3,243.2,332.2,244.4z"
                                />
                        </svg>
   
   
                return teen;

    }
    getTeenWithTool(backGroundColor:string){
            const teen=<svg version="1.1"  x="0px" y="0px"	 viewBox="0 0 500 520" enable-background="new 0 0 500 520" height={this.state.height} >
                        <path fill={backGroundColor} d="M343.3,269.4c4.8-6.5,8.4-13.9,10.9-22c3-10.3,4.5-21.1,4.4-31.8c0-3-0.3-6.1-0.7-9.1
                                c-4.9-31.7-34.4-66.3-74-79.6c-2.6-7.2-16.5-44.3-33.8-44.5c-17.3-0.2-31.3,37.2-33.8,44.5c-39.6,13.3-69.1,47.9-74,79.6
                                c-0.5,3-0.7,6-0.7,9.1c-0.1,10.8,1.3,21.5,4.4,31.8c0,0,0,0,0,0c2.4,7.8,5.9,14.9,10.4,21.3c12.2,17.1,32.5,28.4,61.9,32.9
                                l-2.2,7.5l-7.1,2.6c-4.6,0.3-9.2,1.6-13.2,4c-38.7,21.8-84.7,28.6-81.3,50.1c2.7,16.9,25.6,14.4,47.2,7.9
                                c-8,10.4-16.8,19.3-18.8,33.8c-2.2,16.2,6,25.6-0.2,31.9c-6.4,6.1-15.7-2.4-31.9-0.5c-25.2,2.9-34.1,26.9-62.7,30.8
                                c-11.9,1.6-17.9-1.6-19.2,0.8c-1.9,3.3,8.5,13.4,25.6,21.7c1.2,0.6,2.5,1.2,3.7,1.8c3.7,1.8,7.6,3.5,11.5,4.9
                                c1.3,0.5,2.6,0.9,4,1.4c8,2.3,16.5,3.9,25.2,4.5c1.8,0.1,3.5,0.2,5.3,0.2c19,0.4,38.6-7.4,58.1-12.2c0.7-0.2,1.4-0.3,2-0.5
                                c1.8-0.5,3.7-0.8,5.5-1.1c3.7-0.4,7.3-0.3,10.8,0.7c1.3,0.5,2.5,1.1,3.5,1.9c4.2,3.1,6.8,8.5,9.5,13c0.8,1.3,1.6,2.6,2.1,4
                                c3.5,9.5,20.6,9.4,23.5,9.3l4.7,0l0-0.1c1,0,2.1,0.1,3.1,0.1h0.3c2,0,43.7,0,45.6,0h0.3c2.1,0,4.2-0.1,6.3-0.3
                                c1.4-0.1,2.9-0.3,4.3-0.6c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5
                                l-0.6,0l0.7,0v-0.7c6.3,9.2,14,14.2,22.9,9.6c19.4-10-2.3-51.3-7.5-95.6c-2.8-23.4-26-27.7-35.7-28.5l-8.3-3l-2.2-7.5
                                C310.8,297.1,331,286.1,343.3,269.4z"/>
                            <path opacity="0.15" fill="#4F4F4F" d="M216,519.1c-3.3-0.7-6.5-1.8-9.4-3.4c-1.7-1.1-3.1-2.6-4-4.4
                                c-10.2-17.1-14.7-73.8-15.3-82.9c0-0.4,0-0.9,0-1.2c-0.1-3.2-0.2-5.1-0.2-5.1c0-16.1,0.7-30.2,1.9-42.3c0.6-6.2,1.3-12,2.1-17.2
                                c-7.5,3.3-18.4,7.7-29.6,11.1c-8,10.4-16.8,19.3-18.8,33.8c-2.2,16.2,6,25.6-0.2,31.9c-6.4,6.1-15.7-2.4-31.9-0.5
                                c-25.2,2.9-34.1,26.9-62.7,30.8c-11.9,1.6-17.9-1.6-19.2,0.8c-1.9,3.3,8.5,13.4,25.6,21.7c1.2,0.6,2.5,1.2,3.7,1.8
                                c3.7,1.8,7.6,3.5,11.5,4.9c1.3,0.5,2.6,0.9,4,1.4c8,2.3,16.5,3.9,25.2,4.5c1.8,0.1,3.5,0.2,5.3,0.2c19,0.4,38.6-7.4,58.1-12.2
                                c0.7-0.2,1.4-0.3,2-0.5c1.8-0.5,3.7-0.8,5.5-1.1c3.7-0.4,7.3-0.3,10.8,0.7c1.3,0.5,2.5,1.1,3.5,1.9c4.2,3.1,6.8,8.5,9.5,13
                                c0.8,1.3,1.6,2.6,2.1,4c3.5,9.5,20.6,9.4,23.5,9.3l4.7,0l0-0.1c-1.1,0-2.2-0.1-3.3-0.2C219,519.6,217.5,519.4,216,519.1z"/>
                            <path fill="#F4F0DC" d="M343.3,269.4c0-0.1,0-0.1,0-0.2c0,0,0.8-3.4,0.8-3.4c4.1-25.3-1.1-44.5-15.4-27.2
                                c-22.4,27.3-33.9,2.3-46.1-16.5c-8-12.1-22.6-23.3-40.9-18.6c-23.1,5.2-29.3,32.9-42.8,44.1c-9,7.1-25.7-7.6-29.4-11.3
                                c-14-12.9-16.1,6.4-13.6,29.6c0,0,0,0,0,0c0,0.9,0.1,1.7,0.3,2.8c12.2,17.1,32.5,28.4,61.9,32.9l-2.2,7.5l-7.1,2.6
                                c-4.6,0.3-9.2,1.6-13.2,4c-38.7,21.8-84.7,28.6-81.3,50.1c2.7,16.9,25.6,14.4,47.2,7.9c11.2-3.4,22.1-7.8,29.6-11.1
                                c-0.8,5.3-1.5,11-2.1,17.2c-1.2,12.1-1.9,26.2-1.9,42.3c0,0,0.1,1.9,0.2,5.1c0,0.4,0,0.8,0,1.2c0.7,9.1,5.2,65.8,15.3,82.9
                                c0.9,1.8,2.3,3.3,4,4.4c2.9,1.6,6.1,2.8,9.4,3.4c1.5,0.3,3,0.5,4.5,0.6c1.1,0.1,2.2,0.2,3.3,0.2c1,0,2.1,0.1,3.1,0.1h0.3
                                c2,0,43.7,0,45.6,0h0.3c2.1,0,4.2-0.1,6.3-0.3c1.4-0.1,2.9-0.3,4.3-0.6c3.4-0.7,6.6-1.8,9.6-3.5c1.7-1.1,3.1-2.6,4.1-4.4
                                c8.8-14.8,13.3-58.9,14.8-76.6c0.1-2.9,0.2-5.5,0.3-7.5l-0.6,0l0.7,0v-0.7c6.3,9.2,14,14.2,22.9,9.6c19.4-10-2.3-51.3-7.5-95.6
                                c-2.8-23.4-26-27.7-35.7-28.5l-8.3-3l-2.2-7.5C310.8,297.1,331,286.1,343.3,269.4z"/>
                            <path opacity="0.42" fill="#DDD9C7" d="M231.7,258.7c0,0,20-7.6,30.9,0c10.8,7.6,25.5,24.7,20.9,27.5s-65.5,9.5-67.6-4.7
                                C213.8,267.3,231.7,258.7,231.7,258.7z"/>
                            <path fill="#FFFFFF" d="M294.5,158.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.7,0.1-0.8,0.4c-0.1,0.5,0.1,1.1,0.7,1.2
                                c0.2,0,0.5,0,0.6-0.3c0.1-0.1,0.1-0.2,0.1-0.4C294.7,159.2,294.7,158.9,294.5,158.7z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M298.1,157.3c-1.7-22.5-48.2-42-80.6-21.5c-17.1,10.3-20.7,28.8-12.3,25.8
                                C268.4,119.5,299.2,179.3,298.1,157.3z"/>
                            <path fill="#FFFFFF" d="M286.6,141.4c-3-5.4-24.1-13.3-31.4-8.9C268,131.6,287.7,150.9,286.6,141.4z"/>
                            <path fill="#FFFFFF" d="M294.1,151.3c-0.3-0.7-0.7-1.4-1.1-2c-0.5-0.8-0.9-1.7-1.8-2.1c-1.2-0.6-2.5-0.1-3,1.2
                                c-0.4,1.1,0.1,2.3,0.6,3.3c0.4,0.9,1,1.7,1.7,2.4c0.4,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7,0,1.1-0.2c0.5-0.3,1-1,0.9-1.6
                                C294.5,152.4,294.4,151.8,294.1,151.3z"/>
                            <path fill="#FFFFFF" d="M253,188.8c-14.8-10.2-42.1,19.9-31.2,14.8c10-8.2,19.7-10.7,28.6-9.8C253.2,194,255.7,191.4,253,188.8z"/>
                            <path fill="#FFFFFF" d="M261.5,190.5c-0.7-0.4-1.5-0.8-2.4-0.6c-0.4,0.1-0.7,0.2-0.8,0.6c-0.1,0.3,0,0.8,0.3,1
                                c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.7-0.3,0.8-0.7
                                C262,191.1,261.8,190.7,261.5,190.5z"/>
                            <path fill="#FFFFFF" d="M268.5,192.5c-2.3,0.2-2.1,3.8,0.2,3.6C271.1,195.9,270.8,192.3,268.5,192.5z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M252.4,183c-28.8-0.5-39.8,32.1-39.8,32.1s2.5-5.2,7.5-10.9c-5.4,0.5,19.2-24.9,32.9-15.4
                                c0.1,0.1,0.1,0.1,0.2,0.2c1.9,0.2,3.8,0.5,5.6,1c0.1,0,0.2-0.1,0.3-0.1c0.8-0.1,1.7,0.2,2.4,0.6c0.2,0.1,0.3,0.3,0.4,0.5
                                c1.8,0.7,3.5,1.6,5.1,2.5c0.2-0.6,0.7-1,1.5-1.1c1.8-0.1,2.3,1.9,1.5,3c11.6,8.2,18.2,21,18.2,21S281.2,183.4,252.4,183z"/>
                            <path fill="#FFFFFF" d="M320.4,176.1c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5
                                C345.9,187.5,334.5,176.1,320.4,176.1z"/>
                            <path fill="#FFFFFF" d="M179.6,176.1c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5
                                C205,187.5,193.6,176.1,179.6,176.1z"/>
                            <path fill="#301E1E" d="M315.3,198.2c0-2.8,2.2-5,4.9-5.2c-2.3-1.8-5.2-2.9-8.3-2.9c-7.3,0-13.3,5.9-13.3,13.3
                                c0,7.3,6,13.3,13.3,13.3c7.3,0,13.3-5.9,13.3-13.3h-4.7C317.6,203.3,315.3,201,315.3,198.2z"/>
                            <path fill="#301E1E" d="M188.1,190c-3.2,0-6.1,1.1-8.3,2.9c2.7,0.1,4.9,2.4,4.9,5.2c0,2.8-2.3,5.2-5.2,5.2h-4.7
                                c0,7.3,5.9,13.3,13.3,13.3c7.3,0,13.3-5.9,13.3-13.3C201.4,196,195.5,190,188.1,190z"/>
                            <path fill="#DDD9C7" d="M332.2,244.4c-6.7,4.3-11.5,11-19.5,14.6c-18.5,6.2-28.9-15.9-37.1-28.2c-11-18.9-32-22.2-45.7-7
                                c-9,9.9-12.8,23.3-24.2,31.6c-13.2,10-22.1,2.5-30.8-5.5c-5.3-5.8-14.4-9.2-7.2,1.1c17.2,20.4,34.3,17.3,50.8,3.3
                                c11.4-11.4,14.4-32.1,32.6-32.3c15.1,1.9,18.7,17.9,26.6,27.6c7.1,10.2,21.7,17.1,32.4,15.8C319.7,265.9,343.3,243.2,332.2,244.4z"
                                />
                            <path opacity="0.22" fill="#FFFFFF" d="M143.1,453.9c0,0-6.5-9.3-23-10.1c-16.5-0.9-18.7,5-46.7,21.6c-26.2,15.4-41.7,5.7-41.7,5.7
                                s1,6.2,18,10.9c7.2,2,13,1.1,19.3-1.9c-0.4-0.6-0.3-1.6,0.3-2.4c2.4-3.3,24-20.6,45-25.2c0.5-0.1,0.8,0.2,0.9,0.7
                                C130.3,449.4,143.1,453.9,143.1,453.9z"/>
                            <path fill="#FFFFFF" d="M115.2,453c-0.2-0.5-0.5-0.8-0.9-0.7c-20.9,4.6-42.5,22-45,25.2c-0.6,0.8-0.7,1.8-0.3,2.4
                                c0.4,0.5,1.2,0.6,2.4-0.3c21.9-17,42.7-23.4,43.7-25C115.3,454.2,115.3,453.5,115.2,453z"/>
                            <path opacity="0.22" fill="#FFFFFF" d="M174.7,360.5c-8.3,17.3-19.8,27.9-25.7,43.1c-7.5,19.3,2.4,31,2.4,31s-0.9-1.3-0.1-11.2
                                c0-0.6,0.1-1.2,0.2-1.8c-0.7-0.4-1.2-1.1-1.2-1.9c0.6-7.4,1.4-13.8,5.3-20.2c1.9-3.1,8.6-15.5,10.9-17.5c0.3-0.3,0.6-0.4,0.9-0.4
                                c1-1.5,2.1-3.1,3.2-4.7C171.9,371.8,173.3,366.3,174.7,360.5z"/>
                            <path fill="#FFFFFF" d="M167.4,381.8c-0.3,0-0.6,0.1-0.9,0.4c-2.3,2-9,14.4-10.9,17.5c-3.9,6.4-4.7,12.9-5.3,20.2
                                c-0.1,0.8,0.5,1.5,1.2,1.9c1,0.6,2.3,0.5,2.4-0.8c1.3-15.1,14.1-33.4,15.4-36.5C169.8,383.2,168.6,381.7,167.4,381.8z"/>
                        </svg>
       
       
            return teen;
    }

    render() {
        
        const { isKid, withTool,backGroundColor} = this.props
        console.log(isKid,withTool,backGroundColor)
        return (
            <>
            { isKid ?
                   (  
                            withTool ?
                        this.getkidWithTool(backGroundColor)  :
                        this.getKidWithOutTool(backGroundColor)
                   )
                    
                :
                    (
                        withTool ?
                        this.getTeenWithTool(backGroundColor) :
                        this.getTeenWithOutTool(backGroundColor)
                    )
                }
        </>
        )
    }
}
