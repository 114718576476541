import React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell, Link, Tooltip, Box, } from '@material-ui/core';
import './userList.scss';
import ModalStoryList from '../modal/modalStoryList';
import { userRole } from '../../../enums';
import { storyService, authenticationService } from '../../../services';
import { Scrollbars } from 'react-custom-scrollbars';
import ModalUserList from '../modal/modalUserList';
import { action } from '@storybook/addon-actions';
import { Common } from '../../../common';

const avatar  = Common.getAvadarDefaut();
const titleIcon = require('../../../assets/images/menuSelected.svg');
const statusOnline = require('../../../assets/images/status-online.svg');

const logo = "backgroud-behaviour";
const titleIconShow = require('../../../assets/images/show_all.svg');

export interface Props {
    userList: Array<any>,
    currentUser: any,
    title: string,
    link?: boolean,
    history: any,
    action: boolean
}

export interface State {
    userList: Array<any>,  
    title: string
    openModal: boolean,
    userSelect: any,
    storyList: Array<any>,
    showModal: boolean
}

let childUser:any = null;

class UserList extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {userList: [], title:"", openModal: false, userSelect: "", storyList: [], showModal: false}
    }

    componentWillMount() {
        this.setState({ title: this.props.title });
        childUser = authenticationService.isTeamMemberChildValue;
    }

    async getData(user:any){
        await storyService.getUserStoriesIWrote(user.id).then((data:any) => {
            if(data.success){
                this.setState({userSelect : user, storyList: data.data})
            }else{
                //ERROR
            }
        }, error =>{
            //ERROR
            console.log(error.message);
        });
    }

    closeModal = () =>{
        this.setState({showModal: false});
    }

    openModal = () => {
        this.setState({showModal: true});
    }
    

    handleClick = async (user:any)=>{
        await this.getData(user);
        //this.setState({openModal: true});

    }

    handleClose = ()=>{
        this.setState({openModal: false});
        this.setState({storyList: []});
    }

    render() {
        const userList = this.props.userList ? this.props.userList : [];
        const { openModal, userSelect, showModal, title } = this.state;
        const { currentUser, action, history } = this.props;
        return (
            <div className="">
                <Typography className="title">
                    <img src={titleIcon}></img>
                    <Box component="span" display="block">{title}</Box>
                </Typography>
                {/* <Scrollbars style={{ width: "100%", height: "300px" }}> */}
                <Table className="user-table" aria-label="simple table">
                    <TableBody style={{height:"12px"}}>
                        { userList.length > 0 ?
                        userList.map((item, index) => (
                            index < 5 ?
                            <TableRow key={index}>
                                <Tooltip title={item.isLogin === 1 ? "online" : item.lastLogin} placement="top-start">
                                <TableCell style={{width: "35px"}} component="th" scope="row" align="center">
                                    {item.isLogin === 1 ? <img src={statusOnline}></img> : item.lastLogin !== null ? item.lastLogin.substring(0,4) : item.lastLogin}
                                </TableCell>
                                </Tooltip>
                                <TableCell style={{width: "10px"}} align="center"><img style={{width: "30px"}} src={item.avatarFile ? item.avatarFile: avatar}/></TableCell>
                                <TableCell align="left">
                                    {currentUser.roleId !== userRole.child && childUser === null || this.props.link?
                                    <Link href={`/mydreamcircle/${item.id}`}>
                                        <span className={"name"}>{item.fullName}</span>
                                    </Link> : <span className={"name"}>{item.fullName}</span>}
                                    {item.categoryName ? <span> ~ {item.categoryName}</span> : null}
                                </TableCell>
                            </TableRow> : null
                        )) : <Box component="span" display="block" style={{paddingLeft: "65px"}}>There isn't any registered.</Box>}
                    </TableBody>
                </Table>
                {userList.length > 5 ?
                <Box>
                    <Typography className="title">
                        <img src={titleIconShow}></img>
                        <Link 
                            component="button"
                            onClick={this.openModal}
                            variant="body2"><Box component="span" display="block">Show All</Box></Link>
                    </Typography>
                </Box> : null }
                {showModal ?
                <ModalUserList
                    withAction={action}
                    history={history}
                    titleCol={[]}
                    titleSecond={title}
                    titleFirst={childUser ? childUser.userName : currentUser.userName}
                    currentUser={currentUser}
                    setOpen={showModal}
                    childInfo={""}
                    data={userList}
                    backGroundColor={""}
                    callback={this.closeModal}
                    logo={logo}
                ></ModalUserList>:null}
            </div>
        );
    }
}

export default UserList;