import React from 'react';
import './avatarMenus.scss';
import { Grid, Button } from '@material-ui/core';

export interface Props {
    isSelected: boolean,
    image: any,
    onClick: () => void
}

export interface State {
}

class AvatarSubMenu extends React.Component<Props, State>{
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
    }

    render() {
        const { isSelected, onClick, image } = this.props;
        return (
            <div className={"item-sub-menu"}>
                <Button style={{width:"100%", height:"100%"}}>
                    <Grid xs={12} item className={ "not-selected"} onClick={() => onClick()}>
                        <img src={image}/>
                        {isSelected ?
                         <img className="selected-image" src={require("../../../assets/images/selected-icon.png")}/>
                         :null
                        }
                       
                    </Grid>
                    </Button>
            </div>
        )
    }
}

export default AvatarSubMenu;