
import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import ButtonComponent from './buttonComponent';
import CustomToolbar from "../components/customToolbar";
import { AddChildDialog, AddChildDialogProps} from "./popups/addChildPopup/addChildDialog"
import { FormatDate } from '../../../common/formatDate';


export interface  Props {
    // className:string;
    childListData:any;
    history:any;
    refresh: () => void;
    // isChildrenStory:boolean;
}
 
export interface  State {
    isLoading:boolean;
    teamMemberData:any;
    addChildDailogData:any;
}
class UserChildListComponent  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
       
    }

    onClick(){
        console.log('this is clicked ')
    }


    componentWillMount() {
       
        this.setAddChildDialog(false);

    }
    async setAddChildDialog(isOpen:boolean)
    {
          const stateObject={
              openAction:isOpen,
              adminData:this.props.childListData,
              
          }
          this.setState({addChildDailogData:stateObject} );
        
    }
    openAddChildDialog(){
        this.setAddChildDialog(true)
    }

    closeAddChildDialog=()=>{
        this.setAddChildDialog(false);
        this.props.refresh();
    }

    onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }){
        console.log(this.props)
        this.props.history.push({pathname:'/childdetails/'+rowData[0], rowData})
        console.log("----RowClick");
        console.log("rowData: ", rowData);
        console.log("rowMeta: ", rowMeta);
    }

    render(){
      let children=  this.props.childListData.teamMembers;
      console.log("CHILDREN",children);
        const columns = [
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },  {
                name:"uniqueId",
                label:"Unique ID",
                options:{
                    sort:true
                }
            },{
                name:"fullName",
                label: "Full Name",
                options:{
                    sort:true
                }
            },
            {
                name:"startOfCare",
                label:"Start of Care",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(value){
                            return (
                                <div>{FormatDate.trasformDate(new Date(value))}</div>               
                            );
                        }
                        
                    }
                }
            }
           
        ];
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            selectableRowsHeader:false, 
            selectableRows:'none',
            // onRowClick: this.onRowClick,
            caseSensitive:true,
            searchPlaceholder:"Search",
            onRowClick:  (rowData) => this.props.history.push({pathname:'/childdetails/'+rowData[0], data:rowData}),
            customToolbar: () => {
                return (
                    <CustomToolbar text={"Add Child"} clickHandler={()=>this.openAddChildDialog() } cssClasses={"blue-button"}/>
                );
              }
            // customToolbar: () => {
            //     return (
            //       <CustomToolbar />
            //     );
            //   }
          };
       return( 
        <Grid  container>
                {/* <Grid  container>
                    <Grid item style={{width:'172px'}} className="detail-header">
                        <Typography variant="h4">
                                Children
                        </Typography>
                    </Grid>
                    <Grid>
                          <ButtonComponent toolTip="Add Child" text="Add Child" onClick={()=>this.onClick()} cssClasses={"blue-button"}></ButtonComponent> 
                    </Grid>
                </Grid> */}
                <MUIDataTable
                title={"Children"}
                data={children}
                options={options}
                columns={columns}
                className={"trasnparent-muipaper-root"}
                />
        {this.state.addChildDailogData && this.state.addChildDailogData.openAction   ? 
            <AddChildDialog  onClose={this.closeAddChildDialog}   teamMemberData={this.state.addChildDailogData.adminData}  openAction ={this.state.addChildDailogData.openAction}  /> : null}
        </Grid>
       )
    };
}
export default UserChildListComponent;