
import React, { FunctionComponent, useEffect, useState } from 'react'
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    InputBase,
    TextField,
    MenuItem,
    Typography,
    Box,
    Button,
    Select,
    Tooltip,

} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { behavoursService } from '../../../../../services'
import Wrapper from '../../../../baseComponents/wrapper';
import { Form, Formik, Field } from 'formik/dist/index';
import * as yup from 'yup';
import MomentUtils from "@date-io/moment";
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import { FormatDate } from '../../../../../common/formatDate';
import { Notifier } from '../../../../components/notifier/notifier';
import DescriptionIcon from '@material-ui/icons/Description';
const closeIcon = require('../../../../../assets/images/close_icon.png');

const snackBarStatusInitialState: any = "";

export interface behaviouralPlansDialogProps {
    openAction: boolean;
    behaviouralPlanData: any;
    currentUser: any;
    behaviourIdSelected?: number;
    behaviourPlanId? : number;
    isBehaviourSelected: boolean;
    onClose: (update: boolean) => void;
}

const initialState = {
    data: [],
    selectedDate: new Date(),
    open: false,
}


function buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', 'application/pdf');
    return fileSelector;
}


export const BhaviouralPlansDialog: FunctionComponent<behaviouralPlansDialogProps> = ({
    openAction,
    behaviouralPlanData,
    isBehaviourSelected,
    behaviourIdSelected,
    behaviourPlanId,
    currentUser,
    onClose }) => {

    const [{ data, open, selectedDate }, setinitialState] = useState(initialState);
    const [behavioursList, setBehaviours] = useState([] as any);
    const [file, setFile] = React.useState(null as any);
    const [behaviourPlan, setBehaviourPlan] = React.useState(null as any);
    const [snackBarStatus, setsnackBarStatus] = useState(snackBarStatusInitialState);

    interface behaviourData {
        value: number,
        label: string
    }

    useEffect(
        () => {
            let locations: behaviourData[] = [];
            behavoursService.getAllBehavours().then((behaviours: any) => {
                if (behaviours.success) {
                    behaviours.data.map(behaviour => {
                        let data: any = {
                            value: behaviour.id,
                            label: behaviour.behaviourName
                        }
                        locations.push(data);
                    }
                    )
                    setBehaviours({
                        behavioursList: locations,
                    }
                    );
                } else {
                    //ERROR
                }
            }, error => {
                //ERROR
                console.log(error.message);

            });
            console.log("TESTING_!@#",behaviourPlanId);
            
            if(behaviourPlanId && behaviourPlanId > 0){
                behavoursService.getBehaviourPlan(behaviourPlanId).then((data:any) =>{
                    if(data.success){
                        console.log("Testing",data);
                        setBehaviourPlan({behaviourPlan: data.data});
                        // let file = new File(data.data.behaviourPlanFileURL, data.data.fileName);
                        // setFile({file: file});
                    }else{
                        //ERROR
                    }
                }, error => {
                    //ERROR
                    console.log(error.message);
                    
                })
            }
        }, []
    );

    interface Values {
        yourName: string,
        childId: number,
        teamMemberId: number,
        behaviourId: number,
        isPublished: number,
        date: Date,
        fileName: any
    }
    const schema = yup.object().shape({
        yourName: yup.string().required(),
        childId: yup.number().positive(),
        teamMemberId: yup.number().positive(),
        behaviourId: yup.number().positive(),
        isPublished: yup.number(),
        date: yup.date(),
        //fileName: yup.string().required(),
    })

    const handleDateChange = (val: any) => {
        console.log(val);
        if(val){
        setinitialState({
            selectedDate: new Date(val._d),
            data: initialState.data,
            open: initialState.open,
        });
    }
    };

    const handleFileSelect = (e) => {
        e.preventDefault();
        console.log("event", e);
        const element = buildFileSelector();
        element.click();
        element.addEventListener("change", () => {
            const fileAux: any = element.files;
            setFile({ file: fileAux[0] });
        });
    }

    const upload = async (values: any) => {
        console.log(values);
        let stateObject = {
            msg: "",
            status: false,
            date: new Date(),
            variant: 'success'
        }
        await behavoursService.createBehaviourPlan(values).then((data: any) => {
            if (data.success) {
                console.log(data);
                stateObject.msg = data.message;
                stateObject.status = true;
                stateObject.variant = "success"
            } else {
                //ERROR
                stateObject.msg = data.message;
                stateObject.status = true;
                stateObject.variant = "error"
            }
            setsnackBarStatus(stateObject);
            setTimeout(() => {
                stateObject.msg = "";
                stateObject.status = false;
                stateObject.variant = "";
                //if (data.success) onClose(true);
            }, 2000);
        }, error => {
            //ERROR
            console.log(error.message);
            stateObject.msg = "Error in Server";
            stateObject.status = true;
            stateObject.variant = "error"
            setsnackBarStatus(stateObject);
            setTimeout(() => {
                stateObject.msg = "";
                stateObject.status = false;
                stateObject.variant = "";

            }, 3000);
        })
    }

     const getBehaviourIdByBehaviourName = (behavourlist:Array<any>, behavourPlan:any) => {
         console.log(behavourlist);
         console.log(behavourPlan);
         let id = 0;
         for(var i = 0; i < behavourlist.length; i++){
             if(behavourPlan.behaviourName === behavourlist[i].label){
                 id =  behavourlist[i].value;
             }
         }
         console.log("Testing",id);
         return id;
     }

    return (
        <Dialog open={openAction} className="popup-edit-details child-background">
            <DialogContent className={isBehaviourSelected ? "edit-child-behaviours-shells" : "edit-child-behaviours-fish"}>
                <Grid container >
                    <Grid item xs={12}>
                        <h2>
                            <img src={closeIcon} onClick={() => onClose(true)} />
                        </h2>
                        <h2 className={"popup-heading margin-top"} > {behaviouralPlanData.fullName} </h2>
                        <h2 className={"popup-heading"}> {"Upload New Plan"} </h2>
                    </Grid>
                    <Grid item xs={10}>
                        <Wrapper title={""} >
                            <Formik<Values>
                                initialValues={{
                                    yourName: currentUser ? currentUser.userName : 0,
                                    childId: behaviouralPlanData ? behaviouralPlanData.id : 0,
                                    date: behaviourPlan ? behaviourPlan.date : new Date(),
                                    behaviourId: behaviourIdSelected ? behaviourIdSelected : behaviourPlan ? getBehaviourIdByBehaviourName(behavioursList.behavioursList, behaviourPlan.behaviourPlan) : 0,
                                    teamMemberId: currentUser ? currentUser.userId : 0,
                                    isPublished: behaviourPlan ? behaviourPlan.isPublished : 0,
                                    fileName: null
                                }}
                                validationSchema={schema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    if (file) {
                                        const data = new FormData();
                                        values.date = selectedDate;
                                        values.fileName = file.file;
                                        data.append("childId", values.childId.toString());
                                        data.append("behaviourId", values.behaviourId.toString());
                                        data.append("teamMemberId", values.teamMemberId.toString());
                                        data.append("isPublished", values.isPublished.toString());
                                        data.append("fileName", values.fileName, values.fileName.name);
                                        data.append("date", values.date.toString());
                                        upload(data);
                                        setTimeout(() => {
                                            values.behaviourId = behaviourIdSelected ? behaviourIdSelected : 0;
                                            setFile(null);
                                            setinitialState({
                                                selectedDate: new Date(),
                                                data: initialState.data,
                                                open: initialState.open,
                                            });
                                        }, 2000);

                                    } else {
                                        let stateObject = {
                                            msg: "The file is required",
                                            status: true,
                                            date: new Date(),
                                            variant: 'error'
                                        }
                                        setsnackBarStatus(stateObject);
                                        setTimeout(() => {
                                            stateObject.msg = "";
                                            stateObject.status = false;
                                            stateObject.variant = "";

                                        }, 3000);
                                    }
                                    setSubmitting(false);

                                }}
                                render={({ handleChange, submitForm, values, handleBlur, errors }) => (
                                    <Form className="margin-top">
                                        <Grid item xs={8} className={"row"}>
                                            <InputBase
                                                defaultValue="Today's Date"
                                                inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                className={"label"}
                                            />
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    name="date"
                                                    format="DD/MM/YYYY"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} className={"row"}>
                                            <InputBase
                                                defaultValue="Your Name"
                                                inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                className={"label"}
                                            />
                                            <Field
                                                type="text"
                                                name="yourName"
                                                value={values.yourName}
                                                component={TextField}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'naked', }}
                                                disabled={true}
                                            />
                                        </Grid>
                                        {behavioursList && behavioursList.behavioursList &&
                                            <Grid item xs={12} className={"row select-behaviour"}>
                                                <InputBase
                                                    defaultValue="Behaviour *"
                                                    inputProps={{ 'aria-label': 'naked', 'disabled': 'true' }}
                                                    className={errors.behaviourId ? "label Mui-error-label" : "label"}
                                                />
                                                <Select
                                                    name="behaviourId"
                                                    onChange={handleChange}
                                                    value={values.behaviourId}
                                                    disabled={behaviourIdSelected ? true : false}
                                                >
                                                    {behavioursList.behavioursList.map((option: any) => (

                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        }
                                        <Grid item xs={12} style={{ display: "flex" }} className={"row"}>
                                            <Grid item xs={9}>
                                                <span style={{ fontSize: "20px" }}>
                                                    Browse for a file on your computer
                                                </span>
                                            </Grid>
                                            <Grid item xs={3} style={{ display: "flex" }}>
                                                <Button className={"white-button"} onClick={handleFileSelect} >Browse</Button>
                                                {file ?
                                                    <div style={{ paddingLeft: "10px", display: "flex" }}>
                                                        {/* <DoneOutlineRoundedIcon style={{ margin: "auto" }} /> */}
                                                        <Tooltip title={file.file.name} aria-label="add">
                                                            <DescriptionIcon style={{ margin: "auto" }} />
                                                        </Tooltip>
                                                    </div> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box style={{ paddingTop: "10px" }}>
                                                <Button className={"orange-button"} onClick={submitForm} variant="contained">Upload</Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                )}
                            />
                        </Wrapper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Button color="primary" onClick={onSubmit}>
                    Save
                </Button> */}
            </DialogActions>
            {snackBarStatus.status ?
                <Notifier variant={snackBarStatus.variant} key={snackBarStatus.date} status={snackBarStatus.status} message={snackBarStatus.msg} /> : null}
        </Dialog>
    )
};
