import { BehaviorSubject } from 'rxjs';
import { authHeader,  handleResponse } from '../helper';
import config from '../config';

export const newsFeedService = {
    getAllNewsFeedUpdates,
};

function getAllNewsFeedUpdates(pageId:number)
{
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/newsFeed/getAllNewsFeedUpdates/${pageId}`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}