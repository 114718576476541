
import React from 'react'
import { Button,Typography, Grid , InputBase} from '@material-ui/core';
import Switch from 'react-ios-switch';
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../components/customToolbar";
import  {NewStoryDialog, newStoryDialogProps} from "./popups/newStory/newStoryDialog"
import { EditStoriesDialog, EditStoriesDialogProps} from "./popups/newStory/editStoryDialog"
import IosSwitchMaterialUi from "ios-switch-material-ui";
import { storyService } from '../../../services';
import { FormatDate } from '../../../common/formatDate';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
 



export interface  Props {
    // className:string;
    storyListData:any;
    userId:number;
    isChildrenStory:boolean;
}
 
export interface  State {
    stories:any
    isLoading:boolean;
    teamMemberData:any
    newStoryDialogData:any
    editStoryDiaLogData:any
    userId:number;
    snackBarStatus:any
}
class UserStoriesListComponent  extends React.Component< Props,  State> {
    constructor(props) {
        super(props);
      
    }
    
    componentWillMount() {
        let stories= this.props.isChildrenStory?this.props.storyListData.stories: this.props.storyListData.storiesTM
        this.setState({userId:this.props.userId})
        stories.forEach(item=>{
          
              const dob=(new Date(item.date));
             item.dateAssign= dob;
  
         })
         this.setState({stories:stories})
         console.log(stories);
        this.setStoryDialog(false);

    }

    getStoriesWithUserID=()=>{
    console.log(this.state.userId, this.props.isChildrenStory)
    this.setState({isLoading: true});
    if(this.props.isChildrenStory){
        storyService.getUserStories(this.state.userId).then((child:any)=>{
            if(child.success)
            {
                let stories=child.data
                stories.forEach(item=>{
            
                    const dob=(new Date(item.creationOn));
                item.dateAssign= dob;
        
            })
            
                this.setState({stories : stories, isLoading: false})
            
            
            }else{
                const variantIcon = {
                    success: CheckCircleIcon,
                    warning: WarningIcon,
                    error: ErrorIcon,
                    info: InfoIcon,
                };
                const props={
                    message:"there is an error",
                    variant:variantIcon.error
                }
               
            }
        
     })
    }else{
        storyService.getTeamMemberStories(this.state.userId).then((child:any)=>{
            if(child.success)
            {
                let stories=child.data
                stories.forEach(item=>{
            
                    const dob=(new Date(item.creationOn));
                item.dateAssign= dob;
        
            })
            
                this.setState({stories : stories, isLoading: false})
            
            
            }else{
                const variantIcon = {
                    success: CheckCircleIcon,
                    warning: WarningIcon,
                    error: ErrorIcon,
                    info: InfoIcon,
                };
                const props={
                    message:"there is an error",
                    variant:variantIcon.error
                }
                // messageBar(props);
            }
        
     })
    }
    }

    onViewStoriesClick=()=>{
      this.setStoryDialog(true);
   }

   closeStoryDialog=()=>{
    const stateObject={
        openAction:false,
        storyListData: this.props.storyListData,
        
    }
    this.setState({newStoryDialogData:stateObject} );

   }
   closeEditStoryDialog=()=>{
        const stateObject={
            openAction:false,
            storyData: this.props.storyListData,
            
        }
        this.setState({editStoryDiaLogData:stateObject} );
        
   }

   refreshStories=()=>{
    this.getStoriesWithUserID();
   }

  async setStoryDialog(isOpen:boolean)
  {
        const stateObject={
            openAction:isOpen,
            storyListData:this.props.storyListData,
            
        }
        this.setState({newStoryDialogData:stateObject} );
      
  }
  async setEditStoryDialog(isOpen:boolean, storyData:any)
  {
    const stateObject={
        openAction:isOpen,
        storyData:storyData,
        
    }
    this.setState({editStoryDiaLogData:stateObject} );
  }

    openEditStory(storyArray){
        // console.log(story)
        // const storyArray={
        //     id:10
        // }
     
        this.setEditStoryDialog(true, storyArray)
    }

    handleChange = (isChecked, tableData) => {
        console.log(isChecked, tableData);
            let storiesList =this.state.stories;
            console.log(storiesList,tableData.rowIndex,storiesList.indexOf(tableData.rowIndex))

      };

    render(){
     
        const columns = [
            {
                name:"id",
                label:"id",
                options:{
                   display:false
                }
            },  {
                name:"dateAssign",
                label:"Date",
                options:{
                    sort:true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div>{FormatDate.trasformDate(value)}</div>
                          );
                      }
                }
            },{
                name:this.props.isChildrenStory?"storyBy":"storyFor",
                label:this.props.isChildrenStory? "Story By":"Story For",
                options:{
                    sort:true,
                    customBodyRender : (value, tableMeta, updateValue) => {
                        return (
                            <Typography component={'span'} noWrap={true}>
                                {value}
                            </Typography>
                        )
                    }
                }
            },
            {
                name:"behaviourName",
                label:"Behaviour",
                options:{
                    sort:true,
                    customBodyRender : (value, tableMeta, updateValue) => {
                        return (
                            <Typography component={'span'} noWrap={true}>
                                {value}
                            </Typography>
                        )
                    }
                }
            }, {
                name:"linkedToStar",
                label:"Linked to Star",
                options:{
                    sort:true
                }
            },
            {
                name: "isPublished",
                label: "Published",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value===1?'Yes':'No'}
                        </Typography>
                      );
                  }
                }
              }
           
        ];
        const options = {
            print: false,
            download:false,
            filter:false,
            viewColumns:false,
            // selectableRowsOnClick:true,
            selectableRowsHeader:false, 
            selectableRows:'none',
            // onRowClick: this.onRowClick,
            rowsPerPage:5,
            caseSensitive:true,
            searchPlaceholder:"Search",
            onRowClick: (rowData) => {
                // if(!this.props.isChildrenStory){
                    console.log(rowData);
                    this.openEditStory(rowData)
                // }
            },
            customToolbar: () => {
                if(this.props.isChildrenStory){
                return (
                    <CustomToolbar text={"View New Stories"} clickHandler={()=>this.onViewStoriesClick() } cssClasses={"blue-button"}/>
                );
              }
            else{
                return (
                    null
                )
            }
            }
          };
       return( 
                <>
                <Grid  container className={"user-stroies-header"}>
                        {/* <Grid  container className={"user-stroies-header"}>
                            <Grid item style={{width:'172px'}}>
                                <Typography variant="h4">
                                <IosSwitchMaterialUi   
                                    onChange={this.handleChange}
                                    />
                                     <Switch
                                     checked={true}
                                    className="switch"
                                    // onChange={checked => this.setState({ checked })}
                                />
                            </Typography>
                            </Grid>
                        </Grid> */}
                        <MUIDataTable
                        title={"Stories"}
                        data={this.state.stories}
                        options={options}
                        columns={columns}
                        className={"trasnparent-muipaper-root"}
                        />
                    </Grid>
                    {/* <Button variant="contained" onClick={()=>this.openEditStory()} >
                    open popup
                </Button> */}
                    
                    {this.state.newStoryDialogData && this.state.newStoryDialogData.openAction ? 
                        // <NewStoryDialog  onClose={this.closeStoryDialog} onRowClickHandel={()=>this.openEditStory} isBehaviourNewStories={false} openAction ={this.state.newStoryDialogData.openAction} storyListData={this.state.newStoryDialogData.storyListData}  /> : null}
                        <NewStoryDialog  onClose={this.closeStoryDialog} isBehaviourNewStories={false} openAction ={this.state.newStoryDialogData.openAction} storyListData={this.state.newStoryDialogData.storyListData}  /> : null}

                    {this.state.editStoryDiaLogData && this.state.editStoryDiaLogData.openAction   ? 
                        <EditStoriesDialog  onClose={this.closeEditStoryDialog}  refreshFuntion={this.refreshStories}  storyData={this.state.editStoryDiaLogData.storyData}  openAction ={this.state.editStoryDiaLogData.openAction}  /> : null}
                </>
       )
    };
}
export default UserStoriesListComponent;