import config from '../config';
import { authHeader,  handleResponse } from '../helper';
export const behavoursService = {
    getAllBehavours,
    createBehaviourPlan,
    getBehaviourPlansByBehaviour,
    getBehaviourPlansByChild,
    getBehaviourPlan
}

function getAllBehavours(){
    const requestOptions = { method: 'GET', headers:{ Authorization:authHeader()}  };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/behaviours/getBehaviours/`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })

}

function createBehaviourPlan(values:any){
    const requestOptions = { 
        method: 'POST',
        headers: { authorization:authHeader() },
        body: values,
      };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/behaviourPlans/createBehaviourPlan/`, requestOptions)
        .then(
            response=>{
              
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })

}

function getBehaviourPlansByChild(childId:number){
    const requestOptions = { 
        method: 'GET',
        headers: { authorization:authHeader() },
      };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/behaviourPlans/getBehaviourPlansByChild/${childId}`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })
}

function getBehaviourPlansByBehaviour(childId:number, behaviourId:number){
    const requestOptions = { 
        method: 'GET',
        headers: { authorization:authHeader() },
      };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/behaviourPlans/getBehaviourPlansByBehaviour/${childId}/${behaviourId}`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })

}

function getBehaviourPlan(behaviourPlanId:number){
    const requestOptions = { 
        method: 'GET',
        headers: { authorization:authHeader() },
      };
    return new Promise((resolve, reject)=>{
        fetch(`${config.apiURL}/behaviourPlans/getBehaviourPlan/${behaviourPlanId}`, requestOptions)
        .then(
            response=>{
                response.json()
                .then(data=>{
                  resolve(data)
                })
            })
            .catch(error=>{
                reject(error);
            })
    })

}